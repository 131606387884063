import store from "app/store";
import { isEmpty } from "lodash";
import { showMessage } from "app/store/actions/message.actions";
import { recordReferralAccessLog } from "app/main/referrals/actions/referrals.actions";

// Define function that displays a warning message if one exists
export default async function ReferralActionWarningMessage(orgUnitId, referralId, acceptType) {
  const warningMessage = await store.dispatch(recordReferralAccessLog(orgUnitId, referralId, acceptType));

  // If the warning message is not empty, show it in a message component
  if (!isEmpty(warningMessage)) {
    const message = {
      title: "Warning",
      variant: "warning",
      autoHideDuration: null,
      message: warningMessage,
    };

    return store.dispatch(showMessage(message));
  }

  // If there's no warning message, return null
  return null;
}
