import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { find, isEqual } from "lodash";

import AdvancedFilter, { FilterForm, FilterSection } from "components/items/advanced-filter";
import { TextInput, SelectInput, UserSelector } from "components/inputs";
import DateRange from "components/inputs/date-range";
import { AscendingIcon, DescendingIcon } from "helpers/icon-finder";
import formatFilterDate from "helpers/format-filter-date";

import { fetchPatientAccessLogResults, resetPatientMergeLogsFilters } from "../actions/patientAccessLog.actions";
import { getPageInfo, getSearchParams, EmptySearchParams } from "../reducers/patientAccessLog.reducers";

const sortOptions = [
  { label: "Accessed Date - Oldest to Newest", value: "AccessedDateAsc", icon: <AscendingIcon /> },
  { label: "Accessed Date - Newest to Oldest", value: "AccessedDateDesc", icon: <DescendingIcon /> },
];

export default () => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(getPageInfo);
  const params = useSelector(getSearchParams);
  const { pageSize } = pageInfo;

  const onSearch = searchText => {
    dispatch(fetchPatientAccessLogResults(1, pageSize, { ...params, name: searchText }));
  };

  const searchPatientAccessLogs = searchParams => dispatch(fetchPatientAccessLogResults(1, pageSize, searchParams));

  const handleSubmit = ({ searchParams }) => {
    const { accessedStartDate, accessedEndDate, userId } = searchParams;

    const newSearchParams = {
      ...searchParams,
      accessedStartDate: formatFilterDate(accessedStartDate),
      accessedEndDate: formatFilterDate(accessedEndDate),
      userId: userId?.value ?? userId,
    };

    searchPatientAccessLogs(newSearchParams);
  };

  const handleReset = () => {
    dispatch(resetPatientMergeLogsFilters()).then(searchPatientAccessLogs(EmptySearchParams));
  };

  let searchParams = params;

  searchParams = {
    ...searchParams,
    accessedDateRange: [searchParams.accessedStartDate, searchParams.accessedEndDate],
  };

  return (
    <AdvancedFilter
      onSearch={onSearch}
      searchValue={params?.name}
      filterContentAnchor="right"
      withPaperWrapper={false}
      hasFilterValue={!isEqual(EmptySearchParams, params)}
      content={onClose => (
        <FilterForm
          initialValues={{ searchParams }}
          onSubmit={handleSubmit}
          onReset={handleReset}
          onClose={onClose}
          filters={({ setFieldValue, values }) => {
            const sortByIcon = find(sortOptions, x => x.value === values.searchParams.sortBy)?.icon;
            return (
              <div>
                <FilterSection withDivider>
                  <Field
                    label="Term"
                    name="searchParams.name"
                    component={TextInput}
                    icon="search"
                  />
                </FilterSection>
                <FilterSection withDivider label="Sort by">
                  <Field
                    name="searchParams.sortBy"
                    component={SelectInput}
                    options={sortOptions}
                    icon={sortByIcon}
                  />
                </FilterSection>
                <FilterSection withDivider label="Accessed Date">
                  <Field
                    name="searchParams.accessedDateRange"
                    component={DateRange}
                    onChange={value => {
                      if (value) {
                        setFieldValue("searchParams.accessedStartDate", value[0]);
                        setFieldValue("searchParams.accessedEndDate", value[1]);
                      } else {
                        setFieldValue("searchParams.accessedStartDate", null);
                        setFieldValue("searchParams.accessedEndDate", null);
                      }
                    }}
                  />
                </FilterSection>
                <FilterSection>
                  <Field
                    name="searchParams.userId"
                    label="User"
                    component={UserSelector}
                    icon="person_outline"
                  />
                </FilterSection>
              </div>
            );
          }}
        />
      )}
    />
  );
};
