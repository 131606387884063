
import React from "react";
import * as Yup from "yup";

import "utils/yup-validation";
import LoadingState from "components/items/loading-state";
import useRejectFieldSettings from "app/main/referrals/hooks/useRejectFieldSettings";
import RejectReferralForm from "./form";

export default ({ referral, forceLoad, ...other }) => {
  const { loadingFieldSettings, fieldSettings, fieldLabels } = useRejectFieldSettings(referral?.orgUnitId, forceLoad);

  if (loadingFieldSettings) return <LoadingState />;

  const referrerAdvisedPriorityCodeId = referral?.referralDocument?.referrerAdvisedPriorityCodeId ?? null;

  const schema = Yup.object().shape({
    referral: Yup.object().shape({
      referrerAdvisedPriorityCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.referrerAdvisedPriority, fieldSettings?.referrerAdvisedPriority)
        .default(referrerAdvisedPriorityCodeId)
        .nullable(),
      rejectReasonCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.rejectReasonCodeId, fieldSettings?.rejectReasonCodeId)
        .required(`${fieldLabels?.rejectReasonCodeId} is required`)
        .nullable(),
      reason: Yup.string()
        .fieldSettingsValidation(fieldLabels.reason, fieldSettings?.reason)
        .max(2000, `${fieldLabels?.reason} exceeds maximum length of 2000 characters`)
        .nullable(),
    }),
  });

  return (
    <RejectReferralForm
      schema={schema}
      fieldSettings={fieldSettings}
      fieldLabels={fieldLabels}
      referralDocumentId={referral?.referralDocument.id}
      {...other}
    />
  );
};
