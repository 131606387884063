import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Field } from "formik";
import SpecialtyCodeSetSelector from "app/main/specialtyProfiles/components/specialty-code-set-selector";
import { FaceSickIcon } from "helpers/icon-finder";
import { isEmpty, map, some, includes, head } from "lodash";
import { Alert } from "@material-ui/lab";
import { getReferralWorklistSettings, getPresentingComplaintMaxLength } from "app/auth/store/reducers/system-configuration";

import { updateReferralPresentingComplaint } from "app/main/referrals/actions/referrals.actions";
import Form from "components/form";

const ReferralPresentingCompliantForm = ({
  referralData: { presentingComplaints, assignedTo: { assignedToSpecialtyId } },
  referralData,
  fieldSettings,
  fieldLabels,
  limitTags,
  orgUnitId,
  onSucceed,
  ...other
}) => {
  const dispatch = useDispatch();
  const [showPresentingComplaintWarning, setPresentingComplaintWarning] = useState(false);
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const presentingComplaintMaxLength = useSelector(getPresentingComplaintMaxLength);
  const isEnablePresentingComplaintWarning = !isEmpty(referralWorklistSettings?.patientPresentingComplaintWarningMessage);

  const handleSubmit = ({ referral }, { setErrors }) => {
    const { id } = referralData;
    const { presentingComplaintCodes } = referral;
    const presentingComplaintData = {
      referralId: id,
      presentingComplaintCodes: !isEmpty(presentingComplaintCodes) ? map(presentingComplaintCodes, x => ({ code: x.value, description: x.label })) : null,
    };
    dispatch(updateReferralPresentingComplaint(orgUnitId, presentingComplaintData)).then(response => {
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  const referral = { presentingComplaintCodes: null };
  const multiplePresentingComplaint = presentingComplaintMaxLength > 1;
  if (!isEmpty(presentingComplaints)) {
    if (multiplePresentingComplaint) {
      referral.presentingComplaintCodes = map(presentingComplaints, presentingComplaint => ({
        value: presentingComplaint.code,
        label: presentingComplaint.term,
      }));
    } else {
      const presentingComplaint = head(presentingComplaints);
      referral.presentingComplaintCodes = !isEmpty(presentingComplaint)
        ? {
          value: presentingComplaint?.code,
          label: presentingComplaint?.term,
        } : null;
    }
  }

  return (
    <Form
      contentProps={other}
      onSubmit={handleSubmit}
      initialValues={{ referral }}
      variant="filled"
      content={() => (
        <>
          <Field
            name="referral.presentingComplaintCodes"
            component={SpecialtyCodeSetSelector}
            icon={<FaceSickIcon />}
            label={fieldLabels.presentingComplaintCodes}
            required={fieldSettings.presentingComplaintCodes?.required === true}
            multiple={multiplePresentingComplaint}
            multiline
            limitTags={limitTags ?? 3}
            isTermSelector
            codeSetTypeCode="PresentingComplaintCodes"
            onChange={selectedValues => {
              if (isEnablePresentingComplaintWarning) {
                const valueArray = [].concat(selectedValues);
                const selectedCodes = map(valueArray, val => val?.value);
                const isExistPresentComplianceCode = some(presentingComplaints, x => includes(selectedCodes, x.code));
                setPresentingComplaintWarning(isExistPresentComplianceCode);
              }
            }}
            specialtyId={assignedToSpecialtyId}
          />
          {showPresentingComplaintWarning && <Alert severity="warning">{referralWorklistSettings?.patientPresentingComplaintWarningMessage}</Alert> }
        </>
      )}
    />
  );
};

export default ReferralPresentingCompliantForm;
