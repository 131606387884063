import React from "react";
import IconButton from "components/items/icon-button";
import ReferralDisabledItem from "./referral-disable-item";

const PatientReferralLinkButton = ({
  isDisabled,
  orgUnitId,
  referralOrgUnitName,
  referralOrgUnitId,
  onClick,
}) => {
  if (isDisabled) { return (<ReferralDisabledItem orgUnitId={orgUnitId} referralOrgUnitName={referralOrgUnitName} referralOrgUnitId={referralOrgUnitId} />); }

  return <IconButton title="Go to Referral" icon="arrow_forward" onClick={onClick} />;
};

export default PatientReferralLinkButton;
