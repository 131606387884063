import React from "react";
import { Field } from "formik";
import SpecialtyCodeSetSelector from "app/main/specialtyProfiles/components/specialty-code-set-selector";
import PanelContent from "components/items/panel-content";
import Section from "components/items/section";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";
import { DatePicker } from "components/inputs";

export default ({
  fieldSettings,
  fieldLabels,
  calculateReferralExpiryDate,
  referral,
  referralFormLayoutSetting,
  currentAssignedToSpecialty,
  allowSpecialtyReassignment,
}) => {
  const enableBillingSection = (fieldSettings?.typeOfReferral && referralFormLayoutSetting === "Layout2") || fieldSettings?.referralExpiry || fieldSettings?.clinicType || fieldSettings?.claimType;

  if (!enableBillingSection) return null;
  return (
    <Section withDivider title="Billing Information">
      <PanelContent>
        {referralFormLayoutSetting === "Layout2" && fieldSettings?.typeOfReferral && (
          <Field
            name="referral.typeOfReferralCodeId"
            component={CodeSetValueSelector}
            label={fieldLabels.typeOfReferral}
            codeSetTypeCode={fieldSettings.typeOfReferral.fieldName}
            required={fieldSettings.typeOfReferral.required}
            onChange={val => calculateReferralExpiryDate(val, referral.referralDateUtc)}
            allowHideHealthLinkOnly
          />
        )}
        {fieldSettings?.referralExpiry && (
          <Field
            name="referral.referralExpiryDate"
            component={DatePicker}
            label={fieldLabels.referralExpiry}
            required={fieldSettings.referralExpiry.required}
          />
        )}
      </PanelContent>
      <PanelContent>
        {fieldSettings?.clinicType && (
          <Field
            name="referral.clinicTypeCodeId"
            component={SpecialtyCodeSetSelector}
            codeSetTypeCode={fieldSettings.clinicType.fieldName}
            label={fieldLabels.clinicType}
            required={fieldSettings.clinicType.required}
            disabled={!allowSpecialtyReassignment}
            specialtyId={currentAssignedToSpecialty?.value}
          />
        )}
        {fieldSettings?.claimType && (
          <Field
            name="referral.claimTypeCodeId"
            component={CodeSetValueSelector}
            codeSetTypeCode={fieldSettings.claimType.fieldName}
            label={fieldLabels.claimType}
            required={fieldSettings.claimType.required}
          />
        )}
      </PanelContent>
    </Section>
  );
};
