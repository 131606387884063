import React, { useState, useEffect, useRef } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { isEmpty } from "lodash";
import { List, Typography, Box } from "@material-ui/core";
import EmptyState from "components/items/empty-state";
import LoadingState from "components/items/loading-state";

const InfiniteLoadingList = ({
  loadFunction,
  data,
  loading,
  error,
  renderItem,
  total,
  pageStart,
  initialLoad,
  initial,
  initialMessage,
}) => {
  const [initialLoading, setInitialLoading] = useState(initialLoad);
  const timer = useRef();

  useEffect(() => {
    let isSubscribed = true;

    if (initialLoad && initialLoading) {
      timer.current = setTimeout(() => {
        loadFunction().then(() => {
          if (isSubscribed) {
            setInitialLoading(false);
          }
        });
      }, 500);
    }

    return () => { isSubscribed = false; clearTimeout(timer.current); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (initial) {
    return <EmptyState title={initialMessage} />;
  }

  if (initialLoading || (loading && data.length === 0)) {
    return <LoadingState />;
  }

  if (!isEmpty(error)) {
    return <Typography className="font-bold">{error}</Typography>;
  }

  if (isEmpty(data)) {
    return <EmptyState title="No Records" />;
  }

  return (
    <InfiniteScroll
      pageStart={pageStart}
      loadMore={loadFunction}
      hasMore={data.length < total && !loading}
      useWindow={false}
      loader={<div key={0}><LoadingState /></div>}
    >
      <List className="w-full pt-0 h-full">
        {data.map((item, index) => (
          <Box key={String(`item_${index}`)}>{renderItem(item)}</Box>
        ))}
      </List>
    </InfiniteScroll>
  );
};

export default InfiniteLoadingList;
