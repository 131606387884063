import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, find } from "lodash";

import { getCurrentApplication } from "app/main/applications/reducers/applications.reducers";
import { getHelperTextBySectionName, getUrgencyTreatmentTypeSettings, getDecimalNumberRegex, getDecimalPlacesRegex } from "app/auth/store/reducers/system-configuration";
import { isDrugSearchDisabled } from "app/auth/store/reducers/user.reducer";
import LoadingState from "components/items/loading-state";
import { onSaveOrUpdateTreatment, onSaveTreatment } from "app/main/applications/actions/applications.actions";
import useFormFieldConfig from "hooks/use-form-field-config";
import TreatmentComponent from "./form";
import generateSchema from "./yup-schema";

const generateFieldLabels = fieldSettings => {
  const getFieldLabel = (key, defaultLabel) => ({ [key]: fieldSettings?.[key]?.label || defaultLabel });

  const fieldLabels = {
    ...getFieldLabel("drug", "Requested Medication"),
    ...getFieldLabel("form", "Form"),
    ...getFieldLabel("strength", "Strength"),
    ...getFieldLabel("urgencyTreatmentType", "Urgency Of Treatment"),
    ...getFieldLabel("urgencyOfTreatmentRationale", "Urgency Of Treatment Rationale"),
    ...getFieldLabel("brandSpecified", "Brand Specified"),
    ...getFieldLabel("brand", "Brand"),
    ...getFieldLabel("medicationDoseAndFrequency", "Dose and Frequency"),
    ...getFieldLabel("medicationDose", "Medication Dose"),
    ...getFieldLabel("medicationFrequency", "Medication Frequency"),
    ...getFieldLabel("route", "Route"),
    ...getFieldLabel("treatmentDurationType", "Type of Duration of Treatment"),
    ...getFieldLabel("treatmentDurationValue", "Treatment Duration"),
    ...getFieldLabel("treatmentDurationUnit", "Treatment Duration Unit"),
    ...getFieldLabel("requestedDuration", "Requested Duration"),
    ...getFieldLabel("sMFApprovedIndications", "SMF Approved Indications"),
    ...getFieldLabel("sMFApprovedIndicationsComments", "SMF Approved Indications Comments"),
    ...getFieldLabel("tGAApprovedMedication", "TGA Approved Medication"),
    ...getFieldLabel("tGALicencedIndication", "TGA Approved Indication"),
    ...getFieldLabel("pBSApprovedIndication", "PBS Approved Indication"),
    ...getFieldLabel("pBSApprovedIndicationComments", "PBS Approved Indication Comments"),
    ...getFieldLabel("nursingConsiderations", "Nursing Considerations"),
    ...getFieldLabel("nursingConsiderationRationale", "Nursing Consideration Rationale"),
    ...getFieldLabel("cost", "Cost"),
    ...getFieldLabel("costPer", "Period"),
    ...getFieldLabel("compassionateAccess", "Medication Access Program / Compassionate Access / Cost Share"),
    ...getFieldLabel("medicationAccessProgramOrArrangement", "Medication Access Program Or Arrangement"),
    ...getFieldLabel("medicationSponsorCostContribution", "Medication Sponsor Cost Contribution"),
    ...getFieldLabel("prescriberDeclaration", "Prescriber Declaration"),
    ...getFieldLabel("indication", "Indication"),
    ...getFieldLabel("additionalIndicationDetails", "Additional Indication Details"),
    ...getFieldLabel("relevantMedicalHistory", "Relevant Medical History"),
    ...getFieldLabel("hasTreatmentCommenced", "Has Treatment Commenced?"),
    ...getFieldLabel("relevantTreatmentSoFar", "Relevant Treatments Trialled"),
    ...getFieldLabel("treatmentIntent", "Treatment Intent"),
    ...getFieldLabel("priorApproval", "Prior Approval"),
    ...getFieldLabel("rationale", "Supporting Evidence"),
    ...getFieldLabel("safetyConcerns", "Safety Concerns"),
    ...getFieldLabel("expectedOutcomeMeasures", "Expected Outcome Measures / Goals of Therapy"),
    ...getFieldLabel("anticipatedImpactOnPrognosis", "Anticipated Impact On Prognosis"),
    ...getFieldLabel("patientToxicity", "Possible Toxicity in This Patient"),
    ...getFieldLabel("transitionPlan", "Transition Plan"),
    ...getFieldLabel("transitionPlanPaediatric", "Transition Plan Paediatric"),
  };

  return fieldLabels;
};

const TreatmentRequestForm = props => {
  const {
    initialValues,
    onSucceed,
    requestedMedication: currentMedication,
    orgUnitId,
    indication,
    additionalIndicationDetails,
    relevantTreatmentSoFar,
    rationale,
    expectedOutcomeMeasures,
    medicationDoseAndFrequency,
    cost,
    costPer,
    hasTreatmentCommenced,
    safetyConcerns,
    requestedDuration,
    compassionateAccess,
    medicationAccessProgramOrArrangement,
    medicationSponsorCostContribution,
    prescriberDeclaration,
    priorApproval,
    transitionPlan,
    transitionPlanPaediatric,
    treatmentIntent,
    relevantMedicalHistory,
    anticipatedImpactOnPrognosis,
    patientToxicity,
    nursingConsiderations,
    nursingConsiderationRationale,
    urgencyTreatmentType,
    urgencyOfTreatmentRationale,
    treatmentDurationType,
    treatmentDurationUnit,
    treatmentDurationValue,
    brand,
    brandSpecified,
    forceLoad,
    ...other
  } = props;

  const dispatch = useDispatch();

  const helperText = useSelector(state => getHelperTextBySectionName(state, "treatment"));
  const { fieldSettings, loadingFieldSettings } = useFormFieldConfig(orgUnitId, "CreateEditTreatment", forceLoad);
  const drugSearchDisabled = useSelector(isDrugSearchDisabled);
  const urgencyOfTreatmentStatusChoices = useSelector(getUrgencyTreatmentTypeSettings);
  const currentApplication = useSelector(getCurrentApplication);
  const decimalNumberRegex = useSelector(getDecimalNumberRegex);
  const decimalPlacesRegex = useSelector(getDecimalPlacesRegex);

  const fieldLabels = generateFieldLabels(fieldSettings);

  const isUrgentTreatment = urgencyTreatmentTypeVal => find(urgencyOfTreatmentStatusChoices, x => x.value === urgencyTreatmentTypeVal)?.isUrgent;

  if (loadingFieldSettings) return <LoadingState />;

  const schema = generateSchema(fieldLabels, fieldSettings, isUrgentTreatment, decimalNumberRegex, decimalPlacesRegex);

  let { treatment } = schema.cast();

  if (!isEmpty(currentMedication)) {
    treatment = {
      indication,
      indicationTerm: indication?.indicationTerm,
      additionalIndicationDetails,
      relevantTreatmentSoFar,
      rationale,
      expectedOutcomeMeasures,
      medicationDoseAndFrequency,
      cost,
      costPer,
      hasTreatmentCommenced,
      safetyConcerns,
      requestedDuration,
      compassionateAccess,
      medicationAccessProgramOrArrangement,
      medicationSponsorCostContribution,
      prescriberDeclaration,
      priorApproval,
      transitionPlan,
      transitionPlanPaediatric,
      treatmentIntent,
      relevantMedicalHistory,
      anticipatedImpactOnPrognosis,
      patientToxicity,
      nursingConsiderations,
      nursingConsiderationRationale,
      urgencyTreatmentType,
      urgencyOfTreatmentRationale,
      treatmentDurationType,
      treatmentDurationUnit,
      treatmentDurationValue,
      brand,
      brandSpecified,
      ...currentMedication,
    };
  }

  const handleSubmit = ({ treatment: newTreatment }, { setErrors }) => {
    const { id } = currentApplication;
    const { medicationDose } = newTreatment;
    const formattedTreatment = {
      ...newTreatment,
      orgUnitId,
      medicationDose: !isEmpty(medicationDose) ? `${medicationDose}` : undefined,
    };
    const apiCall = id ? dispatch(onSaveOrUpdateTreatment(orgUnitId, id, formattedTreatment)) : dispatch(onSaveTreatment(orgUnitId, formattedTreatment));

    apiCall.then(response => {
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  return (
    <TreatmentComponent
      schema={schema}
      handleSubmit={handleSubmit}
      fieldLabels={fieldLabels}
      helperText={helperText}
      fieldSettings={fieldSettings}
      treatment={treatment}
      orgUnitId={orgUnitId}
      drugSearchEnabled={!drugSearchDisabled}
      urgencyOfTreatmentStatusChoices={urgencyOfTreatmentStatusChoices}
      isUrgentTreatment={isUrgentTreatment}
      other={other}
    />
  );
};

export default TreatmentRequestForm;
