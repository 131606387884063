import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import PagingTable from "components/items/paging-table";
import { fetchEndorsementTypeConfiguration, fetchOrgUnitNotificationConfiguration } from "app/main/orgUnits/actions/orgUnit.actions";
import { getNotificationConfigurations, isNotificationConfigurationLoading, getNotificationConfigurationPageInfoPageInfo, ORG_UNIT_PAGE_SIZE_OPTIONS } from "app/main/orgUnits/reducers/orgUnit.reducers";
import CheckboxBlankCircleIcon from "mdi-react/CheckboxBlankCircleIcon";
import CheckboxBlankCircleOutlineIcon from "mdi-react/CheckboxBlankCircleOutlineIcon";
import { green, red } from "@material-ui/core/colors";

const OrgUnitNotificationConfigurationContent = ({ id, orgUnit, history }) => {
  const dispatch = useDispatch();
  const orgUnitId = orgUnit.id;
  const getPagedConfiguration = (pageNumber, pageSize) => dispatch(fetchOrgUnitNotificationConfiguration(
    orgUnitId, pageNumber, pageSize,
  ));
  const loading = useSelector(state => isNotificationConfigurationLoading(state, orgUnitId));
  const notificationConfigurations = useSelector(state => getNotificationConfigurations(state, orgUnitId));

  useEffect(() => {
    if (orgUnitId) {
      dispatch(fetchEndorsementTypeConfiguration(orgUnitId));
      dispatch(fetchOrgUnitNotificationConfiguration(orgUnitId));
    }
  }, [dispatch, orgUnitId]);

  const pageInfo = useSelector(getNotificationConfigurationPageInfoPageInfo);
  const onChangeRowsPerPage = pageSize => getPagedConfiguration(1, pageSize);
  const onChangePage = pageNumber => getPagedConfiguration(pageNumber + 1, pageInfo.pageSize);

  const onEdit = eventType => {
    history.push(`/admin/org-units/${orgUnit.orgUnitType}/${id}/notificationConfigurations/${eventType}`);
  };

  const renderTableRow = config => (
    <TableRow
      hover
      key={config.eventType}
      onClick={() => onEdit(config.eventType)}
    >
      <TableCell>
        {config.eventType}
      </TableCell>
      <TableCell>
        {config.enabled ? <CheckboxBlankCircleIcon color={green[600]} size={20} /> : <CheckboxBlankCircleOutlineIcon color={red[600]} size={20} />}
      </TableCell>
    </TableRow>
  );

  return (
    <PagingTable
      loading={loading}
      data={notificationConfigurations}
      rowsPerPage={pageInfo.pageSize}
      page={pageInfo.pageNumber - 1}
      count={pageInfo.totalRecords}
      onChangeRowsPerPage={onChangeRowsPerPage}
      pageSizeOptions={ORG_UNIT_PAGE_SIZE_OPTIONS}
      onChangePage={onChangePage}
      tableRow={item => renderTableRow(item)}
      emptyMessage="No Notification Configuration Recorded"
    />
  );
};

export default OrgUnitNotificationConfigurationContent;
