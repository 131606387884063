import React, { useEffect, useState, useContext } from "react";
import { Tabs, Tab } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { map, compact, size, find, findIndex } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import AppContext from "app/AppContext";
import { fetchOrgUnitById } from "app/main/orgUnits/actions/orgUnit.actions";
import { isCurrentLoading, getCurrentOrgUnit } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { getChildOrgUnitSettings, getOrgUnitAdminTabs, getEnableGPLetters } from "app/auth/store/reducers/system-configuration";
import useSetUrl from "app/main/admin/hooks/useSetUrl";
import withPermissions from "permissions/withPermissions";
import { OrgUnitsIcon } from "helpers/icon-finder";
import DefaultPageLayout from "components/layouts/default-page-layout";
import OrgUnitPageHeader from "app/main/orgUnits/components/orgUnit-page-header";
import OrgUnitList from "app/main/orgUnits/components/orgUnit-list";
import OrgUnitPrimaryHeader from "../components/orgUnit-primary-header";
import OrgUnitDetailPageHeader from "./orgUnit-details-page-header";
import OrgUnitDetailPageContent from "./orgUnit-details-page-content";
import OrgUnitNotificationConfigurationContent from "./orgUnit-notification-configuration-content";
import OrgUnitSpecialtyProfilesPageHeader from "./orgUnit-specialty-profiles-page-header";
import OrgUnitSpecialtyProfilesPageContent from "./orgUnit-specialty-profiles-page-content";
import OrgUnitFormFieldsConfigurationPageContent from "./orgUnit-form-fields-configuration-page-content";
// import OrgUnitFormFieldPageContent from "./orgUnit-form-fields-page-content";
import OrgUnitEndorsementTypePageContent from "./orgUnit-endorsement-types-page-content";
import OrgUnitDecisionLetterTemplatePageContent from "./orgUnit-decision-letter-template-page-content";
import OrgUnitIPABlankFormPageContent from "./orgUnit-ipa-form-page-content";
import OrgUnitGPLetterTemplatePageContent from "./orgUnit-gp-letter-template-page-content";
import OrgUnitReferralFormProfilesPageHeader from "./orgUnit-referral-form-profiles-page-header";
import OrgUnitReferralFormProfilesPageContent from "./orgUnit-referral-form-profiles-page-content";

const useStyles = makeStyles(theme => ({
  tabs: {
    height: 64,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tabsFlexContainer: {
    height: 64,
  },
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: theme.palette.secondary.main,
    },
  },
  tab: {
    minWidth: "auto",
  },
}));

const OrgUnitOverViewPage = ({
  match: { params: { id, orgUnitType } },
  history,
  route,
  hasPermissionSpecialtyProfileMaintain,
  hasPermissionOrgUnitMaintain,
  hasPermissionReferralFormProfileView,
}) => {
  useSetUrl(route);
  const enabledFields = useSelector(getOrgUnitAdminTabs);
  const enabledGPLetter = useSelector(getEnableGPLetters);
  const isFieldEnabled = name => findIndex(enabledFields, x => x === name) !== -1;

  const appContext = useContext(AppContext);
  const { setBackURL } = appContext;
  useEffect(() => {
    setBackURL(history?.location?.fromUrl ?? history?.location?.backUrl ?? "/admin?tab=orgUnits");
  });

  const dispatch = useDispatch();
  const classes = useStyles();
  const orgUnit = useSelector(getCurrentOrgUnit);
  const childOrgUnitSettings = useSelector(state => getChildOrgUnitSettings(state, orgUnit?.orgUnitType));
  const enableChidOrgUnit = size(childOrgUnitSettings) > 0;

  const tabs = compact([
    {
      label: "Details",
      value: "details",
      header: props => <OrgUnitDetailPageHeader {...props} />,
      content: props => <OrgUnitDetailPageContent orgUnitType={orgUnitType} {...props} />,
    },
    enableChidOrgUnit && isFieldEnabled("OrgUnits") && {
      label: "Pharmacies",
      value: "orgUnits",
      icon: <OrgUnitsIcon />,
      header: () => null,
      content: props => map(childOrgUnitSettings, (childOrgUnitSetting, index) => (
        <div key={index}>
          <OrgUnitPageHeader orgUnitType={childOrgUnitSetting.orgUnitType} parentOrgUnitType={orgUnit.orgUnitType} parentOrgUnitId={orgUnit.id} disabledSearch {...props} />
          <OrgUnitList orgUnitType={childOrgUnitSetting.orgUnitType} parentOrgUnitId={orgUnit.id} parentOrgUnitType={orgUnit.orgUnitType} {...props} />
        </div>
      )),
    },
    hasPermissionSpecialtyProfileMaintain && enableChidOrgUnit && isFieldEnabled("SpecialtyProfiles") && {
      label: "Specialty Profiles",
      value: "specialtyProfiles",
      header: props => <OrgUnitSpecialtyProfilesPageHeader {...props} />,
      content: props => <OrgUnitSpecialtyProfilesPageContent {...props} />,
    },
    enableChidOrgUnit && hasPermissionReferralFormProfileView && isFieldEnabled("Fields") && {
      label: "Referral Form Profiles",
      value: "referralFormProfiles",
      header: props => <OrgUnitReferralFormProfilesPageHeader {...props} />,
      content: props => <OrgUnitReferralFormProfilesPageContent {...props} />,
    },
    // enableChidOrgUnit && isFieldEnabled("Fields") && {
    //   label: "Form Fields",
    //   value: "fields",
    //   header: () => null,
    //   content: props => <OrgUnitFormFieldPageContent {...props} />,
    // },
    enableChidOrgUnit && isFieldEnabled("Fields") && {
      label: "Form Configuration",
      value: "formFields",
      header: () => null,
      content: props => <OrgUnitFormFieldsConfigurationPageContent {...props} />,
    },
    enableChidOrgUnit && isFieldEnabled("EndorsementTypes") && {
      label: "Endorsement Types",
      value: "endorsementTypes",
      header: () => null,
      content: props => <OrgUnitEndorsementTypePageContent {...props} />,
    },
    enableChidOrgUnit && isFieldEnabled("DecisionLetterTemplate") && {
      label: "Decision Letter Template",
      value: "decisionLetterTemplate",
      header: () => null,
      content: props => <OrgUnitDecisionLetterTemplatePageContent {...props} label="Decision Letter Template" />,
    },
    enableChidOrgUnit && isFieldEnabled("IpaForm") && {
      label: "IPA Blank Form",
      value: "ipaForm",
      header: () => null,
      content: props => <OrgUnitIPABlankFormPageContent {...props} label="IPA Blank Form" />,
    },
    enableChidOrgUnit && (enabledGPLetter || isFieldEnabled("GPLetter")) && {
      label: "Specialist Letter Templates",
      value: "gpLetterTemplate",
      header: () => null,
      content: props => <OrgUnitGPLetterTemplatePageContent {...props} label="GP Letter Template" />,
    },
    enableChidOrgUnit && isFieldEnabled("NotificationConfiguration") && {
      label: "Notification Configuration",
      value: "notificationConfiguration",
      header: () => null,
      content: props => <OrgUnitNotificationConfigurationContent {...props} label="Notification Configuration" />,
    },
  ]);

  const [disabled, setDisabled] = useState(true);
  const loading = useSelector(isCurrentLoading);
  const query = new URLSearchParams(history.location.search);
  const selectedTab = find(tabs, x => x.value === query.get("tab")) ? query.get("tab") : "details";

  const componentProps = {
    id,
    history,
    orgUnit,
    formDisabled: disabled,
    onToggleFormStatus: setDisabled,
    loading,
  };

  const currentOrgUnitId = orgUnit?.id;

  useEffect(() => {
    if (!loading && id !== currentOrgUnitId && hasPermissionOrgUnitMaintain) {
      dispatch(fetchOrgUnitById(id));
    }
  }, [dispatch, id, loading, currentOrgUnitId, hasPermissionOrgUnitMaintain]);

  const handleChange = (event, value) => {
    history.push(`${history.location.pathname}?tab=${value}`);
  };

  const renderTabs = () => (
    <Tabs
      value={selectedTab}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="on"
      classes={{ root: classes.tabs, flexContainer: classes.tabsFlexContainer, indicator: classes.indicator }}
      TabIndicatorProps={{ children: <span /> }}
    >
      {map(tabs, tab => (
        <Tab
          classes={{ root: classes.tab }}
          key={tab.value}
          label={tab.label}
          value={tab.value}
        />
      ))}
    </Tabs>
  );

  const header = () => map(tabs, (x, index) => {
    if (x.value !== selectedTab) return undefined;
    return (
      <React.Fragment key={index}>
        {x.header({ ...componentProps })}
      </React.Fragment>
    );
  });

  const content = () => map(tabs, (x, index) => {
    if (x.value !== selectedTab) return undefined;
    return (
      <React.Fragment key={index}>
        {x.content({ ...componentProps })}
      </React.Fragment>
    );
  });

  return (
    <>
      <OrgUnitPrimaryHeader />
      <DefaultPageLayout
        empty={orgUnit == null}
        loading={loading === true}
        classes={{ content: "top-64" }}
        accessDenied={!hasPermissionOrgUnitMaintain}
        header={header()}
        content={content()}
        tabs={renderTabs()}
      />
    </>
  );
};

export default withPermissions("SpecialtyProfileMaintain", "OrgUnitMaintain", "ReferralFormProfileView")(OrgUnitOverViewPage);
