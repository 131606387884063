import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import * as Yup from "yup";
import { isEmpty } from "lodash";
import moment from "moment-timezone";

import Form from "components/form";
import { DatePicker, UserSelector, NoteInput, SelectInput } from "components/inputs";
import { addReferralTask, editReferralTask, removeReferralTask } from "app/main/referrals/actions/referrals.actions";
import { getAssignToTypes } from "app/auth/store/reducers/system-configuration";
import SpecialtyRoleSelector from "app/main/specialtyProfiles/components/specialty-role-selector";

const dueDate = "Due Date";
const description = "Task";
const assignedToUser = "Assigned User";
const assignedToRole = "Assigned Role";
const assignedToType = "Assigned to";

const schema = Yup.object().shape({
  task: Yup.object().shape({
    isComplete: Yup.bool().default(false).nullable(),
    description: Yup.string().trim()
      .required("Please enter details of the task")
      .max(1000, `${description} exceeds maximum length of 1000 characters`)
      .nullable(),
    dueDate: Yup.date().default(null).nullable(),
    assignedToType: Yup.string().nullable(),
    assignedToUserId: Yup.string()
      .when("assignedToType", {
        is: val => val === "User",
        then: Yup.string()
          .required(`${assignedToUser} is required`),
      }).nullable(),
    assignedToRoleId: Yup.string()
      .when("assignedToType", {
        is: val => val === "Role",
        then: Yup.string()
          .required(`${assignedToRole} is required`),
      }).nullable(),
  }),
});

const ReferralTaskForm = ({
  referralId,
  orgUnitId,
  currentTask,
  onSucceed,
  onRemoveTask,
  canDeleteTask,
  specialtyId,
  ...other
}) => {
  const dispatch = useDispatch();
  const options = useSelector(getAssignToTypes);
  const handleSubmit = ({ task }, { setSubmitting, setErrors }) => {
    const apiCall = currentTask ? editReferralTask : addReferralTask;

    const newTask = {
      ...task,
      assignedToUserId: task.assignedToUserId?.value ?? task.assignedToUserId,
      assignedToRoleId: task.assignedToRoleId?.value ?? task.assignedToRoleId,
      assignedToType: task.assignedToType?.value ?? task.assignedToType,
      assignedTo: task.assignedToUserId?.label ?? task.assignedTo,
      assignedToRole: task.assignedToRoleId?.label ?? task.assignedToRole,
      timeZone: moment.tz.guess(),
    };

    dispatch(apiCall(orgUnitId, referralId, newTask)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const handleRemove = ({ setErrors }) => {
    dispatch(removeReferralTask(orgUnitId, referralId, currentTask.referralTaskId)).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  let { task } = schema.cast();

  if (!isEmpty(currentTask)) {
    task = currentTask;
  }

  return (
    <Form
      initialValues={{ task }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      contentProps={other}
      onRemove={canDeleteTask ? formProps => handleRemove(formProps) : null}
      variant="filled"
      content={({ setFieldValue, values }) => (
        <>
          <Field
            name="task.description"
            component={NoteInput}
            label="Task"
            required
            autoFocus
          />
          <Field
            name="task.dueDate"
            component={DatePicker}
            label={dueDate}
          />
          <Field
            name="task.assignedToType"
            component={SelectInput}
            options={options}
            label={assignedToType}
          />
          {values.task.assignedToType === "User" && (
            <Field
              name="task.assignedToUserId"
              component={UserSelector}
              label={assignedToUser}
              required
              onChange={option => {
                setFieldValue("task.assignedTo", option?.label ?? "");
                if (option) {
                  setFieldValue("task.assignedToRole", null);
                  setFieldValue("task.assignedToRoleId", null);
                }
              }}
            />
          )}
          {values.task.assignedToType === "Role" && (
            <Field
              name="task.assignedToRoleId"
              label={assignedToRole}
              required
              component={SpecialtyRoleSelector}
              orgUnitId={orgUnitId}
              specialtyIds={!isEmpty(specialtyId) ? [specialtyId] : []}
            />
          )}
        </>
      )}
    />
  );
};

export default ReferralTaskForm;
