import { get, map, mapKeys, filter, concat, orderBy, isEmpty, mapValues, find, isObject } from "lodash";
import curryConnector from "utils/connectors";
import { ActionTypes as WorkListActionTypes } from "app/main/worklist/reducers/worklist.reducers";
import { ActionTypes as EncounterActionTypes } from "app/main/patients/reducers/encounters.reducers";
import { ActionTypes as ContactsActionTypes } from "app/main/patients/reducers/contacts.reducers";
import { ActionTypes as ReferralActionTypes } from "app/main/referrals/reducers/referrals.reducers";

export const PATIENTS_STATE_KEY = "patients";
export const PATIENTS_PAGE_SIZE = 50;
export const PATIENTS_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

const curry = fn => curryConnector(fn, PATIENTS_STATE_KEY);

export const ActionTypes = {
  LOADING_PATIENTS: "LOADING_PATIENTS",
  LOADED_PATIENTS: "LOADED_PATIENTS",
  ERROR_LOADING_PATIENTS: "ERROR_LOADING_PATIENTS",

  LOADING_CURRENT_PATIENT: "LOADING_CURRENT_PATIENT",
  LOADED_CURRENT_PATIENT: "LOADED_CURRENT_PATIENT",
  ERROR_LOADING_CURRENT_PATIENT: "ERROR_LOADING_CURRENT_PATIENT",
  RESET_CURRENT_PATIENT: "RESET_CURRENT_PATIENT",

  SAVED_PATIENT: "SAVED_PATIENT",
  CREATED_PATIENT: "CREATED_PATIENT",

  SET_CURRENT_DISEASE_STATE: "SET_CURRENT_DISEASE_STATE",
  DELETED_DISEASE_STATE: "DELETED_DISEASE_STATE",
  SAVED_DISEASE_STATE: "SAVED_DISEASE_STATE",

  UPDATED_NEXT_OF_KIN: "UPDATED_NEXT_OF_KIN",
  CREATED_NEXT_OF_KIN: "CREATED_NEXT_OF_KIN",

  SAVED_ENCOUNTER: "SAVED_ENCOUNTER",
  CREATED_ENCOUNTER: "CREATED_ENCOUNTER",
  SAVED_PATIENT_ASSIGNED_USER: "SAVED_PATIENT_ASSIGNED_USER",
  WITHDRAWN_ENROLMENT: "WITHDRAWN_ENROLMENT",
  SAVED_ENROLMENT: "SAVED_ENROLMENT",
  SAVED_PATIENT_IDENTIFIER: "SAVED_PATIENT_IDENTIFIER",
  DELETED_PATIENT_IDENTIFIER: "DELETED_PATIENT_IDENTIFIER",
  UPDATED_PATIENT_IDENTIFIER: "UPDATED_PATIENT_IDENTIFIER",

  SAVED_PATIENT_CARD: "SAVED_PATIENT_CARD",
  DELETED_PATIENT_CARD: "DELETED_PATIENT_CARD",
};

const INITIAL_STATE = {
  all: {},
  pages: {},
  pageInfo: { pageNumber: 1, pageSize: PATIENTS_PAGE_SIZE, totalRecords: 0 },
  searchParams: { name: null },
  current: {},
  patientsForworkList: [],
  summary: {},
  currentDiseaseState: {},
  meta: {
    current: {
      loading: false,
      loaded: false,
      error: null,
    },
  },
};

export const normalizePatient = patient => {
  let normalizedPatient = patient;
  if (!isEmpty(patient.contactDetails)) {
    normalizedPatient = {
      ...normalizedPatient,
      phoneHome: patient.contactDetails.phoneHome,
      phoneMobile: patient.contactDetails.phoneMobile,
      phoneWork: patient.contactDetails.phoneWork,
      email: patient.contactDetails.email,
      preferredContactMethod: patient.contactDetails.preferredContactMethod,
    };
  }

  if (!isEmpty(patient.address) && isObject(patient.address)) {
    normalizedPatient = {
      ...normalizedPatient,
      street: patient.address.street ?? null,
      suburb: patient.address.suburb ?? null,
      state: patient.address.state ?? null,
      city: patient.address.city ?? null,
      postcode: patient.address.postcode ?? null,
    };
  }

  if (!isEmpty(patient.postalAddress) && isObject(patient.postalAddress)) {
    normalizedPatient = {
      ...normalizedPatient,
      postalStreet: patient.postalAddress.street ?? null,
      postalSuburb: patient.postalAddress.suburb ?? null,
      postalState: patient.postalAddress.state ?? null,
      postalCity: patient.postalAddress.city ?? null,
      postalPostcode: patient.postalAddress.postcode ?? null,
    };
  }

  if (isEmpty(patient.medicareCard)) {
    normalizedPatient = {
      ...normalizedPatient,
      medicareCardExpiryDate: null,
    };
  }

  return normalizedPatient;
};

const updatePatientEntity = (state, entity, id, data) => {
  const allPatients = mapValues(state.all, patient => {
    if (patient.patientId === data.patientId) {
      return {
        ...patient,
        [entity]: concat(filter(patient[entity], x => x[id] !== data[id]), data),
      };
    }
    return patient;
  });

  return {
    ...state,
    all: allPatients,
    current: {
      ...state.current,
      medicareCard: data?.patientCardTypeCode === "MC" ? data.patientCard : state.current.medicareCard,
      medicareExpiryDate: data?.patientCardTypeCode === "MC" ? data.expiryDate : state.current.medicareExpiryDate,
      [entity]: concat(filter(state.current[entity], x => x[id] !== data[id]), data),
    },
  };
};

const removePatientEntity = (state, entity, id, patientId, removeId, data) => {
  const allPatients = mapValues(state.all, patient => {
    if (patient.patientId === patientId) {
      return {
        ...patient,
        [entity]: filter(patient[entity], x => x[id] !== removeId),
      };
    }
    return patient;
  });

  return {
    ...state,
    all: allPatients,
    current: {
      ...state.current,
      medicareCard: data?.patientCardTypeCode === "MC" ? null : state.current.medicareCard,
      medicareExpiryDate: data?.patientCardTypeCode === "MC" ? null : state.current.medicareExpiryDate,
      [entity]: filter(state.current[entity], x => x[id] !== removeId),
    },
  };
};

function removeNextOfKin(state, action) {
  let { nextOfKin } = state.current;

  // check if the contact being removed is the patients NOK
  if (nextOfKin?.id === action.payload?.contact.id) {
    nextOfKin = null;
  }
  return {
    ...state,
    current: {
      ...state.current,
      nextOfKin,
    },
  };
}

function updateNextOfKin(state, contact, patientId) {
  if (patientId !== state.current.patientId) return state;
  let { nextOfKin } = state.current;

  // The NOK has been changed to a different relationshipType
  if (nextOfKin?.id === contact.id && contact.relationshipType.value !== "NextOfKin") {
    nextOfKin = null;
  } else if (contact.relationshipType.value === "NextOfKin") {
    nextOfKin = contact;
  }

  return {
    ...state,
    current: {
      ...state.current,
      nextOfKin,
    },
  };
}

function updatePatientEnrolments(state, enrolment) {
  if (enrolment === null) return state;

  const { patientId, id } = enrolment;
  let enrolments = get(state.summary, [patientId, "enrolments"], []);
  const existingEnrolment = find(enrolments, x => x.id === id);

  if (isEmpty(existingEnrolment)) {
    enrolments = concat(filter(enrolments, x => x.id !== id), enrolment);
  } else {
    enrolments = map(enrolments, item => {
      if (item.id === id) {
        return enrolment;
      }
      return item;
    });
  }

  return {
    ...state,
    summary: { ...state.summary, [patientId]: { ...state.summary[patientId], enrolments } },
  };
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_PATIENTS:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: true, error: false } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.ERROR_LOADING_PATIENTS:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: action.payload.message } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.LOADED_PATIENTS:
      return {
        ...state,
        all: { ...state.all, ...mapKeys(action.payload.patients, x => x.patientId) },
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: false, loaded: true, ids: map(action.payload.patients, x => x.patientId) } },
        pageInfo: action.payload.pageInfo,
        searchParams: action.payload.searchParams,
      };
    case WorkListActionTypes.SEARCHING_PATIENTS:
      return {
        ...state,
        patientsForworkList: (action.payload.pageNumber === 1) ? [] : state.patientsForworkList,
      };
    case WorkListActionTypes.SEARCHED_PATIENTS:
      return {
        ...state,
        all: { ...state.all, ...mapKeys(action.payload.patients, x => x.patientId) },
        patientsForworkList: (action.payload.pageNumber === 1) ? map(action.payload.patients, x => x.patientId) : concat(state.patientsForworkList, map(action.payload.patients, x => x.patientId)),
        summary: { ...state.summary,
          ...mapKeys(map(action.payload.patientSummaries, summary => ({
            patientId: summary.patientId,
            enrolments: summary.enrolments,
            diseaseStates: orderBy(summary.diseaseStates, [e => e.status || ""], ["asc"]),
          })), x => x.patientId) },
      };
    case ActionTypes.LOADING_CURRENT_PATIENT:
      return {
        ...state,
        meta: {
          ...state.meta,
          current: {
            ...state.meta.current,
            loading: true,
          },
        },
      };
    case ActionTypes.LOADED_CURRENT_PATIENT:
      return {
        ...state,
        current: action.payload.patient,
        meta: {
          ...state.meta,
          current: {
            ...state.meta.current,
            loading: false,
            loaded: true,
            error: null,
          },
        },
        all: { ...state.all, [action.payload.patient.patientId]: action.payload.patient },
        summary: action.payload.summary ? {
          ...state.summary,
          [action.payload.summary.patientId]: {
            patientId: action.payload.summary.patientId,
            enrolments: action.payload.summary.enrolments,
            diseaseStates: orderBy(action.payload.summary.diseaseStates, [e => e.status || ""], ["asc"]),
          },
        } : state.summary,
      };
    case ActionTypes.SAVED_PATIENT_ASSIGNED_USER:
      return updatePatientEnrolments(state, action.payload.enrolment);
    case ActionTypes.WITHDRAWN_ENROLMENT:
    case ActionTypes.SAVED_ENROLMENT:
      return updatePatientEnrolments(state, action.payload.enrolment);
    case ActionTypes.ERROR_LOADING_CURRENT_PATIENT:
      return {
        ...state,
        meta: {
          ...state.meta,
          current: {
            loading: false,
            loaded: false,
            error: action.payload.message,
          },
        },
      };
    case ActionTypes.RESET_CURRENT_PATIENT:
      return {
        ...state,
        current: INITIAL_STATE.current,
        meta: {
          ...state.meta,
          current: INITIAL_STATE.meta.current,
        },
      };
    case EncounterActionTypes.SAVED_ENCOUNTER:
    case EncounterActionTypes.CREATED_ENCOUNTER:
      return updatePatientEnrolments(state, action.payload.enrolment);
    case ReferralActionTypes.CREATE_MANUAL_REFERRAL:
    case ActionTypes.SAVED_PATIENT:
    case ActionTypes.CREATED_PATIENT:
      return {
        ...state,
        current: action.payload.patient,
        all: { ...state.all, [action.payload.patient.patientId]: action.payload.patient },
      };
    case ActionTypes.SET_CURRENT_DISEASE_STATE:
      return {
        ...state,
        currentDiseaseState: action.payload.diseaseState,
      };
    case ActionTypes.SAVED_DISEASE_STATE:
      return {
        ...state,
        currentDiseaseState: action.payload.diseaseState,
        summary: { ...state.summary,
          [action.payload.patientId]: {
            ...state.summary[action.payload.patientId],
            diseaseStates: orderBy(concat(filter(state.summary[action.payload.patientId].diseaseStates, x => x.id !== action.payload.diseaseState.id), action.payload.diseaseState), [e => e.status || ""], ["asc"]),
          } },
      };
    case ActionTypes.DELETED_DISEASE_STATE:
      return {
        ...state,
        currentDiseaseState: action.payload.diseaseState,
        summary: { ...state.summary,
          [action.payload.patientId]: {
            ...state.summary[action.payload.patientId],
            diseaseStates: filter(state.summary[action.payload.patientId].diseaseStates, x => x.id !== action.payload.diseaseState.id),
          } },
      };
    case ContactsActionTypes.UPDATED_CONTACT:
      return updateNextOfKin(state, action.payload.contact, action.payload.patientId); // TODo
    case ActionTypes.UPDATED_NEXT_OF_KIN:
    case ActionTypes.CREATED_NEXT_OF_KIN:
      return {
        ...state,
        current: {
          ...state.current,
          nextOfKin: action.payload.contact,
        },
      };
    case ContactsActionTypes.DELETED_CONTACT:
      return removeNextOfKin(state, action);
    case ReferralActionTypes.SAVED_REFERRAL_ASSIGNED_USER:
    case ActionTypes.SAVED_PATIENT_IDENTIFIER:
    case ActionTypes.UPDATED_PATIENT_IDENTIFIER:
      return updatePatientEntity(state, "patientIdentifiers", "patientIdentifierId", action.payload.patientIdentifier ?? action.payload);
    case ActionTypes.DELETED_PATIENT_IDENTIFIER:
      return removePatientEntity(state, "patientIdentifiers", "patientIdentifierId", action.payload.patientId, action.payload.patientIdentifier.patientIdentifierId);
    case ActionTypes.SAVED_PATIENT_CARD:
      return updatePatientEntity(state, "patientCards", "patientCardId", action.payload.patientCard);
    case ActionTypes.DELETED_PATIENT_CARD:
      return removePatientEntity(state, "patientCards", "patientCardId", action.payload.patientId, action.payload.patientCard.patientCardId, action.payload.patientCard);
    default:
      return state;
  }
};

export const getAllPatients = curry(({ localState }) => {
  const patients = get(localState, ["all"], {});
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return map(get(localState.pages, [pageNumber, "ids"], []), key => patients[key]);
});

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});

export const getErrorMessage = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "error"], null);
});

export const getCurrentPatient = curry(({ localState }) => get(localState, ["current"], {}));

export const isCurrentPatientLoading = curry(({ localState }) => localState.meta.current.loading);

export const isCurrentPatientLoaded = curry(({ localState }) => localState.meta.current.loaded);

export const getPatientSearchTerms = curry(({ localState }) => localState.searchParams);

export const getPageInfo = curry(({ localState }) => localState.pageInfo);

export const getPatientById = curry(({ localState }, patientId) => get(localState, ["all", patientId], null));

export const getPatientsForWorklist = curry(({ localState }) => {
  const patients = get(localState, ["all"], {});
  const patientSummaries = get(localState, ["summary"], {});
  const patientsForworkList = map(localState.patientsForworkList, key => ({ patient: patients[key], patientSummaries: patientSummaries[key] }));

  return patientsForworkList;
});

export const getCurrentDiseaseState = curry(({ localState }) => localState.currentDiseaseState);

export const hasPrimaryDiseaseState = curry(({ localState }, patientId) => {
  const patientSummary = get(localState, ["summary", patientId], {});
  return !isEmpty(filter(patientSummary.diseaseStates, x => x.status === "Primary"));
});

export const getPatientsSummaryById = curry(({ localState }, patientId) => get(localState, ["summary", patientId], {}));

export const getPatientEnrolmentsById = curry(({ localState }, patientId) => {
  const patientSummary = get(localState, ["summary", patientId], {});
  const enrolments = get(patientSummary, "enrolments", null);
  return enrolments;
});

export const getPatientEnrolmentOptions = curry(({ localState }, patientId, enrolmentTypes) => {
  const patientSummary = get(localState, ["summary", patientId], {});
  const enrolments = get(patientSummary, "enrolments", {});

  // only return options for the programs that the patient is enroled in
  const enrolmentOptions = filter(enrolmentTypes, type => find(enrolments, x => x.enrolmentType.value === type.value));

  return enrolmentOptions;
});

export const getCurrentPatientNextOfKin = curry(({ localState }) => localState.current.nextOfKin);
