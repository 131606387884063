import React from "react";
import { useSelector } from "react-redux";
import { Box } from "@material-ui/core";
import { find } from "lodash";
import { blue } from "@material-ui/core/colors";

import ReferralCategoryLabel from "app/main/referrals/components/referral-category-label";
import { getReferralActionTypeSettings } from "app/auth/store/reducers/system-configuration";
import { getAllCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";

const useActionTypeItem = ({
  orgUnitId,
  activity,
}) => {
  const referralActionTypeSettings = useSelector(getReferralActionTypeSettings);

  const status = find(referralActionTypeSettings, x => x.actionType === activity.actionType) || { icon: "lens", color: blue[600], label: activity.actionType };
  const codeSetValues = useSelector(getAllCodeSetValues);
  const getDisplayCodeSetValue = codeSetValueId => find(codeSetValues, x => x.id === codeSetValueId)?.description;

  const title = () => (
    <div className="flex-row-container with-gutter">
      <Box color={status.color} fontWeight="bold" mr={1}>{status.actionedName}</Box>
      {activity.triageCategory
        && <ReferralCategoryLabel triageCategory={activity.triageCategory} isUrgent={activity.isUrgent} showUrgentIcon={false} orgUnitId={orgUnitId} />}
    </div>
  );

  return ({
    title: title(),
    popupTitle: title(),
    getDisplayCodeSetValue,
    status,
  });
};

export default useActionTypeItem;
