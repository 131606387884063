import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import AccountCancelOutlineIcon from "mdi-react/AccountCancelOutlineIcon";
import AccountReactivateOutlineIcon from "mdi-react/AccountReactivateOutlineIcon";

import loadSystemConfiguration from "app/auth/store/actions/system-configuration";
import { isSystemConfigurationLoading } from "app/auth/store/reducers/system-configuration";
import AzureLoginPage from "app/main/login/azure-login-page";
import { isCurrentUserLoading } from "app/auth/store/reducers/user.reducer";
import LoadingState from "components/items/loading-state";
import EmptyState from "components/items/empty-state";
import DefaultButton from "components/items/default-button";

import { useIsAuthenticated, useMsal, useMsalAuthentication } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { loadCurrentUser } from "./store/actions/user.actions";
import { isCurrentUserLoaded, isCurrentUserLoadingError, getAutError } from "./store/reducers/user.reducer";
import { msalScopes } from "./msalConfig";

const ContainerComponent = ({ children, withPaper }) => {
  const content = () => (withPaper
    ? (
      <Paper className="flex justify-center w-1\/2 sm:w-320 p-24">
        {children}
      </Paper>
    ) : children);

  return (
    <div className="h-full flex items-center justify-center">
      {content()}
    </div>
  );
};

const Auth = ({
  children,
}) => {
  const dispatch = useDispatch();

  const shouldLoginAutomatically = process.env.REACT_APP_LOGIN_AUTOMATICALLY === "true";
  const isUserLoaded = useSelector(isCurrentUserLoaded);
  const isAuthenticated = useIsAuthenticated();
  const currentUserIsLoading = useSelector(isCurrentUserLoading);
  const isLoadingSystemConfig = useSelector(isSystemConfigurationLoading);
  const errorLoadingCurrentUser = useSelector(isCurrentUserLoadingError);
  // This is set if an API request fails due to 401 Unauthorized.
  const authError = useSelector(getAutError);
  const { instance, inProgress } = useMsal();

  const loadUser = () => {
    dispatch(loadCurrentUser()).then(res => {
      // should not load system config if there is issue loading current user
      if (res?.error) return;
      dispatch(loadSystemConfiguration());
    });
  };

  const { error: msalError, login } = useMsalAuthentication(
    shouldLoginAutomatically
      ? InteractionType.Redirect
      : InteractionType.Silent,
    { scopes: msalScopes },
  );

  useEffect(() => {
    if (isAuthenticated && !isUserLoaded) {
      loadUser();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoaded, isAuthenticated]);

  if (currentUserIsLoading || isLoadingSystemConfig || inProgress === "handleRedirect" || inProgress === "logout") {
    return <ContainerComponent><LoadingState /></ContainerComponent>;
  }

  if (!isEmpty(authError) || !isEmpty(errorLoadingCurrentUser)) {
    return (
      <ContainerComponent withPaper>
        <EmptyState
          title="Something went wrong!"
          // we won't display the error message comes back from the res
          // as its not useful for user
          content={(
            <>
              <Typography color="textSecondary" className="text-center">{authError}</Typography>
              {isAuthenticated && (
                <div className="mt-24">
                  <DefaultButton
                    onClick={() => instance.logoutRedirect({
                      postLogoutRedirectUri: "/",
                    })}
                    loading={inProgress === "logout"}
                    label="Log Out"
                    size="large"
                  />
                </div>
              )}
            </>
          )}
          onClick={loadUser}
          icon={!isEmpty(authError) ? <AccountCancelOutlineIcon /> : <AccountReactivateOutlineIcon />}
        />
      </ContainerComponent>
    );
  }

  if (isAuthenticated) {
    return children;
  }

  return <AzureLoginPage error={msalError} login={login} inProgress={inProgress} />;
};

export default Auth;
