import React, { useEffect, useState } from "react";
import moment from "moment";
import { DatePicker, DateTimePicker } from "components/inputs";
import OCRBaseComponent from "components/ocr-components/ocr-based-component";

export const DatePickerField = ({
  ...other
}) => {
  const { field: { name }, ocrValue } = other;
  const [formattedValue, setFormattedValue] = useState(null);
  const isValidFormat = formattedValue?.isValid();

  useEffect(() => {
    if (ocrValue) {
      const dateValue = moment(ocrValue);
      setFormattedValue(dateValue);
    }
  }, [ocrValue]);

  const handleConfirmed = (isAccept, change) => {
    if (isAccept && isValidFormat) {
      change(name, formattedValue);
    } else {
      change(name, null);
    }
  };

  return (
    <OCRBaseComponent
      inputComponent={DatePicker}
      onHandleConfirmed={handleConfirmed}
      isValidFormat={isValidFormat}
      formatValue={isValidFormat ? formattedValue : null}
      requireValidFormat
      {...other}
    />
  );
};

export const DateTimePickerField = ({
  ...other
}) => {
  const { field: { name }, ocrValue } = other;
  const [formattedValue, setFormattedValue] = useState(null);
  const isValidFormat = formattedValue?.isValid();

  useEffect(() => {
    if (ocrValue) {
      const dateTimeValue = moment(ocrValue);
      if (dateTimeValue.isValid()) {
        setFormattedValue(dateTimeValue);
      } else {
        const dateValue = moment(ocrValue);
        setFormattedValue(dateValue);
      }
    }
  }, [ocrValue]);

  const handleConfirmed = (isAccept, change) => {
    if (isAccept && isValidFormat) {
      change(name, formattedValue);
    } else {
      change(name, null);
    }
  };

  return (
    <OCRBaseComponent
      inputComponent={DateTimePicker}
      onHandleConfirmed={handleConfirmed}
      isValidFormat={isValidFormat}
      ocrLabel={ocrValue}
      formatValue={isValidFormat ? formattedValue : null}
      requireValidFormat
      {...other}
    />
  );
};
