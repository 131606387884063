import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ButtonBase } from "@material-ui/core";
import { map, join, find, isEmpty } from "lodash";

import { getCodeSetValuesByTypeCode } from "app/main/codeSet/reducers/codeSet.reducers";
import { TextInput } from "components/inputs";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import FormContext from "components/FormContext";

import SpecialtyCodeSetForm from "./specialty-code-set-form";

const SpecialtyMultipleCodeSetInput = ({
  label,
  codeSetTypeCode,
  disabled,
  ...other
}) => {
  const dispatch = useDispatch();
  const codeSetType = useSelector(state => getCodeSetValuesByTypeCode(state, codeSetTypeCode));
  const codeSetTypeCodeOptions = codeSetType?.codeSetValues ?? [];

  const { field: { name, value }, form: { setFieldValue } } = other;
  const [focused, setFocused] = useState(value);
  const [inputValue, setInputValue] = useState("");
  const [selectedOptions, setSelectOptions] = useState([]);

  const getInputValue = values => values && join(map(values, x => x?.description), ", ");

  useEffect(() => {
    if (value) {
      const existingValues = map(value, x => (find(codeSetTypeCodeOptions, option => option.id === x)));
      setSelectOptions(existingValues);
      setInputValue(getInputValue(existingValues));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeSetType]);

  useEffect(() => {
    if (isEmpty(inputValue)) {
      setFocused(false);
    } else {
      setFocused(true);
    }
  }, [inputValue]);

  const handleClose = () => dispatch(closeDialog());

  const handleSucceed = newValues => {
    // save specialtyCodeSetValues (ids) to SpecialtyProfileForm
    setFieldValue(name, map(newValues, x => x.id));
    // save to the display value -> text input below
    setInputValue(getInputValue(newValues));
    // save to local state, in case user save the code set form but then open the form again before save specialty profile
    setSelectOptions(newValues);
    // close dialog
    handleClose();
  };

  const handleClick = () => {
    setFocused(true);
    dispatch(openDialog({
      maxWidth: "md",
      children: (
        <SpecialtyCodeSetForm
          label={label}
          onSucceed={handleSucceed}
          onCancel={handleClose}
          options={codeSetTypeCodeOptions}
          currentValues={selectedOptions}
        />
      ),
    }));
  };

  const renderTextInput = isDisabled => (
    <TextInput
      disabled={isDisabled}
      {...other}
      label={label}
      name={name}
      labelProps={{ shrink: focused }}
      value={inputValue}
    />
  );

  return (
    <FormContext.Consumer>
      {({ formDisabled }) => {
        const isDisabled = disabled || formDisabled;

        if (isDisabled) {
          return renderTextInput(true);
        }

        return (
          <ButtonBase
            className="w-full"
            disableRipple
            onClick={handleClick}
          >
            {renderTextInput()}
          </ButtonBase>
        );
      }}
    </FormContext.Consumer>
  );
};

export default SpecialtyMultipleCodeSetInput;
