import React, { useState } from "react";
import { isEmpty } from "lodash";
import DefaultButton from "components/items/default-button";

const SubmitButton = ({ label, onClick, ...formProps }) => {
  const [clicked, SetClicked] = useState(false);

  return (
    <DefaultButton
      label={label}
      loading={formProps.isSubmitting && isEmpty(formProps.errors) && clicked}
      size="medium"
      disabled={formProps.isSubmitting}
      onClick={() => {
        SetClicked(true);
        if (onClick) {
          onClick();
        }
        formProps.handleSubmit();
      }}
    />
  );
};

export default SubmitButton;
