import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import ApplicationRemoveButton from "app/main/applicationWorklist/components/application-remove-button";
import AttachmentIcon from "components/items/attachment/attachment-icon";

const useStyles = makeStyles(theme => ({
  titleContent: {
    flexShrink: 1,
  },
  itemContent: {
    borderWidth: 2,
    borderRadius: 2,
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#777",
    outline: "none",
    transition: "border .24s ease-in-out",
    justifyContent: "center",
    cursor: "pointer",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    width: 200,
  },
  actions: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: `${theme.spacing(1)}px`,
  },
}));

const ReferralDocumentAttachmentItem = ({
  id,
  mimeType,
  children,
  title,
  subtitle,
  onRemove,
  canRemove,
  isFirst,
  renderActions,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    removingId: null,
  });

  const removeButton = () => (
    <ApplicationRemoveButton
      loading={state.removingId === id}
      onClick={() => {
        setState(prevState => ({ ...prevState, removingId: id }));
        Promise.resolve(onRemove()).then(() => setState(prevState => ({ ...prevState, removingId: null })));
      }}
      edge="end"
    />
  );

  return (
    <div className={clsx(!isFirst && "flex", classes.itemContent)}>
      <div className="flex-auto">
        <div className="flex-row-container justify-between items-center">
          <AttachmentIcon className="w-40" type={mimeType || "link"} size={32} />
          <div className={classes.actions}>
            {canRemove && onRemove && removeButton()}
            {renderActions && renderActions}
          </div>
        </div>

        <div className={classes.titleContent}>
          {title}
          {subtitle}
        </div>

        {children && <div className="mt-8">{children}</div>}
      </div>
    </div>
  );
};

export default ReferralDocumentAttachmentItem;
