import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "formik";
import { isEmpty, map, head } from "lodash";
import * as Yup from "yup";

import Form from "components/form";
import { TextInput, CheckboxInput } from "components/inputs";
import { getEnableMultiFormularyCKMSDrugSearch, getOrgUnitSettings, getEnableCKMSDrugSearch } from "app/auth/store/reducers/system-configuration";
import { addOrgUnit, editOrgUnit } from "app/main/orgUnits/actions/orgUnit.actions";
import OrgUnitSelector from "app/main/orgUnits/components/orgUnit-selector";
import SubFormularySelector from "app/main/applications/components/subformulary-selector";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";

const formularySchema = Yup.object().shape({
  formularyId: Yup.string().nullable(),
  formularyName: Yup.string().nullable(),
});

const getSchema = multiFormularyCKMSDrugSearch => Yup.object().shape({
  orgUnit: Yup.object().shape({
    name: Yup.string()
      .max(200, "Too Long!")
      .trim().required("Name is required")
      .nullable(),
    parentOrgUnitId: Yup.string().required("Parent is required").nullable(),
    externalIdentifier: Yup.string()
      .max(200, "Too Long!")
      .nullable(),
    // Main 
    // subFormularyName: Yup.string()
    //   .nullable(),
    // subFormularyId: Yup.string()
    //   .nullable(),
    // drugSearchDisabled: Yup.bool().default(false)
    // dev waipa
    formularies: multiFormularyCKMSDrugSearch ? Yup.array().of(formularySchema).nullable() : formularySchema.nullable(),
    drugSearchDisabled: Yup.bool()
      .nullable(),
  }),
});

const OrgUnitForm = ({
  disabled,
  onSucceed,
  orgUnitType,
  ...other
}) => {
  const dispatch = useDispatch();
  const multiFormularyCKMSDrugSearch = useSelector(state => getEnableMultiFormularyCKMSDrugSearch(state));

  const orgUnitsSettings = useSelector(state => getOrgUnitSettings(state, other.orgUnit?.orgUnitType));
  const parentOrgUnitType = orgUnitsSettings?.parentOrgUnitType;

  const parentOrgUnitTypeSettings = useSelector(state => getOrgUnitSettings(state, parentOrgUnitType));
  const parentOrgUnitLabel = parentOrgUnitTypeSettings?.nameSingle ?? parentOrgUnitType;

  const enableCKMSDrugSearch = useSelector(getEnableCKMSDrugSearch);

  const nameLabel = "Name";
  const externalIdentifierLabel = "External Identifier";
  const drugSearchDisableLabel = "Disable Formulary Medication Search";
  const enabledMedicationAccessProgramOrArrangementIdsLabel = "Enabled Medication Access Program Or Arrangement Values Options";
  const formularyListLabel = "Medication Search Formularies";

  const handleSubmit = ({ orgUnit }, { setSubmitting, setErrors }) => {
    const orgUnitData = {
      ...orgUnit,
    };

    if (!isEmpty(orgUnit.formularies)) {
      const valueArray = multiFormularyCKMSDrugSearch ? orgUnit.formularies : [].concat(orgUnit.formularies);
      orgUnitData.formularies = map(valueArray, x => ({
        formularyId: x.value,
        formularyName: x.label,
      }));
    }

    const apiCall = () => (orgUnitData?.id ? dispatch(editOrgUnit(orgUnitData?.id, orgUnitData)) : dispatch(addOrgUnit(orgUnitData?.parentOrgUnitId, orgUnitData)));

    apiCall().then(res => {
      setSubmitting(false);
      if (res.error !== true) {
        onSucceed(res.payload.orgUnit.id);
      } else {
        setErrors(res.payload);
      }
    });
  };

  let { orgUnit } = getSchema(multiFormularyCKMSDrugSearch).cast();
  orgUnit.formularies = null;
  if (!isEmpty(other.orgUnit)) {
    orgUnit = {
      ...other.orgUnit,
      drugSearchDisabled: orgUnitType === "Site" ? other.orgUnit.drugSearchDisabled ?? false : false,
    };
  }

  if (other.orgUnit?.formularies) {
    if (multiFormularyCKMSDrugSearch) {
      orgUnit.formularies = map(other.orgUnit?.formularies, formulary => ({
        value: `${formulary?.formularyId}`,
        label: formulary.formularyName,
      }));
    } else {
      const formulary = head(other.orgUnit?.formularies);
      orgUnit.formularies = !isEmpty(formulary)
        ? {
          value: `${formulary?.formularyId}`,
          label: formulary?.formularyName,
        } : null;
    }
  }

  return (
    <Form
      initialValues={{ orgUnit }}
      onSubmit={handleSubmit}
      disabled={disabled}
      contentProps={other}
      validationSchema={getSchema(multiFormularyCKMSDrugSearch)}
      isModal={false}
      content={() => (
        <>
          <Field
            name="orgUnit.name"
            component={TextInput}
            label={nameLabel}
            required
          />
          <Field
            name="orgUnit.parentOrgUnitId"
            label={parentOrgUnitLabel}
            orgUnitType={parentOrgUnitType}
            disabled
            component={OrgUnitSelector}
            onChange={(value, change) => {
              change("orgUnit.parentOrgUnitId", value?.value);
            }}
          />
          <Field
            name="orgUnit.externalIdentifier"
            component={TextInput}
            label={externalIdentifierLabel}
          />
          {orgUnitType === "Site" && enableCKMSDrugSearch && (
            <>
              <Field
                name="orgUnit.formularies"
                component={SubFormularySelector}
                label={formularyListLabel}
                orgUnitId={orgUnit?.id ?? orgUnit?.parentOrgUnitId}
                multiple={multiFormularyCKMSDrugSearch}
              />
              <Field
                name="orgUnit.drugSearchDisabled"
                component={CheckboxInput}
                label={drugSearchDisableLabel}
              />
              <Field
                name="orgUnit.enabledMedicationAccessProgramOrArrangementIds"
                component={CodeSetValueSelector}
                codeSetTypeCode="MedicationAccessProgramArrangement"
                multiple
                label={enabledMedicationAccessProgramOrArrangementIdsLabel}
              />
            </>
          )}
        </>
      )}
    />
  );
};

export default OrgUnitForm;
