import React from "react";
import { connect } from "react-redux";
import { Field } from "formik";

import { NoteInput } from "components/inputs/text-input";
import { archiveReferralAction } from "app/main/referrals/actions/referrals.actions";
import { validateMaxLength } from "utils/validators";
import Form from "components/form";
import ReferralWarningMessage from "./referral-warning-message-alert";

const messageLabel = "Message";
const validateMessage = validateMaxLength(messageLabel, 1000);

class ArchiveReferralForm extends React.PureComponent {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit = ({ referral }, { setErrors }) => {
    const archiveReferral = {
      ...referral,
      referralId: this.props.referralId,
    };
    this.props.archiveReferralAction(this.props.orgUnitId, archiveReferral).then(responds => {
      if (responds.error !== true) {
        this.props.onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  render() {
    const {
      warningMessages,
      ...other
    } = this.props;

    return (
      <Form
        contentProps={other}
        initialValues={{}}
        onSubmit={this.handleSubmit}
        variant="filled"
        content={() => (
          <>
            <ReferralWarningMessage warningMessages={warningMessages} />
            <Field
              name="referral.note"
              component={NoteInput}
              label={messageLabel}
              validate={validateMessage}
              autoFocus
            />
          </>
        )}
      />
    );
  }
}

export default connect(() => ({}), { archiveReferralAction })(ArchiveReferralForm);
