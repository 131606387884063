import ReferralCreatePage from "./pages/referral-create-page";

const CreateReferralWorklistConfig = {
  routes: [
    {
      title: "Create Referral Worklist",
      path: "/CreateReferral",
      component: ReferralCreatePage,
      exact: true,
      isIndexPage: true,
      routes: [
        {
          path: "/CreateReferral?patientIdentifier=:identifier",
          component: ReferralCreatePage,
          isIndexPage: true,
          exact: true,
        },
      ],
    },
  ],
};

export default CreateReferralWorklistConfig;
