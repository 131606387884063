import React from "react";
import { TimelineItem, TimelineOppositeContent, TimelineContent, TimelineConnector, TimelineSeparator, TimelineDot } from "@material-ui/lab";
import { Popover, Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isString, truncate } from "lodash";
import clsx from "clsx";
import IconComponent from "components/items/icon-component";
import PopupMenu from "components/items/popup-menu";

const breakpoint = "md";

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: 80,
    position: "relative",
    transition: theme.transitions.create("background", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.short,
    }),
    flexDirection: "column",
    "&.activeHover:hover": {
      backgroundColor: theme.palette.primary[50],
    },
    [theme.breakpoints.up(breakpoint)]: {
      flexDirection: "row",
    },
  },
  info: {
    width: "100%",
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "space-between",
    maxWidth: 128,
    flexWrap: "wrap",
    textAlign: "left",
    [theme.breakpoints.up(breakpoint)]: {
      flex: "0 0 auto",
      flexDirection: "column",
      justifyContent: "center",
      marginRight: theme.spacing(2),
      textAlign: "right",
    },
  },
  infoContent: ({ textColor }) => ({
    color: textColor,
    lineHeight: 1.5,
    fontSize: 12,
  }),
  indicator: ({ activeColor }) => ({
    borderColor: activeColor,
    marginTop: 0,
    marginBottom: 0,
  }),
  indicatorContainer: {
    position: "absolute",
    right: theme.spacing(2),
    [theme.breakpoints.up(breakpoint)]: {
      position: "relative",
    },
  },
  connector: {
    backgroundColor: theme.palette.divider,
  },
  contentContainer: {
    display: "flex",
    alignItems: "center",
  },
  popup: {
    width: 320,
    [theme.breakpoints.up(breakpoint)]: {
      width: 448,
    },
  },
}));

const ActivityLogItem = ({
  id,
  date,
  user,
  title,
  content,
  icon,
  textColor,
  iconColor,
  activeColor,
  renderPopupContent,
  onClick,
  classes,
  prefix,
  renderLeftContent,
  truncatedContent,
}) => {
  const activeHover = onClick || renderPopupContent;
  const styles = useStyles({ activeColor, textColor });

  const MainContent = props => (
    <div className={clsx(activeHover ? "cursor-pointer" : "cursor-default", "flex-auto", classes?.content)} role="presentation" {...props}>
      {title && <Box fontWeight="fontWeightBold" color={textColor}>{title}</Box>}
      {isString(content) ? <Typography variant="body2">{truncatedContent || truncate(content, { length: 150 })}</Typography> : content}
    </div>
  );

  const contentComponent = () => {
    if (renderPopupContent) {
      return (
        <PopupMenu
          id={id || "item-detail"}
          trigger={popupState => (
            <MainContent {...popupState} />
          )}
          content={({ close, ...other }) => (
            <Popover
              {...other}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              classes={{ paper: styles.popup }}
            >
              <div className="py-16 px-24">
                {renderPopupContent({ close })}
              </div>
            </Popover>
          )}
        />
      );
    }
    return <MainContent />;
  };

  return (
    <TimelineItem className={clsx(styles.root, activeHover && "activeHover", classes?.root)}>
      <TimelineOppositeContent className={clsx(styles.info, classes?.info)}>
        <Box className={styles.infoContent}>{date}</Box>
        <Box className={styles.infoContent}>{user}</Box>
        {renderLeftContent && renderLeftContent}
      </TimelineOppositeContent>
      <TimelineSeparator className={styles.indicatorContainer}>
        <TimelineConnector className={clsx(styles.connector, classes?.connector)} />
        <TimelineDot variant="outlined" className={clsx(styles.indicator, classes?.indicator)}>
          <IconComponent icon={icon} color={iconColor} />
        </TimelineDot>
        <TimelineConnector className={clsx(styles.connector, classes?.connector)} />
      </TimelineSeparator>
      <TimelineContent className={clsx(styles.contentContainer, classes?.contentContainer)}>
        {prefix && prefix}
        {contentComponent()}
      </TimelineContent>
    </TimelineItem>
  );
};

export default ActivityLogItem;
