import React from "react";
import { Autocomplete as MUIAutoComplete } from "@material-ui/lab";
import { createFilterOptions } from "@material-ui/lab/Autocomplete";
import { TextField, CircularProgress, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, map, join } from "lodash";

import checkIfEmpty from "helpers/check-if-empty";
import { textFieldHook } from "components/inputs/components/useInput";
import BaseInputField from "components/inputs/components/base-input-component";
import DisabledInput from "components/inputs/components/disabled-input";
import HelperTextComponent from "components/inputs/components/helper-text-component";

const useStyles = makeStyles(theme => ({
  loader: {
    position: "absolute",
    height: "100%",
    top: 0,
    right: 56,
    display: "flex",
    alignItems: "center",
  },
  listbox: {
    "& > *:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
}));

const AutoCompleteInput = ({
  error,
  touched,
  label,
  loading,
  required,
  value,
  options,
  multiple,
  blurOnSelect = !multiple,
  disableCloseOnSelect = multiple,
  icon,
  helperText,
  multiline,
  disabled,
  showIcon,
  autoFocus,
  withDivider,
  variant,
  filterOption,
  showHelperText,
  disabledEmptyValue,
  ...other
}) => {
  const classes = useStyles();
  const clearButtonStyles = textFieldHook.useClearButtonStyles();
  const inputStyles = textFieldHook.useFilledInputStyles();

  const filledInputProps = variant === "filled" && {
    disableUnderline: true,
    classes: inputStyles,
  };

  if (disabled) {
    let disabledValue = value?.label ?? value;
    if (multiple) {
      disabledValue = join(map(value, x => x.label), ", ");
    }
    return (
      <BaseInputField icon={icon} multiline={multiline} classes={{ root: "flex-1" }} showHelperText={showHelperText}>
        {loading ? <div className={classes.loader}><CircularProgress color="inherit" size={20} /></div> : <DisabledInput label={label} value={disabledValue} disabledEmptyValue={disabledEmptyValue}/>}
      </BaseInputField>
    );
  }

  return (
    <MUIAutoComplete
      loading={loading}
      value={value}
      options={options}
      blurOnSelect={blurOnSelect}
      multiple={multiple}
      disableCloseOnSelect={disableCloseOnSelect}
      filterOptions={!isEmpty(filterOption) ? createFilterOptions(filterOption) : undefined}
      classes={{ clearIndicator: clearButtonStyles.root, root: "select-none", listbox: withDivider ? classes.listbox : "" }}
      renderInput={params => (
        <BaseInputField
          error={!isEmpty(error)}
          icon={icon}
          multiline={multiline}
          showIcon={showIcon}
          showHelperText={showHelperText}
          helperText={(
            <HelperTextComponent
              showError={touched && error}
              error={error}
              helperText={helperText}
            />
          )}
        >
          <TextField
            {...params}
            label={label}
            required={required && checkIfEmpty(value)}
            error={touched && !isEmpty(error)}
            autoFocus={autoFocus}
            variant={variant === "filled" ? "filled" : "standard"}
            InputProps={{
              ...params.InputProps,
              ...filledInputProps,
              endAdornment: (
                <>
                  {loading ? <div className={classes.loader}><CircularProgress color="inherit" size={20} /></div> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        </BaseInputField>
      )}
      {...other}
    />
  );
};

AutoCompleteInput.defaultProps = {
  getOptionLabel: option => option?.label ?? "",
  popupIcon: <Icon>keyboard_arrow_down</Icon>,
  forcePopupIcon: true,
  ChipProps: { size: "small" },
};

export default AutoCompleteInput;
