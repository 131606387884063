import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "formik";
import { TextInput, CheckboxInput } from "components/inputs";
import AdvancedFilter, { FilterForm, FilterSection } from "components/items/advanced-filter";
import { fetchReferralFormProfileResults, resetReferralFormProfilesFilters } from "app/main/referralFormProfiles/actions/referralFormProfiles.actions";
import { getReferralFormProfileSearchTerms, getPageInfo } from "app/main/referralFormProfiles/reducers/referralFormProfiles.reducers";

const ReferralFormProfileSearch = ({ orgUnitId }) => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(getPageInfo);
  const { pageSize } = pageInfo;
  const referralFormProfileSearchTerms = useSelector(getReferralFormProfileSearchTerms);

  const onSearch = searchText => {
    dispatch(fetchReferralFormProfileResults(orgUnitId, 1, pageSize, { ...referralFormProfileSearchTerms, name: searchText }));
  };

  const searchReferralFormProfiles = searchParams => dispatch(fetchReferralFormProfileResults(orgUnitId, 1, pageSize, searchParams));

  const handleSubmit = ({ searchParams }) => {
    searchReferralFormProfiles(searchParams);
  };

  const handleReset = () => {
    dispatch(resetReferralFormProfilesFilters()).then(searchReferralFormProfiles());
  };

  return (
    <AdvancedFilter
      onSearch={onSearch}
      searchValue={referralFormProfileSearchTerms?.name}
      filterContentAnchor="right"
      withPaperWrapper={false}
      content={onClose => (
        <FilterForm
          initialValues={{ searchParams: referralFormProfileSearchTerms }}
          onSubmit={handleSubmit}
          onReset={handleReset}
          onClose={onClose}
          filters={() => (
            <div>
              <FilterSection>
                <Field
                  label="Name"
                  name="searchParams.name"
                  component={TextInput}
                  icon="search"
                />
              </FilterSection>
              <FilterSection>
                <Field
                  name="searchParams.showDeprecated"
                  label="Show Deprecate"
                  component={CheckboxInput}
                />
              </FilterSection>
            </div>
          )}
        />
      )}
    />
  );
};

export default ReferralFormProfileSearch;
