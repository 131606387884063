import React from "react";
import ScreenHeader from "components/items/screen-header";
import withPermissions from "permissions/withPermissions";
import ReferralFormProfileSearch from "app/main/referralFormProfiles/components/referralFormProfile-search";

const OrgUnitReferralFormProfilesPageHeader = ({
  id,
  orgUnit,
  history,
  hasPermissionReferralFormProfileCreate,
}) => (
  <ScreenHeader
    renderSearch={<ReferralFormProfileSearch orgUnitId={id} />}
    action={hasPermissionReferralFormProfileCreate && {
      label: "add a referral profile form",
      onClick: () => history.push(`/admin/org-units/${orgUnit.orgUnitType}/${id}/referralFormProfiles/new`),
    }}
  />
);

export default withPermissions("ReferralFormProfileCreate")(OrgUnitReferralFormProfilesPageHeader);
