import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import * as Yup from "yup";

import { NoteInput } from "components/inputs/text-input";
import Form from "components/form";
import AttachmentUploadField from "components/inputs/attachment-upload";
import { uploadReferralAttachment, updateReferralAttachment } from "app/main/referrals/actions/referrals.actions";
import { showMessage } from "app/store/actions/message.actions";
import { getFriendlyUploadTypeDescription } from "app/auth/store/reducers/system-configuration";

const description = "Description";

const schema = Yup.object().shape({
  attachment: Yup.object().shape({
    fileName: Yup.string()
      .required("Please choose a file")
      .nullable(),
    description: Yup.string().max(255, "Too long!"),
  }),
});

const ReferralAttachmentForm = ({
  referralId,
  orgUnitId,
  referralDocumentId,
  onSucceed,
  disabled,
  ...other
}) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [apiError, setAPIError] = useState(false);
  const helperText = useSelector(getFriendlyUploadTypeDescription);

  const handleSubmit = ({ attachment }, { setSubmitting, setErrors }) => {
    const data = {
      id: attachment.id,
      description: attachment.description,
    };

    dispatch(updateReferralAttachment(orgUnitId, referralId, referralDocumentId, data)).then(response => {
      setSubmitting(false);
      if (response.error === true) {
        setErrors(response.payload);
      } else {
        onSucceed();
      }
    });
  };

  React.useEffect(() => {
    if (apiError) {
      dispatch(showMessage({
        message: apiError,
        variant: "error",
      }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiError]);

  const initialValues = schema.cast();

  return (
    <Form
      initialStatus={{ apiErrors: null }}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      disabled={disabled}
      contentProps={other}
      variant="filled"
      disabledActions={uploading}
      validationSchema={schema}
      content={({ setFieldValue, setStatus }) => (
        <>
          <Field
            name="attachment.fileName"
            component={AttachmentUploadField}
            label="Attachment"
            icon="attachment"
            helperText={helperText}
            loading={uploading}
            onChange={file => {
              if (file) {
                setFieldValue("attachment.fileName", file.name);
                // setFieldValue("attachment.fileSize", file.size); // not needed
                const reader = new FileReader();
                reader.readAsDataURL(file);
                setUploading(true);
                reader.onload = () => {
                  const formData = new FormData();
                  formData.append("file", file);
                  dispatch(uploadReferralAttachment(orgUnitId, referralId, referralDocumentId, formData)).then(response => {
                    if (response.error === true) {
                      setStatus({ apiErrors: response.payload.message });
                      setAPIError(response.payload.message);
                    } else {
                      setFieldValue("attachment.id", response.payload.id);
                      setStatus({ apiErrors: null });
                      setAPIError(null);
                    }
                    setUploading(false);
                  });
                };
              }
            }}
          />
          <Field
            name="attachment.description"
            component={NoteInput}
            label={description}
            maxLength={255}
          />
        </>
      )}
    />
  );
};

export default ReferralAttachmentForm;
