import React, { useEffect } from "react";
import withPermissions from "permissions/withPermissions";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, startCase } from "lodash";
import { TableCell, TableRow, Typography, Hidden, Chip } from "@material-ui/core";
import IconButton from "components/items/icon-button";
import ConfirmationDialog from "components/items/confirmation-dialog";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { fetchReferralFormProfileResults, onRemove } from "app/main/referralFormProfiles/actions/referralFormProfiles.actions";
import { REFERRAL_FORM_PROFILES_PAGE_SIZE_OPTIONS, REFERRAL_FORM_PROFILES_PAGE_SIZE, getAllReferralFormProfiles, isPageLoading, getErrorMessage, getReferralFormProfileSearchTerms, getPageInfo } from "app/main/referralFormProfiles/reducers/referralFormProfiles.reducers";
import PagingTable from "components/items/paging-table";

const useStyles = makeStyles(theme => ({
  actions: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: `${theme.spacing(1)}px`,
  },
}));

const OrgUnitReferralFormProfilesPageContent = ({ id, orgUnit, history,
  hasPermissionReferralFormProfileDelete }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeDialog());
  const referralFormProfiles = useSelector(getAllReferralFormProfiles);
  const isEmptyResult = isEmpty(referralFormProfiles);
  const loading = useSelector(isPageLoading);
  const error = useSelector(getErrorMessage);
  const pageInfo = useSelector(getPageInfo);
  const searchParams = useSelector(getReferralFormProfileSearchTerms);
  const searchOrgUnitReferralFormProfiles = (pageNumber, pageSize, reload) => dispatch(fetchReferralFormProfileResults(
    id, pageNumber, pageSize, { ...searchParams, traverseParentOrgUnits: true }, reload || history.location.state?.forceLoad === true,
  ));

  useEffect(() => {
    searchOrgUnitReferralFormProfiles(1, REFERRAL_FORM_PROFILES_PAGE_SIZE, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // call back previous page if current page has only one record and it has been deleted.
    if (!loading && isEmptyResult && pageInfo?.pageNumber > 1) {
      searchOrgUnitReferralFormProfiles(pageInfo?.pageNumber - 1, REFERRAL_FORM_PROFILES_PAGE_SIZE, true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isEmptyResult, pageInfo.pageNumber]);

  const onChangeRowsPerPage = pageSize => {
    searchOrgUnitReferralFormProfiles(1, pageSize, true);
  };

  const onChangePage = pageNumber => searchOrgUnitReferralFormProfiles(pageNumber + 1, pageInfo.pageSize, true);

  const onDeleteReferralFormProfile = current => dispatch(openDialog({
    disableBackdropClick: false,
    maxWidth: "xs",
    children: (
      <ConfirmationDialog
        type="warning"
        title="Delete Referral Form Profile"
        content={`Are you sure you want to delete ${current.name}?`}
        onConfirm={() => {
          dispatch(onRemove(id, current.id)).then(() => {
            onClose();
          });
        }}
        onCancel={onClose}
      />
    ),
  }));

  const onViewReferralFormProfile = referralFormProfileId => history.push(`/admin/org-units/${orgUnit.orgUnitType}/${id}/referralFormProfiles/${referralFormProfileId}`);

  const Actions = ({ item }) => (
    <>
      {hasPermissionReferralFormProfileDelete && item.enableDelete && <IconButton title="Delete" icon="delete" onClick={() => onDeleteReferralFormProfile(item)} />}
      <IconButton title="Edit" icon="edit" onClick={() => onViewReferralFormProfile(item?.id)} />
    </>
  );

  const referralFormProfileItem = referralFormProfile => (
    <div className="flex">
      <div className="flex flex-col flex-1">
        <Typography variant="body2" className="font-bold">{referralFormProfile.name}{referralFormProfile.isDeprecated && (
          <Chip variant="outlined" size="small" label="Deprecated" className="mx-8" />
        )}
        </Typography>
        <div className="flex-row-container">
          <Typography variant="body2" className="mr-4">Form Configuration:</Typography>
          <Typography variant="body2" color="textPrimary">{referralFormProfile.formName ?? startCase(referralFormProfile.formType)}</Typography>
        </div>
        <div className="flex-row-container">
          <Typography variant="body2" className="mr-4">Default:</Typography>
          <Typography variant="body2" color="textPrimary">
            {referralFormProfile.isDefault ? "Yes" : "No"}
          </Typography>
        </div>
      </div>
      <div className={classes.actions}>
        <Hidden smDown>
          <Actions item={referralFormProfile} />
        </Hidden>
      </div>
    </div>
  );

  const renderTableRow = referralFormProfile => (
    <TableRow
      hover
      key={referralFormProfile?.id}
    >
      <TableCell>{referralFormProfileItem(referralFormProfile)}</TableCell>
    </TableRow>
  );

  return (
    <PagingTable
      loading={loading}
      error={error}
      data={referralFormProfiles}
      rowsPerPage={pageInfo.pageSize}
      pageSizeOptions={REFERRAL_FORM_PROFILES_PAGE_SIZE_OPTIONS}
      page={pageInfo.pageNumber - 1}
      count={pageInfo.totalRecords}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      tableRow={item => renderTableRow(item)}
      emptyMessage="No Referral Form Profile"
    />
  );
};

export default withPermissions("ReferralFormProfileDelete")(OrgUnitReferralFormProfilesPageContent);
