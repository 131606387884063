import React, { useState, useEffect } from "react";
import { Checkbox, Divider, FormControlLabel, FormControl, FormGroup } from "@material-ui/core";
import { map, includes, concat, filter, size, some } from "lodash";

/**
 * A control that provide ability select all checkbox
 * - renders select 'ALL' checkbox
 * - render sets of checkbox (multi-select)
 * Note: expected field value would be array of strings
 * @param {function} onChangeSelectAll - callback when 'ALL' is clicked
 * @param {function} onChange - callback when checkbox is clicked
 * @param {array} options - array of options, expected format [{ value, label}, {value, label }, ....]
 *
 * checkbox select would hold 3 different status
 * - checked: when all of the options are checked
 * - unchecked: when none of the options are checked
 * - indeterminate: when one or more option(s) is/are checked
 */
const MultipleCheckbox = ({
  options,
  form: { setFieldValue },
  field: { value, name },
  onChangeSelectAll,
  onChange,
  renderExtraActions = null,
}) => {
  const [selectALLStatus, setSelectAllStatus] = useState(false);

  useEffect(() => {
    // if the size of the option matches the current selected options
    // all status is true
    if (size(options) === size(value)) {
      return setSelectAllStatus(true); // checked
    } if (some(value)) {
      // if some of value is selected
      return setSelectAllStatus("indeterminate"); // checked
    }
    return setSelectAllStatus(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSelectAll = event => {
    // determine select all or deselect
    const { checked } = event.target;
    if (checked === true) {
      setFieldValue(name, map(options, s => s?.value));
    } else {
      setFieldValue(name, []);
    }

    if (onChangeSelectAll) {
      onChangeSelectAll(checked);
    }
  };

  const handleChange = (event, option) => {
    const updatedStatuses = event.target.checked
      ? concat(value ?? [], option.value) // add to existing selection
      : filter(value, x => x !== option.value); // remove from selection

    setFieldValue(name, updatedStatuses);
    if (onChange) {
      onChange(event.target.checked, option);
    }
  };

  return (
    <>
      <div className="flex">
        <FormControlLabel
          control={(
            <Checkbox
              name="checkAll"
              indeterminate={selectALLStatus === "indeterminate"}
              checked={selectALLStatus === "indeterminate" ? false : selectALLStatus}
              onChange={handleSelectAll}
            />
        )}
          label="All"
        />
        {renderExtraActions && renderExtraActions}
      </div>
      <Divider />
      <FormControl component="fieldset">
        <FormGroup>
          <div className="checkboxGroups">
            {map(options, option => {
              const isChecked = includes(value, option.value);
              return (
                <FormControlLabel
                  label={option.label}
                  key={option.value}
                  className="mr-0"
                  control={<Checkbox checked={isChecked} onChange={event => handleChange(event, option)} name={option.value} />}
                />
              );
            })}
          </div>
        </FormGroup>
      </FormControl>
    </>
  );
};

export default MultipleCheckbox;
