import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { ActionTypes,
  isPageLoading,
  isCurrentSpecialtyProfileLoaded,
  isCurrentSpecialtyProfileLoading,
  areSearchedSpecialtyProfilesLoading,
  areSpecialtyCodeSetValuesLoading,
  areSpecialtyCodeSetValuesLoaded,
  isPageLoaded,
  areSpecialtyRolesLoading,
  areSubSpecialtiesLoading,
  areSpecialtySpecialistsLoading,
  areOCRSpecialtyProfilesLoading,
  areOCRSpecialtyProfilesLoaded,
  areSpecialtiesCodeSetValues,
  areSpecialtyReferralFormProfilesLoading } from "../reducers/specialtyProfiles.reducers";

export const resetCurrentSpecialtyProfile = () => ({
  type: ActionTypes.RESET_CURRENT_SPECIALTY_PROFILE,
});

export const resetSpecialtySelectors = specialtyId => ({
  type: ActionTypes.RESET_SPECIALTY_SELECTORS,
  payload: specialtyId,
});

export const fetchSpecialtyProfilesResults = (orgUnitId, pageNumber, pageSize, searchParams, forceLoad = true) => dispatch => {
  const url = `/specialtyprofiles/${orgUnitId}?pageNumber=${pageNumber}&pageSize=${pageSize}&showDeprecated=true${formatSearchParams(searchParams)}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "specialty_Profiles",
      pageNumber,
      bailout: state => isPageLoading(state, pageNumber) || (!forceLoad && isPageLoaded(state, pageNumber)),
    },
  });
};

export const fetchOCRSpecialtyProfiles = (orgUnitId, forceLoad = true) => dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/specialtyprofiles/${orgUnitId}/GetOCRSpecialtyProfiles`,
    method: "GET",
    types: [
      ActionTypes.LOADING_OCR_SPECIALTY_PROFILES,
      ActionTypes.LOADED_OCR_SPECIALTY_PROFILES,
      ActionTypes.ERROR_LOADING_OCR_SPECIALTY_PROFILES,
    ],
    bailout: state => areOCRSpecialtyProfilesLoading(state) || (!forceLoad && areOCRSpecialtyProfilesLoaded(state)),
  },
});

export const getSpecialtyProfile = (orgUnitId, id) => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/specialtyprofiles/${orgUnitId}/${id}`,
    entityType: "SPECIALTY_PROFILE",
    bailout: state => isCurrentSpecialtyProfileLoaded(state) || isCurrentSpecialtyProfileLoading(state),
  },
});

export const addNewSpecialtyProfile = specialtyProfile => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/specialtyprofiles/${specialtyProfile.orgUnitId}`,
    body: { specialtyProfile },
    entityType: "SPECIALTY_PROFILE",
  },
});

export const updateExistingSpecialtyProfile = specialtyProfile => async dispatch => {
  const { orgUnitId, id } = specialtyProfile;
  return dispatch({
    [ApiActions.SAVE_TO_API]: {
      endpoint: `/specialtyprofiles/${orgUnitId}/${id}`,
      body: { specialtyProfile },
      entityType: "SPECIALTY_PROFILE",
    },
  });
};

export const fetchSpecialtyRoles = (orgUnitId, specialtyIds, selectedOrgUnitId, isAssignment = false) => dispatch => {
  const searchParams = formatSearchParams({ specialtyIds, selectedOrgUnitId, isAssignment });

  const url = `/specialtyprofiles/${orgUnitId}/getSpecialtyRoles?${searchParams}`;
  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => areSpecialtyRolesLoading(state),
      entityType: "SPECIALTY_ROLES",
    },
  });
};

export const fetchSpecialtyReferralFormProfiles = (orgUnitId, specialtyId) => dispatch => {
  const url = `/specialtyprofiles/${orgUnitId}/${specialtyId}/getSpecialtyReferralFormProfiles`;
  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => areSpecialtyReferralFormProfilesLoading(state),
      entityType: "SPECIALTY_REFERRAL_FORM_PROFILES",
    },
  });
};

export const fetchAllSubSpecialties = showDeprecated => dispatch => {
  const url = `/specialtyprofiles/getAllSubSpecialties?showDeprecated=${showDeprecated}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => areSubSpecialtiesLoading(state),
      entityType: "SUB_SPECIALTIES",
    },
  });
};

export const deprecateSpecialtyProfile = specialtyProfile => async dispatch => {
  const { orgUnitId, id } = specialtyProfile;
  return dispatch({
    [ApiActions.SAVE_TO_API]: {
      endpoint: `/specialtyprofiles/${orgUnitId}/${id}/deprecate`,
      entityType: "DEPRECATE_SPECIALTY_PROFILE",
    },
  });
};

export const restoreSpecialtyProfile = specialtyProfile => async dispatch => {
  const { orgUnitId, id } = specialtyProfile;
  return dispatch({
    [ApiActions.SAVE_TO_API]: {
      endpoint: `/specialtyprofiles/${orgUnitId}/${id}/restore`,
      entityType: "RESTORE_SPECIALTY_PROFILE",
    },
  });
};

export const fetchSpecialtyProfilesForSelector = (orgUnitId, searchParams) => dispatch => {
  const params = formatSearchParams(searchParams);
  const pageNumber = 1;
  const url = `/specialtyprofiles/${orgUnitId}?pageNumber=${pageNumber}&pageSize=999${params}`;
  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      bailout: state => areSearchedSpecialtyProfilesLoading(state),
      method: "GET",
      entityType: "SEARCHED_SPECIALTIES",
    },
  });
};

export const fetchSpecialtyProfileCodeSetValues = (orgUnitId, specialtyId, forceLoad = false) => dispatch => {
  const url = `/specialtyprofiles/${orgUnitId}/${specialtyId}/getSpecialtyProfileCodeSetValues`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => areSpecialtyCodeSetValuesLoading(state, specialtyId) || (!forceLoad && areSpecialtyCodeSetValuesLoaded(state, specialtyId)),
      entityType: "SPECIALTY_CODE_SET_VALUES",
      specialtyId,
    },
  });
};

export const fetchSpecialtyProfilesCodeSetValue = (orgUnitId, searchParams) => dispatch => {
  const params = formatSearchParams(searchParams);
  const url = `/specialtyprofiles/${orgUnitId}/getSpecialtyProfilesCodeSetValues?${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => areSpecialtiesCodeSetValues(state),
      entityType: "SPECIALTIES_CODE_SET_VALUES",
    },
  });
};

export const fetchSpecialtySpecialists = specialtyProfileId => dispatch => {
  const url = `/specialtyprofiles/${specialtyProfileId}/getSpecialtySpecialists`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => areSpecialtySpecialistsLoading(state),
      entityType: "SPECIALTY_SPECIALISTS",
      specialtyProfileId,
    },
  });
};

export const fetchAllSpecialtySpecialists = orgUnitId => dispatch => {
  const url = `/specialtyprofiles/${orgUnitId}/getAllSpecialtySpecialists`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => areSpecialtySpecialistsLoading(state),
      entityType: "SPECIALTY_SPECIALISTS",
      orgUnitId,
    },
  });
};
