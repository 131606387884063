import React from "react";
import { connect } from "react-redux";

import { closeDialog } from "app/store/actions/dialog.actions";
import { showMessage } from "app/store/actions/message.actions";
import LoadingState from "components/items/loading-state";

import ConfirmationDialog from "components/items/confirmation-dialog";
import { cloneApplication } from "app/main/applications/actions/applications.actions";

import { isCloningApplication } from "app/main/applications/reducers/applications.reducers";
import { getHelperTextBySectionName } from "app/auth/store/reducers/system-configuration";

class CloneApplicationDialog extends React.PureComponent {
  render() {
    const { loading, helperText } = this.props;
    return (
      <ConfirmationDialog
        onCancel={() => this.props.closeDialog()}
        onConfirm={() => {
          this.props.cloneApplication(this.props.orgUnitId, this.props.id).then(response => {
            if (response.error === true) {
              this.props.showMessage({ message: response.payload.exceptionMessage || "An error has occurred", variant: "error" });
            } else {
              this.props.scrollItemToView(response.payload.id);
              this.props.history.push(`/applications?applicationId=${response.payload.id}`);
            }
            this.props.closeDialog();
          });
        }}
        title={<div className="text-center">Request ongoing treatment?</div>}
        content={loading
          ? <LoadingState />
          : helperText.copyApplicationForm}
      />
    );
  }
}

const mapStateToProps = state => ({
  helperText: getHelperTextBySectionName(state, "reportBack"),
  loading: isCloningApplication(state),
});

export default connect(mapStateToProps, { cloneApplication, closeDialog, showMessage })(CloneApplicationDialog);
