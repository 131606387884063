import * as Yup from "yup";
import "utils/yup-validation";
import { isNaN } from "lodash";

export default (fieldLabels, fieldSettings, isUrgentTreatment, decimalNumberRegex, decimalPlacesRegex) => {
  const drugRequiredMessage = "Drug is required";
  const tooLong = "Too Long!";
  const getBooleanDefaultValue = setting => (setting ? false : undefined);

  return Yup.object().shape({
    treatment: Yup.object().shape({
      drug: Yup.string().max(250, tooLong).trim().required(drugRequiredMessage)
        .nullable(),
      form: Yup.string()
        .max(100, tooLong)
        .fieldSettingsValidation(fieldLabels.form, fieldSettings?.form)
        .nullable(),
      strength: Yup.string()
        .max(100, tooLong)
        .fieldSettingsValidation(fieldLabels.strength, fieldSettings?.strength)
        .nullable(),
      // config fields
      urgencyTreatmentType: Yup.string()
        .fieldSettingsValidation(fieldLabels.urgencyTreatmentType, fieldSettings?.urgencyOfTreatmentGroup)
        .nullable(),
      urgencyOfTreatmentRationale: Yup.string()
        .max(1000, tooLong)
        .nullable()
        .when("urgencyTreatmentType", {
          is: isUrgentTreatment,
          then: Yup.string().validateRequired(fieldLabels.urgencyOfTreatmentRationale).max(500, tooLong).trim(),
        }),
      brandSpecified: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.brandSpecified, fieldSettings?.brandGroup)
        .nullable()
        .default(() => getBooleanDefaultValue(fieldSettings?.brandGroup)),
      brand: Yup.string()
        .max(50, tooLong)
        .nullable()
        .when("brandSpecified", {
          is: true,
          then: Yup.string().fieldSettingsValidation(fieldLabels.brand, fieldSettings?.brandGroup),
        }),
      medicationDoseAndFrequency: Yup.string()
        .max(50, tooLong)
        .fieldSettingsValidation(fieldLabels.medicationDoseAndFrequency, fieldSettings?.medicationDoseAndFrequency)
        .nullable(),
      medicationDose: Yup.number()
        .fieldSettingsValidation(fieldLabels.medicationDose, fieldSettings?.medicationDose)
        .validateDecimalNumber(fieldLabels.medicationDose, decimalNumberRegex)
        .validateDecimalNumberDecimalValues(fieldLabels.medicationDose, decimalPlacesRegex)
        .nullable()
        .transform(v => (isNaN(v) ? null : v)),
      medicationFrequency: Yup.number()
        .fieldSettingsValidation(fieldLabels.medicationFrequency, fieldSettings?.medicationFrequency)
        .validateDecimalNumber(fieldLabels.medicationFrequency, decimalNumberRegex)
        .validateDecimalNumberDecimalValues(fieldLabels.medicationFrequency, decimalPlacesRegex)
        .nullable()
        .transform(v => (isNaN(v) ? null : v)),
      route: Yup.string()
        .max(50, tooLong)
        .fieldSettingsValidation(fieldLabels.route, fieldSettings?.route)
        .nullable(),
      treatmentDurationType: Yup.string()
        .fieldSettingsValidation(fieldLabels.treatmentDurationType, fieldSettings?.treatmentDurationGroup)
        .nullable(),
      treatmentDurationUnit: Yup.string()
        .fieldSettingsValidation(fieldLabels.treatmentDurationUnit, fieldSettings?.treatmentDurationGroup)
        .nullable(),
      treatmentDurationValue: Yup.number()
        .fieldSettingsValidation(fieldLabels.treatmentDurationValue, fieldSettings?.treatmentDurationGroup)
        .max(Number.MAX_SAFE_INTEGER, tooLong)
        .integer("Must be a whole number")
        .nullable()
        .transform(v => (isNaN(v) ? null : v)),
      requestedDuration: Yup.string()
        .max(50, tooLong)
        .fieldSettingsValidation(fieldLabels.requestedDuration, fieldSettings?.requestedDuration)
        .nullable(),
      smfApprovedIndications: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.sMFApprovedIndications, fieldSettings?.sMFApprovedIndications)
        .nullable()
        .default(() => getBooleanDefaultValue(fieldSettings?.sMFApprovedIndications)),
      smfApprovedIndicationsComments: Yup.string()
        .max(1000, tooLong)
        .nullable()
        .when("smfApprovedIndications", {
          is: true,
          then: Yup.string().fieldSettingsValidation(fieldLabels.sMFApprovedIndicationsComments, fieldSettings?.sMFApprovedIndications),
        }),
      tgaApprovedMedication: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.tGAApprovedMedication, fieldSettings?.tGAApprovedMedication)
        .nullable()
        .default(() => getBooleanDefaultValue(fieldSettings?.tGAApprovedMedication)),
      tgaLicencedIndication: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.tGALicencedIndication, fieldSettings?.tGALicencedIndication)
        .nullable()
        .default(() => getBooleanDefaultValue(fieldSettings?.tGALicencedIndication)),
      pbsApprovedIndication: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.pBSApprovedIndication, fieldSettings?.pBSApprovedIndicationGroup)
        .nullable()
        .default(() => getBooleanDefaultValue(fieldSettings?.pBSApprovedIndicationGroup)),
      pbsApprovedIndicationComments: Yup.string()
        .max(1000, tooLong)
        .nullable()
        .when("pbsApprovedIndication", {
          is: true,
          then: Yup.string().fieldSettingsValidation(fieldLabels.pBSApprovedIndicationComments, fieldSettings?.pBSApprovedIndicationGroup),
        }),
      nursingConsiderations: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.nursingConsiderations, fieldSettings?.nursingConsiderationsGroup)
        .nullable()
        .default(() => getBooleanDefaultValue(fieldSettings?.nursingConsiderationsGroup)),
      nursingConsiderationRationale: Yup.string()
        .max(1000, tooLong)
        .nullable()
        .when("nursingConsiderations", {
          is: true,
          then: Yup.string().fieldSettingsValidation(fieldLabels.nursingConsiderationRationale, fieldSettings?.nursingConsiderationsGroup),
        }),
      cost: Yup.number()
        .fieldSettingsValidation(fieldLabels.cost, fieldSettings?.costGroup)
        .validateDecimalNumber(fieldLabels.cost, decimalNumberRegex)
        .validateDecimalNumberDecimalValues(fieldLabels.cost, decimalPlacesRegex)
        .nullable()
        .transform(v => (isNaN(v) ? null : v)),
      costPer: Yup.string()
        .max(50, tooLong)
        .fieldSettingsValidation(fieldLabels.costPer, fieldSettings?.costGroup)
        .nullable(),
      compassionateAccess: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.compassionateAccess, fieldSettings?.compassionateAccess)
        .nullable()
        .default(() => getBooleanDefaultValue(fieldSettings?.compassionateAccess)),
      indicationTerm: Yup.string()
        .max(120, tooLong)
        .fieldSettingsValidation(fieldLabels.indication, fieldSettings?.indication)
        .nullable(),
      additionalIndicationDetails: Yup.string()
        .max(1000, tooLong)
        .fieldSettingsValidation(fieldLabels.additionalIndicationDetails, fieldSettings?.additionalIndicationDetails)
        .nullable(),
      relevantMedicalHistory: Yup.string()
        .max(1000, tooLong)
        .fieldSettingsValidation(fieldLabels.relevantMedicalHistory, fieldSettings?.relevantMedicalHistory)
        .nullable(),
      relevantTreatmentSoFar: Yup.string()
        .max(500, tooLong)
        .fieldSettingsValidation(fieldLabels.relevantTreatmentSoFar, fieldSettings?.relevantTreatmentSoFar)
        .nullable(),
      hasTreatmentCommencedLabel: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.hasTreatmentCommencedLabel, fieldSettings?.hasTreatmentCommencedLabel)
        .nullable()
        .default(() => getBooleanDefaultValue(fieldSettings?.hasTreatmentCommencedLabel)),
      treatmentIntent: Yup.string()
        .max(50, tooLong)
        .fieldSettingsValidation(fieldLabels.treatmentIntent, fieldSettings?.treatmentIntent)
        .nullable(),
      priorApproval: Yup.boolean()
        .fieldSettingsValidation(fieldLabels.priorApproval, fieldSettings?.priorApproval)
        .nullable()
        .default(() => getBooleanDefaultValue(fieldSettings?.priorApproval)),
      rationale: Yup.string()
        .max(500, tooLong)
        .fieldSettingsValidation(fieldLabels.rationale, fieldSettings?.rationale)
        .nullable(),
      safetyConcerns: Yup.string()
        .max(1000, tooLong)
        .fieldSettingsValidation(fieldLabels.safetyConcerns, fieldSettings?.safetyConcerns)
        .nullable(),
      expectedOutcomeMeasures: Yup.string()
        .max(1000, tooLong)
        .fieldSettingsValidation(fieldLabels.expectedOutcomeMeasures, fieldSettings?.expectedOutcomeMeasures)
        .nullable(),
      anticipatedImpactOnPrognosis: Yup.string()
        .max(1000, tooLong)
        .fieldSettingsValidation(fieldLabels.anticipatedImpactOnPrognosis, fieldSettings?.anticipatedImpactOnPrognosis)
        .nullable(),
      patientToxicity: Yup.string()
        .max(1000, tooLong)
        .fieldSettingsValidation(fieldLabels.patientToxicity, fieldSettings?.patientToxicity)
        .nullable(),
      transitionPlanPaediatric: Yup.string()
        .max(1000, tooLong)
        .fieldSettingsValidation(fieldLabels.transitionPlanPaediatric, fieldSettings?.transitionPlanPaediatric)
        .nullable(),
      transitionPlan: Yup.string()
        .max(1000, tooLong)
        .fieldSettingsValidation(fieldLabels.transitionPlan, fieldSettings?.transitionPlan)
        .nullable(),
      drugResultJson: Yup.string().nullable(),
      medicationSponsorCostContribution: Yup.number()
        .fieldSettingsValidation(fieldLabels.medicationSponsorCostContribution, fieldSettings?.compassionateAccess)
        .validateDecimalNumber(fieldLabels.medicationSponsorCostContribution, decimalNumberRegex)
        .validateDecimalNumberDecimalValues(fieldLabels.medicationSponsorCostContribution, decimalPlacesRegex)
        .nullable()
        .transform(v => (isNaN(v) ? null : v)),
    }),
  });
};
