import React from "react";
import { compact, map, find, isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { AccessDeniedState } from "components/items/empty-state";
import { getOverviewPanelSettings } from "app/auth/store/reducers/system-configuration";
import CareSummaryPanel from "app/main/worklist/components/care-summary-panel";
import withPermissions from "permissions/withPermissions";
import AppointmentSummaryPanel from "../appointments/components/appointment-summary-panel";
import PatientActiveReferralPanel from "../referrals/components/patient-active-referrals-panel";

export const PatientOverviewPage = ({
  patientId,
  orgUnitId,
  diseaseStates,
  hasPermissionEnrolmentView,
  hasPermissionAppointmentsView,
  hasPermissionReferralsView,
}) => {
  const overviewPanelSettings = useSelector(getOverviewPanelSettings);

  const panels = compact([
    hasPermissionEnrolmentView && {
      code: "CareSummary",
      component: props => <CareSummaryPanel diseaseStates={diseaseStates} {...props} />,
    },
    hasPermissionAppointmentsView && {
      code: "Appointment",
      component: props => <AppointmentSummaryPanel {...props} />,
    },
    hasPermissionReferralsView && {
      code: "Referral",
      component: props => <PatientActiveReferralPanel {...props} />,
    },
  ]);

  if (isEmpty(overviewPanelSettings)) {
    return <AccessDeniedState />;
  }

  return map(overviewPanelSettings, (x, index) => {
    const panel = find(panels, item => item.code === x.overviewPanel);

    if (panel) {
      const isLast = index + 1 === panels.length;
      return <React.Fragment key={panel.code}>{panel.component({ patientId, orgUnitId, isLast, title: x.label })}</React.Fragment>;
    }

    return null;
  });
};

export default withPermissions(
  "EnrolmentView",
  "AppointmentsView",
  "ReferralsView",
)(PatientOverviewPage);
