import { useSelector } from "react-redux";
import { includes } from "lodash";
import { getReferralWorkflowEndStates, getEnablePreventAddEditNotesInEndState } from "app/auth/store/reducers/system-configuration";

const useCanEditInEndState = (referralStatus) => {
  const referralWorklistEndStates = useSelector(getReferralWorkflowEndStates);
  const enablePreventAddEditNotesInEndState = useSelector(getEnablePreventAddEditNotesInEndState);
  const canEditInEndState = !(includes(referralWorklistEndStates, referralStatus) && enablePreventAddEditNotesInEndState);

  return canEditInEndState;
}

export default useCanEditInEndState;