import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { map, filter, size, head, isEmpty } from "lodash";

import { AutoComplete } from "components/inputs";
import { validateRequired } from "utils/validators";
import { getOrgUnitsForOrgUnitsSelector, isOrgUnitsSelectorLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { fetchOrgUnitsForSelector } from "app/main/orgUnits/actions/orgUnit.actions";
import Form from "components/form";
import SpecialtySelector from "app/main/specialtyProfiles/components/specialty-selector";
import SubSpecialtySelector from "app/main/specialtyProfiles/components/sub-specialty-selector";
import { OrgUnitsIcon } from "helpers/icon-finder";

const orgUnitLabel = "Site";
const validateOrgUnit = value => validateRequired(orgUnitLabel)(value);

const specialtyLabel = "Specialty";
const validateSpecialty = value => validateRequired(specialtyLabel)(value);

const UserSpecialtyProfileFields = props => {
  const { userSpecialtyProfiles, userSpecialtyProfile, isOrgUnitLoading, orgUnits } = props;

  return (
    <>
      <Field
        name="userSpecialtyProfile.orgUnitId"
        loading={isOrgUnitLoading}
        label={orgUnitLabel}
        component={AutoComplete}
        options={map(orgUnits, orgUnit => ({ label: orgUnit.name, value: orgUnit.id }))}
        validate={validateOrgUnit}
        icon={<OrgUnitsIcon />}
        onChange={(value, change) => {
          change("userSpecialtyProfile.orgUnitId", value?.value);
          change("userSpecialtyProfile.orgUnitName", value?.label);
        }}
      />
      <Field
        name="userSpecialtyProfile.specialtyProfileId"
        label="Specialty"
        component={SpecialtySelector}
        traverseParentOrgUnits
        orgUnitId={userSpecialtyProfile.orgUnitId}
        excludeSpecialtiesIds={map(filter(userSpecialtyProfiles, x => x.specialtyProfileId !== userSpecialtyProfile.specialtyProfileId), x => x.specialtyProfileId)}
        validate={validateSpecialty}
        showIcon
        onChange={(value, change) => {
          change("userSpecialtyProfile.specialtyProfileId", value?.value);
          change("userSpecialtyProfile.specialtyProfileLabel", value?.label);
          change("userSpecialtyProfile.subSpecialtyId", null);
        }}
        loadInitialOption={userSpecialtyProfile.specialtyProfileId}
      />
      <Field
        name="userSpecialtyProfile.subSpecialtyId"
        component={SubSpecialtySelector}
        specialtyIds={!isEmpty(userSpecialtyProfile.specialtyProfileId) ? [userSpecialtyProfile.specialtyProfileId] : []}
        showIcon
        onChange={(value, change) => {
          change("userSpecialtyProfile.subSpecialtyId", value?.value);
          change("userSpecialtyProfile.subSpecialtyLabel", value?.displayName ?? value?.label);
        }}
      />
    </>
  );
};

const UserSpecialtyProfileForm = props => {
  const { userSpecialtyProfiles, onSubmit, currentUserSpecialtyProfile, ...other } = props;

  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(fetchOrgUnitsForSelector({ orgUnitTypes: ["Site"], currentOrgUnitId: other.orgUnit.id }));
  }, [dispatch, other.orgUnit.id]);

  const isOrgUnitLoading = useSelector(isOrgUnitsSelectorLoading);
  const orgUnits = useSelector(getOrgUnitsForOrgUnitsSelector);

  let initialValues = currentUserSpecialtyProfile || { orgUnitId: null, specialtyProfileId: null, subSpecialtyId: null };
  if (initialValues?.orgUnitId === null && size(orgUnits) === 1) {
    const currentOrgUnit = head(orgUnits);
    initialValues = {
      ...initialValues,
      orgUnitId: currentOrgUnit?.id,
      orgUnitName: currentOrgUnit?.name,
    };
  }

  const addSpecialtyProfile = ({ userSpecialtyProfile }, currentIndex) => {
    const formattedSpecialtyProfile = {
      ...userSpecialtyProfile,
      specialtyProfileId: userSpecialtyProfile.specialtyProfileId?.value ?? userSpecialtyProfile.specialtyProfileId,
      specialtyProfileLabel: userSpecialtyProfile.specialtyProfileId?.label ?? userSpecialtyProfile.specialtyProfileLabel,
    };
    onSubmit({ userSpecialtyProfile: formattedSpecialtyProfile }, currentIndex);
  };

  return (
    <Form
      initialValues={{ userSpecialtyProfile: initialValues }}
      contentProps={other}
      onSubmit={addSpecialtyProfile}
      content={({ values }) => (
        <UserSpecialtyProfileFields userSpecialtyProfiles={userSpecialtyProfiles} userSpecialtyProfile={values.userSpecialtyProfile} isOrgUnitLoading={isOrgUnitLoading} orgUnits={orgUnits} />
      )}
    />
  );
};

export default UserSpecialtyProfileForm;
