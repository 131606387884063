import React, { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, filter, find } from "lodash";
import { Alert, Pagination } from "@material-ui/lab";
import { Fade, Typography, Tooltip, Badge } from "@material-ui/core";
import CardTextOutlineIcon from "mdi-react/CardTextOutlineIcon";
import AccountSearchOutlineIcon from "mdi-react/AccountSearchOutlineIcon";
import IconComponent from "components/items/icon-component";
import { TextInput, DatePicker, SelectInput } from "components/inputs";
import { showMessage } from "app/store/actions/message.actions";
import { resetPatientSearch } from "app/main/patients/actions/patientsSearch.actions";
import { getSexOptions, getSexLabel, getFullModePatientSearch, getPrimaryPatientIdentifier, getPrimaryPatientIdentifierSettings, getPatientSearchMandatoryFieldsNumber, getDisplaySourceIndicator, getEnableOCR, getDobCountsTowardMandatoryFieldsNumber } from "app/auth/store/reducers/system-configuration";
import { PATIENTS_SEARCH_PAGE_SIZE, getPageInfo, getSearchParams } from "app/main/patients/reducers/patientsSearch.reducers";
import PatientSearchResults from "app/main/patients/components/external-patient-search-button/patient-search-results";
import PanelContent from "components/items/panel-content";
import DefaultButton from "components/items/default-button";
import DialogPopup from "components/items/dialog-popup";
import OCRFieldComponent from "components/ocr-components/ocr-field-component";
import { OCRTextInput, OCRSelectInput, OCRDatePicker } from "components/ocr-components";
import formatFilterDate from "helpers/format-filter-date";

const PatientSearchForm = ({
  title,
  patientPresetFilter,
  continueLabel = "Continue",
  isContinueLoading = false,
  onContinue,
  onClose,
  onClear,
  searchApi,
  sourceDocument,
  ocrResult = null,
  confirmedOCR = false,
  renderHeaderActions = null,
  titleClass = null,
}) => {
  const dispatch = useDispatch();
  const sexOptions = useSelector(getSexOptions);
  const sexLabel = useSelector(getSexLabel);
  const isFullModeSearch = useSelector(getFullModePatientSearch);
  const pageInfo = useSelector(getPageInfo);
  const searchParams = useSelector(getSearchParams);
  const settings = useSelector(getPrimaryPatientIdentifierSettings);
  const dobCountsTowardMandatoryFieldsNumber = useSelector(getDobCountsTowardMandatoryFieldsNumber);
  const code = settings?.type;
  const [selectedPatient, setSelectedPatient] = useState(null);
  const primaryPatientIdentifierSettings = useSelector(getPrimaryPatientIdentifierSettings);
  const externalPatientSearchMandatoryFieldsNumber = useSelector(getPatientSearchMandatoryFieldsNumber);
  const displaySourceIndicator = useSelector(getDisplaySourceIndicator);
  const enableOCR = useSelector(getEnableOCR);
  const identifierOptions = primaryPatientIdentifierSettings?.options;
  const givenNameLabel = "Given Name";
  const middleNameLabel = "Middle Name";
  const familyNameLabel = "Family Name";
  const medicareNumberLabel = "Medicare Number";
  const primaryIdentifierLabel = find(identifierOptions, x => x.value === code)?.label ?? useSelector(getPrimaryPatientIdentifier);

  const primaryPatientIdentifierMinLength = primaryPatientIdentifierSettings?.minLength;
  const primaryPatientIdentifierMaxLength = primaryPatientIdentifierSettings?.maxLength;

  const showPaging = pageInfo.totalRecords > PATIENTS_SEARCH_PAGE_SIZE;
  const showActionConfirm = true;

  const selectedSex = find(sexOptions, x => x.label === patientPresetFilter?.sex);
  const [currentPatientSearchForm, setCurrentPatientSearchForm] = useState({
    givenName: patientPresetFilter?.givenName ?? searchParams?.givenName,
    middleName: patientPresetFilter?.middleName ?? searchParams?.middleName,
    familyName: patientPresetFilter?.familyName ?? searchParams?.familyName,
    dateOfBirth: patientPresetFilter?.dateOfBirth ?? searchParams?.dateOfBirth,
    sex: selectedSex?.value ?? searchParams?.sex,
    medicareNumber: patientPresetFilter?.medicareNumber ?? searchParams?.medicareNumber,
    dvaNumber: patientPresetFilter?.dvaNumber ?? searchParams?.dvaNumber,
    primaryIdentifier: patientPresetFilter?.primaryIdentifier ?? searchParams?.primaryIdentifier,
  });

  const schema = Yup.object().shape({
    patientSearchForm: Yup.object().shape({
      primaryIdentifier: Yup.string().validateIdentifier("Primary Identifier", { min: primaryPatientIdentifierMinLength, max: primaryPatientIdentifierMaxLength, numberOnly: false }).nullable(),
      givenName: Yup.string().validateMinLength(givenNameLabel, 2).nullable(),
      middleName: Yup.string().validateMinLength(middleNameLabel, 2).nullable(),
      familyName: Yup.string().validateMinLength(familyNameLabel, 2).nullable(),
      isNameInvalid: Yup.bool().when(["givenName", "familyName", "middleName"], {
        is: (givenName, familyName, middleName) => middleName && !(givenName || familyName),
        then: Yup.bool().required("Given name or family name should be included"),
        otherwise: Yup.bool().nullable(),
      }).nullable(),
      dateOfBirth: Yup.date().default(null).nullable().typeError('Invalid Date').max(new Date(), "Birth date can not be in the future"),
      sex: Yup.string().nullable(),
      medicareNumber: Yup.string().validateMinLength(medicareNumberLabel, 9).nullable(),
      dvaNumber: Yup.string().nullable(),
      isInvalid: Yup.bool().when(["primaryIdentifier", "givenName", "familyName", "dateOfBirth", "sex", "medicareNumber", "dvaNumber", "middleName"], {
        is: (primaryIdentifier, givenName, familyName, dateOfBirth, sex, medicareNumber, dvaNumber, middleName) => {
          const arr = [primaryIdentifier, givenName, dateOfBirth, familyName, sex, medicareNumber, dvaNumber, middleName]
          
          // "Objects are considered empty if they have no own enumerable string keyed properties" (see https://lodash.info/doc/isEmpty). As a result, Date objects will always be IsEmpty. 
          // Instead, we can check if the field has a value and then increment nonEmptyFieldsLength as necessary when dobCountsTowardMandatoryFieldsNumber is true.
          const dobHasValue = dateOfBirth !== null;
          const nonEmptyFieldsLength = filter(arr, x => !isEmpty(x)).length + (dobHasValue && dobCountsTowardMandatoryFieldsNumber ? 1 : 0);

          return isEmpty(primaryIdentifier) && nonEmptyFieldsLength < externalPatientSearchMandatoryFieldsNumber;
        },
        then: Yup.bool().required(),
        otherwise: Yup.bool(),
      }).nullable(),
    }),
  });

  let initialValues = schema.cast();

  initialValues = { patientSearchForm: currentPatientSearchForm };

  const clear = () => { dispatch(resetPatientSearch()); setSelectedPatient(null); if (onClear) { onClear(); } };

  const formatSearchParameter = patientSearch => ({
    ...patientSearch,
    dateOfBirth: formatFilterDate(patientSearch.dateOfBirth),
  });

  const onSubmit = ({ patientSearchForm }, { setSubmitting }) => {
    setSelectedPatient(null);
    setCurrentPatientSearchForm(patientSearchForm);
    dispatch(searchApi(1, PATIENTS_SEARCH_PAGE_SIZE, formatSearchParameter(patientSearchForm))).then(() => setSubmitting(false));
  };

  const handleChangePage = (event, page) => {
    if (page !== pageInfo.pageNumber) {
      dispatch(searchApi(page, pageInfo.pageSize, formatSearchParameter(searchParams)));
    }
  };

  const formContent = props => (
    <Formik
      enableReinitialize
      onSubmit={onSubmit}
      initialValues={initialValues}
      contentProps={{ ...props }}
      validationSchema={schema}
      render={({ handleSubmit, isSubmitting, errors }) => (
        <div className="px-32 py-16">
          <PanelContent itemsPerRow={isFullModeSearch ? 3 : 1}>
            <Field
              name="patientSearchForm.primaryIdentifier"
              component={OCRFieldComponent}
              FieldComponent={TextInput}
              OCRComponent={OCRTextInput}
              enabledOCR={enableOCR}
              ocrProperty="PatientPrimaryIdentifier"
              showActionConfirm={showActionConfirm}
              confirmedOCR={confirmedOCR}
              ocrResult={ocrResult}
              label={primaryIdentifierLabel}
              icon={<CardTextOutlineIcon />}
            />
            {isFullModeSearch && (
              <Field
                name="patientSearchForm.medicareNumber"
                component={OCRFieldComponent}
                FieldComponent={TextInput}
                OCRComponent={OCRTextInput}
                enabledOCR={enableOCR}
                ocrProperty="PatientMedicareNumber"
                showActionConfirm={showActionConfirm}
                confirmedOCR={confirmedOCR}
                ocrResult={ocrResult}
                label={medicareNumberLabel}
              />
            )}
            {isFullModeSearch && (
              <Field
                name="patientSearchForm.dvaNumber"
                component={TextInput}
                label="DVA Number"
              />
            )}
          </PanelContent>
          {isFullModeSearch && (
            <>
              <PanelContent itemsPerRow={3}>
                <Field
                  name="patientSearchForm.givenName"
                  component={OCRFieldComponent}
                  FieldComponent={TextInput}
                  OCRComponent={OCRTextInput}
                  enabledOCR={enableOCR}
                  ocrProperty="PatientGivenNames"
                  showActionConfirm={showActionConfirm}
                  confirmedOCR={confirmedOCR}
                  ocrResult={ocrResult}
                  label={givenNameLabel}
                />
                <Field
                  name="patientSearchForm.middleName"
                  component={OCRFieldComponent}
                  FieldComponent={TextInput}
                  OCRComponent={OCRTextInput}
                  enabledOCR={enableOCR}
                  ocrProperty="PatientMiddleNames"
                  showActionConfirm={showActionConfirm}
                  confirmedOCR={confirmedOCR}
                  ocrResult={ocrResult}
                  label={middleNameLabel}
                />
                <Field
                  name="patientSearchForm.familyName"
                  component={OCRFieldComponent}
                  FieldComponent={TextInput}
                  OCRComponent={OCRTextInput}
                  enabledOCR={enableOCR}
                  ocrProperty="PatientFamilyName"
                  showActionConfirm={showActionConfirm}
                  confirmedOCR={confirmedOCR}
                  ocrResult={ocrResult}
                  label={familyNameLabel}
                />
              </PanelContent>
              {!isEmpty(errors?.patientSearchForm?.isNameInvalid) && (
                <div className="flex items-center justify-between">
                  <Alert severity="error">{`${givenNameLabel} or ${familyNameLabel} must be included.`}</Alert>
                </div>
              )}
              <PanelContent itemsPerRow={3}>
                <Field
                  name="patientSearchForm.dateOfBirth"
                  label="Date of Birth"
                  component={OCRFieldComponent}
                  FieldComponent={DatePicker}
                  OCRComponent={OCRDatePicker}
                  enabledOCR={enableOCR}
                  ocrProperty="PatientDateOfBirth"
                  showActionConfirm={showActionConfirm}
                  confirmedOCR={confirmedOCR}
                  ocrResult={ocrResult}
                />
                <Field
                  name="patientSearchForm.sex"
                  options={sexOptions}
                  component={OCRFieldComponent}
                  FieldComponent={SelectInput}
                  OCRComponent={OCRSelectInput}
                  enabledOCR={enableOCR}
                  ocrProperty="PatientGender"
                  showActionConfirm={showActionConfirm}
                  confirmedOCR={confirmedOCR}
                  ocrResult={ocrResult}
                  label={sexLabel}
                  ocrCustomProps={{
                    ignoreCase: true,
                  }}
                />
              </PanelContent>
            </>
          )}
          <div className="flex items-center justify-between">
            <Fade in={!isEmpty(errors?.patientSearchForm?.isInvalid)}>
              <Alert severity="error">{`You must specify search criteria with ${primaryIdentifierLabel}${isFullModeSearch ? ` or at least ${externalPatientSearchMandatoryFieldsNumber} other fields` : ""}.`}</Alert>
            </Fade>
            <DefaultButton icon="search" label="Search" onClick={handleSubmit} type="submit" size="medium" loading={isSubmitting && isEmpty(errors)} />
          </div>
        </div>
      )}
    />
  );

  return (
    <Formik
      enableReinitialize
    >
      {props => {
        const onContinueAction = async () => {
          try {
            const actionResponse = await onContinue(selectedPatient, sourceDocument);
            if (actionResponse?.error === true) {
              dispatch(showMessage({ message: actionResponse.payload.message, variant: "error" }));
            }
          } catch (error) {
            dispatch(showMessage({ message: error, variant: "error" }));
          }
        };

        const onCancelAction = () => { props.resetForm(); clear(); onClose(); };

        return (
          <DialogPopup
            title={title}
            titleClass={titleClass}
            renderHeaderActions={renderHeaderActions}
            renderHeaderContent={(
              <div className="no-drag">
                {formContent(props)}
                <div className="flex items-center justify-between mb-16 mx-24">
                  <div className="flex-row-container with-gutter">
                    <Typography variant="caption" color="textSecondary">Total results: {pageInfo?.totalRecords}</Typography>
                    {displaySourceIndicator && pageInfo?.totalPAS && <Badge badgeContent={pageInfo?.totalPAS}><Tooltip title="PAS"><div className="flex items-center"><IconComponent icon={<AccountSearchOutlineIcon />} /></div></Tooltip></Badge>}
                    {displaySourceIndicator && pageInfo?.totalRMS && <Badge badgeContent={pageInfo?.totalRMS}><Tooltip title="RMS"><div className="flex items-center"><IconComponent icon="account_circle" /></div></Tooltip></Badge>}
                  </div>
                  {showPaging && (
                  <Pagination
                    count={Math.ceil(pageInfo?.totalRecords / pageInfo?.pageSize) ?? 1}
                    page={pageInfo?.pageNumber ?? 1}
                    onChange={handleChangePage}
                    size="small"
                  />
                  )}
                </div>
              </div>
            )}
            renderActions={(
              <div className="flex-row-container with-gutter">
                <DefaultButton size="medium" label={continueLabel} disabled={selectedPatient === null} loading={isContinueLoading} onClick={onContinueAction} />
                <DefaultButton size="medium" label="Cancel" variant="text" color="default" onClick={onCancelAction} />
              </div>
            )}
            content={(<PatientSearchResults selectedPatient={selectedPatient} setSelectedPatient={setSelectedPatient} />)}
          />
        );
      }}
    </Formik>
  );
};

export default PatientSearchForm;
