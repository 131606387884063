import React from "react";
import { useSelector } from "react-redux";
import { find, isEmpty } from "lodash";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ContextItem } from "components/items/default-item";
import { toDOBFormat } from "app/main/patients/helpers/format-dob";
import { addressIsEmpty } from "app/main/patients/helpers/check-object-is-empty";
import { getPatientIdentifier } from "app/main/patients/helpers/get-patient-display-item";
import getPatientIdentifierLabel from "app/main/patients/helpers/get-patient-identifier-label";
import { getPrimaryPatientIdentifierSettings, getSexLabel, getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";
import formatDateTime from "helpers/format-date-time";
import formatDate from "helpers/format-date";

import LoadingState from "components/items/loading-state";
import { getAllCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";
import useReferralFieldSettings from "app/main/referrals/hooks/useReferralFieldSettings";
import useTriageFieldSettings from "app/main/referrals/hooks/useTriageFieldSettings";
import MarkdownDialog from "components/items/mark-down-dialog";

const useStyles = makeStyles(theme => ({
  headingBox: {
    backgroundColor: "#CBDEF3",
    padding: theme.spacing(1 / 2),
    border: "1px solid #002060",
    maxWidth: 615,
    width: "100%",
  },
  content: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
  container: {
    maxWidth: 615,
  },
}));

const Item = ({ multiple, ...props }) => (
  <ContextItem
    labelClassName="w-256 mr-16"
    renderIfEmpty
    alignment="items-start"
    className={clsx(multiple && "flex-wrap sm:flex-no-wrap")}
    {...props}
  />
);

const NoteContent = ({ children }) => {
  const classes = useStyles();

  if (!children) return <Typography className={classes.content}>Not Recorded</Typography>;
  return (
    <Typography className={classes.content}>{children}</Typography>
  );
};

const Header = ({ header }) => {
  const classes = useStyles();
  return (
    <div className={classes.headingBox}>
      <Typography variant="body2" className="font-bold">{header}</Typography>
    </div>
  );
};

const ReferralDetailsPanel = ({
  referral,
}) => {
  const { patient } = referral;
  const sexLabel = useSelector(getSexLabel);
  const primaryPatientIdentifierSettings = useSelector(getPrimaryPatientIdentifierSettings);
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const primaryPatientIdentifier = primaryPatientIdentifierSettings?.type;
  const primaryPatientIdentifierLabel = find(primaryPatientIdentifierSettings?.options, x => x.value === primaryPatientIdentifier)?.label ?? getPatientIdentifierLabel(primaryPatientIdentifier);
  const manualReferral = referral?.referralDocument.referralDocumentType === "Manual";
  const classes = useStyles();

  const codeSetValues = useSelector(getAllCodeSetValues);
  const displayCodeSetValue = codeSetValueId => find(codeSetValues, x => x.id === codeSetValueId)?.description;

  const { fieldSettings, fieldLabels } = useReferralFieldSettings(referral?.formFieldConfigurationId);
  const { loadingFieldSettings: loadingTriageSettings, fieldSettings: triageFieldSettings, fieldLabels: triageFieldLabels } = useTriageFieldSettings(referral?.orgUnitId);

  if (loadingTriageSettings) return <LoadingState />;

  let mbsConsentString = null;
  if (referral.referralDocument.mbsConsent != null) {
    mbsConsentString = referral.referralDocument.mbsConsent ? "Yes" : "No";
  }

  return (
    <>
      <Header header="Patient Information" />
      <div className={clsx(classes.container, "items-container my-8")}>
        {!patient && <Typography>{referralWorklistSettings?.noPatientAssignedMessage}</Typography>}
        {patient && (
        <>
          <Item label="Patient" content={patient.displayNameFull} />
          <Item label={primaryPatientIdentifierLabel} content={getPatientIdentifier(patient.patientIdentifiers, primaryPatientIdentifier)} />
          <Item label="Medicare Number" content={getPatientIdentifier(patient.patientIdentifiers, "MC")} />
          <Item label="DVA Number" content={getPatientIdentifier(patient.patientIdentifiers, "DVA")} />
          <Item label="DOB" content={toDOBFormat(patient.birthDate)} />
          <Item label="Age" content={patient.age} />
          <Item label={sexLabel} content={patient.sex} />
          <Item
            label="Residential Address"
            content={!addressIsEmpty(patient.address) ? `${patient.address.street}, ${patient.address.suburb}, ${patient.address.state}, ${patient.address.postcode}` : ""}
          />
          <Item label="Home Phone" content={patient.contactDetails.phoneHome} />
          <Item label="Mobile Phone" content={patient.contactDetails.phoneMobile} />
          <Item label="Work Phone" content={patient.contactDetails.phoneWork} />
        </>
        )}
      </div>
      <Header header="Referrer Information" />
      <div className={clsx(classes.container, "items-container my-8")}>
        {fieldSettings?.referrerFullName && <Item label={fieldLabels.referrerFullName} content={referral.referrerFullName} />}
        <Item label="Provider Number" content={referral.referrerProviderNumber} />
        {fieldSettings?.referrerOrganisationName && <Item label={fieldLabels.referrerOrganisationName} content={referral.referrerOrganisationName} />}
        {fieldSettings?.designation && (<Item label={fieldLabels.designation} content={referral.designationDisplayValue} />)}
        {fieldSettings?.referringOrgUnitId && (<Item label={fieldLabels.referringOrgUnitId} content={referral.referringOrgUnitName} />)}
        {fieldSettings?.referringLocation && (<Item label={fieldLabels.referringLocation} content={displayCodeSetValue(referral.referralDocument.referringLocationCodeId)} />)}
        {fieldSettings?.referringInternalLocation && (<Item label={fieldLabels.referringInternalLocation} content={displayCodeSetValue(referral.referralDocument.referringInternalLocationCodeId)} />)}
      </div>
      <Header header="Clinical Referral Information" />
      <div className={clsx(classes.container, "items-container my-8")}>
        <Item label="Referral Number" content={referral.referralNumber} />
        {fieldSettings?.mbsConsent && (<Item label={fieldLabels.mbsConsent} content={mbsConsentString} />)}
        {fieldSettings?.referrerUrgency && <Item label={fieldLabels.referrerUrgency} content={referral.referrerUrgency ? "Yes" : "No"} />}
        {fieldSettings?.referralDate && <Item label={fieldLabels.referralDate} content={referral.referralDateUtc ? formatDateTime(referral.referralDateUtc, true) : null} />}
        {/*
         Show the referral requirements field in Details when ReferralRequirementsFieldEnabled is set to true for the referral.
         Don't need to check if the current MeetsRequirements field is Enabled or not in the fieldSettings since we only
         care about its value at the moment the referral was saved.
        */}
        {referral.referralDocument.referralRequirementsFieldEnabled && (
          <div className={clsx(classes.container, "items-container my-8")}>
            {!isEmpty(referral.referralDocument.requirementsMarkdown) && <MarkdownDialog markdownText={referral.referralDocument.requirementsMarkdown} />}
            <Item label="Meets Requirements" content={referral.referralDocument.meetsRequirements ? "Yes" : "No"} />
          </div>
        )}
        {(fieldSettings?.referralPeriod || referral.referralPeriod) &&
          <Item label={fieldLabels.referralPeriod} content={referral.referralPeriod} />
        }
        <Item label="Referred to" content={referral.assignedTo?.assignedToSpecialtyName} />
        {fieldSettings?.assignToSubSpecialty && <Item label={fieldLabels.assignToSubSpecialty} content={referral.assignedTo?.assignedToSubSpecialtyDisplayName} />}
        {fieldSettings?.specialistConsultant && (<Item label={fieldLabels.specialistConsultant} content={referral.referralDocument.specialistConsultant} />)}
        {fieldSettings?.presentingComplaintNote && manualReferral && <Item label={fieldLabels.presentingComplaintNote} content={<NoteContent>{referral.presentingComplaintNote}</NoteContent>} multiple />}
        {fieldSettings?.presentingComplaintCodes && manualReferral
          && <Item label={fieldLabels.presentingComplaintCodes} content={<NoteContent>{referral.presentingComplaintSummary ? referral.presentingComplaintSummary : null}</NoteContent>} multiple />}
        {fieldSettings?.relevantPatientHistoryNote && <Item label={fieldLabels.relevantPatientHistoryNote} content={<NoteContent>{referral.relevantPatientHistoryNote}</NoteContent>} multiple />}
        {fieldSettings?.referrerAdvisedPriority && (<Item label={fieldLabels.referrerAdvisedPriority} content={displayCodeSetValue(referral.referralDocument.referrerAdvisedPriorityCodeId)} />)}
        {/* <----------------------------------------divider----------------------------------------> */}
        {fieldSettings?.typeOfReferral && (<Item label={fieldLabels.typeOfReferral} content={displayCodeSetValue(referral.referralDocument.typeOfReferralCodeId)} />)}
        {fieldSettings?.referralExpiry && (<Item label={fieldLabels.referralExpiry} content={formatDate(referral.referralDocument.referralExpiryDate)} />)}

        {/* <----------------------------------------divider----------------------------------------> */}
        {fieldSettings?.interpreterRequired && <Item label={fieldLabels.interpreterRequired} content={referral.interpreterRequired ? "Yes" : "No"} />}
        {fieldSettings?.interpreterRequired && referral.interpreterRequired && <Item label="Preferred Language" content={referral.preferredLanguage} />}
        {triageFieldSettings?.externalTriageStatus && (<Item label={triageFieldLabels.externalTriageStatus} content={displayCodeSetValue(referral.referralDocument.externalTriageStatusCodeId)} />)}
        {fieldSettings?.reasonForReferralCode && (<Item label={fieldLabels.reasonForReferralCode} content={displayCodeSetValue(referral.referralDocument.reasonForReferralCodeId)} />)}
        {fieldSettings?.reasonNote && <Item label={fieldLabels.reasonNote} content={<NoteContent>{referral.reasonNote}</NoteContent>} multiple />}
        {fieldSettings?.clinicType && (<Item label={fieldLabels.clinicType} content={displayCodeSetValue(referral.referralDocument.clinicTypeCodeId)} />)}
        {fieldSettings?.claimType && (<Item label={fieldLabels.claimType} content={displayCodeSetValue(referral.referralDocument.claimTypeCodeId)} />)}
        {triageFieldSettings?.episodeHealthCondition && <Item label={triageFieldLabels.episodeHealthCondition} content={referral.episodeHealthConditionSummary} />}
        {referral.attachmentCount > 0 && <Header header="File Attachments" /> }
      </div>
    </>
  );
};

export default ReferralDetailsPanel;
