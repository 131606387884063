import { useSelector } from "react-redux";
import { findIndex } from "lodash";
import { getUserAdminOptionalFields } from "../app/auth/store/reducers/system-configuration";

const useUserUniversalSearchConfig = () => {
  const enabledFields = useSelector(getUserAdminOptionalFields); // ProductConfiguration.UserAdminOptionalFields
  const isFieldEnabled = fieldName => findIndex(enabledFields, x => x === fieldName) !== -1;

  // Determines if the specialty should be enabled and displayed
  const isSpecialtyFieldEnabled = isFieldEnabled("SpecialtyProfileId");
  // Determines if the user role should be enabled and displayed
  const isUserRoleFieldEnabled = isFieldEnabled("UserRole");

  return ({
    isSpecialtyFieldEnabled,
    isUserRoleFieldEnabled,
  });
};

export default useUserUniversalSearchConfig;
