import moment from "moment";
import { formatUtcDate } from "helpers/format-date-time";

// do not display the year if given date is the same year
const displayDateFormat = date => {
  const sortDateFormat = "DD-MMM HH:mm";
  const longDateFormat = "DD-MMM-YY HH:mm";
  return moment(date).isSame(moment(), "year") ? sortDateFormat : longDateFormat;
};

export default function formatReferralActivityDate(date) {
  if (!date) return date;
  return formatUtcDate(date, displayDateFormat(date));
}
