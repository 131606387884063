import downloadFromApi from "utils/download-from-api";
import { ApiActions } from "middleware/call-api";
import { ActionTypes } from "../reducers/reportExport.reducers";

export const addExportJobList = exportJobList => ({
  type: ActionTypes.ADD_EXPORT_JOB_LIST,
  payload: exportJobList,
});

export const getExportJobList = (orgUnitId, userId) => async dispatch => {
  const url = `/reportExport/${orgUnitId}/${userId}/getAll`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "EXPORT_JOB_LIST",
    },
  });
};

export const downloadExportedReport = async (orgUnitId, exportId) => {
  const url = `api/reportExport/${orgUnitId}/${exportId}/download`;

  const response = await downloadFromApi(url);

  return response.statusText;
};

export const showExportIndicator = () => ({
  type: ActionTypes.SHOW_EXPORT_INDICATOR,
});

export const removeExportCompleteIndicator = () => ({
  type: ActionTypes.REMOVE_EXPORT_COMPLETE_INDICATOR,
});

export const clearExportCompleteJob = () => ({
  type: ActionTypes.CLEAR_EXPORT_COMPLETE_JOB,
});

export const clearExportedReport = (orgUnitId, exportId) => async dispatch => {
  const url = `/reportExport/${orgUnitId}/clear/${exportId}`;

  return dispatch({
    [ApiActions.DELETE_FROM_API]: {
      endpoint: url,
      entityType: "EXPORT_JOB",
      exportId,
    },
  });
};
