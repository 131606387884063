import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { map, isEmpty, find } from "lodash";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchReferralFormProfileForSelector } from "app/main/referralFormProfiles/actions/referralFormProfiles.actions";
import { SummaryIcon } from "helpers/icon-finder";

export default ({
  label,
  showIcon = true,
  initialOptions = [],
  loadInitialOption = false, // should load default option for existing value
  orgUnitId,
  onChange,
  ...other
}) => {
  const dispatch = useDispatch();
  const { field: { value } } = other; // consider existing value
  const [options, setOptions] = useState(initialOptions || []);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const shouldLoadInitialOption = loadInitialOption && value && isEmpty(initialOptions);

  const loadOptions = () => {
    if (orgUnitId) {
      setLoading(true);
      setOptions([]);
      dispatch(fetchReferralFormProfileForSelector(orgUnitId)).then(response => {
        if (response) {
          setOptions(response.error ? [] : response.payload.referralFormProfiles);
        } else {
          setOptions([]);
        }
      }).finally(() => {
        setLoading(false);
      });
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    if (shouldLoadInitialOption) {
      setInitialLoading(true);
      loadOptions();
      setInitialLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadInitialOption, orgUnitId]);

  const loadOptionsOnOpen = () => loadOptions();

  const onSelectChange = selectedValue => {
    if (onChange) {
      const selectedReferralFormProfile = find(options, x => x.id === selectedValue?.value);
      onChange(selectedReferralFormProfile);
    }
    setLoading(false);
  };

  if (initialLoading) return <AutoCompleteLoading />;

  return (
    <AutoComplete
      icon={showIcon && <SummaryIcon />}
      label={label}
      loading={loading}
      onOpen={shouldLoadInitialOption ? null : loadOptionsOnOpen}
      options={map(options, x => ({ label: x.name, value: x.id }))}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      onChange={onSelectChange}
      {...other}
    />
  );
};
