import React, { useRef, useEffect, useState } from "react";
import { get } from "lodash";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { AppBar } from "@material-ui/core";

import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import clsx from "clsx";
import SelectionIcon from "mdi-react/SelectionIcon";

import { setMobileContentSidebarStatus } from "app/store/actions/sidebars.actions";

import EmptyState from "components/items/empty-state";
import LoadingState from "components/items/loading-state";
import DefaultButton from "components/items/default-button";

const useStyles = makeStyles(theme => ({
  container: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
  },
  contentContainer: {
    width: "100%",
    flex: "1 1 100%",
    backgroundColor: theme.palette.background.panel,
    overflowX: "hidden",
    overflowY: "auto",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up("sm")]: {
      flex: "1 1 auto",
    },
  },
  toolbar: {
    width: "100%",
    flex: "0 0 auto",
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
  toolbarAction: {
    zIndex: 999,
    display: "flex",
    justifyContent: "center",
    "&:not(:empty)": {
      paddingBottom: 16,
    },
    [theme.breakpoints.up("md")]: {
      right: theme.spacing(4),
      bottom: -theme.spacing(2),
      position: "absolute",
      "&:not(:empty)": {
        paddingBottom: 0,
      },
    },
  },
  emptyState: {
    height: "100%",
    width: "100%",
    display: "flex",
    backgroundColor: theme.palette.background.paper,
  },
}));

const PanelContent = ({
  renderHeader,
  loading,
  emptyIcon,
  emptyTitle,
  renderEmptyContent,
  empty,
  renderContent,
  menuVariant,
  headerElevation = 10,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const theme = useTheme();
  const location = useLocation();
  const [width, setWidth] = useState(0);
  const [shadow, setShadow] = useState(1);

  const contentRef = useRef();

  const updateWidth = () => {
    setWidth(get(contentRef, ["current", "clientWidth"], 0));
  };

  const handleScroll = () => {
    if (contentRef && contentRef.current && contentRef.current.scrollTop > 10) {
      setShadow(headerElevation);
    } else {
      setShadow(1);
    }
  };

  useEffect(() => {
    if (contentRef && contentRef.current) {
      contentRef.current.scrollTop = 0;
    }
  }, [location.search]); // content will scroll to top when url changes

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => window.removeEventListener("resize", updateWidth);
  });

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, true);
    return () => window.removeEventListener("scroll", handleScroll);
  });

  const temporaryMenu = menuVariant === "temporary";
  const { palette: { secondary } } = theme;

  const openSidebar = () => dispatch(setMobileContentSidebarStatus(true));

  if (loading) {
    return <LoadingState />;
  }

  if (empty) {
    if (renderEmptyContent) {
      return renderEmptyContent();
    }

    return (
      <div className={classes.emptyState}>
        <EmptyState
          icon={emptyIcon || <SelectionIcon />}
          iconSize={64}
          iconOffset={60}
          onClick={temporaryMenu ? (() => openSidebar()) : null}
          color={temporaryMenu ? secondary.main : "inherit"}
          content={(
            <DefaultButton
              variant={temporaryMenu ? "outlined" : "text"}
              className="flex normal-case mt-16"
              disabled={!temporaryMenu}
              onClick={openSidebar}
              label={emptyTitle || "Select an item to view details.."}
            />
          )}
        />
      </div>
    );
  }

  return (
    <div id="main-content" className={classes.container}>
      <AppBar className={classes.toolbar} color="default" elevation={shadow}>
        {renderHeader && renderHeader()}
        <div
          id="header-action"
          className={classes.toolbarAction}
        />
      </AppBar>
      <div
        className={clsx(classes.contentContainer)}
        ref={contentRef}
      >
        {renderContent({ width })}
      </div>
    </div>
  );
};

export default PanelContent;
