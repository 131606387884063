import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { find } from "lodash";
import { fetchReferral } from "app/main/referrals/actions/referrals.actions";
import normaliseDateTime from "app/main/patients/helpers/normalise-date-time";
import { Typography } from "@material-ui/core";
import { ListItem } from "app/main/patients/components/patient-context-list-item";
import ItemStatus from "components/items/item-status";
import ReferralCategoryLabel from "app/main/referrals/components/referral-category-label";
import useReferralFieldSettings from "app/main/referrals/hooks/useReferralFieldSettings";
import PatientReferralLinkButton from "./patient-referral-link-button";

const getAssignedToSpecialtyDisplay = (assignedTo, orgUnitName) => {
  if (!assignedTo?.assignedToSpecialtyName) {
    return "No Assigned Specialty";
  }

  const subSpecialtyDisplay = assignedTo.assignedToSubSpecialtyDisplayName
    ? ` (${assignedTo.assignedToSubSpecialtyDisplayName})`
    : "";

  return `${assignedTo.assignedToSpecialtyName}${subSpecialtyDisplay} (${orgUnitName})`;
};

const getReferralFromDisplay = (referralFrom, referringOrgUnitName) => {
  if (!referralFrom) {
    return "No Referrer";
  }

  if (!referringOrgUnitName) {
    return `${referralFrom}`;
  }

  return `${referralFrom} (${referringOrgUnitName})`;
};

const PatientReferralsListItem = ({
  referralWorkflowSettings,
  fromUrl,
  isFirst,
  patientId,
  referral,
  orgUnitId,
  disabled,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { fieldSettings } = useReferralFieldSettings(referral.formFieldConfigurationId);
  const { id, referralStatus, referralDateUtc, triageCategory, isUrgent, referralFrom, orgUnitName, referringOrgUnitName, assignedTo, presentingComplaintSummary } = referral;
  const referralDate = normaliseDateTime(referralDateUtc);
  const status = find(referralWorkflowSettings, x => x.workflowState === referralStatus);
  const assignedToSpecialtyDisplayValue = getAssignedToSpecialtyDisplay(assignedTo, orgUnitName);
  const referralFromDisplayValue = getReferralFromDisplay(referralFrom, referringOrgUnitName);

  return (
    <ListItem
      key={id}
      isFirst={isFirst}
      onClick={disabled ? null : () => {
        dispatch(fetchReferral(orgUnitId, id))
          .then(() => history.push({
            pathname: `/patients/${patientId}/referrals/${id}`,
            fromUrl: fromUrl ?? `${history.location.pathname}?tab=referrals`,
          }));
      }}
    >
      <div className="flex justify-between flex-auto">
        <div className="flex-row-container w-full justify-between items-start md:items-start lg:items-center xl:items-center">
          <div className="flex flex-auto flex-col w-full sm:w-full md:w-6/12 lg:w-3/12 xl:w-3/12">
            <div>
              <Typography className="font-bold" variant="caption">For: </Typography>
              <Typography variant="caption">{assignedToSpecialtyDisplayValue}</Typography>
            </div>
            <div>
              <Typography className="font-bold" variant="caption">From: </Typography>
              <Typography variant="caption">{referralFromDisplayValue}</Typography>
            </div>
          </div>
          {presentingComplaintSummary && (
          <div className="flex flex-initial flex-col w-full sm:w-full md:w-6/12 lg:w-3/12 xl:w-3/12">
            <div>
              {(fieldSettings?.presentingComplaintCodes && presentingComplaintSummary) && <Typography className="font-bold" variant="caption">{fieldSettings.presentingComplaintCodes.label}: </Typography>}
              {presentingComplaintSummary && <Typography variant="caption">{presentingComplaintSummary}</Typography>}
            </div>
          </div>
          )}
          <div className="flex flex-initial flex-col sm:items-end md:items-start w-full sm:w-full md:w-6/12 lg:w-3/12 xl:w-3/12">
            {referralDate.date && <Typography variant="caption">{`${referralDate.date} ${referralDate.time}`}</Typography>}
            <div>
              <Typography className="font-bold" variant="caption">Referral Number: </Typography>
              <Typography variant="caption">{referral.referralNumber}</Typography>
            </div>
          </div>
          <div className="flex flex-initial flex-col md:items-start w-full sm:w-full md:w-6/12 lg:w-3/12 xl:w-3/12">
            <ItemStatus label={status?.label ?? referralStatus} color={status?.color} icon={status?.icon} />
            <ReferralCategoryLabel triageCategory={triageCategory} isUrgent={isUrgent} orgUnitId={referral.orgUnitId} />
          </div>
        </div>
        <div className="flex md:items-center">
          <PatientReferralLinkButton isDisabled={disabled} orgUnitId={orgUnitId} referralOrgUnitName={referral.orgUnitName} referralOrgUnitId={referral.orgUnitId} />
        </div>
      </div>
    </ListItem>
  );
};

export default PatientReferralsListItem;
