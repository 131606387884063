import React, { useState } from "react";
import { Field } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, mapKeys, mapValues, keys, pickBy } from "lodash";
import { InputLabel, makeStyles } from "@material-ui/core";

import Form from "components/form";
import { validateEmailFormat, validatePhoneFormat } from "utils/validators";
import { TextInput, CheckboxInput } from "components/inputs";
import { getUser } from "app/auth/store/reducers/user.reducer";
import { updateUserProfile } from "app/main/users/actions/users.actions";

const mobileNumberLabel = "Mobile Number";
const emailAddressLabel = "Email Address";
const notificationPreferenceLabel = "Notification Preferences";

const validateEmail = validateEmailFormat(emailAddressLabel);
const validatePhone = validatePhoneFormat(mobileNumberLabel);

const useStyles = makeStyles(theme => ({
  label: {
    marginLeft: theme.spacing(4),
  },
}));

const options = [
  { value: "Email", label: "Email" },
  { value: "SMS", label: "SMS" },
];

const schema = Yup.object().shape({
  userProfile: Yup.object().shape({
    phoneMobile: Yup.string().max(12, "Too long!").nullable(),
    email: Yup.string()
      .required(`${emailAddressLabel} is required`)
      .max(100, "Too long!").nullable(),
  }),
});

const UserProfileForm = props => {
  const dispatch = useDispatch();
  const currentUser = useSelector(getUser);
  const classes = useStyles();

  const { email, phoneMobile, notificationPreference } = currentUser;
  const [disableSMS, setDisableSMS] = useState(isEmpty(phoneMobile));
  const initialValues = { userProfile: { email, phoneMobile, notificationPreference: mapValues(mapKeys(notificationPreference, x => x), y => !isEmpty(y)) } };

  const handleSubmit = ({ userProfile }, { setErrors }) => {
    const data = { ...userProfile, notificationPreference: keys(pickBy(userProfile.notificationPreference, x => x === true)) };
    dispatch(updateUserProfile(data)).then(response => {
      if (response.error !== true) {
        props.onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  return (
    <Form
      initialValues={initialValues}
      onSubmit={handleSubmit}
      contentProps={props}
      validationSchema={schema}
      content={({ setFieldValue }) => (
        <div className="flex-1">
          <Field
            name="userProfile.email"
            component={TextInput}
            required
            label={emailAddressLabel}
            validate={validateEmail}
          />
          <Field
            name="userProfile.phoneMobile"
            label={mobileNumberLabel}
            component={TextInput}
            validate={validatePhone}
            onChange={value => {
              if (isEmpty(value)) {
                setFieldValue("userProfile.notificationPreference.SMS", false);
                setDisableSMS(true);
              } else if (disableSMS) {
                setDisableSMS(false);
              }
            }}
          />
          <>
            <InputLabel
              shrink
              className={classes.label}
              htmlFor={notificationPreferenceLabel}
            >
              {notificationPreferenceLabel}
            </InputLabel>
            <div className="flex-row-container with-gutter">
              {options.map(x => (
                <Field
                  name={`userProfile.notificationPreference.${x.label}`}
                  component={CheckboxInput}
                  disabled={x.label === "SMS" && disableSMS}
                  key={x.value}
                  label={x.label}
                />
              ))}
            </div>
          </>
        </div>
      )}
    />
  );
};

export default UserProfileForm;
