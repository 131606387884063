import React from "react";
import { InputLabel, Typography } from "@material-ui/core";
import checkIfEmpty from "helpers/check-if-empty";
import clsx from "clsx";

const DisabledInput = ({ label, value, disabledEmptyValue = "N/A", classes }) => (
  <>
    <InputLabel shrink htmlFor={label}>{label}</InputLabel>
    <div className={clsx("MuiInputBase-root MuiInputBase-input mt-16 flex", classes?.input)}>
      <Typography>{checkIfEmpty(value) ? disabledEmptyValue : value}</Typography>
    </div>
  </>
);

export default DisabledInput;
