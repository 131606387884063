import React from "react";
import AdvancedFilter, { FilterForm, FilterSection } from "components/items/advanced-filter";
import { useSelector, useDispatch } from "react-redux";
import DateRange from "components/inputs/date-range";
import { Field } from "formik";
import formatFilterDate from "helpers/format-filter-date";
import { SelectInput, TextInput } from "components/inputs";
import { getPrimaryPatientIdentifier } from "app/auth/store/reducers/system-configuration";
import { isEmpty } from "lodash";
import { DefaultSearchParams,
  getSearchParams,
  INTEGRATION_LOGS_PAGE_SIZE } from "./reducers/integrationLogs.reducers";
import { searchIntegrationLogsPageInfo } from "./actions/integrationLogs.actions";

const StatusOptions = [
  { value: "Queued", label: "Queued" },
  { value: "Processing", label: "Processing" },
  { value: "Sending", label: "Sending" },
  { value: "Sent", label: "Sent" },
  { value: "Processed", label: "Processed" },
  { value: "Failed", label: "Failed" },
];

const IntegrationLogsSearch = () => {
  const dispatch = useDispatch();
  const params = useSelector(getSearchParams);
  const PrimaryIdentifierType = useSelector(getPrimaryPatientIdentifier);

  const search = (startDateRange, endDateRange, referralNumber, patientIdentifier, status, messageType, messageId) => {
    dispatch(searchIntegrationLogsPageInfo(1, INTEGRATION_LOGS_PAGE_SIZE,
      { ...params,
        startDateRange,
        endDateRange,
        referralNumber,
        patientIdentifier,
        status,
        messageType,
        messageId }));
  };

  const handleReset = () => dispatch(searchIntegrationLogsPageInfo(1, INTEGRATION_LOGS_PAGE_SIZE, DefaultSearchParams));

  const hasFilterContent = !isEmpty(params.startDateRange)
    || !isEmpty(params.endDateRange)
    || !isEmpty(params.referralNumber)
    || !isEmpty(params.status)
    || !isEmpty(params.patientIdentifier)
    || !isEmpty(params.messageId)
    || !isEmpty(params.messageType);

  const searchParams = {
    ...params,
    dateRanges: [params.startDateRange, params.endDateRange],
  };

  return (
    <AdvancedFilter
      showSearchBar={false}
      filterContentAnchor="right"
      hasFilterValue={hasFilterContent}
      content={onClose => (
        <FilterForm
          initialValues={{ searchParams }}
          searchValue={searchParams.searchText}
          onSubmit={values => {
            search(
              formatFilterDate(values.searchParams.startDateRange),
              formatFilterDate(values.searchParams.endDateRange),
              values.searchParams.referralNumber,
              values.searchParams.patientIdentifier,
              values.searchParams.status,
              values.searchParams.messageType,
              values.searchParams.messageId,
            );
          }}
          onReset={handleReset}
          onClose={onClose}
          filters={({ setFieldValue }) => (
            <div>
              <FilterSection>
                <Field
                  label="Date range"
                  name="searchParams.dateRanges"
                  component={DateRange}
                  onChange={value => {
                    if (value) {
                      setFieldValue("searchParams.startDateRange", value[0]);
                      setFieldValue("searchParams.endDateRange", value[1]);
                    } else {
                      setFieldValue("searchParams.startDateRange", null);
                      setFieldValue("searchParams.endDateRange", null);
                    }
                  }}
                />
              </FilterSection>
              <FilterSection>
                <Field
                  label="Referral Number"
                  name="searchParams.referralNumber"
                  component={TextInput}
                  icon="search"
                />
              </FilterSection>
              <FilterSection>
                <Field
                  label={`Patient ${PrimaryIdentifierType}`}
                  name="searchParams.patientIdentifier"
                  component={TextInput}
                  icon="search"
                />
              </FilterSection>
              <FilterSection>
                <Field
                  label="Status"
                  name="searchParams.status"
                  component={SelectInput}
                  options={StatusOptions}
                  icon="search"
                />
              </FilterSection>
              <FilterSection>
                <Field
                  label="Message Type"
                  name="searchParams.messageType"
                  component={TextInput}
                  icon="search"
                />
              </FilterSection>
              <FilterSection>
                <Field
                  label="Message Id"
                  name="searchParams.messageId"
                  component={TextInput}
                  icon="search"
                />
              </FilterSection>
            </div>
          )}
        />
      )}
    />
  );
};

export default IntegrationLogsSearch;
