// selector-helper-text.js

/**
 * SelectorHelperText Component
 *
 * Purpose:
 * This component is used for maintaining consistent CSS layout across different selectors.
 *
 * Context:
 * Some components, like ProviderSelectorInput, use ProviderHelperText which occupies vertical space.
 * For consistency, we use this empty component in selectors that don't use ProviderHelperText.
 *
 * Usage:
 * Pass this component as a prop to selectors that don't use ProviderHelperText:
 * helperText={<SelectorHelperText />}
 *
 * Example:
 * <SomeSelector
 *   ...otherProps
 *   helperText={<SelectorHelperText />}
 * />
 *
 * Note:
 * This component renders nothing (returns null) but maintains the expected layout space.
 */
const SelectorHelperText = () => null;

export default SelectorHelperText;
