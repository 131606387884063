import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TableCell as NativeTableCell } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  editButton: {
    position: "absolute !important",
    top: 0,
    right: theme.spacing(3),
    [theme.breakpoints.up("lg")]: {
      position: "relative !important",
      top: "auto",
      right: "auto",
    },
  },
  content: {
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  },
}));

const TableCell = ({ title, isEditButton, children, ...other }) => {
  const classes = useStyles();
  return (
    <NativeTableCell size="small" data-title={title} className={clsx(isEditButton ? classes.editButton : classes.content)} {...other}>{children}</NativeTableCell>
  );
};

export default TableCell;
