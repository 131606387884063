import * as signalR from "@microsoft/signalr";
import { getSignalRHubAddress } from "utils/get-environment-variables";
import { getAccessToken } from "app/auth/msalConfig";

export const SignalRMessageTypes = {
  REFERRAL_VIEWED: "referralViewed",
  REFERRAL_STATUS_CHANGED: "referralStatusChanged",
  REPORT_EXPORT_PROGRESS_UPDATE: "ReportExportProgressUpdate",
};

class SignalRConnector {
  static instance;

  constructor(currentUser, errorCallback) {
    getAccessToken().then(token => {
      this.connection = new signalR.HubConnectionBuilder()
        .withUrl(getSignalRHubAddress(), {
          headers: { "X-HCS-Negotiation-Token": `Bearer ${token}` },
        })
        .withAutomaticReconnect()
        .build();

      this.connection.start().catch(err => {
        if (errorCallback) {
          errorCallback("There was a problem registering you for real-time updates.  The system should still operate correctly, but you will not receive real-time updates.");
        }
        /* eslint-disable no-console */
        console.error(err);
      /* eslint-enable no-console */
      });

      this.initiateMessageHandlers(SignalRMessageTypes.REFERRAL_STATUS_CHANGED);
      this.initiateMessageHandlers(SignalRMessageTypes.REPORT_EXPORT_PROGRESS_UPDATE);
      this.currentUser = currentUser;
    });
  }

  addMessageHandler = (messageType, messageHandler) => {
    document.addEventListener(`signalR_${messageType}`, messageHandler);
  };

  removeMessageHandler = (messageType, messageHandler) => {
    document.removeEventListener(`signalR_${messageType}`, messageHandler);
  };

  initiateMessageHandlers = messageType => {
    this.connection.on(messageType, (...args) => {
      const event = new CustomEvent(`signalR_${messageType}`, { detail: args });
      document.dispatchEvent(event);
    });
  };

  static getInstance(currentUser, errorCallback) {
    if (!SignalRConnector.instance) {
      SignalRConnector.instance = new SignalRConnector(currentUser, errorCallback);
    }
    return SignalRConnector.instance;
  }
}

export default SignalRConnector.getInstance;
