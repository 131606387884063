import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "formik";
import { FormControlLabel, Checkbox, Box } from "@material-ui/core";
import { some, map, find } from "lodash";
import { ReferralOrgIcon, TagHeartIcon, NoteIcon, PriorityIcon } from "helpers/icon-finder";

import { NoteInput, TextInput } from "components/inputs/text-input";
import RadioButtonsGroup from "components/inputs/radio-buttons-group";
import { acceptReferralAction } from "app/main/referrals/actions/referrals.actions";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";
import Form from "components/form";

import { getEnabledTriageCategorySettings, getEnableReadOnly } from "app/auth/store/reducers/system-configuration";
import SpecialtyCodeSetSelector from "app/main/specialtyProfiles/components/specialty-code-set-selector";
import ReferralWarningMessage from "app/main/referrals/components/referral-warning-message-alert";

const AcceptReferralForm = ({
  orgUnitId,
  referralId,
  referralDocumentId,
  onSucceed,
  schema,
  warningMessages,
  fieldSettings,
  fieldLabels,
  episodeHealthConditionMaxLength,
  specialtyId,
  defaultEpisodeHealthConditions,
  ...other
}) => {
  const dispatch = useDispatch();

  const initialValues = schema.cast();
  const triageCategories = useSelector(getEnabledTriageCategorySettings);
  const urgentTriageCategory = find(triageCategories, x => x.displayUrgentFlag === true)?.triageCategory;
  const enableReadOnly = useSelector(getEnableReadOnly);
  if (defaultEpisodeHealthConditions) {
    initialValues.referral.episodeHealthConditionValues = [].concat(defaultEpisodeHealthConditions);
  }

  const handleSubmit = ({ referral }, { setSubmitting, setErrors }) => {
    let { episodeHealthConditions } = referral;

    if (episodeHealthConditions) {
      episodeHealthConditions = map(referral.episodeHealthConditionValues, x => ({
        code: x.value,
        description: x.label,
      }));
    }

    const acceptReferral = {
      referralId,
      referralDocumentId,
      episodeHealthConditions,
      triageCategory: referral.triageCategory,
      isUrgent: referral.isUrgent,
      note: referral.note,
      clinicCodeId: referral.clinicCodeId?.value ?? referral.clinicCodeId,
      clinicTypeCodeId: referral.clinicTypeCodeId?.value ?? referral.clinicTypeCodeId,
      claimTypeCodeId: referral.claimTypeCodeId?.value ?? referral.claimTypeCodeId,
      reasonForReferralCodeId: referral.reasonForReferralCodeId?.value ?? referral.reasonForReferralCodeId,
      reasonNote: referral.reasonNote,
      externalTriageStatusCodeId: referral.externalTriageStatusCodeId?.value ?? referral.externalTriageStatusCodeId,
    };

    dispatch(acceptReferralAction(orgUnitId, acceptReferral)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const IsUrgentControl = props => (
    <FormControlLabel
      control={(
        <Checkbox
          onChange={props.onChange}
          value="isUrgent"
          checked={props.checked}
          required={fieldSettings.isUrgent.required}
          disabled={props.disabled || (enableReadOnly && fieldSettings.isUrgent.enabledReadOnly)}
        />
      )}
      label={fieldLabels.isUrgent}
    />
  );

  return (
    <Form
      contentProps={other}
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      variant="filled"
      content={({ values, setFieldValue }) => {
        const categoryChoices = map(triageCategories, category => ({
          value: category.triageCategory,
          label: (<Box color={category.color} fontWeight="bold">{category.label}</Box>),
          description: category.description,
          renderExtraContent: category.triageCategory === urgentTriageCategory && fieldSettings?.isUrgent && (
            <Box color={category.color}>
              <IsUrgentControl
                checked={values.referral.isUrgent ?? false}
                onChange={() => setFieldValue("referral.isUrgent", !values.referral.isUrgent)}
                disabled={values.referral.triageCategory !== urgentTriageCategory}
              />
            </Box>
          ),
        }));
        const multipleEpisodeHealthConditions = episodeHealthConditionMaxLength > 1;

        return (
          <>
            <ReferralWarningMessage warningMessages={warningMessages} />
            {fieldSettings?.referrerAdvisedPriority && (
              <Field
                name="referral.referrerAdvisedPriorityCodeId"
                component={CodeSetValueSelector}
                codeSetTypeCode={fieldSettings.referrerAdvisedPriority.fieldName}
                label={fieldLabels.referrerAdvisedPriority}
                required={fieldSettings.referrerAdvisedPriority.required}
                disabled={enableReadOnly && fieldSettings.referrerAdvisedPriority.enabledReadOnly}
                icon={<PriorityIcon />}
                disabledEmptyValue="None Recorded"
                allowHideOnForms
              />
            )}
            {/* <div className="ml-32"> */}
            <Field
              name="referral.triageCategory"
              component={RadioButtonsGroup}
              choices={categoryChoices}
              onChange={value => {
                if (value !== urgentTriageCategory) {
                  setFieldValue("referral.isUrgent", false);
                }
              }}
            />
            {/* </div> */}
            {fieldSettings?.externalTriageStatus && (
              <Field
                name="referral.externalTriageStatusCodeId"
                component={CodeSetValueSelector}
                codeSetTypeCode={fieldSettings.externalTriageStatus.fieldName}
                label={fieldLabels.externalTriageStatus}
                required={fieldSettings.externalTriageStatus.required}
                disabled={enableReadOnly && fieldSettings.externalTriageStatus.enabledReadOnly}
                icon={<ReferralOrgIcon />}
                allowHideOnForms
              />
            )}
            {fieldSettings?.reasonForReferralCode && (
              <Field
                name="referral.reasonForReferralCodeId"
                component={CodeSetValueSelector}
                icon={<NoteIcon />}
                codeSetTypeCode={fieldSettings.reasonForReferralCode.fieldName}
                label={fieldLabels.reasonForReferralCode}
                required={fieldSettings.reasonForReferralCode.required}
                disabled={enableReadOnly && fieldSettings.reasonForReferralCode.enabledReadOnly}
                allowHideOnForms
              />
            )}
            {fieldSettings?.reasonNote && (
              <Field
                name="referral.reasonNote"
                component={TextInput}
                icon={<NoteIcon />}
                label={fieldLabels.reasonNote}
                required={fieldSettings.reasonNote.required}
                disabled={enableReadOnly && fieldSettings.reasonNote.enabledReadOnly}
              />
            )}
            {fieldSettings?.clinicType && (
              <Field
                name="referral.clinicTypeCodeId"
                component={SpecialtyCodeSetSelector}
                specialtyId={specialtyId}
                label={fieldLabels.clinicType}
                codeSetTypeCode={fieldSettings.clinicType.fieldName}
                required={fieldSettings.clinicType.required}
                disabled={enableReadOnly && fieldSettings.clinicType.enabledReadOnly}
                icon={<ReferralOrgIcon />}
                allowHideOnForms
              />
            )}
            {fieldSettings?.claimType && (
              <Field
                name="referral.claimTypeCodeId"
                component={CodeSetValueSelector}
                icon="list_alt"
                codeSetTypeCode={fieldSettings.claimType.fieldName}
                label={fieldLabels.claimType}
                required={fieldSettings.claimType.required}
                disabled={enableReadOnly && fieldSettings.claimType.enabledReadOnly}
                allowHideOnForms
              />
            )}
            {fieldSettings?.clinic && (
              <Field
                name="referral.clinicCodeId"
                component={SpecialtyCodeSetSelector}
                label={fieldLabels.clinic}
                specialtyId={specialtyId}
                codeSetTypeCode={fieldSettings.clinic.fieldName}
                required={fieldSettings.clinic.required}
                disabled={enableReadOnly && fieldSettings.clinic.enabledReadOnly}
                icon={<ReferralOrgIcon />}
                allowHideOnForms
              />
            )}
            {fieldSettings?.episodeHealthCondition && (
              <Field
                name="referral.episodeHealthConditions"
                component={SpecialtyCodeSetSelector}
                label={fieldLabels.episodeHealthCondition}
                codeSetTypeCode={fieldSettings.episodeHealthCondition.fieldName}
                required={fieldSettings.episodeHealthCondition.required}
                disabled={enableReadOnly && fieldSettings.episodeHealthCondition.enabledReadOnly}
                icon={<TagHeartIcon />}
                multiple={multipleEpisodeHealthConditions}
                multiline
                limitTags={episodeHealthConditionMaxLength}
                isTermSelector
                specialtyId={specialtyId}
                defaultValue={defaultEpisodeHealthConditions}
                onChange={value => {
                  const valueArray = [].concat(value);
                  if (some(valueArray, x => x?.settings?.isUrgent === true)) {
                    setFieldValue("referral.triageCategory", urgentTriageCategory);
                    setFieldValue("referral.isUrgent", true);
                  }

                  // use episodeHealthConditionValues to set episodeHealthConditions on submit
                  setFieldValue("referral.episodeHealthConditionValues", valueArray);
                }}
                allowHideOnForms
              />
            )}
            {fieldSettings?.note && (
              <Field
                name="referral.note"
                component={NoteInput}
                label={fieldLabels.note}
                required={fieldSettings.note.required}
                disabled={enableReadOnly && fieldSettings.note.enabledReadOnly}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default AcceptReferralForm;
