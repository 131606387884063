import React from "react";
import { useDispatch } from "react-redux";
import DialogPopup from "components/items/dialog-popup";
import { closeDialog } from "app/store/actions/dialog.actions";
import DefaultButton from "components/items/default-button";
import MarkdownPreview from "components/items/mark-down-preview";

const MarkdownPreviewDialog = ({ content }) => {
  const dispatch = useDispatch();

  return (
    <DialogPopup
      title="Preview"
      content={(
        <MarkdownPreview markdownText={content} />
      )}
      renderActions={<DefaultButton color="default" onClick={() => dispatch(closeDialog())} label="Close" size="medium" variant="text" />}
    />
  );
};

export default MarkdownPreviewDialog;
