import React from "react";
import BaseTextField from "components/inputs/components/base-text-field";
import IconButton from "components/items/icon-button";
import UploadButton from "./upload-button";

const FileUploadInput = ({
  icon,
  disabled,
  onFileUpload,
  onRemove,
  loading,
  value,
  error,
  label,
  touched,
  acceptTypes,
}) => (
  <BaseTextField
    loading={loading}
    className="cursor-pointer flex-1"
    icon={icon}
    disabled={disabled}
    endAdornment={(
      <div className="absolute right-0 z-50 flex">
        {onRemove && <IconButton title="Remove" icon="clear" disabled={loading} onClick={onRemove} />}
        <UploadButton disabled={loading} onChange={onFileUpload} acceptTypes={acceptTypes} />
      </div>
    )}
    error={error}
    label={label}
    touched={touched}
    value={value}
  />
);

export default FileUploadInput;
