import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SortIcon from "mdi-react/SortIcon";
import { Field } from "formik";
import * as Yup from "yup";
import { map, startCase, filter, includes } from "lodash";

import { CheckboxInput, TextInput, AutoComplete } from "components/inputs";
import { getAllAssignableRoles, getAllowReferralProfileFormTypes, getEnableTypeOfReferralForFormProfile } from "app/auth/store/reducers/system-configuration";
import { getOrgUnitFormFieldConfigs } from "app/main/orgUnits/reducers/orgUnit.reducers";
import Form from "components/form";
import { addNewReferralFormProfile, updateExistingReferralFormProfile } from "../actions/referralFormProfiles.actions";
import { TypeIcon } from "helpers/icon-finder";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";

const name = "Profile Name";
const formTypeLabel = "Create Referral Form Configuration";
const sortOrderLabel = "Profile Sort Order";
const isDefaultLabel = "Is Default Form Profile";
const enableDraftLabel = "Save as Draft Enabled";
const enableEditInRequestLabel = "Allow Editing of Requested Referrals";
const enableNotificationLabel = "Show Popup Notification on Save";
const setCurrentUserAsReferrerLabel = "Default Current User as the Referrer";
const setCurrentSiteAsReferringLabel = "Default Current Site as the Referring Site";
const disableSourceDocumentUploadLabel = "Disable Source Document Upload";
const restrictToSpecificRolesLabel = "Restrict to Roles";
const currentReferralFormProfileRolesLabel = "Enable Roles";
const typeOfReferralLabel = "Type of Referral";

const schema = Yup.object().shape({
  referralFormProfile: Yup.object().shape({
    name: Yup.string()
      .max(255, "Too long!")
      .required(`${name} is required`)
      .nullable(),
    formFieldConfigurationId: Yup.string().required(`${formTypeLabel} is required`).nullable(),
    sortOrder: Yup.number()
      .transform((value, originalValue) => (originalValue === "" ? 0 : value)) // ensure an empty string is converted to number 0 before validation
      .default(0).nullable(),
    isDefault: Yup.boolean().nullable().default(false),
    enableDraft: Yup.boolean().nullable().default(false),
    enableEditInRequest: Yup.boolean().nullable().default(false),
    enableNotification: Yup.boolean().nullable().default(false),
    restrictToSpecificRoles: Yup.boolean().nullable().default(false),
    disableSourceDocumentUpload: Yup.boolean().nullable().default(false),
    currentReferralFormProfileRoles: Yup.string().nullable(),
  }),
});

const ReferralFormProfileForm = ({
  orgUnitId,
  onSucceed,
  referralFormProfile: currentReferralFormProfile,
  referralFormProfileId = null,
  ...other
}) => {
  const dispatch = useDispatch();
  const referralProfileFormTypes = useSelector(getAllowReferralProfileFormTypes);
  const orgUnitFormFieldConfigs = useSelector(getOrgUnitFormFieldConfigs);
  const allowReferralProfileFormTypes = filter(orgUnitFormFieldConfigs, currentFormType => currentFormType.orgUnitId === orgUnitId && includes(referralProfileFormTypes, currentFormType.formType));
  const allowReferralProfileFormTypesOptions = map(allowReferralProfileFormTypes, x => ({
    value: x.id,
    label: x.name || startCase(x.formType),
  }));
  const allAssignableRoles = useSelector(getAllAssignableRoles);
  const roleOptions = map(allAssignableRoles, role => ({ value: role.roleId, label: role.roleDescription ?? startCase(role.roleName) }));
  const enableTypeOfReferralField = useSelector(getEnableTypeOfReferralForFormProfile);

  const handleSubmit = ({ referralFormProfile }, { setSubmitting, setErrors }) => {
    const apiCall = referralFormProfileId ? updateExistingReferralFormProfile : addNewReferralFormProfile;

    const data = {
      ...referralFormProfile,
      formType: referralFormProfile.formType?.value ?? referralFormProfile.formType,
      orgUnitId,
    };

    dispatch(apiCall(data)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed(responds.payload.referralFormProfile.id);
      } else {
        setErrors(responds.payload);
      }
    });
  };

  let { referralFormProfile } = schema.cast();

  if (currentReferralFormProfile) {
    referralFormProfile = currentReferralFormProfile;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ referralFormProfile }}
      validationSchema={schema}
      isModal={false}
      {...other}
      content={({ setFieldValue, values }) => (
        <>
          <Field
            name="referralFormProfile.name"
            label={name}
            type="text"
            component={TextInput}
          />
          <Field
            name="referralFormProfile.formFieldConfigurationId"
            label={formTypeLabel}
            component={AutoComplete}
            options={allowReferralProfileFormTypesOptions}
            onChange={value => setFieldValue("referralFormProfile.formFieldConfigurationId", value?.value)}
          />
          <Field
            name="referralFormProfile.sortOrder"
            component={TextInput}
            label={sortOrderLabel}
            type="number"
            icon={<SortIcon />}
          />
          {enableTypeOfReferralField &&
            <Field
              name="referralFormProfile.typeOfReferralCodeId"
              component={CodeSetValueSelector}
              codeSetTypeCode="TypeOfReferral"
              label={typeOfReferralLabel}
              required={false}
              icon={<TypeIcon />}
              onChange={type => setFieldValue("referralFormProfile.typeOfReferralCodeId", type?.value ?? type?.inputValue)}
            />
          }
          <Field
            name="referralFormProfile.isDefault"
            component={CheckboxInput}
            label={isDefaultLabel}
            disabled={referralFormProfile.isDefault}
          />
          <Field
            name="referralFormProfile.enableDraft"
            component={CheckboxInput}
            label={enableDraftLabel}
          />
          <Field
            name="referralFormProfile.enableEditInRequest"
            component={CheckboxInput}
            label={enableEditInRequestLabel}
          />
          <Field
            name="referralFormProfile.enableNotification"
            component={CheckboxInput}
            label={enableNotificationLabel}
          />
          <Field
            name="referralFormProfile.setCurrentUserAsReferrer"
            component={CheckboxInput}
            label={setCurrentUserAsReferrerLabel}
          />
          <Field
            name="referralFormProfile.setCurrentSiteAsReferring"
            component={CheckboxInput}
            label={setCurrentSiteAsReferringLabel}
          />
          <Field
            name="referralFormProfile.disableSourceDocumentUpload"
            component={CheckboxInput}
            label={disableSourceDocumentUploadLabel}
          />
          <Field
            name="referralFormProfile.restrictToSpecificRoles"
            component={CheckboxInput}
            label={restrictToSpecificRolesLabel}
            onChange={val => {
              if (!val) {
                setFieldValue("referralFormProfile.currentReferralFormProfileRoles", []);
              }
            }}
          />
          {values.referralFormProfile.restrictToSpecificRoles && (
            <Field
              name="referralFormProfile.currentReferralFormProfileRoles"
              label={currentReferralFormProfileRolesLabel}
              component={AutoComplete}
              options={roleOptions}
              multiple
            />
          )}
        </>
      )}
    />
  );
};

export default ReferralFormProfileForm;
