import React, { useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { find, map, isEmpty, filter, includes } from "lodash";
import { Field } from "formik";
import { Alert } from "@material-ui/lab";
import useReferralFieldSettings from "app/main/referrals/hooks/useReferralFieldSettings";

import Form from "components/form";
import LoadingState from "components/items/loading-state";
import { TextInput } from "components/inputs/text-input";
import { getReferralWorklistSettings, getPrimaryPatientIdentifierSettings, showExternalPatientSearch, enablePatientIdentifierSearch, getAllowUserAssignment, getOnlyAllowRequestedReferralsAssignedSiteEdit, getAllowReassignSpecialtyStates, getAllowReassignSubSpecialtyStates, getAllowReassignRoleStates } from "app/auth/store/reducers/system-configuration";
import withPermissions from "permissions/withPermissions";
import "utils/yup-validation";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import ReferralReAssignmentForm from "app/main/referrals/components/referral-reassignment-form";
import PatientSearchButton from "app/main/patients/components/external-patient-search-button";
import getPatientIdentifierLabel from "app/main/patients/helpers/get-patient-identifier-label";
import { NoteInput, AutoComplete } from "components/inputs";
import useReferralAssignmentFieldSettings from "app/main/referrals/hooks/useReferralAssignmentFieldSettings";

import AccountCircleIcon from "mdi-react/AccountCircleIcon";
import { AssignedUserIcon, OrgUnitsIcon, SpecialtyIcon } from "helpers/icon-finder";

import { getOrgUnitsForOrgUnitsSelector, isOrgUnitsSelectorLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { fetchOrgUnitsForSelector } from "app/main/orgUnits/actions/orgUnit.actions";
import SpecialtySelector from "app/main/specialtyProfiles/components/specialty-selector";
import SubSpecialtySelector from "app/main/specialtyProfiles/components/sub-specialty-selector";
import SpecialtyRoleSelector from "app/main/specialtyProfiles/components/specialty-role-selector";
import { fetchAllSpecialtySpecialists } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { getSpecialtySpecialists, areSpecialtySpecialistsLoading } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import { saveReferralAssignedUser, fetchReferral } from "app/main/referrals/actions/referrals.actions";
import { resetPatientSearch } from "app/main/patients/actions/patientsSearch.actions";

const ReferralAssignmentForm = ({
  orgUnitId,
  referral,
  loading,
  onSucceed,
  patientIdentifier,
  forceLoad,
  hasPermissionPatientsIdentifiersAssign,
  hasPermissionPatientsIdentifiersUpdate,
  hasPermissionReferralsAssignedSiteEdit,
  ...other
}) => {
  const dispatch = useDispatch();
  const [reassignment, setReassignment] = useState(false);
  const settings = useSelector(getReferralWorklistSettings);
  const primaryPatientIdentifierSettings = useSelector(getPrimaryPatientIdentifierSettings);
  const showIdentifier = settings?.requirePrimaryIdentifierOnAssignment ?? false;
  const max = primaryPatientIdentifierSettings?.maxLength;
  const min = primaryPatientIdentifierSettings?.minLength;
  const primaryPatientIdentifier = primaryPatientIdentifierSettings?.type;
  const numericIdentifiersOnly = primaryPatientIdentifierSettings?.numericIdentifiersOnly;
  const onlyAllowRequestedReferralsAssignedSiteEdit = useSelector(getOnlyAllowRequestedReferralsAssignedSiteEdit);

  const hasPrimaryPatientIdentifier = find(referral.patient?.patientIdentifiers, x => x.patientIdentifierTypeCode === primaryPatientIdentifier && !x.isDeprecated);
  const showIdentifierField = showIdentifier && !hasPrimaryPatientIdentifier;

  const enabledExternalPatientSearch = useSelector(showExternalPatientSearch);
  const isEnablePatientIdentifierSearch = useSelector(enablePatientIdentifierSearch);
  const primaryPatientIdentifierLabel = find(primaryPatientIdentifierSettings?.options, x => x.value === primaryPatientIdentifier)?.label ?? getPatientIdentifierLabel(primaryPatientIdentifier);

  const isOrgUnitLoading = useSelector(isOrgUnitsSelectorLoading);
  const orgUnits = useSelector(getOrgUnitsForOrgUnitsSelector);
  const orgUnitsOptions = map(filter(orgUnits, x => !x.isDeprecated), orgUnit => ({ label: orgUnit.name, value: orgUnit.id }));
  const allowUserAssignment = useSelector(getAllowUserAssignment);
  const specialtySpecialists = useSelector(getSpecialtySpecialists);
  const loadingSpecialtySpecialists = useSelector(areSpecialtySpecialistsLoading);
  const [currentReferralOrgUnitId, setCurrentReferralOrgUnitId] = useState(null);

  const { loadingFieldSettings, fieldSettings, fieldLabels } = useReferralAssignmentFieldSettings(currentReferralOrgUnitId, primaryPatientIdentifierLabel, forceLoad);
  const { fieldSettings: referralFieldSettings } = useReferralFieldSettings(referral.formFieldConfigurationId);
  const skipReAssignmentForm = (!isEmpty(fieldSettings?.subSpecialty) || isEmpty(referralFieldSettings?.assignToSubSpecialty))
                               && isEmpty(referralFieldSettings?.specialistConsultant) && isEmpty(referral?.referralDocument.specialistConsultant)
                               && isEmpty(referralFieldSettings?.clinicType) && isEmpty(referral?.referralDocument.clinicTypeCodeId)
                               && isEmpty(referralFieldSettings?.presentingComplaintCodes) && isEmpty(referral.presentingComplaints) && referral?.referralDocument.hasReferralCriteria !== true;

  const { referralStatus } = referral;
  const AllowReassignSpecialtyStates = useSelector(getAllowReassignSpecialtyStates);
  const allowReassignSpecialtyStates = includes(AllowReassignSpecialtyStates, referralStatus);

  const AllowReassignSubSpecialtyStates = useSelector(getAllowReassignSubSpecialtyStates);
  const allowReassignSubSpecialtyStates = includes(AllowReassignSubSpecialtyStates, referralStatus);

  const AllowReassignRoleStates = useSelector(getAllowReassignRoleStates);
  const allowReassignRoleStates = includes(AllowReassignRoleStates, referralStatus);

  React.useEffect(() => {
    if (currentReferralOrgUnitId === null) {
      setCurrentReferralOrgUnitId(referral.orgUnitId ?? orgUnitId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    if (allowUserAssignment && currentReferralOrgUnitId && !loadingSpecialtySpecialists) {
      dispatch(fetchAllSpecialtySpecialists(currentReferralOrgUnitId));
    }
    dispatch(fetchOrgUnitsForSelector({ orgUnitTypes: ["Site"] }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowUserAssignment, currentReferralOrgUnitId, loadingSpecialtySpecialists]);

  const identifierLabel = `${primaryPatientIdentifierLabel} ${fieldLabels.patientIdentifier}`;

  let initialValues = {
    assignReferral: {
      identifier: patientIdentifier,
      orgUnitId: currentReferralOrgUnitId,
      showIdentifierField,
      assignedTo: referral.assignedTo,
      reason: "",
    },
  };
  if (currentReferralOrgUnitId === referral.orgUnitId) {
    initialValues = {
      assignReferral: {
        ...initialValues.assignReferral,
        assignedToSpecialtyId: referral.assignedTo?.assignedToSpecialtyId ? { value: referral.assignedTo?.assignedToSpecialtyId, label: referral.assignedTo?.assignedToSpecialtyName } : null,
        assignedToSubSpecialtyId: referral.assignedTo?.assignedToSubSpecialtyId ? { value: referral.assignedTo?.assignedToSubSpecialtyId, label: referral.assignedTo?.assignedToSubSpecialtyDisplayName } : null,
        assignedToRoleId: referral.assignedTo?.assignedToRoleId,
      },
    };
  }

  const schema = Yup.object().shape({
    assignReferral: Yup.object().shape({
      orgUnitId: Yup.string()
        .fieldSettingsValidation(fieldLabels.orgUnit, fieldSettings?.orgUnit)
        .nullable(),
      showIdentifierField: Yup.bool(),
      identifier: Yup.string()
        .when("showIdentifierField", {
          is: true,
          then: Yup.string()
            .fieldSettingsValidation(identifierLabel, fieldSettings?.patientIdentifier)
            .validateIdentifier(identifierLabel, { min, max, numberOnly: numericIdentifiersOnly })
            .nullable(),
        })
        .nullable(),
      assignedToSpecialtyId: Yup.string()
        .fieldSettingsValidation(fieldLabels.specialty, fieldSettings?.specialty)
        .nullable(),
      assignedToSubSpecialtyId: Yup.string()
        .fieldSettingsValidation(fieldLabels.subSpecialty, fieldSettings?.subSpecialty)
        .nullable(),
      assignedToRoleId: Yup.string()
        .fieldSettingsValidation(fieldLabels.specialtyRole, fieldSettings?.specialtyRole)
        .nullable(),
      reason: Yup.string()
        .max(250, "Too Long")
        .fieldSettingsValidation(fieldLabels.reason, fieldSettings?.reason)
        .nullable(),
    }),
  });

  let patientSearchFilter = {};
  if (referral?.patient) {
    const patient = referral?.patient;
    patientSearchFilter = {
      givenName: patient.givenName,
      middleName: patient.middleName,
      familyName: patient.familyName,
      dateOfBirth: patient.birthDate,
      sex: patient.sex,
      dvaNumber: find(referral?.patient?.patientIdentifiers, x => x.patientIdentifierTypeCode === "DVA")?.identifier,
      medicareNumber: find(referral?.patient?.patientIdentifiers, x => x.patientIdentifierTypeCode === "MC")?.identifier,
    };
  }

  const openReferralReAssignmentForm = (title, assignedPatientIdentifier, originalReferral, onSucceedAction, hasPermission) => {
    dispatch(openDialog({
      maxWidth: "sm",
      children: (
        <ReferralAssignmentForm
          title={title}
          patientIdentifier={assignedPatientIdentifier}
          referral={originalReferral}
          onSucceed={onSucceedAction}
          orgUnitId={orgUnitId}
          hasPermissionPatientsIdentifiersAssign={hasPermission}
        />
      ),
    }));
  };

  const getConvertedAssigningReferral = assignReferral => {
    const assignedToSpecialtyId = assignReferral.assignedToSpecialtyId?.value ?? assignReferral.assignedToSpecialtyId;
    const assignedToRoleId = assignReferral.assignedToRoleId?.value ?? assignReferral.assignedToRoleId;
    const assignedToSubSpecialtyId = assignReferral.assignedToSubSpecialtyId?.value ?? assignReferral.assignedToSubSpecialtyId;
    const assignedToUserId = assignReferral.assignedToUserId?.value ?? assignReferral.assignedToUserId;

    return {
      referralId: referral.id,
      orgUnitId: assignReferral.orgUnitId?.value ?? assignReferral.orgUnitId,
      assignedToSpecialtyId,
      assignedToRoleId,
      assignedToSubSpecialtyId,
      assignedToUserId,
      identifier: assignReferral.identifier,
      reason: assignReferral.reason,
      assignedTo: {
        assignedToSpecialtyId,
        assignedToDisplayName: assignReferral.assignedToSpecialtyId?.label ?? assignReferral.assignedTo?.assignedToSpecialtyName,
        assignedToSubSpecialtyId,
        assignedToSubSpecialtyDisplayName: assignedToSubSpecialtyId ? assignReferral.assignedToSubSpecialtyId?.label ?? assignReferral.assignedTo?.assignedToSubSpecialtyDisplayName : "",
        assignedToRoleId,
        assignedToRoleDisplayName: assignedToRoleId ? assignReferral.assignedToRoleId?.label ?? assignReferral.assignedTo?.assignedToRoleDisplayName : "",
      },
      assignSubSpecialty: !isEmpty(fieldSettings?.subSpecialty),
    };
  };

  const handleSubmit = ({ assignReferral }, { setErrors, setSubmitting }) => {
    const convertedAssigningReferral = getConvertedAssigningReferral(assignReferral);
    if (reassignment && !skipReAssignmentForm) {
      // open to reassignment form
      setSubmitting(false);
      return dispatch(openDialog({
        children: (
          <ReferralReAssignmentForm
            title="Re-Assign Referral - Update Referral Details"
            referral={referral}
            orgUnitId={orgUnitId}
            onSucceed={assignedReferral => {
              if (orgUnitId !== currentReferralOrgUnitId) {
                onSucceed(assignedReferral);
              } else {
                dispatch(closeDialog());
                // reload the current referral to get the updated fields
                dispatch(fetchReferral(orgUnitId, referral.id));
              }
            }}
            referrerAdvisedPriorityCodeId={referral?.referralDocument.referrerAdvisedPriorityCodeId}
            currentAssignReferral={convertedAssigningReferral}
            currentAssignedTo={convertedAssigningReferral.assignedTo}
            assignmentSpecialtyFieldSettings={fieldSettings}
            assignmentSpecialtyFieldLabels={fieldLabels}
          />
        ),
      }));
    }
    return dispatch(saveReferralAssignedUser(orgUnitId, convertedAssigningReferral)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed(responds.payload);
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const handleCancel = () => dispatch(resetPatientSearch());

  return (
    <Form
      validationSchema={schema}
      contentProps={other}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      onCancel={handleCancel}
      variant="filled"
      content={({ setFieldValue, values }) => {
        const showPermissionAlert = !hasPrimaryPatientIdentifier && !hasPermissionPatientsIdentifiersAssign && !values.assignReferral.identifier;
        const specialtyId = values.assignReferral.assignedToSpecialtyId?.value ?? values.assignReferral.assignedToSpecialtyId;
        const disabledIdentifierAssign = !hasPermissionPatientsIdentifiersAssign && !hasPermissionPatientsIdentifiersUpdate;

        if (loadingFieldSettings || fieldSettings === null) return <LoadingState />;

        return (
          <>
            {showPermissionAlert && (
              <Alert severity="error">
                {`Patient requires a ${identifierLabel} before assignment. Please contact a system administrator`}
              </Alert>
            )}
            {showIdentifierField && fieldSettings?.patientIdentifier && (
              <div className="flex items-center justify-center">
                <Field
                  className=" flex-1"
                  name="assignReferral.identifier"
                  label={identifierLabel}
                  component={TextInput}
                  disabled={disabledIdentifierAssign}
                  required={fieldSettings?.patientIdentifier.required}
                />
                {!disabledIdentifierAssign && isEnablePatientIdentifierSearch && enabledExternalPatientSearch && (
                <div className="ml-16">
                  <PatientSearchButton
                    patientPresetFilter={patientSearchFilter}
                    onContinue={selectedPatient => openReferralReAssignmentForm(other.title, selectedPatient.patientIdentifier, referral, onSucceed, hasPermissionPatientsIdentifiersAssign)}
                    onCancel={() => openReferralReAssignmentForm(other.title, values.assignReferral.identifier, referral, onSucceed, hasPermissionPatientsIdentifiersAssign)}
                  />
                </div>
                )}
              </div>

            )}
            {fieldSettings?.orgUnit && (
            <Field
              name="assignReferral.orgUnitId"
              loading={isOrgUnitLoading}
              label={fieldLabels.orgUnit}
              required={fieldSettings.orgUnit.required}
              component={AutoComplete}
              options={orgUnitsOptions}
              icon={<OrgUnitsIcon />}
              disabled={!hasPermissionReferralsAssignedSiteEdit
                        || (onlyAllowRequestedReferralsAssignedSiteEdit && referral.referralStatus !== "Requested")
                        || !allowReassignSpecialtyStates}
              onChange={value => {
                if (value != null) {
                  setReassignment(value.value !== orgUnitId);
                  setCurrentReferralOrgUnitId(value.value);
                }
                setFieldValue("assignReferral.assignedToSpecialtyId", null);
                setFieldValue("assignReferral.assignedToSubSpecialtyId", null);
                setFieldValue("assignReferral.assignedToRoleId", null);
                setFieldValue("assignReferral.assignedToUserId", null);
              }}
            />
            )}
            {fieldSettings?.specialty && (
              <Field
                name="assignReferral.assignedToSpecialtyId"
                component={SpecialtySelector}
                label={fieldLabels.specialty}
                required={fieldSettings.specialty.required}
                disabled={!allowReassignSpecialtyStates}
                icon={<SpecialtyIcon />}
                isAssignment
                isCrossOrgAssignment={currentReferralOrgUnitId !== orgUnitId}
                orgUnitId={currentReferralOrgUnitId}
                onChange={selectedSpecialty => {
                  setReassignment(selectedSpecialty?.value !== referral.assignedTo?.assignedToSpecialtyId);
                  setFieldValue("assignReferral.assignedToSubSpecialtyId", null);
                  setFieldValue("assignReferral.assignedToRoleId", null);
                  setFieldValue("assignReferral.assignedToUserId", null);
                }}
                loadInitialOption
              />
            )}
            {fieldSettings?.subSpecialty && (
              <Field
                name="assignReferral.assignedToSubSpecialtyId"
                component={SubSpecialtySelector}
                label={fieldLabels.subSpecialty}
                specialtyIds={!isEmpty(specialtyId) ? [specialtyId] : []}
                required={fieldSettings.subSpecialty.required}
                disabled={!allowReassignSubSpecialtyStates}
              />
            )}
            {fieldSettings?.specialtyRole && (
              <Field
                name="assignReferral.assignedToRoleId"
                label={fieldLabels.specialtyRole}
                component={SpecialtyRoleSelector}
                orgUnitId={currentReferralOrgUnitId}
                icon={<AccountCircleIcon />}
                specialtyIds={!isEmpty(specialtyId) ? [specialtyId] : []}
                required={fieldSettings.specialtyRole.required}
                disabled={!allowReassignRoleStates}
              />
            )}
            {allowUserAssignment && (
              <Field
                name="assignReferral.assignedToUserId"
                label="Specialist"
                loading={loadingSpecialtySpecialists}
                component={AutoComplete}
                icon={<AssignedUserIcon />}
                options={map(specialtySpecialists, x => ({ value: x.userId, label: x.name }))}
                getOptionSelected={(option, val) => option.value === val.value}
              />
            )}
            {fieldSettings?.reason && (
              <Field
                name="assignReferral.reason"
                component={NoteInput}
                label={fieldLabels.reason}
                maxLength={250}
                required={fieldSettings.reason.required}
              />
            )}
          </>
        );
      }}
    />
  );
};

export default withPermissions("PatientsIdentifiersAssign", "PatientsIdentifiersUpdate", "ReferralsAssignedSiteEdit")(ReferralAssignmentForm);
