import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Field } from "formik";

import Form from "components/form";
import { importCodeSetValue } from "app/main/codeSet/actions/codeSet.actions";
import { areCodeSetValuesImporting } from "app/main/codeSet/reducers/codeSet.reducers";
import AttachmentUploadField from "components/inputs/attachment-upload";
import { ExcelExportIcon } from "helpers/icon-finder";

const schema = Yup.object().shape({
  attachment: Yup.object().shape({
    fileName: Yup.string().required("Please choose a file"),
  }),
});

const CodeSetUploadForm = ({
  codeSetTypeCode,
  onSucceed,
  ...other
}) => {
  const dispatch = useDispatch();
  const codeSetValuesImporting = useSelector(areCodeSetValuesImporting);
  const disabledForm = codeSetValuesImporting;

  const handleSubmit = ({ attachment }, { setSubmitting, setErrors }) => {
    dispatch(importCodeSetValue(codeSetTypeCode, attachment.formData)).then(response => {
      setSubmitting(false);
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  const { attachment } = schema.cast();

  return (
    <Form
      initialValues={{ attachment }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      submitLabel="Import"
      contentProps={other}
      disabled={disabledForm}
      content={({ setFieldValue }) => (
        <Field
          name="attachment.fileName"
          component={AttachmentUploadField}
          label="File"
          icon={<ExcelExportIcon />}
          acceptFormat=".xlsx,application/vnd.ms-excel,application/msexcel"
          required
          onChange={file => {
            if (file) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                const formData = new FormData();
                formData.append("file", file);
                setFieldValue("attachment.formData", formData);
                setFieldValue("attachment.fileName", file.name);
              };
            } else {
              setFieldValue("attachment.fileName", null);
            }
          }}
        />
      )}
    />
  );
};

export default CodeSetUploadForm;
