import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { get, isEmpty, concat, filter } from "lodash";
import PropTypes from "prop-types";

import { showMessage } from "app/store/actions/message.actions";
import InputLoader from "components/inputs/components/loader";
import FileUploaderInput from "./input";
import FileSelectInput from "./select-input";

const FileUploader = ({
  icon,
  label,
  onUploadFileChange,
  onSelectFileChange,
  onRemove,
  onUploadingError, // callback when error occur while uploading the file
  form: { touched, errors, status, setFieldTouched, setStatus, setFieldValue },
  field: { name, value },
  onUpload,
  options,
  isSelector,
  component: Component = isSelector ? FileSelectInput : FileUploaderInput,
  defaultValue,
  ...other
}) => {
  const dispatch = useDispatch();
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(!isEmpty(value));
  const [fileOptions, setFileOptions] = useState(options);
  let fieldError = get(errors, name, null);

  const serverError = get(status, "apiErrors", null);
  if (serverError) {
    fieldError = serverError;
  }

  const addNewOptionToOptions = val => setFileOptions(concat([{ value: val, label: val, disabled: true }], filter(options, x => x.value !== val)));

  useEffect(() => {
    if (value) {
      addNewOptionToOptions(value);
      setLoading(false);
    } else {
      setFileOptions(options);
    }

    if (defaultValue) {
      addNewOptionToOptions(defaultValue);
      setLoading(false);
    } else {
      setFileOptions(options);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options, defaultValue]);

  const onError = error => {
    if (onUploadingError) {
      onUploadingError(error);
    }
    if (error) {
      dispatch(showMessage({
        message: error,
        variant: "error",
      }));
    }
  };

  const handleOnChange = event => {
    const { files } = event.target;
    const file = files[0];
    setFieldTouched(name);

    if (file) {
      setFieldValue(name, file.name); // update input display name
      // update options, add file name to option list to avoid warning
      addNewOptionToOptions(file.name);

      const reader = new FileReader();
      reader.readAsDataURL(file);
      setUploading(true);
      reader.onload = () => {
        const formData = new FormData();
        formData.append("file", file);
        dispatch(onUpload(other?.orgUnitId, formData))
          .then(response => {
            const apiErrors = response.payload?.exceptionMessage ?? response.payload.message;
            if (response.error === true) {
              setStatus({ apiErrors });
              onError(apiErrors);
            } else {
              setStatus(null);
              onError(null);
            }
          })
          .finally(() => setUploading(false));
      };
    }

    if (onUploadFileChange) {
      onUploadFileChange(file, setFieldValue);
    }
  };

  const handleOnRemove = () => {
    setFieldValue(name, null);
    // only fire
    if (!fieldError && onRemove) {
      onRemove();
    }
    setStatus({ apiErrors: null });
  };

  const handleOnSelect = event => {
    const { value: option } = event.target;
    if (!option) return;

    if (onSelectFileChange) {
      onSelectFileChange(option, setFieldValue);
    }

    setFieldValue(name, option);
  };

  if (loading) return <InputLoader label={label} icon={icon} />;

  return (
    <Component
      icon={icon}
      label={label}
      loading={uploading}
      onFileUpload={handleOnChange}
      onFileSelect={handleOnSelect}
      onRemove={value && handleOnRemove}
      error={fieldError}
      touched={get(touched, name, false)}
      value={value || defaultValue || ""}
      options={fileOptions}
      {...other}
    />
  );
};

FileUploader.prototype = {
  onUpload: PropTypes.func.isRequired,
};

FileUploader.defaultProps = {
  icon: "attach_file",
};

export default FileUploader;
