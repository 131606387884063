import React, { useEffect, useCallback } from "react";
import withPermissions from "permissions/withPermissions";
import { useSelector } from "react-redux";
import { Field } from "formik";
import { SpecialtyIcon } from "helpers/icon-finder";
import Section from "components/items/section";
import { isEmpty, includes, find } from "lodash";

import SpecialtySelector from "app/main/specialtyProfiles/components/specialty-selector";
import { getSignedInOrgUnit, getUser, getReferralFormProfilesById } from "app/auth/store/reducers/user.reducer";

import { getAllowReassignSpecialtyStates, getAllowOnlyAssignedSpecialties, getPresentingComplaintMaxLength, getAllowedFutureDateMinutes,
  getReferringSiteLabel, getSpecialtyLabel, getSpecialtyFormProfileLabel } from "app/auth/store/reducers/system-configuration";
import { formatFieldSettings, getOrgUnitFormFieldConfigs } from "app/main/orgUnits/reducers/orgUnit.reducers";
import SpecialtyReferralFormProfileSelector from "app/main/specialtyProfiles/components/specialty-referralFormProfile-selector";
import { getFieldLabels } from "app/main/referrals/utils/referral-fieldLabels";
import { getSchema } from "app/main/referrals/utils/referral-schema";
import ReferralOrgUnitSelector from "app/main/referrals/components/referral-orgUnit-selector";

const ReferralTemplateSection = ({
  hasPermissionChangeCurrentSiteConfiguration,
  hasPermissionAddEditManualReferralsAllSites,
  isEdit,
  currentReferral,
  isReading,
  defaultReferralDate,
  loadingFieldSettings,
  fieldSettings,
  values,
  form: { setFieldValue },
  currentAssignedToSpecialty,
  setCurrentAssignSpecialty,
  setSchema,
  setLoadingFieldSettings,
  setFieldLabels,
  setFieldSettings,
  setAutoReferralFullName,
  setAutoReferringOrgUnitId,
  setChangesReferral,
  setEnableDraft,
  setDisableSourceDocumentUpload,
}) => {
  const orgUnit = useSelector(getSignedInOrgUnit);
  const currentUser = useSelector(getUser);
  // allow reassignment when
  // 1. is new form
  // 2. when current referral status in allowed states
  const allowOnlyAssignedSpecialties = useSelector(getAllowOnlyAssignedSpecialties);
  const AllowReassignSpecialtyStates = useSelector(getAllowReassignSpecialtyStates);
  const allowSpecialtyReassignment = !isEdit || includes(AllowReassignSpecialtyStates, currentReferral?.referralStatus);
  const presentingComplaintMaxLength = useSelector(getPresentingComplaintMaxLength);
  const allowedFutureDateMinutes = useSelector(getAllowedFutureDateMinutes);

  const referringSiteLabel = useSelector(getReferringSiteLabel);
  const specialtyLabel = useSelector(getSpecialtyLabel);
  const specialtyFormProfileLabel = useSelector(getSpecialtyFormProfileLabel);
  const allOrgUnitFormFieldConfigs = useSelector(getOrgUnitFormFieldConfigs);
  const defaultSelectedOrgUnit = { id: orgUnit.id, name: orgUnit.name };
  const defaultReferrerFullName = { value: currentUser.name, label: currentUser.name };
  const defaultReferringOrgUnit = defaultSelectedOrgUnit;
  const currentReferralOrgUnitId = values?.referral.orgUnitId ?? orgUnit.id;
  const isDisabled = isEdit && currentReferral?.referralStatus !== "Draft";  
  const currentProfile =  useSelector(state => getReferralFormProfilesById(state, values?.referral?.referralFormProfileId));

  const updateFieldConfiguration = useCallback((orgUnitFormFieldConfigs, profileId, formFieldConfigurationId, referralValues, forceLoad = false) => {
    if (isEmpty(profileId) || isEmpty(formFieldConfigurationId)) return;

    if (formFieldConfigurationId === referralValues?.formFieldConfigurationId && !forceLoad) {
      const updated = {
        ...referralValues,
        referralFormProfileId: profileId,
        formFieldConfigurationId,
      };
      setChangesReferral(updated);
      return;
    }

    const orgUnitFormFieldConfig = find(orgUnitFormFieldConfigs, x => x.id === formFieldConfigurationId);

    if (isEmpty(orgUnitFormFieldConfig?.configurationJson)) return;

    setLoadingFieldSettings(true);
    const updatedFieldSettings = formatFieldSettings(orgUnitFormFieldConfig?.configurationJson);
    const updatedFieldLabels = getFieldLabels(updatedFieldSettings);
    const updatedSchema = getSchema(isEdit, updatedFieldLabels, updatedFieldSettings, allowedFutureDateMinutes, defaultReferralDate, presentingComplaintMaxLength);
    let { referral } = updatedSchema.cast();
    referral = {
      ...referralValues,
      referralFormProfileId: profileId,
      formFieldConfigurationId,
      referralDateUtc: referralValues?.referralDateUtc ?? defaultReferralDate,
      organisationUnit: referralValues?.organisationUnit ?? defaultSelectedOrgUnit,
    };
    setFieldLabels(updatedFieldLabels);
    setFieldSettings(updatedFieldSettings);
    setSchema(updatedSchema);
    setChangesReferral(referral);
    setLoadingFieldSettings(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingFieldSettings && ((currentReferral && (values?.referral?.formFieldConfigurationId !== currentReferral?.formFieldConfigurationId)) || isEmpty(fieldSettings))) {
      const formatCurrentReferral = {
        ...currentReferral,
        referredToSpecialty: currentAssignedToSpecialty,
      };
      updateFieldConfiguration(allOrgUnitFormFieldConfigs, currentReferral?.referralFormProfileId, currentReferral?.formFieldConfigurationId, formatCurrentReferral, isEmpty(fieldSettings));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrgUnitFormFieldConfigs, currentReferral, currentReferral?.referralFormProfileId, currentReferral?.formFieldConfigurationId, loadingFieldSettings]);

  const onChangeReferralProfile = (selectedReferralFormProfile, extraParams) => {
    if (isEmpty(extraParams?.allOrgUnitFormFieldConfigs)) return;
    const referralValues = extraParams?.referral;
    let changesReferral = referralValues;

    if (selectedReferralFormProfile) {
      if (selectedReferralFormProfile?.id && (isEmpty(referralValues.referralFormProfileId) || selectedReferralFormProfile?.id !== referralValues.referralFormProfileId)) {
        setAutoReferralFullName(selectedReferralFormProfile?.setCurrentUserAsReferrer);
        setAutoReferringOrgUnitId(selectedReferralFormProfile?.setCurrentSiteAsReferring);
        setEnableDraft(selectedReferralFormProfile?.enableDraft);
        setDisableSourceDocumentUpload(selectedReferralFormProfile?.disableSourceDocumentUpload);

        changesReferral = {
          ...changesReferral,
          disabledReferrerFullName: selectedReferralFormProfile?.setCurrentUserAsReferrer,
          disabledReferringOrgUnit: selectedReferralFormProfile?.setCurrentSiteAsReferring,
          referrerFullName: selectedReferralFormProfile?.setCurrentUserAsReferrer ? (changesReferral?.referrerFullName ?? defaultReferrerFullName) : changesReferral?.referrerFullName,
          referringOrgUnitId: selectedReferralFormProfile?.setCurrentSiteAsReferring ? (changesReferral?.referringOrgUnitId ?? defaultReferringOrgUnit?.id) : changesReferral?.referringOrgUnitId,
          referringOrgUnit: selectedReferralFormProfile?.setCurrentSiteAsReferring ? (changesReferral?.referringOrgUnit ?? defaultReferringOrgUnit) : changesReferral?.referringOrgUnit,
        };
      }
      updateFieldConfiguration(extraParams?.allOrgUnitFormFieldConfigs, selectedReferralFormProfile?.id, selectedReferralFormProfile?.formFieldConfigurationId, changesReferral);
    }
  };

  const updateSelectedSpecialty = () => {
    setFieldValue("referral.referralFormProfileId", null);
    setFieldValue("referral.assignToSubSpecialty", null);
    setFieldValue("referral.clinicTypeCodeId", null);
    setFieldValue("referral.assignedToSpecialist", null);
    setFieldValue("referral.presentingComplaintCodes", null);
    setFieldValue("referral.assignedToRoleId", null);
  };

  return (
    <Section withDivider title="Referral Form">
      {hasPermissionChangeCurrentSiteConfiguration && (
        <Field
          name="referral.orgUnitId"
          label={referringSiteLabel}
          disabled={!hasPermissionAddEditManualReferralsAllSites || isDisabled}
          initialOptions={values?.referral?.organisationUnit ? [values?.referral?.organisationUnit] : []}
          component={ReferralOrgUnitSelector}
          onChange={(value, change) => {
            change("referral.orgUnitId", value?.value);
            setFieldValue("referral.referredToSpecialty", null);
            updateSelectedSpecialty();
            setCurrentAssignSpecialty(null);
          }}
        />
      )}
      <Field
        name="referral.referredToSpecialty"
        component={SpecialtySelector}
        orgUnitId={currentReferralOrgUnitId}
        disabled={isDisabled || !allowSpecialtyReassignment || isReading}
        label={specialtyLabel}
        required
        isCreateReferral
        includeDefaultSpecialist
        includeReferralProfiles
        icon={<SpecialtyIcon />}
        onAutoAssignExtraAction={selectedValue => setCurrentAssignSpecialty(selectedValue)}
        onChange={selectedValue => {
          updateSelectedSpecialty();
          setCurrentAssignSpecialty(selectedValue);
        }}
        loadInitialOption={allowOnlyAssignedSpecialties}
      />
      <Field
        name="referral.referralFormProfileId"
        label={specialtyFormProfileLabel}
        disabled={isDisabled}
        component={SpecialtyReferralFormProfileSelector}
        required
        orgUnitId={currentReferralOrgUnitId}
        specialtyId={currentAssignedToSpecialty?.value}
        extraParams={{ allOrgUnitFormFieldConfigs, referral: values.referral }}
        initialOptions={currentProfile ? [currentProfile] : []}
        onAutoAssignExtraAction={(selectedValue, extraParams) => onChangeReferralProfile(selectedValue, extraParams)}
        onChange={(selectedReferralFormProfile, extraParams) => onChangeReferralProfile(selectedReferralFormProfile, extraParams)}
      />
    </Section>
  );
};

export default withPermissions("ChangeCurrentSiteConfiguration", "AddEditManualReferralsAllSites")(ReferralTemplateSection);
