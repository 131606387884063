import React from "react";
import { Alert } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { find } from "lodash";

import { undoAcceptBookedAction } from "app/main/referrals/actions/referrals.actions";
import Form from "components/form";
import { acceptedWorkflowState, acceptedBookedWorkflowState } from "../helpers/workflow-state-names";

const UndoAcceptedBookedDialog = ({
  onSucceed,
  referralId,
  referralWorkflowSettings,
  referralActionTypeSettings,
  orgUnitId,
}) => {
  const dispatch = useDispatch();
  const getWorkflowStateLabel = stateName => find(referralWorkflowSettings, x => x.workflowState === stateName)?.label || stateName;
  const getWorkflowState = stateName => find(referralWorkflowSettings, x => x.workflowState === stateName)?.name || stateName;
  const getAcceptedStatus = () => getWorkflowStateLabel(acceptedWorkflowState);
  const getAcceptedBookedActionSettings = () => {
    const actionedName = getWorkflowState(acceptedBookedWorkflowState);
    return find(referralActionTypeSettings, x => x.actionedName === actionedName);
  };

  const warningMessage = getAcceptedBookedActionSettings()?.warningMessage;

  const handleSubmit = ({ referral }, { setErrors }) => {
    dispatch(undoAcceptBookedAction(orgUnitId, referral)).then(response => {
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ referral: { referralId } }}
      contentProps={{ title: getAcceptedBookedActionSettings()?.formTitle ?? "Undo Referral Status", submitLabel: "Confirm" }}
      variant="filled"
      content={() => (
        <>

          {warningMessage && <Alert severity="warning">{warningMessage} </Alert>}
          <Typography>{`Mark this referral as ${getAcceptedStatus()}?`}</Typography>
        </>
      )}
    />
  );
};

export default UndoAcceptedBookedDialog;
