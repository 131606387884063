import React from "react";
import { Typography } from "@material-ui/core";

import { ContextItem } from "components/items/default-item";
import IconButton from "components/items/icon-button";
import ActivityLogItem from "components/items/activity-log/item";
import useTriageFieldSettings from "app/main/referrals/hooks/useTriageFieldSettings";
import useActionTypeItem from "./useActionTypeItem";
import Acknowledgement from "./acknowledgement";

const Item = props => (
  <ContextItem
    className="flex-col"
    alignment="items-start"
    labelClassName="w-full"
    {...props}
  />
);

const AcceptActionTypeItem = ({ orgUnitId, activity }) => {
  const { title, popupTitle, status, getDisplayCodeSetValue } = useActionTypeItem({ orgUnitId, activity });
  const { fieldLabels, fieldSettings } = useTriageFieldSettings(orgUnitId);

  return (
    <ActivityLogItem
      date={activity.createdDateLocalised}
      user={activity?.actionedBy}
      renderLeftContent={<Acknowledgement acknowledgement={activity.acknowledgement} dateTime={activity.acknowledgementDateTimeUtc} />}
      icon={status.icon}
      content={activity.message}
      iconColor={status.color}
      activeColor={status.color}
      title={title}
      renderPopupContent={({ close }) => (
        <>
          <div className="flex justify-between items-center">
            {popupTitle}
            <div className="flex items-center justify-end">
              <IconButton title="Close" onClick={close} icon="close" />
            </div>
          </div>
          <Typography variant="caption">by {activity.actionedBy} at {activity.createdDateLocalised}</Typography>
          <div className="items-container mt-16">
            {fieldSettings?.episodeHealthCondition
              && <Item label={fieldLabels.episodeHealthCondition} content={activity.acceptReferralAction.episodeHealthConditionSummary} />}
            {fieldSettings?.clinic
              && <Item label={fieldLabels.clinic} content={getDisplayCodeSetValue(activity.acceptReferralAction.clinicCodeId)} />}
            {fieldSettings?.clinicType
              && <Item label={fieldLabels.clinicType} content={getDisplayCodeSetValue(activity.acceptReferralAction.clinicTypeCodeId)} />}
            {fieldSettings?.externalTriageStatus
              && <Item label={fieldLabels.externalTriageStatus} content={getDisplayCodeSetValue(activity.acceptReferralAction.externalTriageStatusCodeId)} />}
            {fieldSettings?.note
              && <Item label={fieldLabels.note} content={activity.message} />}
          </div>
        </>
      )}
    />
  );
};

export default AcceptActionTypeItem;
