import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import withPermissions from "permissions/withPermissions";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";

import HospitalBuildingIcon from "mdi-react/HospitalBuildingIcon";
import Form from "components/form";
import { OrgUnitsIcon } from "helpers/icon-finder";
import { PharmacySelector, SiteSelector } from "app/main/applications/components/site-transfer-form-selectors";
import { getAuthorizedOrgUnits } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { fetchOrgUnitsForSelector } from "app/main/orgUnits/actions/orgUnit.actions";
import { onTransferSite } from "app/main/applications/actions/applications.actions";
import { showMessage } from "app/store/actions/message.actions";
import { closeDialog } from "app/store/actions/dialog.actions";
import * as Yup from "yup";

const siteSelectorLabel = "Site";
const pharmacyLabel = "Pharmacy Location";

const schema = Yup.object().shape({
  siteTransferModel: Yup.object().shape({
    siteSelector: Yup.string()
      .required(`${siteSelectorLabel} is required`)
      .nullable(),
    pharmacySelector: Yup.string()
      .required(`${pharmacyLabel} is required`)
      .nullable(),
  }),
});

const SiteTransferForm = ({
  hasPermissionChangeCurrentSiteConfiguration,
  application,
  onSucceed,
  currentSite,
  ...other
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const orgUnits = useSelector(getAuthorizedOrgUnits);
  const [selectedSite, setSelectedSite] = useState();
  const [selectedPharmacy, setSelectedPharmacy] = useState();

  useEffect(() => {
    dispatch(fetchOrgUnitsForSelector());
  }, [dispatch, currentSite]);

  const handleSelectTransferSite = newSelectedSite => {
    if (!newSelectedSite) {
      setSelectedSite(null);
      setSelectedPharmacy(null);

      return;
    }

    setSelectedSite({ label: newSelectedSite.label, id: newSelectedSite.value });
  };

  const handleSelectTranferPharmacy = newSelectedPharmacy => {
    if (!newSelectedPharmacy) {
      setSelectedPharmacy(null);
      return;
    }

    setSelectedPharmacy({ label: newSelectedPharmacy.label, id: newSelectedPharmacy.value });
  };

  const handleCloseDialog = () => {
    dispatch(closeDialog());
  };

  const handleSuccessfulTransfer = () => {
    handleCloseDialog();
    history.push("/applications");
    dispatch(showMessage({
      variant: "success",
      message: "Transfer successful",
    }));
  };

  const handleFailedTransfer = error => {
    handleCloseDialog();
    dispatch(showMessage({
      variant: "error",
      message: `Transfer failed: ${error}`,
    }));
  };

  const performSiteTransfer = async () => {
    if (!selectedSite || !selectedPharmacy) {
      handleFailedTransfer("Please select a site and pharmacy location");
      return;
    }

    const siteTransferInfo = { orgUnitId: selectedSite.id, collectionLocationId: selectedPharmacy.id };
    try {
      const response = await dispatch(onTransferSite(currentSite.id, application.id, siteTransferInfo));
      if (response.error) {
        handleCloseDialog();
        handleFailedTransfer(response.payload.message);
        return;
      }

      handleSuccessfulTransfer();
    } catch (error) {
      handleFailedTransfer(error);
    }
  };

  const handleSubmit = () => {
    performSiteTransfer();
  };

  const { siteTransferModel } = schema.cast();

  return (
    <Form
      initialValues={{ siteTransferModel }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      contentProps={other}
      content={() => (
        <>
          <Field
            name="siteTransferModel.siteSelector"
            icon={<OrgUnitsIcon />}
            component={SiteSelector}
            currentSite={currentSite}
            initialOptions={orgUnits}
            label={siteSelectorLabel}
            onChange={handleSelectTransferSite}
            required
          />
          <Field
            name="siteTransferModel.pharmacySelector"
            icon={<HospitalBuildingIcon />}
            component={PharmacySelector}
            selectedSite={selectedSite}
            label={pharmacyLabel}
            onChange={handleSelectTranferPharmacy}
            required
          />
        </>
      )}
    />
  );
};

export default withPermissions("ChangeCurrentSiteConfiguration")(SiteTransferForm);
