import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, size, includes, findKey } from "lodash";
import { Typography, IconButton, Icon } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DownloadIcon from "mdi-react/DownloadOutlineIcon";
import PDFIcon from "mdi-react/FilePdfBoxIcon";

import { getCurrentReferralAttachmentFiles } from "app/main/referrals/reducers/referrals.reducers";
import DialogPopup from "components/items/dialog-popup";
import DefaultButton from "components/items/default-button";
import LoadingState from "components/items/loading-state";
import downloadFromApi, { openPdf } from "utils/download-from-api";
import { fetchPreviewAttachment } from "app/main/referrals/actions/referrals.actions";
import { setDialogOptions, closeDialog } from "app/store/actions/dialog.actions";
import PreviewContent from "components/items/preview-content";
import { EmptyStateComponent } from "components/items/pdf-preview";
import { getAllowNotScannedFileView } from "app/auth/store/reducers/system-configuration";

const useStyles = makeStyles(() => ({
  imageStyle: {
    minHeight: "100%",
    backgroundSize: "contain",
  },
  content: {
    height: "80vh",
  },
}));

const pendingStatus = "Pending";
const cleanStatus = "Clean";
const virusDetectedStatus = "VirusDetected";
const notScanned = "NotScanned";

const ReferralAttachmentPreviewDialog = ({
  orgUnitId,
  referralId,
  referralDocumentId,
  file,
  disabledHeaderActions = false,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const contentRef = useRef();

  const currentAttachmentFiles = useSelector(state => getCurrentReferralAttachmentFiles(state, referralId));
  const allowNotScannedFileView = useSelector(getAllowNotScannedFileView);
  // keep track with the current file (details of attachment)
  const [currentFile, setCurrentFile] = useState(file);
  // the attachment with their preview content
  const [attachment, setAttachment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const currentIndex = Number(findKey(currentAttachmentFiles, x => x.id === currentFile.id));
  const totalAttachments = size(currentAttachmentFiles);
  const isFirst = currentIndex === 0;
  const isLast = currentIndex + 1 === totalAttachments;
  const isPDF = includes(currentFile.mimeType, "pdf");

  const isPending = attachment?.fileScanStatus === pendingStatus;
  const isClean = attachment?.fileScanStatus === cleanStatus;
  const isVirusDetected = attachment?.fileScanStatus === virusDetectedStatus;
  const isNotScanned = attachment?.fileScanStatus === notScanned;
  const isEnableViewFile = isClean || (allowNotScannedFileView && isNotScanned);

  const fetchPreview = fileId => {
    dispatch(fetchPreviewAttachment(orgUnitId, referralId, referralDocumentId, fileId)).then(response => {
      if (response.error) {
        setError(true);
      } else {
        setError(false);
        setAttachment(response.payload);
      }
    })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  // first load
  useEffect(() => {
    fetchPreview(file.id);
    dispatch(setDialogOptions({ maxWidth: "md" }));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPreviousFile = () => {
    const previousFile = currentAttachmentFiles[currentIndex - 1];
    setLoading(true);
    setAttachment(null);
    fetchPreview(previousFile.id);
    setCurrentFile(previousFile);
  };

  const getNextFile = () => {
    const nextFile = currentAttachmentFiles[currentIndex + 1];
    setLoading(true);
    setAttachment(null);
    fetchPreview(nextFile.id);
    setCurrentFile(nextFile);
  };

  const downloadAttachment = fileId => downloadFromApi(`api/referrals/${orgUnitId}/${referralId}/download?fileId=${fileId}`);

  const content = () => {
    if (loading) {
      return <LoadingState />;
    }

    if (error) {
      return <EmptyStateComponent title="An error occurred while loading the preview." />;
    }

    if (isVirusDetected) {
      return <EmptyStateComponent title="Potential malicious content detected. File not available for download." />;
    }

    if (isPending) {
      return <EmptyStateComponent title="Malware scanning pending. Please check back again later." />;
    }

    if (isEnableViewFile) {
      return (
        <PreviewContent
          fileData={attachment?.base64File || attachment?.htmlPreview}
          mimeType={currentFile?.mimeType}
          pdfProps={{ width: get(contentRef, ["current", "clientWidth"], 0) }}
          imageProps={{ classes: { root: classes.imageStyle } }}
          hasPreview={attachment.hasPreview}
        />
      );
    }

    return <EmptyStateComponent />;
  };

  return (
    <DialogPopup
      classes={{ content: classes.content }}
      title={currentFile.fileName}
      content={<div ref={contentRef} className="w-full h-full">{content()}</div>}
      renderHeaderActions={
        !disabledHeaderActions && (
          <>
            <Typography className="mr-8" variant="caption">Attachment</Typography>
            <Typography className="mr-8" variant="caption">{`${currentIndex + 1} / ${totalAttachments}`}</Typography>
            <IconButton color="inherit" disabled={isFirst} onClick={() => getPreviousFile()}><Icon fontSize="small">arrow_back</Icon></IconButton>
            <IconButton color="inherit" disabled={isLast} onClick={() => getNextFile()}><Icon fontSize="small">arrow_forward</Icon></IconButton>
          </>
        )
      }
      renderActions={(
        <div className="flex items-center justify-between flex-1">
          {isEnableViewFile
            ? (
              <div className="flex">
                {isPDF && (
                  <DefaultButton
                    className="mr-8"
                    color="secondary"
                    onClick={() => {
                      openPdf(`api/referrals/${orgUnitId}/${referralId}/download?fileId=${currentFile.id}`);
                    }}
                    label="Open in browser"
                    size="medium"
                    icon={<PDFIcon />}
                  />
                )}
                <DefaultButton
                  onClick={() => downloadAttachment(currentFile.id)}
                  label="Download"
                  size="medium"
                  icon={<DownloadIcon />}
                />
              </div>
            ) : <div />}
          <DefaultButton color="default" onClick={() => dispatch(closeDialog())} label="Close" size="medium" variant="text" />
        </div>
      )}
    />
  );
};

export default ReferralAttachmentPreviewDialog;
