import React, { useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty, map, get, filter } from "lodash";
import { makeStyles, fade } from "@material-ui/core/styles";
import { Typography, IconButton, CircularProgress, Tooltip, Collapse } from "@material-ui/core";
import clsx from "clsx";
import moment from "moment-timezone";
import FilePDFOutlineIcon from "mdi-react/FilePdfBoxIcon";

import withPermissions from "permissions/withPermissions";
import ItemStatus from "components/items/item-status";
import { PatientDetailsIcon } from "helpers/icon-finder";
import ContentWithDivider from "app/main/applications/components/content-with-divider";
import downloadFromApi from "utils/download-from-api";
import UrgentIcon from "app/main/applications/components/urgent-icon";
import { actionPanel } from "components/styles/base";
import { getIPAWorklistTitle } from "utils/get-environment-variables";
import { PatientDOBLabel } from "app/main/patients/components/patient-details";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import ContentApplicationReceiver from "./content-application-receiver";
import { OpenIcon } from "helpers/icon-finder";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexWrap: "wrap",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "space-between",
      flexWrap: "noWrap",
    },
  },
  contentContainer: ({ applicationStatusColor }) => ({
    padding: `${theme.spacing(2)}px`,
    backgroundColor: applicationStatusColor ? fade(applicationStatusColor, 0.1) : "transparent",
    flexGrow: "2",
    [theme.breakpoints.between("0","950")]: {
      padding: "6px 16px",
    },
  }),
  collapseContentContainer: {
    flex: "1 1 auto",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",  
  },
  patientContainer: {
    width: "100%",
    padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      width: actionPanel.width,
      padding: 0,
      height: "100%",
      alignItems: "center",
      justifyContent: "center",
    },

  },
  patient: {
    width: "inherit",
    [theme.breakpoints.between("0","600")]: {
      display: "flex",
      gap: "8px",
    },
  },
  pdfIcon: {
    padding: "0 12px 4px 12px",
  },
  gapEight: {
    columnGap: "8px",
  },
  headerButton: {
    display: "none",
    [theme.breakpoints.between("0","600")]: {
      display: "inline-block",
      position: "absolute",
      top: "5px",
      right: "5px",
    },
  },
  open: {
    transform: "rotate(0)",
  },
  closed: {
    transform: "rotate(-90deg)",
  },
  headerCollapse:  {
    [theme.breakpoints.up("600")]: {
      height: "auto !important",
      visibility: "visible",
    },
  },
}));

const worklistTitle = getIPAWorklistTitle();
const ApplicationHeader = ({
  hasPermissionPatientsView,
  application,
  applicationStatus,
  applicationStatusColor,
  patientSetting,
}) => {
  const classes = useStyles({ applicationStatusColor });
  const [generatingPDF, setPDFState] = useState(false);
  const { patient, requestedMedication, statusFlags: { canExportPdf }, displayProvisionalApprovalLabel, isReportBackApplication } = application;
  const timeZone = moment.tz.guess();
  const hidePDFButton = !canExportPdf;
  const collectionLocation = get(application, ["collectionLocation", "name"], null);
  const signedInSite = useSelector(getSignedInOrgUnit);
  const [open, setOpen] = React.useState(true);
  const handleChange = () => {
    setOpen((prev) => !prev);
  };
  const handleChange2 = () => {
    setOpen(true);
  };
  const collapseMe = (
    <div className={classes.collapseContentContainer}>
      <div className="flex-1">
        <div className={`flex-row-container mt-4 ${classes.gapEight}`}>
          <div className="flex items-center">
            <UrgentIcon show={application.urgentApproval} />
            <ItemStatus
              color={applicationStatusColor}
              label={displayProvisionalApprovalLabel ? `${applicationStatus} (Provisionally Approved)` : applicationStatus}
              textProps={{ variant: "body1" }}
            />
          </div>
          <div className="enable-shrink">
            <ContentWithDivider contents={[{ label: get(requestedMedication, "drug", null), className: "font-bold", color: "primary", variant: "body2" }, get(application, ["indication", "indicationTerm"], null)]} />
          </div>
        </div>
        <div className={clsx((signedInSite?.name || collectionLocation || patientSetting) )}>
          <ContentApplicationReceiver
            site={signedInSite?.name}
            collectionLocation={collectionLocation}
            patientSetting={patientSetting}
          />
        </div>
      </div>
      <div className="flex flex-col items-end justify-between w-64">
        {hidePDFButton
          ? <div className="h-48" />
          : (
            <Tooltip title="Export to PDF">
              <IconButton
                color="primary"
                className={classes.pdfIcon}
                onClick={() => {
                  setPDFState(true);
                  downloadFromApi(`api/applications/${application.orgUnitId}/${application.id}/createPdf?timeZone=${timeZone}`).then(() => {
                    setPDFState(false);
                  });
                }}
              >
                {generatingPDF ? <CircularProgress size={24} /> : <FilePDFOutlineIcon />}
              </IconButton>
            </Tooltip>
          )}
        {application.status !== "New" && <Typography variant="body2">IPA# {application.applicationNumber}</Typography>}
      </div>
    </div>
  );
  return (
    <div className={classes.container}>
      <OpenIcon size={32} className={`${open ? classes.open : classes.closed} ${classes.headerButton}`} onClick={handleChange} />
      <div className={classes.contentContainer}>  
        <Typography variant="h6" className="font-bold" onClick={handleChange2}>{`${worklistTitle.single} ${isReportBackApplication ? " - Report Back" : ""}`}</Typography>
        <Collapse in={open} className={classes.headerCollapse}>{collapseMe}</Collapse>
      </div>
      <div className={classes.patientContainer}>
        <div className={classes.patient}>
          {isEmpty(patient) || !hasPermissionPatientsView
            ? <PatientDetailsIcon size={48} />
            : (
              <>
                <Typography className="font-bold" color="primary">{patient.displayNameFull}</Typography>
                {(patient.birthDate && patient.age) && <PatientDOBLabel age={patient.age} birthDate={patient.birthDate} />}
                {patient.patientIdentifiers && (
                  <div>
                    {map(filter(patient.patientIdentifiers, patientIdentifier => patientIdentifier.identifier != null), (x, index) => (
                      <div key={x.identifier} className={clsx("flex items-center justify-center", index !== 0 && "mt-8")}>
                        <Typography variant="body1" className="font-bold">{x.label}</Typography>
                        <Typography variant="body1" className="ml-8">{x.identifier}</Typography>
                      </div>
                    ))}
                  </div>
                )}
              </>
            )}
        </div>
      </div>
    </div>
  );
};

export default withPermissions("PatientsView")(ApplicationHeader);
