import React from "react";
import { withMobileDialog, Slide, Dialog, Paper } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import * as Actions from "app/store/actions/dialog.actions";
import Draggable from "react-draggable";
import { getDraggableDialogs } from "utils/get-environment-variables";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  paperScrollPaper: {
    height: "calc(100% - 64px)",
    overflowY: "hidden",
  },
});

const Transition = React.forwardRef((props, ref) => <Slide mountOnEnter unmountOnExit direction="up" ref={ref} {...props} />);

const isEnabledDraggableDialog = getDraggableDialogs() ?? false;
function PaperComponent(props) {
  if (isEnabledDraggableDialog) {
    return (
      <Draggable
        bounds="body"
        handle="#form-dialog-title"
        cancel=".no-drag"
      >
        <Paper {...props} />
      </Draggable>
    );
  }

  return <Paper {...props} />;
}

const DefaultDialog = props => {
  const dispatch = useDispatch();
  const state = useSelector(({ dialog }) => dialog.defaultDialog);
  const options = useSelector(({ dialog }) => dialog.options);
  const fullHeight = useSelector(({ dialog }) => dialog.fullHeight);
  const classes = useStyles();

  const classValues = fullHeight ? { paperScrollPaper: classes.paperScrollPaper } : {};

  return (
    <Dialog
      PaperComponent={PaperComponent}
      fullScreen={props.fullScreen}
      fullWidth
      disableEnforceFocus // prevent loss focus when render dialog on top of dialog
      onClose={() => dispatch(Actions.closeDialog())}
      onExited={() => dispatch(Actions.resetDialog())}
      open={state}
      TransitionComponent={Transition}
      keepMounted
      classes={classValues}
      {...options}
    />
  );
};

export default withMobileDialog({ breakpoint: "xs" })(DefaultDialog);
