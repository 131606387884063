import React, { useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { filter, includes } from "lodash";

import { areCodeSetValuesLoaded, areCodeSetValuesLoading, getCodeSetValuesByTypeCode } from "app/main/codeSet/reducers/codeSet.reducers";
import { fetchAllCodeSetValues, translateTermOptions } from "app/main/codeSet/actions/codeSet.actions";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { getHideHealthLinkOnlyTypeCodes } from "app/auth/store/reducers/system-configuration";

const CodeSetValueSelector = ({
  label,
  codeSetTypeCode,
  isTermSelector = false,
  showInactive = false,
  allowHideHealthLinkOnly = false,
  allowHideOnForms = false,
  allowHideOnSearch = false,
  ...other
}) => {
  const dispatch = useDispatch();
  const loaded = useSelector(areCodeSetValuesLoaded);
  const loading = useSelector(areCodeSetValuesLoading);
  const codeSetTypeOptions = useSelector(state => getCodeSetValuesByTypeCode(state, codeSetTypeCode));
  const hideHealthLinkOnlyTypeCodes = useSelector(getHideHealthLinkOnlyTypeCodes);
  const [options, setOptions] = useState(null);
  const timer = useRef();
  const { field: { value: selectedValue } } = other;

  React.useEffect(() => {
    timer.current = setTimeout(() => {
      if (loaded === false) {
        dispatch(fetchAllCodeSetValues());
      }
    }, 300);
    return () => { clearTimeout(timer.current); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  React.useEffect(() => {
    let filteredOptions = codeSetTypeOptions?.codeSetValues || [];

    if (includes(hideHealthLinkOnlyTypeCodes, codeSetTypeCode) && allowHideHealthLinkOnly === true) {
      filteredOptions = filter(filteredOptions, x => (x.propertiesJson?.HealthLinkOnly ?? false) === false);
    }

    if (allowHideOnForms) {
      filteredOptions = filter(filteredOptions, x => (x.propertiesJson?.HideOnForms ?? false) === false);
    }

    if (allowHideOnSearch) {
      filteredOptions = filter(filteredOptions, x => (x.propertiesJson?.HideOnSearch ?? false) === false);
    }

    setOptions({...codeSetTypeOptions, codeSetValues: filteredOptions});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeSetTypeCode, codeSetTypeOptions]);

  if (loading) return <AutoCompleteLoading label={label} />;

  return (
    <AutoComplete
      label={label}
      options={translateTermOptions(options?.codeSetValues, isTermSelector, selectedValue, showInactive)}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      getOptionDisabled={option => option.disabled}
      {...other}
    />
  );
};

CodeSetValueSelector.propTypes = {
  codeSetTypeCode: PropTypes.string.isRequired,
};

export default CodeSetValueSelector;
