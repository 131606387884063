import { get, map, mapKeys } from "lodash";
import curryConnector from "utils/connectors";

export const PROVIDER_STATE_KEY = "providers";
export const PROVIDERS_PAGE_SIZE = 50;

const curry = fn => curryConnector(fn, PROVIDER_STATE_KEY);

export const ActionTypes = {
  LOADING_PROVIDERS: "LOADING_PROVIDERS",
  LOADED_PROVIDERS: "LOADED_PROVIDERS",
  ERROR_LOADING_PROVIDERS: "ERROR_LOADING_PROVIDERS",
};

const initialState = {
  all: null,
  pages: {},
  pageInfo: { pageNumber: 1, pageSize: PROVIDERS_PAGE_SIZE, totalRecords: 0 },
  loading: false,
  loaded: false,
  error: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_PROVIDERS:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: true, error: false } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.LOADED_PROVIDERS:
      return {
        ...state,
        all: { ...state.all, ...mapKeys(action.payload.providers, x => x.id) },
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: false, loaded: true, ids: map(action.payload.providers, x => x.id) } },
        pageInfo: action.payload.pageInfo,
        searchParams: action.payload.searchParams,
      };
    case ActionTypes.ERROR_LOADING_PROVIDERS:
      return {
        ...state,
        loading: false,
        loaded: false,
        all: null,
        error: action.payload.message,
      };
    default:
      return state;
  }
};

export const areProvidersLoading = curry(({ localState }) => localState.loading === true);

export const areProvidersLoaded = curry(({ localState }) => localState.loaded === true);

export const getProviders = curry(({ localState }) => localState.all);

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});
