import { filter, reduce, orderBy, includes } from "lodash";

export const getWarningMessages = (referralActionTypeSetting, referralDocumentType) => {
  const documentTypeWarningMessages = reduce(orderBy(filter(referralActionTypeSetting?.documentTypeSettings, x => includes(x.referralDocumentTypes, referralDocumentType)), "sortOrder"), (results, documentTypeSetting) => {
    if (documentTypeSetting) {
      return [...results, documentTypeSetting.warningMessage];
    }
    return results;
  }, []);
  return documentTypeWarningMessages;
};
