import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter, find, concat, isEmpty, isArray, includes } from "lodash";

import { areSpecialtyCodeSetValuesLoading, getSpecialtyCodeSetValues } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchSpecialtyProfileCodeSetValues } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { translateTermOptions } from "app/main/codeSet/actions/codeSet.actions";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import { getAllCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";
import { getHideHealthLinkOnlyTypeCodes } from "app/auth/store/reducers/system-configuration";

// consider that code set value that selected by a referral
// but it has been removed from the specialty profile form later on (not inactive)
// providing that we already load all code sets
// 1. find the selected option
// 2. load the options from api
// 3. if have selected option and it not in the list of options
// 4. add the selected option to the option list
const SpecialtyCodeSetSelector = ({
  label,
  specialtyId,
  codeSetTypeCode,
  multiple,
  forceLoad,
  isTermSelector = false,
  allowHideHealthLinkOnly = false,
  allowHideOnForms = false,
  allowHideOnSearch = false,
  ...other
}) => {
  const dispatch = useDispatch();
  const orgUnit = useSelector(getSignedInOrgUnit);
  const loading = useSelector(state => areSpecialtyCodeSetValuesLoading(state, specialtyId));
  const specialtyCodeSetValues = useSelector(state => getSpecialtyCodeSetValues(state, specialtyId));
  const hideHealthLinkOnlyTypeCodes = useSelector(getHideHealthLinkOnlyTypeCodes);
  const codeSetOptions = filter(specialtyCodeSetValues, x => x.codeSetTypeCode === codeSetTypeCode);
  const timer = useRef();
  const codeSetValues = useSelector(getAllCodeSetValues);

  const { field: { value: selectedValue } } = other;

  let options = [];

  const loadOptions = () => dispatch(fetchSpecialtyProfileCodeSetValues(orgUnit.id, specialtyId, forceLoad));

  useEffect(() => {
    if (specialtyId) {
      timer.current = setTimeout(() => {
        loadOptions();
      }, 1000);
    }

    return () => clearTimeout(timer.current);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialtyId]);

  if (loading) return <AutoCompleteLoading label={label} />;

  let filteredOptions = codeSetOptions || [];
  if (includes(hideHealthLinkOnlyTypeCodes, codeSetTypeCode) && allowHideHealthLinkOnly === true) {
    filteredOptions = filter(filteredOptions, x => (x.propertiesJson?.HealthLinkOnly ?? false) === false);
  }
  if (allowHideOnForms) {
    filteredOptions = filter(filteredOptions, x => (x.propertiesJson?.HideOnForms ?? false) === false);
  }
  if (allowHideOnSearch) {
    filteredOptions = filter(filteredOptions, x => (x.propertiesJson?.HideOnSearch ?? false) === false);
  }

  if (specialtyId && codeSetTypeCode) {
    options = translateTermOptions(filteredOptions, isTermSelector, selectedValue);
  }

  if (!isEmpty(selectedValue)) {
    // check if selected value is in the option
    // select code set from all the code set values
    if (isArray(selectedValue)) {
      selectedValue.forEach(value => {
        const existingOption = find(codeSetValues, x => x.id === value);
        options = concat(options ?? [], { value: existingOption?.id, label: existingOption?.displayName, disabled: true });
      });
    } else {
      const existingOption = find(codeSetValues, x => x.id === selectedValue);
      options = concat(options ?? [], { value: existingOption?.id, label: existingOption?.displayName, disabled: true });
    }
  }

  const multipleProps = multiple && {
    handleOnChange: (newValue, name, setFieldValue) => setFieldValue(name, newValue),
  };

  return (
    <AutoComplete
      label={label}
      options={options}
      filterSelectedOptions
      multiple={multiple}
      getOptionDisabled={option => option.disabled}
      {...multipleProps}
      {...other}
    />
  );
};

export default SpecialtyCodeSetSelector;
