import React from "react";
import { LinkIcon } from "helpers/icon-finder";

const CustomLinkRenderer = ({ href, children }) => (
  <a 
    href={href} 
    target="_blank" 
    rel="noopener noreferrer"
    style={{ display: "inline-flex", alignItems: "center" }}
  >
    {children}
    <LinkIcon style={{ marginLeft: "5px" }} size={12} />
  </a>
);

export default CustomLinkRenderer;
