import { get } from "lodash";
import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { ActionTypes } from "../reducers/applications.reducers";

const determineApplicationType = applicationId => (applicationId ? ["SAVING_APPLICATION", ActionTypes.SAVED_APPLICATION, ActionTypes.ERROR_SAVING_APPLICATION] : ["CREATING_APPLICATION", ActionTypes.CREATED_APPLICATION, "ERROR_CREATING_APPLICATION"]);

const determineActionTypes = (id, entityType) => {
  if (id) {
    return [
      `SAVING_${entityType}`,
      `SAVED_${entityType}`,
      `ERROR_SAVING_${entityType}`,
    ];
  }
  return [
    `CREATING_${entityType}`,
    `CREATED_${entityType}`,
    `ERROR_CREATING_${entityType}`,
  ];
};

export const setCurrentApplication = application => ({
  type: ActionTypes.SET_CURRENT_APPLICATION,
  payload: application,
});

export const setCurrentAttachment = attachment => ({
  type: ActionTypes.LOADED_ATTACHMENT_FILE,
  payload: { ...attachment },
});

export const fetchApplication = (applicationId, orgUnitId) => dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}`,
    method: "GET",
    types: [
      ActionTypes.LOADING_APPLICATION,
      ActionTypes.LOADED_APPLICATION,
      ActionTypes.ERROR_LOADING_APPLICATION,
    ],
  },
});

// PATIENT SECTION
export const onSavePatient = (orgUnitId, patient) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/createPatient`,
    body: { patient },
    entityType: "APPLICATION",
  },
});

export const onSaveOrUpdatePatient = (orgUnitId, applicationId, patient) => async dispatch => {
  const PATIENT_ACTION_TYPES = determineApplicationType(applicationId);

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/editPatient`,
      body: { patient },
      types: PATIENT_ACTION_TYPES,
    },
  });
};

// TREATMENT SECTION
export const searchDrugs = (orgUnitId, searchParams) => dispatch => {
  const url = `/applications/${orgUnitId}/drugSearch?${formatSearchParams(searchParams)}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "DRUGS",
    },
  });
};

export const searchDrugCostInformation = (identifier, identifierType) => dispatch => {
  const url = `/applications/drugCostInformationSearch/${identifier}/${identifierType}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "DRUG_COST_INFORMATION",
      identifier,
      identifierType,
    },
  });
};

export const onSaveTreatment = (orgUnitId, treatment) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/createTreatment`,
    body: { treatment },
    entityType: "APPLICATION",
  },
});

export const onSaveOrUpdateTreatment = (orgUnitId, applicationId, treatment) => async dispatch => {
  const TREATMENT_ACTION_TYPES = determineApplicationType(applicationId);

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/editTreatment`,
      body: { treatment },
      types: TREATMENT_ACTION_TYPES,
    },
  });
};

// APPLICANT and CONSULTANT SECTION
export const onSaveOrUpdateApplicant = (applicationId, orgUnitId, applicant) => async dispatch => {
  const APPLICANT_ACTION_TYPES = determineActionTypes(get(applicant, "id", false), "APPLICANT");

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/editApplicant`,
      body: { applicant },
      types: APPLICANT_ACTION_TYPES,
    },
  });
};

// CONSULTANT SECTION
export const onSaveOrUpdateConsultant = (applicationId, orgUnitId, consultant) => async dispatch => {
  const CONSULTANT_ACTION_TYPES = determineActionTypes(get(consultant, "id", false), "CONSULTANT");

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/editConsultant`,
      body: { consultant },
      types: CONSULTANT_ACTION_TYPES,
    },
  });
};

export const onRemoveConsultant = (applicationId, consultantId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/removeConsultant/${consultantId}`,
    entityType: "CONSULTANT",
  },
});

// ENDORSEMENT SECTION
export const onSaveOrUpdateEndorsement = (applicationId, orgUnitId, endorsement) => async dispatch => {
  const ENDORSEMENT_ACTION_TYPES = determineActionTypes(get(endorsement, "id", false), "ENDORSEMENT");

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/completeEndorsement`,
      body: { endorsement },
      types: ENDORSEMENT_ACTION_TYPES,
      applicationId,
    },
  });
};

export const onSaveOrUpdateEndorsementRequest = (applicationId, orgUnitId, endorsement) => async dispatch => {
  const ENDORSEMENT_ACTION_TYPES = determineActionTypes(get(endorsement, "id", false), "ENDORSEMENT");

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/editEndorsement`,
      body: { endorsement },
      types: ENDORSEMENT_ACTION_TYPES,
      applicationId,
    },
  });
};

export const onMarkAsEndorsedRequest = (applicationId, orgUnitId, endorsement) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/markApplicationAsEndorsed`,
    body: { endorsement },
    types: ["MARKING_APPLICATION_AS_ENDORSED", ActionTypes.MARKED_APPLICATION_AS_ENDORSED, "ERROR_MARKING_APPLICATION_AS_ENDORSED"],
    applicationId,
  },
});

export const onUndoManualEndorsement = (applicationId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/undoManualEndorsement`,
    types: ["MARKING_APPLICATION_AS_REQUESTED", ActionTypes.MARKED_APPLICATION_AS_REQUESTED, "ERROR_MARKING_APPLICATION_AS_REQUESTED"],
    applicationId,
  },
});

export const onRemoveEndorsement = (applicationId, endorsementId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/removeEndorsement/${endorsementId}`,
    entityType: "ENDORSEMENT",
    endorsementId,
    applicationId,
  },
});

// DECISION SECTION
export const onAcceptApplication = (applicationId, orgUnitId, decision) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/acceptApplication`,
    body: { decision },
    types: [
      "ACCEPTING_APPLICATION",
      ActionTypes.ACCEPT_APPLICATION,
      "ERROR_ACCEPTING_APPLICATION",
    ],
  },
});

export const onSaveOrUpdateDecision = (applicationId, orgUnitId, decision) => async dispatch => {
  const DECISION_ACTION_TYPES = determineActionTypes(get(decision, "id", false), "DECISION");

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/recordDecision`,
      body: { decision },
      types: DECISION_ACTION_TYPES,
      applicationId,
    },
  });
};

export const onAssignApplicationToUser = (applicationId, orgUnitId, assignment) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/assignToUser`,
    body: { assignment },
    types: [
      "ASSIGNING_APPLICATION",
      "ASSIGNED_APPLICATION",
      "ERROR_ASSIGNING_APPLICATION",
    ],
  },
});

export const onProvisionalApproval = (applicationId, orgUnitId, provisionalApproval) => async dispatch => {
  const DECISION_ACTION_TYPES = determineApplicationType(applicationId);

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/provisionallyApproveApplication`,
      body: { provisionalApproval },
      types: DECISION_ACTION_TYPES,
    },
  });
};

export const onRemoveDecisionFromAgenda = (applicationId, decisionId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/removeDecision/${decisionId}`,
    types: [
      "DELETING_APPLICATION_DECISION",
      ActionTypes.DELETED_APPLICATION_DECISION,
      "ERROR_DELETING_APPLICATION_DECISION",
    ],
    applicationId,
    decision: null,
  },
});

export const onRevokeProvisionalApproval = (applicationId, orgUnitId) => async dispatch => {
  const DECISION_ACTION_TYPES = determineApplicationType(applicationId);

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/revokeProvisionalApproval`,
      types: DECISION_ACTION_TYPES,
    },
  });
};

export const onDeleteDecision = (applicationId, decisionId, orgUnitId) => async dispatch => {
  const DECISION_ACTION_TYPES = determineActionTypes(decisionId, "DECISION");

  return dispatch({
    [ApiActions.SAVE_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/deleteDecision`,
      types: DECISION_ACTION_TYPES,
      applicationId,
    },
  });
};

// INTERESTED PERSON SECTION
export const onSaveOrUpdateInterestedPerson = (applicationId, orgUnitId, interestedPerson) => async dispatch => {
  const INTERESTED_PERSON_ACTION_TYPES = determineActionTypes(get(interestedPerson, "id", false), "INTERESTED_PERSON");

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/editInterestedPerson`,
      body: { interestedPerson },
      types: INTERESTED_PERSON_ACTION_TYPES,
    },
  });
};

export const onRemoveInterestedPerson = (applicationId, personId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/removeInterestedPerson/${personId}`,
    entityType: "INTERESTED_PERSON",
    personId,
  },
});

// MARK APPLICATION AS COMPLETE
export const onCompleteApplication = (applicationId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/completeApplication`,
    entityType: "APPLICATION",
  },
});

// EXPIRED PRESCRIPTION PRESENTATION SECTION
export const onSaveOrUpdateExpiredPrescriptionPresentation = (applicationId, orgUnitId, expiredPrescriptionPresentation) => async dispatch => {
  const EXPIRED_PRESCRIPTION_TYPES = determineActionTypes(get(expiredPrescriptionPresentation, "id", false), "EXPIRED_PRESCRIPTION");
  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/editExpiredPrescriptionPresentation`,
      body: { expiredPrescriptionPresentation },
      types: EXPIRED_PRESCRIPTION_TYPES,
    },
  });
};

export const onRemoveExpiredPrescriptionPresentation = (applicationId, prescriptionId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/removeExpiredPrescriptionPresentation/${prescriptionId}`,
    entityType: "EXPIRED_PRESCRIPTION_PRESENTATION",
    prescriptionId,
  },
});

// APPLICATION APPEAL SECTION
export const onSaveOrUpdateApplicationAppeal = (applicationId, orgUnitId, appeal) => async dispatch => {
  const APPLICATION_APPEAL = determineActionTypes(get(appeal, "id", false), "APPLICATION_APPEAL");
  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/editApplicationAppeal`,
      body: { appeal },
      types: APPLICATION_APPEAL,
    },
  });
};

export const onRemoveApplicationAppeal = (applicationId, appealId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/treatApplicationAppeal/${appealId}`,
    appealId,
    entityType: "APPLICATION_APPEAL",
  },
});

// REPORT BACK SECTION
export const onSaveOrUpdateReportBack = (applicationId, orgUnitId, reportBack) => async dispatch => {
  const REPORTBACK_TYPES = determineActionTypes(get(reportBack, "id", false), "REPORTBACK");
  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/applications/${orgUnitId}/${applicationId}/editReportBack`,
      body: { reportBack },
      types: REPORTBACK_TYPES,
    },
  });
};

export const onRemoveReportBack = (applicationId, reportBackId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/removeReportBack/${reportBackId}`,
    entityType: "REPORTBACK",
    reportBackId,
  },
});

export const onUploadReportBackAttachment = (applicationId, reportBackId, orgUnitId, attachment) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/uploadReportBackAttachment/${reportBackId}`,
    body: { attachment },
    entityType: "REPORTBACK_REPORT",
  },
});

export const removeReportBackAttachment = (applicationId, reportBackId, orgUnitId, submission) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/removeReportBackReport/${reportBackId}`,
    body: { submission },
    entityType: "REPORTBACK_REPORT",
  },
});

// ATTACHMENT
export const onAddApplicationAttachment = (applicationId, orgUnitId, attachment) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/addAttachment`,
    body: { attachment },
    entityType: "APPLICATION_ATTACHMENT",
  },
});

export const uploadFile = (applicationId, formData, orgUnitId) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/upload`,
    headers: { "Content-Type": "multipart/form-data" },
    body: formData,
    entityType: "ATTACHMENT_FILE",
  },
});

export const previewAttachmentImage = (applicationId, attachmentId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/previewAttachmentImage/${attachmentId}`,
    method: "GET",
    entityType: "ATTACHMENT_IMAGE",
  },
});

export const removeApplicationAttachment = (applicationId, attachmentId, fileId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/removeApplicationAttachment/${attachmentId}/${fileId}`,
    entityType: "APPLICATION_ATTACHMENT",
    attachmentId,
  },
});

// LINK
export const onAddExternalResourceLink = (applicationId, orgUnitId, resourceLink) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/addExternalResourceLink`,
    body: { resourceLink },
    entityType: "APPLICATION_ATTACHMENT",
  },
});

export const removeExternalResourceLink = (applicationId, attachmentId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/removeExternalResourceLink/${attachmentId}/`,
    entityType: "APPLICATION_ATTACHMENT",
    attachmentId,
  },
});

// APPLICATION - ADDITIONAL INFORMATION
export const onSaveAdditionalInformation = (applicationId, additionalInformation, orgUnitId) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/editAdditionalInformation`,
    body: { additionalInformation: { additionalInformation } },
    entityType: "APPLICATION_ADDITIONAL_INFORMATION",
  },
});

// WITHDRAW APPLICATION
export const onWithdrawApplication = (applicationId, applicationStatus, orgUnitId) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/withdrawApplication`,
    body: { applicationStatus },
    entityType: "APPLICATION",
  },
});

// PLACE APPLICATION IN DRAFT
export const placeApplicationInDraft = (applicationId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/placeApplicationInDraft`,
    entityType: "APPLICATION",
  },
});

// REINSTATE APPLICATION
export const reinstateApplication = (applicationId, applicationStatus, orgUnitId) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/reinstateApplication`,
    body: { applicationStatus },
    entityType: "APPLICATION",
  },
});

export const cloneApplication = (orgUnitId, applicationId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/cloneApplication`,
    method: "GET",
    types: [ActionTypes.CLONING_APPLICATION, ActionTypes.CLONED_APPLICATION, "ERROR_CLONING_APPLICATION"],
  },
});

export const onAssignApprovalRecommendation = (applicationId, assignedToUserId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/assignApprovalRecommendation/${assignedToUserId}`,
    assignedToUserId,
    entityType: "APPROVAL_RECOMMENDATION_ASSIGNMENT",
  },
});

export const onSaveOrUpdateApprovalRecommendation = (applicationId, approvalRecommendationComment, orgUnitId) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/editApprovalRecommendation`,
    body: { approvalRecommendationComment },
    entityType: "APPROVAL_RECOMMENDATION_COMMENT",
  },
});

export const onRemoveApprovalRecommendation = (applicationId, approvalRecommendationId, orgUnitId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/removeApprovalRecommendation/${approvalRecommendationId}`,
    entityType: "APPROVAL_RECOMMENDATION_COMMENT",
    approvalRecommendationId,
  },
});

export const onTransferSite = (orgUnitId, applicationId, siteTransferInfo) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/applications/${orgUnitId}/${applicationId}/transferSite`,
    body: { siteTransferInfo },
    types: [
      ActionTypes.TRANSFERRING_SITE,
      ActionTypes.TRANSFERRED_SITE,
      ActionTypes.ERROR_TRANSFERRING_SITE,
    ],
  },
});
