import React, { Fragment } from "react";
import { map, isEmpty, find, filter, groupBy } from "lodash";
import InfoCard from "app/main/applicationWorklist/components/info-card";
import { Typography } from "@material-ui/core";

const RestrictionDisplay = props => {
  const { drugResultJson, strengthName, formularies } = props;

  const json = JSON.parse(drugResultJson);
  const getRestrictions = () => {
    const strength = find(json.strengths, x => x.name === strengthName);
    const hasStrength = !isEmpty(strength);
    return filter(json.restrictions, restriction => restriction.strengthId === (hasStrength ? strength.strengthId : null));
  };

  const preSortedRestrictions = getRestrictions();

  const restrictions = groupBy(preSortedRestrictions, x => x.formularyId);
  if (isEmpty(restrictions)) return null;

  const getRestrictionSeverity = restrictionLevelType => {
    switch (restrictionLevelType) {
      case "Green": return "success";
      case "Amber": return "warning";
      case "Red": return "error";
      case "Stop": return "error";
      case "Grey": return "error";
      case "Black": return "error";
      default: return null;
    }
  };

  const getRestrictionIcon = restrictionLevelType => {
    switch (restrictionLevelType) {
      case "Green": return "check_circle";
      case "Amber": return "info";
      case "Red": return "error_outline";
      case "Stop": return "error_outline";
      case "Grey": return "cancel";
      case "Black": return "cancel";
      default: return "check_circle";
    }
  };

  const getRestrictionSeverityFromDescription = restrictionLevel => {
    switch (restrictionLevel) {
      case "Restricted": return "warning";
      case "Unrestricted": return "success";
      case "Non-Formulary": return "error";
      case "Highly Restricted": return "error";
      default: return "warning";
    }
  };

  const getRestrictionsHeader = formularyId => {
    let response = null;

    if (formularyId && !isEmpty(formularies)) {
      const formularyName = find(formularies, x => x.subFormularyId === formularyId)?.subFormularyName;
      response = `${formularyName} Restrictions:\n`;
    }

    return response;
  };

  const indicationOutput = indication => {

    // taken from formulary indication-pill-box css style
    const offLabelStyle = {
      background: "#D7D7D7",
      borderRadius: "45px",
      padding: "2px 4px",
      fontSize: 9,
      whiteSpace: "nowrap",
      alignItems: "center",
      display: "flex",
    };

    // if it's off label, then it is italicized with (off-label) after it
    if (indication.offLabel) {
      return <><i>{indication.name}</i>&nbsp;<span style={offLabelStyle}>off-label</span></>;
    }

    // if it's not off label, then it is just the name and bolded
    return <b>{indication.name}</b>;
  }


  const getRestrictionTitleWithIndications = restriction => {
    // Ensures the title and indications are on the same line
    const rowStyle = {
      display: "flex",
      flexDirection: "row",
    };


    let title = (
      <Typography>
        {restriction?.restrictionLevelDescription ?? restriction?.restrictionLevelType}
      </Typography>
    )

    let indications = undefined;
    
    if (!isEmpty(restriction.indications)) {
      indications = (
        <span style={{ ...rowStyle, fontWeight: 400 }}>
          &nbsp;for:
          {map(restriction.indications, (indication, index) => (
            <Typography key={index} style={rowStyle}>
               &nbsp;
              {indicationOutput(indication)}
              {`${index < restriction.indications.length - 1 ? ", " : ""}`}
            </Typography>
          ))}
        </span>
      );
    }
    
    return (
      <span style={rowStyle}>
        {title}
        {indications}
      </span>
    );
  }

  const getHeadlineForRestriction = restriction => {
    let response = null;

    if (restriction.appliesToTradeProductName) {
      response += `Restriction on brand product: ${restriction.appliesToTradeProductName}`;
    }

    return response;
  };

  return (
    <div className="items-container pl-32 pr-16 w-auto">
      {map(restrictions, (restrictionGroup, index) => {
        if (restrictionGroup.length > 0) {
          return (
            <Fragment
              key={index}
            >
              <Typography
                variant="body1"
                className="font-bold"
              >
                {getRestrictionsHeader(restrictionGroup[0].formularyId)}
              </Typography>
              {map(restrictionGroup, (restriction, restrictionIndex) => (
                <InfoCard
                  key={restrictionIndex}
                  icon={getRestrictionIcon(restriction?.restrictionLevelType)}
                  severity={getRestrictionSeverity(restriction?.restrictionLevelType)
                ?? getRestrictionSeverityFromDescription(restriction?.restrictionLevel ?? restriction?.restrictionLevelDescription ?? restriction.restrictionLevelType)}
                  headline={(getHeadlineForRestriction(restriction))}
                  title={getRestrictionTitleWithIndications(restriction)}
                >
                  {(restriction.stampedRestrictionHtml || restriction.customText)
                  && (
                    // eslint-disable-next-line react/no-danger
                    <div key={restriction.stampedRestrictionHtml} dangerouslySetInnerHTML={{ __html: isEmpty(restriction.stampedRestrictionHtml) ? restriction.customText : restriction.stampedRestrictionHtml }} />
                  )}
                </InfoCard>
              ))}
            </Fragment>
          );
        }

        return null;
      })}
    </div>
  );
};

export default RestrictionDisplay;
