/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Grid, CircularProgress } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import { isEmpty, debounce } from "lodash";
import qs from "qs";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import ChatPanel from "components/items/chat-panel/index";
import PatientReferralViewSelector from "app/main/referrals/components/patient-referral-view-selector";
import DefaultListItem from "components/items/default-list-item";
import InfiniteLoadingList from "components/items/infinite-Loading-list";
import { fetchPatientReferrals, fetchPatientActiveReferrals } from "app/main/referrals/actions/patientReferrals.actions";
import { getExistingPatientById } from "app/main/patients/actions/patients.actions";
import { getCurrentPatient, isCurrentPatientLoading } from "app/main/patients/reducers/patients.reducers";

import PatientDetails, { PatientDOBLabel, NameLabel, PatientIdentifierLabel } from "app/main/patients/components/patient-details";
import AdvancedFilter from "components/items/advanced-filter";
import { isMobileContentSidebarOpen } from "app/store/reducers/sidebars.reducer";
import { setMobileContentSidebarStatus } from "app/store/actions/sidebars.actions";
import ReferralListPanel from "app/main/patientReferralWorklist/components/referral-list-panel";
import ActiveReferralListPanel from "app/main/patientReferralWorklist/components/active-referral-list-panel";
import ViewPatientRecordButton from "app/main/patients/components/view-patient-record-button";
import { getPatientReferralSearchParams } from "app/main/referrals/reducers/patientReferrals.reducers";
import ReferralPatientDetails from "app/main/referrals/components/referral-patient-details";
import { resetReferralWorklistFilter } from "app/main/referralWorklist/actions/referralWorklist.actions";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import { getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";
import { ReferralWorklistIcon } from "helpers/icon-finder";
import { getPatientLabel } from "utils/get-environment-variables";

import { searchPatientReferrals, updatePatientReferralWorklistSearchParams, resetPatientReferralWorklistResults } from "../actions";
import { getPatientReferralsList, getPatientReferralWorklistPageInfo, getPatientReferralWorklistSearchParams, isPageLoading, getPageErrorMessage } from "../reducers/patientReferralWorklist.reducers";

const patientLabel = getPatientLabel();

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  content: {
    display: "flex",
    alignItems: "center",
    zIndex: 999,
    gap: `${theme.spacing(1)}px`,
    flex: 1,
  },
}));

const PatientReferralWorklistPage = ({ forceLoad }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const patients = useSelector(getPatientReferralsList);
  const pageInfo = useSelector(getPatientReferralWorklistPageInfo);
  let searchParams = useSelector(getPatientReferralWorklistSearchParams);

  const loading = useSelector(state => isPageLoading(state, pageInfo?.pageNumber));
  const error = useSelector(state => getPageErrorMessage(state, pageInfo?.pageNumber));
  const isSidebarOpen = useSelector(isMobileContentSidebarOpen);
  const patientReferralSearchParams = useSelector(getPatientReferralSearchParams);
  const [loadingItem, setLoadingItem] = useState(null);

  const selected = useSelector(getCurrentPatient);
  const loadingCurrent = useSelector(isCurrentPatientLoading);

  const [initialSearch, setInitialSearch] = useState(true);
  const [initialCreate, setInitialCreate] = useState(false);

  const signedInOrgUnit = useSelector(getSignedInOrgUnit);
  const orgUnitId = signedInOrgUnit?.id;

  const identifierProps = { showIcon: false, disabled: true };

  searchParams = {
    ...searchParams,
    orgUnitId,
  };

  const fetchAllPatientReferrals = patientId => {
    const getActives = dispatch(fetchPatientActiveReferrals(orgUnitId, patientId, referralWorklistSettings?.activeReferralListPageSize));
    const getAll = dispatch(fetchPatientReferrals({ ...patientReferralSearchParams, patientId, orgUnitId }, 1, true));
    return Promise.all([getActives, getAll]);
  };

  const onSearch = useCallback(debounce((params, pageNumber, pageSize, forceToReLoad) => {
    dispatch(searchPatientReferrals(params, pageNumber, pageSize, forceToReLoad));
  }, 300));

  const onSearchText = val => {
    onSearch({ ...searchParams, name: val }, 1, pageInfo.pageSize, true);
    if (initialSearch) {
      setInitialSearch(false);
    }
  };

  useEffect(() => {
    dispatch(resetReferralWorklistFilter());
  }, []);

  useEffect(() => () => dispatch(resetPatientReferralWorklistResults()), []);

  useEffect(() => {
    const queryString = qs.parse(history.location.search, { ignoreQueryPrefix: true });
    const { patientId, patientIdentifier, createReferral } = queryString;
    if (patientIdentifier) {
      onSearchText(patientIdentifier);
    }
    if (patientId) {
      dispatch(getExistingPatientById(patientId));
      fetchAllPatientReferrals(patientId);
    }
    if (createReferral) {
      setInitialCreate(true);
    }
  }, []);

  const onClickItem = selectedItem => {
    const patientId = selectedItem?.patient?.patientId;
    const referralCount = selectedItem?.referralCount;
    setLoadingItem(patientId);

    if (isSidebarOpen) {
      dispatch(setMobileContentSidebarStatus());
    }
    if (referralCount > 0) {
      fetchAllPatientReferrals(patientId);
    }
    dispatch(getExistingPatientById(patientId, true)).then(() => {
      history.push(`/patientReferralWorklist?patientId=${patientId}`);
    });
  };

  const goToPatientMedicalRecordPage = () => history.push(`/PatientReferralWorklist/patients/${selected.patientId}`);

  const onClearSearch = () => {
    // prevent to call api
    dispatch(updatePatientReferralWorklistSearchParams({ name: null }));
    setInitialSearch(true);
  };

  const renderItem = item => {
    const isActive = !isEmpty(selected) && selected.patientId === item.patient.patientId;

    return (
      <DefaultListItem
        id={item.patient.patientId}
        onClick={isActive ? null : () => onClickItem(item)}
        active={isActive}
        content={(
          <>
            <div className={clsx(classes.container, "flex-row-container with-gutter")}>
              <NameLabel name={item.patient.displayNameFull} className="" />
              <PatientDOBLabel age={item.patient.age} birthDate={item.patient.birthDate} />
            </div>
            <PatientIdentifierLabel
              className={clsx(classes.content)}
              patientId={item.patient.patientId}
              showIcon={false}
              onlyPrimaryIdentifier
              patientIdentifiers={item.patient.patientIdentifiers}
              {...identifierProps}
              patient={item.patient}
            />
            <div className={clsx(classes.content)}>
              {item.referralCount > 0
                && <Typography variant="caption">{`${item.referralCount} referral${item.referralCount > 1 ? "s" : ""}`}</Typography>}
              {loadingItem === item.patient.patientId && loadingCurrent && <CircularProgress size={20} />}
            </div>
          </>
        )}
      />
    );
  };

  const renderSidebarContent = () => (
    <InfiniteLoadingList
      data={patients}
      renderItem={item => renderItem(item)}
      loading={loading}
      error={error}
      total={pageInfo.totalRecords}
      pageStart={pageInfo.pageNumber}
      initial={initialSearch}
      initialMessage="Please Enter a Search Term"
      loadFunction={page => {
        const number = page === undefined ? 1 : pageInfo.pageNumber + 1;
        return onSearch(searchParams, number, pageInfo.pageSize, forceLoad);
      }}
    />
  );

  const renderHeader = () => (
    <Grid container className="py-24 px-16">
      <PatientDetails
        renderContent={(
          <ReferralPatientDetails
            patient={selected}
            goToPatientRecordPage={goToPatientMedicalRecordPage}
          />
        )}
        renderRightContent={!isEmpty(selected.patientId) && <ViewPatientRecordButton onClick={goToPatientMedicalRecordPage} />}
      />
    </Grid>
  );

  const renderContent = () => (
    <>
      <ActiveReferralListPanel
        patientId={selected.patientId}
        orgUnitId={orgUnitId}
        initialCreate={initialCreate}
        fetchAllPatientReferrals={fetchAllPatientReferrals}
      />
      {!referralWorklistSettings?.displayOnlyActiveReferralList && <ReferralListPanel patientId={selected.patientId} orgUnitId={orgUnitId} isLast />}
    </>
  );

  return (
    <ChatPanel
      empty={isEmpty(selected)}
      renderSidebarHeader={() => (
        <>
          <div className="mb-8">
            <PatientReferralViewSelector />
          </div>
          <AdvancedFilter
            renderFilterInfo={<Typography variant="caption">Total Results: {pageInfo.totalRecords}</Typography>}
            onSearch={onSearchText}
            searchValue={searchParams?.name ?? ""}
            searchPlaceholder={`Search ${patientLabel.single} name or identifier`}
            searchInputProps={{ onClearSearch, autoFocus: true }}
          />
        </>
      )}
      renderSidebarContent={() => renderSidebarContent()}
      renderContent={() => renderContent()}
      renderHeader={() => renderHeader()}
      emptyIcon={<ReferralWorklistIcon />}
      emptyTitle={`Select a ${patientLabel.single} to view details`}
    />
  );
};

export default PatientReferralWorklistPage;
