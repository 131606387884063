import React from "react";
import { Dialog, DialogContent, CircularProgress, Typography } from "@material-ui/core";

const LoadingDialog = ({ open, loadingMessage = "Loading...", maxWidth = "xs" }) => (
  <Dialog open={open} maxWidth={maxWidth}>
    <DialogContent>
      <div className="flex items-center flex-col w-320 py-16">
        <CircularProgress />
      </div>
      <Typography align="center" className="mb-24">{loadingMessage}</Typography>
    </DialogContent>
  </Dialog>
);

export default LoadingDialog;
