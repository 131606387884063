import React from "react";
import { useSelector } from "react-redux";
import { isEmpty, map } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import { ButtonBase } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import clsx from "clsx";

import { PatientSummary } from "app/main/patients/components/patient-details";
import EmptyState from "components/items/empty-state";
import LoadingState from "components/items/loading-state";
import { getPatientSearchResults, isPageLoading, isPageLoaded, getPageError } from "app/main/patients/reducers/patientsSearch.reducers";
import PatientSearchCard from "app/main/patients/components/patient-search-card";

const useStyles = makeStyles(theme => ({
  selected: {
    borderColor: theme.palette.secondary.main,
  },
}));

const PatientSearchResults = ({ icon = "account_circle", selectedPatient, setSelectedPatient }) => {
  const classes = useStyles();
  const isLoading = useSelector(isPageLoading);
  const isLoaded = useSelector(isPageLoaded);
  const error = useSelector(getPageError);
  const patients = useSelector(getPatientSearchResults);

  if (isLoading) {
    return <PatientSearchCard><LoadingState /></PatientSearchCard>;
  }

  if (error) {
    return <Alert severity="error">{error}</Alert>;
  }

  if (isEmpty(patients)) {
    return <PatientSearchCard><EmptyState icon={icon} content={isLoaded && "There are no results that match your search criteria"} /></PatientSearchCard>;
  }

  return (
    <div className="items-container">
      {map(patients, (x, index) => (
        <ButtonBase key={index.toString()} onClick={() => setSelectedPatient(x)} disableRipple>
          <PatientSearchCard
            withColor
            classes={{ root: clsx(selectedPatient === x && classes.selected, "pt-0 m-0 w-full text-left") }}
          >
            <PatientSummary patient={x} showAddress showContactDetails />
          </PatientSearchCard>
        </ButtonBase>
      ))}
    </div>
  );
};

export default PatientSearchResults;
