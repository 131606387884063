import curryConnector from "utils/connectors";
import { get, filter } from "lodash";

export const REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD_STATE_KEY = "referralDocumentAttachmentUploads";

const curry = fn => curryConnector(fn, REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD_STATE_KEY);

export const ActionTypes = {
  CREATED_REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD: "CREATED_REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD",
  DELETED_REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD: "DELETED_REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD",
  CLEAR_REFERRAL_DOCUMENT_ATTACHMENT_UPLOADS: "CLEAR_REFERRAL_DOCUMENT_ATTACHMENT_UPLOADS",
};

const INITIAL_STATE = {
  referralDocumentAttachmentUploads: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.CREATED_REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD:
      return {
        ...state,
        referralDocumentAttachmentUploads: { ...state.referralDocumentAttachmentUploads, [action.payload.id]: action.payload },
      };
    case ActionTypes.DELETED_REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD:
      return {
        ...state,
        referralDocumentAttachmentUploads: filter(state.referralDocumentAttachmentUploads, item => item.id !== action.payload.referralDocumentAttachmentUploadId),
      };
    case ActionTypes.CLEAR_REFERRAL_DOCUMENT_ATTACHMENT_UPLOADS:
      return INITIAL_STATE;
    default:
      return state || INITIAL_STATE;
  }
};

export const getReferralDocumentAttachmentUploads = curry(({ localState }) => get(localState, ["referralDocumentAttachmentUploads"], []));
