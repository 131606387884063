import React, { useState } from "react";
import withPermissions from "permissions/withPermissions";
import { useDispatch, useSelector } from "react-redux";
import { map, size } from "lodash";
import { Alert } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";

import { Button, Popover, MenuItem, CircularProgress, ListItemText, Typography } from "@material-ui/core";
import { OrgUnitsIcon } from "helpers/icon-finder";
import PopupMenu from "components/items/popup-menu";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import ConfirmationDialog from "components/items/confirmation-dialog";

import { fetchOrgUnitsForSelector } from "app/main/orgUnits/actions/orgUnit.actions";
import { isOrgUnitsSelectorLoading, getAuthorizedOrgUnits } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { changeSignedInOrgUnit } from "app/auth/store/actions/user.actions";
import { getSignedInOrgUnit, getActiveOrgUnitIds } from "app/auth/store/reducers/user.reducer";

const useStyles = makeStyles(theme => ({
  menuItem: {
    width: "100%",
    minHeight: 48,
    minWidth: 164,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  siteButton: {
    paddingTop: "10px",
    paddingLeft: "16px",
    paddingRight: "16px",
    '& .MuiButton-startIcon': {
      display: "inline-block",
      verticalAlign: "middle",
      marginTop: "3px",
    },
    '& .MuiButton-label': {
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        justifyContent: "left",
        overflow: "hidden",
        display: "inline-block",
    },
    [theme.breakpoints.between("0","900")]: {
      maxWidth: "190px",
    }
  }
}));

const SiteSelector = ({
  hasPermissionChangeCurrentSiteConfiguration,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const orgUnits = useSelector(getAuthorizedOrgUnits);
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);
  const isLoading = useSelector(isOrgUnitsSelectorLoading);

  const [error, setError] = useState(null);
  const [updatingOrgUnitId, setUpdatingOrgUnitId] = useState(null);
  const disabledSwitchSite = size(useSelector(getActiveOrgUnitIds)) <= 1;

  if (hasPermissionChangeCurrentSiteConfiguration !== true) {
    return null;
  }

  if (disabledSwitchSite) {
    return (
      <div color="inherit" className="px-16 flex items-center">
        <OrgUnitsIcon className="pr-8" />
        <Typography>{signedInOrgUnit != null ? signedInOrgUnit.name : "No current site"}</Typography>
      </div>
    );
  }

  const onCloseDialog = () => dispatch(closeDialog());

  const SiteSelectorContent = ({ onClose }) => {
    if (isLoading) {
      return (
        <MenuItem disabled className={classes.menuItem}>
          <div className="flex-1 flex justify-center"><CircularProgress size={20} /></div>
        </MenuItem>
      );
    }

    if (!error && orgUnits.length === 0) {
      return (
        <MenuItem disabled className={classes.menuItem}>
          <ListItemText>No Available Sites</ListItemText>
        </MenuItem>
      );
    }

    return map(orgUnits, orgUnit => {
      const isUpdating = updatingOrgUnitId === orgUnit.id;
      const isSelected = signedInOrgUnit != null && signedInOrgUnit.id === orgUnit.id;

      return (
        <MenuItem
          className={classes.menuItem}
          key={orgUnit.id}
          component="button"
          disabled={isUpdating || isSelected}
          onClick={() => {
            onClose();
            dispatch(openDialog({
              maxWidth: "xs",
              fullScreen: false,
              children: (
                <ConfirmationDialog
                  type="warning"
                  onCancel={onCloseDialog}
                  onConfirm={() => {
                    setUpdatingOrgUnitId(orgUnit.id);
                    dispatch(changeSignedInOrgUnit(orgUnit.id)).then(payload => {
                      setError(payload.error ? "Error switching site" : null);
                      setUpdatingOrgUnitId(null);
                    });
                    onCloseDialog();
                  }}
                  title={`Switch to ${orgUnit.name}`}
                  content={`Switching to ${orgUnit.name} will cause a redirect to the home page; please ensure all information is saved prior to switching.`}
                />
              ),
            }));
          }}
        >
          {orgUnit.name}
          {isUpdating && (<CircularProgress size={20} />)}
        </MenuItem>
      );
    });
  };

  return (
    <PopupMenu
      trigger={popupState => (
        <Button
          color="inherit"
          className={classes.siteButton}
          startIcon={<OrgUnitsIcon />}
          onClick={e => {
            popupState.onClick(e.currentTarget);
            dispatch(fetchOrgUnitsForSelector()).then(payload => {
              setError(payload != null && payload.error ? "Error loading sites" : null);
            });
          }}
        >
          {signedInOrgUnit != null ? signedInOrgUnit.name : "No current site"}
          {updatingOrgUnitId && (<CircularProgress className="ml-8" color="inherit" size={20} />)}
        </Button>
      )}
      content={({ close, ...other }) => (
        <Popover
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          transformOrigin={{ vertical: "top", horizontal: "center" }}
          {...other}
        >
          <SiteSelectorContent onClose={close} />
          {error && !isLoading && <Alert severity="warning">{error}</Alert>}
        </Popover>
      )}
    />
  );
};

export default withPermissions("ChangeCurrentSiteConfiguration")(SiteSelector);
