import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";

export const fetchIndicationTermResults = (searchTerm, pageSize = 50) => dispatch => {
  const url = `/codeterms/indication?pageSize=${pageSize}${formatSearchParams(searchTerm)}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "INDICATION_TERMS",
    },
  });
};
