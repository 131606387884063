import React, { useState } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { map, find, toLower, join, isEmpty, filter, concat } from "lodash";
import { Chip, InputLabel, Input, IconButton, Tooltip } from "@material-ui/core";

import DefaultButton from "components/items/default-button";
import DisabledInput from "components/inputs/components/disabled-input";
import BaseInputField from "components/inputs/components/base-input-component";
import FormContext from "components/FormContext";
import HelperText from "components/inputs/components/helper-text";
import { CloseCrossIcon } from "helpers/icon-finder";

const useStyles = makeStyles(() => ({
  tag: {
    "& svg": {
      pointerEvents: "initial",
    },
  },
  input: {
    "&::placeholder": {
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
  },
}));
const MultipleTextInput = ({
  field: { name, value: items },
  form: { setFieldValue },
  label,
  readOnly,
  disabled,
}) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(null);

  const addItem = () => {
    const isMatch = find(items, x => toLower(x) === toLower(inputValue));

    if (isMatch) {
      setError(`The ${label} entered already exists in the list`);
      return items;
    }
    setInputValue("");
    return setFieldValue(name, isEmpty(items) ? [inputValue] : concat(items, inputValue));
  };

  const removeItem = item => {
    const updatedItems = filter(items, x => x !== item);
    setFieldValue(name, updatedItems);
  };

  return (
    <FormContext.Consumer>
      {({ formDisabled }) => {
        const disabledInput = readOnly || disabled || formDisabled;

        if (disabledInput) {
          return (
            <BaseInputField multiline>
              <DisabledInput label={label} value={items ? join(items, ", ") : null} />
            </BaseInputField>
          );
        }

        return (
          <>
            <BaseInputField error={!isEmpty(error)}>
              <InputLabel htmlFor={label}>{label}</InputLabel>
              <div className="mt-16 flex sm:flex-row sm:items-center flex-col">
                <Input
                  id={label}
                  label={label}
                  placeholder={`Enter name of the new ${label}`}
                  value={inputValue}
                  rows={2}
                  classes={{
                    root: "flex-1 flex-row-container with-gutter",
                    input: clsx("h-auto flex-1", classes.tag, classes.input),
                  }}
                  onKeyDown={e => {
                    if (e.key === "Enter" && !isEmpty(inputValue)) {
                      addItem();
                    }
                  }}
                  onChange={e => {
                    if (error) {
                      setError(null);
                    }
                    setInputValue(e.target.value);
                  }}
                  startAdornment={map(items, item => {
                    const renderToolTipTile = () => "Remove";

                    return (
                      <Chip
                        label={item}
                        size="small"
                        key={item}
                        onDelete={() => removeItem(item)}
                        deleteIcon={<Tooltip title={renderToolTipTile()}><div><CloseCrossIcon size={17} /></div></Tooltip>}
                      />
                    );
                  })}
                  endAdornment={inputValue
                  && (
                  <div className="absolute right-0">
                    <IconButton
                      icon="clear"
                      title="Clear input"
                      onClick={() => { setInputValue(""); setError(null); }}
                    />
                  </div>
                  )}
                />
                <DefaultButton type="submit" label="Add" disabled={inputValue === ""} onClick={addItem} />
              </div>
            </BaseInputField>
            {error && (
              <div className="ml-32 -mt-8">
                <HelperText error helperText={error} />
              </div>
            )}
          </>
        );
      }}
    </FormContext.Consumer>
  );
};

export default MultipleTextInput;
