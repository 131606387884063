import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { filter, map, isEmpty, find, concat, includes } from "lodash";
import { getSubSpecialties, areSubSpecialtiesLoading, areSubSpecialtiesLoaded } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchAllSubSpecialties } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { SubSpecialtyIcon } from "helpers/icon-finder";
import { getSubSpecialtyLabel } from "app/auth/store/reducers/system-configuration";

export default ({
  specialtyIds,
  label,
  showIcon = true,
  showDeprecated = false,
  ...other
}) => {
  const dispatch = useDispatch();
  const allSubSpecialties = useSelector(getSubSpecialties);
  const loading = useSelector(areSubSpecialtiesLoading);
  const loaded = useSelector(areSubSpecialtiesLoaded);
  const subSpecialtyLabel = useSelector(getSubSpecialtyLabel);
  const { field: { value: selectedValue } } = other;
  const displayLabel = label ?? subSpecialtyLabel;

  let options = [];

  React.useEffect(() => {
    if (!loaded) { // only load if haven't been loaded
      dispatch(fetchAllSubSpecialties(true));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  if (loading) return <AutoCompleteLoading label={displayLabel} />;

  if (specialtyIds) {
    const filterOption = filter(allSubSpecialties,
      x => includes(specialtyIds, x.specialtyProfileId)
      && (showDeprecated || (!showDeprecated && !x.isDeprecated)));
    options = map(filterOption, x => ({ value: x.id, label: `${x.specialtyProfileName} - ${x.displayName}`, disabled: x.isDeprecated, displayName: x.displayName }));
    const selected = selectedValue?.value ?? selectedValue;
    // Check if myArray is an array and does not contain only undefined
    const isValidArray = Array.isArray(selected) ? !selected.every(item => item === undefined) : true;

    if (!isEmpty(selectedValue) && isValidArray) {
      // check if selected value is in the option
      const existing = find(options, x => x.value === selected);
      if (!existing) {
        options = concat(options ?? [], selectedValue);
      }
    }
  }

  return (
    <AutoComplete
      label={displayLabel}
      options={options}
      filterSelectedOptions
      getOptionDisabled={option => option.disabled}
      icon={showIcon && <SubSpecialtyIcon />}
      {...other}
    />
  );
};
