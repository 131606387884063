import React, { useEffect, useState } from "react";
import { find, lowerCase } from "lodash";

import { SelectInput } from "components/inputs";
import OCRBaseComponent from "components/ocr-components/ocr-based-component";

const SelectInputField = ({
  className,
  onBlur,
  ocrValue,
  ignoreCase = false,
  ...other
}) => {
  const { field: { name }, options, confidenceLevelPercentage, thresholdPercentage } = other;
  const [ocrMatch, setOCRMatch] = useState(null);
  const useConfirmedValue = (confidenceLevelPercentage >= thresholdPercentage || !other.showActionConfirm);

  useEffect(() => {
    const selectedOption = ignoreCase === true ? find(options, x => lowerCase(x.value) === lowerCase(ocrValue) || lowerCase(x.label) === lowerCase(ocrValue)) : find(options, x => x.value === ocrValue || x.label === ocrValue);
    setOCRMatch(selectedOption);
  }, [options, ocrValue, ignoreCase]);

  const handleConfirmed = (isAccept, change) => {
    if (isAccept) {
      if (ocrMatch) {
        change(name, ocrMatch?.value);
      }
    } else {
      change(name, null);
    }
  };

  return (
    <OCRBaseComponent
      inputComponent={SelectInput}
      onHandleConfirmed={handleConfirmed}
      ocrLabel={ocrMatch?.label}
      ocrValue={useConfirmedValue ? ocrMatch?.value ?? ocrValue : ocrValue}
      {...other}
    />
  );
};

export default SelectInputField;
