import React from "react";
import { useMediaQuery } from "@material-ui/core";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import { useDispatch, useSelector } from "react-redux";
import { includes, find } from "lodash";

import { NoteIcon, RequestInfoIcon, TaskIcon, FlagOff, Flag } from "helpers/icon-finder";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import DefaultButton from "components/items/default-button";
import EditReferralNoteForm from "app/main/referrals/components/edit-referral-note-form";
import ReferralCorrespondenceForm from "app/main/referrals/components/referral-correspondence-form";
import ReferralRequestMoreInfoForm from "app/main/referrals/components/referral-request-more-info-form";
import { getUser } from "app/auth/store/reducers/user.reducer";
import withPermissions from "permissions/withPermissions";
import { getReferralActionTypeSettings, getReferralWorklistSettings, getAllowCorrespondenceAttachmentStates, getEnableSendGPLetterActionLabel } from "app/auth/store/reducers/system-configuration";
import ActivityLogItem from "components/items/activity-log/item";
import ReferralTaskForm from "app/main/referrals/components/referral-task-form";
import ReferralSendToCorrespondenceForm from "app/main/referrals/components/referral-send-to-correspondence-form";
import ReferralUrgentStatusForm from "app/main/referrals/components/referral-urgent-status-form";
import { archivedWorkflowState } from "../../helpers/workflow-state-names";
import { requestMoreInformationActionType, urgentActionType, notUrgentActionType } from "../../helpers/action-type-names";
import { getWarningMessages } from "../../helpers/get-referral-action-warning-messages";
import useCanEditInEndState from "hooks/use-can-edit-in-end-state";

const useStyles = makeStyles(theme => ({
  indicator: {
    width: 20,
    height: 20,
    borderStyle: "dashed",
  },
  connector: {
    backgroundColor: "transparent",
    borderWidth: 1,
    borderStyle: "dashed",
    borderColor: theme.palette.divider,
    width: 0,
  },
}));

const ReferralActivityLogHeader = ({
  hasPermissionReferralCorrespondenceAttachmentEditAnyState,
  hasPermissionReferralCorrespondenceCreate,
  hasPermissionReferralNotesCreate,
  hasPermissionReferralTasksCreate,
  hasPermissionAllowOwnReferralActivityCreateOnly,
  orgUnitId,
  referral: { permissions, referrerUrgency, referralStatus, canReplyToReferrer, availableActions, referrerFullName, id, assignedTo, createdByUserId, referralDocument },
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector(getUser);
  const theme = useTheme();
  const displayLabel = useMediaQuery(theme.breakpoints.up("sm"));
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const allowCorrespondenceAttachmentStates = useSelector(getAllowCorrespondenceAttachmentStates);
  const sendGPLetterLabel = useSelector(getEnableSendGPLetterActionLabel);
  const showButtons = referralStatus !== archivedWorkflowState;
  const canEditInEndState = useCanEditInEndState(referralStatus);

  const onClose = () => dispatch(closeDialog());
  const referralActionTypes = useSelector(getReferralActionTypeSettings);
  const referralActionTypeSettings = find(referralActionTypes, x => x.actionType === requestMoreInformationActionType);
  const requestMoreInformationLabel = referralActionTypeSettings?.nameSingle;
  const showRequestForMoreInformationButton = permissions.canRequestMoreInformation && includes(availableActions, requestMoreInformationActionType);
  const showReplyToReferrerButton = canReplyToReferrer && hasPermissionReferralCorrespondenceCreate && referralWorklistSettings?.outboundReferralNotificationsEnabled === true && canEditInEndState;
  const allowCreateOtherUserReferralActivity = !hasPermissionAllowOwnReferralActivityCreateOnly
                                               || (hasPermissionAllowOwnReferralActivityCreateOnly && user.userId === createdByUserId);
  const showAddNoteButton = hasPermissionReferralNotesCreate && allowCreateOtherUserReferralActivity && canEditInEndState;
  const showAddTaskButton = hasPermissionReferralTasksCreate && allowCreateOtherUserReferralActivity;
  const showSendCorrespondLetterButton = permissions.canAddLetter && ((includes(allowCorrespondenceAttachmentStates, referralStatus) || hasPermissionReferralCorrespondenceAttachmentEditAnyState));

  const urgentActionTypeSettings = find(referralActionTypes, x => x.actionType === urgentActionType);
  const showSetUrgentButton = permissions.canMarkAsUrgent && !referrerUrgency && includes(availableActions, urgentActionType);
  const notUrgentActionTypeSettings = find(referralActionTypes, x => x.actionType === notUrgentActionType);
  const showSetNotUrgentButton = permissions.canMarkAsUrgent && referrerUrgency && includes(availableActions, notUrgentActionType);

  const Button = ({
    variant = "outlined",
    label,
    icon,
    formComponent,
    component: FormComponent = formComponent,
    formProps,
  }) => (
    <DefaultButton
      tooltip={label}
      size="medium"
      variant={variant}
      label={displayLabel ? label : null}
      icon={icon}
      onClick={() => dispatch(openDialog({
        children: (
          <FormComponent
            onSucceed={onClose}
            referralId={id}
            orgUnitId={orgUnitId}
            {...formProps}
          />
        ),
      }))}
    />
  );

  return (
    <ActivityLogItem
      date="Today"
      user={user.name}
      textColor={grey[400]}
      classes={{ content: "flex flex-row items-center", indicator: classes.indicator, connector: classes.connector }}
      content={showButtons ? (
        <div className="flex-row-container with-gutter">
          {showRequestForMoreInformationButton && (
            <Button
              variant="contained"
              label={requestMoreInformationLabel}
              icon={<RequestInfoIcon />}
              formComponent={ReferralRequestMoreInfoForm}
              formProps={{
                title: requestMoreInformationLabel,
                warningMessages: getWarningMessages(referralActionTypeSettings, referralDocument?.referralDocumentType),
                orgUnitId,
              }}
            />
          )}
          {showReplyToReferrerButton && (
            <Button
              label="Reply to the Referrer"
              icon="reply"
              formComponent={ReferralCorrespondenceForm}
              formProps={{
                title: "Reply to the Referrer",
                referrerFullName,
                orgUnitId,
              }}
            />
          )}
          {showSendCorrespondLetterButton && (
            <Button
              label={sendGPLetterLabel}
              icon="send"
              formComponent={ReferralSendToCorrespondenceForm}
              formProps={{
                title: sendGPLetterLabel,
              }}
            />
          )}
          {showAddNoteButton && (
            <Button
              label="Add a note"
              icon={<NoteIcon />}
              formComponent={EditReferralNoteForm}
              formProps={{
                title: "Note",
                orgUnitId,
              }}
            />
          )}
          {showAddTaskButton && (
            <Button
              label="Add a task"
              icon={<TaskIcon />}
              formComponent={ReferralTaskForm}
              formProps={{
                title: "Task",
                specialtyId: assignedTo.assignedToSpecialtyId,
                orgUnitId,
              }}
            />
          )}
          {showSetUrgentButton && (
            <Button
              label={urgentActionTypeSettings.formTitle}
              icon={<Flag />}
              formComponent={ReferralUrgentStatusForm}
              formProps={{
                title: urgentActionTypeSettings.formTitle,
                referralId: id,
                setUrgent: true,
                orgUnitId,
                referralActionTypeSettings: urgentActionTypeSettings,
                warningMessages: getWarningMessages(urgentActionTypeSettings, referralDocument?.referralDocumentType),
              }}
            />
          )}
          {showSetNotUrgentButton && (
            <Button
              label={notUrgentActionTypeSettings.formTitle}
              icon={<FlagOff />}
              formComponent={ReferralUrgentStatusForm}
              formProps={{
                title: notUrgentActionTypeSettings.formTitle,
                referralId: id,
                setUrgent: false,
                orgUnitId,
                referralActionTypeSettings: notUrgentActionTypeSettings,
                warningMessages: getWarningMessages(notUrgentActionTypeSettings, referralDocument?.referralDocumentType),
              }}
            />
          )}
        </div>
      ) : null}
    />
  );
};

export default withPermissions("ReferralCorrespondenceAttachmentEditAnyState", "AllowOwnReferralActivityCreateOnly", "ReferralNotesCreate", "ReferralCorrespondenceCreate", "ReferralTasksCreate")(ReferralActivityLogHeader);
