import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button, InputBase, List, ListItem } from "@material-ui/core";
import { orderBy, get, map, startCase, isEmpty } from "lodash";
import clsx from "clsx";
import { Field } from "formik";

import AsteriskIcon from "mdi-react/AsteriskIcon";
import SquareRoundedIcon from "mdi-react/SquareRoundedIcon";
import SquareRoundedOutlineIcon from "mdi-react/SquareRoundedOutlineIcon";
import IconComponent from "components/items/icon-component";
import Form from "components/form";
import { SelectInput, TextInput } from "components/inputs";
import EmptyState from "components/items/empty-state";
import { closeDialog } from "app/store/actions/dialog.actions";

import { editOrgUnitFormFieldConfiguration, addOrgUnitFormFieldConfiguration } from "app/main/orgUnits/actions/orgUnit.actions";
import { getDefaultOrgUnitFormFieldConfig } from "app/auth/store/reducers/user.reducer";

const useStyles = makeStyles(theme => ({
  listContainer: {
    margin: 0,
    overflowY: "auto",
    maxHeight: "61vh",
    overflowX: "hidden",
  },
  list: {
    padding: 0,
    margin: 0,
    width: "100%",
    border: `1px solid ${theme.palette.divider}`,
    "& > *:not(:last-child)": {
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
  },
  listItem: {
    padding: theme.spacing(1),
    "& > *": {
      overflowY: "auto",
    },
  },
  icon: {
    width: 32,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.error.main,
  },
  button: {
    display: "flex",
    "&.required": {
      color: theme.palette.error.main,
    },
    "&.enabled": {
      color: theme.palette.success.main,
    },
  },
  primaryText: {
    color: theme.palette.text.secondary,
    flex: 1,
    "&.enabled": {
      color: theme.palette.text.primary,
      fontWeight: "bold",
    },
  },
}));

const ToggleButton = ({ item, onClick, label, property, ...other }) => {
  const classes = useStyles();
  return (
    <Button
      variant="outlined"
      size="small"
      onClick={() => onClick(item)}
      startIcon={(
        <div className={clsx(classes.button, item[property] && property)}>
          <IconComponent icon={item[property] ? <SquareRoundedIcon /> : <SquareRoundedOutlineIcon />} />
        </div>
      )}
      {...other}
    >
      {label}
    </Button>
  );
};

const nameLabel = "Name";
const formTypeLabel = "Form Type";

const fieldSchema = Yup.object().shape({
  fieldId: Yup.string().trim()
    .nullable(),
  label: Yup.string().trim()
    .nullable(),
  enabled: Yup.bool().default(false).nullable(),
  required: Yup.bool().default(false).nullable(),
  enabledOCR: Yup.bool().default(false).nullable(),
  enabledReadOnly: Yup.bool().default(false).nullable(),
});

const schema = Yup.object().shape({
  form: Yup.object().shape({
    name: Yup.string().max(200, "Too Long!").trim().required(`${nameLabel} is required`)
      .nullable(),
    orgUnitId: Yup.string().required("orgUnitId is required")
      .nullable(),
    formType: Yup.string().required(`${formTypeLabel} is required`).nullable(),
    configurationJson: Yup.string(),
    fields: Yup.array().of(fieldSchema).nullable(),
  }),
});

const OrgUnitFormFieldsConfigurationForm = ({
  formTypeOptions,
  currentFormFieldsConfiguration = null,
  onSucceed,
  orgUnitId,
  enableOCR,
  enableReadOnly,
  editFormFields = false,
  ...other
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const parseJsonToFields = formFieldsConfiguration => (isEmpty(formFieldsConfiguration?.configurationJson) ? null : JSON.parse(formFieldsConfiguration?.configurationJson));
  const initialFields = currentFormFieldsConfiguration?.configurationJson;
  const defaultOrgUnitFormFields = useSelector(getDefaultOrgUnitFormFieldConfig);
  const [displayOCR, setDisplayOCR] = useState(enableOCR);
  const [displayReadOnly, setDisplayReadOnly] = useState(enableReadOnly);

  const handleSubmit = ({ form }, { setErrors, setSubmitting }) => {
    const formFieldConfiguration = {
      ...form,
      configurationJson: JSON.stringify(form.fields),
    };
    const apiCall = formFieldConfiguration.id ? editOrgUnitFormFieldConfiguration : addOrgUnitFormFieldConfiguration;
    dispatch(apiCall(orgUnitId, formFieldConfiguration)).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    }).finally(() => setSubmitting(false));
  };

  const toggleItemProperty = (fieldItems, setFieldValue, item, property) => {
    const updatedFieldItems = map(fieldItems, x => {
      if (x.fieldId === item.fieldId) {
        let extra = {};
        // reset other props if enable is false ??
        if (property === "enabled" && item.required === true) {
          extra = {
            required: false,
          };
        }

        return {
          ...x,
          ...extra,
          [property]: !x[property],
        };
      }
      return x;
    });
    setFieldValue("form.fields", updatedFieldItems);
  };

  const toggleFieldEnabledStatus = (fieldItems, setFieldValue, item) => toggleItemProperty(fieldItems, setFieldValue, item, "enabled");

  const toggleFiledRequiredStatus = (fieldItems, setFieldValue, item) => toggleItemProperty(fieldItems, setFieldValue, item, "required");

  const toggleFiledOCRStatus = (fieldItems, setFieldValue, item) => toggleItemProperty(fieldItems, setFieldValue, item, "enabledOCR");

  const toggleFileReadOnlyStatus = (fieldItems, setFieldValue, item) => toggleItemProperty(fieldItems, setFieldValue, item, "enabledReadOnly");

  const onFieldLabelChange = (fieldItems, setFieldValue, fieldId, label) => {
    const updatedFieldItems = map(fieldItems, x => {
      if (x.fieldId === fieldId) {
        return {
          ...x,
          label,
        };
      }
      return x;
    });
    setFieldValue("form.fields", updatedFieldItems);
  };

  let { form } = schema.cast();
  if (currentFormFieldsConfiguration) {
    form = currentFormFieldsConfiguration;
    form.fields = initialFields;
  } else {
    form.orgUnitId = orgUnitId;
  }

  const ContentHeader = () => (
    <div className="py-8">
      <Field
        name="form.formType"
        component={SelectInput}
        label={formTypeLabel}
        options={formTypeOptions}
        disabled={editFormFields}
        required
        onChange={(value, change) => {
          const formConfig = get(defaultOrgUnitFormFields, [value]);
          const fieldSettings = parseJsonToFields(formConfig);
          change("form.fields", fieldSettings);
          setDisplayOCR(formConfig?.enableOCR);
          setDisplayReadOnly(formConfig?.enableReadOnly);
        }}
      />
      <Field
        name="form.name"
        component={TextInput}
        label={nameLabel}
        required
      />
    </div>
  );

  const ContentFields = ({ setFieldValue, values }) => {
    if (isEmpty(values.form.fields)) {
      return <EmptyState title="No Field Configurations" />;
    }

    return (
      <div className={classes.listContainer}>
        <List className={classes.list}>
          {map(orderBy(values.form.fields, "fieldName"), x => (
            <ListItem button key={x.fieldId} className={classes.listItem} disableRipple>
              <div className="flex-1 flex items-center">
                <div className={classes.icon}>
                  {x.required && <IconComponent icon={<AsteriskIcon />} size={12} />}
                </div>
                <InputBase
                  classes={{ root: clsx(classes.primaryText, x.enabled && "enabled") }}
                  defaultValue={x.label}
                  onChange={event => onFieldLabelChange(values.form.fields, setFieldValue, x.fieldId, event.target.value)}
                />
                <Typography color="textSecondary" variant="caption">{`${startCase(x.fieldName)}`}</Typography>

              </div>
              <div className="flex-row-container with-gutter ml-16">
                <ToggleButton
                  item={x}
                  property="enabled"
                  label="Enabled"
                  disabled={x.enableRequired}
                  onClick={() => toggleFieldEnabledStatus(values.form.fields, setFieldValue, x)}
                />
                {displayReadOnly && (
                <ToggleButton
                  onClick={() => toggleFileReadOnlyStatus(values.form.fields, setFieldValue, x)}
                  item={x}
                  property="enabledReadOnly"
                  label="ReadOnly"
                  disabled={!x.enabled || !x.editReadOnly}
                />
                )}
                <ToggleButton
                  onClick={() => toggleFiledRequiredStatus(values.form.fields, setFieldValue, x)}
                  item={x}
                  property="required"
                  label="Mandatory"
                  disabled={!x.enabled || !x.editRequired}
                />
                {displayOCR && (
                <ToggleButton
                  onClick={() => toggleFiledOCRStatus(values.form.fields, setFieldValue, x)}
                  item={x}
                  property="enabledOCR"
                  label="OCR"
                  disabled={!x.enabled || !x.editOCR}
                />
                )}
              </div>
            </ListItem>
          ))}
        </List>
      </div>

    );
  };

  return (
    <>
      <Form
        onSubmit={handleSubmit}
        contentProps={other}
        onCancel={() => dispatch(closeDialog())}
        initialValues={{ form }}
        validationSchema={schema}
        content={({ setFieldValue, values }) => (
          <>
            <ContentHeader />
            <ContentFields setFieldValue={setFieldValue} values={values} />
          </>
        )}
      />
    </>
  );
};

export default OrgUnitFormFieldsConfigurationForm;
