import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import HelpRhombusOutlineIcon from "mdi-react/HelpRhombusOutlineIcon";
import { isEmpty } from "lodash";
import FileWordBoxIcon from "mdi-react/FileWordBoxIcon";
import clsx from "clsx";
import TriangleOutlineIcon from "mdi-react/TriangleOutlineIcon";

import DefaultPanelLayout from "components/items/default-panel-layout";
import { ApplicationItemWithWidth, ApplicationItemTextArea } from "app/main/applications/components/application-item";
import ApplicationPanelContent from "app/main/applications/components/application-panel-content";
import DefaultButton from "components/items/default-button";
import formatDate from "helpers/format-date";
import DecisionForm from "app/main/applications/components/decision-form";
import AcceptApplicationForm from "app/main/applications/components/accept-application-form";
import downloadFromApi from "utils/download-from-api";
import ApplicationNoteContent from "app/main/applications/components/application-note-content";
import ConfirmationDialog from "components/items/confirmation-dialog";
import { onRevokeProvisionalApproval, onRemoveDecisionFromAgenda } from "app/main/applications/actions/applications.actions";
import { NoneRecordedState } from "components/items/empty-state";
import ProvisionalApprovalForm from "app/main/applications/components/provisional-approval-form";
import { NoteIcon } from "helpers/icon-finder";
import { getSystemConfigByName } from "app/auth/store/reducers/system-configuration";
import ApplicationAssignTo from "app/main/applications/components/application-assign-to";
import useOpenConfigForm from "hooks/use-open-config-form";

const DecisionPanel = ({
  id: applicationId,
  closeDialog,
  openDialog,
  status,
  canView,
  canEdit,
  hasProvisionalApproval,
  provisionalApprovalDateTimeUtc,
  canDownload,
  statusFlags,
  provisionalApprovalDetails,
  canViewInternalNotes,
  agendaCategory,
  endorsedDateTimeUtc,
  canMarkRatifiedDecision,
  orgUnitId,
  assignment,
  medicationSponsorCostContribution,
  ...other
}) => {
  const dispatch = useDispatch();
  const [generatingLetter, setGeneratingLetter] = useState(false);
  const patientHistoryLabel = useSelector(state => getSystemConfigByName(state, "patientHistoryLabel"));

  const { decision } = other;

  const { canAccept, isDecided, canProvisionallyApprove } = statusFlags;
  const decisionIsWithDrawn = status === "Withdrawn";
  const decidedView = isDecided || decision?.status === "MoreInformationRequired";

  const recordDecision = title => {
    openDialog({
      maxWidth: "md",
      children: <DecisionForm title={title} id={applicationId} orgUnitId={orgUnitId} canMarkRatifiedDecision={canMarkRatifiedDecision} onSucceed={() => closeDialog()} canViewInternalNotes={canViewInternalNotes} {...other} />,
    });
  };

  const { openConfigForm, loadingConfigurations } = useOpenConfigForm(orgUnitId, "CreateEditDecision", recordDecision);

  if (decisionIsWithDrawn) return null;

  const acceptApplication = () => {
    openDialog({
      children: <AcceptApplicationForm onSucceed={() => closeDialog()} title="Add to Agenda" id={applicationId} orgUnitId={orgUnitId} patientHistoryLabel={patientHistoryLabel} {...other} />,
    });
  };

  const provisionalApproval = () => {
    openDialog({
      children: <ProvisionalApprovalForm orgUnitId={orgUnitId} onSucceed={() => closeDialog()} title="Provisional Approval" id={applicationId} {...other} />,
    });
  };

  const revokeProvisionalApproval = () => {
    openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <ConfirmationDialog
          onCancel={() => closeDialog()}
          onConfirm={() => {
            dispatch(onRevokeProvisionalApproval(applicationId, orgUnitId));
            closeDialog();
          }}
          title={<div className="text-center">Revoke provisional approval?</div>}
        />
      ),
    });
  };

  const removeFromAgenda = () => {
    openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <ConfirmationDialog
          onCancel={() => closeDialog()}
          onConfirm={() => {
            dispatch(onRemoveDecisionFromAgenda(applicationId, decision.id, orgUnitId));
            closeDialog();
          }}
          title={<div className="text-center">Remove from agenda?</div>}
        />
      ),
    });
  };

  const handleWordDocument = () => {
    setGeneratingLetter(true);
    downloadFromApi(`api/applications/${orgUnitId}/${applicationId}/createWordDocument`).then(() => {
      setGeneratingLetter(false);
    });
  };

  const ProvisionalApproveButton = ({ showIcon }) => (
    <div>
      <DefaultButton label="Provisionally Approve" icon={showIcon ? "check" : null} variant="outlined" size="large" onClick={() => provisionalApproval()} />
    </div>
  );

  const RevokeButton = ({ showIcon }) => (
    <div>
      <DefaultButton label="Revoke Provisional Approval" icon={showIcon ? "clear" : null} variant="outlined" size="large" onClick={() => revokeProvisionalApproval()} />
    </div>
  );

  return (
    <DefaultPanelLayout
      title="Decision"
      icon={<HelpRhombusOutlineIcon />}
      accessDenied={!canView}
      isLoading={loadingConfigurations}
      headerRightContent={!isEmpty(decision) && canEdit && <div className="mr-16 my-8"><ApplicationAssignTo orgUnitId={orgUnitId} applicationId={applicationId} assignment={assignment} /></div>}
      headerActions={decidedView
        && [
          canDownload && {
            label: "Generate Decision Letter",
            icon: <FileWordBoxIcon />,
            loading: generatingLetter,
            onClick: () => handleWordDocument(),
          },
          canEdit && {
            label: "Edit",
            icon: "edit",
            onClick: () => openConfigForm("Edit Decision Details"),
            loading: loadingConfigurations,
          },
        ]}
    >
      <>
        {/* original state */}
        {/* TODO - when user has permission to view, but not edit, a blank panel renders here */}
        {canView && !canEdit && isEmpty(decision)
          && <NoneRecordedState />}
        {canEdit && isEmpty(decision)
        && (
          <ApplicationPanelContent>
            {canAccept
            && (
            <div className="flex justify-center">
              <DefaultButton label="Add to agenda" icon="check" size="large" className="mr-8" onClick={() => acceptApplication()} />
            </div>
            )}
            {canProvisionallyApprove
            && (
            <div className="flex justify-center">
              {hasProvisionalApproval ? <RevokeButton showIcon /> : <ProvisionalApproveButton showIcon />}
            </div>
            )}
          </ApplicationPanelContent>
        )}
        {/* under review */}
        {canView && !isEmpty(decision) && (
          <ApplicationPanelContent>
            <>
              <ApplicationItemWithWidth icon="date_range" label="Meeting Date" content={formatDate(decision.meetingDate)} />
              <ApplicationItemWithWidth icon="list_alt" label="Agenda Category" content={agendaCategory} />
              <ApplicationItemTextArea icon={<NoteIcon />} label={patientHistoryLabel || "Patient History"} content={decision.patientHistory} className="flex-col with-horizontal-divider mr-8" />
            </>
            {decidedView
              ? (
                <>
                  <ApplicationItemWithWidth
                    icon={<TriangleOutlineIcon />}
                    label="Decision"
                    content={<Typography className="font-bold">{decision.decisionStatus}</Typography>}
                  />
                  <ApplicationItemWithWidth icon="date_range" label="Decided on" content={formatDate(decision.decisionDate)} />
                  {Object.prototype.hasOwnProperty.call(decision, "expiryDate") && <ApplicationItemWithWidth label="Expiry Date" icon="date_range" content={decision.expiryDate && formatDate(decision.expiryDate)} />}
                </>
              ) : canEdit
              && (
                <div className="flex flex-col flex-auto justify-center">
                  <div className="flex-row-container with-gutter justify-center">
                    <DefaultButton label="Record Decision" size="large" onClick={() => openConfigForm("Record Decision")} className="my-4" />
                    <DefaultButton label="Remove From Agenda" size="large" onClick={() => removeFromAgenda()} className="my-4" />
                  </div>
                  <div className={clsx(canProvisionallyApprove && "mt-8", "flex justify-center")}>
                    {canProvisionallyApprove && hasProvisionalApproval ? <RevokeButton /> : <ProvisionalApproveButton />}
                  </div>
                </div>
              )}
            {decidedView
              ? (
                <>
                  {Object.prototype.hasOwnProperty.call(decision, "totalCost") && <ApplicationItemWithWidth label="Total cost" content={decision.totalCost && `$${decision.totalCost}`} />}
                  {Object.prototype.hasOwnProperty.call(decision, "approvalDuration") && <ApplicationItemWithWidth label="Approval Duration" content={decision.approvalDuration} />}

                  {Object.prototype.hasOwnProperty.call(decision, "isRatified") && <ApplicationItemWithWidth label="Decision ratified" content={decision.isRatified ? "Yes" : "No"} />}
                  {Object.prototype.hasOwnProperty.call(decision, "urgentTreatmentApproval") && <ApplicationItemWithWidth label="Urgent treatment approval" content={decision.urgentTreatmentApproval ? "Yes" : "No"} />}
                  {Object.prototype.hasOwnProperty.call(decision, "compassionateAccess") && <ApplicationItemWithWidth label="Compassionate Access" content={decision.compassionateAccess ? "Yes" : "No"} />}
                  {Object.prototype.hasOwnProperty.call(decision, "medicationSponsorCostContribution") && <ApplicationItemWithWidth label="Medication Sponsor Cost Contribution" content={decision.medicationSponsorCostContribution && `$${decision.medicationSponsorCostContribution?.toFixed(2)}`} />}
                  {Object.prototype.hasOwnProperty.call(decision, "outOfSessionAssessment") && <ApplicationItemWithWidth label="Out of session" content={decision.outOfSessionAssessment ? "Yes" : "No"} />}
                  {Object.prototype.hasOwnProperty.call(decision, "agendaItemNumber") && <ApplicationItemWithWidth label="Agenda item" content={decision.agendaItemNumber} />}
                  {Object.prototype.hasOwnProperty.call(decision, "reportingYear") && <ApplicationItemWithWidth label="Reporting year (FY)" content={decision.reportingYear && `${decision.reportingYear} - ${Number(decision.reportingYear) + 1}`} />}
                  {Object.prototype.hasOwnProperty.call(decision, "endOfFinancialYear") && <ApplicationItemWithWidth label="End Of Financial Year" content={decision.endOfFinancialYear} />}
                  <div className="items-container md with-horizontal-divider">
                    {Object.prototype.hasOwnProperty.call(decision, "outcomeMeasures") && <ApplicationItemTextArea label="Outcome measures" content={decision.outcomeMeasures} />}
                    {Object.prototype.hasOwnProperty.call(decision, "restrictionDetails") && <ApplicationItemTextArea label="Restriction details" content={decision.restrictionDetails && <ApplicationNoteContent>{decision.restrictionDetails}</ApplicationNoteContent>} />}
                    {Object.prototype.hasOwnProperty.call(decision, "notes") && <ApplicationItemTextArea label="Internal Notes" content={decision.notes && <ApplicationNoteContent>{decision.notes}</ApplicationNoteContent>} />}
                    {Object.prototype.hasOwnProperty.call(decision, "commentsForDecisionLetter") && <ApplicationItemTextArea label="Comments for decision letter" content={decision.commentsForDecisionLetter && <ApplicationNoteContent>{decision.commentsForDecisionLetter}</ApplicationNoteContent>} />}
                    {Object.prototype.hasOwnProperty.call(decision, "decisionRationale") && <ApplicationItemTextArea label="Decision Rationale" content={decision.decisionRationale && <ApplicationNoteContent>{decision.decisionRationale}</ApplicationNoteContent>} />}
                  </div>
                </>
              ) : null}
          </ApplicationPanelContent>
        )}
        {hasProvisionalApproval
          && (
            <div className="items-container with-horizontal-divider">
              <ApplicationItemWithWidth icon="date_range" label="Provisionally Approved" content={formatDate(provisionalApprovalDateTimeUtc)} />
              <ApplicationItemWithWidth
                icon={NoteIcon}
                label="Provisionally Approved Details"
                content={provisionalApprovalDetails && <ApplicationNoteContent>{provisionalApprovalDetails}</ApplicationNoteContent>}
              />
            </div>
          )}
      </>
    </DefaultPanelLayout>
  );
};

export default DecisionPanel;
