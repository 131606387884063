import React, { useEffect } from "react";
import DefaultButton from "components/items/default-button";
import withPermissions from "permissions/withPermissions";
import useAddPatientReferral from "app/main/patientReferralWorklist/hooks/useAddPatientReferral";
import ActiveReferralPanel from "./active-referral-panel";

const ActiveReferralListPanel = ({
  hasPermissionReferralsCreate,
  hasPermissionReferralsDraft,
  initialCreate,
  patientId,
  orgUnitId,
  fetchAllPatientReferrals,
}) => {
  const onCreatedPatientReferral = () => fetchAllPatientReferrals(patientId);

  const { openAddReferralForm, loadingConfigurations } = useAddPatientReferral({ patientId, orgUnitId, onSucceed: onCreatedPatientReferral, allowSaveAsDraft: hasPermissionReferralsDraft });

  useEffect(() => {
    if (initialCreate) {
      openAddReferralForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCreate]);

  return (
    <ActiveReferralPanel
      patientId={patientId}
      orgUnitId={orgUnitId}
      headerRightContent={hasPermissionReferralsCreate && (
      <DefaultButton
        fab
        label="Add Referral"
        onClick={openAddReferralForm}
        loading={loadingConfigurations}
      />
      )}
    />
  );
};

export default withPermissions("ReferralsCreate", "ReferralsDraft")(ActiveReferralListPanel);
