import React from "react";
import PropTypes from "prop-types";
import { startCase } from "lodash";
import DefaultItem from "components/items/default-item";

import getAppointmentStatus from "app/main/patients/helpers/get-appointment-status";

const statusBarBackgroundColor = status => {
  return getAppointmentStatus(status).color;
};

const AppointmentStatus = ({ status }) => (
  <DefaultItem
    icon="fiber_manual_record"
    iconColor={statusBarBackgroundColor(status)}
    content={startCase(status)}
  />
);

AppointmentStatus.propTypes = {
  status: PropTypes.string,
};

export default AppointmentStatus;
