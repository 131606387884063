import React, { useState, useEffect } from "react";
import { Icon, Typography, Divider, Link } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { find, toUpper, isString, isEmpty, includes } from "lodash";
import { makeStyles } from "@material-ui/core/styles";

import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { showMessage } from "app/store/actions/message.actions";
import { fetchReferral } from "app/main/referrals/actions/referrals.actions";
import DefaultPanelLayout from "components/items/default-panel-layout";
import formatDate from "helpers/format-date";
import formatDaysFromNow from "helpers/format-days-from-now";
import UrgentStatus from "app/main/referrals/components/urgent-status";
import withPermissions from "permissions/withPermissions";
import DefaultItem, { ContextItem } from "components/items/default-item";
import ReferralCategoryLabel from "app/main/referrals/components/referral-category-label";
import DefaultButton from "components/items/default-button";
import ReferralAccessLogDialog from "app/main/referrals/components/referral-access-log-dialog";
import ReferralPresentingCompliantForm from "app/main/referrals/components/referral-presenting-compliant-form";
import { ReferralOrgIcon } from "helpers/icon-finder";
import PanelContent from "components/items/panel-content";
import { getAllCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";
import useReferralFieldSettings from "app/main/referrals/hooks/useReferralFieldSettings";
import useTriageFieldSettings from "app/main/referrals/hooks/useTriageFieldSettings";
import TimetableIcon from "mdi-react/TimetableIcon";
import { getAllowEditPresentingComplaintsStates, getReferralSummaryLayoutSetting } from "app/auth/store/reducers/system-configuration";
import { getCurrentOrgUnitFormFieldConfigByIdField } from "app/main/orgUnits/reducers/orgUnit.reducers";

const useStyles = makeStyles(theme => ({
  container: {
    "& > *:not(:last-child)": {
      marginBottom: theme.spacing(1),
    },
  },
  warningColor: {
    color: theme.palette.warning.main,
  },
}));

const Item = ({ icon, itemLabel, label, isEmptyContent, content, ...other }) => {
  const renterItemContent = () => {
    if (isEmptyContent || !content) return <Typography>Not Recorded</Typography>;
    if (isString(content)) return <Typography>{content}</Typography>;
    return content;
  };

  return (
    <DefaultItem
      icon={icon}
      label={itemLabel}
      content={(
        <div>
          {label && <Typography variant="subtitle2">{toUpper(label)}</Typography>}
          {renterItemContent()}
        </div>
      )}
      {...other}
    />
  );
};

const ReferralWorkListPanel = ({
  referral,
  orgUnitId,
  hasPermissionReferralAccessLog,
}) => {
  const dispatch = useDispatch();
  const [initialLoading, setInitialLoading] = useState(true);
  const classes = useStyles();

  const {
    triageCategory,
    isUrgent,
    referrerUrgency,
    // assigned to required params
    referredToServiceDescription,
    referrerFullName,
    referrerPhone,
    referrerOrganisationName,
    referralDateUtc,
    referralPeriod,
    referrerProviderNumber,
    interpreterRequired,
    preferredLanguage,
    id,
    assignedTo,
    referralStatusUpdated,
    referralStatusUpdatedBy,
    referralStatusUpdatedByOtherUser,
    referringOrgUnitName,
    referringOrgUnitId,
    permissions,
  } = referral;

  useEffect(() => {
    if (referralStatusUpdatedByOtherUser) {
      dispatch(showMessage({
        title: `Referral Updated${referralStatusUpdatedBy ? " By Another User" : ""}`,
        variant: "warning",
        autoHideDuration: null,
        message: `This referral has been updated by ${referralStatusUpdatedBy || "a system process"}, refresh the referral details to see new referral information.`,
      }));
    }
  }, [referralStatusUpdatedByOtherUser, referralStatusUpdatedBy, dispatch]);

  const showAccessLog = () => dispatch(openDialog({ children: <ReferralAccessLogDialog referralId={id} orgUnitId={referral.orgUnitId} /> }));
  const codeSetValues = useSelector(getAllCodeSetValues);
  const allowEditPresentingComplaintsStates = useSelector(getAllowEditPresentingComplaintsStates);
  const referralSummaryLayoutSetting = useSelector(getReferralSummaryLayoutSetting);
  const { fieldSettings, fieldLabels } = useReferralFieldSettings(referral.formFieldConfigurationId);
  const { fieldSettings: triageFieldSettings, fieldLabels: triageFieldLabels, loadedFieldSettings: loadedTriageSettings } = useTriageFieldSettings(referral.orgUnitId);
  const presentingComplaintSettings = useSelector(state => getCurrentOrgUnitFormFieldConfigByIdField(state, referral.orgUnitId, referral.formFieldConfigurationId, "PresentingComplaintCodes"));

  const onClose = () => dispatch(closeDialog());

  const showEditPresentingCompliantDialog = () => dispatch(openDialog({
    children:
      (
        <ReferralPresentingCompliantForm
          referralData={referral}
          orgUnitId={referral.orgUnitId}
          onSucceed={onClose}
          fieldSettings={fieldSettings}
          fieldLabels={fieldLabels}
          title="Presenting Complaint"
        />
      ),
  }));

  const refreshReferral = () => dispatch(fetchReferral(orgUnitId, id));

  const displayCodeSetValue = codeSetValueId => find(codeSetValues, x => x.id === codeSetValueId)?.description;

  const showOrgItem = true; // TODO improve this check
  const referringLocationDisplay = !isEmpty(referral.referralDocument.referringLocationCodeId) ? `${displayCodeSetValue(referral.referralDocument.referringLocationCodeId)} ` : "";
  const organisationNameDisplay = referrerOrganisationName && !isEmpty(referrerOrganisationName) ? referrerOrganisationName : "";

  useEffect(() => {
    if (initialLoading && loadedTriageSettings) {
      setInitialLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadedTriageSettings]);

  let StatusComponent = () => null;
  let PresentingComplaintComponent = () => null;

  if (triageCategory) {
    StatusComponent = () => <ReferralCategoryLabel triageCategory={triageCategory} isUrgent={isUrgent} orgUnitId={referral.orgUnitId} />;
  }

  let fromString = "";

  if (fieldSettings?.referrerFullName && referrerFullName) {
    fromString += referrerFullName;
  }

  if (fieldSettings?.referrerProviderNumber && referrerProviderNumber) {
    fromString += ` (${referrerProviderNumber})`;
  }

  const shouldRenderUrgentStatus = () => (
    isUrgent === true || (isEmpty(triageCategory) && referrerUrgency === true)
  );

  PresentingComplaintComponent = () => {
    const presentingComplaintCodesLabel = presentingComplaintSettings?.label ?? fieldLabels.presentingComplaintCodes;

    if (permissions.canUpdatePresentingComplaintFromPanel && includes(allowEditPresentingComplaintsStates, referral.referralStatus)) {
      return (
        <Item
          label={presentingComplaintCodesLabel}
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
          content={<Link href="#" color="secondary" underline="always" variant="body2" onClick={showEditPresentingCompliantDialog}>{referral.presentingComplaintSummary ? referral.presentingComplaintSummary : "Not Recorded"}</Link>}
        />
      );
    }

    return (
      <Item
        label={presentingComplaintCodesLabel}
        content={referral.presentingComplaintSummary ? referral.presentingComplaintSummary?.trim() : null}
      />
    );
  };

  return (
    <DefaultPanelLayout
      title="Referral Summary"
      icon="perm_contact_calendar"
      status={<StatusComponent />}
      headerContent={(
        <ContextItem
          label="For"
          content={assignedTo?.assignedToDisplayName ?? referredToServiceDescription}
          renderIfEmpty
          labelClassName="w-40"
        />
      )}
      headerRightContent={hasPermissionReferralAccessLog && (
        <div className="flex-row-container with-gutter">
          {referralStatusUpdated && <DefaultButton fab label="Refresh Details" onClick={refreshReferral} icon="replay" />}
          <DefaultButton fab label="Referral Access Log" onClick={showAccessLog} />
        </div>
      )}
      isLoading={initialLoading}
    >
      <PanelContent itemsPerRow={3} breakpoints="xs">
        <div className={classes.container}>
          <Item itemLabel="From" content={fromString} />
          {fieldSettings?.referrerPhone && <Item icon="call" content={referrerPhone} />}
          {showOrgItem && (
            <Item
              icon={<ReferralOrgIcon />}
              content={(!isEmpty(referringOrgUnitId) || !isEmpty(referringLocationDisplay) || !isEmpty(organisationNameDisplay)) ? (
                <>
                  {!isEmpty(referringOrgUnitId) && <Item content={referringOrgUnitName} />}

                  {(!isEmpty(referringLocationDisplay) || !isEmpty(organisationNameDisplay))
                    && (<Item content={`${referringLocationDisplay}${organisationNameDisplay}`} />
                    )}
                </>
              ) : ""}
            />
          )}
        </div>
        <div className={classes.container}>
          {shouldRenderUrgentStatus() && (
            <DefaultItem
              className="mb-8"
              icon={<UrgentStatus iconOnly />}
              content={<UrgentStatus labelOnly label={fieldLabels?.referrerUrgency ?? "Urgent"} labelClassName="font-bold" />}
            />
          )}
          {fieldSettings?.referralDate && (
            <Item
              icon="calendar_today"
              label="Referred On"
              content={!referralDateUtc ? <div className="flex"><Typography>Not Recorded</Typography><Icon className={classes.warningColor}>warning</Icon></div> : `${formatDate(referralDateUtc)} ${formatDaysFromNow(referralDateUtc)}`}
            />
          )}
          <Item icon="list_alt" label="Referral Number" content={referral.referralNumber} />
          {(fieldSettings?.referralPeriod || referralPeriod)
            && <Item icon="access_time" label="Referral Period" content={referralPeriod} />}
        </div>
        <div className={classes.container}>
          {(fieldSettings?.typeOfReferral || referral.referralDocument.typeOfReferralCodeId)
           && <Item icon={<TimetableIcon />} label={fieldLabels.typeOfReferral} content={displayCodeSetValue(referral.referralDocument.typeOfReferralCodeId)} />}
          {fieldSettings?.interpreterRequired && interpreterRequired && <Item icon="language" content={`Interpreter Required${preferredLanguage ? ` (${preferredLanguage})` : ""}`} />}
          {(fieldSettings?.reasonForReferralCode || !isEmpty(referral.referralDocument.reasonForReferralCodeId)) && (<Item icon="label_important" label={fieldLabels.reasonForReferralCode} content={displayCodeSetValue(referral.referralDocument.reasonForReferralCodeId)} />)}
        </div>
      </PanelContent>
      <Divider className="my-16" />
      <PanelContent itemsPerRow={3}>
        {(fieldSettings?.presentingComplaintCodes || !isEmpty(referral.presentingComplaints) || referral.referralDocument?.hasReferralCriteria === true) && <PresentingComplaintComponent />}
        {triageFieldSettings?.episodeHealthCondition && <Item label={triageFieldLabels.episodeHealthCondition} content={referral.episodeHealthConditionSummary} />}
        {referralSummaryLayoutSetting !== "Layout2" && (fieldSettings?.referrerAdvisedPriority || !isEmpty(referral.referralDocument?.referrerAdvisedPriorityCodeId) || referral.referralDocument?.hasReferralCriteria === true)
          && <Item label={triageFieldLabels.referrerAdvisedPriority} content={displayCodeSetValue(referral.referralDocument?.referrerAdvisedPriorityCodeId)} />}
        {triageFieldSettings?.clinic && <Item label={triageFieldLabels.clinic} content={referral.clinicDescription} />}
      </PanelContent>
    </DefaultPanelLayout>
  );
};

export default withPermissions("ReferralAccessLog")(ReferralWorkListPanel);
