import React, { useCallback, useState, useEffect } from "react";
import { debounce, map } from "lodash";
import { Chip } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { fetchUserListForSelector } from "app/main/users/actions/users.actions";

import { AutoComplete } from "components/inputs";
import { AssignedUserIcon } from "helpers/icon-finder";
import isSearchStringValid from "helpers/is-search-string-valid";

const formatUserOptions = (options, showFullName) => map(options, option => ({
  value: option.userId,
  label: showFullName ? option.displayNameFull : option.name,
  isActive: option.isActive,
}));

const UserSelector = ({
  showIcon = true,
  defaultOptions = [],
  loadOptions = true,
  orgUnitId = null,
  includeInactive = false,
  userHasPermission = null,
  filteredEndorsedByType = null,
  excludeUserIds = null,
  showFullName = false,
  ...other
}) => {
  const dispatch = useDispatch();
  const selectedUser = other.field.value;
  const [options, setOptions] = useState(defaultOptions || []);
  const [loading, setLoading] = useState(false);

  const SearchUsers = name => {
    if (loading) return;

    setLoading(true);
    dispatch(fetchUserListForSelector({ orgUnitId, name, includeInactive, userHasPermission, filteredEndorsedByType, excludeUserIds }))
      .then(res => setOptions(formatUserOptions(res.payload.users, showFullName)))
      .finally(() => {
        setLoading(false);
      });
  };

  const onInputChange = useCallback(debounce((event, val, action) => {
    if (action !== "input" || !isSearchStringValid(val, 2)) {
      return;
    }

    SearchUsers(val);
  }, 300));

  useEffect(() => {
    if (loadOptions) {
      SearchUsers();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOptions]);

  // ensure currently selected user remains in the list of options
  const optionsIncludingSelected = selectedUser == null || options.some(x => x.value === selectedUser?.value)
    ? options
    : [...options, selectedUser];

  return (
    <AutoComplete
      loading={loading}
      icon={showIcon && <AssignedUserIcon />}
      options={optionsIncludingSelected}
      onInputChange={onInputChange}
      getOptionSelected={(o, val) => o.value === val.value}
      filterOptions={allOptions => allOptions}
      renderOption={option => (
        <div className="flex flex-wrap justify-between flex-auto">
          {option.label}
          {option.isActive === false && <Chip size="small" label="Inactive" />}
        </div>
      )}
      {...other}
    />
  );
};

export default UserSelector;
