/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useMemo } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Field } from "formik";
import { isEmpty, every, isString, find, isObject, includes, isEqual, size, map, get, head } from "lodash";
import moment from "moment";
import { Icon } from "@material-ui/core";

import LoadingState from "components/items/loading-state";
import EmptyState from "components/items/empty-state";
import Form from "components/form";
import { OCRIcon } from "helpers/icon-finder";
import { showMessage } from "app/store/actions/message.actions";
import { getDefaultRoute } from "utils/get-environment-variables";

import { getAllCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";
import { getReferralFormLayoutSetting, getPrimaryPatientIdentifier, getAllowReassignSpecialtyStates,
  getReferralWorklistSettings, getEnableOCR, getEnableStreamlinedAddReferral, getSpecialtyLabel, getSpecialtyFormProfileLabel, getReferralSourceDocumentUploadDisabledMarkdown, getShowReferralSourceDocumentUploadDisableIcon } from "app/auth/store/reducers/system-configuration";

import { createManualReferral, editManualReferral, clearReferralSourceDocumentFiles, getReferralSourceDocumentFilePreviewData, processOCR } from "app/main/referrals/actions/referrals.actions";
import { getDefaultReferralSourceDocumentFile, isCurrentReferralSourceDocumentFileLoading } from "app/main/referrals/reducers/referrals.reducers";

import { fetchPatientPresentingComplaints } from "app/main/referrals/actions/patientReferrals.actions";
import { getPatientPresentingComplaints } from "app/main/referrals/reducers/patientReferrals.reducers";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";

import DefaultButton from "components/items/default-button";
import IconButton from "components/items/icon-button";
import Section from "components/items/section";
import { setCurrentPatientOCR, resetCurrentPatientOCR, resetCurrentAttachment } from "app/main/patients/actions/patientOCR.selector.actions";
import { getCurrentPatientOCR } from "app/main/patients/reducers/patientOCR.selector.reducers";
import ReferralWarningMessage from "app/main/referrals/components/referral-warning-message-alert";
import { resetPatientSearch } from "app/main/patients/actions/patientsSearch.actions";
import { fetchOCRSpecialtyProfiles } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { getOCRSpecialtyProfiles } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";

import { openDialog } from "app/store/actions/dialog.actions";
import CreateReferralForm from "app/main/referrals/components/create-referral-form";
import BackReferralConfirmDialog from "app/main/referrals/components/add-referral-form/back-referral-confirm-dialog";

import { clearUploadAttachment } from "app/main/referralDocumentAttachmentUploads/actions/referralDocumentAttachmentUploads.actions";
import ReferralDocumentAttachmentUpload from "app/main/referralDocumentAttachmentUploads/components/referral-document-attachment-upload";

import { normalizePatient } from "app/main/patients/reducers/patients.reducers";
import ReferralSourceDocumentPreview from "./referralSourceDocumentPreview";
import ReferralSourceDocumentDisableUpload from "./referralSourceDocumentDisableUpload";
import ReferralFormComponent from "../referral-form-component";
import PatientSummaryCard from "../patient-summary-card";
import SubmitButton from "../submit-button";
import ReferrerInformationSection from "./referrer-information-section";
import ReferralInformationSection from "./referral-information-section";
import BillingInformationSection from "./billing-information-section";

const useStyles = makeStyles(theme => ({
  rootContainer: {
    flexDirection: "column",
    display: "flex",
    minHeight: "100%",
  },
  headerContainer: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
    alignItems: "center",
    marginTop: theme.spacing(1),
    borderBottom: "1px solid #e0e0e0",
  },
  documentContainer: {
    flexGrow: "1",
    overflowY: "auto",
  },
}));

export default ({
  orgUnitId,
  currentReferral,
  schema,
  fieldLabels,
  fieldSettings,
  loadingFieldSettings,
  autoReferralFullName,
  autoReferringOrgUnitId,
  templateReferral,
  currentAssignedToSpecialty,
  setCurrentAssignSpecialty,
  enableDraft,
  disableSourceDocumentUpload,
  renderTemplateComponents,

  onSucceed,
  patient: presetPatient,
  sourceDocument,
  isEdit,
  allowSaveAsDraft,
  warningMessages,
  onCancel,
  enableProcessOCR,
  isFormStreamlinedAddReferral = false,
  isFromNewPatient = false,
  enableBack = false,
  presentingComplaintMaxLength,
  ...other
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const codeSetValues = useSelector(getAllCodeSetValues);
  const codeSetValueProperties = codeSetValueId => find(codeSetValues, x => x.id === codeSetValueId)?.propertiesJson;

  const referralSourceDocumentFile = useSelector(getDefaultReferralSourceDocumentFile);
  const [previewSourceDocument, setPreviewSourceDocument] = useState(sourceDocument);
  const isReferralSourceDocumentFileLoading = useSelector(isCurrentReferralSourceDocumentFileLoading);

  const [saveAsDraft, setSaveAsDraft] = useState(false);
  const [saveAsProcess, setSaveAsProcess] = useState(false);
  // allow reassignment when
  // 1. is new form
  // 2. when current referral status in allowed states
  const AllowReassignSpecialtyStates = useSelector(getAllowReassignSpecialtyStates);
  const allowSpecialtyReassignment = !isEdit || includes(AllowReassignSpecialtyStates, currentReferral?.referralStatus);
  const primaryPatientIdentifier = useSelector(getPrimaryPatientIdentifier);

  // consider 2 way of patient details
  // 1. from new patient or existing - presetPatient
  // 2. from edit - currentReferral.patient
  const patientDetails = useMemo(() => presetPatient ?? currentReferral?.patient, [presetPatient, currentReferral?.patient]);
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const isEnablePresentingComplaintWarning = !isEmpty(referralWorklistSettings?.patientPresentingComplaintWarningMessage);
  const patientPresentingComplaints = useSelector(state => getPatientPresentingComplaints(state, patientDetails?.patientId));

  const orgUnit = useSelector(getSignedInOrgUnit);
  const enableOCR = useSelector(getEnableOCR);
  const enableStreamlinedAddReferral = useSelector(getEnableStreamlinedAddReferral);
  const referralFormLayoutSetting = useSelector(getReferralFormLayoutSetting);
  const preSetOCRResultJSON = useMemo(() => currentReferral?.referralDocument?.ocrResultJSON);
  const currentPatientOCR = useSelector(getCurrentPatientOCR);
  const [ocrResult, setOCRResult] = useState(currentPatientOCR);
  const [isNewUpload, setIsNewUpload] = useState(isEdit !== true);
  const currentAssignedTo = currentReferral?.assignedTo ? { value: currentReferral?.assignedTo?.assignedToSpecialtyId, label: currentReferral?.assignedTo?.assignedToSpecialtyName } : null;
  const referredToSpecialty = sourceDocument?.referredToSpecialty ?? currentAssignedTo;
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [isReading, setIsReading] = useState(false);
  const [ocrError, setOCRError] = useState(null);
  const [ocrSuccess, setOCRSuccess] = useState(null);
  const ocrSpecialtyProfiles = useSelector(getOCRSpecialtyProfiles);
  const ocrSpecialtyProfilesIds = map(ocrSpecialtyProfiles, x => x.id);
  const isEmptyPatient = isEmpty(patientDetails) || (isEdit && isEmpty(patientDetails?.patientId));
  const multiplePresentingComplaint = presentingComplaintMaxLength > 1;

  const specialtyLabel = useSelector(getSpecialtyLabel);
  const specialtyFormProfileLabel = useSelector(getSpecialtyFormProfileLabel);
  const currentReferralOrgUnitId = currentReferral?.orgUnitId ?? orgUnit?.id;
  const referralSourceDocumentUploadDisabledMarkdown = useSelector(getReferralSourceDocumentUploadDisabledMarkdown);
  const showReferralSourceDocumentUploadDisableIcon = useSelector(getShowReferralSourceDocumentUploadDisableIcon);

  useEffect(() => {
    if (currentReferralOrgUnitId && currentReferral?.referralSourceDocument) {
      // fetch current source document preview data
      dispatch(getReferralSourceDocumentFilePreviewData(currentReferralOrgUnitId, currentReferral.referralSourceDocument.id, currentReferral.referralSourceDocument.fileId));
    }
  }, [currentReferralOrgUnitId, currentReferral?.referralSourceDocument]);

  useEffect(() => {
    if (currentReferralOrgUnitId && patientDetails?.patientId && isEnablePresentingComplaintWarning) {
      dispatch(fetchPatientPresentingComplaints(currentReferralOrgUnitId, patientDetails?.patientId));
    }
  }, [currentReferralOrgUnitId, patientDetails?.patientId]);

  useEffect(() => {
    setPreviewSourceDocument(referralSourceDocumentFile ?? sourceDocument);
    if (!isEmpty(preSetOCRResultJSON)) {
      dispatch(setCurrentPatientOCR(preSetOCRResultJSON));
    }
  }, [referralSourceDocumentFile]);

  useEffect(() => {
    setOCRResult(currentPatientOCR?.ocrData);
  }, [currentPatientOCR]);

  useEffect(() => () => dispatch(clearReferralSourceDocumentFiles()), []);

  useEffect(() => {
    if (currentReferralOrgUnitId && enableProcessOCR) {
      dispatch(fetchOCRSpecialtyProfiles(currentReferralOrgUnitId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableProcessOCR, currentReferralOrgUnitId]);

  const onAddReferral = () => {
    dispatch(openDialog({
      maxWidth: enableStreamlinedAddReferral ? "xl" : "md",
      children: (
        <CreateReferralForm
          orgUnitId={currentReferralOrgUnitId}
          onSucceed={onSucceed}
          referredToSpecialty={currentAssignedToSpecialty}
          isFormStreamlinedAddReferral={isFormStreamlinedAddReferral}
          isFromNewPatient={isFromNewPatient}
          presetPatient={patientDetails}
          confirmedOCR
        />
      ),
    }, enableStreamlinedAddReferral));
  };

  const handleSubmit = ({ referral: data }, { setSubmitting, setErrors }) => {
    let { referralExpiryDate } = data;

    if (referralExpiryDate) {
      referralExpiryDate = isString(data.referralExpiryDate) ? data.referralExpiryDate : moment(data.referralExpiryDate).format("YYYY-MM-DD");
    }

    let referralData = {
      ...data,
      patientId: patientDetails?.patientId,
      patient: patientDetails,
      nok: !every(data.nok, x => (x === null || x === undefined)) ? { ...data.nok, relationshipType: "NextOfKin" } : null,
      referredToServiceDescription: data.referredToSpecialty?.label,
      assignToSpecialty: data.referredToSpecialty?.value,
      referralSourceDocumentFileId: previewSourceDocument?.id,
      referrerTimeZone: moment.tz.guess(),
      referralSourceDocumentFileName: previewSourceDocument?.fileName,
      externalSourceDocumentId: previewSourceDocument?.externalSourceDocumentId,
      assignToSubSpecialty: data.assignToSubSpecialty?.value ?? data.assignToSubSpecialty,
      typeOfReferralCodeId: data.typeOfReferralCodeId?.value ?? data.typeOfReferralCodeId,
      referrerAdvisedPriorityCodeId: data.referrerAdvisedPriorityCodeId?.value ?? data.referrerAdvisedPriorityCodeId,
      referringLocationCodeId: data.referringLocationCodeId?.value ?? data.referringLocationCodeId,
      referringInternalLocationCodeId: data.referringInternalLocationCodeId?.value ?? data.referringInternalLocationCodeId,
      reasonForReferralCodeId: data.reasonForReferralCodeId?.value ?? data.reasonForReferralCodeId,
      claimTypeCodeId: data.claimTypeCodeId?.value ?? data.claimTypeCodeId,
      clinicTypeCodeId: data.clinicTypeCodeId?.value ?? data.clinicTypeCodeId,
      referrerFullName: data.referrerFullName?.label ?? data.referrerFullName,
      designationCodeId: data.designationCodeId?.value ?? data.designationCodeId,
      referralExpiryDate,
      saveAsDraft,
      saveAsProcess,
      externalTriageStatusCodeId: data.externalTriageStatusCodeId?.value ?? data.externalTriageStatusCodeId,
      referralFormProfileId: data.referralFormProfileId?.value ?? data.referralFormProfileId,
    };

    if (!isEmpty(data.presentingComplaintCodes)) {
      const valueArray = multiplePresentingComplaint ? data.presentingComplaintCodes : [].concat(data.presentingComplaintCodes);
      referralData.presentingComplaintCodes = map(valueArray, x => ({
        code: x.value,
        description: x.label,
      }));
    }

    if (presetPatient) {
      referralData = {
        ...referralData,
        patient: {
          ...presetPatient,
          sex: presetPatient.sex?.value,
        },
        primaryPatientIdentifier: presetPatient.primaryPatientIdentifier
                                  ?? find(presetPatient.patientIdentifiers, id => id.patientIdentifierTypeCode === primaryPatientIdentifier)?.identifier,
      };
    }

    referralData = {
      ...referralData,
      patient: !isEmpty(referralData.patient) ? normalizePatient(referralData.patient) : null,
      ocrResultJSON: JSON.stringify(ocrResult),
      referralSourceDocument: null,
      activities: null,
    };

    const apiCall = isEdit ? editManualReferral : createManualReferral;

    dispatch(apiCall(orgUnitId, referralData)).then(response => {
      setSubmitting(false);
      const showNotification = response.payload.referral?.displayCreatedNotification && (isEmpty(currentReferral) || (currentReferral.referralStatus === "Draft" && !saveAsDraft && !saveAsProcess));

      if (response.error !== true) {
        dispatch(resetCurrentPatientOCR());
        dispatch(resetPatientSearch());
        dispatch(resetCurrentAttachment());
        dispatch(clearUploadAttachment());

        const { referral } = response.payload;
        if (orgUnitId !== referral.orgUnitId) {
          history.push({ pathname: getDefaultRoute(), search: "", state: { forceLoad: true, referralId: null } });
          onCancel();
        } else {
          onSucceed(response.payload);
        }
        if (showNotification) {
          const referredToSiteName = referral.organisationUnit?.name || "";
          const assignedToDisplayName = referral.assignedTo?.assignedToDisplayName || "";
          // If both values are truthy: ["Site A", "DisplayName B"] becomes "Site A, DisplayName B"
          // If only one value is truthy: ["Site A", ""] becomes ["Site A"] after filtering, then "Site A" after joining
          // If both are falsy: ["", ""] becomes [] after filtering, then "" (an empty string) after joining
          const sentToLabels = [referredToSiteName, assignedToDisplayName].filter(Boolean).join(", ");

          const message = saveAsDraft
            ? `${referral.referralNumber} Saved as Draft`
            : `${referral.referralNumber} created and sent to ${sentToLabels}`;

          dispatch(showMessage({
            variant: "success",
            message,
          }));
        }
      } else {
        setErrors(response.payload);
      }
    });
  };

  let { referral } = schema ? schema?.cast() : {};

  referral = {
    ...referral,
    nok: patientDetails?.nextOfKin ?? {},
    interpreterRequired: patientDetails?.interpreterRequired ?? null,
    preferredLanguage: patientDetails?.primaryLanguage,
    referralSourceDocument: sourceDocument ?? {},
    referredToSpecialty,
    orgUnitId,
    organisationUnit: referral?.organisationUnit ?? orgUnit,
  };

  if (!isEmpty(currentReferral)) {
    const { organisationUnit, patient, referralDateUtc, referralPeriod,
      reasonNote, presentingComplaintNote, relevantPatientHistoryNote,
      interpreterRequired, preferredLanguage, referrerFullName, referringOrgUnitId, referrerOrganisationName,
      id, referralDocumentId, referrerPracticeProviderGuid, referrerPracticeProviderRole, referrerProviderNumber, referrerPhone, referralAssignedToSubSpecialtyId,
      referralAssignedToSubSpecialtyName, presentingComplaints, referrerUrgency } = currentReferral;

    referral = {
      ...referral,
      referralId: id,
      referralDocumentId,
      referralDateUtc,
      referralPeriod,
      reasonNote,
      presentingComplaintNote,
      relevantPatientHistoryNote,
      interpreterRequired,
      preferredLanguage,
      referrerFullName: referrerFullName ? { value: null, label: referrerFullName } : null,
      referringOrgUnitId,
      referringOrgUnit: currentReferral.referringOrgUnit,
      referrerOrganisationName,
      referrerProviderNumber,
      referrerPracticeProviderGuid,
      referrerPracticeProviderRole,
      referrerPhone,
      patientId: patient?.patientId,
      referralSourceDocument: currentReferral.referralSourceDocument,
      assignToSubSpecialty: referralAssignedToSubSpecialtyId ? { value: referralAssignedToSubSpecialtyId, label: referralAssignedToSubSpecialtyName } : null,
      typeOfReferralCodeId: currentReferral.referralDocument.typeOfReferralCodeId,
      mbsConsent: currentReferral.referralDocument.mbsConsent,
      referrerAdvisedPriorityCodeId: currentReferral.referralDocument.referrerAdvisedPriorityCodeId,
      referringLocationCodeId: currentReferral.referralDocument.referringLocationCodeId,
      referringInternalLocationCodeId: currentReferral.referralDocument.referringInternalLocationCodeId,
      reasonForReferralCodeId: currentReferral.referralDocument.reasonForReferralCodeId,
      clinicTypeCodeId: currentReferral.referralDocument.clinicTypeCodeId,
      claimTypeCodeId: currentReferral.referralDocument.claimTypeCodeId,
      referralExpiryDate: currentReferral.referralDocument.referralExpiryDate,
      designationCodeId: currentReferral.designationCodeId,
      designationDisplayValue: currentReferral.designationDisplayValue,
      assignedToSpecialist: (currentReferral.referralDocument.specialistConsultantUserId || currentReferral.referralDocument.specialistConsultant)
        ? {
          value: currentReferral.referralDocument.specialistConsultantUserId,
          label: currentReferral.referralDocument.specialistConsultant,
        } : null,
      specialistConsultant: currentReferral.referralDocument.specialistConsultant,
      specialistConsultantUserId: currentReferral.referralDocument.specialistConsultantUserId,
      meetsRequirements: currentReferral.referralDocument.meetsRequirements,
      referrerUrgency,
      referralFormProfileId: currentReferral.referralFormProfileId,
      formFieldConfigurationId: currentReferral.formFieldConfigurationId,
      orgUnitId: currentReferral.orgUnitId,
      organisationUnit,
    };

    if (presentingComplaints) {
      if (multiplePresentingComplaint) {
        referral.presentingComplaintCodes = map(presentingComplaints, presentingComplaint => ({
          value: presentingComplaint.code,
          label: presentingComplaint.term,
        }));
      } else {
        const presentingComplaint = head(presentingComplaints);
        referral.presentingComplaintCodes = !isEmpty(presentingComplaint)
          ? {
            value: presentingComplaint?.code,
            label: presentingComplaint?.term,
          } : null;
      }
    }
  }

  if (!isEmpty(templateReferral)) {
    referral = {
      ...referral,
      ...templateReferral,
    };
  }

  if (!isEmpty(templateReferral)) {
    referral = {
      ...referral,
      ...templateReferral,
    };
  }

  const renderExtraActions = (referralStatus, formProps) => (
    <div className="flex-row-container with-gutter ml-16">
      {enableBack
        && (
          <>
            <DefaultButton
              icon={<Icon>arrow_back</Icon>}
              label="Back"
              color="default"
              variant="text"
              onClick={() => {
                const touchedFields = formProps.touched;
                const hasChanged = !isEqual(formProps.initialValues, formProps.values);
                if (touchedFields && hasChanged) {
                  setIsOpenConfirm(true);
                } else {
                  onAddReferral();
                }
              }}
            />
            <BackReferralConfirmDialog open={isOpenConfirm} onConfirm={onAddReferral} onCancel={() => setIsOpenConfirm(false)} />
          </>

        )}
      {enableDraft && (allowSaveAsDraft || referralStatus === "Draft")
        && (
        <SubmitButton
          label="Save as draft"
          onClick={() => setSaveAsDraft(true)}
          {...formProps}
        />
        )}

      {(referralStatus === "Processing")
        && (
        <SubmitButton
          label="Save as Process"
          onClick={() => setSaveAsProcess(true)}
          {...formProps}
        />
        )}
    </div>
  );
  const renderSubmitButton = formProps => (
    <SubmitButton
      label={isEdit ? "Save and Request" : "Add"}
      onClick={() => { setSaveAsDraft(false); setSaveAsProcess(false); }}
      {...formProps}
    />
  );

  const onFileUploaded = uploadedSourceDocumentFile => {
    if (uploadedSourceDocumentFile) {
      setPreviewSourceDocument(uploadedSourceDocumentFile);
    }
  };

  const onFileRemove = () => {
    setPreviewSourceDocument(null);
    setOCRResult(null);
    setIsNewUpload(true);
    setOCRSuccess("");
    setOCRError("");
  };

  const handleReadPDF = selectedSpecialty => {
    const selectedSpecialtyId = selectedSpecialty?.value;
    if (selectedSpecialtyId && previewSourceDocument?.id) {
      setIsReading(true);
      setOCRSuccess("");
      setOCRError("");
      setOCRResult(null);
      dispatch(processOCR(currentReferralOrgUnitId, selectedSpecialtyId, previewSourceDocument?.id)).then(response => {
        setIsReading(false);
        if (response.error) {
          const errorMessage = get(response.payload, "message", null) ?? response.error;
          setOCRError(errorMessage);
        } else {
          setOCRSuccess("OCR request is completed.");
          setOCRResult(response.ocrData);
          setCurrentAssignSpecialty(selectedSpecialty);
          setIsNewUpload(true);
        }
      });
    }
  };

  const handleCancel = () => {
    dispatch(resetCurrentPatientOCR());
    dispatch(resetCurrentAttachment());
    dispatch(resetPatientSearch());
    dispatch(clearUploadAttachment());
    if (onCancel) {
      onCancel();
    }
  };

  const fileData = (previewSourceDocument?.fileData || previewSourceDocument?.base64File || previewSourceDocument?.htmlPreview);
  const hasPreview = !isEmpty(fileData);

  const renderSourceDocumentSection = props => {
    // 1. if a Referral Source Document is uploaded (so this situation has to be Editing a Referral), render the preview component
    // 2. if the disableSourceDocumentUpload set to false, render the preview component
    if ((isEdit && hasPreview) || !disableSourceDocumentUpload) {
      return (
        <ReferralSourceDocumentPreview
          fileData={fileData}
          mimeType={previewSourceDocument?.mimeType}
          pdfProps={{ paged: true, showPreviewControls: true, ...props }}
          hasPreview
          loading={isReferralSourceDocumentFileLoading}
          orgUnitId={currentReferralOrgUnitId}
          specialtyId={currentAssignedToSpecialty?.value}
          label="Drag & drop your pdf source referral here or click on the box to upload"
          onFileUploaded={onFileUploaded}
          ocrError={ocrError}
          ocrSuccess={ocrSuccess}
          ocrProcessing={isReading}
          disabled={isReading}
        />
      );
    }

    // 3. if the disableSourceDocumentUpload set to true, render the static message
    return (
      <ReferralSourceDocumentDisableUpload helperText={referralSourceDocumentUploadDisabledMarkdown} showIcon={showReferralSourceDocumentUploadDisableIcon} />
    );
  };

  return (
    <ReferralFormComponent
      renderPreview={props => (
        <div className={classes.rootContainer} elevation={1}>
          <div className={classes.headerContainer}>
            {enableOCR && enableProcessOCR && size(ocrSpecialtyProfilesIds) > 0 && (!isEmpty(currentAssignedToSpecialty?.value) && includes(ocrSpecialtyProfilesIds, currentAssignedToSpecialty?.value)) && (
              <DefaultButton
                loading={isReading}
                label="OCR"
                onClick={() => handleReadPDF(currentAssignedToSpecialty)}
                className="ml-16"
                icon={<OCRIcon />}
                disabled={!hasPreview || !currentAssignedToSpecialty?.value}
              />
            )}
            {(isEdit || enableProcessOCR) && hasPreview && (
              <IconButton style={{ marginLeft: "4px" }} title="Remove file" icon="delete" disabled={isReading} edge="end" onClick={onFileRemove} className="pr-8" />
            )}
          </div>
          <div className={classes.documentContainer}>
            {renderSourceDocumentSection(props)}
          </div>
        </div>
      )}
    >
      <Form
        onCancel={handleCancel}
        contentProps={{ ...other, submitLabel: isEdit ? "Save" : "Add" }}
        initialValues={{ referral }}
        onSubmit={handleSubmit}
        validationSchema={schema}
        renderHeaderContent={<PatientSummaryCard patient={patientDetails} />}
        renderExtraActions={props => renderExtraActions(currentReferral?.referralStatus, props)}
        renderSubmitButton={renderSubmitButton}
        variant="filled"
        content={({ values, setFieldValue }) => {
          const calculateReferralExpiryDate = (typeOfReferralCode, referralDateUtc) => {
            // If the type of referral has a duration setting, update the expiry to the new referral date + setting months
            const typeOfReferralCodeId = isObject(typeOfReferralCode)
              ? typeOfReferralCode?.value
              : typeOfReferralCode;
            const months = codeSetValueProperties(typeOfReferralCodeId)?.referralDurationMonths;
            if (months) {
              setFieldValue("referral.referralExpiryDate", moment(referralDateUtc).add(months, "M"));
            }
          };

          const referralDetailComponents = sectionComponentProps => {
            if (loadingFieldSettings) return <LoadingState />;
            return (
              <>
                <Field
                  name="referral.referrerInformationSection"
                  component={ReferrerInformationSection}
                  calculateReferralExpiryDate={calculateReferralExpiryDate}
                  {...sectionComponentProps}
                />
                <Field
                  name="referral.referralInformationSection"
                  component={ReferralInformationSection}
                  {...sectionComponentProps}
                  isEdit={isEdit}
                  currentReferral={currentReferral}
                  calculateReferralExpiryDate={calculateReferralExpiryDate}
                  currentAssignedToSpecialty={currentAssignedToSpecialty}
                  presentingComplaintMaxLength={presentingComplaintMaxLength}
                  patientPresentingComplaints={patientPresentingComplaints}
                  isEmptyPatient={isEmptyPatient}
                />
                <Field
                  name="referral.billingInformationSection"
                  component={BillingInformationSection}
                  fieldSettings={sectionComponentProps?.fieldSettings}
                  fieldLabels={sectionComponentProps?.fieldLabels}
                  referral={sectionComponentProps?.referral}
                  calculateReferralExpiryDate={calculateReferralExpiryDate}
                  currentAssignedToSpecialty={currentAssignedToSpecialty}
                  referralFormLayoutSetting={referralFormLayoutSetting}
                  allowSpecialtyReassignment={allowSpecialtyReassignment}
                />
                {fieldSettings?.attachments && (
                <Section title={fieldLabels.attachments}>
                  <Field
                    name="referral.addedAttachments"
                    label="Drag 'n' drop file here, or click to select file"
                    component={ReferralDocumentAttachmentUpload}
                    orgUnitId={orgUnit.id}
                    disabled
                    referralId={currentReferral?.id}
                    referralPermissions={currentReferral?.permissions}
                    removedAttachmentFieldName="referral.removedAttachments"
                  />
                </Section>
               )}
              </>
            );
          };

          return (
            <>
              <ReferralWarningMessage warningMessages={warningMessages} />
              {renderTemplateComponents && renderTemplateComponents({ isReading, values, loadingFieldSettings, fieldSettings })}
              {(isEmpty(values?.referral?.referralFormProfileId)
               || isEmpty(currentAssignedToSpecialty?.value))
                ? <EmptyState title={`Select a ${specialtyLabel} and ${specialtyFormProfileLabel} to continue`} />
                : referralDetailComponents({ fieldSettings, fieldLabels, ocrResult, isNewUpload, referral: values?.referral, orgUnitId: currentReferralOrgUnitId })}
            </>
          );
        }}
      />
    </ReferralFormComponent>
  );
};
