import React from "react";
import { Field } from "formik";
import { map, isEmpty, includes, find, head, size, filter } from "lodash";
import { Fade, IconButton, Tooltip } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import CalendarMultipleIcon from "mdi-react/CalendarMultipleIcon";

import { FilterSection, FilterForm } from "components/items/advanced-filter";
import SelectInput from "components/inputs/select-input";
import DateRange from "components/inputs/date-range";
import { AscendingIcon, DescendingIcon, FaceSickIcon, OrgUnitsIcon, LocationIcon, NoteIcon, TypeIcon, CreatedByIcon } from "helpers/icon-finder";
import { CheckboxInput, UserSelector, RadioButtonsGroup, MultipleCheckbox, TextInput } from "components/inputs";
import withPermissions from "permissions/withPermissions";
import SpecialtySelector from "app/main/specialtyProfiles/components/specialty-selector";
import SpecialtyAssignedRoleSelector from "app/main/specialtyProfiles/components/specialty-assigned-role-selector";
import SubSpecialtySelector from "app/main/specialtyProfiles/components/sub-specialty-selector";
import SpecialtySpecialistSelector from "app/main/specialtyProfiles/components/specialty-specialist-selector";
import { getSignedInOrgUnit, isEnableCIRS, getUser } from "app/auth/store/reducers/user.reducer";
import { fetchAllUserRoles } from "app/main/users/actions/users.actions";
import { getReferralSortByOptions, getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";
import { getDefaultSortOrder, getSortTitle } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import SpecialtiesCodeSetSelector from "app/main/specialtyProfiles/components/specialties-code-set-selector";
import { getCodeSetValuesByTypeCode } from "app/main/codeSet/reducers/codeSet.reducers";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";
import OrgUnitSelector from "app/main/orgUnits/components/orgUnit-selector";

const taskOptions = [
  { value: "CompletedTasks", label: "Has Completed Tasks" },
  { value: "OutstandingTasks", label: "Has Outstanding Tasks" },
  { value: "OverdueTasks", label: "Has Overdue Tasks" },
];

const ReferralAdvancedFilterForm = ({
  handleSubmit,
  handleReset,
  referralStatusChoices,
  triageCategoryChoices,
  referralWorkflowSettings,
  triageCategorySettings,
  hasPermissionReferralGlobalAccess,
  hasPermissionReferralTasksModify,
  hasPermissionReferralGlobalAccessCreateReferral,
  triageFieldSettings,
  triageFieldLabels,
  rejectFieldSettings,
  rejectFieldLabels,
  referralFieldSettings,
  referralFieldLabels,
  orgUnitId,
  ...other
}) => {
  const dispatch = useDispatch();
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);
  const getWorkflowStateLabel = stateName => find(referralWorkflowSettings, x => x.workflowState === stateName)?.label || stateName;
  const getTriageCategoryLabel = category => find(triageCategorySettings, choice => choice.triageCategory === category)?.label;
  const referralSortByOptions = useSelector(getReferralSortByOptions);
  const defaultSortOrder = useSelector(getDefaultSortOrder);
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const enableCIRS = useSelector(isEnableCIRS);
  const currentUser = useSelector(getUser);

  const sortOptions = map(referralSortByOptions, x => ({
    ...x,
    icon: <CalendarMultipleIcon />,
    sortOrderDescending: defaultSortOrder?.sortOrderDescending,
  }));

  const referrerAdvisedPrioritySettings = triageFieldSettings?.referrerAdvisedPriority ?? rejectFieldSettings?.referrerAdvisedPriority;
  const referrerAdvisedPriorityLabel = triageFieldLabels?.referrerAdvisedPriority ?? rejectFieldLabels?.referrerAdvisedPriority;
  const referrerAdvisedPriorityValues = useSelector(state => getCodeSetValuesByTypeCode(state, referrerAdvisedPrioritySettings?.fieldName));

  //Apply filtering for referrerAdvisedPriorityValues for CRISOnly and HideOnSearch which may be present for the CodeSetValues
  let filteredReferrerAdvisedPriorityCodeSetValues = referrerAdvisedPriorityValues?.codeSetValues || [];
  if (enableCIRS && filteredReferrerAdvisedPriorityCodeSetValues) {
    filteredReferrerAdvisedPriorityCodeSetValues = filter(filteredReferrerAdvisedPriorityCodeSetValues, x => (x.propertiesJson?.CIRSOnly ?? false) === enableCIRS);
  }
  if (filteredReferrerAdvisedPriorityCodeSetValues) {
    filteredReferrerAdvisedPriorityCodeSetValues = filter(filteredReferrerAdvisedPriorityCodeSetValues, x => (x.propertiesJson?.HideOnSearch ?? false) === false);
  }

  const referrerAdvisedPriorityFilteredOptions = map(filteredReferrerAdvisedPriorityCodeSetValues, x => ({ label: x.displayName, value: x.id }));

  const referringOrgUnitLabel = referralFieldLabels?.referringOrgUnitId;
  const typeOfReferralLabel = referralFieldLabels?.typeOfReferral;

  React.useEffect(() => {
    dispatch(fetchAllUserRoles());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const referralStatusOptions = map(referralStatusChoices, x => ({ label: getWorkflowStateLabel(x), value: x }));
  const triageCategoryOptions = map(triageCategoryChoices, x => ({ label: getTriageCategoryLabel(x), value: x }));

  return (
    <FilterForm
      onSubmit={handleSubmit}
      onReset={handleReset}
      {...other}
      filters={({ setFieldValue, values }) => {
        if (!isEmpty(values?.referralFilters?.referrerAdvisedPriorityCodeIds) && values?.referralFilters?.onlyUnassignedReferrerAdvisedPriority === true) {
          setFieldValue("referralFilters.onlyUnassignedReferrerAdvisedPriority", false);
        }

        return (
          <div className="advanced-filter-sections">
            <FilterSection withDivider label="Sort By">
              <Field
                name="referralFilters.sortBy"
                icon={values.referralFilters.sortBy?.icon}
                component={SelectInput}
                options={sortOptions}
                inputProps={{ isClearable: false }}
                renderExtraAction={(
                  <Tooltip title={getSortTitle(values.referralFilters.sortBy, values.referralFilters.sortOrderDescending)}>
                    <IconButton
                      aria-label="Sort"
                      onClick={() => {
                        setFieldValue("referralFilters.sortOrderDescending", !values.referralFilters.sortOrderDescending);
                      }}
                    >
                      {values.referralFilters.sortOrderDescending ? <DescendingIcon /> : <AscendingIcon />}
                    </IconButton>
                  </Tooltip>
                )}
                onChange={option => {
                  setFieldValue("referralFilters.sortOrderDescending", option.sortOrderDescending);
                }}
              />
              {referralWorklistSettings.enableUrgentSortOrder && (
                <Field
                  name="referralFilters.applyUrgentReferralFirst"
                  label="Urgent on Top"
                  component={CheckboxInput}
                  showIcon={false}
                  onChangeSelectAll={checked => {
                    setFieldValue("referralFilters.applyUrgentReferralFirst", checked);
                  }}
                  onChange={() => setFieldValue("referralFilters.applyUrgentReferralFirst", !values.referralFilters.applyUrgentReferralFirst)}
                />
              )}
            </FilterSection>
            <FilterSection label="Referral Date" withDivider>
              <Field
                name="referralFilters.dateRange"
                component={DateRange}
                definedRanges={["today", "yesterday"]}
                onChange={value => {
                  if (value) {
                    setFieldValue("referralFilters.startDate", value[0]);
                    setFieldValue("referralFilters.endDate", value[1]);
                  } else {
                    setFieldValue("referralFilters.startDate", null);
                    setFieldValue("referralFilters.endDate", null);
                  }
                }}
              />
            </FilterSection>
            <FilterSection label="Status" withDivider>
              <Field
                name="referralFilters.status"
                component={MultipleCheckbox}
                options={referralStatusOptions}
              />
              <Field
                name="referralFilters.daysSinceLastStatusChange"
                label="Days waiting in status"
                component={TextInput}
                disabled={isEmpty(values.referralFilters.status)}
                showHelperText={false}
                showIcon={false}
                type="number"
              />
            </FilterSection>

            {referrerAdvisedPrioritySettings && (
              <FilterSection label={referrerAdvisedPriorityLabel} withDivider>
                <Field
                  name="referralFilters.referrerAdvisedPriorityCodeIds"
                  component={MultipleCheckbox}
                  options={referrerAdvisedPriorityFilteredOptions}
                  onChangeSelectAll={value => {
                    if (value === true) {
                      setFieldValue("referralFilters.onlyUnassignedReferrerAdvisedPriority", false);
                    }
                  }}
                  renderExtraActions={(
                    <Field
                      name="referralFilters.onlyUnassignedReferrerAdvisedPriority"
                      component={CheckboxInput}
                      label="None Recorded"
                      showIcon={false}
                      onChange={value => {
                        if (value === true) {
                          setFieldValue("referralFilters.referrerAdvisedPriorityCodeIds", null);
                        }
                      }}
                    />
                  )}
                />
              </FilterSection>
            )}

            <FilterSection label="Triage Category" withDivider>
              <Field
                name="referralFilters.category"
                component={MultipleCheckbox}
                options={triageCategoryOptions}
                onChange={() => {
                  if (!includes(values.referralFilters.category, triageCategoryChoices[0])) {
                    setFieldValue("referralFilters.isUrgent", false);
                  }
                }}
              />
              <Fade in={triageFieldSettings?.isUrgent && includes(values.referralFilters.category, triageCategoryChoices[0])}>
                <div>
                  <Field
                    name="referralFilters.isUrgent"
                    label={triageFieldLabels.isUrgent}
                    component={CheckboxInput}
                    showIcon={false}
                    onChangeSelectAll={checked => {
                      setFieldValue("referralFilters.isUrgent", checked);
                    }}
                    onChange={() => setFieldValue("referralFilters.isUrgent", !values.referralFilters.isUrgent)}
                  />
                </div>
              </Fade>
            </FilterSection>

            <FilterSection label="Referrer Urgency" withDivider>
              <Field
                name="referralFilters.isReferrerUrgent"
                label="Urgent"
                component={CheckboxInput}
                showIcon={false}
                onChange={() => setFieldValue("referralFilters.isReferrerUrgent", !values.referralFilters.isReferrerUrgent)}
              />
            </FilterSection>
            {/* TEMP hide for BHS release */}
            {/* <FilterSection withDivider>
            <Field
              name="referralFilters.unreadAddendum"
              label="Unread Updated Information"
              component={CheckboxInput}
              showIcon={false}
            />
          </FilterSection> */}
            {hasPermissionReferralTasksModify
              && (
                <FilterSection label="Task" withDivider>
                  <Field
                    name="referralFilters.taskStatus"
                    component={RadioButtonsGroup}
                    choices={taskOptions}
                    displayRow
                    allowUnCheck
                  />
                  <Field
                    name="referralFilters.taskAssignedToUserId"
                    label="Assigned To User"
                    component={UserSelector}
                    orgUnitId={orgUnitId}
                    includeInactive
                  />
                  <Field
                    name="referralFilters.taskAssignedToRoleIds"
                    label="Assigned To Roles"
                    component={SpecialtyAssignedRoleSelector}
                    orgUnitId={orgUnitId}
                  />
                </FilterSection>
              )}
            <FilterSection label="Assigned To" withDivider>
              <Field
                name="referralFilters.assignedToSpecialtyIds"
                label="Specialty"
                component={SpecialtySelector}
                orgUnitId={signedInOrgUnit.id}
                onChange={assignedToSpecialtyIds => {
                  const currentAssignedToSpecialtyIds = map(assignedToSpecialtyIds, x => x.value);
                  // filter out the ones that no longer in the selected specialties
                  const updatedAssignedToSubSpecialty = filter(values.referralFilters.assignedToSubSpecialties, x => includes(currentAssignedToSpecialtyIds, x.specialtyProfileId));
                  setFieldValue("referralFilters.assignedToSubSpecialtyIds", map(updatedAssignedToSubSpecialty, x => x.id));

                  setFieldValue("referralFilters.assignedToSpecialistId", null);
                  setFieldValue("referralFilters.onlyShowUnassigned", false);
                }}
                loadInitialOption={values.referralFilters.assignedToSpecialtyIds}
                multiple
              />
              <Field
                name="referralFilters.assignedToSubSpecialtyIds"
                component={SubSpecialtySelector}
                specialtyIds={values.referralFilters.assignedToSpecialtyIds}
                onChange={() => {
                  setFieldValue("referralFilters.onlyShowUnassigned", false);
                }}
                multiple
              />
              <Field
                name="referralFilters.assignedToSpecialistId"
                label="Specialist"
                component={SpecialtySpecialistSelector}
                specialtyId={head(values.referralFilters.assignedToSpecialtyIds)}
                disabled={size(values.referralFilters.assignedToSpecialtyIds) > 1}
                onChange={() => {
                  setFieldValue("referralFilters.onlyShowUnassigned", false);
                }}
              />
              {hasPermissionReferralGlobalAccess
                && (
                  <Field
                    name="referralFilters.onlyShowUnassigned"
                    label="Not Assigned to a Specialty"
                    component={CheckboxInput}
                    showIcon={false}
                    onChange={val => {
                      if (val === true) {
                        setFieldValue("referralFilters.assignedToSpecialtyIds", null);
                        setFieldValue("referralFilters.assignedToSubSpecialtyId", null);
                      }
                    }}
                  />
                )}
              <Field
                name="referralFilters.assignedToRoleIds"
                label="Role"
                component={SpecialtyAssignedRoleSelector}
                specialtyIds={values.referralFilters.assignedToSpecialtyIds?.value ?? values.referralFilters.assignedToSpecialtyIds}
                orgUnitId={orgUnitId}
                loadOptions
                disabled={!isEmpty(values.referralFilters.assignedToUserId)}
              />
              <Field
                name="referralFilters.assignedToUserId"
                label="User"
                component={UserSelector}
                disabled={!isEmpty(values.referralFilters.assignedToRoleId)}
                includeInactive
              />
            </FilterSection>
            {/* Created By */}
            <FilterSection>
              <Field
                name="referralFilters.createdByUserId"
                icon={<CreatedByIcon />}
                label="Created By"
                component={UserSelector}
                orgUnitId={orgUnitId}
                includeInactive
                loadInitialOption={values.referralFilters.createdByUserId}
                onChange={selectedUser => {
                  setFieldValue("referralFilters.createdByUserId", selectedUser?.value);

                  // Disable and uncheck "Include My Referrals for All Sites" if the selected user is not the current user
                  if (!selectedUser
                    || (selectedUser && selectedUser.value !== currentUser.userId)) {
                    setFieldValue("referralFilters.includeAllSites", false);
                  }
                }}
              />
              {/* Include All Sites */}
              {/* Only show in the advanced search if the user has the hasPermissionReferralGlobalAccessCreateReferral permission */}
              {/* Uncheck and disable the checkbox if Created By filter doesn't set to current user themselves */}
              {hasPermissionReferralGlobalAccessCreateReferral && (
                <Field
                  name="referralFilters.includeAllSites"
                  label="Include My Referrals for All Sites"
                  component={CheckboxInput}
                  showIcon={false}
                  onChange={() => setFieldValue("referralFilters.includeAllSites", !values.referralFilters.includeAllSites)}
                  // disable the checkbox if the selected user is not the current user
                  disabled={
                    !values.referralFilters.createdByUserId
                    || (values.referralFilters.createdByUserId && values.referralFilters.createdByUserId !== currentUser.userId)
                  }
                />
              )}
            </FilterSection>
            {/* Presenting Complaint */}
            <FilterSection>
              <Field
                name="referralFilters.presentingComplaintCodes"
                component={SpecialtiesCodeSetSelector}
                icon={<FaceSickIcon />}
                codeSetTypeCode="PresentingComplaintCodes"
                label="Presenting Complaint"
                specialtyIds={values.referralFilters.assignedToSpecialtyIds?.value ?? values.referralFilters.assignedToSpecialtyIds}
                loadOptions
                multiple
                isTermSelector
                onChange={selectedValues => {
                  const presentingComplaintCodes = map(selectedValues, x => x.value);
                  setFieldValue("referralFilters.presentingComplaintCodes", presentingComplaintCodes);
                }}
                allowHideOnSearch
              />
            </FilterSection>
            {/* Referral Reason  */}
            <FilterSection>
              <Field
                name="referralFilters.reasonForReferralCodeIds"
                icon={<NoteIcon />}
                component={CodeSetValueSelector}
                codeSetTypeCode="ReasonForReferralCode"
                showIcon={false}
                multiple
                label="Reason For Referral"
                onChange={value => {
                  setFieldValue("referralFilters.reasonForReferralCodeId", value?.value);
                }}
                allowHideOnSearch
              />
            </FilterSection>
            {/* Referral Type */}
            {referralFieldSettings?.typeOfReferral && (
              <FilterSection withDivider>
                <Field
                  name="referralFilters.typeOfReferralCodeIds"
                  icon={<TypeIcon />}
                  component={CodeSetValueSelector}
                  codeSetTypeCode="TypeOfReferral"
                  showIcon={false}
                  multiple
                  label={typeOfReferralLabel}
                  onChange={types => {
                    const ids = map(types, x => x?.value);
                    setFieldValue("referralFilters.typeOfReferralCodeIds", ids);
                  }}
                  allowHideOnSearch
                />
              </FilterSection>
            )}
            {/* Referral Source */}
            {referralFieldSettings?.referringOrgUnitId && (
              <FilterSection>
                <Field
                  name="referralFilters.referringOrgUnitIds"
                  icon={<OrgUnitsIcon />}
                  label={referringOrgUnitLabel}
                  orgUnitType="Site"
                  multiple
                  showIcon={false}
                  component={OrgUnitSelector}
                  onChange={orgUnits => {
                    const ids = map(orgUnits, x => x?.value);
                    setFieldValue("referralFilters.referringOrgUnitIds", ids);
                  }}
                />
              </FilterSection>
            )}
            {/* Referral Location */}
            <FilterSection>
              <Field
                name="referralFilters.referringLocationCodeIds"
                icon={<LocationIcon />}
                component={CodeSetValueSelector}
                codeSetTypeCode="ReferringLocation"
                multiple
                showIcon={false}
                label="Location"
                onChange={value => {
                  setFieldValue("referralFilters.referringLocationCodeId", value?.value);
                }}
                allowHideOnSearch
              />
            </FilterSection>
          </div>
        );
      }}
    />
  );
};

export default withPermissions("ReferralGlobalAccess", "ReferralTasksModify", "ReferralGlobalAccessCreateReferral")(ReferralAdvancedFilterForm);