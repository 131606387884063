import React from "react";
import { Alert } from "@material-ui/lab";
import { map, isEmpty } from "lodash";

function ReferralWarningMessage(props) {
  const { warningMessages } = props;
  return !isEmpty(warningMessages) && (
  <Alert severity="warning">{map(warningMessages, (warningMessage, index) => (
    <React.Fragment key={index}>{warningMessage}<br /></React.Fragment>
  ))}
  </Alert>
  );
}

export default ReferralWarningMessage;
