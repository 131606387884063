import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty } from "lodash";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import Pagination from "@material-ui/lab/Pagination";

import { getDefaultSortOrder } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import { fetchPatientReferrals, resetPatientActiveReferralSearch, updatePatientReferralWorkFilterTags } from "app/main/referrals/actions/patientReferrals.actions";
import { getReferralsForPatient, getPatientReferralPageInfo, REFERRALS_PAGE_SIZE, isPageLoading, getPatientReferralSearchParams } from "app/main/referrals/reducers/patientReferrals.reducers";
import StateContainer from "components/layouts/default-state-container";
import PatientReferralListAdvancedFilter from "app/main/patients/referrals/components/patient-referral-list-advanced-filter";
import useReferralSearch from "app/main/referralWorklist/hooks/useReferralSearch";
import useTriageFieldSettings from "app/main/referrals/hooks/useTriageFieldSettings";
import PatientReferralsListItem from "./patient-referrals-item";

export default function PatientReferralsPage({ patientId, orgUnitId, fromUrl, classes, showLoadReferrals = true }) {
  const dispatch = useDispatch();

  const patientReferrals = useSelector(state => getReferralsForPatient(state, patientId));
  const isLoading = useSelector(state => isPageLoading(state, orgUnitId, patientId));
  const pageInfo = useSelector(state => getPatientReferralPageInfo(state, patientId));
  const params = useSelector(getPatientReferralSearchParams);
  const defaultSortOrder = useSelector(getDefaultSortOrder);
  const { fieldSettings: triageFieldSettings, fieldLabels: triageFieldLabels } = useTriageFieldSettings(orgUnitId);

  const { searchParams, onSearch, referralWorkflowSettings, ...otherSearchProps } = useReferralSearch({ params, orgUnitId, searchAPI: fetchPatientReferrals, updateFilterTags: updatePatientReferralWorkFilterTags });

  const onSearchPatientReferrals = (newParams, page, forceLoad) => {
    dispatch(fetchPatientReferrals({ ...newParams, patientId, orgUnitId }, page, forceLoad))
      .then(res => {
        if (res) {
          dispatch(updatePatientReferralWorkFilterTags(res.payload?.searchParams));
        }
      });
  };

  useEffect(() => {
    if (showLoadReferrals) {
      onSearchPatientReferrals({ ...searchParams, sortOrderDescending: defaultSortOrder?.sortOrderDescending }, 1);
    }
    return () => dispatch(resetPatientActiveReferralSearch());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showLoadReferrals]);

  const handleChangePage = (event, page) => {
    if (page !== pageInfo.pageNumber) {
      onSearchPatientReferrals(searchParams, page, true);
    }
  };

  return (
    <div className={clsx("flex flex-col h-full justify-between", classes?.root)}>
      <div className={clsx(classes?.filter)}>
        <PatientReferralListAdvancedFilter
          total={pageInfo?.totalRecords}
          pageNumber={pageInfo?.pageNumber}
          pageSize={REFERRALS_PAGE_SIZE}
          onSearch={onSearch}
          onPageChange={handleChangePage}
          searchParams={searchParams}
          patientId={patientId}
          orgUnitId={orgUnitId}
          referralWorkflowSettings={referralWorkflowSettings}
          triageFieldLabels={triageFieldLabels}
          triageFieldSettings={triageFieldSettings}
          {...otherSearchProps}
        />
      </div>
      <div className={clsx("py-16 flex-auto", classes?.content)}>
        <StateContainer loading={isLoading} empty={isEmpty(patientReferrals)} emptyTitle="No Referrals Found">
          <div className="flex-auto">
            {map(patientReferrals, (referral, index) => (
              <PatientReferralsListItem
                key={referral.id}
                patientId={patientId}
                orgUnitId={orgUnitId}
                isFirst={index === 0}
                referral={referral}
                fromUrl={fromUrl}
                referralWorkflowSettings={referralWorkflowSettings}
                triageFieldLabels={triageFieldLabels}
                disabled={!referral.canViewFullReferral}
              />
            ))}
          </div>
        </StateContainer>
      </div>
      <div className={clsx("flex items-center justify-end", classes?.paging)}>
        <Typography variant="caption" color="textSecondary">Total results: {pageInfo?.totalRecords}</Typography>
        <Pagination
          count={Math.ceil(pageInfo?.totalRecords / pageInfo?.pageSize) ?? 1}
          page={pageInfo?.pageNumber ?? 1}
          onChange={handleChangePage}
        />
      </div>
    </div>
  );
}
