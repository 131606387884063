import React from "react";
import { connect } from "react-redux";
import { isEmpty, xorBy, findIndex } from "lodash";

import { Field } from "formik";

import { validateRequired, validateEmailFormat, validatePhoneFormat, validateMulti, validateMaxLength } from "utils/validators";
import { TextInput } from "components/inputs";
import Form from "components/form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { getUser, getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import withPermissions from "permissions/withPermissions";
import ConfirmationDialog from "components/items/confirmation-dialog";
import PanelContent from "components/items/panel-content";
import { getUserAdminOptionalFields } from "app/auth/store/reducers/system-configuration";

import { addNewUser, updateExistingUser } from "../actions/users.actions";
import { getCurrentUser } from "../reducers/users.reducers";
import UserRoleSelector from "./user-roles-selector";
import EndorsementTypeSelector from "./endorsement-type-selector";
import UserSpecialtyProfileSelector from "./user-specialty-profile-selector";

const username = "Username";
const title = "Title";
const firstName = "First Name";
const lastName = "Last Name";
const phoneMobile = "Mobile Number";
const email = "Email";
const userRolesLabel = "Roles";
const specialtyProfileLabel = "Specialty";
const endorsementTypes = "Endorsement Types";
const position = "Position";
const employeeNumber = "Employee Number";

const validateUsername = value => validateRequired(username)(value) || validateMaxLength(username, 128)(value);
const validateFirstName = value => validateRequired(firstName)(value) || validateMaxLength(firstName, 200)(value);
const validateLastName = value => validateRequired(lastName)(value) || validateMaxLength(lastName, 200)(value);
const validateEmail = value => validateEmailFormat(email)(value) || validateMaxLength(email, 255)(value);
const validatePhone = value => validatePhoneFormat(phoneMobile)(value) || validateMaxLength(phoneMobile, 20)(value);
const validateRoles = value => validateRequired(userRolesLabel)(value) || validateMulti(userRolesLabel)(value);
const validatePosition = validateMaxLength(position, 128);
const validateEmployeeNumber = validateMaxLength(employeeNumber, 128);
const validateTitle = validateMaxLength(title, 50);

class UserForm extends React.PureComponent {
  isFieldEnabled = fieldName => findIndex(this.props.enabledFields, x => x === fieldName) !== -1;

  handleSubmit = ({ user }, { setErrors, setSubmitting }) => {
    const rolesHaveChanged = () => !isEmpty(xorBy(this.props.initialValues.user.userRoles, user.userRoles, x => `${x.orgUnitId}/${x.roleId}`));

    // if user is changing their own role, ask for confirmation
    if (this.props.currentAdminUser.userId === user.userId && rolesHaveChanged()) {
      this.props.openDialog({
        disableEscapeKeyDown: true, // consider to put this in default settings?
        maxWidth: "xs",
        children: (
          <ConfirmationDialog
            onCancel={() => {
              setSubmitting(false);
              this.props.closeDialog();
            }}
            onConfirm={() => {
              this.submitUserChange(user, setErrors, setSubmitting, true);
              this.props.closeDialog();
            }}
            title={<div className="text-center">Are you sure?</div>}
            content="Changing your roles may reduce your access level"
          />
        ),
      });
    } else {
      this.submitUserChange(user, setErrors, setSubmitting, false);
    }
  };

  submitUserChange(user, setErrors, setSubmitting, refreshOnSuccess) {
    const apiCall = this.props.isEdit ? this.props.updateExistingUser : this.props.addNewUser;

    apiCall(this.props.signedInOrgUnit?.id, user).then(responds => {
      if (responds.error !== true) {
        if (refreshOnSuccess) {
          window.location.href = "/";
        } else {
          this.props.onSucceed();
        }
      } else {
        setErrors(responds.payload);
      }
      setSubmitting(false);
    });
  }

  render() {
    const {
      roles,
      hasPermissionUsersMaintainDetail,
      hasPermissionUsersMaintainRoles,
      hasPermissionUsersMaintainSpecialtyProfile,
      signedInOrgUnit,
      initialValues,
      enabledFields,
      disabled,
      ...other
    } = this.props;

    const disabledFullUserDetail = disabled || !hasPermissionUsersMaintainDetail;

    return (
      <Form
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        isModal={false}
        disabled={disabledFullUserDetail}
        {...other}
        content={() => (
          <>
            <Field
              name="user.username"
              label={username}
              type="text"
              component={TextInput}
              validate={validateUsername}
            />
            <PanelContent itemsPerRow={3}>
              <Field
                name="user.title"
                label={title}
                component={TextInput}
                validate={validateTitle}
              />
              <Field
                name="user.firstName"
                label={firstName}
                component={TextInput}
                validate={validateFirstName}
              />
              <Field
                name="user.lastName"
                component={TextInput}
                label={lastName}
                validate={validateLastName}
              />
            </PanelContent>
            <PanelContent>
              <Field
                name="user.phoneMobile"
                label={phoneMobile}
                component={TextInput}
                validate={validatePhone}
              />
              <Field
                name="user.email"
                component={TextInput}
                label={email}
                validate={validateEmail}
              />
            </PanelContent>
            {this.isFieldEnabled("EmployeeNumber") && (
              <Field
                name="user.employeeNumber"
                component={TextInput}
                label={employeeNumber}
                validate={validateEmployeeNumber}
              />
            )}
            {this.isFieldEnabled("Position") && (
              <Field
                name="user.position"
                component={TextInput}
                label={position}
                validate={validatePosition}
              />
            )}
            <Field
              name="user.userRoles"
              component={UserRoleSelector}
              label={userRolesLabel}
              isDisabled={disabled || !hasPermissionUsersMaintainRoles}
              orgUnit={signedInOrgUnit}
              validate={validateRoles}
            />
            {this.isFieldEnabled("EndorsementTypes") && (
              <Field
                name="user.endorsementTypes"
                component={EndorsementTypeSelector}
                label={endorsementTypes}
                isDisabled={disabled || !hasPermissionUsersMaintainRoles}
                orgUnit={signedInOrgUnit}
              />
            )}
            {this.isFieldEnabled("SpecialtyProfileId") && (
            <Field
              name="user.userSpecialtyProfiles"
              component={UserSpecialtyProfileSelector}
              label={specialtyProfileLabel}
              isDisabled={disabled || !hasPermissionUsersMaintainSpecialtyProfile}
              orgUnit={signedInOrgUnit}
            />
            )}
          </>
        )}
      />
    );
  }
}

export default withPermissions("UsersMaintainDetail", "UsersMaintainRoles", "UsersMaintainSpecialtyProfile")(connect(
  (state, ownProps) => {
    const signedInOrgUnit = getSignedInOrgUnit(state);
    const user = ownProps.user || getCurrentUser(state);

    return ({
      initialValues: { user: { ...user, specialtyProfileId: user?.specialtyProfile?.id, subSpecialtyId: user?.subSpecialty?.id } },
      currentAdminUser: getUser(state),
      signedInOrgUnit,
      enabledFields: getUserAdminOptionalFields(state),
    });
  },
  { addNewUser, updateExistingUser, openDialog, closeDialog },
)(UserForm));
