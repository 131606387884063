import React from "react";
import { useDispatch } from "react-redux";
import { Typography } from "@material-ui/core";
import Form from "components/form";

import { undoReferralAction } from "app/main/referrals/actions/referrals.actions";
import { find } from "lodash";
import { rejectedWorkflowState, acceptedWorkflowState, requestedWorkflowState, draftWorkflowState } from "../helpers/workflow-state-names";
import { getWarningMessages } from "../helpers/get-referral-action-warning-messages";
import ReferralWarningMessage from "./referral-warning-message-alert";

const UndoReferralDialog = ({ onSucceed,
  orgUnitId,
  referralWorkflowSettings,
  referralActionTypeSettings,
  referral: { referralStatus, id, referralDocument } }) => {
  const dispatch = useDispatch();
  const getWorkflowStateLabel = stateName => find(referralWorkflowSettings, x => x.workflowState === stateName)?.label || stateName;
  const getWorkflowState = stateName => find(referralWorkflowSettings, x => x.workflowState === stateName)?.name || stateName;

  const getPreviousStatus = currentStatus => {
    switch (currentStatus) {
      case "Accepted":
      case "AwaitingInformation":
      case "Rejected":
        return getWorkflowStateLabel(requestedWorkflowState);
      case "Completed":
        return getWorkflowStateLabel(acceptedWorkflowState);
      case "Archived":
        return getWorkflowStateLabel(rejectedWorkflowState);
      case "Processing":
        return getWorkflowStateLabel(draftWorkflowState);
      default:
        return null;
    }
  };

  const getPreviousActionType = currentStatus => {
    switch (currentStatus) {
      case "Accepted":
      case "AwaitingInformation":
      case "Rejected":
        return getWorkflowState(requestedWorkflowState);
      case "Completed":
        return getWorkflowState(acceptedWorkflowState);
      case "Archived":
        return getWorkflowState(rejectedWorkflowState);
      case "AcceptedBooked":
        return getWorkflowState(acceptedWorkflowState);
      case "Process":
        return getWorkflowState(draftWorkflowState);
      default:
        return null;
    }
  };

  const getPreviousReferralActionSettings = () => {
    const actionType = getPreviousActionType(referralStatus);
    return find(referralActionTypeSettings, x => x.actionType === actionType);
  };

  const warningMessages = () => getWarningMessages(getPreviousReferralActionSettings(), referralDocument.referralDocumentType);

  const handleSubmit = ({ referralId = id }, { setSubmitting, setErrors }) => {
    const undoReferral = {
      referralId,
    };

    dispatch(undoReferralAction(orgUnitId, undoReferral)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      contentProps={{ title: "Undo Referral Status", submitLabel: "Confirm" }}
      variant="filled"
      content={() => (
        <>
          <ReferralWarningMessage warningMessages={warningMessages()} />
          <Typography>{`Set this referral back to ${getPreviousStatus(referralStatus)}?`}</Typography>
        </>
      )}
    />
  );
};

export default UndoReferralDialog;
