import { ApiActions } from "middleware/call-api";
import moment from "moment-timezone";
import { isPageLoaded, isPageLoading } from "../reducers/integrationLogs.reducers";
import formatSearchParams from "../../../../helpers/format-search-params";

export const searchIntegrationLogsPageInfo = (pageNumber, pageSize, searchParams, forceLoad = true) => dispatch => {
  const params = formatSearchParams(searchParams);
  const timeZone = moment.tz.guess();
  const url = `/logs?pageNumber=${pageNumber}&pageSize=${pageSize}&timeZone=${timeZone}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "INTEGRATION_LOGS_LIST",
      pageNumber,
      bailout: state => isPageLoading(state, pageNumber) || (!forceLoad && isPageLoaded(state, pageNumber)),
    },
  });
};
