import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { get, map, isEmpty, find, isObject, isEqual, isString, filter } from "lodash";
import { Field } from "formik";
import { IconButton, Tooltip } from "@material-ui/core";
import moment from "moment-timezone";

import PillIcon from "mdi-react/PillIcon";
import CursorPointerIcon from "mdi-react/CursorPointerIcon";
import CalendarMultipleIcon from "mdi-react/CalendarMultipleIcon";

import AdvancedFilter, { FilterForm, FilterTags, FilterSection } from "components/items/advanced-filter";
import DefaultSelectionFilter from "components/items/default-selection-filter";
import { UserSelector, MultipleCheckbox, TextInput } from "components/inputs";
import DateRange from "components/inputs/date-range";
import formatFilterDate from "helpers/format-filter-date";
import RadioButtonsGroup from "components/inputs/radio-buttons-group";

import { filterOptions, getPresetFilterParams, defaultFilterOption, getApplicationWorklistFilter } from "app/main/applicationWorklist/reducers/applicationWorklist.reducers";
import { setApplicationWorkListFilter } from "app/main/applicationWorklist/actions/applicationWorklist.actions";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import SelectInput from "components/inputs/select-input";
import SpecialtySelector from "app/main/specialtyProfiles/components/specialty-selector";
import formatSearchParams from "helpers/format-search-params";
import downloadFromApi from "utils/download-from-api";
import withPermissions from "permissions/withPermissions";
import { isIE } from "helpers/is-browser";
import { ExcelExportIcon, AscendingIcon, DescendingIcon } from "helpers/icon-finder";

const sortOptions = [
  { value: "RequestedDate", label: "Submitted Date", icon: <CalendarMultipleIcon />, sortOrderDescending: true },
  { value: "StatusChangedDate", label: "Recent Activity", icon: <CalendarMultipleIcon />, sortOrderDescending: true },
  { value: "CreatedDate", label: "Created Date", icon: <CalendarMultipleIcon />, sortOrderDescending: true },
  { value: "MeetingDate", label: "Meeting Date", icon: <CalendarMultipleIcon />, sortOrderDescending: true },
  { value: "PatientFamilyName", label: "Patient Name", icon: "person", sortOrderDescending: false },
];

const provisionalApprovalChoices = [
  { value: true, label: "Has Provisional Approval" },
  { value: false, label: "Does Not Have Provisional Approval" },
];

const appealStatusChoices = [
  { value: true, label: "Open" },
  { value: false, label: "Closed" },
];

const applicationAssignedChoices = [
  { value: true, label: "Assigned" },
  { value: false, label: "Unassigned" },
];

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const ApplicationAdvancedFilter = ({
  total,
  searchParams,
  onSearch,
  initialValues,
  renderFilterInfo,
  applicationStatusChoices,
  hasPermissionIPAExportExcelDocument,
  hasPermissionIPAExportAgendaExcelDocument,
  hasPermissionPatientsView,
  hasPermissionIPAApplicationInDraftView,
  orgUnitId,
}) => {
  const [searchTags, setSearchTags] = useState(null);

  const dispatch = useDispatch();
  const presetFilter = useSelector(state => getApplicationWorklistFilter(state));
  const orgUnit = useSelector(getSignedInOrgUnit);
  const searchPlaceholder = hasPermissionPatientsView ? process.env.REACT_APP_IPA_WORKLIST_SEARCH_PLACEHOLDER : "Search #IPA";
  const prevApplicationStatusChoices = usePrevious(applicationStatusChoices);
  const selectedFilter = isObject(presetFilter) ? get(presetFilter, "value", "") : presetFilter;

  const updateSearchTags = (filterParams, currentFilter = selectedFilter) => {
    let statusTags = null;
    const {
      status,
      applicationSpecialty,
      requestedBy,
      endorser,
      medication,
      indication,
      applicant,
      // dates
      requestedStartDate,
      requestedEndDate,
      reportReceivedStartDate,
      reportReceivedEndDate,
      meetingStartDate,
      meetingEndDate,
      reportStartDate,
      reportEndDate,
      sortBy,
      hasProvisionalApproval,
      sortOrderDescending,
      prescriptionStartDate,
      prescriptionEndDate,
      assignedTo,
      isAssigned,
    } = filterParams;

    if (!isEmpty(status)) {
      statusTags = `${map(status, s => {
        if (isEmpty(s)) {
          return "";
        }
        if (isString(s)) {
          // when preset filter changes, it will only return value, so we need to get its display label
          return ` ${get(find(applicationStatusChoices, x => x.value === s), "label", "")}`;
        }
        return ` ${s.label}`;
      })}`;
    }

    const sortByDateLabel = sortBy ? find(sortOptions, x => x.value === sortBy).label : null;

    const generateIsAssignedTag = () => {
      if (assignedTo) {
        return `Assigned To: ${assignedTo.label}`;
      }

      return isAssigned ? "Assigned Application" : "Unassigned Application";
    };

    const tags = [
      currentFilter !== "all" && statusTags,
      applicationSpecialty && `Specialty: ${applicationSpecialty}`,
      requestedBy && `Requested By: ${requestedBy.label}`,
      endorser && `Endorser: ${endorser.label}`,
      medication && `Medication: ${medication}`,
      indication && `Indication: ${indication}`,
      applicant && `Applicant: ${applicant}`,
      requestedStartDate && requestedEndDate && `Requested From ${requestedStartDate} to ${requestedEndDate}`,
      meetingStartDate && meetingEndDate && `Meeting From ${meetingStartDate} to ${meetingEndDate}`,
      reportStartDate && reportEndDate && `Report Back Due Date From ${reportStartDate} to ${reportEndDate}`,
      reportReceivedStartDate && reportReceivedEndDate && `Report Back Submitted Date From ${reportReceivedStartDate} to ${reportReceivedEndDate}`,
      prescriptionStartDate && prescriptionEndDate && `Prescription Presentation Date From ${prescriptionStartDate} to ${prescriptionEndDate}`,
      `${sortByDateLabel} ${sortOrderDescending ? "\u2193" : "\u2191"}`,
      hasProvisionalApproval && (hasProvisionalApproval === true ? "Provisional Approval" : "Without Provisional Approval"),
      isAssigned != null && generateIsAssignedTag(),
    ];

    setSearchTags(tags);
  };

  useEffect(() => {
    if (!isEqual(prevApplicationStatusChoices, applicationStatusChoices)) {
      const transformedStatus = map(searchParams.status, x => find(applicationStatusChoices, a => a.value === x));
      updateSearchTags({ ...searchParams, status: transformedStatus });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicationStatusChoices]); // only run when init

  const handleSubmit = ({ applicationFilters }) => {
    const {
      requestedBy,
      requestedStartDate,
      requestedEndDate,
      endorser,
      meetingStartDate,
      meetingEndDate,
      reportStartDate,
      reportEndDate,
      reportReceivedStartDate,
      reportReceivedEndDate,
      applicationSpecialty,
      hasProvisionalApproval,
      appealStatus,
      prescriptionStartDate,
      prescriptionEndDate,
      assignedTo,
      ...other
    } = applicationFilters;

    const newApplicationFilters = {
      ...other,
      requestedStartDate: formatFilterDate(requestedStartDate),
      requestedEndDate: formatFilterDate(requestedEndDate),
      meetingStartDate: formatFilterDate(meetingStartDate),
      meetingEndDate: formatFilterDate(meetingEndDate),
      reportStartDate: formatFilterDate(reportStartDate),
      reportEndDate: formatFilterDate(reportEndDate),
      reportReceivedStartDate: formatFilterDate(reportReceivedStartDate),
      reportReceivedEndDate: formatFilterDate(reportReceivedEndDate),
      requestedById: get(requestedBy, "value", null),
      endorserId: get(endorser, "value", null),
      assignedToUserId: get(assignedTo, "value", null),
      prescriptionStartDate: formatFilterDate(prescriptionStartDate),
      prescriptionEndDate: formatFilterDate(prescriptionEndDate),
      hasProvisionalApproval,
      appealStatus,
    };

    let applicationSpecialtyId = applicationSpecialty;
    if (applicationSpecialty && isObject(applicationSpecialty)) {
      applicationSpecialtyId = applicationSpecialty.value;
    }

    const applicationSpecialtyLabel = get(applicationSpecialty, "label", null);

    const newParams = { ...searchParams, ...newApplicationFilters, applicationSpecialtyId };

    updateSearchTags({
      ...applicationFilters,
      requestedStartDate: formatFilterDate(requestedStartDate, "DD-MMM-YYYY"),
      requestedEndDate: formatFilterDate(requestedEndDate, "DD-MMM-YYYY"),
      meetingStartDate: formatFilterDate(meetingStartDate, "DD-MMM-YYYY"),
      meetingEndDate: formatFilterDate(meetingEndDate, "DD-MMM-YYYY"),
      reportStartDate: formatFilterDate(reportStartDate, "DD-MMM-YYYY"),
      reportEndDate: formatFilterDate(reportEndDate, "DD-MMM-YYYY"),
      reportReceivedStartDate: formatFilterDate(reportReceivedStartDate, "DD-MMM-YYYY"),
      reportReceivedEndDate: formatFilterDate(reportReceivedEndDate, "DD-MMM-YYYY"),
      prescriptionStartDate: formatFilterDate(prescriptionStartDate, "DD-MMM-YYYY"),
      prescriptionEndDate: formatFilterDate(prescriptionEndDate, "DD-MMM-YYYY"),
      applicationSpecialty: applicationSpecialtyLabel,
    }, "advancedFilter");
    dispatch(setApplicationWorkListFilter("advancedFilter"));
    onSearch(newParams, null, null, true);
  };

  const handleReset = () => {
    const defaultFilter = getPresetFilterParams(defaultFilterOption);
    updateSearchTags(defaultFilter, get(defaultFilterOption, "value", null));
    dispatch(setApplicationWorkListFilter(defaultFilterOption)).then(onSearch(defaultFilter, null, null, true));
  };

  const onSearchText = searchTerm => {
    onSearch({ ...searchParams, term: searchTerm }, null, null, true);
  };

  const onUpdateFilter = filterValue => {
    dispatch(setApplicationWorkListFilter(filterValue));
    const newParams = {
      ...getPresetFilterParams(filterValue),
      term: searchParams.term,
    };
    updateSearchTags(newParams, filterValue);
    onSearch(newParams, null, null, true);
  };

  const handleExport = apiCall => {
    let { status } = searchParams;
    const { applicationSpecialty, endorser, requestedBy, assignedTo } = searchParams;
    let applicationSpecialtyId = null;
    let endorserId = null;
    let requestedById = null;
    let assignedToUserId = null;

    if (status) {
      status = map(status, x => (isString(x) ? x : x.value));
    }

    if (applicationSpecialty) {
      applicationSpecialtyId = applicationSpecialty.value;
    }

    if (endorser) {
      endorserId = endorser.value;
    }

    if (requestedBy) {
      requestedById = requestedBy.value;
    }

    if (assignedTo) {
      assignedToUserId = assignedTo.value;
    }

    const params = formatSearchParams({ ...searchParams, ...status, applicationSpecialtyId, endorserId, requestedById, assignedToUserId });
    const timeZone = moment.tz.guess();
    return downloadFromApi(`${apiCall}?${params || searchParams}&timeZone=${timeZone}`);
  };

  const extraActions = [
    hasPermissionIPAExportExcelDocument && { label: "Standard Export", icon: <ExcelExportIcon />, onClick: () => handleExport(`api/applications/${orgUnitId}/createExcelDocument`) },
    hasPermissionIPAExportAgendaExcelDocument && { label: "Agenda Export", icon: <ExcelExportIcon />, onClick: () => handleExport(`api/applications/${orgUnitId}/createAgendaExcelDocument`) },
  ];

  return (
    <AdvancedFilter
      renderFilterInfo={renderFilterInfo}
      onSearch={searchText => onSearchText(searchText)}
      searchPlaceholder={searchPlaceholder}
      searchValue={searchParams?.term}
      renderFilterTags={<FilterTags tags={searchTags} />}
      renderExtraActions={total > 0 && extraActions}
      renderPresetFilter={({ openAdvancedFilter }) => (
        <div className="flex-1 mb-8">
          <DefaultSelectionFilter
            filterValue={presetFilter}
            onChange={
              filterValue => {
                if (filterValue && presetFilter !== filterValue) {
                  if (filterValue === "advancedFilter") {
                    openAdvancedFilter();
                  } else {
                    onUpdateFilter(filterValue);
                  }
                }
              }
            }
            options={filter(filterOptions, x => (hasPermissionIPAApplicationInDraftView || x.value !== "Draft"))}
          />
        </div>
      )}
      content={onClose => (
        <FilterForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          onClose={onClose}
          onReset={handleReset}
          filters={({ setFieldValue, values }) => (
            <div className="advanced-filter-sections">
              <FilterSection withDivider>
                <Field
                  label={searchPlaceholder}
                  name="applicationFilters.term"
                  component={TextInput}
                  icon="search"
                />
              </FilterSection>
              <FilterSection withDivider label="Sort By">
                <Field
                  name="applicationFilters.sortBy"
                  icon={values.applicationFilters.sortBy.icon}
                  component={SelectInput}
                  options={sortOptions}
                  inputProps={{ isClearable: false }}
                  renderExtraAction={(
                    <Tooltip title={values.applicationFilters.sortOrderDescending ? "Sort Descending" : "Sort Ascending"}>
                      <IconButton
                        aria-label="Sort"
                        onClick={() => {
                          setFieldValue("applicationFilters.sortOrderDescending", !values.applicationFilters.sortOrderDescending);
                        }}
                      >
                        {values.applicationFilters.sortOrderDescending ? <DescendingIcon /> : <AscendingIcon />}
                      </IconButton>
                    </Tooltip>
                  )}
                  onChange={option => {
                    setFieldValue("applicationFilters.sortOrderDescending", option.sortOrderDescending);
                  }}
                />
              </FilterSection>
              <FilterSection withDivider>
                <Field
                  label="Medication"
                  name="applicationFilters.medication"
                  component={TextInput}
                  icon={<PillIcon />}
                />
                <Field
                  label="Indication"
                  name="applicationFilters.indication"
                  component={TextInput}
                  icon={<CursorPointerIcon />}
                />
                <Field
                  label="Applicant"
                  name="applicationFilters.applicant"
                  component={TextInput}
                  icon="person"
                />
              </FilterSection>
              <FilterSection withDivider>
                <Field
                  label="Specialty"
                  name="applicationFilters.applicationSpecialty"
                  component={SpecialtySelector}
                  orgUnitId={orgUnit.id}
                />
                <Field
                  label="Requested by"
                  name="applicationFilters.requestedBy"
                  component={UserSelector}
                  orgUnitId={orgUnitId}
                  includeInactive
                />
                <Field
                  label="Endorser"
                  name="applicationFilters.endorser"
                  component={UserSelector}
                  orgUnitId={orgUnitId}
                  includeInactive
                />
              </FilterSection>
              <FilterSection withDivider label="Provisional Approval">
                <Field
                  name="applicationFilters.hasProvisionalApproval"
                  component={RadioButtonsGroup}
                  choices={provisionalApprovalChoices}
                  isBoolean
                  allowUnCheck
                  spacing={0}
                />
              </FilterSection>
              <FilterSection withDivider label="Application Assignment">
                <Field
                  name="applicationFilters.isAssigned"
                  component={RadioButtonsGroup}
                  choices={applicationAssignedChoices}
                  allowUnCheck
                  isBoolean
                  spacing={0}
                  onChange={value => {
                    if (value !== true) {
                      setFieldValue("applicationFilters.assignedTo", null);
                    }
                  }}
                />
                {values.applicationFilters.isAssigned
                  && (
                  <Field
                    label="AssignedTo"
                    name="applicationFilters.assignedTo"
                    component={UserSelector}
                    orgUnitId={orgUnitId}
                    includeInactive
                  />
                  )}
              </FilterSection>
              <FilterSection withDivider label="Appeal Status">
                <Field
                  name="applicationFilters.appealStatus"
                  component={RadioButtonsGroup}
                  choices={appealStatusChoices}
                  isBoolean
                  allowUnCheck
                  spacing={0}
                />
              </FilterSection>
              <FilterSection label="Status" withDivider>
                <Field
                  name="applicationFilters.status"
                  component={MultipleCheckbox}
                  options={applicationStatusChoices}
                />
              </FilterSection>
              <FilterSection helperText={isIE ? "Select a start date and end date from the calender" : null} classes={{ content: "p-0" }} helperTextPosition="top">
                <FilterSection withDivider label="Submitted Date">
                  <Field
                    name="applicationFilters.requestedDateRange"
                    component={DateRange}
                    onChange={value => {
                      if (value) {
                        setFieldValue("applicationFilters.requestedStartDate", value[0]);
                        setFieldValue("applicationFilters.requestedEndDate", value[1]);
                      } else {
                        setFieldValue("applicationFilters.requestedStartDate", null);
                        setFieldValue("applicationFilters.requestedEndDate", null);
                      }
                    }}
                  />
                </FilterSection>
                <FilterSection label="Meeting Date" withDivider>
                  <Field
                    name="applicationFilters.meetingDateRange"
                    component={DateRange}
                    onChange={value => {
                      if (value) {
                        setFieldValue("applicationFilters.meetingStartDate", value[0]);
                        setFieldValue("applicationFilters.meetingEndDate", value[1]);
                      } else {
                        setFieldValue("applicationFilters.meetingStartDate", null);
                        setFieldValue("applicationFilters.meetingEndDate", null);
                      }
                    }}
                  />
                </FilterSection>
                <FilterSection label="Report Back Due Date">
                  <Field
                    name="applicationFilters.reportDateRange"
                    component={DateRange}
                    onChange={value => {
                      if (value) {
                        setFieldValue("applicationFilters.reportStartDate", value[0]);
                        setFieldValue("applicationFilters.reportEndDate", value[1]);
                      } else {
                        setFieldValue("applicationFilters.reportStartDate", null);
                        setFieldValue("applicationFilters.reportEndDate", null);
                      }
                    }}
                  />
                </FilterSection>
                <FilterSection label="Report Back Submitted Date" withDivider>
                  <Field
                    name="applicationFilters.reportReceivedDateRange"
                    component={DateRange}
                    onChange={value => {
                      if (value) {
                        setFieldValue("applicationFilters.reportReceivedStartDate", value[0]);
                        setFieldValue("applicationFilters.reportReceivedEndDate", value[1]);
                      } else {
                        setFieldValue("applicationFilters.reportReceivedStartDate", null);
                        setFieldValue("applicationFilters.reportReceivedEndDate", null);
                      }
                    }}
                  />
                </FilterSection>
                <FilterSection label="Prescription Presentation Date">
                  <Field
                    name="applicationFilters.prescriptionDateRange"
                    component={DateRange}
                    onChange={value => {
                      if (value) {
                        setFieldValue("applicationFilters.prescriptionStartDate", value[0]);
                        setFieldValue("applicationFilters.prescriptionEndDate", value[1]);
                      } else {
                        setFieldValue("applicationFilters.prescriptionStartDate", null);
                        setFieldValue("applicationFilters.prescriptionEndDate", null);
                      }
                    }}
                  />
                </FilterSection>
              </FilterSection>
            </div>
          )}
        />
      )}
    />
  );
};

export default withPermissions(
  "IPAExportExcelDocument",
  "IPAExportAgendaExcelDocument",
  "PatientsView",
  "IPAApplicationInDraftView",
)(ApplicationAdvancedFilter);
