import { map } from "lodash";
import formatFilterDate from "helpers/format-filter-date";

export default function translateReferralSearchParams(params) {
  let { assignedToSpecialtyIds, assignedToUserId, assignedToRoleIds, assignedToSubSpecialtyIds, taskAssignedToUserId, taskAssignedToRoleIds, createdByUserId, assignedToSpecialistId, includeAllSites } = params;
  const { status, category, startDate, endDate, unreadAddendum, isUrgent, applyUrgentReferralFirst, sortBy, sortOrderDescending, onlyShowUnassigned, taskStatus, term,
    daysSinceLastStatusChange, isReferrerUrgent, presentingComplaintCodes, referrerAdvisedPriorityCodeIds, onlyUnassignedReferrerAdvisedPriority, referringOrgUnitIds, referringLocationCodeIds,
    reasonForReferralCodeIds, typeOfReferralCodeIds } = params;

  assignedToSpecialtyIds = assignedToSpecialtyIds?.value ?? assignedToSpecialtyIds;
  assignedToUserId = assignedToUserId?.value ?? assignedToUserId;
  assignedToRoleIds = assignedToRoleIds?.value ?? assignedToRoleIds;
  assignedToSubSpecialtyIds = assignedToSubSpecialtyIds?.value ?? assignedToSubSpecialtyIds;
  taskAssignedToUserId = taskAssignedToUserId?.value ?? taskAssignedToUserId;
  taskAssignedToRoleIds = map(taskAssignedToRoleIds, x => x?.value ?? x);

  createdByUserId = createdByUserId?.value ?? createdByUserId;
  assignedToSpecialistId = assignedToSpecialistId?.value ?? assignedToSpecialistId;
  includeAllSites = includeAllSites?.value ?? includeAllSites;

  return ({
    status,
    category,
    startDate: formatFilterDate(startDate),
    endDate: formatFilterDate(endDate),
    applyUrgentReferralFirst,
    sortBy,
    sortOrderDescending,
    unreadAddendum,
    isUrgent,
    onlyShowUnassigned,
    assignedToSpecialtyIds,
    assignedToUserId,
    assignedToRoleIds,
    daysSinceLastStatusChange,
    assignedToSubSpecialtyIds,
    taskStatus,
    taskAssignedToUserId,
    taskAssignedToRoleIds,
    createdByUserId,
    term,
    assignedToSpecialistId,
    isReferrerUrgent,
    referringOrgUnitIds,
    referringLocationCodeIds,
    reasonForReferralCodeIds,
    typeOfReferralCodeIds,
    presentingComplaintCodes,
    referrerAdvisedPriorityCodeIds,
    onlyUnassignedReferrerAdvisedPriority,
    includeAllSites,
  });
}
