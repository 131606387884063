import React, { useState, useEffect, useRef, useCallback } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { get, split, includes } from "lodash";
import { ButtonBase, Icon, IconButton, Tooltip } from "@material-ui/core";
import BaseTextInput from "components/inputs/base-text-input";

const useStyles = makeStyles(theme => ({
  dragging: {
    border: "2px dashed #CCCCCC",
    padding: theme.spacing(1),
  },
}));

const AttachmentUploadField = ({
  form: { touched, errors },
  field: { name, value },
  onChange,
  onRemove,
  loading,
  disabled,
  defaultValue,
  acceptFormat,
  ...other
}) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragCount, setDragCount] = useState(0);
  const fileUploadRef = useRef(null);
  const classes = useStyles();

  const handleDragEnter = e => {
    e.preventDefault();
    setDragCount(dragCount + 1);
  };

  const handleDragLeave = e => {
    e.preventDefault();
    setDragCount(dragCount - 1);
  };

  const handleDrop = e => {
    e.preventDefault();
    setDragCount(0);
    const file = e.dataTransfer.files[0];
    onChange(file);
  };

  const handleInputChange = e => {
    const file = e.target.files[0];
    onChange(file);
  };

  const handlePaste = e => {
    if (e.clipboardData && e.clipboardData.files && e.clipboardData.files.length) {
      e.preventDefault();
      const file = e.clipboardData.files[0];
      const acceptFormats = split(acceptFormat, ",");
      if (includes(acceptFormats, file?.type)) {
        onChange(file);
      }
    }
  };

  const handleClick = useCallback(() => {
    fileUploadRef.current.click();
  }, []);

  useEffect(() => {
    document.addEventListener("paste", handlePaste);
    return () => {
      document.removeEventListener("paste", handlePaste);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsDragging(dragCount > 0);
  }, [dragCount]);

  let fieldError = get(errors, name, null);
  const serverError = get(other.status, "apiErrors", null);
  if (serverError) {
    fieldError = serverError;
  }

  return (
    <>
      <div
        className={clsx("flex file-upload", isDragging && classes.dragging)}
        onDragEnter={handleDragEnter}
        onDragOver={e => e.preventDefault()}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <ButtonBase disabled={loading || disabled} className="w-full" disableRipple onClick={handleClick}>
          <BaseTextInput
            loading={loading}
            className="cursor-pointer flex-1"
            icon={other.icon}
            label={other.label}
            error={fieldError}
            touched={get(touched, name, false)}
            value={value || defaultValue || ""}
            renderExtraAction={(
              <Tooltip title="Choose a file">
                <IconButton component="label" disabled={loading || disabled} edge="end">
                  <Icon>more_horiz</Icon>
                </IconButton>
              </Tooltip>
            )}
            {...other}
          />
          <input
            type="file"
            capture="camera"
            ref={fileUploadRef}
            accept={acceptFormat}
            style={{ display: "none" }}
            onChange={handleInputChange}
          />
        </ButtonBase>
        {onRemove && (value || defaultValue) && (
          <div className="flex items-center justify-end">
            <Tooltip title="Clear" className="ml-16">
              <IconButton component="label" disabled={loading || disabled} onClick={onRemove}>
                <Icon fontSize="small">clear</Icon>
              </IconButton>
            </Tooltip>
          </div>
        )}
      </div>
    </>
  );
};

export default AttachmentUploadField;

