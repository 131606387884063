import React from "react";
import { Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { isEmpty } from "lodash";

import Form from "components/form";
import { TextAreaInput } from "components/inputs";
import { onMarkAsEndorsedRequest } from "app/main/applications/actions/applications.actions";
import { getHelperTextBySectionName } from "app/auth/store/reducers/system-configuration";

const markAsEndorsedReasonLabel = "Reason";

const schema = Yup.object().shape({
  endorsement: Yup.object().shape({
    markAsEndorsedReason: Yup.string()
      .required(`${markAsEndorsedReasonLabel} is required`)
      .nullable(),
  }),
});

const MarkAsEndorsedForm = props => {
  const { applicationId, orgUnitId, ...other } = props;
  const dispatch = useDispatch();
  const helperText = useSelector(state => getHelperTextBySectionName(state, "endorsements"));

  let { endorsement } = schema.cast();

  if (!isEmpty(props.endorsement)) {
    endorsement = {
      ...props.endorsement,
    };
  }

  const handleSubmit = (value, { setErrors }) => {
    const { id, markAsEndorsedReason } = value.endorsement;

    const newEndorsement = {
      id,
      markAsEndorsedReason,
    };

    dispatch(onMarkAsEndorsedRequest(applicationId, orgUnitId, newEndorsement)).then(response => {
      if (response.error !== true) {
        props.onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  return (
    <Form
      initialValues={{ endorsement }}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      content={() => (
        <>
          <Field
            name="endorsement.markAsEndorsedReason"
            component={TextAreaInput}
            label={markAsEndorsedReasonLabel}
            helperText={helperText.markAsEndorsedReason}
            icon="person"
          />
        </>
      )}
    />
  );
};

export default MarkAsEndorsedForm;
