import React, { useEffect } from "react";
import { connect } from "react-redux";

import { setReferralStatusUpdated } from "app/main/referrals/actions/signalr.actions";
import { updateReportExportProgress } from "app/main/reportExport/actions/signalr.actions";
import { getUser } from "app/auth/store/reducers/user.reducer";
import { showMessage } from "app/store/actions/message.actions";
import { isSignalREnabled } from "utils/get-environment-variables";

import SignalrConnector, { SignalRMessageTypes } from "signalr-connection";

const SignalrHandler = props => {
  const showErrorMessage = message => props.showMessage({
    message,
    variant: "error",
  });
  const signalREnabled = isSignalREnabled();

  let addMessageHandler = null;
  let removeMessageHandler = null;

  if (signalREnabled) {
    ({ addMessageHandler, removeMessageHandler } = SignalrConnector(props.currentUser, showErrorMessage));
  }
  useEffect(() => {
    if (signalREnabled) {
      // Receive SignalR message
      addMessageHandler(SignalRMessageTypes.REFERRAL_STATUS_CHANGED, props.setReferralStatusUpdated);
      addMessageHandler(SignalRMessageTypes.REPORT_EXPORT_PROGRESS_UPDATE, props.updateReportExportProgress);
      return () => {
        removeMessageHandler(SignalRMessageTypes.REFERRAL_STATUS_CHANGED, props.setReferralStatusUpdated);
        removeMessageHandler(SignalRMessageTypes.REPORT_EXPORT_PROGRESS_UPDATE, props.updateReportExportProgress);
      };
    }
    return () => {};
  });

  return (
    <>
      {props.children}
    </>
  );
};

export default connect(state => ({ currentUser: getUser(state) }), { setReferralStatusUpdated, updateReportExportProgress, showMessage })(SignalrHandler);
