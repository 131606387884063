import React from "react";
import { Divider, Typography } from "@material-ui/core";

export default ({ withDivider, title, children, classes }) => (
  <div className={classes?.root}>
    {title && <Typography className="mx-32 mb-16 font-bold" color="textSecondary">{title}</Typography>}
    <div className="items-container">
      {children}
    </div>
    {withDivider && <div className="py-8"><Divider /></div>}
  </div>
);
