import useFormFieldConfig from "hooks/use-form-field-config";

// load current orgUnit form field settings - 'TriageForm';
const useTriageFieldSettings = (orgUnitId, forceLoad) => {
  const { loadingFieldSettings, fieldSettings, loadedFieldSettings } = useFormFieldConfig(orgUnitId, "TriageForm", forceLoad);

  const fieldLabels = {
    referrerAdvisedPriority: fieldSettings?.referrerAdvisedPriority?.label || "Referrer Advised Priority",
    isUrgent: fieldSettings?.isUrgent?.label || "URGENT",
    episodeHealthCondition: fieldSettings?.episodeHealthCondition?.label || "Episode Health Conditions",
    note: fieldSettings?.note?.label || "Message",
    clinic: fieldSettings?.clinic?.label || "Clinic",
    clinicType: fieldSettings?.clinicType?.label || "Clinic Type",
    reasonForReferralCode: fieldSettings?.reasonForReferralCode?.label || "Reason For Referral Code",
    reasonNote: fieldSettings?.reasonNote?.label || "Reason Note",
    claimType: fieldSettings?.claimType?.label || "Claim Type",
    externalTriageStatus: fieldSettings?.externalTriageStatus?.label || "Triage Status",
  };

  return ({
    loadingFieldSettings,
    fieldSettings,
    fieldLabels,
    loadedFieldSettings,
  });
};

export default useTriageFieldSettings;
