import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { map, find } from "lodash";

import { NoteInput, SelectInput } from "components/inputs";
import { addCorrespondenceAttachment } from "app/main/referrals/actions/referrals.actions";
import Form from "components/form";
import { fetchGPLetterTemplateAttachments } from "app/main/orgUnits/actions/orgUnit.actions";
import { getEnableSendGPLetterAdditionalMessage } from "app/auth/store/reducers/system-configuration";

const messageLabel = "Additional Message (optional)";
const typeLabel = "Letter Type";

const schema = Yup.object().shape({
  correspondenceLetter: Yup.object().shape({
    templateAttachment: Yup.string().required(`${typeLabel} is required`).nullable(),
    message: Yup.string().max(4000, "Too long!").nullable(),
  }),
});

const ReferralCorrespondenceForm = ({
  referralId,
  orgUnitId,
  onSucceed,
  ...other
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const enableAdditionalMessage = useSelector(getEnableSendGPLetterAdditionalMessage);

  useEffect(() => {
    if (orgUnitId) {
      dispatch(fetchGPLetterTemplateAttachments(orgUnitId)).then(res => {
        if (res.payload?.templates) {
          const loadedOptions = map(res.payload.templates, x => ({ value: x.id, label: x.displayName, gpLetterTemplateType: x.gpLetterTemplateType }));
          setOptions(loadedOptions);
        }
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = ({ correspondenceLetter }, { setSubmitting, setErrors }) => {
    const submitData = {
      referralId,
      orgUnitId,
      subject: correspondenceLetter.subject,
      message: correspondenceLetter.message,
      letterTemplateType: correspondenceLetter.letterTemplateType,
    };

    dispatch(addCorrespondenceAttachment(orgUnitId, submitData)).then(response => {
      setSubmitting(false);
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  const initialValues = schema.cast();

  return (
    <Form
      onSubmit={handleSubmit}
      contentProps={other}
      initialValues={initialValues}
      validationSchema={schema}
      variant="filled"
      content={({ setFieldValue }) => (
        <>
          <Field
            name="correspondenceLetter.templateAttachment"
            component={SelectInput}
            label="Type"
            icon="mail_outlined"
            options={options}
            onChange={val => {
              if (!val) return;
              const selectedOption = find(options, x => x.value === val);
              setFieldValue("correspondenceLetter.subject", selectedOption?.label);
              setFieldValue("correspondenceLetter.letterTemplateType", selectedOption?.gpLetterTemplateType);
            }}
          />
          {enableAdditionalMessage
            && (
            <Field
              name="correspondenceLetter.message"
              component={NoteInput}
              label={messageLabel}
            />
            )}
        </>
      )}
    />
  );
};

export default ReferralCorrespondenceForm;
