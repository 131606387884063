/* eslint-disable react/no-danger */
import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Alert } from "@material-ui/lab";
import { useDispatch } from "react-redux";
import { includes, isEmpty } from "lodash";
import { CardMedia } from "@material-ui/core";
import AsyncComponent from "components/async-component";
import { EmptyStateComponent } from "components/items/pdf-preview";
import LoadingState from "components/items/loading-state";
import { uploadReferralSourceDocumentFile } from "app/main/referrals/actions/referrals.actions";
import DropzoneAttachment from "components/dropzoneAttachment";
import LoadingDialog from "components/loading-dialog";

const PDFPreview = AsyncComponent(() => import("components/items/pdf-preview"));

const useStyles = makeStyles(theme => ({
  helperText: {
    color: theme.palette.text.hint,
    display: "flex",
    minHeight: 20,
  },
  contentContainer: {
    flexDirection: "column",
    display: "flex",
    height: "100%",
  },
}));

const ReferralSourceDocumentPreview = ({
  hasPreview,
  fileData,
  mimeType,
  pdfProps,
  imageProps,
  htmlProps,
  loading,
  orgUnitId,
  acceptFormat,
  onFileUploaded,
  specialtyId,
  disabled = false,
  ocrError = null,
  ocrSuccess = null,
  ocrProcessing = false,
  label,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [isReferralSourceDocumentFileUpLoading, setIsReferralSourceDocumentFileUpLoading] = useState(false);
  const [error, setError] = useState(ocrError);
  const [success, setSuccess] = useState(ocrSuccess);

  useEffect(() => {
    setError(ocrError);
    setSuccess(ocrSuccess);
  }, [ocrError, ocrSuccess]);

  const onFileUpload = (file, selectedSpecialtyId) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setIsReferralSourceDocumentFileUpLoading(true);
      reader.onload = () => {
        const formData = new FormData();
        formData.append("file", file);
        setSuccess("");
        dispatch(uploadReferralSourceDocumentFile(orgUnitId, formData, selectedSpecialtyId))
          .then(response => {
            const apiErrors = response.payload?.exceptionMessage ?? response.payload.message;
            if (response.error === true) {
              setSuccess("");
              setError(apiErrors);
            } else {
              setError(null);
              if (onFileUploaded) {
                onFileUploaded(response.payload);
              }
            }
          })
          .finally(() => setIsReferralSourceDocumentFileUpLoading(false));
      };
    }
  };

  if (loading) {
    return <LoadingState />;
  }

  if (!fileData) {
    return (
      <div className={classes.contentContainer}>
        {!isEmpty(error) && <Alert severity="error">{error}</Alert>}
        {!isEmpty(success) && <Alert severity="success">{success}</Alert>}
        <DropzoneAttachment label={label} acceptFormat={acceptFormat} extraParameters={specialtyId} onFileUpload={onFileUpload} loading={isReferralSourceDocumentFileUpLoading || ocrProcessing} disabled={disabled} />
      </div>
    );
  }

  if (hasPreview) {
    // render preview
    const base64Data = `data:${mimeType};base64,${fileData}`;
    const isPdf = includes(mimeType, "pdf");
    const isHtml = includes(mimeType, "html") || includes(mimeType, "plain") || includes(mimeType, "rtf");

    return (
      <div className={classes.contentContainer}>
        {!isEmpty(error) && <Alert severity="error">{error}</Alert>}
        {!isEmpty(success) && <Alert severity="success">{success}</Alert>}
        {isPdf && <PDFPreview file={base64Data} {...pdfProps} />}
        {isHtml && <div dangerouslySetInnerHTML={{ __html: fileData }} {...htmlProps} />}
        {!isPdf && !isHtml && <CardMedia image={base64Data} {...imageProps} />}
        <LoadingDialog open={ocrProcessing} loadingMessage="Processing..." />
      </div>
    );
  }

  return <EmptyStateComponent />;
};

export default ReferralSourceDocumentPreview;
