import React from "react";
import { Field } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty, find, get } from "lodash";
import * as Yup from "yup";
import moment from "moment";
import { Collapse } from "@material-ui/core";

import HospitalBuildingIcon from "mdi-react/HospitalBuildingIcon";
import SmartCardOutlineIcon from "mdi-react/SmartCardOutlineIcon";
import { SpecialtyIcon } from "helpers/icon-finder";

import Form from "components/form";
import { TextInput } from "components/inputs";
import DateOfBirthInput from "components/inputs/date-of-birth";
import { onSaveOrUpdatePatient, onSavePatient } from "app/main/applications/actions/applications.actions";
import getPatientIdentifierLabel from "app/main/patients/helpers/get-patient-identifier-label";
import { getDefaultAddressState, getPrimaryPatientIdentifierSettings } from "app/auth/store/reducers/system-configuration";
import { getCurrentApplication } from "app/main/applications/reducers/applications.reducers";
import { CollectionLocationSelector, PatientSettingSelector } from "./patient-application-form-selectors";
import "utils/yup-validation";

const givenNameLabel = "Given Name";
const familyNameLabel = "Family Name";
const birthDateLabel = "DOB";
const collectionLocationLabel = "Pharmacy Location";
const patientSettingLabel = "Patient Setting";
const streetLabel = "Street (optional)";
const suburbLabel = "City / Suburb";
const postcodeLabel = "Postcode";
const stateLabel = "State";
const today = moment().startOf("day");

const PatientApplicationForm = ({
  onSucceed,
  patient: currentPatient,
  orgUnitId,
  ...other
}) => {
  const dispatch = useDispatch();
  const currentApplication = useSelector(state => getCurrentApplication(state));
  const defaultState = useSelector(state => getDefaultAddressState(state));

  const primaryPatientIdentifierSettings = useSelector(getPrimaryPatientIdentifierSettings);
  const primaryPatientIdentifier = primaryPatientIdentifierSettings?.type;
  const primaryPatientIdentifierMinLength = primaryPatientIdentifierSettings?.minLength;
  const primaryPatientIdentifierMaxLength = primaryPatientIdentifierSettings?.maxLength;
  const primaryPatientIdentifierLabel = find(primaryPatientIdentifierSettings?.options, x => x.value === primaryPatientIdentifier)?.label ?? getPatientIdentifierLabel(primaryPatientIdentifier);
  const numericIdentifiersOnly = primaryPatientIdentifierSettings?.numericIdentifiersOnly;

  const schema = Yup.object().shape({
    patient: Yup.object().shape({
      givenName: Yup.string()
        .max(100, "Too Long!")
        .trim().required(`${givenNameLabel} is required`),
      familyName: Yup.string()
        .max(100, "Too Long!")
        .trim().required(`${familyNameLabel} is required`),
      primaryPatientIdentifier: Yup.string()
        .validateIdentifier(primaryPatientIdentifierLabel, { min: primaryPatientIdentifierMinLength, max: primaryPatientIdentifierMaxLength, numberOnly: numericIdentifiersOnly })
        .trim().required(`${primaryPatientIdentifierLabel} is required`),
      birthDate: Yup.string()
        .test("is-invalid-date", "Invalid Date", value => value && moment(value, "YYYY-MM-DD").isValid())
        .test("is-future", `${birthDateLabel} can not be in the future`, value => value && !moment(value).isAfter(today))
        .required(`${birthDateLabel} is required`),
      collectionLocationId: Yup.string().required(`${collectionLocationLabel} is required.`).nullable(),
      setting: Yup.string().required(`${patientSettingLabel} is required.`).nullable(),
      street: Yup.string().nullable(),
      suburb: Yup.string().nullable(),
      postcode: Yup.string()
        .matches(/^\d+$/, { excludeEmptyString: true, message: "This does not look like a valid postcode." })
        .test("length", "This does not look like a valid postcode.", val => {
          if (!isEmpty(val) && val.length !== 4) {
            return false;
          }
          return true;
        })
        .nullable(),
      state: Yup.string().nullable(),
    }),
  });

  // incase we need to collapse the address section, otherwise the commented out code should be removed
  // const [showAddress, setShowAddress] = React.useState(get(currentPatient, "street", false));
  const applicationId = get(currentApplication, "id");

  const handleSubmit = ({ patient }, { setErrors }) => {
    let newPatient = patient;
    const setting = get(newPatient, ["setting", "value"], null);

    newPatient = {
      ...patient,
      collectionLocationId: patient.collectionLocationId?.value,
      setting,
      patientId: get(currentPatient, "patientId"),
      primaryPatientIdentifier: patient.primaryPatientIdentifier,
      orgUnitId,
    };

    const apiCall = applicationId ? onSaveOrUpdatePatient(orgUnitId, applicationId, newPatient) : onSavePatient(orgUnitId, newPatient);

    dispatch(apiCall).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  let { patient } = schema.cast();

  if (!isEmpty(currentPatient)) {
    const patientIdentifier = find(currentPatient.patientIdentifiers, x => x.patientIdentifierTypeCode === primaryPatientIdentifier);
    patient = {
      primaryPatientIdentifier: patientIdentifier?.identifier ?? "",
      givenName: currentPatient.givenName,
      familyName: currentPatient.familyNameUnformatted,
      birthDate: currentPatient.birthDate,
      collectionLocationId: get(currentApplication, ["collectionLocation", "id"]),
      setting: get(currentApplication, "setting"),
      street: currentPatient.street,
      suburb: currentPatient.suburb,
      postcode: currentPatient.postcode,
      state: currentPatient.state,
    };
  }

  return (
    <Form
      initialValues={{ patient }}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      content={({ values, setFieldValue }) => {
        const setDefaultState = val => {
          if (val && !values.patient.state) {
            setFieldValue("patient.state", defaultState);
          }
        };
        return (
          <>
            <div className="flex">
              <Field
                name="patient.primaryPatientIdentifier"
                component={TextInput}
                icon={<SmartCardOutlineIcon />}
                label={primaryPatientIdentifierLabel}
                className="flex-1"
                required
              />
              <div className="flex-1" />
            </div>
            <div className="flex">
              <Field
                name="patient.givenName"
                component={TextInput}
                icon="person"
                label={givenNameLabel}
                className="flex-1"
                required
              />
              <Field
                name="patient.familyName"
                component={TextInput}
                label={familyNameLabel}
                className="flex-1"
                required
              />
            </div>
            <Field
              name="patient.birthDate"
              component={DateOfBirthInput}
              label={birthDateLabel}
              required
            />
            <div>
              {/* <div>
                <IconButton edge="start" onClick={() => setShowAddress(!showAddress)}>
                  <Icon fontSize="small">{showAddress ? "expand_less" : "person_pin"}</Icon>
                </IconButton>
                <Typography variant="body-2" className="cursor-pointer" onClick={() => setShowAddress(!showAddress)}>Address</Typography>
              </div> */}
              {/* in={showAddress} */}
              <Collapse in timeout="auto" unmountOnExit>
                <div>
                  <Field
                    name="patient.street"
                    label={streetLabel}
                    component={TextInput}
                    icon="person_pin"
                    onChange={val => setDefaultState(val)}
                  />
                  <div className="flex">
                    <Field
                      name="patient.suburb"
                      label={suburbLabel}
                      component={TextInput}
                      onChange={val => setDefaultState(val)}
                      className="flex-1"
                    />
                    <Field
                      name="patient.postcode"
                      label={postcodeLabel}
                      component={TextInput}
                      onChange={val => setDefaultState(val)}
                      className="flex-1"
                    />
                    <Field
                      name="patient.state"
                      label={stateLabel}
                      component={TextInput}
                      className="flex-1"
                    />
                  </div>
                </div>
              </Collapse>
            </div>
            <Field
              name="patient.collectionLocationId"
              icon={<HospitalBuildingIcon />}
              component={CollectionLocationSelector}
              orgUnitId={orgUnitId}
              label={collectionLocationLabel}
              required
            />
            <Field
              name="patient.setting"
              component={PatientSettingSelector}
              label={patientSettingLabel}
              icon={values.patient.setting === "Outpatient" ? <SpecialtyIcon /> : "hotel"}
              required
            />
          </>
        );
      }}
    />
  );
};

export default PatientApplicationForm;
