import React, { useEffect, useState } from "react";
import { FormHelperText, FormControl, IconButton, Typography, InputLabel } from "@material-ui/core";
import clsx from "clsx";
import { get, isEmpty } from "lodash";
import checkIfEmpty from "helpers/check-if-empty";
import useOCRStyles from "components/ocr-components/styles";
import { AcceptIcon, ReEnterIcon } from "helpers/icon-finder";
import { green, red } from "@material-ui/core/colors";

const OCRBaseComponent = ({
  className,
  ocrValue,
  isValidFormat = true,
  requireValidFormat = false,
  formatValue,
  ocrLabel,
  confidenceLevelPercentage = 0,
  thresholdPercentage = 0,
  onChange,
  onBlur,
  inputComponent: Component,
  onHandleConfirmed,
  spacing = 0,
  confirmedOCR = false,
  showActionConfirm = true, // Display Accept and Re-enter button
  ...other
}) => {
  const displayOCRIndictor = confidenceLevelPercentage !== 0 && !isEmpty(ocrValue);
  const requireConfirm = confidenceLevelPercentage < thresholdPercentage;
  const [isConfirmed, setConfirmed] = useState(!requireConfirm);
  const classes = useOCRStyles({ requireConfirm });
  const { name, value } = other.field;
  const { touched, errors, setFieldValue, setFieldTouched } = other.form;
  const error = get(errors, name, null);
  const hasError = !checkIfEmpty(error);
  const valueWithRequiredFormat = isValidFormat ? formatValue : null;
  const valueWithNotRequiredFormat = formatValue ?? ocrValue;

  useEffect(() => {
    if (confirmedOCR) {
      setConfirmed(true);
    } else if (!showActionConfirm || !requireConfirm) {
      if (requireValidFormat) {
        setFieldValue(name, valueWithRequiredFormat);
        setConfirmed(true);
      } else {
        setFieldValue(name, valueWithNotRequiredFormat);
        setConfirmed(true);
      }
    } else {
      setConfirmed(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmedOCR, requireConfirm, showActionConfirm, requireValidFormat, valueWithRequiredFormat, valueWithNotRequiredFormat]);

  const handleConfirmed = isAccept => {
    if (onHandleConfirmed) {
      onHandleConfirmed(isAccept, setFieldValue);
    } else if (isAccept) {
      setFieldValue(name, requireValidFormat ? valueWithRequiredFormat : valueWithNotRequiredFormat);
    } else {
      setFieldValue(name, null);
    }
    setConfirmed(true);
  };

  const handleBlur = event => {
    setFieldTouched(name);

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleChange = event => {
    const val = event?.target?.value ?? event;
    if (onChange) {
      onChange(val, setFieldValue);
    } else {
      setFieldValue(name, val);
    }
  };

  return (
    <div
      className={clsx(classes.root, "flex flex-row", !other.textarea && "items-center", className)}
    >
      <FormControl
        className="flex-1"
        error={touched && hasError}
        required={other.required && checkIfEmpty(value)}
      >
        {(!displayOCRIndictor || isConfirmed || !showActionConfirm) && (
          <Component
            error={error}
            onBlur={handleBlur}
            onChange={handleChange}
            touched={get(touched, name, null)}
            value={value || ""}
            {...other}
          />
        )}
        {displayOCRIndictor && showActionConfirm && requireConfirm && !isConfirmed && (
          <div className={classes.content}>
            <InputLabel className={classes.contentLabel} shrink htmlFor={other.label}>{other.label}</InputLabel>
            <div className={classes.contentValue}>
              <Typography className="flex-1">{ocrValue}</Typography>
              <div className={clsx(classes.actions, `justify-end pt-${spacing}`)}>
                <IconButton title="Accept" disabled={other.readOnly} onClick={() => handleConfirmed(true)}>
                  <AcceptIcon color={green[600]} fontSize="large" />
                </IconButton>
                <IconButton title="Re-enter" disabled={other.readOnly} onClick={() => handleConfirmed(false)}>
                  <ReEnterIcon color={red[600]} fontSize="large" />
                </IconButton>
              </div>
            </div>
          </div>

        )}
        {displayOCRIndictor && (
          <FormHelperText
            error={false}
            className={clsx(
              classes.helperText,
              classes.threshold,
              `justify-end pt-${spacing}`,
            )}
          >
            {isConfirmed && (
              <span className={clsx(classes.ocrResult)}>
                {requireValidFormat && !isValidFormat && `Invalid Format ${ocrLabel ?? ocrValue} :`}
                {ocrValue !== value && formatValue !== value && isValidFormat ? `${ocrLabel ?? ocrValue} :` : ""}
              </span>
            )}
            {" OCR Confidence:"}
            <span className={isConfirmed ? clsx(classes.confirmedThresholdPercentage) : clsx(classes.thresholdPercentage)}>
              {confidenceLevelPercentage}%
            </span>
          </FormHelperText>
        )}
      </FormControl>
    </div>
  );
};

export default OCRBaseComponent;
