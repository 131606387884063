import React from "react";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { find, head, map, isEmpty } from "lodash";

import "utils/yup-validation";
import LoadingState from "components/items/loading-state";
import { getAllCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";
import { getEnabledTriageCategorySettings, getEpisodeHealthConditionMaxLength } from "app/auth/store/reducers/system-configuration";
import useTriageFieldSettings from "app/main/referrals/hooks/useTriageFieldSettings";

import AcceptReferralForm from "./form";

export default ({ referral, forceLoad, ...other }) => {
  const { loadingFieldSettings, fieldSettings, fieldLabels } = useTriageFieldSettings(referral?.orgUnitId, forceLoad);
  const codeSetValues = useSelector(getAllCodeSetValues);
  const triageCategories = useSelector(getEnabledTriageCategorySettings);
  const episodeHealthConditionMaxLength = useSelector(getEpisodeHealthConditionMaxLength);

  if (loadingFieldSettings) return <LoadingState />;

  let referrerAdvisedPriority = null;
  let triageCategory = null;
  const referrerAdvisedPriorityCodeId = referral?.referralDocument?.referrerAdvisedPriorityCodeId ?? null;
  // if referrerAdvisedPriorityCodeId if not null
  // we look for the codeSetValue proprieties by its id
  if (referrerAdvisedPriorityCodeId) {
    referrerAdvisedPriority = find(codeSetValues, x => x.id === referrerAdvisedPriorityCodeId);
  }

  // if setting for the codeSetValue is founded
  // we set the default value for triageCategory
  if (referrerAdvisedPriority) {
    triageCategory = find(triageCategories, x => x.codeSetValueCode === referrerAdvisedPriority.code)?.triageCategory ?? null;
  }

  const { reasonNote } = referral;

  // Check if the referral document has a clinic type
  const clinicTypeCodeId = referral.referralDocument.clinicTypeCodeId ?? null;
  const externalTriageStatusCodeId = referral.referralDocument.externalTriageStatusCodeId ?? null;
  const reasonForReferralCodeId = referral.referralDocument.reasonForReferralCodeId ?? null;
  const claimTypeCodeId = referral.referralDocument.claimTypeCodeId ?? null;

  const episodeHealthConditionSchema = Yup.object().shape({
    code: Yup.string(),
    description: Yup.string(),
  });

  const multipleEpisodeHealthCondition = episodeHealthConditionMaxLength > 1;
  let { episodeHealthConditions } = referral;
  const episodeHealthConditionIds = referral.referralDocument.episodeHealthConditionIds ?? null;
  if (referral.episodeHealthConditions) {
    if (multipleEpisodeHealthCondition) {
      episodeHealthConditions = map(episodeHealthConditions, episodeHealthCondition => ({
        value: episodeHealthCondition.code,
        label: episodeHealthCondition.term,
      }));
    } else {
      const episodeHealthCondition = head(episodeHealthConditions);
      episodeHealthConditions = !isEmpty(episodeHealthCondition)
        ? {
          value: episodeHealthCondition?.code,
          label: episodeHealthCondition?.term,
        } : null;
    }
  }

  const schema = Yup.object().shape({
    referral: Yup.object().shape({
      referrerAdvisedPriorityCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.referrerAdvisedPriority, fieldSettings?.referrerAdvisedPriority)
        .default(referrerAdvisedPriorityCodeId)
        .nullable(),
      isUrgent: Yup.bool().nullable(),
      triageCategory: Yup.string()
        .required("This field is required")
        .default(triageCategory)
        .nullable(),
      clinicCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.clinic, fieldSettings?.clinic)
        .nullable(),
      clinicTypeCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.clinicType, fieldSettings?.clinicType)
        .default(clinicTypeCodeId)
        .nullable(),
      note: Yup.string()
        .fieldSettingsValidation(fieldLabels.note, fieldSettings?.note)
        .max(1000, "Too long")
        .nullable(),
      claimTypeCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.claimType, fieldSettings?.claimType)
        .default(claimTypeCodeId)
        .nullable(),
      reasonForReferralCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.reasonForReferralCode, fieldSettings?.reasonForReferralCode)
        .default(reasonForReferralCodeId)
        .nullable(),
      reasonNote: Yup.string()
        .fieldSettingsValidation(fieldLabels.reasonNote, fieldSettings?.reasonNote)
        .default(reasonNote)
        .nullable(),
      episodeHealthConditions: multipleEpisodeHealthCondition
        ? Yup.array()
          .of(episodeHealthConditionSchema)
          .fieldSettingsValidation(fieldLabels.episodeHealthCondition, fieldSettings?.episodeHealthCondition)
          .max(episodeHealthConditionMaxLength, `${fieldLabels.episodeHealthCondition} must have less than or equal to ${episodeHealthConditionMaxLength} items`)
          .default(episodeHealthConditionIds)
          .nullable()
        : episodeHealthConditionSchema
          .fieldSettingsValidation(fieldLabels.episodeHealthCondition, fieldSettings?.episodeHealthCondition)
          .default(head(episodeHealthConditionIds)).nullable(),
      externalTriageStatusCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.externalTriageStatus, fieldSettings?.externalTriageStatus)
        .default(externalTriageStatusCodeId)
        .nullable(),
    }),
  });

  return (
    <AcceptReferralForm
      schema={schema}
      fieldSettings={fieldSettings}
      fieldLabels={fieldLabels}
      triageCategoryOptions={triageCategories}
      referralDocumentId={referral?.referralDocument.id}
      episodeHealthConditionMaxLength={episodeHealthConditionMaxLength}
      specialtyId={referral?.assignedTo?.assignedToSpecialtyId}
      defaultEpisodeHealthConditions={episodeHealthConditions}
      {...other}
    />
  );
};
