import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty, orderBy } from "lodash";
import clsx from "clsx";
import { useHistory } from "react-router-dom";

import { ReferralWorklistIcon } from "helpers/icon-finder";
import DefaultPanelLayout from "components/items/default-panel-layout";
import withPermissions from "permissions/withPermissions";
import PanelContent from "components/items/panel-content";
import { getPatientActiveReferrals, isPatientActiveReferralsLoading, isPatientActiveReferralsLoaded } from "app/main/referrals/reducers/patientReferrals.reducers";
import { fetchReferral } from "app/main/referrals/actions/referrals.actions";
import { fetchPatientActiveReferrals } from "app/main/referrals/actions/patientReferrals.actions";
import { getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";

import ReferralCard from "./referral-card";

const ActiveReferralListPanel = ({
  patientId,
  orgUnitId,
  ...other
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoading = useSelector(state => isPatientActiveReferralsLoading(state, patientId));
  const isLoaded = useSelector(state => isPatientActiveReferralsLoaded(state, patientId));
  const patientReferrals = useSelector(state => getPatientActiveReferrals(state, patientId));
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);

  useEffect(() => {
    if (!isLoaded) {
      dispatch(fetchPatientActiveReferrals(orgUnitId, patientId, referralWorklistSettings?.activeReferralListPageSize));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId, orgUnitId]);

  const goToReferral = ({ referralId }) => {
    dispatch(fetchReferral(orgUnitId, referralId)).then(() => {
      history.push({
        pathname: `/patients/${patientId}/referrals/${referralId}`,
        fromUrl: history.location.pathname + history.location.search,
      });
    });
  };

  const hasReferrals = !isEmpty(patientReferrals);

  return (
    <DefaultPanelLayout
      title="Recent Referrals (Active)"
      icon={<ReferralWorklistIcon />}
      isEmpty={!hasReferrals}
      isLoading={isLoading}
      classes={{ content: clsx(hasReferrals && "p-0"), contentContainer: clsx(hasReferrals && "pb-8 bg-transparent") }}
      elevation={!hasReferrals ? 1 : 0}
      emptyTitle="No Recent Referrals"
      {...other}
    >
      <PanelContent>
        {map(orderBy(patientReferrals, ["referralDateUtc"], ["desc"]), x => (
          <ReferralCard
            key={x.id}
            referral={x}
            orgUnitId={orgUnitId}
            goToReferral={!x.canViewFullReferral ? null : () => goToReferral({ referralId: x.id })}
          />
        ))}
      </PanelContent>
    </DefaultPanelLayout>
  );
};

export default withPermissions("ReferralsCreate")(ActiveReferralListPanel);
