import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { find, isEmpty, map } from "lodash";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchSpecialtySpecialists } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { AssignedUserIcon } from "helpers/icon-finder";

export default ({
  specialtyId,
  label,
  showIcon = true,
  setDefaultValue,
  initialOptions = [],
  loadInitialOption = false,
  ...other
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(initialOptions || []);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const { field: { name, value }, form: { setFieldValue } } = other;

  const shouldLoadInitialOption = loadInitialOption && value && isEmpty(initialOptions);

  const loadOptions = selectedSpecialtyId => {
    if (selectedSpecialtyId) {
      setLoading(true);
      setOptions([]);
      dispatch(fetchSpecialtySpecialists(selectedSpecialtyId)).then(response => {
        if (response) {
          const specialties = response.error ? [] : response?.payload?.specialtySpecialists;
          const defaultSpecialtySpecialist = find(specialties, x => x.isDefault === true);
          if (setDefaultValue === true && defaultSpecialtySpecialist) {
            setFieldValue(name, defaultSpecialtySpecialist?.userId);
          }
          setOptions(specialties);
        } else {
          setOptions([]);
        }
      }).finally(() => {
        setLoading(false);
      });
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    if (shouldLoadInitialOption) {
      setInitialLoading(true);
      loadOptions(specialtyId);
      setInitialLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadInitialOption, specialtyId]);

  const loadOptionsOnOpen = selectedSpecialtyId => loadOptions(selectedSpecialtyId);

  if (initialLoading) return <AutoCompleteLoading label={label} />;

  return (
    <AutoComplete
      label={label}
      options={map(options, x => ({ value: x.userId, label: x.name }))}
      filterSelectedOptions
      icon={showIcon && <AssignedUserIcon />}
      loading={loading}
      onOpen={() => (shouldLoadInitialOption ? null : loadOptionsOnOpen(specialtyId))}
      {...other}
    />
  );
};
