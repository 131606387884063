import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import ScreenHeader from "components/items/screen-header";
import withPermissions from "permissions/withPermissions";
import DefaultButton from "components/items/default-button";
import ConfirmationDialog from "components/items/confirmation-dialog";
import { ExcelExportIcon, CodeSetFileImportIcon } from "helpers/icon-finder";
import UserUploadForm from "app/main/users/components/user-upload-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { showMessage } from "app/store/actions/message.actions";
import { fetchUsersResults } from "app/main/users/actions/users.actions";
import { getPageInfo, getUserSearchTerms } from "app/main/users/reducers/users.reducers";
import formatSearchParams from "helpers/format-search-params";

import downloadFromApi from "utils/download-from-api";

import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import UsersSearch from "./users-search";

const UsersPageHeader = props => {
  const { hasPermissionUsersCreate, hasPermissionUserExport, hasPermissionUserImport, history, orgUnit } = props;
  const dispatch = useDispatch();
  const pageInfo = useSelector(getPageInfo);
  const { pageSize } = pageInfo;
  const [loadingExport, setLoadingExport] = useState(false);
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);
  const userSearchTerms = useSelector(getUserSearchTerms);

  const searchUsers = searchParams => dispatch(fetchUsersResults(signedInOrgUnit?.id, 1, pageSize, searchParams));

  const onClose = () => dispatch(closeDialog());

  const onSucceed = responsePayload => {
    onClose();

    if (Object.keys(responsePayload).length > 0) {
      dispatch(showMessage({
        variant: "error",
        message: `Failure to import the following users/rows:\n\n${Object.values(responsePayload)}`,
      }));
    } else {
      dispatch(showMessage({
        variant: "success",
        message: "Successfully imported all users.",
      }));
    }

    searchUsers();// Refresh the list
  };

  const requestExcelFile = url => {
    onClose();
    setLoadingExport(true);
    downloadFromApi(url).then(() => setLoadingExport(false));
  };

  const handleImport = () => dispatch(openDialog({
    children: (
      <UserUploadForm
        title="Import users"
        onSucceed={onSucceed}
        requestExcelFile={requestExcelFile}
        signedInOrgUnit={signedInOrgUnit}
        closeDialog={closeDialog}
        hasPermissionUserExport={hasPermissionUserExport}
      />
    ),
  }));

  const handleExport = () => {
    dispatch(openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <ConfirmationDialog
          onCancel={onClose}
          type="warning"
          title="Export User"
          content="Would you like to export the user list?"
          onConfirm={() => requestExcelFile(`api/users/${signedInOrgUnit.id}/exportUsers?${formatSearchParams(userSearchTerms)}`)}
        />
      ),
    }));
  };

  return (
    <ScreenHeader
      title="Users"
      renderSearch={<UsersSearch orgUnit={orgUnit} />}
      renderActions={(
        hasPermissionUsersCreate && (
        <>
          {hasPermissionUsersCreate
            && (
            <DefaultButton
              label="Add a user"
              variant="outlined"
              color="inherit"
              icon="add"
              onClick={() => history.push("/admin/users/new")}
            />
            )}
            {hasPermissionUserExport
          && (
          <DefaultButton
            label="Export"
            variant="outlined"
            color="inherit"
            loading={loadingExport}
            icon={<ExcelExportIcon />}
            onClick={handleExport}
          />
          )}
          {hasPermissionUserImport
          && (
          <DefaultButton
            label="Import"
            variant="outlined"
            color="inherit"
            icon={<CodeSetFileImportIcon />}
            onClick={handleImport}
          />
          )}
        </>
        )
      )}
    />
  );
};

export default withPermissions("UsersCreate", "UserExport", "UserImport")(UsersPageHeader);
