import { get, mapKeys, map, startCase, find } from "lodash";
import curryConnector from "utils/connectors";
import { getAllRoles } from "app/auth/store/reducers/system-configuration";

export const SPECIALTY_PROFILES_STATE_KEY = "specialtyprofiles";
export const SPECIALTY_PROFILES_PAGE_SIZE = 50;
export const SPECIALTY_PROFILES_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

const curry = fn => curryConnector(fn, SPECIALTY_PROFILES_STATE_KEY);

export const ActionTypes = {
  LOADING_SPECIALTY_PROFILES: "LOADING_SPECIALTY_PROFILES",
  LOADED_SPECIALTY_PROFILES: "LOADED_SPECIALTY_PROFILES",
  ERROR_LOADING_SPECIALTY_PROFILES: "ERROR_LOADING_SPECIALTY_PROFILES",

  LOADING_SPECIALTY_PROFILE: "LOADING_SPECIALTY_PROFILE",
  LOADED_SPECIALTY_PROFILE: "LOADED_SPECIALTY_PROFILE",
  ERROR_LOADING_SPECIALTY_PROFILE: "ERROR_LOADING_SPECIALTY_PROFILE",
  RESET_CURRENT_SPECIALTY_PROFILE: "RESET_CURRENT_SPECIALTY_PROFILE",

  SAVED_SPECIALTY_PROFILE: "SAVED_SPECIALTY_PROFILE",
  CREATED_SPECIALTY_PROFILE: "CREATED_SPECIALTY_PROFILE",

  LOADING_SEARCHED_SPECIALTIES: "LOADING_SEARCHED_SPECIALTIES",
  ERROR_LOADING_SEARCHED_SPECIALTIES: "ERROR_LOADING_SEARCHED_SPECIALTIES",
  LOADED_SEARCHED_SPECIALTIES: "LOADED_SEARCHED_SPECIALTIES",

  LOADING_SUB_SPECIALTIES: "LOADING_SUB_SPECIALTIES",
  ERROR_LOADING_SUB_SPECIALTIES: "ERROR_LOADING_SUB_SPECIALTIES",
  LOADED_SUB_SPECIALTIES: "LOADED_SUB_SPECIALTIES",
  LOADING_SPECIALTY_ROLES: "LOADING_SPECIALTY_ROLES",
  ERROR_LOADING_SPECIALTY_ROLES: "ERROR_LOADING_SPECIALTY_ROLES",
  LOADED_SPECIALTY_ROLES: "LOADED_SPECIALTY_ROLES",

  LOADING_SPECIALTY_SPECIALISTS: "LOADING_SPECIALTY_SPECIALISTS",
  LOADED_SPECIALTY_SPECIALISTS: "LOADED_SPECIALTY_SPECIALISTS",
  ERROR_LOADING_SPECIALTY_SPECIALISTS: "ERROR_LOADING_SPECIALTY_SPECIALISTS",
  LOADING_SPECIALTY_CODE_SET_VALUES: "LOADING_SPECIALTY_CODE_SET_VALUES",
  LOADED_SPECIALTY_CODE_SET_VALUES: "LOADED_SPECIALTY_CODE_SET_VALUES",
  ERROR_LOADING_SPECIALTY_CODE_SET_VALUES: "ERROR_LOADING_SPECIALTY_CODE_SET_VALUES",
  LOADING_SPECIALTIES_CODE_SET_VALUES: "LOADING_SPECIALTIES_CODE_SET_VALUES",
  LOADED_SPECIALTIES_CODE_SET_VALUES: "LOADED_SPECIALTIES_CODE_SET_VALUES",
  ERROR_LOADING_SPECIALTIES_CODE_SET_VALUES: "ERROR_LOADING_SPECIALTIES_CODE_SET_VALUES",

  SAVED_DEPRECATE_SPECIALTY_PROFILE: "SAVED_DEPRECATE_SPECIALTY_PROFILE",
  SAVED_RESTORE_SPECIALTY_PROFILE: "SAVED_RESTORE_SPECIALTY_PROFILE",

  RESET_SPECIALTY_SELECTORS: "RESET_SPECIALTY_SELECTORS",

  LOADING_OCR_SPECIALTY_PROFILES: "LOADING_OCR_SPECIALTY_PROFILES",
  LOADED_OCR_SPECIALTY_PROFILES: "LOADED_OCR_SPECIALTY_PROFILES",
  ERROR_LOADING_OCR_SPECIALTY_PROFILES: "ERROR_LOADING_OCR_SPECIALTY_PROFILES",

  LOADING_SPECIALTY_REFERRAL_FORM_PROFILES: "LOADING_SPECIALTY_REFERRAL_FORM_PROFILES",
  LOADED_SPECIALTY_REFERRAL_FORM_PROFILES: "LOADED_SPECIALTY_REFERRAL_FORM_PROFILES",
  ERROR_LOADING_SPECIALTY_REFERRAL_FORM_PROFILES: "ERROR_LOADING_SPECIALTY_REFERRAL_FORM_PROFILES",
};

const INITIAL_META = {
  loading: false,
  loaded: false,
  error: null,
};

const INITIAL_STATE = {
  all: [],
  pages: {},
  pageInfo: { pageNumber: 1, pageSize: SPECIALTY_PROFILES_PAGE_SIZE, totalRecords: 0 },
  searchParams: { },
  current: {},
  searchedSpecialtyProfiles: [],
  subSpecialties: [],
  specialtyRoles: [],
  specialtyCodeSetValues: [],
  specialtySpecialists: [],
  ocrSpecialtyProfiles: [],
  specialtyReferralFormProfiles: [],
  meta: {
    current: INITIAL_META,
    subSpecialties: INITIAL_META,
    specialtyCodeSetValues: INITIAL_META,
    specialtiesCodeSetValues: INITIAL_META,
    specialtySpecialists: INITIAL_META,
    specialtyRoles: INITIAL_META,
    ocrSpecialtyProfiles: INITIAL_META,
    specialtyReferralFormProfiles: INITIAL_META,
  },
};

const formatCurrentSpecialtyProfile = (state, specialtyProfile) => {
  const allRoles = getAllRoles(state);

  const formattedSpecialtyProfile = {
    ...specialtyProfile,
    autoAssignRoles: map(specialtyProfile.autoAssignRoles, autoAssignRole => {
      const role = find(allRoles, r => r.roleId === autoAssignRole.roleId);

      return {
        ...autoAssignRole,
        roleName: role?.roleDescription ?? startCase(role?.roleName),
      };
    }),
  };

  return formattedSpecialtyProfile;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_SPECIALTY_PROFILES:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: true, error: false } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.ERROR_LOADING_SPECIALTY_PROFILES:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: action.payload.message } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.LOADED_SPECIALTY_PROFILES:
      return {
        ...state,
        all: { ...state.all, ...mapKeys(action.payload.specialtyProfiles, x => x.id) },
        pages: { ...state.pages, [action.payload.pageInfo.pageNumber]: { loading: false, error: false, loaded: true, ids: map(action.payload.specialtyProfiles, x => x.id) } },
        pageInfo: action.payload.pageInfo,
        searchParams: action.payload.searchParams,
      };
    case ActionTypes.LOADING_SPECIALTY_PROFILE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current: {
            ...state.meta.current,
            loading: true,
          },
        },
      };
    case ActionTypes.LOADED_SPECIALTY_PROFILE:
      return {
        ...state,
        current: action.payload.specialtyProfile,
        meta: {
          ...state.meta,
          current: {
            ...state.meta.current,
            loading: false,
            loaded: true,
            error: null,
          },
        },
      };
    case ActionTypes.ERROR_LOADING_SPECIALTY_PROFILE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current: {
            loading: false,
            loaded: false,
            error: action.payload.message,
          },
        },
      };
    case ActionTypes.RESET_CURRENT_SPECIALTY_PROFILE:
      return {
        ...state,
        current: INITIAL_STATE.current,
        meta: {
          ...state.meta,
          current: INITIAL_STATE.meta.current,
        },
      };
    case ActionTypes.SAVED_SPECIALTY_PROFILE:
    case ActionTypes.CREATED_SPECIALTY_PROFILE:
      return {
        ...state,
        current: action.payload.specialtyProfile,
        all: { ...state.all, [action.payload.specialtyProfile.id]: { ...action.payload.specialtyProfile } },
      };
    case ActionTypes.LOADING_SEARCHED_SPECIALTIES:
      return {
        ...state,
        searchedSpecialtyProfiles: { ...state.specialtyProfiles, loading: true },
      };
    case ActionTypes.ERROR_LOADING_SEARCHED_SPECIALTIES:
      return {
        ...state,
        searchedSpecialtyProfiles: { ...state.specialtyProfiles, loading: false, error: action.payload.message },
      };
    case ActionTypes.LOADED_SEARCHED_SPECIALTIES:
      return {
        ...state,
        searchedSpecialtyProfiles: {
          ...state.searchedSpecialtyProfiles,
          all: action.payload.specialtyProfiles,
          loading: false,
          loaded: true,
        },
      };
    case ActionTypes.LOADING_SUB_SPECIALTIES:
      return {
        ...state,
        meta: {
          ...state.meta,
          subSpecialties: { loading: true },
        },
      };
    case ActionTypes.LOADED_SUB_SPECIALTIES:
      return {
        ...state,
        subSpecialties: action.payload.subSpecialties,
        meta: {
          ...state.meta,
          subSpecialties: { loading: false, loaded: true, error: null },
        },
      };
    case ActionTypes.ERROR_LOADING_SUB_SPECIALTIES:
      return {
        ...state,
        meta: {
          ...state.meta,
          subSpecialties: { loading: false, loaded: false, error: action.payload.message },
        },
      };
    case ActionTypes.LOADING_SPECIALTY_ROLES:
      return {
        ...state,
        meta: {
          ...state.meta,
          specialtyRoles: { loading: true },
        },
      };
    case ActionTypes.LOADED_SPECIALTY_ROLES:
      return {
        ...state,
        specialtyRoles: action.payload.specialtyRoles,
        meta: {
          ...state.meta,
          specialtyRoles: { loading: false, loaded: true, error: null },
        },
      };
    case ActionTypes.ERROR_LOADING_SPECIALTY_ROLES:
      return {
        ...state,
        meta: {
          ...state.meta,
          specialtyRoles: { loading: false, loaded: false, error: action.payload.message },
        },
      };
    case ActionTypes.LOADING_SPECIALTY_SPECIALISTS:
      return {
        ...state,
        meta: {
          ...state.meta,
          specialtySpecialists: { loading: true },
        },
      };
    case ActionTypes.LOADED_SPECIALTY_SPECIALISTS:
      return {
        ...state,
        specialtySpecialists: action.payload.specialtySpecialists, // consider to update if there are multiple instance in one form
        meta: {
          ...state.meta,
          specialtySpecialists: { loading: false, loaded: true, error: null },
        },
      };
    case ActionTypes.ERROR_LOADING_SPECIALTY_SPECIALISTS:
      return {
        ...state,
        meta: {
          ...state.meta,
          specialtySpecialists: { loading: false, loaded: false, error: action.payload.message },
        },
      };
    case ActionTypes.LOADING_SPECIALTY_CODE_SET_VALUES:
      return {
        ...state,
        meta: {
          ...state.meta,
          specialtyCodeSetValues: {
            ...state.meta.specialtyCodeSetValues,
            [action.payload.specialtyId]: { loading: true, loaded: false, error: null },
          },
        },
      };
    case ActionTypes.LOADED_SPECIALTY_CODE_SET_VALUES:
      return {
        ...state,
        specialtyCodeSetValues: {
          ...state.specialtyCodeSetValues,
          [action.payload.specialtyId]: action.payload.codeSetValues,
        },
        meta: {
          ...state.meta,
          specialtyCodeSetValues: {
            ...state.meta.specialtyCodeSetValues,
            [action.payload.specialtyId]: { loading: false, loaded: true, error: null },
          },
        },
      };
    case ActionTypes.ERROR_LOADING_SPECIALTY_CODE_SET_VALUES:
      return {
        ...state,
        meta: {
          ...state.meta,
          specialtyCodeSetValues: {
            ...state.meta.specialtyCodeSetValues,
            [action.payload.specialtyId]: { loading: false, loaded: false, error: action.payload.message },
          },
        },
      };
    case ActionTypes.LOADING_SPECIALTIES_CODE_SET_VALUES:
      return {
        ...state,
        meta: {
          ...state.meta,
          specialtiesCodeSetValues: { loading: true, loaded: false, error: null },
        },
      };
    case ActionTypes.LOADED_SPECIALTIES_CODE_SET_VALUES:
      return {
        ...state,
        specialtiesCodeSetValues: action.payload.specialtiesCodeSetValues,
        meta: {
          ...state.meta,
          specialtiesCodeSetValues: { loading: false, loaded: true, error: null },
        },
      };
    case ActionTypes.ERROR_LOADING_SPECIALTIES_CODE_SET_VALUES:
      return {
        ...state,
        meta: {
          ...state.meta,
          specialtiesCodeSetValues: { loading: false, loaded: false, error: action.payload.message },
        },
      };
    case ActionTypes.SAVED_DEPRECATE_SPECIALTY_PROFILE:
      return {
        ...state,
        current: {
          ...state.current,
          deprecatedDateUtc: action.payload.specialtyProfile.deprecatedDateUtc,
        },
      };
    case ActionTypes.SAVED_RESTORE_SPECIALTY_PROFILE:
      return {
        ...state,
        current: {
          ...state.current,
          deprecatedDateUtc: null,
        },
      };
    case ActionTypes.RESET_SPECIALTY_SELECTORS:
      // selectors for referral form use
      return {
        ...state,
        specialtyCodeSetValues: {
          ...state.specialtyCodeSetValues,
          [action.payload]: [],
        },
        specialtySpecialists: [],
        meta: {
          ...state.meta,
          specialtyCodeSetValues: {
            ...state.meta.specialtyCodeSetValues,
            [action.payload]: INITIAL_META,
          },
          specialtySpecialists: INITIAL_META,
        },
      };
    case ActionTypes.LOADING_OCR_SPECIALTY_PROFILES:
      return {
        ...state,
        meta: {
          ...state.meta,
          ocrSpecialtyProfiles: { loading: true },
        },
      };
    case ActionTypes.LOADED_OCR_SPECIALTY_PROFILES:
      return {
        ...state,
        ocrSpecialtyProfiles: action.payload.specialtyProfiles,
        ocrSpecialtyProfilesLabel: action.payload.ocrSpecialtyProfilesLabel,
        meta: {
          ...state.meta,
          ocrSpecialtyProfiles: { loading: false, loaded: true, error: null },
        },
      };
    case ActionTypes.ERROR_LOADING_OCR_SPECIALTY_PROFILES:
      return {
        ...state,
        meta: {
          ...state.meta,
          ocrSpecialtyProfiles: { loading: false, loaded: false, error: action.payload.message },
        },
      };
    case ActionTypes.LOADING_SPECIALTY_REFERRAL_FORM_PROFILES:
      return {
        ...state,
        meta: {
          ...state.meta,
          specialtyReferralFormProfiles: { loading: true },
        },
      };
    case ActionTypes.LOADED_SPECIALTY_REFERRAL_FORM_PROFILES:
      return {
        ...state,
        specialtyReferralFormProfiles: action.payload.specialtyReferralFormProfiles,
        meta: {
          ...state.meta,
          specialtyReferralFormProfiles: { loading: false, loaded: true, error: null },
        },
      };
    case ActionTypes.ERROR_LOADING_SPECIALTY_REFERRAL_FORM_PROFILES:
      return {
        ...state,
        meta: {
          ...state.meta,
          specialtyReferralFormProfiles: { loading: false, loaded: false, error: action.payload.message },
        },
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});

export const getPageInfo = curry(({ localState }) => localState.pageInfo);

export const getErrorMessage = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "error"], null);
});

export const isCurrentSpecialtyProfileLoading = curry(state => state.localState.meta.current.loading === true);

export const isCurrentSpecialtyProfileLoaded = curry(state => state.localState.meta.current.loaded === true);

export const isCurrentSpecialtyProfileError = curry(state => state.localState.meta.current.error);

export const getCurrentSpecialtyProfile = curry(({ localState, state }) => {
  const currentSpecialtyProfile = get(localState, ["current"], null);

  return formatCurrentSpecialtyProfile(state, currentSpecialtyProfile);
});

export const getSpecialtyProfileSearchTerms = curry(({ localState }) => localState.searchParams);

export const getAllSpecialtyProfiles = curry(({ localState }) => {
  const specialtyProfiles = get(localState, ["all"], {});
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return map(get(localState.pages, [pageNumber, "ids"], []), key => specialtyProfiles[key]);
});

export const getSearchedSpecialtyProfiles = curry(({ localState }) => get(localState.searchedSpecialtyProfiles, ["all"], []));

export const areSearchedSpecialtyProfilesLoading = curry(state => get(state.localState.searchedSpecialtyProfiles, ["loading"], false) === true);

export const areSubSpecialtiesLoading = curry(state => state.localState.meta.subSpecialties.loading === true);

export const areSubSpecialtiesLoaded = curry(state => state.localState.meta.subSpecialties.loaded === true);

export const getSubSpecialties = curry(({ localState }) => localState.subSpecialties);

export const getSpecialtyRoles = curry(({ localState }) => get(localState, ["specialtyRoles"], null));

export const areSpecialtyRolesLoading = curry(state => state.localState.meta.specialtyRoles.loading === true);

export const areSpecialtySpecialistsLoading = curry(state => state.localState.meta.specialtySpecialists.loading === true);

export const getSpecialtySpecialists = curry(({ localState }) => localState.specialtySpecialists);

export const getSpecialtyCodeSetValues = curry(({ localState }, specialtyId) => localState.specialtyCodeSetValues?.[specialtyId] ?? []);

export const areSpecialtyCodeSetValuesLoading = curry(({ localState }, specialtyId) => get(localState.meta.specialtyCodeSetValues, [specialtyId, "loading"], false) === true);

export const areSpecialtyCodeSetValuesLoaded = curry(({ localState }, specialtyId) => get(localState.meta.specialtyCodeSetValues, [specialtyId, "loaded"], false) === true);

export const areSpecialtiesCodeSetValues = curry(({ localState }) => get(localState.meta.specialtiesCodeSetValues, ["loading"], false) === true);

export const getSpecialtiesCodeSetValues = curry(({ localState }) => localState.specialtiesCodeSetValues ?? []);

export const getOCRSpecialtyProfiles = curry(({ localState }) => localState.ocrSpecialtyProfiles ?? []);

export const areOCRSpecialtyProfilesLoading = curry(state => state.localState.meta.ocrSpecialtyProfiles.loading === true);

export const areOCRSpecialtyProfilesLoaded = curry(state => state.localState.meta.ocrSpecialtyProfiles.loaded === true);

export const getReferralRequirementsFromSearchedSpecialtyProfile = curry(({ localState }, specialtyId) => find(localState.searchedSpecialtyProfiles.all, x => x.id === specialtyId)?.referralRequirements);

export const areSpecialtyReferralFormProfilesLoading = curry(state => state.localState.meta.specialtyReferralFormProfiles.loading === true);

export const getOverrideDefaultFormProfilesFromSearchedSpecialtyProfile = curry(({ localState }, specialtyId) => find(localState.searchedSpecialtyProfiles.all, x => x.id === specialtyId)?.overrideDefaultFormProfiles);

export const getDefaultSpecialist = curry(({ localState }, specialtyId) => find(localState.searchedSpecialtyProfiles.all, x => x.id === specialtyId)?.defaultSpecialist);

export const getCurrentSpecialtyReferralFormProfiles = curry(({ localState }, specialtyId) => find(localState.searchedSpecialtyProfiles.all, x => x.id === specialtyId)?.currentSpecialtyReferralFormProfiles);
