import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { fetchUsersResults, resetUsersFilters, fetchAllUserRoles } from "app/main/users/actions/users.actions";
import { getUserSearchTerms, getPageInfo, getAllRoles } from "app/main/users/reducers/users.reducers";
import { fetchSpecialtyProfilesForSelector } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { getSearchedSpecialtyProfiles } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import AdvancedFilter, { FilterForm, FilterSection } from "components/items/advanced-filter";
import { TextInput, CheckboxInput } from "components/inputs";
import SpecialtySelector from "app/main/specialtyProfiles/components/specialty-selector";
import withPermissions from "permissions/withPermissions";
import { isEmpty } from "lodash";
import useUserUniversalSearchConfig from "hooks/use-user-universal-search-config";
import { isOrgUnitTypeSite } from "app/main/orgUnits/helpers/org-unit-type";
import UserSearchRoleSelector from "./users-search-roles-selector";

const UsersSearch = ({
  orgUnit,
}) => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(getPageInfo);
  const params = useSelector(getUserSearchTerms);
  const { pageSize } = pageInfo;
  const userRoles = useSelector(getAllRoles);
  const userSpecialties = useSelector(getSearchedSpecialtyProfiles);
  const { isSpecialtyFieldEnabled, isUserRoleFieldEnabled } = useUserUniversalSearchConfig();
  const { orgUnitType } = orgUnit;
  const showSpecialty = isSpecialtyFieldEnabled && isOrgUnitTypeSite(orgUnitType);

  useEffect(() => {
    dispatch(fetchSpecialtyProfilesForSelector(orgUnit.id));
  }, [dispatch, orgUnit.id]);

  useEffect(() => {
    dispatch(fetchAllUserRoles());
  }, [dispatch]);

  const onSearch = searchText => {
    dispatch(fetchUsersResults(orgUnit.id, 1, pageSize, { ...params, universeSearch: searchText }));
  };

  const searchUsers = searchParams => dispatch(fetchUsersResults(orgUnit.id, 1, pageSize, searchParams));

  const handleSubmit = ({ searchParams }) => {
    searchUsers(searchParams);
  };

  const handleReset = () => {
    dispatch(resetUsersFilters()).then(searchUsers(params));
  };

  const hasFilterContent = params.includeInactive || !isEmpty(params.roles) || !isEmpty(params.specialties);
  return (
    <AdvancedFilter
      onSearch={onSearch}
      searchValue={params?.universeSearch}
      filterContentAnchor="right"
      withPaperWrapper={false}
      hasFilterValue={hasFilterContent}
      content={onClose => (
        <FilterForm
          initialValues={{ searchParams: params }}
          onSubmit={handleSubmit}
          onReset={handleReset}
          onClose={onClose}
          filters={() => (
            <div>
              <FilterSection>
                <Field
                  label="Search"
                  name="searchParams.universeSearch"
                  component={TextInput}
                  icon="search"
                />
              </FilterSection>
              <FilterSection>
                <Field
                  name="searchParams.includeInactive"
                  label="Show inactive users"
                  component={CheckboxInput}
                />
              </FilterSection>
              {isUserRoleFieldEnabled && (
                <FilterSection label="Roles" withDivider>
                  <Field
                    name="searchParams.roles"
                    label="Roles"
                    component={UserSearchRoleSelector}
                    initialOptions={userRoles}
                    multiple
                  />
                </FilterSection>
              )}
              {false && showSpecialty && (
                <FilterSection label="Specialty" withDIvider>
                  <Field
                    name="searchParams.specialties"
                    label="Specialty"
                    component={SpecialtySelector}
                    initialOptions={userSpecialties}
                    orgUnitId={orgUnit.id}
                    multiple
                  />
                </FilterSection>
              )}
            </div>
          )}
        />
      )}
    />
  );
};

export default withPermissions("UsersMaintain")(UsersSearch);
