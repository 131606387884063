
import { get, find, mapKeys, map, concat, isEmpty, filter, camelCase, size, head, reduce, uniq, includes } from "lodash";
import moment from "moment";

import curryConnector from "utils/connectors";
import { ActionTypes as ReferralWorkListActionTypes } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import { ActionTypes as PatientActionTypes } from "app/main/patients/reducers/patients.reducers";
import { ActionTypes as ExternalDocumentActionTypes } from "app/main/externalDocumentWorklist/reducers/externalDocumentWorklist.reducers";
import formatReferralActivityDate from "app/main/referrals/helpers/referral-activity-date";

export const REFERRALS_STATE_KEY = "referrals";
export const REFERRALS_PAGE_SIZE = 50;
export const REFERRALS_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

const curry = fn => curryConnector(fn, REFERRALS_STATE_KEY);

export const ActionTypes = {
  LOADING_REFERRALS: "LOADING_REFERRALS",
  LOADED_REFERRALS: "LOADED_REFERRALS",
  ERROR_LOADING_REFERRALS: "ERROR_LOADING_REFERRALS",

  ACCEPTED_REFERRAL: "ACCEPTED_REFERRAL",
  REJECTED_REFERRAL: "REJECTED_REFERRAL",
  COMPLETED_REFERRAL: "COMPLETED_REFERRAL",
  ARCHIVED_REFERRAL: "ARCHIVED_REFERRAL",
  REQUEST_REFERRAL_ACTION: "REQUEST_REFERRAL_ACTION",
  UNDONE_REFERRAL_ACTION: "UNDONE_REFERRAL_ACTION",
  LOADED_PRINT_REFERRAL: "LOADED_PRINT_REFERRAL",
  REQUESTED_MORE_INFO: "REQUESTED_MORE_INFO",
  CANCELLED_REFERRAL: "CANCELLED_REFERRAL",
  UPDATED_DRAFT_REFERRAL: "UPDATED_DRAFT_REFERRAL",
  UPDATED_PROCESS_REFERRAL: "UPDATED_PROCESS_REFERRAL",
  UPDATED_PRESENTING_COMPLAINT: "UPDATED_PRESENTING_COMPLAINT",

  LOADED_REFERRAL_REPORT: "LOADED_REFERRAL_REPORT",
  LOADING_REFERRAL_REPORT: "LOADING_REFERRAL_REPORT",
  ERROR_LOADING_REFERRAL_REPORT: "LOADING_REFERRAL_REPORT",

  SAVED_REFERRAL_ASSIGNED_USER: "SAVED_REFERRAL_ASSIGNED_USER",

  LOADED_REFERRAL: "LOADED_REFERRAL",
  LOADING_REFERRAL: "LOADING_REFERRAL",
  ERROR_LOADING_REFERRAL: "ERROR_LOADING_REFERRAL",

  LOADED_REFERRAL_ATTACHMENTS: "LOADED_REFERRAL_ATTACHMENTS",
  LOADING_REFERRAL_ATTACHMENTS: "LOADING_REFERRAL_ATTACHMENTS",
  ERROR_LOADING_REFERRAL_ATTACHMENTS: "ERROR_LOADING_REFERRAL_ATTACHMENTS",
  LOADED_REFERRAL_ATTACHMENT_FILE: "LOADED_REFERRAL_ATTACHMENT_FILE",
  CREATE_MANUAL_REFERRAL: "CREATE_MANUAL_REFERRAL",
  EDIT_MANUAL_REFERRAL: "EDIT_MANUAL_REFERRAL",
  MOVE_CURRENT_REFERRAL: "MOVE_CURRENT_REFERRAL",

  CREATED_REFERRAL_NOTE: "CREATED_REFERRAL_NOTE",
  SAVED_REFERRAL_NOTE: "SAVED_REFERRAL_NOTE",
  DELETED_REFERRAL_NOTE: "DELETED_REFERRAL_NOTE",

  CREATED_REFERRAL_CORRESPONDENCE: "CREATED_REFERRAL_CORRESPONDENCE",
  LOADED_REFERRAL_PDF_PREVIEW: "LOADED_REFERRAL_PDF_PREVIEW",
  LOADING_REFERRAL_PDF_PREVIEW: "LOADING_REFERRAL_PDF_PREVIEW",
  ERROR_LOADING_REFERRAL_PDF_PREVIEW: "ERROR_LOADING_REFERRAL_PDF_PREVIEW",
  LOADED_CURRENT_REFERRAL: "LOADED_CURRENT_REFERRAL",

  CREATING_SOURCE_DOCUMENT_FILE: "CREATING_SOURCE_DOCUMENT_FILE",
  CREATED_SOURCE_DOCUMENT_FILE: "CREATED_SOURCE_DOCUMENT_FILE",
  CLEAR_SOURCE_DOCUMENT_FILES: "CLEAR_SOURCE_DOCUMENT_FILES",
  LOADED_REFERRAL_APPOINTMENTS: "LOADED_REFERRAL_APPOINTMENTS",
  LOADING_REFERRAL_APPOINTMENTS: "LOADING_REFERRAL_APPOINTMENTS",
  ERROR_LOADING_REFERRAL_APPOINTMENTS: "ERROR_LOADING_REFERRAL_APPOINTMENTS",

  LOADED_REFERRAL_SOURCE_DOCUMENT: "LOADED_REFERRAL_SOURCE_DOCUMENT",
  LOADING_REFERRAL_SOURCE_DOCUMENT: "LOADING_REFERRAL_SOURCE_DOCUMENT",
  ERROR_LOADING_REFERRAL_SOURCE_DOCUMENT: "ERROR_LOADING_REFERRAL_SOURCE_DOCUMENT",

  LOADED_REFERRAL_SOURCE_DOCUMENT_FILE_DATA: "LOADED_REFERRAL_SOURCE_DOCUMENT_FILE_DATA",
  LOADING_REFERRAL_ACCESS_LOG: "LOADING_REFERRAL_ACCESS_LOG",
  LOADED_REFERRAL_ACCESS_LOG: "LOADED_REFERRAL_ACCESS_LOG",
  ERROR_LOADING_REFERRAL_ACCESS_LOG: "ERROR_LOADING_REFERRAL_ACCESS_LOG",

  LOADING_EXISTING_SOURCE_DOCUMENT_FILE: "LOADING_EXISTING_SOURCE_DOCUMENT_FILE",
  LOADED_EXISTING_SOURCE_DOCUMENT_FILE: "LOADED_EXISTING_SOURCE_DOCUMENT_FILE",
  ERROR_EXISTING_SOURCE_DOCUMENT_FILE: "ERROR_EXISTING_SOURCE_DOCUMENT_FILE",
  RESET_CURRENT_REFERRAL: "RESET_CURRENT_REFERRAL",

  CREATED_REFERRAL_TASK: "CREATED_REFERRAL_TASK",
  SAVED_REFERRAL_TASK: "SAVED_REFERRAL_TASK",
  DELETED_REFERRAL_TASK: "DELETED_REFERRAL_TASK",

  SAVED_REFERRAL_ATTACHMENT: "SAVED_REFERRAL_ATTACHMENT",
  DELETED_REFERRAL_ATTACHMENT: "DELETED_REFERRAL_ATTACHMENT",

  SENDING_REFERRAL_OUTBOUND_MESSAGE: "SENDING_REFERRAL_OUTBOUND_MESSAGE",
  SENT_REFERRAL_OUTBOUND_MESSAGE: "SENT_REFERRAL_OUTBOUND_MESSAGE",
  ERROR_SENDING_REFERRAL_OUTBOUND_MESSAGE: "ERROR_SENDING_REFERRAL_OUTBOUND_MESSAGE",

  REFERRAL_AS_NOT_URGENT: "REFERRAL_AS_NOT_URGENT",
  REFERRAL_AS_URGENT: "REFERRAL_AS_URGENT",

  SAVED_REFERRAL_ASSIGNED_PATIENT: "SAVED_REFERRAL_ASSIGNED_PATIENT",

  CREATING_REFERRAL_ACTION_ACCESS_LOG: "CREATING_REFERRAL_ACTION_ACCESS_LOG",
  CREATED_REFERRAL_ACTION_ACCESS_LOG: "CREATED_REFERRAL_ACTION_ACCESS_LOG",
  ERROR_CREATING_REFERRAL_ACTION_ACCESS_LOG: "ERROR_CREATING_REFERRAL_ACTION_ACCESS_LOG",

  /* Actions received from SignalR */
  REFERRAL_VIEWED_BY_OTHER_USER: "REFERRAL_VIEWED_BY_OTHER_USER",
  REFERRAL_STATUS_CHANGED_BY_OTHER_USER: "REFERRAL_STATUS_CHANGED_BY_OTHER_USER",

  SAVED_REFERRAL_ASSIGNED_PATIENT_EDIT: "SAVED_REFERRAL_ASSIGNED_PATIENT_EDIT",

  UPDATE_CURRENT_REFERRAL_PATIENT_CONTACT: "UPDATE_CURRENT_REFERRAL_PATIENT_CONTACT",
};

function updateCurrentReferralPatientContact(currentReferral, patientId, contact) {
  if (currentReferral !== null
    && currentReferral.patientId === patientId
  ) {
    return {
      ...currentReferral,
      patient: {
        ...currentReferral.patient,
        nextOfKin: contact,
      },
    };
  }
  return currentReferral;
}

function updateReferralInfoFromOtherUser(existingReferral, newReferralData) {
  if (existingReferral.referralStatus !== newReferralData.status) {
    return {
      ...existingReferral,
      referralStatusUpdated: true,
      referralStatusUpdatedBy: newReferralData.userDisplayName,
      referralStatus: newReferralData.status,
    };
  }

  return existingReferral;
}

function updateCurrentReferralFromOtherUser(currentReferral, newReferralData) {
  if (currentReferral !== null
    && currentReferral.id === newReferralData.referralId
    && currentReferral.referralStatus !== newReferralData.status
  ) {
    return {
      ...currentReferral,
      referralStatusUpdated: true,
      referralStatusUpdatedByOtherUser: newReferralData.updatedByOtherUser,
      referralStatusUpdatedBy: newReferralData.userDisplayName,
      newReferralStatus: newReferralData.status,
    };
  }
  return currentReferral;
}

function updatePatientIdentifiers(patient, patientIdentifier) {
  let updated = false;

  if (!isEmpty(patient) && patient.patientId === patientIdentifier.patientId) {
    const patientIdentifiers = map(patient.patientIdentifiers, item => {
      if (item.patientIdentifierId === patientIdentifier.patientIdentifierId) {
        updated = true;
        return patientIdentifier;
      }
      return item;
    });
    return {
      ...patient,
      patientIdentifiers: updated ? patientIdentifiers : concat(patientIdentifiers, patientIdentifier),
    };
  }
  return patient;
}

function removePatientIdentifiers(patient, patientIdentifier) {
  if (!isEmpty(patient) && patient.patientId === patientIdentifier.patientId) {
    return {
      ...patient,
      patientIdentifiers: filter(patient.patientIdentifiers, item => item.patientIdentifierId !== patientIdentifier.patientIdentifierId),
    };
  }
  return patient;
}

function updateAttachmentFile(file, payload) {
  let updatedFile = file;
  if (file.id === payload.id) {
    updatedFile = {
      ...updatedFile,
      ...payload,
    };
  }
  return updatedFile;
}

function normaliseActivity(activity) {
  if (!isEmpty(activity)) {
    const createdDate = get(activity, "actionDateTimeUtc", null) || get(activity, "addedDateTimeUtc", null) || get(activity, "assignedDateTimeUtc", null);
    const editDate = get(activity, "lastEditedDateTimeUtc", null);

    return {
      ...activity,
      createdDateLocalised: formatReferralActivityDate(createdDate),
      editDateLocalised: formatReferralActivityDate(editDate),
    };
  }
  return null;
}

function normaliseAttachmentActivity(activity) {
  if (!isEmpty(activity)) {
    const sortDateTimeUtc = get(activity, "sortDateTimeUtc", null);

    return {
      ...activity,
      user: activity.removedBy ?? activity.addedBy ?? activity.user,
      sortDateTimeLocalised: formatReferralActivityDate(sortDateTimeUtc),
    };
  }
  return null;
}

function normaliseSortedActivity(activity) {
  const normalisedActivity = { ...activity, sortOrder: moment(activity.sortDateTimeUtc).format("X") };
  delete normalisedActivity.sortDateTimeUtc;
  return normalisedActivity;
}

const updateActivityActions = (activities, activity) => ({
  ...activities,
  action: concat(normaliseActivity(activity.action), activities.action),
  assignment: activity.referralAssignment ? concat(normaliseActivity(activity.referralAssignment), activities.assignment) : activities.assignment,
  sortedActivities: concat(activity.newActivities, activities.sortedActivities),
});

const setCurrentPDFPreview = (state, payload, loading) => {
  const preview = state.current.id === payload.referralId ? payload.data : null;

  return {
    ...state,
    current: {
      ...state.current,
      pdfPreview: {
        data: preview,
      },
    },
    meta: {
      ...state.meta,
      loadingPdfPreview: loading,
      errorLoadingPdfPreview: payload.message,
    },
  };
};

const loadExistingSourceDocument = (state, action) => {
  let sourceDocument = state.current.referralSourceDocument;

  if (sourceDocument.id === action.payload.sourceDocumentId) {
    sourceDocument = {
      ...state.current.referralSourceDocument,
      ...action.payload,
      id: action.payload.sourceDocumentId,
      fileId: action.payload.id,
    };
  }

  return {
    ...state,
    current: {
      ...state.current,
      referralSourceDocument: sourceDocument,
    },
    referralSourceDocumentFiles: {
      loading: false,
      loaded: true,
      all: concat(
        [action.payload],
        filter(state.referralSourceDocumentFiles?.all, x => x.id !== action.payload?.id),
      ),
    },
    meta: {
      ...state.meta,
      loadingCurrentSourceDocument: false,
      loadedCurrentSourceDocument: true,
      errorLoadingCurrentSourceDocument: null,
    },
  };
};

const updateReferralTasks = (state, action) => {
  // check when its null
  const tasks = map(state.current.activities.task, x => (x.id === action.payload?.id ? normaliseActivity(action.payload) : x));

  return {
    ...state,
    all: {
      ...state.all,
      [action.payload.referralId]: {
        ...state.all[action.payload.referralId],
        incompleteTaskCounts: size(filter(tasks, x => x.completedDateTimeUtc === null)),
      },
    },
    current: {
      ...state.current,
      activities: {
        ...state.current.activities,
        task: tasks,
      },
    },
  };
};

const removeReferralTask = (state, action) => {
  const tasks = filter(state.current.activities.task, x => x.referralTaskId !== action.payload.referralTaskId);

  return {
    ...state,
    all: {
      ...state.all,
      [action.payload.referralId]: {
        ...state.all[action.payload.referralId],
        incompleteTaskCounts: size(filter(tasks, x => x.completedDateTimeUtc === null)),
      },
    },
    current: {
      ...state.current,
      activities: {
        ...state.current.activities,
        task: tasks,
      },
    },
  };
};

const removeReferral = (state, action) => {
  const filteredAll = mapKeys(filter(state.all, x => x.referralId !== action.payload.referralId), x => x.id);
  const pages = reduce(state.pages, (results, page) => {
    const updatedPage = { ...page, ids: filter(page.ids, x => x !== action.payload.referralId) };
    return [...results, updatedPage];
  }, []);

  const referralsForWorkList = filter(state.referralsForWorkList, x => x !== action.payload.referralId);
  return {
    ...state,
    all: filteredAll,
    pages,
    pageInfo: { ...state.pageInfo, totalRecords: state.pageInfo.totalRecords - 1 },
    referralsForWorkList,
    current: null,
  };
};

const referralSourceDocumentFilesState = {
  all: null,
  loading: false,
  loaded: false,
};

const INITIAL_STATE = {
  all: {},
  pages: {},
  pageInfo: { pageNumber: 1, pageSize: REFERRALS_PAGE_SIZE, totalRecords: 0 },
  searchParams: {},
  referralsForWorkList: [],
  current: null,
  meta: {
    loading: false,
    error: null,
    loadingReports: false,
    errorLoadingReports: null,
    loadingAttachments: false,
    errorLoadingAttachments: null,
    loadingPdfPreview: true,
    errorLoadingPdfPreview: null,
    loadingAppointments: false,
    loadedAppointments: false,
    errorLoadingAppointments: null,
    loadingCurrentSourceDocument: false,
    loadedCurrentSourceDocument: false,
    errorLoadingCurrentSourceDocument: null,
    sendingReferralOutboundMessage: false,
    sendReferralOutboundMessage: false,
    errorSendingReferralOutboundMessage: null,
  },
  referralSourceDocumentFiles: referralSourceDocumentFilesState,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReferralWorkListActionTypes.SEARCHING_REFERRALS:
      return {
        ...state,
        referralsForWorkList: (action.payload.pageNumber === 1) ? [] : state.referralsForWorkList,
      };
    case ReferralWorkListActionTypes.SEARCHED_REFERRALS:
      const currentPageNumber = action.payload.pageInfo.pageNumber;

      // remove the ids which are include in previous page from currentPage
      const combinedList = Object.keys(state.pages)
        .filter(pageNumber => pageNumber < currentPageNumber) // Filter out pages after the current page
        .reduceRight((acc, pageNumber) => {
          const pageIds = state.pages[pageNumber].ids;
          return [...pageIds, ...acc]; // Combine the IDs of the previous page with the existing combinedList
        }, []);
      const currentPageReferrals = filter(action.payload.referrals, x => !includes(combinedList, x.id));
      const currentPageIds = map(currentPageReferrals, x => x.id);

      return {
        ...state,
        all: { ...state.all, ...mapKeys(currentPageReferrals, x => x.id) },
        pages: { ...state.pages, [currentPageNumber]: { loading: false, ids: currentPageIds } },
        pageInfo: action.payload.pageInfo,
        referralsForWorkList: (currentPageNumber === 1) ? currentPageIds : concat(state.referralsForWorkList, currentPageIds),
      };
    /**
    * getting referral source document data
    */
    case ActionTypes.LOADING_REFERRAL_SOURCE_DOCUMENT:
      return {
        ...state,
        currentSourceDocument: { ...state.currentSourceDocument, loading: true, loaded: false },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL_SOURCE_DOCUMENT:
      return {
        ...state,
        currentSourceDocument: {
          ...state.currentSourceDocument,
          loading: false,
          error: action.payload.message,
        },
      };
    case ActionTypes.LOADED_REFERRAL_SOURCE_DOCUMENT:
      return {
        ...state,
        currentSourceDocument: {
          base64File: action.payload.document,
          loading: false,
          loaded: true,
          error: null,
        },
      };
    /**
    * Loading single referral
    */
    case ActionTypes.LOADING_REFERRAL:
      return {
        ...state,
        meta: {
          ...state.meta,
          loading: true,
        },
      };
    case ActionTypes.LOADED_REFERRAL:
      return {
        ...state,
        current: {
          ...action.payload.referral,
          activities: {
            action: map(action.payload.actions, x => normaliseActivity(x)),
            note: map(action.payload.notes, x => normaliseActivity(x)),
            correspondence: map(action.payload.correspondence, x => normaliseActivity(x)),
            assignment: map(action.payload.assignments, x => normaliseActivity(x)),
            task: map(action.payload.tasks, x => normaliseActivity(x)),
            addAttachment: map(action.payload.addAttachments, x => normaliseAttachmentActivity(x)),
            removeAttachment: map(action.payload.removeAttachments, x => normaliseAttachmentActivity(x)),
            sortedActivities: map(action.payload.sortedActivities, x => normaliseSortedActivity(x)),
          },
        },
        meta: {
          loading: false,
          loaded: true,
          error: null,
        },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL:
      return {
        ...state,
        meta: {
          loading: false,
          loaded: false,
          error: action.payload.message,
        },
      };
    /**
     * Current referral Detail tab
     */
    case ActionTypes.LOADING_REFERRAL_REPORT:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingReports: true,
        },
      };
    case ActionTypes.LOADED_REFERRAL_REPORT:
      return {
        ...state,
        current: {
          ...state.current,
          reports: action.payload.reports,
        },
        meta: {
          ...state.meta,
          loadingReports: false,
          errorLoadingReports: null,
        },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL_REPORT:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingReports: false,
          errorLoadingReports: action.payload.message,
        },
      };
    /**
     * Current referral Attachment tab
     */
    case ActionTypes.LOADING_REFERRAL_ATTACHMENTS:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingAttachments: true,
        },
      };
    case ActionTypes.LOADED_REFERRAL_ATTACHMENTS:
      return {
        ...state,
        current: {
          ...state.current,
          attachments: action.payload.attachments,
        },
        meta: {
          ...state.meta,
          loadingAttachments: false,
          errorLoadingAttachments: null,
        },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL_ATTACHMENTS:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingAttachments: false,
          errorLoadingAttachments: action.payload.message,
        },
      };
    case ActionTypes.LOADED_REFERRAL_ATTACHMENT_FILE:
      return {
        ...state,
        current: {
          ...state.current,
          attachments: [
            ...map(state.current.attachments, attachment => ({
              ...updateAttachmentFile(attachment, action.payload),
            })),
          ],
        },
      };
      /**
     * Current referral Appointment tab
     */
    case ActionTypes.LOADING_REFERRAL_APPOINTMENTS:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingAppointments: true,
        },
      };
    case ActionTypes.LOADED_REFERRAL_APPOINTMENTS:
      return {
        ...state,
        current: {
          ...state.current,
          appointments: {
            ...mapKeys(action.payload.appointments, x => x.appointment.id),
          },
        },
        meta: {
          ...state.meta,
          loadingAppointments: false,
          errorLoadingAppointments: null,
          loadedAppointments: true,
        },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL_APPOINTMENTS:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingAppointments: false,
          errorLoadingAppointments: action.payload.message,
          loadedAppointments: false,
        },
      };

    case PatientActionTypes.UPDATED_NEXT_OF_KIN:
      return {
        ...state,
        current: updateCurrentReferralPatientContact(state.current, action.payload.patientId, action.payload.contact),
      };

    /**
     * Patient Identifier
     */
    case PatientActionTypes.SAVED_PATIENT_IDENTIFIER:
    case PatientActionTypes.UPDATED_PATIENT_IDENTIFIER:
      if (state.current) {
        return {
          ...state,
          current: {
            ...state.current,
            patient: updatePatientIdentifiers(state.current.patient, action.payload.patientIdentifier),
          },
        };
      }
      return state;
    case PatientActionTypes.DELETED_PATIENT_IDENTIFIER:
      if (state.current) {
        return {
          ...state,
          current: {
            ...state.current,
            patient: removePatientIdentifiers(state.current.patient, action.payload.patientIdentifier),
          },
        };
      }
      return state;
    /**
     * Referral actives
     * Correspondence, Notes, action actives, print, assign to
     */
    case ActionTypes.SAVED_REFERRAL_ASSIGNED_USER:
      // remove referral from list since already moved to different org unit.
      if (action.payload.orgUnitId !== action.payload.assignedOrgUnitId) {
        return removeReferral(state, action);
      }
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.referralId]: { ...state.all[action.payload.referralId], assignedTo: action.payload.assignedTo },
        },
        current: {
          ...state.current,
          assignedTo: action.payload.assignedTo,
          ...(action.payload.patientIdentifier && {
            patient: {
              ...state.current.patient,
              patientIdentifiers: [...state.current.patient.patientIdentifiers, action.payload.patientIdentifier],
            },
          }),
          activities: {
            ...state.current.activities,
            assignment: concat(normaliseActivity(action.payload), state.current.activities.assignment),
            sortedActivities: concat(normaliseSortedActivity({
              id: action.payload.id,
              type: "Assignment",
              sortDateTimeUtc: action.payload.assignedDateTimeUtc,
            }), state.current.activities.sortedActivities),
          },
        },
      };
    case ActionTypes.SAVED_REFERRAL_ASSIGNED_PATIENT_EDIT:
    case ActionTypes.SAVED_REFERRAL_ASSIGNED_PATIENT:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.referralId]: {
            ...state.all[action.payload.referralId],
            ...action.payload.referral,
            patient: action.payload.patient,
          },
        },
        current: {
          ...action.payload.referral,
          patient: action.payload.patient,
          activities: {
            ...state.current?.activities,
            action: map(action.payload.actions, x => normaliseActivity(x)),
            sortedActivities: map(action.payload.sortedActivities, x => normaliseSortedActivity(x)),
          },
        },
      };
    case ActionTypes.COMPLETED_REFERRAL:
    case ActionTypes.ARCHIVED_REFERRAL:
    case ActionTypes.REJECTED_REFERRAL:
    case ActionTypes.UNDONE_REFERRAL_ACTION:
    case ActionTypes.REQUEST_REFERRAL_ACTION:
    case ActionTypes.REQUESTED_MORE_INFO:
    case ActionTypes.ACCEPTED_REFERRAL:
    case ActionTypes.CANCELLED_REFERRAL:
    case ActionTypes.UPDATED_PROCESS_REFERRAL:
    case ActionTypes.UPDATED_DRAFT_REFERRAL:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.id]:
        {
          ...state.all[action.payload.id],
          triageCategory: action.payload.triageCategory,
          isUrgent: action.payload.isUrgent,
          referralStatus: action.payload.referralStatus,
          assignedTo: action.payload.assignedTo ? action.payload.assignedTo : state.all[action.payload.id]?.assignedTo,
        },
        },
        current: {
          ...state.current,
          triageCategory: action.payload.triageCategory,
          isUrgent: action.payload.isUrgent,
          referralStatus: action.payload.referralStatus,
          assignedTo: action.payload.assignedTo ? action.payload.assignedTo : state.current.assignedTo,
          activities: updateActivityActions(state.current.activities, action.payload),
          availableActions: action.payload.availableActions,
        },
      };
    case ActionTypes.UPDATED_PRESENTING_COMPLAINT:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.id]:
          {
            ...state.all[action.payload.id],
            triageCategory: action.payload.triageCategory,
            isUrgent: action.payload.isUrgent,
            referralStatus: action.payload.referralStatus,
            assignedTo: action.payload.assignedTo ? action.payload.assignedTo : state.all[action.payload.id]?.assignedTo,
          },
        },
        current: {
          ...state.current,
          triageCategory: action.payload.triageCategory,
          isUrgent: action.payload.isUrgent,
          referralStatus: action.payload.referralStatus,
          assignedTo: action.payload.assignedTo ? action.payload.assignedTo : state.current.assignedTo,
          activities: updateActivityActions(state.current.activities, action.payload),
          availableActions: action.payload.availableActions,
          presentingComplaintSummary: action.payload.presentingComplaintSummary,
          presentingComplaints: action.payload.presentingComplaints,
        },
      };
    case ActionTypes.REFERRAL_AS_NOT_URGENT:
    case ActionTypes.REFERRAL_AS_URGENT:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.id]:
          {
            ...state.all[action.payload.id],
            triageCategory: action.payload.triageCategory,
            isUrgent: action.payload.isUrgent,
            referralStatus: action.payload.referralStatus,
            assignedTo: action.payload.assignedTo ? action.payload.assignedTo : state.all[action.payload.id]?.assignedTo,
            referrerUrgency: action.payload.referrerUrgency,
          },
        },
        current: {
          ...state.current,
          triageCategory: action.payload.triageCategory,
          isUrgent: action.payload.isUrgent,
          referralStatus: action.payload.referralStatus,
          referrerUrgency: action.payload.referrerUrgency,
          assignedTo: action.payload.assignedTo ? action.payload.assignedTo : state.current.assignedTo,
          activities: updateActivityActions(state.current.activities, action.payload),
          availableActions: action.payload.availableActions,
        },
      };
    case ActionTypes.LOADED_PRINT_REFERRAL:
      return {
        ...state,
        current: {
          ...state.current,
          activities: updateActivityActions(state.current.activities, action.payload.action),
        },
      };
    case ActionTypes.CREATED_REFERRAL_NOTE:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.referralId]: { ...state.all[action.payload.referralId], noteCounts: (state.all[action.payload.referralId]?.noteCounts ?? 0) + 1 },
        },
        current: {
          ...state.current,
          activities: {
            ...state.current.activities,
            note: concat(normaliseActivity(action.payload), state.current.activities.note ?? []),
            sortedActivities: concat(normaliseSortedActivity({
              id: action.payload.id,
              type: "Note",
              sortDateTimeUtc: action.payload.addedDateTimeUtc,
            }), state.current.activities.sortedActivities),
          },
        },
      };
    case ActionTypes.SAVED_REFERRAL_NOTE:
      return {
        ...state,
        current: {
          ...state.current,
          activities: {
            ...state.current.activities,
            note: map(state.current.activities.note, x => (x.id === action.payload.id ? normaliseActivity(action.payload) : x)),
          },
        },
      };
    case ActionTypes.DELETED_REFERRAL_NOTE:
      const activityNotes = filter(state.current.activities.note, x => x.id !== action.payload.id);
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.referralId]: { ...state.all[action.payload.referralId], noteCounts: size(activityNotes) },
        },
        current: {
          ...state.current,
          activities: {
            ...state.current.activities,
            note: activityNotes,
          },
        },
      };
    case ActionTypes.CREATED_REFERRAL_CORRESPONDENCE:
      return {
        ...state,
        current: {
          ...state.current,
          activities: {
            ...state.current.activities,
            correspondence: concat(normaliseActivity(action.payload), state.current.activities.correspondence),
            sortedActivities: concat(normaliseSortedActivity({
              id: action.payload.id,
              type: "Correspondence",
              sortDateTimeUtc: action.payload.addedDateTimeUtc,
            }), state.current.activities.sortedActivities),
          },
        },
      };
    case ActionTypes.LOADED_REFERRAL_PDF_PREVIEW:
    case ActionTypes.ERROR_LOADING_REFERRAL_PDF_PREVIEW:
      return setCurrentPDFPreview(state, action.payload, false);
    case ActionTypes.LOADING_REFERRAL_PDF_PREVIEW:
      return setCurrentPDFPreview(state, action.payload, true);
    case ActionTypes.UPDATE_CURRENT_REFERRAL_PATIENT_CONTACT:
      return {
        ...state,
        current: updateCurrentReferralPatientContact(state.current, action.payload.patientId, action.payload.contact),
      };
    case ActionTypes.CREATE_MANUAL_REFERRAL:
      if (action.payload.referral.orgUnitId !== action.payload.orgUnitId) { return state; }

      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.referral.id]: action.payload.referral,
        },
        current: {
          ...action.payload.referral,
          activities: {
            action: map(action.payload.actions, x => normaliseActivity(x)),
            assignment: map(action.payload.assignments, x => normaliseActivity(x)),
            addAttachment: map(action.payload.addAttachments, x => normaliseAttachmentActivity(x)),
            removeAttachment: [],
            sortedActivities: map(action.payload.sortedActivities, x => normaliseSortedActivity(x)),
          },
        },
        referralsForWorkList: [action.payload.referral.id, ...state.referralsForWorkList ?? []],
        referralSourceDocumentFiles: referralSourceDocumentFilesState, // reset files object
      };
    case ActionTypes.EDIT_MANUAL_REFERRAL:
      if (action.payload.referral.orgUnitId !== action.payload.orgUnitId) {
        return removeReferral(state, action);
      }
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.referral.id]: {
            ...state.all[action.payload.referral.id],
            ...action.payload.referral,
          },
        },
        current: {
          ...action.payload.referral,
          activities: {
            ...state.current?.activities,
            action: map(action.payload.actions, x => normaliseActivity(x)),
            assignment: map(action.payload.assignments, x => normaliseActivity(x)),
            addAttachment: map(action.payload.addAttachments, x => normaliseAttachmentActivity(x)),
            removeAttachment: map(action.payload.removeAttachments, x => normaliseAttachmentActivity(x)),
            sortedActivities: map(action.payload.sortedActivities, x => normaliseSortedActivity(x)),
          },
        },
        meta: {
          loading: false,
          loaded: true,
          error: null,
        },
        referralSourceDocumentFiles: referralSourceDocumentFilesState, // reset files object
      };
    case ActionTypes.LOADED_CURRENT_REFERRAL:
      return {
        ...state,
        current: action.payload.referral,
      };
    case ActionTypes.CREATING_SOURCE_DOCUMENT_FILE:
    case ExternalDocumentActionTypes.LOADING_CURRENT_SOURCE_DOCUMENT_FILE:
      return {
        ...state,
        referralSourceDocumentFiles: {
          ...state.referralSourceDocumentFiles,
          loading: true,
        },
      };
    case ActionTypes.CREATED_SOURCE_DOCUMENT_FILE:
    case ExternalDocumentActionTypes.LOADED_CURRENT_SOURCE_DOCUMENT_FILE:
      return {
        ...state,
        // support multiple later on
        referralSourceDocumentFiles: {
          loading: false,
          loaded: true,
          all: concat(
            [{ ...action.payload, base64File: action.payload?.fileData || action.payload.base64File }],
            filter(state.referralSourceDocumentFiles?.all, x => x.id !== action.payload?.id),
          ),
        },
      };
    case ActionTypes.CLEAR_SOURCE_DOCUMENT_FILES:
      return {
        ...state,
        referralSourceDocumentFiles: referralSourceDocumentFilesState,
      };
    case ActionTypes.LOADED_EXISTING_SOURCE_DOCUMENT_FILE:
      return loadExistingSourceDocument(state, action);
    case ActionTypes.LOADING_EXISTING_SOURCE_DOCUMENT_FILE:
      return {
        ...state,
        meta: {
          ...state.meta,
          loadingCurrentSourceDocument: true,
        },
      };
    case ActionTypes.ERROR_EXISTING_SOURCE_DOCUMENT_FILE:
      return {
        ...state,
        meta: {
          ...state.meta,
          errorLoadingCurrentSourceDocument: action.payload.message,
        },
      };
    case ActionTypes.LOADING_REFERRAL_ACCESS_LOG:
      return {
        ...state,
        current: {
          ...state.current,
          referralAccessLog: {
            loading: true,
          },
        },
      };
    case ActionTypes.LOADED_REFERRAL_ACCESS_LOG:
      return {
        ...state,
        current: {
          ...state.current,
          referralAccessLog: {
            loading: false,
            log: action.payload.referralAccessLog,
            errorLoadingReferralAccessLog: null,
            loaded: true,
          },
        },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL_ACCESS_LOG:
      return {
        ...state,
        current: {
          ...state.current,
          referralAccessLog: {
            loading: false,
            errorLoadingReferralAccessLog: action.payload.message,
            log: null,
            loaded: false,
          },
        },
      };
    case ActionTypes.CREATING_REFERRAL_ACTION_ACCESS_LOG:
      return {
        ...state,
        current: {
          ...state.current,
          warningReferralAccessLog: {
            loading: true,
          },
        },
      };
    case ActionTypes.CREATED_REFERRAL_ACTION_ACCESS_LOG:
      return {
        ...state,
        current: {
          ...state.current,
          warningReferralAccessLog: {
            loading: false,
            logs: action.payload.warningReferralAccessLogs,
            error: null,
            loaded: true,
          },
        },
      };
    case ActionTypes.ERROR_CREATING_REFERRAL_ACTION_ACCESS_LOG:
      return {
        ...state,
        current: {
          ...state.current,
          warningReferralAccessLog: {
            loading: false,
            error: action.payload.message,
            log: null,
            loaded: false,
          },
        },
      };
    case ActionTypes.RESET_CURRENT_REFERRAL:
      return {
        ...state,
        current: null,
      };
    case ActionTypes.CREATED_REFERRAL_TASK:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.referralId]: { ...state.all[action.payload.referralId], incompleteTaskCounts: (state.all[action.payload.referralId]?.incompleteTaskCounts ?? 0) + 1 },
        },
        current: {
          ...state.current,
          activities: {
            ...state.current.activities,
            task: concat(normaliseActivity(action.payload), state.current.activities.task ?? []),
            sortedActivities: concat(normaliseSortedActivity({
              id: action.payload.id,
              type: "Task",
              sortDateTimeUtc: action.payload.addedDateTimeUtc,
            }), state.current.activities.sortedActivities),
          },
        },
      };
    case ActionTypes.SAVED_REFERRAL_TASK:
      return updateReferralTasks(state, action);
    case ActionTypes.DELETED_REFERRAL_TASK:
      return removeReferralTask(state, action);
    case ActionTypes.SAVED_REFERRAL_ATTACHMENT:
      return {
        ...state,
        current: {
          ...state.current,
          attachmentCount: state.current.attachmentCount + 1,
          attachments: [...state.current.attachments, action.payload.attachment],
          activities: {
            ...state.current.activities,
            addAttachment: concat(normaliseAttachmentActivity(action.payload.attachment), state.current.activities.addAttachment ?? []),
            sortedActivities: concat(normaliseSortedActivity({
              id: action.payload.attachment.id,
              type: "addAttachment",
              sortDateTimeUtc: action.payload.attachment.addedDateTimeUtc,
              user: action.payload.attachment.addedBy,
              canPreview: true,
            }), filter(state.current.activities.sortedActivities, x => x.id !== action.payload.referralAttachmentId)),
          },
        },
      };
    case ActionTypes.DELETED_REFERRAL_ATTACHMENT:
      return {
        ...state,
        current: {
          ...state.current,
          attachmentCount: state.current.attachmentCount - 1,
          attachments: filter(state.current.attachments, x => x.id !== action.payload.referralAttachmentId),
          activities: {
            ...state.current.activities,
            removeAttachment: concat(normaliseAttachmentActivity(action.payload), state.current.activities.removeAttachment ?? []),
            sortedActivities: concat(normaliseSortedActivity({
              id: action.payload.id,
              type: "removeAttachment",
              sortDateTimeUtc: action.payload.removedDateTimeUtc,
              user: action.payload.removedBy,
              canPreview: false,
            }), state.current.activities.sortedActivities),
          },
        },
      };
    case ActionTypes.SENDING_REFERRAL_OUTBOUND_MESSAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          sendingReferralOutboundMessage: true,
        },
      };
    case ActionTypes.SENT_REFERRAL_OUTBOUND_MESSAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          sendingReferralOutboundMessage: false,
          sendReferralOutboundMessage: true,
        },
      };
    case ActionTypes.ERROR_SENDING_REFERRAL_OUTBOUND_MESSAGE:
      return {
        ...state,
        meta: {
          ...state.meta,
          sendingReferralOutboundMessage: false,
          sendReferralOutboundMessage: false,
          errorSendingReferralOutboundMessage: action.payload.message,
        },
      };
    /*
     *
     *
     *  Referral changes initiated by other user
     *
     *
    */
    case ActionTypes.REFERRAL_STATUS_CHANGED_BY_OTHER_USER:
      if (get(state, ["all", action.detail.referralId], null)) {
        return {
          ...state,
          current: updateCurrentReferralFromOtherUser(state.current, action.detail),
          all: {
            ...state.all,
            [action.detail.referralId]: updateReferralInfoFromOtherUser(state.all[action.detail.referralId], action.detail),
          },
        };
      }
      return {
        ...state,
        current: updateCurrentReferralFromOtherUser(state.current, action.detail),
      };

    default:
      return state;
  }
};

export const getReferralById = curry(({ localState }, referralId) => {
  const referrals = get(localState, ["all"], {});
  const referral = find(referrals, x => x.id === referralId);
  return referral;
});

export const getAllReferrals = curry(({ localState }) => {
  const referrals = get(localState, ["all"], {});
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return map(get(localState.pages, [pageNumber, "ids"], []), key => referrals[key]);
});

export const isPageLoading = curry(({ localState }) => {
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", pageNumber, "loading"], false);
});

export const isPageLoaded = curry(({ localState }) => {
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", pageNumber, "loading"], null) === false;
});

export const isReferralLoading = curry(({ localState }) => get(localState, ["meta", "loading"], false));

export const getReferralError = curry(({ localState }) => get(localState, ["meta", "error"], null));

export const getErrorMessage = curry(({ localState }) => {
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", pageNumber, "error"], null);
});

export const getReferralsForWorklist = curry(({ localState }) => {
  const referrals = get(localState, ["all"], {});
  const referralsForWorkList = uniq(map(localState.referralsForWorkList, key => referrals[key]), "referralId");
  return referralsForWorkList;
});

export const getReferralSearchTerms = curry(({ localState }) => localState.searchParams);

export const getPageInfo = curry(({ localState }) => localState.pageInfo);

export const getCurrentReferralAttachmentFiles = curry(({ localState }, referralId) => {
  const referral = get(localState, ["current"], false);
  if (referral && referral.id === referralId) {
    return referral.attachments;
  }
  return null;
});

export const getCurrentReferral = curry(({ localState }) => get(localState, ["current"], null));

export const getActivityByTypeAndId = curry(({ localState }, type, id) => {
  const referralActivities = get(localState, ["current", "activities"], null);
  if (referralActivities) {
    return { type: camelCase(type), detail: find(referralActivities[camelCase(type)], x => x.id === id) };
  }
  return null;
});

export const getCurrentReferralSortedActivities = curry(({ localState }) => get(localState, ["current", "activities", "sortedActivities"], null));

export const getCurrentReferralNotes = curry(({ localState }) => get(localState, ["current", "activities", "note"], null));

export const currentReferralReportIsLoading = curry(({ localState }) => get(localState, ["meta", "loadingReports"], false));

export const errorLoadingCurrentReferralReport = curry(({ localState }) => get(localState, ["meta", "errorLoadingReports"], false));

export const currentReferralAttachmentIsLoading = curry(({ localState }) => get(localState, ["meta", "loadingAttachments"], false));

export const errorLoadingCurrentReferralAttachments = curry(({ localState }) => get(localState, ["meta", "errorLoadingAttachments"], false));

export const currentReferralAppointmentsLoading = curry(({ localState }) => get(localState, ["meta", "loadingAppointments"], false));

export const errorLoadingCurrentReferralAppointments = curry(({ localState }) => get(localState, ["meta", "errorLoadingAppointments"], false));

export const getReferralPDFPreview = curry(({ localState }) => get(localState, ["current", "pdfPreview", "data"], null));

export const isReferralPDFPreviewLoading = curry(({ localState }) => get(localState, ["meta", "loadingPdfPreview"], false));

export const errorLoadingReferralPDFPreview = curry(({ localState }) => get(localState, ["meta", "errorLoadingPdfPreview"], false));

export const getCurrentReferralAppointments = curry(({ localState }) => get(localState, ["current", "appointments"], null));

export const getDefaultReferralSourceDocumentFile = curry(({ localState }) => {
  if (!localState.referralSourceDocumentFiles?.all) {
    return null;
  }

  return head(localState.referralSourceDocumentFiles.all);
});

export const getReferralAccessLog = curry(({ localState }) => get(localState, ["current", "referralAccessLog", "log"], null));

export const isReferralAccessLogLoading = curry(({ localState }) => get(localState, ["current", "referralAccessLog", "loading"], null));

export const isReferralSourceDocumentFileLoading = curry(({ localState }) => get(localState, ["referralSourceDocumentFiles", "loading"], false));

export const getCurrentReferralSourceDocumentFile = curry(({ localState }) => get(localState, ["current", "referralSourceDocument"], null));

export const isCurrentReferralSourceDocumentFileLoading = curry(({ localState }) => get(localState, ["meta", "loadingCurrentSourceDocument"], null));

export const errorLoadingCurrentReferralSourceDocumentFile = curry(({ localState }) => get(localState, ["meta", "errorLoadingCurrentSourceDocument"], null));

export const isSendingReferralOutboundMessage = curry(({ localState }) => get(localState, ["meta", "sendingReferralOutboundMessage"], null));

export const getErrorSendingReferralOutboundMessage = curry(({ localState }) => get(localState, ["meta", "errorSendingReferralOutboundMessage"], false));

export const getWarningReferralAccessLog = curry(({ localState }) => get(localState, ["current", "warningReferralAccessLog", "logs"], null));

export const isWarningReferralAccessLogLoading = curry(({ localState }) => get(localState, ["current", "warningReferralAccessLog", "loading"], null));
