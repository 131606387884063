
import React from "react";
import IconButton from "components/items/icon-button";

const ReferralDisabledItem = ({
  orgUnitId,
  referralOrgUnitName,
  referralOrgUnitId,
}) => (<IconButton disabled title={referralOrgUnitId !== orgUnitId ? `Please login to ${referralOrgUnitName} to view this referral` : "You do not have the correct permission to view this referral"} icon="visibility_off" />);

export default ReferralDisabledItem;
