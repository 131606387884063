import React from "react";
import * as Yup from "yup";
import { Field } from "formik";

import Form from "components/form";
import { CheckboxInput, DateTimePicker } from "components/inputs";
import { TextInputField } from "components/inputs/text-input";

const NameLabel = "Name";
const DeprecatedLabel = "Deprecated";
const DeprecatedDateLabel = "Deprecated Date";

const schema = Yup.object().shape({
  name: Yup.string().trim().max(200, "Too Long!").required(`${NameLabel} is required`)
    .nullable(),
  isDeprecated: Yup.boolean().nullable(),
});

const SubSpecialtyForm = ({
  subSpecialty,
  handleSubmit,
  handleRemove,
  ...other
}) => (
  <Form
    initialValues={subSpecialty}
    onSubmit={handleSubmit}
    onRemove={subSpecialty?.enableDelete ? () => handleRemove(subSpecialty) : null}
    contentProps={other}
    validationSchema={schema}
    content={({ values }) => (
      <>
        <Field
          name="name"
          component={TextInputField}
          label={NameLabel}
          showIcon={false}
        />
        {values?.id && (
          <>
            <Field
              name="isDeprecated"
              component={CheckboxInput}
              label={DeprecatedLabel}
              showIcon={false}
            />
            {values.isDeprecated && values.deprecatedDateUtc && (
            <Field
              name="deprecatedDateUtc"
              component={DateTimePicker}
              label={DeprecatedDateLabel}
              showIcon={false}
              disabled
            />
            )}
          </>
        )}
      </>
    )}
  />
);

export default SubSpecialtyForm;
