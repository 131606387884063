import React, { useMemo, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { makeStyles } from "@material-ui/core/styles";
import LoadingState from "components/items/loading-state";
import { CloudFileUpload } from "helpers/icon-finder";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  dropZone: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#777",
    outline: "none",
    transition: "border .24s ease-in-out",
    justifyContent: "center",
    cursor: "pointer",
  },
  dropZoneFocused: {
    borderColor: "#2196f3",
  },
  dropZoneAccept: {
    borderColor: "#00e676",
  },
  dropZoneReject: {
    borderColor: "#ff1744",
  },
}));

function DropzoneAttachment({
  onFileUpload,
  disabled = false,
  loading,
  disabledLabel,
  label,
  helperText,
  extraParameters,
  isAcceptAllFormat = false,
  acceptFormat = {
    "application/pdf": [".pdf"],
  },
}) {
  const classes = useStyles();

  const onDrop = useCallback(acceptedFile => {
    if (acceptedFile) {
      const file = acceptedFile[0];
      if (onFileUpload) {
        onFileUpload(file, extraParameters);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extraParameters]);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
    maxFiles: 1,
    accept: isAcceptAllFormat ? undefined : acceptFormat,
    disabled,
  });

  const dropZoneClass = useMemo(() => [
    classes.dropZone,
    isFocused && classes.dropZoneFocused,
    isDragAccept && classes.dropZoneAccept,
    isDragReject && classes.dropZoneReject,
  ].filter(Boolean).join(" "),
  [isFocused, isDragAccept, isDragReject, classes]);

  return (
    <div {...getRootProps({ className: dropZoneClass })}>
      <input {...getInputProps()} />
      {disabled && disabledLabel && <p>{disabledLabel}</p>}
      {loading && <LoadingState />}
      {!disabled && !loading && (
        <>
          <Typography variant="subtitle1">{label ?? "Drag 'n' drop a pdf source document here, or click to select file"}</Typography>
          {helperText && <Typography variant="caption">{helperText}</Typography>}
          <CloudFileUpload size={48} />
        </>
      )}
    </div>
  );
}

export default DropzoneAttachment;
