/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { isEmpty, find, includes, compact, map } from "lodash";
import { withStyles } from "@material-ui/core/styles";
import { Grid, Typography, Badge } from "@material-ui/core";
import FileSendOutlineIcon from "mdi-react/FileSendOutlineIcon";
import AccountEditOutlineIcon from "mdi-react/AccountEditOutlineIcon";

import { printReferralReport, fetchReferral, sendReferralOutboundMessage } from "app/main/referrals/actions/referrals.actions";
import { isReferralLoading, getReferralError, getCurrentReferral } from "app/main/referrals/reducers/referrals.reducers";
import { clearUploadAttachment } from "app/main/referralDocumentAttachmentUploads/actions/referralDocumentAttachmentUploads.actions";
import { getReferralActionTypeSettings, getReferralWorklistSettings, getSendOutboundMessageStates, getEnableWarningMessageForPrintWithAttachments, getWarningTextForPrintWithAttachments, getAllowReassignPatientStates, getEnableStreamlinedAddReferral } from "app/auth/store/reducers/system-configuration";
import { printActionType, cancelActionType, editActionType } from "app/main/referrals/helpers/action-type-names";
import { cancelledWorkflowState } from "app/main/referrals/helpers/workflow-state-names";
import ReferralHeader from "app/main/referrals/components/referral-header";
import DefaultTabBar from "components/items/default-tab-bar";
import withPermissions from "permissions/withPermissions";
import LoadingState from "components/items/loading-state";
import ErrorState from "components/items/error-state";
import ReferralAssignTo from "app/main/referralWorklist/components/referral-assign-to";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import AddReferralForm from "app/main/referrals/components/add-referral-form";
import downloadFromApi, { openPdf } from "utils/download-from-api";
import { RecordOutlineIcon } from "helpers/icon-finder";
import CancelReferralForm from "app/main/referrals/components/cancel-referral-form";
import IconButton from "components/items/icon-button";
import { OverviewPanel, DetailPanel, PreviewPanel, AttachmentPanel, AppointmentPanel, SourceDocumentPanel } from "app/main/referralWorklist/components/panels";
import PrintDialog from "components/items/print-dialog";
import useOpenConfigFormByProfile from "hooks/use-open-config-form-by-profile";
import SendReferralOutboundDialog from "app/main/referrals/components/send-referral-outbound-dialog";
import { showMessage } from "app/store/actions/message.actions";
import EmptyState from "components/items/empty-state";
import ReferralAssignmentPatientDialog from "app/main/referrals/components/referral-assignment-patient-dialog";

import ReferralActionWarningMessage from "app/main/referrals/utils/referral-action-warning-message";
import { getWarningMessages } from "../helpers/get-referral-action-warning-messages";

const TabBadge = withStyles(theme => ({
  badge: {
    transform: "none",
    position: "relative",
    marginLeft: theme.spacing(0.5),
  },
}))(Badge);

const Header = ({
  state,
  setState,
  orgUnitId,
  referral,
  tabs,
  getActionSettings,
  hasPermissionReferralsView,
  hasPermissionReferralsPrint,
  hasPermissionReferralsPdfDownload,
  loading,
  showMedicalRecordButton,
  sendOutboundMessageStates,
  referralWorklistSettings,
  hasPermissionReferralSendOutboundMessage,
  hasPermissionReferralsAssignedPatientEdit,
  allowReassignPatientStates,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const enableStreamlinedAddReferral = useSelector(getEnableStreamlinedAddReferral);
  const handleCloseDialog = () => {
    dispatch(closeDialog());
    dispatch(clearUploadAttachment());
  };

  const onEditReferral = () => {
    ReferralActionWarningMessage(orgUnitId, referral.id, "Edit");
    dispatch(openDialog({
      maxWidth: "xl",
      children: <AddReferralForm title="Edit Referral" orgUnitId={orgUnitId} currentReferral={referral} onCancel={handleCloseDialog} onSucceed={handleCloseDialog} warningMessage={getActionSettings(editActionType).warningMessage} isEdit enableProcessOCR={referral.isManualReferral} disabledRemove={!referral.isManualReferral} />,
    }));
  };

  const { loadingConfigurations, openConfigForm } = useOpenConfigFormByProfile(referral?.orgUnitId, referral?.formFieldConfigurationId, onEditReferral);

  if (loading) return <LoadingState />;

  if (isEmpty(referral)) return <EmptyState />;

  const { id, permissions, referralDocument, referralSourceDocument, assignedTo, referralStatus } = referral;
  const manualReferral = referral?.referralDocument.referralDocumentType === "Manual";
  const printActionLabel = getActionSettings(printActionType)?.nameSingle || "Print";
  const cancelActionSettings = getActionSettings(cancelActionType);
  const cancelActionLabel = cancelActionSettings?.nameSingle || "Cancel";
  const isCancelled = referral?.referralStatus === cancelledWorkflowState;

  const canCancel = manualReferral && permissions.canCancel && includes(referral.availableActions, cancelActionType);
  const canEdit = permissions.canEdit && includes(referral.availableActions, editActionType);
  const canSendOutboundMessage = hasPermissionReferralSendOutboundMessage && includes(sendOutboundMessageStates, referral?.referralStatus);
  const canReassignPatientStates = hasPermissionReferralsAssignedPatientEdit && includes(allowReassignPatientStates, referralStatus) && !isEmpty(referral?.patient) && referral?.isManualReferral;
  const referredToSpecialty = assignedTo ? { label: assignedTo?.assignedToSpecialtyRoleDisplay, value: assignedTo?.assignedToSpecialtyId } : null;

  const handleTabChange = value => {
    setState(prev => ({ ...prev, currentTab: value }));
  };

  const downloadPdf = () => downloadFromApi(`api/referrals/${orgUnitId}/GetPdf/${id}?recordedAction=${false}`);

  const handleReAssignPatient = () => dispatch(openDialog({
    maxWidth: enableStreamlinedAddReferral ? "xl" : "md",
    children: (
      <ReferralAssignmentPatientDialog
        referralId={referral.id}
        disabledRemove={!referral.isManualReferral}
        enableProcessOCR={referral.isManualReferral}
        orgUnitId={orgUnitId}
        referredToSpecialty={referredToSpecialty}
        referralSourceDocument={referralSourceDocument}
        onSucceed={referralId => { handleCloseDialog(); history.push(`/referralWorklist?referralId=${referralId}`); }}
        onClose={handleCloseDialog}
        title="Re-Assign Patient"
        isReAssignPatient
      />
    ),
  }, enableStreamlinedAddReferral));

  const handleCancel = () => {
    const cancelSettings = getActionSettings(cancelActionType);
    dispatch(openDialog({
      children: (
        <CancelReferralForm
          title={cancelSettings?.formTitle || "Cancel Referral"}
          referralId={referral.id}
          warningMessages={getWarningMessages(cancelSettings, referralDocument.referralDocumentType)}
          onSucceed={handleCloseDialog}
          orgUnitId={orgUnitId}
        />),
    }));
  };

  const printContent = () => {
    if (permissions.canView) {
      return (
        <IconButton
          icon="print"
          title={printActionLabel}
          loading={state.generatingPDF}
          disabled={!hasPermissionReferralsPrint}
          onClick={() => setState(prev => ({ ...prev, showGeneratingPDF: true }))}
        />
      );
    }
    return <div />;
  };

  return (
    <Grid container>
      <ReferralHeader referral={referral} showMedicalRecordButton={showMedicalRecordButton} orgUnitId={orgUnitId} />
      <DefaultTabBar
        tabs={tabs}
        currentTab={state.currentTab}
        onChange={(_event, value) => handleTabChange(value)}
        content={permissions.canView && <ReferralAssignTo referral={referral} disabled={!permissions.canAssign} orgUnitId={orgUnitId} />}
        rightContent={(
          <div>
            {isCancelled ? printContent() : (
              <>
                {(manualReferral && canEdit) && (
                  <IconButton
                    onClick={openConfigForm}
                    icon={<RecordOutlineIcon />}
                    title="Edit"
                    loading={loadingConfigurations}
                  />
                )}
                {canReassignPatientStates && (
                  <IconButton
                    onClick={handleReAssignPatient}
                    icon={<AccountEditOutlineIcon />}
                    title="Re-Assign Patient"
                  />
                )}
                <>
                  {printContent()}
                  {permissions.canView && hasPermissionReferralsPdfDownload && (
                  <IconButton
                    onClick={downloadPdf}
                    icon="attachment"
                    title="Pdf"
                    disabled={!hasPermissionReferralsView}
                  />
                  )}
                </>
                {canCancel && (
                <IconButton
                  onClick={handleCancel}
                  icon={cancelActionSettings?.icon ?? "cancel"}
                  title={cancelActionLabel}
                />
                )}
              </>
            )}
            {canSendOutboundMessage && (
            <IconButton
              onClick={() => setState(prev => ({ ...prev, showSendingReferralOutbound: true }))}
              icon={<FileSendOutlineIcon />}
              title={referralWorklistSettings?.referralSendOutboundMessageDialogTitle}
            />
            )}
          </div>
        )}
      />
    </Grid>
  );
};

const Content = ({
  state,
  setState,
  referral,
  getActionSettings,
  width,
  loading,
  tabs,
  orgUnitId,
}) => {
  const dispatch = useDispatch();
  const referralLoadingError = useSelector(getReferralError);
  const enableWarningMessageForPrintWithAttachments = useSelector(getEnableWarningMessageForPrintWithAttachments);
  const warningTextForPrintWithAttachments = useSelector(getWarningTextForPrintWithAttachments);

  const pdfWidth = width > 960 ? 960 : width;

  if (loading) return <LoadingState />;

  if (referralLoadingError) {
    return <ErrorState title={referralLoadingError} />;
  }

  const referralId = referral?.id;
  const { currentTab, generatingPDF, showGeneratingPDF } = state;

  const handlePrintPopup = () => {
    setState(prev => ({ ...prev, generatingPDF: true }));
    dispatch(printReferralReport(orgUnitId, referralId)).then(res => {
      if (!res.error) {
        openPdf(`api/referrals/${orgUnitId}/GetPdf/${referralId}`).then(() => {
          setState(prev => ({ ...prev, generatingPDF: false, showGeneratingPDF: false }));
        });
      }
    });
  };

  const handleClosePrintDialog = () => setState(prev => ({ ...prev, showGeneratingPDF: false }));

  const printConfirmationPopup = () => {
    const printActionSettings = getActionSettings(printActionType);

    return (
      <PrintDialog
        open={showGeneratingPDF}
        loading={generatingPDF}
        title={printActionSettings?.formTitle}
        onCancel={handleClosePrintDialog}
        onClose={handleClosePrintDialog}
        warningMessages={getWarningMessages(printActionSettings, referral.referralDocument.referralDocumentType)}
        content={(
          <>
            <Typography align="center" gutterBottom>{`Please confirm you wish to ${printActionSettings?.nameSingle?.toLowerCase() || "print"} the referral`}</Typography>
            {enableWarningMessageForPrintWithAttachments && referral.attachmentCount > 0 && <Typography variant="body1" className="font-bold" align="center" gutterBottom>{warningTextForPrintWithAttachments}</Typography>}
          </>
        )}
        printLabel={printActionSettings?.nameSingle}
        onPrint={handlePrintPopup}
      />
    );
  };

  const onSendOutboundMessage = (isNewReferral = false) => {
    setState(prev => ({ ...prev, sendingOutboundMessage: true }));
    dispatch(sendReferralOutboundMessage(orgUnitId, referralId, isNewReferral)).then(response => {
      if (response?.error === true) {
        setState(prev => ({ ...prev, sendingOutboundMessage: false }));
      } else {
        setState(prev => ({ ...prev, sendingOutboundMessage: false }));
        setState(prev => ({ ...prev, showSendingReferralOutbound: false }));
        dispatch(showMessage({
          variant: "success",
          message: "Successfully queued an update",
        }));
      }
    });
  };

  const handleCloseSendOutboundMessageDialog = () => setState(prev => ({ ...prev, showSendingReferralOutbound: false }));

  const sendingOutboundMessageConfirmationPopup = () => (
    <SendReferralOutboundDialog
      open={state.showSendingReferralOutbound}
      loading={state.sendingOutboundMessage}
      onCancel={handleCloseSendOutboundMessageDialog}
      onClose={handleCloseSendOutboundMessageDialog}
      onSend={onSendOutboundMessage}
    />
  );

  return (
    <>
      {printConfirmationPopup()}
      {sendingOutboundMessageConfirmationPopup()}
      {map(tabs, tab => {
        if (tab.key !== currentTab) return undefined;
        return <React.Fragment key={tab.key}>{tab.component({ orgUnitId, referral, pdfWidth })}</React.Fragment>;
      })}
    </>
  );
};

const ReferralContent = ({ referralId, renderContent, hasPermissionAppointmentsView, orgUnitId, ...other }) => {
  const dispatch = useDispatch();
  const INITIAL_STATE = {
    initialLoad: true,
    currentTab: 0,
    showGeneratingPDF: false,
    showSendingReferralOutbound: false,
  };
  const [state, setState] = useState(INITIAL_STATE);
  const loading = useSelector(isReferralLoading);
  const currentReferral = useSelector(getCurrentReferral);
  const referralActionSettings = useSelector(getReferralActionTypeSettings);
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const sendOutboundMessageStates = useSelector(getSendOutboundMessageStates);
  const allowReassignPatientStates = useSelector(getAllowReassignPatientStates);
  const currentLoading = state.initialLoad || loading;
  const canView = currentReferral?.permissions?.canView;

  const getActionSettings = actionType => find(referralActionSettings, x => x.actionType === actionType);

  const resetCurrentTab = () => {
    if (state.currentTab !== 0) {
      setState(prevState => ({ ...prevState, currentTab: 0 }));
    }
  };

  useEffect(() => {
    let isMounted = true;
    if ((referralId && isEmpty(currentReferral))) {
      dispatch(fetchReferral(orgUnitId, referralId)).then(() => {
        if (isMounted) {
          setState(prev => ({ ...prev, initialLoad: false }));
        }
      });
    } else {
      setState(prev => ({ ...prev, initialLoad: false }));
    }
    return () => {
      isMounted = false;
    };
  }, [referralId, currentReferral]);

  const getAttachmentLabel = x => {
    if (x && x.attachmentCount) {
      return (
        <TabBadge badgeContent={x.attachmentCount} color="secondary">
          Attachments
        </TabBadge>
      );
    }
    return "Attachments";
  };

  const getSourceDocumentLabel = x => {
    if (x && x.referralSourceDocument) {
      return (
        <TabBadge variant="dot" color="secondary">
          Source Document
        </TabBadge>
      );
    }
    return "Source Document";
  };

  let tabs = compact([
    { label: "Overview", component: props => <OverviewPanel {...props} /> },
    canView && { label: "Details", component: props => <DetailPanel {...props} /> },
    canView && { label: getSourceDocumentLabel(currentReferral), component: props => <SourceDocumentPanel {...props} /> },
    canView && { label: getAttachmentLabel(currentReferral), component: props => <AttachmentPanel {...props} /> },
    canView && { label: "Preview", component: props => <PreviewPanel {...props} /> },
    canView && hasPermissionAppointmentsView && { label: "Appointments", component: props => <AppointmentPanel {...props} /> },
  ]);

  tabs = map(tabs, (r, key) => ({ ...r, key }));

  const childrenProps = {
    referral: currentReferral,
    getActionSettings,
    state,
    setState,
    loading: currentLoading,
    orgUnitId,
    tabs,
  };

  return renderContent({
    loading: currentLoading,
    currentReferral,
    orgUnitId,
    currentTab: state.currentTab,
    resetTab: () => resetCurrentTab(),
    header: props => (
      <Header
        referralWorklistSettings={referralWorklistSettings}
        sendOutboundMessageStates={sendOutboundMessageStates}
        allowReassignPatientStates={allowReassignPatientStates}
        {...childrenProps}
        {...props}
        {...other}
      />
    ),
    content: props => (
      <Content
        {...childrenProps}
        {...props}
      />
    ),
  });
};

export default withPermissions(
  "ReferralsView",
  "ReferralsPrint",
  "ReferralsPdfDownload",
  "AppointmentsView",
  "ReferralSendOutboundMessage",
  "ReferralsAssignedPatientEdit",
)(ReferralContent);
