import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { includes, filter, map, startCase, uniqBy, concat, orderBy } from "lodash";
import { getSpecialtyRoles, areSpecialtyRolesLoading } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchSpecialtyRoles } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { AssignedSpecialtyIcon } from "helpers/icon-finder";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import { getAllAssignableRoles } from "app/auth/store/reducers/system-configuration";

export default ({
  label,
  specialtyIds,
  loadOptions,
  orgUnitId,
  showIcon = true,
  isAssignment = false,
  ...other
}) => {
  const dispatch = useDispatch();
  const specialtyRoles = useSelector(getSpecialtyRoles);
  const loading = useSelector(areSpecialtyRolesLoading);
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);
  const allAssignableRoles = useSelector(getAllAssignableRoles);
  const { field: { value: selectedValue } } = other;

  const selectedSpecialtyRoles = filter(allAssignableRoles, x => includes(selectedValue, x.roleId));
  const options = orderBy(uniqBy(concat(specialtyRoles, selectedSpecialtyRoles), "roleId"), ["roleDescription", "roleName"]);

  React.useEffect(() => {
    if (loadOptions) {
      dispatch(fetchSpecialtyRoles(signedInOrgUnit?.id, specialtyIds, orgUnitId, isAssignment));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(specialtyIds), orgUnitId]);

  if (loading) return <AutoCompleteLoading label={label} />;

  return (
    <AutoComplete
      icon={showIcon && <AssignedSpecialtyIcon />}
      label={label}
      options={map(options, x => ({ label: x.roleDescription ?? startCase(x.roleName), value: x.roleId }))}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      multiple
      {...other}
    />
  );
};
