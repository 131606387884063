import curryConnector from "utils/connectors";
import { get, mapKeys } from "lodash";

export const INTEGRATION_LOGS_STATE_KEY = "integrationLogs";
export const INTEGRATION_LOGS_PAGE_SIZE = 10;

const curry = fn => curryConnector(fn, INTEGRATION_LOGS_STATE_KEY);

export const ActionTypes = {
  GET_INTEGRATION_LOGS_LIST: "GET_INTEGRATION_LOGS_LIST",
  LOADING_INTEGRATION_LOGS_LIST: "LOADING_INTEGRATION_LOGS_LIST",
  LOADED_INTEGRATION_LOGS_LIST: "LOADED_INTEGRATION_LOGS_LIST",
  ERROR_LOADING_INTEGRATION_LOGS_LIST: "ERROR_LOADING_INTEGRATION_LOGS_LIST",
};

export const IntegrationDirections = {
  Incoming: "Incoming",
  Outgoing: "Outgoing",
};

export const DefaultSearchParams = {
  direction: IntegrationDirections.Incoming,
  patientIdentifier: "",
  referralNumber: "",
  dateRanges: null,
  startDateRange: null,
  endDateRange: null,
  status: null,
  messageType: "",
  messageId: "",
};

const INITIAL_STATE = {
  all: {},
  pageInfo: { pageNumber: 1, pageSize: INTEGRATION_LOGS_PAGE_SIZE, totalRecords: 0, direction: IntegrationDirections.Incoming },
  pages: { loading: false, loaded: false, error: null, ids: null },
  searchParams: DefaultSearchParams,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_INTEGRATION_LOGS_LIST:
      return {
        ...state,
        pages: { loading: true, loaded: false, error: null },
      };
    case ActionTypes.GET_INTEGRATION_LOGS_LIST:
    case ActionTypes.LOADED_INTEGRATION_LOGS_LIST:
      return {
        ...state,
        all: mapKeys(action.payload.logs, x => x.id),
        pageInfo: action.payload.pageInfo,
        pages: { loading: false, loaded: true, error: null },
        searchParams: { ...action.payload.searchParams },
      };
    case ActionTypes.ERROR_LOADING_INTEGRATION_LOGS_LIST:
      return {
        ...state,
        logs: null,
        pages: { loading: false, loaded: true, error: action.payload.exceptionMessage },
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const getAllLogs = curry(({ localState }) => {
  const allLogs = get(localState, ["all"], {});

  // turn object into array since PagingTable component expects an array for the data prop
  return Object.values(allLogs);
});

export const getIntegrationPageInfo = curry(({ localState }) => {
  const { pageInfo } = localState;

  if (pageInfo.startDateRange !== "" && pageInfo.endDateRange !== "") {
    pageInfo.dateRanges = [
      pageInfo.startDateRange,
      pageInfo.endDateRange,
    ];
  }
  return pageInfo;
});

export const getDirection = curry(({ localState }) => get(localState, ["searchParams", "direction"], IntegrationDirections.Inbound));

export const getIntegrationActivityLocations = curry(({ localState }) => get(localState, ["pageInfo", "integrationActivityLocations"], []));

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});

export const getErrorMessage = curry(({ localState }) => get(localState, ["pageState", "error"], null));

export const getSearchParams = curry(({ localState }) => localState.searchParams);
