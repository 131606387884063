import React from "react";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { isCurrentUserLoading } from "app/auth/store/reducers/user.reducer";
import LoadingState from "components/items/loading-state";
import withPermissions from "permissions/withPermissions";
import { isSystemConfigurationLoaded } from "app/auth/store/reducers/system-configuration";
import { getPatientsByIdentifier } from "app/main/patients/actions/patients.actions";
import SecureReferralWorklistPage from "app/main/referralWorklist/pages/secure-referral-worklist-page";
import qs from "qs";

const ReferralCreatePage = ({ hasPermissionReferralsCreate, hasPermissionPatientsView, history }) => {
  const dispatch = useDispatch();
  const loading = useSelector(isCurrentUserLoading);
  const isSystemConfigLoaded = useSelector(isSystemConfigurationLoaded);
  const [isLoadingPatients, setLoadingPatients] = React.useState(true);
  const [results, setResults] = React.useState(null);
  const presetURL = history.location.search ?? null;
  const queryString = qs.parse(presetURL, { ignoreQueryPrefix: true });
  const identifier = Object.keys(queryString).reduce((acc, key) => acc || (key.toLowerCase() === "patientidentifier" ? queryString[key] : null), null);

  React.useEffect(() => {
    if (isSystemConfigLoaded) {
      dispatch(getPatientsByIdentifier(identifier)).then(responds => {
        if (responds.error !== true) {
          setResults(responds?.payload?.patients);
        }
        setLoadingPatients(false);
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSystemConfigLoaded]);

  if (isEmpty(identifier) || loading || isLoadingPatients) return <LoadingState />;

  if (hasPermissionPatientsView && hasPermissionReferralsCreate && results?.length === 0) {
    history.push(`/referralWorklist?patientIdentifier=${identifier}`);
  } else if (hasPermissionPatientsView && results?.length > 1) {
    history.push(`/patientReferralWorklist?patientIdentifier=${identifier}`);
  } else if (results?.length === 1 && hasPermissionReferralsCreate && !isEmpty(results[0].patientId)) {
    history.push(`/patientReferralWorklist?patientId=${results[0].patientId}&createReferral=true&patientIdentifier=${identifier}`);
  } else {
    history.push("/referralWorklist");
  }

  return <SecureReferralWorklistPage />;
};

export default withPermissions("ReferralsCreate", "PatientsView")(ReferralCreatePage);
