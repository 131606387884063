import React from "react";
import Typography from "@material-ui/core/Typography";
import { toDOBFormat } from "app/main/patients/helpers/format-dob";

export default function PatientAgeLabel({
  age,
  variant,
  formatDOB,
  birthDate,
  className,
  ...other
}) {
  if (!birthDate && !age) return null;

  return (
    <Typography variant={variant || "body1"} className={className || "font-bold"} {...other}>
      {formatDOB ? toDOBFormat(birthDate) : age}
    </Typography>
  );
}
