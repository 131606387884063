const JobStatusType = {
  FAILED: "Failed",
  QUEUED: "Queued",
  INPROGRESS: "InProgress",
  COMPLETE: "Complete",
  CANCELLED: "Cancelled",
};

export const isJobStatusComplete = (status) => {
  return status.toLowerCase() === JobStatusType.COMPLETE.toLowerCase();
};

export const isJobStatusFailedOrCompleteOrInProgress = (status) => {
  return status.toLowerCase() === JobStatusType.FAILED.toLowerCase() || isJobStatusComplete(status) || JobStatusType.INPROGRESS.toLowerCase();
}

export default JobStatusType;