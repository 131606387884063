import React from "react";
import { Typography, Hidden } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import PrimaryHeaderPortal from "portals/primaryHeaderPortal";
import PatientAvatar from "components/items/avatar";
import PatientAgeLabel from "app/main/patients/components/patient-details/details/age-label";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
}));
const PatientSummaryHeader = ({
  patient: { avatar, age, sex, displayNameFull },
}) => {
  const classes = useStyles();

  return (
    <PrimaryHeaderPortal>
      <div className={classes.container}>
        <Hidden smDown>
          <PatientAvatar src={avatar} name={displayNameFull} />
        </Hidden>
        <Typography className="font-bold">{displayNameFull}</Typography>
        {age && <PatientAgeLabel age={age} />}
        {sex && <Typography className="font-bold">{sex}</Typography>}
      </div>
    </PrimaryHeaderPortal>
  );
};

export default PatientSummaryHeader;
