import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty, filter, some, find, startCase, concat } from "lodash";
import { Typography } from "@material-ui/core";

import DefaultPanelLayout from "components/items/default-panel-layout";
import EmptyState from "components/items/empty-state";
import { AttachmentItem } from "components/items/attachment";
import IconButton from "components/items/icon-button";
import { getCurrentGPLetters } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import downloadFromApi from "utils/download-from-api";
import GPLetterTemplateHelper from "app/main/orgUnits/components/orgUnit-gp-letter-template-helper";
import { removeGPLetterTemplate } from "app/main/orgUnits/actions/orgUnit.actions";
import DefaultItem from "components/items/default-item";
import { getGPLetterTemplateOptions, getGPLetterOutputTypes } from "app/auth/store/reducers/system-configuration";

import OrgUnitGPLetterTemplateForm from "./orgUnit-gp-letter-template-form";

const title = "Specialist Letter Templates";

const OrgUnitGPLetterTemplatePageContent = ({
  orgUnit,
}) => {
  const dispatch = useDispatch();
  const currentAttachments = useSelector(getCurrentGPLetters);
  const availableAttachmentTypes = useSelector(getGPLetterTemplateOptions);
  const gpLetterOutputTypes = useSelector(getGPLetterOutputTypes);

  const typeOptions = filter(availableAttachmentTypes, type => !some(currentAttachments, x => x.gpLetterTemplateType === type.value));

  const getEditTypeOptions = currentType => concat(typeOptions ?? [], find(availableAttachmentTypes, x => x.value === currentType));

  const getGPLetterTemplateTypeDisplayName = value => find(availableAttachmentTypes, x => x.value === value)?.label ?? startCase(value);

  const getGpLetterOutputTypeDisplayName = value => find(gpLetterOutputTypes, x => x.value === value)?.label ?? startCase(value);

  const openOrgUnitGPLetterTemplateForm = (gpLetterTemplateTitle, options, attachment = null, editAttachment = false) => dispatch(openDialog({
    children: (
      <OrgUnitGPLetterTemplateForm
        title={gpLetterTemplateTitle}
        currentAttachment={attachment}
        typeOptions={options}
        orgUnitId={orgUnit.id}
        editAttachment={editAttachment}
        onSucceed={() => dispatch(closeDialog())}
      />
    ),
  }));

  const onAddGPLetter = () => openOrgUnitGPLetterTemplateForm("Add Letter Template", typeOptions);

  const onRemove = attachment => dispatch(removeGPLetterTemplate(orgUnit.id, attachment.id));

  const onEditGPLetter = attachment => openOrgUnitGPLetterTemplateForm("Edit Letter Template", getEditTypeOptions(attachment.gpLetterTemplateType), attachment);

  const onEditAttachment = attachment => openOrgUnitGPLetterTemplateForm(`Edit ${getGPLetterTemplateTypeDisplayName(attachment.gpLetterTemplateType)} Letter Template Attachment`, getEditTypeOptions(attachment.gpLetterTemplateType), attachment, true);

  return (
    <>
      <DefaultPanelLayout
        title="Letter Templates"
        icon="mail_outline"
        isEmpty={isEmpty(currentAttachments)}
        emptyState={<EmptyState icon="attach_file" subtitle={`Add ${title}`} onClick={onAddGPLetter} />}
        headerActions={([
          {
            label: "Add",
            icon: "add",
            onClick: () => onAddGPLetter(),
          },
        ])}
      >
        <div className="items-container">
          {map(currentAttachments, (attachment, index) => (
            <AttachmentItem
              key={attachment.id}
              id={attachment.id}
              mimeType={attachment.mimeType}
              isFirst={index === 0}
              title={<Typography className="font-bold">{attachment.fileName}</Typography>}
              subtitle={(
                <>
                  <DefaultItem label="Letter Template Type" content={getGPLetterTemplateTypeDisplayName(attachment.gpLetterTemplateType)} labelClassName="w-192" />
                  <DefaultItem label="Display Name" content={attachment.displayName} labelClassName="w-192" />
                  <DefaultItem label="Filetype" content={getGpLetterOutputTypeDisplayName(attachment.outputType)} labelClassName="w-192" />
                  <DefaultItem label="Trigger Patient SMS" content={attachment.patientSMS ? "Yes" : "No"} labelClassName="w-192" />
                </>
              )}
              renderActions={(
                <>
                  <IconButton title="Edit" icon="edit" onClick={() => onEditGPLetter(attachment)} />
                  <IconButton title="Edit Attachment" icon="attachment" onClick={() => onEditAttachment(attachment)} />
                </>
              )}
              canRemove
              onRemove={() => onRemove(attachment)}
              canDownload
              onDownload={() => downloadFromApi(`api/orgUnits/${orgUnit.id}/downloadGPLetter/${attachment.id}`)}
            />
          ))}
        </div>
      </DefaultPanelLayout>
      <GPLetterTemplateHelper />
    </>
  );
};

export default OrgUnitGPLetterTemplatePageContent;
