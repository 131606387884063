import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { map } from "lodash";
import { useDispatch } from "react-redux";
import { AutoComplete } from "components/inputs";
import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import AutoAssignRoleForm from "./auto-assign-role-form";

const useStyles = makeStyles(() => ({
  tag: {
    "& svg": {
      pointerEvents: "initial",
    },
  },
}));

const AutoAssignRoleSelector = ({
  isDisabled,
  options,
  ...other
}) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeDialog());
  const { field: { name, value }, form: { setFieldValue } } = other;

  const addAutoAssignRole = ({ autoAssignRole }) => {
    setFieldValue(name, [...(value ?? []), autoAssignRole]);
    onClose();
  };

  const onAddAutoAssignRole = () => {
    dispatch(openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <AutoAssignRoleForm
          autoAssignRoles={value}
          title="Add Auto Assign Role"
          onSubmit={addAutoAssignRole}
          submitLabel="Add"
          options={options}
        />
      ),
    }));
  };

  const classes = useStyles();

  return (
    <div className="flex sm:flex-row sm:items-center flex-col">
      <AutoComplete
        className={clsx("flex-1", "isDisableLink", classes.tag)}
        options={map(value, x => ({ label: `${x.roleName} (${x.actionType})`, value: x }))}
        getOptionSelected={(option, val) => option.value === val.value}
        multiple
        forcePopupIcon={false}
        disableClearable
        open={false}
        disabled={isDisabled}
        multiline
        {...other}
      />
      {!isDisabled && (
        <div className="sm:pl-8 pl-32 flex">
          <DefaultButton
            onClick={onAddAutoAssignRole}
            label="Add"
            edge="end"
          />
        </div>
      )}
    </div>
  );
};

export default AutoAssignRoleSelector;
