import React, { useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import { debounce, map } from "lodash";
import { AutoComplete } from "components/inputs";
import { Alert } from "@material-ui/lab";
import isSearchStringValid from "helpers/is-search-string-valid";

const normaliseOptions = terms => map(terms, term => ({
  value: term.id,
  label: term.term,
  ...term,
}));

export default function CodedTermSelector({ required, isCreatable = true, onChange, apiCall, ...other }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [apiError, setApiError] = useState(null);
  const [options, setOptions] = useState([]);

  const onInputChange = useCallback(debounce(async (event, val, action) => {
    if (action !== "input" || !isSearchStringValid(val)) {
      return;
    }

    setLoading(true);

    const res = await dispatch(apiCall({ searchTerm: val }));

    setOptions(res.error ? [] : normaliseOptions(res.payload));
    setApiError(res.error ? "Error loading indications" : null);
    setLoading(false);
  }, 300));

  const onSelectChange = value => {
    if (onChange) {
      onChange(value);
    }
    setLoading(false);
  };

  return (
    <>
      <AutoComplete
        isCreatable={isCreatable}
        loading={loading}
        options={options}
        onInputChange={onInputChange}
        disableClearable={required}
        required={required}
        onChange={onSelectChange}
        {...other}
        handleOnChange={onSelectChange}
      />
      {apiError && <Alert severity="warning">{apiError}</Alert>}
    </>
  );
}
