import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { concat } from "lodash";

const useStyles = makeStyles(theme => ({
  confirmButton: {
    "&.disabled": {
      backgroundColor: theme.palette.background.default,
    },
  },
  warningButton: {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.warning.dark,
    },
  },
}));

const BackReferralConfirmDialog = ({
  open,
  onConfirm,
  onCancel,
  isFromNewPatient = false,
}) => {
  const classes = useStyles();
  const title = concat("Confirm and back to ", isFromNewPatient ? "Patient Search" : "Create a new Patient");
  const confirmContentString = "Any referral information currently entered will be lost";
  const confirmLabel = "Confirm";
  const cancelLabel = "Cancel";

  const [isOpen, setIsOpen] = useState(open);
  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Dialog open={isOpen} maxWidth="xs">
      {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}
      <DialogContent className={clsx(!title && "p-24")}>
        <DialogContentText id="alert-dialog-description">
          {confirmContentString}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="justify-center">
        <Button
          color="secondary"
          autoFocus
          onClick={() => {
            setIsOpen(false);
            if (onConfirm) {
              onConfirm();
            }
          }}
          className={clsx("flex-1", classes.confirmButton, classes.warningButton)}
        >
          {confirmLabel}
        </Button>
        <Button onClick={onCancel} className="flex-1">
          {cancelLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BackReferralConfirmDialog;
