import React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  urgentColor: {
    color: theme.palette.warning.main,
  },
}));

export default function UnmergeWarningLabel(props) {
  const { requiresUnmerge } = props;
  const classes = useStyles();

  return (
    requiresUnmerge ? <Typography className={clsx(classes.urgentColor)} variant="caption">This patient record has been marked as requiring un-merge</Typography> : null
  );
}
