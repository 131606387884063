import React, { useEffect, useState } from "react";
import { Box, Collapse, Tab, Table, TableBody, TableCell, TableHead, TableRow, Tabs } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import withPermissions from "permissions/withPermissions";
import {
  INTEGRATION_LOGS_PAGE_SIZE,
  DefaultSearchParams,
  getAllLogs,
  getIntegrationPageInfo,
  isPageLoading,
  getErrorMessage,
  getDirection,
  getSearchParams,
  getIntegrationActivityLocations,
  IntegrationDirections,
} from "./reducers/integrationLogs.reducers";
import { searchIntegrationLogsPageInfo } from "./actions/integrationLogs.actions";
import PagingTable from "../../../components/items/paging-table";
import { formatUtcDate } from "../../../helpers/format-date-time";

const IntegrationLogsTable = () => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(getIntegrationPageInfo);
  const logs = useSelector(getAllLogs);
  const loading = useSelector(isPageLoading);
  const error = useSelector(getErrorMessage);
  const searchParams = useSelector(getSearchParams);
  const direction = useSelector(getDirection);
  const integrationActivityLocations = useSelector(getIntegrationActivityLocations);
  const [activeTab, setActiveTab] = useState(IntegrationDirections.Incoming);
  const [selectedLogId, setSelectedLogId] = useState(null);

  const DEFAULT_STATUS = "N/A";
  const DATETIME_DISPLAY_FORMAT = "DD-MM-YYYY HH:mm:ss";
  const DATETIME_DISPLAY_FORMAT_LONG = "DD-MM-YYYY HH:mm:ss.SSS";
  const COLUMN_COUNT = 7; // Number of columns in the table

  useEffect(() => {
    dispatch(searchIntegrationLogsPageInfo(1, INTEGRATION_LOGS_PAGE_SIZE, DefaultSearchParams, false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setActiveTab(direction);
  }, [direction]);

  const locationToString = location => integrationActivityLocations[location];

  const formatLogMessages = logMessages => (
    <>
      {logMessages.length > 0 ? logMessages.map(x => (
        <p key={x.id}>{x.logMessage}</p>
      )) : <>N/A</>}
    </>
  );

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    const newDirection = newValue === IntegrationDirections.Incoming
      ? IntegrationDirections.Incoming
      : IntegrationDirections.Outgoing;
    dispatch(searchIntegrationLogsPageInfo(1, INTEGRATION_LOGS_PAGE_SIZE, { ...DefaultSearchParams, direction: newDirection }, false));
  };

  const handleShowActivities = logId => {
    if (selectedLogId === logId) {
      // collapse activities of the log
      setSelectedLogId(null);
      return;
    }

    // expand activities for the log
    setSelectedLogId(logId);
  };

  const getStatusForMessage = msg => {
    if (!msg || !Array.isArray(msg.integrationMessageActivities)) {
      return DEFAULT_STATUS;
    }

    const activities = msg.integrationMessageActivities;
    if (activities.length === 0) {
      return DEFAULT_STATUS;
    }

    const lastActivity = activities[activities.length - 1];
    return lastActivity.status || DEFAULT_STATUS;
  };

  const handleChangeRowsPerPage = pageSize => dispatch(searchIntegrationLogsPageInfo(1, pageSize, searchParams));

  const handleChangePage = pageNumber => dispatch(searchIntegrationLogsPageInfo(pageNumber + 1, pageInfo.pageSize, searchParams));

  const renderTableHeader = () => (
    <>
      <TableRow>
        <TableCell colSpan={COLUMN_COUNT}>
          <Tabs value={activeTab} onChange={handleTabChange}>
            <Tab label={IntegrationDirections.Incoming} value={IntegrationDirections.Incoming} />
            <Tab label={IntegrationDirections.Outgoing} value={IntegrationDirections.Outgoing} />
          </Tabs>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>Integration Type</TableCell>
        <TableCell>Message Type</TableCell>
        <TableCell>Message Id</TableCell>
        <TableCell>{activeTab === IntegrationDirections.Incoming ? "First Received" : "First Sent"}</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Patient Id</TableCell>
        <TableCell>Referral Number</TableCell>
      </TableRow>
    </>
  );

  const renderActivityHeader = () => (
    <TableHead>
      <TableRow>
        <TableCell>Status</TableCell>
        <TableCell>Activity Time</TableCell>
        <TableCell>Activity Location</TableCell>
        <TableCell>Log Message</TableCell>
      </TableRow>
    </TableHead>
  );

  const renderActivityRow = activity => {
    const {
      id,
      status,
      lastActivityDateTimeUtc,
      activityLocation,
      logMessages,
    } = activity;

    return (
      <TableRow key={id} style={{ width: "100%" }}>
        <TableCell>
          {status}
        </TableCell>
        <TableCell>
          {formatUtcDate(lastActivityDateTimeUtc, DATETIME_DISPLAY_FORMAT_LONG)}
        </TableCell>
        <TableCell>
          {(locationToString(activityLocation))}
        </TableCell>
        <TableCell>
          {formatLogMessages(logMessages)}
        </TableCell>
      </TableRow>
    );
  };

  const renderActivityBody = activities => (
    <TableBody>
      {activities.map(activity => renderActivityRow(activity))}
    </TableBody>
  );

  const renderActivityTable = (logId, selectedId, message) => {
    const expendRow = logId === selectedId;

    return (
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={expendRow} timeout="auto" unmountOnExit>
            <Box style={{ width: "100%", background: "#f2e9e9" }}>
              <Table>
                {renderActivityHeader()}
                {renderActivityBody(message.integrationMessageActivities)}
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    );
  };

  const renderTableRow = log => {
    const {
      id,
      integrationType,
      message,
      patientId,
      referralId,
    } = log;

    return (
      <>
        <TableRow hover key={id} onClick={() => handleShowActivities(id)}>
          <TableCell>{integrationType}</TableCell>
          <TableCell>{message.messageType}</TableCell>
          <TableCell>{message.messageId}</TableCell>
          <TableCell>{formatUtcDate(message.addedDateTimeUtc, DATETIME_DISPLAY_FORMAT)}</TableCell>
          <TableCell>{getStatusForMessage(message)}</TableCell>
          <TableCell>{patientId}</TableCell>
          <TableCell>{referralId}</TableCell>
        </TableRow>
        {renderActivityTable(id, selectedLogId, message)}
      </>
    );
  };

  return (
    <PagingTable
      loading={loading}
      error={error}
      data={logs}
      rowsPerPage={pageInfo.pageSize}
      page={pageInfo.pageNumber - 1}
      count={pageInfo.totalRecords}
      onChangeRowsPerPage={handleChangeRowsPerPage}
      onChangePage={handleChangePage}
      emptyMessage="No Integration Logs Recorded"
      tableHead={renderTableHeader}
      tableRow={item => renderTableRow(item)}
    />
  );
};

export default withPermissions("IntegrationLogsView")(IntegrationLogsTable);
