import React from "react";
import { get, isEmpty } from "lodash";
import { thresholdPercentage, convertPercentage } from "app/auth/store/reducers/system-configuration";

const OCRFieldComponent = ({
  OCRComponent = null,
  FieldComponent,
  ocrResult = null,
  enabledOCR = false,
  ocrProperty = null,
  confirmedOCR = false,
  showActionConfirm = true,
  ocrCustomProps,
  ...other
}) => {
  if (enabledOCR) {
    const ocrResultFields = isEmpty(ocrResult) ? null : ocrResult?.Fields;
    const currentOCRValue = get(ocrResultFields, ocrProperty);

    return (
      <OCRComponent
        thresholdPercentage={thresholdPercentage}
        confidenceLevelPercentage={convertPercentage(currentOCRValue?.Confidence, 1)}
        ocrValue={currentOCRValue?.Value}
        confirmedOCR={confirmedOCR}
        showActionConfirm={showActionConfirm}
        {...ocrCustomProps}
        {...other}
      />
    );
  }
  return (
    <FieldComponent
      {...other}
    />
  );
};

export default OCRFieldComponent;
