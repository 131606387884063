import React from "react";
import CircleSmallIcon from "mdi-react/CircleSmallIcon";
import { Typography } from "@material-ui/core";
import { grey } from "@material-ui/core/colors";

const defaultTextColor = grey[700];

const ContentApplicationReceiver = ({
  site,
  collectionLocation,
  patientSetting,
  divider,
}) => {
  if (!divider) {
    // eslint-disable-next-line no-param-reassign
    divider = <CircleSmallIcon size={20} />;
  }

  return (
    <div className="flex flex-wrap">
      <>
        {site && (
          <Typography style={{ color: defaultTextColor }} variant="body2" className="enable-shrink">{site}</Typography>
        )}

        {collectionLocation && (
        <>
          {divider}
          <Typography style={{ color: defaultTextColor }} variant="body2" className="enable-shrink">{collectionLocation}</Typography>
        </>
        )}

        {patientSetting && (
        <>
          {divider}
          <Typography style={{ color: defaultTextColor }} variant="body2" className="enable-shrink">{patientSetting}</Typography>
        </>
        )}
      </>
    </div>
  );
};

export default ContentApplicationReceiver;
