import React from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { Field } from "formik";

import Form from "components/form";
import { importUserValues } from "app/main/users/actions/users.actions";
import AttachmentUploadField from "components/inputs/attachment-upload";
import { ExcelExportIcon } from "helpers/icon-finder";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import SubmitButton from "app/main/referrals/components/submit-button";

const schema = Yup.object().shape({
  attachment: Yup.object().shape({
    fileName: Yup.string().required("Please choose a file"),
  }),
});

const UserUploadForm = ({
  onSucceed,
  requestExcelFile,
  signedInOrgUnit,
  closeDialog,
  ...other
}) => {
  const dispatch = useDispatch();
  const signedInOrg = useSelector(getSignedInOrgUnit);

  const handleSubmit = ({ attachment }, { setSubmitting, setErrors }) => {
    dispatch(importUserValues(signedInOrg.id, attachment.formData)).then(response => {
      setSubmitting(false);
      if (response.error !== true) {
        onSucceed(response.payload);
      } else {
        setErrors(response.payload);
      }
    });
  };

  const renderExtraActions = formProps => (
    <SubmitButton
      label="Blank Import Template"
      onClick={() => requestExcelFile(`api/users/${signedInOrgUnit.id}/exportUserTemplate`)}
      {...formProps}
    />
  );

  const { attachment } = schema.cast();

  return (
    <Form
      initialValues={{ attachment }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      renderExtraActions={props => renderExtraActions(props)}
      submitLabel="Import"
      contentProps={other}
      content={({ setFieldValue }) => (
        <Field
          name="attachment.fileName"
          component={AttachmentUploadField}
          label="File"
          icon={<ExcelExportIcon />}
          acceptFormat=".xlsx,application/vnd.ms-excel,application/msexcel"
          required
          onChange={file => {
            if (file) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                const formData = new FormData();
                formData.append("file", file);
                setFieldValue("attachment.formData", formData);
                setFieldValue("attachment.fileName", file.name);
              };
            } else {
              setFieldValue("attachment.fileName", null);
            }
          }}
        />
      )}
    />
  );
};

export default UserUploadForm;
