import { get, concat, filter, orderBy, find, take, isEmpty, mapKeys, map, last, pick } from "lodash";
import moment from "moment";

import curryConnector from "utils/connectors";
import { ActionTypes as EncounterActionTypes } from "app/main/patients/reducers/encounters.reducers";
import { ActionTypes as PatientsActionTypes } from "app/main/patients/reducers/patients.reducers";
import { ActionTypes as WorkListActionTypes } from "app/main/worklist/reducers/worklist.reducers";
import { ActionTypes as AssessmentActionTypes } from "app/main/patients/reducers/assessments.reducers";

export const APPOINTMENTS_STATE_KEY = "appointments";

const curry = fn => curryConnector(fn, APPOINTMENTS_STATE_KEY);

export const ActionTypes = {
  LOADING_APPOINTMENTS: "LOADING_APPOINTMENTS",
  LOADED_APPOINTMENTS: "LOADED_APPOINTMENTS",
  ERROR_LOADING_APPOINTMENTS: "ERROR_LOADING_APPOINTMENTS",

  LOADING_CURRENT_APPOINTMENT: "LOADING_CURRENT_APPOINTMENT",
  ERROR_LOADING_CURRENT_APPOINTMENT: "ERROR_LOADING_CURRENT_APPOINTMENT",
  LOADED_CURRENT_APPOINTMENT: "LOADED_CURRENT_APPOINTMENT",

  SCHEDULING_APPOINTMENT: "SCHEDULING_APPOINTMENT",
  SCHEDULED_APPOINTMENT: "SCHEDULED_APPOINTMENT",
  ERROR_SCHEDULING_APPOINTMENT: "ERROR_SCHEDULING_APPOINTMENT",
  DELETED_APPOINTMENT: "DELETED_APPOINTMENT",
  SAVED_APPOINTMENT: "SAVED_APPOINTMENT",
  CANCELLED_APPOINTMENT: "CANCELLED_APPOINTMENT",
};

const INITIAL_STATE = {
  all: {},
  current: {},
  loadingCurrent: false,
};

// this needs to be defined
const calculateNextEncounter = encounters => last(filter(encounters, x => (x.status === "Booked") && moment().isBefore(x.startDateTimeUtc))) || null;

const addOrUpdateAppointment = (appointments, appointment) => {
  if (!isEmpty(appointment)) {
    const appointmentReferrals = find(appointments, x => x.appointment.id === appointment.id)?.appointmentReferrals;
    return orderBy(concat(filter(appointments, x => x.appointment.id !== appointment.id), [{ appointment, appointmentReferrals }]), [e => e.appointment.startDateTimeUtc || ""], ["desc"]);
  }
  return appointments;
};

const removeAppointment = (state, action) => {
  const { payload: { appointment, patientId } } = action;
  const appointments = get(state.all, [patientId, "appointments"], []);
  return {
    ...state,
    current: appointment,
    all: {
      ...state.all,
      [patientId]: {
        ...state.all[patientId],
        appointments: filter(appointments, x => x.appointment.id !== appointment.id),
      },
    },
  };
};

const addOrUpdateAppointments = (state, action) => {
  const { payload: { bookedByAppointment, followUpAppointment, appointment, patientId } } = action;
  let appointments = get(state.all, [patientId, "appointments"], []);
  appointments = addOrUpdateAppointment(appointments, bookedByAppointment);
  appointments = addOrUpdateAppointment(appointments, followUpAppointment);
  appointments = addOrUpdateAppointment(appointments, appointment);
  const nextEncounter = calculateNextEncounter(appointments);
  return {
    ...state,
    current: isEmpty(bookedByAppointment) ? appointment : bookedByAppointment,
    all: {
      ...state.all,
      [patientId]: {
        ...state.all[patientId],
        appointments,
        nextEncounter,
      },
    },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_APPOINTMENTS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: true } },
      };
    case ActionTypes.ERROR_LOADING_APPOINTMENTS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_APPOINTMENTS:
      return {
        ...state,
        all: { ...state.all,
          [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, loaded: true, appointments: action.payload.appointments } },
      };
    case ActionTypes.DELETED_APPOINTMENT:
      return removeAppointment(state, action);
    case ActionTypes.LOADING_CURRENT_APPOINTMENT:
      return {
        ...state,
        loadingCurrent: true,
      };
    case ActionTypes.ERROR_LOADING_CURRENT_APPOINTMENT:
      return {
        ...state,
        loadingCurrent: false,
      };
    case ActionTypes.LOADED_CURRENT_APPOINTMENT:
      return {
        ...state,
        current: action.payload.encounter,
        loadingCurrent: false,
      };
    case ActionTypes.CANCELLED_APPOINTMENT:
    case ActionTypes.SAVED_APPOINTMENT:
    case ActionTypes.SCHEDULED_APPOINTMENT:
    case EncounterActionTypes.CREATED_ENCOUNTER:
    case EncounterActionTypes.SAVED_ENCOUNTER:
    case AssessmentActionTypes.CONTINUED_ASSESSMENT:
      return addOrUpdateAppointments(state, action);
    case WorkListActionTypes.SEARCHED_PATIENTS:
      return {
        ...state,
        all: { ...state.all,
          ...mapKeys(map(action.payload.patientSummaries, summary => ({
            patientId: summary.patientId,
            ...state.all[summary.patientId],
            nextEncounter: summary.nextEncounter,
          })), x => x.patientId) },
      };
    case PatientsActionTypes.LOADED_CURRENT_PATIENT:
      return {
        ...state,
        all: action.payload.summary ? {
          ...state.all,
          [action.payload.summary.patientId]: {
            ...state.all[action.payload.summary.patientId],
            nextEncounter: action.payload.summary.nextEncounter,
          },
        } : state.all,
      };
    // after cerated assessment, remove the item out of appointment list
    case AssessmentActionTypes.CREATED_ASSESSMENT:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            appointments: filter(state.all[action.payload.patientId].appointments, x => x.id !== action.payload.appointmentId),
          },
        },
      };
    default:
      return state;
  }
};

export const getAppointmentsByPatientId = curry(({ localState }, patientId, showAllStatus) => {
  let appointments = get(localState, ["all", patientId, "appointments"], []);
  if (!showAllStatus) {
    appointments = appointments.filter(x => x.appointment.status === "Booked");
  }
  return appointments;
});

export const getRecentAppointments = curry(({ localState }, patientId, number, showAllStatus) => {
  let appointments = get(localState, ["all", patientId, "appointments"], []);
  if (!showAllStatus) {
    appointments = appointments.filter(x => x.appointment.status === "Booked");
  }
  return take(appointments, number);
});

export const areAppointmentsLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loading"], false));

export const areAppointmentsLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loaded"], false));

export const getErrorMessage = curry(({ localState }, patientId) => get(localState, ["all", patientId, "error"], null));

export const getCurrentAppointment = curry(({ localState }) => get(localState, ["current"], null));

export const isCurrentAppointmentLoading = curry(({ localState }) => get(localState, ["loadingCurrent"], false));

export const getAppointmentById = curry(({ localState }, patientId, encounterId) => {
  const encounters = get(localState, ["all", patientId, "appointments"], []);
  return find(encounters, x => x.appointment.id === encounterId)?.appointment || {};
});

export const getNextEncounter = curry(({ localState }, patientId) => get(localState, ["all", patientId, "nextEncounter"], {}));
export const getAppointmentByPatientIds = curry(({ localState }, patientIds) => pick(localState.all, patientIds));
