import { get, concat, filter, mapKeys, map, pick, orderBy, head, find } from "lodash";
import moment from "moment";
import curryConnector from "utils/connectors";
import { ActionTypes as WorkListActionTypes } from "app/main/worklist/reducers/worklist.reducers";
import { ActionTypes as PatientsActionTypes } from "app/main/patients/reducers/patients.reducers";
import { ActionTypes as AssessmentActionTypes } from "app/main/patients/reducers/assessments.reducers";

export const ENCOUNTERS_STATE_KEY = "encounters";

const curry = fn => curryConnector(fn, ENCOUNTERS_STATE_KEY);

export const ActionTypes = {
  LOADING_ENCOUNTERS: "LOADING_ENCOUNTERS",
  LOADED_ENCOUNTERS: "LOADED_ENCOUNTERS",
  ERROR_LOADING_ENCOUNTERS: "ERROR_LOADING_ENCOUNTERS",

  LOADING_CURRENT_ENCOUNTER: "LOADING_CURRENT_ENCOUNTER",
  ERROR_LOADING_CURRENT_ENCOUNTER: "ERROR_LOADING_CURRENT_ENCOUNTER",
  LOADED_CURRENT_ENCOUNTER: "LOADED_CURRENT_ENCOUNTER",

  SAVED_ENCOUNTER: "SAVED_ENCOUNTER",
  CREATED_ENCOUNTER: "CREATED_ENCOUNTER",
  DELETED_ENCOUNTER: "DELETED_ENCOUNTER",
};

const INITIAL_STATE = {
  all: {},
  current: null,
  loadingCurrent: false,
};

const calculateLastEncounter = encounters => head(filter(encounters, x => (x.status === "Completed" || x.status === "InProgress") && moment().isAfter(x.startDateTimeUtc))) || null;

const addOrUpdateEncounter = (state, action) => {
  const { payload: { encounter, patientId } } = action;
  let encounters = get(state.all, [patientId, "encounters"], []);
  encounters = orderBy(concat(filter(encounters, x => x.id !== encounter.id), [encounter]), [e => e.startDateTimeUtc || ""], ["desc"]);
  const lastEncounter = calculateLastEncounter(encounters);

  return {
    ...state,
    current: encounter,
    all: { ...state.all, [patientId]: { ...state.all[patientId], encounters, lastEncounter } },
  };
};

const removeEncounter = (state, action) => {
  const { payload: { encounter, patientId } } = action;
  let encounters = get(state.all, [patientId, "encounters"], []);
  encounters = orderBy(filter(encounters, x => x.id !== encounter.id), [e => e.startDateTimeUtc || ""], ["desc"]);
  const lastEncounter = calculateLastEncounter(encounters);

  return {
    ...state,
    all: { ...state.all, [patientId]: { ...state.all[patientId], encounters, lastEncounter } },
  };
};

const onCompleteAssessment = (state, action) => {
  let { encounters } = state.all[action.payload.patientId];
  encounters = map(encounters, x => {
    if (x.assessmentId === action.payload.assessment.id) {
      return ({
        ...x,
        status: action.payload.assessment.status,
        assessmentStatus: action.payload.assessment.status,
      });
    }
    return x;
  });

  return {
    ...state,
    all: {
      ...state.all,
      [action.payload.patientId]: {
        ...state.all[action.payload.patientId],
        encounters,
      },
    },
  };
};

const addAssessmentToEncounterList = (state, action) => {
  // if its already in the list, update the object, otherwise add to the list
  let { encounters } = state.all[action.payload.patientId];

  const newEncounter = {
    ...action.payload.encounter,
    assessmentId: action.payload.assessment.id,
    appointmentId: action.payload.appointmentId,
    assessmentStatus: action.payload.assessment.status,
  };

  encounters = orderBy(concat(filter(encounters, x => x.id !== action.payload.encounter.id), [newEncounter]), [e => e.startDateTimeUtc || ""], ["desc"]);

  return {
    ...state,
    all: {
      ...state.all,
      [action.payload.patientId]: {
        ...state.all[action.payload.patientId],
        encounters,
      },
    },
  };
};

const onRemoveAssessment = (state, action) => {
  let { encounters } = state.all[action.payload.patientId];

  encounters = map(encounters, x => {
    if (x.assessmentId === action.payload.assessmentId) {
      return ({
        ...x,
        assessmentId: null,
        assessmentStatus: null,
      });
    }
    return x;
  });

  return {
    ...state,
    all: {
      ...state.all,
      [action.payload.patientId]: {
        ...state.all[action.payload.patientId],
        encounters,
      },
    },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_ENCOUNTERS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: true } },
      };
    case ActionTypes.ERROR_LOADING_ENCOUNTERS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_ENCOUNTERS:
      return {
        ...state,
        all: { ...state.all, [action.payload.patientId]: { ...state.all[action.payload.patientId], loading: false, loaded: true, encounters: action.payload.encounters } },
      };
    case ActionTypes.LOADING_CURRENT_ENCOUNTER:
      return {
        ...state,
        loadingCurrent: true,
      };
    case ActionTypes.ERROR_LOADING_CURRENT_ENCOUNTER:
      return {
        ...state,
        loadingCurrent: false,
      };
    case ActionTypes.LOADED_CURRENT_ENCOUNTER:
      return {
        ...state,
        current: action.payload.encounter,
        loadingCurrent: false,
      };
    case ActionTypes.CONTINUED_ASSESSMENT:
    case ActionTypes.SAVED_ENCOUNTER:
    case ActionTypes.CREATED_ENCOUNTER:
      return addOrUpdateEncounter(state, action);
    case ActionTypes.DELETED_ENCOUNTER:
      return removeEncounter(state, action);
    case WorkListActionTypes.SEARCHED_PATIENTS:
      return {
        ...state,
        all: { ...state.all,
          ...mapKeys(map(action.payload.patientSummaries, summary => ({
            patientId: summary.patientId,
            ...state.all[summary.patientId],
            lastEncounter: summary.lastEncounter,
          })), x => x.patientId) },
      };
    case PatientsActionTypes.LOADED_CURRENT_PATIENT:
      return {
        ...state,
        all: action.payload.summary ? {
          ...state.all,
          [action.payload.summary.patientId]: {
            ...state.all[action.payload.summary.patientId],
            lastEncounter: action.payload.summary.lastEncounter,
          },
        } : state.all,
      };
    // when created assessment move this to encounter list
    case AssessmentActionTypes.CREATED_ASSESSMENT:
      return addAssessmentToEncounterList(state, action);
    // upon complete assessment, encounter list item should mark as completed
    case AssessmentActionTypes.COMPLETED_ASSESSMENT:
      return onCompleteAssessment(state, action);
    case AssessmentActionTypes.DELETED_ASSESSMENT:
      return onRemoveAssessment(state, action);
    default:
      return state;
  }
};

export const areEncountersLoading = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loading"], false));

export const areEncountersLoaded = curry(({ localState }, patientId) => get(localState, ["all", patientId, "loaded"], false));

export const getCurrentEncounter = curry(({ localState }) => get(localState, ["current"], null));


export const getEncounterByPatientIds = curry(({ localState }, patientIds) => pick(localState.all, patientIds));

export const getEncounterById = curry(({ localState }, patientId, encounterId) => {
  const encounters = get(localState, ["all", patientId, "encounters"], []);
  return find(encounters, x => x.id === encounterId) || {};
});

export const getLastEncounter = curry(({ localState }, patientId) => get(localState, ["all", patientId, "lastEncounter"], {}));
