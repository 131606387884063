import React from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty, size } from "lodash";
import { Typography, Link, Hidden } from "@material-ui/core";
import withWidth, { isWidthDown } from "@material-ui/core/withWidth";
import DoctorIcon from "mdi-react/DoctorIcon";
import EmailSendOutlineIcon from "mdi-react/EmailSendOutlineIcon";

import DefaultPanelLayout from "components/items/default-panel-layout";
import formatDate from "helpers/format-date";
import DefaultButton from "components/items/default-button";
import EmptyState, { NoneRecordedState } from "components/items/empty-state";
import { showMessage } from "app/store/actions/message.actions";
import ApplicationPanelContent from "app/main/applications/components/application-panel-content";
import ApplicationItem from "app/main/applications/components/application-item";
import EndorsementForm from "app/main/applications/components/endorsement-form";
import MarkAsEndorsedForm from "app/main/applications/components/mark-as-endorsed-form";
import ConfirmationDialog from "components/items/confirmation-dialog";
import RequestEndorsementForm from "app/main/applications/components/request-endorsement-form";
import { onRemoveEndorsement, onUndoManualEndorsement } from "app/main/applications/actions/applications.actions";
import getEndorsementStatus from "app/main/applications/helpers/get-endorsement-status";
import ApplicationNoteContent from "app/main/applications/components/application-note-content";
import { RequestInfoIcon, NoteIcon } from "helpers/icon-finder";
import { isSectionCompleted } from "app/main/applications/reducers/applications.reducers";
import { getHelperTextBySectionName } from "app/auth/store/reducers/system-configuration";

import ApplicationRemoveButton from "./application-remove-button";
import ApplicationEditButton from "./application-edit-button";

const EndorsementPanel = ({
  width, canView, canEdit, endorsements, closeDialog, openDialog, id: applicationId, isReportBackApplication, orgUnitId, canRemoveAutomaticallyAddedEndorsement, canMarkAsEndorsed, canUndoMarkAsEndorsed, ...other
}) => {
  const dispatch = useDispatch();
  const showIsRequired = useSelector(state => isSectionCompleted(state, "endorsement"));
  const helperText = useSelector(state => getHelperTextBySectionName(state, "endorsements"));

  const openForm = ({ actionType, endorsement, title }) => {
    const isNew = !endorsement;
    let FormComponent;
    switch (actionType) {
      case "requestEndorsement":
        FormComponent = RequestEndorsementForm;
        break;
      case "markAsEndorsement":
        FormComponent = MarkAsEndorsedForm;
        break;
      case "editEndorsement":
      default:
        FormComponent = EndorsementForm;
        break;
    }

    openDialog({
      children: (
        <FormComponent
          onSucceed={() => closeDialog()}
          title={title || `${isNew ? "New" : "Edit"} Endorsement`}
          endorsement={endorsement}
          applicationId={applicationId}
          orgUnitId={orgUnitId}
          {...other}
        />
      ),
    });
  };

  const onMarkAsRequested = () => {
    openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <ConfirmationDialog
          onCancel={closeDialog}
          onConfirm={() => {
            dispatch(onUndoManualEndorsement(applicationId, orgUnitId)).then(response => {
              if (response.error === true) {
                dispatch(showMessage({ message: response.payload.exceptionMessage || "An error has occurred", variant: "error" }));
              }
              closeDialog();
            });
          }}
          title={<div className="text-center">Would you like to undo mark as endorsed and set this application back to requested?</div>}
        />
      ),
    });
  };

  const removeEndorsement = endorsementId => {
    dispatch(onRemoveEndorsement(applicationId, endorsementId, orgUnitId)).then(response => {
      if (response.error === true) {
        dispatch(showMessage({ message: response.payload.exceptionMessage || "Error occurred while deleting the endorsement", variant: "error" }));
      } else {
        dispatch(showMessage({ message: "Successfully removed the endorsement", variant: "success" }));
      }
    });
  };

  return (
    <DefaultPanelLayout
      title="Endorsements"
      icon="thumbs_up_down"
      isEmpty={isEmpty(endorsements)}
      emptyState={canEdit
        ? <EmptyState icon="thumbs_up_down" subtitle="Add Endorsement" onClick={() => openForm({ actionType: "requestEndorsement" })} />
        : <NoneRecordedState />}
      accessDenied={!canView}
      headerActions={
        [
          canMarkAsEndorsed && {
            label: "Mark as Endorsed",
            icon: "check",
            onClick: () => openForm({ actionType: "markAsEndorsement", title: "Mark as Endorsed" }),
          },
          canUndoMarkAsEndorsed && {
            label: "Undo Mark as Endorsed",
            icon: "edit",
            onClick: () => onMarkAsRequested(),
          },
          canEdit && {
            label: "Add Endorsement",
            icon: "add",
            onClick: () => openForm({ actionType: "requestEndorsement" }),
          },
        ]
      }
      status={!isReportBackApplication && showIsRequired && <Typography color="error">*</Typography>}
      subtitle={<Typography variant="caption" color="textSecondary" dangerouslySetInnerHTML={{ __html: helperText.endorsements }} />}
    >
      <ApplicationPanelContent>
        {map(endorsements, (x, index) => {
          const decisionConfig = getEndorsementStatus(x.decision);
          const isIncomplete = x.decision === null;
          const showEditButton = canEdit && (isIncomplete || (x.canComplete && !isIncomplete));
          const showDeleteButton = canEdit && (!x.automaticallyAdded || canRemoveAutomaticallyAddedEndorsement);
          const emailAddress = `mailto:${x.email}?subject=${x.mailToSubject}&body=${x.mailToBody}`;

          return (
            <React.Fragment key={x.id}>
              <div className="flex items-center justify-between flex-wrap">
                <Typography className="font-bold">{x.endorsedByTypeLabel}</Typography>
                {canView
                  && (
                  <div
                    className={clsx(
                      "flex items-center justify-end",
                      index % 2 === 0 && !isWidthDown("sm", width) && "mr-16",
                    )}
                  >
                    {(showEditButton || showDeleteButton)
                      && (
                      <>
                        {showDeleteButton && <ApplicationRemoveButton onClick={() => removeEndorsement(x.id)} />}
                        {showEditButton && <ApplicationEditButton onClick={() => openForm({ actionType: isIncomplete ? "requestEndorsement" : "editEndorsement", endorsement: x })} />}
                      </>
                      )}
                  </div>
                  )}
              </div>
              {x.automaticallyAdded && <Typography>This endorsement type was automatically added to the application</Typography>}
              <ApplicationItem icon={<DoctorIcon />} content={x.endorsedBy} />
              <ApplicationItem
                icon="email"
                contentClassName="flex-no-grow"
                content={x.email && (
                  <Link href={emailAddress} className="flex-row-container">
                    <Typography>{x.email}</Typography>
                    <Hidden smDown><div className="ml-8 flex"><EmailSendOutlineIcon size={20} /></div></Hidden>
                  </Link>
                )}
              />
              <ApplicationItem
                icon={decisionConfig.icon}
                iconColor={decisionConfig.color}
                content={<Typography className="font-bold" style={{ color: decisionConfig.color }}>{decisionConfig.label}</Typography>}
              />
              <ApplicationItem icon="date_range" content={formatDate(x.signedDateTimeUtc) || "Not yet signed"} />
              <ApplicationItem icon={<RequestInfoIcon />} content={x.moreInformationRequested ? <ApplicationNoteContent>{x.moreInformationRequested}</ApplicationNoteContent> : null} />
              <ApplicationItem icon={<NoteIcon />} content={x.notes ? <div className="mr-8"><ApplicationNoteContent>{x.notes}</ApplicationNoteContent></div> : null} />
              {x.canComplete && isIncomplete
                && <div className="flex justify-center"><DefaultButton variant="outlined" label="Complete Endorsement" size="small" onClick={() => openForm({ actionType: "editEndorsement", endorsement: x, title: "Complete Endorsement" })} /></div>}
            </React.Fragment>
          );
        })}
        {size(endorsements) % 2 !== 0 && <div />}
      </ApplicationPanelContent>
    </DefaultPanelLayout>
  );
};

export default withWidth()(EndorsementPanel);
