import React, { useState, useCallback } from "react";
import { debounce, map, split, isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import Highlighter from "react-highlight-words";

import { ContextItem } from "components/items/default-item";
import { AutoComplete } from "components/inputs";
import { fetchProvidersResults } from "app/main/providers/actions/providers.actions";
import { isPageLoading } from "app/main/providers/reducers/providers.reducers";
import { enableFreeTextOnProviderInput } from "app/auth/store/reducers/system-configuration";
import isSearchStringValid from "helpers/is-search-string-valid";

const normaliseOptions = options => map(options, x => ({
  label: x.name,
  value: x.id,
  ...x,
}));

const Item = props => (
  <ContextItem
    className="flex-1"
    labelClassName="w-128"
    labelProps={{ variant: "caption", color: "textSecondary" }}
    renderIfEmpty
    {...props}
  />
);

const ProviderSummary = ({ provider, highlightTerm }) => (
  <div className="w-full">
    <Typography className="font-bold">
      {provider.label ? <Highlighter autoEscape searchWords={highlightTerm} textToHighlight={provider.label} /> : provider.label}
    </Typography>
    {provider.providerNumber ? (
      <Item
        label="Provider Number:"
        content={<Highlighter autoEscape searchWords={highlightTerm} textToHighlight={provider.providerNumber} />}
      />
    ) : provider.providerNumber}
    {provider.practiceName !== undefined && <Item label="Practice:" content={provider.practiceName} />}
    {provider.practicePhone !== undefined && <Item label="Contact Number:" content={provider.practicePhone} />}
  </div>
);

const ProviderSelectorInput = ({
  label,
  ...other
}) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(state => isPageLoading(state, 1));
  const [options, setOptions] = useState([]);
  const [open, setOpen] = useState(false);
  const isCreatable = useSelector(enableFreeTextOnProviderInput);
  const createLabel = "Clinician not found. Use";

  const filterOptions = (allOptions, params) => {
    if (!isCreatable) return allOptions;
    const filtered = allOptions;

    if (params.inputValue !== "" && isEmpty(allOptions)) {
      filtered.push({
        inputValue: params.inputValue,
        label: `${createLabel} "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  const onInputChange = useCallback(debounce(async (event, val, action) => {
    if (action !== "input" || !isSearchStringValid(val)) {
      setOpen(false);
      return;
    }
    setOptions([]);

    dispatch(fetchProvidersResults({ name: val, showInactive: false })).then(res => {
      const newOptions = res?.payload?.providers;
      if (newOptions) {
        setOptions(normaliseOptions(newOptions));
      }
      setOpen(true);
    });
  }, 300));

  return (
    <AutoComplete
      open={open}
      options={options}
      loading={isLoading}
      withDivider
      isCreatable={isCreatable}
      label={label}
      filterOptions={filterOptions}
      noOptionsText="No Clinicians Found"
      onInputChange={onInputChange}
      createLabel={createLabel}
      renderOption={(option, { inputValue }) => {
        const searchWords = split(inputValue, " ");
        if (!option) return null;
        return <ProviderSummary provider={option} highlightTerm={searchWords} />;
      }}
      {...other}
    />
  );
};

export default ProviderSelectorInput;
