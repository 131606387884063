import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { UserReferralFormProfile } from "app/auth/store/actions/user.actions";
import { ActionTypes,
  isPageLoading,
  isCurrentReferralFormProfileLoaded,
  isCurrentReferralFormProfileLoading,
  isPageLoaded,
  areSearchedReferralFormProfilesLoading } from "../reducers/referralFormProfiles.reducers";

export const resetCurrentReferralFormProfile = () => ({
  type: ActionTypes.RESET_REFERRAL_FORM_PROFILE,
});

export const resetReferralFormProfilesFilters = () => ({
  type: ActionTypes.RESET_REFERRAL_FORM_PROFILES_FILTERS,
});

export const fetchReferralFormProfileResults = (orgUnitId, pageNumber, pageSize, searchParams, forceLoad = true) => dispatch => {
  const url = `/referralFormProfiles/${orgUnitId}?pageNumber=${pageNumber}&pageSize=${pageSize}${formatSearchParams(searchParams)}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "REFERRAL_FORM_PROFILES",
      pageNumber,
      bailout: state => isPageLoading(state, pageNumber) || (!forceLoad && isPageLoaded(state, pageNumber)),
    },
  });
};

export const fetchReferralFormProfileForSelector = (orgUnitId, searchParams) => dispatch => {
  const params = formatSearchParams(searchParams);
  const pageNumber = 1;
  const url = `/referralFormProfiles/${orgUnitId}?pageNumber=${pageNumber}&pageSize=999${params}`;
  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      bailout: state => areSearchedReferralFormProfilesLoading(state),
      method: "GET",
      entityType: "SEARCHED_REFERRAL_FORM_PROFILES",
    },
  });
};

export const getReferralFormProfile = (orgUnitId, id) => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referralFormProfiles/${orgUnitId}/${id}`,
    method: "GET",
    entityType: "REFERRAL_FORM_PROFILE",
    bailout: state => isCurrentReferralFormProfileLoaded(state) || isCurrentReferralFormProfileLoading(state),
  },
});

export const addNewReferralFormProfile = referralFormProfile => async dispatch => {
  const newReferralFormProfile = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referralFormProfiles/${referralFormProfile.orgUnitId}`,
      body: { referralFormProfile },
      entityType: "REFERRAL_FORM_PROFILE",
    },
  });

  if (newReferralFormProfile.error !== true) {
    dispatch(UserReferralFormProfile(newReferralFormProfile.payload));
  }

  return newReferralFormProfile;
};

export const updateExistingReferralFormProfile = referralFormProfile => async dispatch => {
  const { orgUnitId, id } = referralFormProfile;
  const updateReferralFormProfile = await dispatch({
    [ApiActions.SAVE_TO_API]: {
      endpoint: `/referralFormProfiles/${orgUnitId}/${id}`,
      body: { referralFormProfile },
      entityType: "REFERRAL_FORM_PROFILE",
    },
  });

  if (updateReferralFormProfile.error !== true) {
    dispatch(UserReferralFormProfile(updateReferralFormProfile.payload));
  }

  return updateReferralFormProfile;
};

export const deprecateReferralFormProfile = referralFormProfile => async dispatch => {
  const { orgUnitId, id } = referralFormProfile;
  return dispatch({
    [ApiActions.SAVE_TO_API]: {
      endpoint: `/referralFormProfiles/${orgUnitId}/${id}/deprecate`,
      entityType: "DEPRECATE_REFERRAL_FORM_PROFILE",
    },
  });
};

export const restoreReferralFormProfile = referralFormProfile => async dispatch => {
  const { orgUnitId, id } = referralFormProfile;
  return dispatch({
    [ApiActions.SAVE_TO_API]: {
      endpoint: `/referralFormProfiles/${orgUnitId}/${id}/restore`,
      entityType: "RESTORE_REFERRAL_FORM_PROFILE",
    },
  });
};

export const onRemove = (orgUnitId, referralFormProfileId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/referralFormProfiles/${orgUnitId}/${referralFormProfileId}`,
    entityType: "REFERRAL_FORM_PROFILE",
    referralFormProfileId,
  },
});
