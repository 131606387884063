import * as Actions from "../actions/dialog.actions";

const initialState = {
  defaultDialog: false, // default dialog
  options: {
    children: "",
    disableBackdropClick: true,
    maxWidth: "sm",
  },
  fullHeight: false,
};

const dialog = (state = initialState, action) => {
  switch (action.type) {
    case Actions.OPEN_DIALOG:
    {
      return {
        ...state,
        defaultDialog: true,
        fullHeight: action.fullHeight,
        options: {
          ...state.options,
          ...action.options,
        },
      };
    }
    case Actions.CLOSE_DIALOG:
    {
      return {
        ...state,
        defaultDialog: false,
      };
    }
    case Actions.SET_DIALOG_OPTIONS:
    {
      return {
        ...state,
        options: {
          ...state.options,
          ...action.options,
        },
      };
    }
    case Actions.RESET_DIALOG: {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default dialog;
