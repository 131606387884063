import { useEffect, useRef } from "react";
import { fetchOrgUnitFormFields } from "app/main/orgUnits/actions/orgUnit.actions";
import { useSelector, useDispatch } from "react-redux";
import { getCurrentOrgUnitFormFields, areCurrentOrgUnitFormFieldsLoading, areCurrentOrgUnitFormFieldsLoaded } from "app/main/orgUnits/reducers/orgUnit.reducers";

// a hook that returns form config for each formType in current orgUnit
// accept param that force to load from api again, to keep data up to date, e.g. forms
const useFormFieldConfig = (orgUnitId, formType, forceLoad = false) => {
  const dispatch = useDispatch();
  const loadingFieldSettings = useSelector(state => areCurrentOrgUnitFormFieldsLoading(state, orgUnitId, formType));
  const loadedFieldSettings = useSelector(state => areCurrentOrgUnitFormFieldsLoaded(state, orgUnitId, formType));
  const fieldSettings = useSelector(state => getCurrentOrgUnitFormFields(state, orgUnitId, formType));
  const timer = useRef();

  useEffect(() => {
    timer.current = setTimeout(() => {
      dispatch(fetchOrgUnitFormFields(orgUnitId, formType, forceLoad));
    }, 300);
    return () => { clearTimeout(timer.current); };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId, formType, forceLoad]);

  return { fieldSettings, loadingFieldSettings, loadedFieldSettings };
};

export default useFormFieldConfig;
