import { useSelector } from "react-redux";
import { getDefaultReferralFormProfile } from "app/auth/store/reducers/user.reducer";
import { getOrgUnitFormFieldConfigsById } from "app/main/orgUnits/reducers/orgUnit.reducers";

const useFormFieldConfigByProfile = (orgUnitId, formFieldConfigurationId) => {
  const defaultReferralFormProfile = useSelector(state => getDefaultReferralFormProfile(state, orgUnitId));
  const requestFormFieldConfigurationId = formFieldConfigurationId ?? defaultReferralFormProfile?.formFieldConfigurationId;
  const fieldSettings = useSelector(state => getOrgUnitFormFieldConfigsById(state, requestFormFieldConfigurationId));

  return { fieldSettings };
};

export default useFormFieldConfigByProfile;
