import React, { useState } from "react";
import { Field } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { Icon, Popover, Typography } from "@material-ui/core";
import { isEmpty, lowerCase } from "lodash";

import Form from "components/form";
import PatientSelector from "app/main/patients/components/patient-selector";
import DisabledInput from "components/inputs/components/disabled-input";
import BaseInputField from "components/inputs/components/base-input-component";
import HelperText from "components/inputs/components/helper-text";
import { getPatientLabel } from "utils/get-environment-variables";

import DefaultButton from "components/items/default-button";
import { addMergePatient } from "../actions/patientMerge.actions";

const patientLabel = getPatientLabel();
const lowerCasePatientLabel = lowerCase(patientLabel.single);
const masterPatientLabel = `Master ${patientLabel.single}`;
const deprecatedPatientLabel = `Deprecated ${patientLabel.single}`;

const schema = Yup.object().shape({
  patientMerge: Yup.object().shape({
    masterPatient: Yup.string()
      .required(`${masterPatientLabel} is required`)
      .nullable(),
    deprecatedPatient: Yup.string()
      .required(`${deprecatedPatientLabel} is required`)
      .nullable(),
  }),
});

const PatientMergeForm = ({
  onSucceed,
  ...other
}) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = target => {
    setAnchorEl(target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "patient-merge" : undefined;

  const onSubmit = ({ patientMerge }, { setSubmitting }) => {
    dispatch(addMergePatient(patientMerge.masterPatient.value, patientMerge.deprecatedPatient.value)).then(res => {
      setSubmitting(false);
      handleClose();
      if (res.error !== true) {
        onSucceed();
      }
    });
  };

  const renderSubmitButton = formProps => (
    <DefaultButton
      onClick={event => {
        const target = event.currentTarget;

        formProps.validateForm().then(() => {
          if (formProps.isValid) {
            handleClick(target);
          }
        });
      }}
      label="Merge"
      size="medium"
    />
  );

  const { patientMerge } = schema.cast();

  return (
    <Form
      initialValues={{ patientMerge }}
      onSubmit={onSubmit}
      contentProps={{ ...other, submitLabel: "Merge" }}
      validationSchema={schema}
      renderSubmitButton={renderSubmitButton}
      content={({ values, handleSubmit, isSubmitting, errors }) => (
        <>
          <Popover
            id={id}
            anchorEl={anchorEl}
            open={open}
            anchorOrigin={{ vertical: "center", horizontal: "left" }}
            transformOrigin={{ vertical: "center", horizontal: "right" }}
          >
            <div className="p-16">
              <Typography variant="h6" align="center">Are you sure?</Typography>
              <Typography align="center">This merge cannot be undone.</Typography>
              <div className="flex-row-container with-gutter mt-16">
                <DefaultButton
                  onClick={handleSubmit}
                  label="Confirm merge"
                  size="large"
                  type="submit"
                  loading={isSubmitting && isEmpty(errors)}
                />
                <DefaultButton
                  color="default"
                  label="Cancel"
                  size="large"
                  variant="text"
                  onClick={handleClose}
                />
              </div>
            </div>
          </Popover>
          <div className="flex flex-col items-center items-container md">
            <div className="flex-1 w-full">
              <Field
                name="patientMerge.masterPatient"
                component={PatientSelector}
                label={masterPatientLabel}
                required
                icon="person"
                filteredPatient={values.patientMerge.deprecatedPatient}
                helperText={`The master ${lowerCasePatientLabel} record that data will be merged into.`}
              />
            </div>
            <div className="flex w-56 justify-end">
              <Icon>arrow_upward</Icon>
            </div>
            <div className="flex-1 w-full">
              {values.patientMerge.masterPatient ? (
                <Field
                  name="patientMerge.deprecatedPatient"
                  label={deprecatedPatientLabel}
                  component={PatientSelector}
                  required
                  icon="person"
                  filteredPatient={values.patientMerge.masterPatient}
                  helperText={`The deprecated ${lowerCasePatientLabel}'s data will be moved to the master ${lowerCasePatientLabel} and the ${lowerCasePatientLabel} record will be removed.`}
                />
              ) : (
                <BaseInputField>
                  <DisabledInput label={deprecatedPatientLabel} value=" " />
                  <HelperText helperText={`Select a master ${lowerCasePatientLabel} before selecting a ${lowerCasePatientLabel} to deprecate.`} />
                </BaseInputField>
              )}
            </div>
          </div>
        </>
      )}
    />
  );
};

export default PatientMergeForm;
