import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import formatDate from "helpers/format-date";
import ConfirmationDialog from "components/items/confirmation-dialog";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import DefaultPageLayout from "components/layouts/default-page-layout";
import ScreenHeader from "components/items/screen-header";
import withPermissions from "permissions/withPermissions";
import DefaultButton from "components/items/default-button";
import useSetUrl from "app/main/admin/hooks/useSetUrl";
import OrgUnitPrimaryHeader from "app/main/orgUnits/components/orgUnit-primary-header";
import SpecialtyProfileForm from "../components/specialtyProfile-form";
import { getCurrentSpecialtyProfile, isCurrentSpecialtyProfileLoading } from "../reducers/specialtyProfiles.reducers";
import { getSpecialtyProfile, resetCurrentSpecialtyProfile, deprecateSpecialtyProfile, restoreSpecialtyProfile, resetSpecialtySelectors } from "../actions/specialtyProfiles.actions";

const SpecialtyProfileEditPage = ({
  match: { params: { id, specialtyProfileId } },
  hasPermissionSpecialtyProfileUpdate,
  route,
}) => {
  const dispatch = useDispatch();
  const current = useSelector(getCurrentSpecialtyProfile);
  const isActive = isEmpty(current?.deprecatedDateUtc);
  const loading = useSelector(isCurrentSpecialtyProfileLoading);
  const [disabled, setDisabled] = useState(true);

  useSetUrl(route);

  const onClose = () => dispatch(closeDialog());

  const onUpdateSpecialtyProfileStatus = () => dispatch(openDialog({
    disableBackdropClick: false,
    maxWidth: "xs",
    children: (
      <ConfirmationDialog
        type="warning"
        title={`${isActive ? "Deprecate" : "Restore"}`}
        content={`Are you sure you want to ${isActive ? "deprecate" : "restore"} ${current.name}?`}
        onConfirm={() => {
          const apiCall = isActive ? deprecateSpecialtyProfile : restoreSpecialtyProfile;

          dispatch(apiCall(current)).then(() => {
            onClose();
          });
        }}
        onCancel={onClose}
      />
    ),
  }));

  useEffect(() => {
    if (!loading && specialtyProfileId) {
      dispatch(getSpecialtyProfile(id, specialtyProfileId));
    }
    return () => dispatch(resetCurrentSpecialtyProfile());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderActions = () => {
    if (!hasPermissionSpecialtyProfileUpdate) return null;
    return (
      <>
        <DefaultButton
          variant="outlined"
          color="inherit"
          label={isActive ? "Deprecate" : "Restore"}
          icon={isActive ? "cancel" : "undo"}
          onClick={onUpdateSpecialtyProfileStatus}
          disabled={!disabled}
        />
        <DefaultButton label="Edit" variant="outlined" color="inherit" icon="edit" onClick={() => setDisabled(false)} disabled={!disabled || !isActive} />
      </>
    );
  };

  const onSucceed = specialtyId => {
    setDisabled(true);
    // reset selector data, so if will fetch new data again in case of field changes
    dispatch(resetSpecialtySelectors(specialtyId));
  };

  return (
    <>
      <OrgUnitPrimaryHeader orgUnitId={id} />
      <DefaultPageLayout
        loading={loading}
        accessDenied={!hasPermissionSpecialtyProfileUpdate}
        header={(
          <ScreenHeader
            title={`Specialty Profile - ${current.name}`}
            subtitle={!isActive ? `Deprecated Date: ${formatDate(current?.deprecatedDateUtc)}` : ""}
            renderActions={renderActions()}
          />
        )}
        content={(
          <SpecialtyProfileForm
            specialtyProfile={current}
            disabled={disabled}
            onCancel={() => setDisabled(true)}
            onSucceed={onSucceed}
            orgUnitId={id}
            specialtyProfileId={specialtyProfileId}
          />
        )}
      />
    </>
  );
};

export default withPermissions("SpecialtyProfileUpdate")(SpecialtyProfileEditPage);
