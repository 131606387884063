import React from "react";
import { makeStyles, fade } from "@material-ui/core/styles";
import { isNil } from "lodash";
import PropTypes from "prop-types";

import formatReferralActivityDate from "app/main/referrals/helpers/referral-activity-date";
import IconComponent from "components/items/icon-component";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    fontSize: 12,
    margin: theme.spacing(1, 0),
  },
  active: ({ activeColor, isGroup }) => ({
    "&:before": {
      backgroundColor: isGroup ? "transparent" : theme.palette.primary[500],
    },
    backgroundColor: activeColor,
  }),
  container: ({ acknowledgement }) => {
    let backgroundColor = theme.palette.grey[100];
    if (acknowledgement === true) {
      backgroundColor = theme.palette.success.light;
    } if (acknowledgement === false) {
      backgroundColor = theme.palette.error.light;
    }
    return ({
      display: "flex",
      position: "relative",
      zIndex: 1,
      "&:after": {
        position: "absolute",
        zIndex: -1,
        content: "' '",
        padding: theme.spacing(1 / 2),
        backgroundColor: fade(backgroundColor, 0.4),
        borderRadius: theme.shape.borderRadius / 2,
        boxShadow: `0 0 0 2px ${fade(backgroundColor, 0.6)}`,
        width: "calc(100% + 4px)",
        height: "100%",
        top: -4,
        left: -4,
      },
    });
  },

}));

// accept states
// 1. Yes
// 2. No
const Acknowledgement = ({
  acknowledgement,
  dateTime,
}) => {
  const classes = useStyles({ acknowledgement });

  if (isNil(dateTime)) return null;

  let icon = null;
  let text = "";

  if (acknowledgement === true) {
    icon = "check";
    text = "YES";
  }

  if (acknowledgement === false) {
    icon = "clear";
    text = "NO";
  }

  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <div className="flex w-24">
          <IconComponent icon={icon} size={16} />
        </div>
        <div className="flex flex-col justify-center">
          <div>{formatReferralActivityDate(dateTime)}</div>
          <div>PATIENT SMS {text}</div>
        </div>
      </div>
    </div>
  );
};

Acknowledgement.propTypes = {
  dateTime: PropTypes.string,
  acknowledgement: PropTypes.bool,
};

export default Acknowledgement;
