import React from "react";
import { TextInput, NoteInput } from "components/inputs";
import OCRBaseComponent from "components/ocr-components/ocr-based-component";
import { NoteIcon } from "helpers/icon-finder";

export const TextInputField = ({
  ...other
}) => (
  <OCRBaseComponent
    inputComponent={TextInput}
    formatValue={other.ocrValue}
    isValidFormat
    requireValidFormat={false}
    {...other}
  />
);

export const NoteInputField = ({
  ...other
}) => (
  <OCRBaseComponent
    inputComponent={NoteInput}
    icon={<NoteIcon />}
    formatValue={other.ocrValue}
    isValidFormat
    requireValidFormat={false}
    {...other}
  />
);

