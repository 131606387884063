import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { find, isEmpty } from "lodash";
import HomePhoneIcon from "mdi-react/PhoneClassicIcon";
import SmartCardOutlineIcon from "mdi-react/SmartCardOutlineIcon";

import PanelContent from "components/items/panel-content";
import { validateRequired, validateEmailFormat, validatePhoneFormat, validatePostcodeFormat, validateDateIsAfter, validateMedicareNumber, validateMaxLength, validatePatientIdentifier, validateDateOfBirth } from "utils/validators";
import { normalizePatient, getCurrentPatient, isCurrentPatientLoading } from "app/main/patients/reducers/patients.reducers";
import { recordNewPatient, updateExistingPatient } from "app/main/patients/actions/patients.actions";
import { TextInput, SelectInput, DatePicker, CheckboxInput } from "components/inputs";
import LoadingState from "components/items/loading-state";
import Form from "components/form";
import { getSexOptions, getSexLabel, getPatientIdentifierTypeOptions, getPrimaryPatientIdentifierSettings, getNationalityLabel, getVeteranMilitaryStatusOptions, getDisplayUsualGPProps, getEnableOCR } from "app/auth/store/reducers/system-configuration";
import OCRFieldComponent from "components/ocr-components/ocr-field-component";
import { OCRTextInput, OCRSelectInput, OCRDatePicker, OCRCheckBoxInput } from "components/ocr-components";

const preferredName = "Preferred Name";
const givenName = "Given Name";
const middleName = "Middle Name";
const familyName = "Family Name";
const birthDate = "Birth Date";
const deathDate = "Death Date";
const practice = "Usual GP Practice";
const provider = "Usual GP";

const phoneHome = "Home Phone";
const phoneMobile = "Mobile Phone";
const email = "Email";
const street = "Street";
const suburb = "City/Suburb";
const stateLabel = "State";
const postcode = "Postcode";
const title = "Title";
const phoneWork = " Work Phone";
const preferredContactMethod = "Preferred Contact Method";
const privacyRequested = "Privacy Requested";
const smsConsent = "SMS Consent";

const medicareCard = "Medicare Card";
const medicareCardExpiryDate = "Expiry Date";
const DVACard = "DVA Card";
const DVACardExpiryDate = "DVA Card Expiry Date";

const primaryLanguage = "Primary Language";
const interpreterRequired = "Interpreter Required";
const veteranMilitaryStatus = "DVA Card Colour";

const preferredContactMethodOptions = [
  { label: phoneHome, value: "Home" },
  { label: phoneWork, value: "Work" },
  { label: phoneMobile, value: "Mobile" },
  { label: email, value: "Email" },
];

const validatePreferredName = value => validateMaxLength(preferredName, 100)(value);
const validateGivenName = value => validateRequired(givenName)(value) || validateMaxLength(givenName, 100)(value);
const validateFamilyName = value => validateRequired(familyName)(value) || validateMaxLength(familyName, 100)(value);
const validateBirthDate = validateDateOfBirth(birthDate);
const validateDeathDate = validateDateIsAfter(deathDate, birthDate, ["patient", "birthDate"]);
const validateMedicareCard = validateMedicareNumber(medicareCard);
const validateEmail = validateEmailFormat(email);
const validatePhoneHome = validatePhoneFormat(phoneHome);
const validatePhoneMobile = validatePhoneFormat(phoneMobile);
const validatePostcode = validatePostcodeFormat(postcode);
const validatePhoneWork = validatePhoneFormat(phoneWork);
const validateDVACard = validatePatientIdentifier(DVACard, { min: 2, max: 9, numberOnly: false });
const validatePreferredContactMethod = validateRequired(preferredContactMethod);

const PatientFormContainer = ({
  loading,
  isModal = false,
  handleSubmit,
  onCancel,
  onSucceed,
  disabled,
  isEdit,
  initialValues,
  sexOptions,
  classes,
  renderContent,
  ocrResult = null,
  confirmedOCR = false,
  ...other
}) => {
  const dispatch = useDispatch();
  const typeOptions = useSelector(state => getPatientIdentifierTypeOptions(state, true));

  const primaryPatientIdentifierSettings = useSelector(getPrimaryPatientIdentifierSettings);
  const primaryPatientIdentifier = primaryPatientIdentifierSettings?.type;
  const primaryPatientIdentifierMinLength = primaryPatientIdentifierSettings?.minLength;
  const primaryPatientIdentifierMaxLength = primaryPatientIdentifierSettings?.maxLength;
  const primaryPatientIdentifierLabel = find(typeOptions, x => x.value === primaryPatientIdentifier)?.label ?? "Identifier";
  const numericIdentifiersOnly = primaryPatientIdentifierSettings?.numericIdentifiersOnly;
  const veteranMilitaryStatusOptions = useSelector(getVeteranMilitaryStatusOptions);
  const sexLabel = useSelector(getSexLabel);
  const validateSex = validateRequired(sexLabel);

  const nationalityLabel = useSelector(getNationalityLabel);
  const displayUsualGPProps = useSelector(getDisplayUsualGPProps);

  // nationality label would be configured through product config
  // hence label definition and validation lives here
  const nationality = nationalityLabel;
  const validateNationality = validateMaxLength(nationality, 100);

  const enableOCR = useSelector(getEnableOCR);
  const showActionConfirm = true;

  if (loading) {
    return <LoadingState />;
  }

  const onSubmit = ({ patient }, { setErrors, setSubmitting }) => {
    let newPatient = normalizePatient(patient);

    const apiCall = isEdit ? updateExistingPatient : recordNewPatient;

    newPatient = {
      ...newPatient,
      veteranMilitaryStatusDescription: isEmpty(newPatient.veteranMilitaryStatusCode) ? null : newPatient.veteranMilitaryStatusDescription,
    };

    dispatch(apiCall(newPatient)).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    }).finally(() => setSubmitting(false));
  };

  const primaryIdentifierSection = () => (
    <div className="flex">
      <Field
        name="patient.primaryPatientIdentifier"
        component={OCRFieldComponent}
        FieldComponent={TextInput}
        OCRComponent={OCRTextInput}
        enabledOCR={enableOCR}
        ocrProperty="PatientPrimaryIdentifier"
        showActionConfirm={showActionConfirm}
        confirmedOCR={confirmedOCR}
        ocrResult={ocrResult}
        icon={<SmartCardOutlineIcon />}
        label={primaryPatientIdentifierLabel}
        className="flex-1"
        required
        validate={value => validatePatientIdentifier(primaryPatientIdentifierLabel,
          { min: primaryPatientIdentifierMinLength, max: primaryPatientIdentifierMaxLength, numberOnly: numericIdentifiersOnly, required: true })(value)}
      />
      <div className="flex-1" />
    </div>
  );

  const personalSection = ({ values }) => (
    <>
      <PanelContent itemsPerRow={4}>
        <Field
          name="patient.title"
          label={title}
          component={TextInput}
          icon="person"
        />
        <Field
          name="patient.givenName"
          component={OCRFieldComponent}
          FieldComponent={TextInput}
          OCRComponent={OCRTextInput}
          enabledOCR={enableOCR}
          ocrProperty="PatientGivenNames"
          showActionConfirm={showActionConfirm}
          confirmedOCR={confirmedOCR}
          ocrResult={ocrResult}
          label={givenName}
          validate={validateGivenName}
          required
        />
        <Field
          name="patient.middleName"
          component={OCRFieldComponent}
          FieldComponent={TextInput}
          OCRComponent={OCRTextInput}
          enabledOCR={enableOCR}
          ocrProperty="PatientMiddleNames"
          showActionConfirm={showActionConfirm}
          confirmedOCR={confirmedOCR}
          ocrResult={ocrResult}
          label={middleName}
        />
        <Field
          name="patient.familyName"
          component={OCRFieldComponent}
          FieldComponent={TextInput}
          OCRComponent={OCRTextInput}
          enabledOCR={enableOCR}
          ocrProperty="PatientFamilyName"
          showActionConfirm={showActionConfirm}
          confirmedOCR={confirmedOCR}
          ocrResult={ocrResult}
          label={familyName}
          validate={validateFamilyName}
          required
        />
      </PanelContent>
      <PanelContent itemsPerRow={4}>
        <Field
          name="patient.preferredName"
          label={preferredName}
          component={TextInput}
          validate={validatePreferredName}
          icon="person"
        />
        <Field
          name="patient.sex"
          component={OCRFieldComponent}
          FieldComponent={SelectInput}
          OCRComponent={OCRSelectInput}
          enabledOCR={enableOCR}
          ocrProperty="PatientGender"
          showActionConfirm={showActionConfirm}
          confirmedOCR={confirmedOCR}
          ocrResult={ocrResult}
          label={sexLabel}
          validate={validateSex}
          options={sexOptions}
          required
          ocrCustomProps={{
            ignoreCase: true,
          }}
        />
        <Field
          name="patient.nationality"
          label={nationality}
          component={TextInput}
          validate={validateNationality}
        />
      </PanelContent>
      <PanelContent itemsPerRow={4}>
        <Field
          name="patient.birthDate"
          label={birthDate}
          icon="cake"
          isPast
          validate={validateBirthDate}
          required
          component={OCRFieldComponent}
          FieldComponent={DatePicker}
          OCRComponent={OCRDatePicker}
          enabledOCR={enableOCR}
          ocrProperty="PatientDateOfBirth"
          showActionConfirm={showActionConfirm}
          confirmedOCR={confirmedOCR}
          ocrResult={ocrResult}
        />
        {!disabled && (
        <Field
          name="patient.isDead"
          label="Deceased "
          component={CheckboxInput}
          onChange={(value, change) => {
            if (!value) {
              change("patient.deathDate", null);
            }
          }}
        />
        )}
        {(values?.patient.isDead === true) && (
        <Field
          name="patient.deathDate"
          label={deathDate}
          component={DatePicker}
          icon=" "
          isPast
          validate={value => validateDeathDate(value, values)}
        />
        )}
      </PanelContent>
      <PanelContent itemsPerRow={3}>
        <Field
          name="patient.primaryLanguage"
          label={primaryLanguage}
          component={OCRFieldComponent}
          FieldComponent={TextInput}
          OCRComponent={OCRTextInput}
          enabledOCR={enableOCR}
          ocrProperty="PreferredLanguage"
          showActionConfirm={showActionConfirm}
          confirmedOCR={confirmedOCR}
          ocrResult={ocrResult}
          className="flex-1"
        />
        <Field
          name="patient.interpreterRequired"
          label={interpreterRequired}
          component={OCRFieldComponent}
          FieldComponent={CheckboxInput}
          OCRComponent={OCRCheckBoxInput}
          enabledOCR={enableOCR}
          ocrProperty="InterpreterRequired"
          showActionConfirm={showActionConfirm}
          confirmedOCR={confirmedOCR}
          ocrResult={ocrResult}
        />

        <Field
          name="patient.veteranMilitaryStatusCode"
          component={SelectInput}
          label={veteranMilitaryStatus}
          options={veteranMilitaryStatusOptions}
          onChange={(value, change) => {
            const selectedOption = find(veteranMilitaryStatusOptions, x => x.value === value);
            change("patient.veteranMilitaryStatusDescription", selectedOption?.label);
          }}
          className="flex-1"
        />
      </PanelContent>
      {displayUsualGPProps && disabled && (
      <PanelContent itemsPerRow={2}>
        <Field
          name="patient.providerName"
          label={provider}
          component={TextInput}
        />
        <Field
          name="patient.practiceName"
          label={practice}
          component={TextInput}
        />
      </PanelContent>
      )}
    </>
  );

  const healthSection = () => (
    <>
      <PanelContent itemsPerRow={2}>
        <Field
          name="patient.medicareCard"
          component={OCRFieldComponent}
          FieldComponent={TextInput}
          OCRComponent={OCRTextInput}
          enabledOCR={enableOCR}
          ocrProperty="PatientMedicareNumber"
          showActionConfirm={showActionConfirm}
          confirmedOCR={confirmedOCR}
          ocrResult={ocrResult}
          label={medicareCard}
          validate={validateMedicareCard}
          icon="payment"
        />
        <Field
          name="patient.medicareExpiryDate"
          label={medicareCardExpiryDate}
          component={DatePicker}
        />
      </PanelContent>
      <PanelContent itemsPerRow={2}>
        <Field
          name="patient.dva"
          label={DVACard}
          component={TextInput}
          icon="payment"
          validate={validateDVACard}
        />
        <Field
          name="patient.dvaExpiryDate"
          label={DVACardExpiryDate}
          component={DatePicker}
        />
      </PanelContent>
    </>
  );

  const contactSection = () => (
    <>
      <PanelContent itemsPerRow={3}>
        <Field
          name="patient.contactDetails.phoneHome"
          component={OCRFieldComponent}
          FieldComponent={TextInput}
          OCRComponent={OCRTextInput}
          enabledOCR={enableOCR}
          ocrProperty="PatientPhone"
          showActionConfirm={showActionConfirm}
          confirmedOCR={confirmedOCR}
          ocrResult={ocrResult}
          label={phoneHome}
          validate={validatePhoneHome}
          icon={<HomePhoneIcon />}
        />
        <Field
          name="patient.contactDetails.phoneMobile"
          component={OCRFieldComponent}
          FieldComponent={TextInput}
          OCRComponent={OCRTextInput}
          enabledOCR={enableOCR}
          ocrProperty="PatientMobile"
          showActionConfirm={showActionConfirm}
          confirmedOCR={confirmedOCR}
          ocrResult={ocrResult}
          label={phoneMobile}
          validate={validatePhoneMobile}
          icon="smartphone"
        />
        <Field
          name="patient.contactDetails.phoneWork"
          label={phoneWork}
          component={TextInput}
          validate={validatePhoneWork}
          icon="phone"
        />
      </PanelContent>
      <PanelContent itemsPerRow={3}>
        <Field
          name="patient.contactDetails.email"
          label={email}
          component={TextInput}
          validate={validateEmail}
          icon="email"
        />
      </PanelContent>
      <PanelContent itemsPerRow={3}>
        <Field
          name="patient.contactDetails.preferredContactMethod"
          label={preferredContactMethod}
          component={SelectInput}
          icon="contact_support"
          validate={validatePreferredContactMethod}
          options={preferredContactMethodOptions}
          required
        />
        <Field
          name="patient.privacyRequested"
          label={privacyRequested}
          component={CheckboxInput}
        />
        <Field
          name="patient.smsConsent"
          label={smsConsent}
          component={CheckboxInput}
        />
      </PanelContent>
    </>
  );

  const residentialAddressSection = () => (
    <>
      <Field
        name="patient.address.street"
        component={OCRFieldComponent}
        FieldComponent={TextInput}
        OCRComponent={OCRTextInput}
        enabledOCR={enableOCR}
        ocrProperty="PatientAddress"
        showActionConfirm={showActionConfirm}
        confirmedOCR={confirmedOCR}
        ocrResult={ocrResult}
        label={street}
        icon="location_on"
      />
      <PanelContent itemsPerRow={3}>
        <Field
          name="patient.address.suburb"
          label={suburb}
          component={TextInput}
        />
        <Field
          name="patient.address.state"
          label={stateLabel}
          component={TextInput}
        />
        <Field
          name="patient.address.postcode"
          label={postcode}
          component={TextInput}
          validate={validatePostcode}
        />
      </PanelContent>
    </>
  );

  const postalAddressSection = () => (
    <>
      <Field
        name="patient.postalStreet"
        label={street}
        component={TextInput}
        icon="location_on"
      />
      <PanelContent itemsPerRow={3}>
        <Field
          name="patient.postalSuburb"
          label={suburb}
          component={TextInput}
        />
        <Field
          name="patient.postalState"
          label={stateLabel}
          component={TextInput}
        />
        <Field
          name="patient.postalPostcode"
          label={postcode}
          component={TextInput}
          validate={validatePostcode}
        />
      </PanelContent>
    </>
  );

  const nokSection = () => (
    <PanelContent itemsPerRow={3}>
      <Field
        name="patient.nextOfKin.givenName"
        component={TextInput}
        label="Given Name"
        icon="people"
      />
      <Field
        name="patient.nextOfKin.familyName"
        component={TextInput}
        label="Family Name"
      />
      <Field
        name="patient.nextOfKin.contact"
        component={TextInput}
        label="Contact"
        icon="phone"
      />
    </PanelContent>
  );

  return (
    <Form
      isModal={isModal}
      onSubmit={handleSubmit ?? onSubmit}
      initialValues={initialValues}
      onCancel={onCancel}
      disabled={disabled}
      classes={classes}
      contentProps={{ ...other }}
      content={props => renderContent({
        ...props,
        personalSection,
        healthSection,
        contactSection,
        residentialAddressSection,
        postalAddressSection,
        nokSection,
        primaryIdentifierSection,
      })}
    />
  );
};

export default connect((state, ownProps) => {
  const patient = ownProps.patient || getCurrentPatient(state);
  const sexOptions = getSexOptions(state);

  let medicare = null;
  let dva = null;

  if (patient && patient.patientCards) {
    medicare = find(patient.patientCards, x => x.patientCardTypeCode === "MC");
    dva = find(patient.patientCards, x => x.patientCardTypeCode === "DVA");
  }

  return {
    loading: ownProps.loading || isCurrentPatientLoading(state),
    initialValues: {
      patient: {
        ...patient,
        givenName: ownProps.givenName ?? patient.givenName,
        familyName: ownProps.familyName ?? patient.familyName,
        sex: patient.sex ? find(sexOptions, x => x.label === (patient.sex?.label ?? patient.sex))?.value : patient.sex,
        primaryPatientIdentifier: patient?.primaryPatientIdentifier,
        medicareCard: medicare?.patientCard,
        medicareExpiryDate: medicare?.expiryDate,
        dva: dva?.patientCard,
        dvaExpiryDate: dva?.expiryDate,
      },
    },
    sexOptions,
  };
}, {})(PatientFormContainer);
