import { size, trim } from "lodash";

export default function isSearchStringValid(searchString, length = 3, maxLength) {
  const inputLength = size(trim(searchString));

  if (maxLength !== undefined) {
    return inputLength >= length && inputLength <= maxLength;
  }

  return inputLength >= length;
}
