import { get, map, find, mapKeys, filter, reduce, size } from "lodash";
import curryConnector from "utils/connectors";

export const REFERRAL_FORM_PROFILES_STATE_KEY = "referralFormProfiles";
export const REFERRAL_FORM_PROFILES_PAGE_SIZE = 50;
export const REFERRAL_FORM_PROFILES_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

const curry = fn => curryConnector(fn, REFERRAL_FORM_PROFILES_STATE_KEY);

export const ActionTypes = {
  LOADING_REFERRAL_FORM_PROFILES: "LOADING_REFERRAL_FORM_PROFILES",
  ERROR_LOADING_REFERRAL_FORM_PROFILES: "ERROR_LOADING_REFERRAL_FORM_PROFILES",
  LOADED_REFERRAL_FORM_PROFILES: "LOADED_REFERRAL_FORM_PROFILES",

  SET_REFERRAL_FORM_PROFILE: "SET_REFERRAL_FORM_PROFILE",
  LOADING_REFERRAL_FORM_PROFILE: "LOADING_REFERRAL_FORM_PROFILE",
  LOADED_REFERRAL_FORM_PROFILE: "LOADED_REFERRAL_FORM_PROFILE",
  ERROR_LOADING_REFERRAL_FORM_PROFILE: "ERROR_LOADING_REFERRAL_FORM_PROFILE",
  RESET_REFERRAL_FORM_PROFILE: "RESET_REFERRAL_FORM_PROFILE",

  CREATED_REFERRAL_FORM_PROFILE: "CREATED_REFERRAL_FORM_PROFILE",
  SAVED_REFERRAL_FORM_PROFILE: "SAVED_REFERRAL_FORM_PROFILE",
  DELETED_REFERRAL_FORM_PROFILE: "DELETED_REFERRAL_FORM_PROFILE",

  RESET_REFERRAL_FORM_PROFILES_FILTERS: "RESET_REFERRAL_FORM_PROFILES_FILTERS",

  SAVED_DEPRECATE_REFERRAL_FORM_PROFILE: "SAVED_DEPRECATE_REFERRAL_FORM_PROFILE",
  SAVED_RESTORE_REFERRAL_FORM_PROFILE: "SAVED_RESTORE_REFERRAL_FORM_PROFILE",

  LOADED_SEARCHED_REFERRAL_FORM_PROFILES: "LOADED_SEARCHED_REFERRAL_FORM_PROFILES",
  ERROR_LOADING_SEARCHED_REFERRAL_FORM_PROFILES: "ERROR_LOADING_SEARCHED_REFERRAL_FORM_PROFILES",
  LOADING_SEARCHED_REFERRAL_FORM_PROFILES: "LOADING_SEARCHED_REFERRAL_FORM_PROFILES",
};

const meta = {
  loading: false,
  loaded: false,
  error: null,
};

const INITIAL_STATE = {
  all: [],
  pages: {},
  pageInfo: { pageNumber: 1, pageSize: REFERRAL_FORM_PROFILES_PAGE_SIZE, totalRecords: 0 },
  searchParams: { name: "", showDeprecated: true },
  current: {},
  meta: {
    current: { ...meta },
  },
};
const loadedReferralFormProfiles = (state, action) => {
  const allReferralFormProfiles = action.payload.referralFormProfiles;

  return {
    ...state,
    all: mapKeys(allReferralFormProfiles, x => x.id),
    pages: {
      ...state.pages,
      [action.payload.pageNumber]: { loading: false, loaded: true, error: false, ids: map(allReferralFormProfiles, x => x.id) },
    },
    pageInfo: action.payload.pageInfo,
    searchParams: action.payload.searchParams,
  };
};

const removeReferralFormProfile = (state, action) => {
  const filteredAll = mapKeys(filter(state.all, x => x.id !== action.payload.referralFormProfileId), x => x.id);
  const pages = reduce(state.pages, (results, page, index) => {
    const updatedPage = { ...page, ids: filter(page.ids, x => x !== action.payload.referralFormProfileId) };

    return {
      ...results,
      [index]: updatedPage,
    };
  }, []);

  const updatedPageSize = size(pages);
  return {
    ...state,
    all: filteredAll,
    pages,
    pageInfo: { ...state.pageInfo,
      pageNumber: state.pageInfo.pageNumber > updatedPageSize ? updatedPageSize : state.pageInfo.pageNumber,
      pageSize: updatedPageSize,
      totalRecords: state.pageInfo.totalRecords - 1 },
    current: null,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_REFERRAL_FORM_PROFILES:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: true, error: false } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.ERROR_LOADING_REFERRAL_FORM_PROFILES:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: action.payload.message } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.LOADED_REFERRAL_FORM_PROFILES:
      return loadedReferralFormProfiles(state, action);
    case ActionTypes.DELETED_REFERRAL_FORM_PROFILE:
      return removeReferralFormProfile(state, action);
    case ActionTypes.LOADING_REFERRAL_FORM_PROFILE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current: {
            ...state.meta.current,
            loading: true,
          },
        },
      };
    case ActionTypes.SET_REFERRAL_FORM_PROFILE:
    case ActionTypes.LOADED_REFERRAL_FORM_PROFILE:
      return {
        ...state,
        current: action.payload.referralFormProfile,
        meta: {
          ...state.meta,
          current: {
            ...state.meta.current,
            loading: false,
            loaded: true,
            error: null,
          },
        },
      };
    case ActionTypes.ERROR_LOADED_REFERRAL_FORM_PROFILE:
      return {
        ...state,
        meta: {
          ...state.meta,
          current: {
            loading: false,
            loaded: false,
            error: action.payload.message, // confirm correct? used to be action.error???
          },
        },
      };
    case ActionTypes.RESET_REFERRAL_FORM_PROFILE:
      return {
        ...state,
        current: INITIAL_STATE.current,
        meta: {
          ...state.meta,
          current: INITIAL_STATE.meta.current,
        },
      };
    case ActionTypes.SAVED_REFERRAL_FORM_PROFILE:
    case ActionTypes.CREATED_REFERRAL_FORM_PROFILE:
      return {
        ...state,
        current: action.payload.referralFormProfile,
        all: { ...state.all, [action.payload.referralFormProfile.id]: action.payload.referralFormProfile },
      };

    case ActionTypes.RESET_REFERRAL_FORM_PROFILES_FILTERS:
      return {
        ...state,
        searchParams: INITIAL_STATE.searchParams,
      };
    case ActionTypes.SAVED_DEPRECATE_REFERRAL_FORM_PROFILE:
    case ActionTypes.SAVED_RESTORE_REFERRAL_FORM_PROFILE:
      const deprecatedDateUtc = action.payload.referralFormProfile?.deprecatedDateUtc || null;
      const isDeprecated = action.payload.referralFormProfile?.isDeprecated || false;

      return {
        ...state,
        current: { ...state.current, deprecatedDateUtc, isDeprecated },
        all: { ...state.all, [action.payload.id]: { ...state.all[action.payload.id], deprecatedDateUtc, isDeprecated } },
      };
    case ActionTypes.LOADING_SEARCHED_REFERRAL_FORM_PROFILES:
      return {
        ...state,
        searchedReferralFormProfiles: { ...state.referralFormProfiles, loading: true },
      };
    case ActionTypes.ERROR_LOADING_SEARCHED_REFERRAL_FORM_PROFILES:
      return {
        ...state,
        searchedReferralFormProfiles: { ...state.referralFormProfiles, loading: false, error: action.payload.message },
      };
    case ActionTypes.LOADED_SEARCHED_REFERRAL_FORM_PROFILES:
      return {
        ...state,
        searchedReferralFormProfiles: {
          ...state.searchedSpecialtyProfiles,
          all: action.payload.referralFormProfiles,
          loading: false,
          loaded: true,
        },
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});

export const getErrorMessage = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "error"], null);
});

export const getReferralFormProfileSearchTerms = curry(({ localState }) => localState.searchParams);

export const getPageInfo = curry(({ localState }) => localState.pageInfo);

export const getCurrentReferralFormProfile = curry(({ localState }) => get(localState, ["current"], null));

export const getAllReferralFormProfiles = curry(({ localState }) => {
  const referralFormProfiles = get(localState, ["all"], {});
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  const ids = get(localState.pages, [pageNumber, "ids"], []);
  return map(ids, x => find(referralFormProfiles, y => y.id === x));
});

export const isCurrentReferralFormProfileLoading = curry(state => state.localState.meta.current.loading === true);

export const isCurrentReferralFormProfileLoaded = curry(state => state.localState.meta.current.loaded === true);

export const areSearchedReferralFormProfilesLoading = curry(state => get(state.localState.searchedReferralFormProfiles, ["loading"], false) === true);
