import React from "react";
import { Typography } from "@material-ui/core";

import PatientFormContainer from "./container";

const PatientForm = ({
  showNOK,
  showHealthSection,
  showPrimaryIdentifier,
  ...other
}) => (
  <PatientFormContainer
    {...other}
    renderContent={({ personalSection, healthSection, contactSection, residentialAddressSection, postalAddressSection, nokSection, values, primaryIdentifierSection }) => (
      <>
        {showPrimaryIdentifier && primaryIdentifierSection()}
        <Typography className="ml-32 font-bold">Personal Details</Typography>
        {personalSection({ values })}
        {showHealthSection && (
        <>
          <Typography className="ml-32 font-bold">Health Details</Typography>
          {healthSection()}
        </>
        )}
        <>
          <Typography className="ml-32 font-bold">Contact Details</Typography>
          {contactSection()}
        </>
        <Typography className="ml-32 font-bold">Residential Address</Typography>
        {residentialAddressSection()}
        <Typography className="ml-32 font-bold">Postal Address</Typography>
        {postalAddressSection()}
        {showNOK && (
        <>
          <Typography className="ml-32 font-bold">Next of Kin</Typography>
          {nokSection()}
        </>
        )}
      </>
    )}
  />
);

export default PatientForm;
