import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { IconButton, Icon, Menu, MenuItem } from "@material-ui/core";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { get, map, startCase, isEmpty, isArray } from "lodash";

import { getDateRanges } from "utils/get-date-range";
import PopupMenu from "components/items/popup-menu";
import { isIE } from "helpers/is-browser";
import { shouldSwapDates } from "utils/validators";

import BaseTextInput from "./base-text-input";

const useStyles = makeStyles(theme => ({
  inputContainer: {
    flex: "1 1 auto",
    "& .react-daterange-picker__calendar": {
      zIndex: "1000", // Fix input borders from showing above the popper
    },
    "& > .react-daterange-picker__wrapper": {
      width: "100%",
      border: "none",
      "& > .react-daterange-picker__range-divider": {
        width: 40,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontWeight: 500,
      },
      "& > .react-daterange-picker__inputGroup": {
        pointerEvents: isIE ? "none" : "auto",
      },
      "& input": {
        minWidth: 20,
        textAlign: "center", // Fix spacing issues when dates are selected
        "&[name=year]": {
          minWidth: 20 * 2,
        },
      },
    },
  },
  selected: {
    backgroundColor: theme.palette.background.default,
  },
}));

const DefinedRangeItem = ({ label, onClick }) => (
  <MenuItem onClick={onClick}>
    {label}
  </MenuItem>
);

const DateRange = ({
  field,
  icon,
  disabled,
  form: { touched, errors },
  definedRanges,
  onChange,
  helperText,
  renderExtraAction,
}) => {
  const classes = useStyles();
  const [selectedDateRange, setSelectedDateRange] = useState();

  const DateInputComponent = () => (
    <DateRangePicker
      value={field.value}
      className={classes.inputContainer}
      onChange={newDates => {
        if (isArray(newDates) || newDates === null) {
        // If the start date is later than the end date, assume user error and swap them.
          const dates = shouldSwapDates(newDates)
            ? [newDates[1], newDates[0]]
            : newDates;

          if (onChange) {
            onChange(dates);
          }
          // Note: if the the date is not valid, it wont fire the on change event
          field.onChange(field.name)(dates);
        }
      }}
      format="d-M-yyyy" // temp workaround fix for leading 0 issue 'dd-MM-yyyy'
    />
  );

  const predefinedDateRanges = () => (
    <PopupMenu
      trigger={popupState => (
        <IconButton {...popupState} edge="end">
          <Icon>more_vert</Icon>
        </IconButton>
      )}
      content={({ close, ...other }) => (
        <Menu {...other}>
          {map(definedRanges, range => {
            if (!range) return null;
            const dateRanges = getDateRanges(range);
            const selected = range === selectedDateRange;
            return (
              <div key={range} className={selected ? classes.selected : ""}>
                <DefinedRangeItem
                  label={startCase(range)}
                  onClick={() => {
                    if (onChange) {
                      onChange(dateRanges);
                    }
                    field.onChange(field.name)(dateRanges);
                    setSelectedDateRange(range);
                    close();
                  }}
                />
              </div>
            );
          })}
        </Menu>
      )}
    />
  );

  return (
    <BaseTextInput
      helperText={helperText}
      icon={icon || "date_range"}
      error={get(errors, field.name, null)}
      touched={get(touched, field.name, null)}
      disabled={disabled}
      inputComponent={DateInputComponent}
      value={field.value}
      renderExtraAction={(
        <>
          {!isEmpty(definedRanges) && predefinedDateRanges()}
          {renderExtraAction && renderExtraAction}
        </>

      )}
    />
  );
};

export default DateRange;
