import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Typography, IconButton, Box } from "@material-ui/core";
import clsx from "clsx";
import LockIcon from "mdi-react/SortVariantLockIcon";

import IconComponent from "components/items/icon-component";

const useStyles = makeStyles(theme => ({
  icon: ({ iconSize, iconOffset, color }) => ({
    borderRadius: (iconSize + iconOffset) / 2,
    width: iconSize + iconOffset,
    height: iconSize + iconOffset,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.control,
    color,
  }),
  text: ({ color }) => ({
    color: color || theme.palette.text.secondary,
  }),
}));

const EmptyState = ({
  color,
  onClick,
  icon,
  iconSize,
  subtitle,
  title,
  content,
  iconOffset = 20,
}) => {
  const size = iconSize || 48;
  const classes = useStyles({ iconSize: size, iconOffset, color });
  const theme = useTheme();
  const { palette: { text } } = theme;

  return (
    <Box className="w-full h-full flex justify-center flex-col items-center">
      {icon
        && (
          <IconButton className={classes.icon} onClick={onClick} disabled={!onClick}>
            <IconComponent icon={icon} size={size} color={color || text.secondary} />
          </IconButton>
        )}
      <Box
        className={clsx("flex flex-col items-center", icon && "mt-8", onClick ? "cursor-pointer" : "cursor-default")}
        onClick={onClick}
      >
        {title && <Typography className={classes.text} variant="h6">{title}</Typography>}
        {subtitle && <Typography className={classes.text} variant="caption">{subtitle}</Typography>}
        {content && content}
      </Box>
    </Box>
  );
};

export default React.memo(EmptyState);

export const AccessDeniedState = ({ title = "Access Denied" }) => (
  <EmptyState
    title={title}
    icon={<LockIcon />}
  />
);

export const NoneRecordedState = () => (
  <EmptyState
    title="None Recorded"
    icon="notes"
  />
);
