import React from "react";
import { Select as NativeSelect, MenuItem, Icon, IconButton, Chip } from "@material-ui/core";
import { map, get, find, isEmpty, filter, includes } from "lodash";
import checkIfEmpty from "helpers/check-if-empty";

import BaseTextInput, { BaseInput, BaseFilledInput } from "./components/base-text-field";
import { textFieldHook } from "./components/useInput";

export const Select = ({
  name,
  helperText,
  value,
  options,
  touched,
  error,
  label,
  required,
  onClearSelected,
  disabled,
  selectedOption,
  icon,
  endAdornment,
  showIcon,
  extraOptions,
  ...other
}) => {
  const clearButtonStyles = textFieldHook.useClearButtonStyles();
  const selectInputStyles = textFieldHook.useSelectInputStyles();

  return (
    <BaseTextInput
      name={name}
      helperText={helperText}
      label={label}
      error={error}
      touched={touched}
      required={required && checkIfEmpty(value)}
      value={value}
      disabledValue={selectedOption}
      icon={icon}
      showIcon={showIcon}
      disabled={disabled}
      inputComponent={({ formFieldVariant }) => {
        const inputProps = {
          id: name,
          error: touched && !isEmpty(error),
          value,
          endAdornment: (
            <div className={clearButtonStyles.root}>
              {value && onClearSelected && <IconButton onClick={onClearSelected}><Icon fontSize="small">clear</Icon></IconButton>}
              {endAdornment}
            </div>
          ),
        };
        return (
          <NativeSelect
            name={name}
            value={value}
            IconComponent={props => <Icon {...props}>keyboard_arrow_down</Icon>}
            classes={selectInputStyles}
            input={(
              formFieldVariant === "filled"
                ? <BaseFilledInput {...inputProps} />
                : <BaseInput {...inputProps} />
            )}
            {...other}
          >
            {isEmpty(options)
              ? <MenuItem disabled>{`No ${label}s Available`}</MenuItem>
              : map(options, x => (
                <MenuItem disabled={x.disabled} key={x.value} value={x.value} className="flex justify-between">
                  {x.label} {x?.tag && <Chip label={x.tag} variant="outlined" className="h-16 text-base" />}
                </MenuItem>
              ))}
            {extraOptions && extraOptions}
          </NativeSelect>
        );
      }}
    />
  );
};

const SelectInput = ({
  options,
  field: { name, value },
  form: { setFieldValue, touched, errors, setFieldTouched },
  onChange,
  multiple,
  isClearable = true,
  onBlur,
  ...other
}) => {
  const error = get(errors, name, false);
  const isTouched = get(touched, name, false);
  const emptyValue = multiple ? [] : "";

  const handleBlur = event => {
    setFieldTouched(name);

    if (onBlur) {
      onBlur(event);
    }
  };

  const getSelectedOption = val => (multiple
    ? filter(options, x => includes(val, x.value))
    : find(options, x => x.value === val || x.label === val));

  const selectedOption = getSelectedOption(value);

  const selectedOptionLabel = multiple
    ? map(selectedOption, x => x.label)
    : selectedOption?.label ?? value?.label;

  const getInputValue = () => {
    // make sure options is loaded before set the selected value
    if (!isEmpty(options) && value) {
      return value;
    }
    return emptyValue;
  };

  const handleChange = event => {
    const { value: option } = event.target;

    if (onChange) {
      onChange(option, setFieldValue);
    }

    if (!option) {
      return setFieldValue(name, emptyValue);
    }

    return setFieldValue(name, option);
  };

  const onClearSelected = () => {
    setFieldValue(name, emptyValue);
    setFieldTouched(name);
  };

  return (
    <Select
      name={name}
      value={getInputValue()}
      onChange={handleChange}
      options={options}
      multiple={multiple}
      error={error}
      touched={isTouched}
      onClearSelected={isClearable ? () => onClearSelected() : null}
      selectedOption={selectedOptionLabel}
      onBlur={handleBlur}
      {...other}
    />
  );
};

export default SelectInput;
