import React from "react";
import OCRBaseComponent from "components/ocr-components/ocr-based-component";
import ProviderSelectorInput from "./provider-selector-input";

const ProviderOCRSelectorInput = ({
  ...other
}) => (
  <OCRBaseComponent
    inputComponent={ProviderSelectorInput}
    {...other}
  />
);

export default ProviderOCRSelectorInput;
