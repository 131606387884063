import React from "react";
import { useSelector } from "react-redux";
import MessageBulletedIcon from "mdi-react/MessageBulletedIcon";
import MessageBulletedOffIcon from "mdi-react/MessageBulletedOffIcon";
import { enableSMSConsentLabelDisplay } from "app/auth/store/reducers/system-configuration";
import DefaultItem from "components/items/default-item";

const SMSRequestedStatus = ({
  smsRequested,
  label,
}) => {
  const displaySMSConsentLabel = useSelector(enableSMSConsentLabelDisplay);
  const defaultContent = smsRequested ? "SMS Requested" : "SMS Not Requested";

  if (!displaySMSConsentLabel) return null;

  return (
    <DefaultItem
      className="mt-16"
      icon={smsRequested ? <MessageBulletedIcon size={16} /> : <MessageBulletedOffIcon size={16} />}
      content={label ?? defaultContent}
    />
  );
};

export default SMSRequestedStatus;
