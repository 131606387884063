import * as Yup from "yup";
import { isEmpty, every } from "lodash";
import "utils/yup-validation";

const requiredMessage = "This field is required";
const maxLengthMessage = "Exceeds maximum length";

export const getInitialTemplateSchema = (isEdit, defaultReferralDate, allowedFutureDateMinutes) => Yup.object().shape({
  referral: Yup.object().shape({
    orgUnitId: Yup.string()
      .required(requiredMessage)
      .nullable(),
    referralFormProfileId: Yup.string()
      .required(requiredMessage)
      .nullable(),
    referralSourceDocument: Yup.object().shape({
      fileName: Yup.string().nullable(),
    }).default({}).nullable(),
    referralDateUtc: Yup.date()
      .required(requiredMessage)
      .default(() => (isEdit ? null : defaultReferralDate))
      .validateAllowedFutureDateMinutes("Referral Date", allowedFutureDateMinutes)
      .nullable(),
    referredToSpecialty: Yup.string()
      .required(requiredMessage)
      .nullable(),
  }),
});

export const getSchema = (isEdit, fieldLabels, fieldSettings, allowedFutureDateMinutes, defaultReferralDate, presentingComplaintMaxLength) => {
  const presentingComplaintCodeSchema = Yup.object().shape({
    code: Yup.string(),
    description: Yup.string(),
  });

  return Yup.object().shape({
    referral: Yup.object().shape({
      orgUnitId: Yup.string()
        .required(requiredMessage)
        .nullable(),
      referralFormProfileId: Yup.string()
        .required(requiredMessage)
        .nullable(),
      referralSourceDocument: Yup.object().shape({
        fileName: Yup.string().nullable(),
      }).default({}).nullable(),
      referralDateUtc: Yup.date()
        .required(requiredMessage)
        .default(() => (isEdit ? null : defaultReferralDate))
        .validateAllowedFutureDateMinutes("Referral Date", allowedFutureDateMinutes)
        .nullable(),
      referralPeriod: Yup.string().max(100, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.referralPeriod, fieldSettings?.referralPeriod)
        .nullable(),
      referredToSpecialty: Yup.string()
        .required(requiredMessage)
        .nullable(),
      // config fields
      assignToSubSpecialty: Yup.string()
        .fieldSettingsValidation(fieldLabels.assignToSubSpecialty, fieldSettings?.assignToSubSpecialty)
        .nullable(),
      mbsConsent: Yup.string()
        .nullable()
        .fieldSettingsValidation(fieldLabels.mbsConsent, fieldSettings?.mbsConsent)
        .default(false),
      specialistConsultant: Yup.string()
        .max(50, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.specialistConsultant, fieldSettings?.specialistConsultant)
        .nullable(),
      typeOfReferralCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.typeOfReferral, fieldSettings?.typeOfReferral)
        .nullable(),
      reasonForReferralCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.reasonForReferralCode, fieldSettings?.reasonForReferralCode)
        .nullable(),
      referrerAdvisedPriorityCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.referrerAdvisedPriority, fieldSettings?.referrerAdvisedPriority)
        .nullable(),
      referringLocationCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.referringLocation, fieldSettings?.referringLocation)
        .nullable(),
      referringInternalLocationCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.referringInternalLocation, fieldSettings?.referringInternalLocation)
        .nullable(),
      referrerFullName: Yup.string()
        .max(300, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.referrerFullName, fieldSettings?.referrerFullName)
        .nullable(),
      referrerOrganisationName: Yup.string()
        .max(300, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.referrerOrganisationName, fieldSettings?.referrerOrganisationName)
        .nullable(),
      reasonNote: Yup.string()
        .max(500, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.reasonNote, fieldSettings?.reasonNote)
        .nullable(),
      presentingComplaintNote: Yup.string()
        .max(5000, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.presentingComplaintNote, fieldSettings?.presentingComplaintNote)
        .nullable(),
      presentingComplaintCodes: presentingComplaintMaxLength > 1
        ? Yup.array()
          .of(presentingComplaintCodeSchema)
          .fieldSettingsValidation(fieldLabels.presentingComplaintCodes, fieldSettings?.presentingComplaintCodes)
          .max(presentingComplaintMaxLength, `${fieldLabels.presentingComplaintCodes} must have less than or equal to ${presentingComplaintMaxLength} items`)
          .nullable()
        : presentingComplaintCodeSchema
          .fieldSettingsValidation(fieldLabels.presentingComplaintCodes, fieldSettings?.presentingComplaintCodes)
          .nullable(),
      relevantPatientHistoryNote: Yup.string()
        .max(500, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.relevantPatientHistoryNote, fieldSettings?.relevantPatientHistoryNote)
        .nullable(),
      interpreterRequired: Yup.bool()
        .nullable()
        .fieldSettingsValidation(fieldLabels.interpreterRequired, fieldSettings?.interpreterRequired)
        .default(false),
      preferredLanguage: Yup.string()
        .when("interpreterRequired", {
          is: true,
          then: Yup.string().trim().required(requiredMessage),
          otherwise: Yup.string().notRequired(),
        })
        .max(100, maxLengthMessage)
        .nullable(),
      nok: Yup.object().shape({
        givenName: Yup.string().max(60, maxLengthMessage).nullable(),
        familyName: Yup.string().max(60, maxLengthMessage).nullable(),
        contact: Yup.string().max(60, maxLengthMessage).nullable(),
        isValid: Yup.string().when(["familyName", "contact"], {
          is: (familyName, contact) => {
            const arr = [familyName, contact];

            // if Next of Kin field is set to mandatory and all fields (familyName, contact) are empty, 
            // return false (invalid)
            if (fieldSettings?.nok?.required === true && every(arr, x => isEmpty(x))) {
              return false;
            }

            return true;
          },
          then: Yup.string(),
          otherwise: Yup.string().trim().required("Please complete next of kin details"),
        }).nullable(),
      }).default({})
        .fieldSettingsValidation(fieldLabels.nok, fieldSettings?.nok)
        .nullable(),
      referralExpiry: Yup.date()
        .fieldSettingsValidation(fieldLabels.referralExpiry, fieldSettings?.referralExpiry)
        .nullable(),
      clinicTypeCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.clinicType, fieldSettings?.clinicType)
        .nullable(),
      claimTypeCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.claimType, fieldSettings?.claimType)
        .nullable(),
      designationCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.designation, fieldSettings?.designation)
        .nullable(),
      referrerProviderNumber: Yup.string()
        .max(50, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.referrerProviderNumber, fieldSettings?.referrerProviderNumber)
        .nullable(),
      referrerPhone: Yup.string()
        .max(50, maxLengthMessage)
        .fieldSettingsValidation(fieldLabels.referrerPhone, fieldSettings?.referrerPhone)
        .nullable(),
      referrerUrgency: Yup.bool()
        .nullable()
        .fieldSettingsValidation(fieldLabels.referrerUrgency, fieldSettings?.referrerUrgency)
        .default(false),
      meetsRequirements: Yup.bool()
        .nullable()
        .fieldSettingsValidation(fieldLabels.meetsRequirements, fieldSettings?.meetsRequirements)
        .default(false),
      referringOrgUnitId: Yup.string()
        .max(200, maxLengthMessage)
        .nullable()
        .fieldSettingsValidation(fieldLabels.referringOrgUnitId, fieldSettings?.referringOrgUnitId),
      addedAttachments: Yup.array()
        .fieldSettingsValidation(fieldLabels.attachments, fieldSettings?.attachments)
        .nullable()
        .default([]),
      removedAttachments: Yup.array().nullable()
        .default([]),
    }),
  });
};
