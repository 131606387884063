import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { ActionTypes, isCurrentCodeSetLoading, areCodeSetValuesLoaded, areCodeSetValuesLoading } from "app/main/codeSet/reducers/codeSet.reducers";
import { forEach, filter, map, isEmpty } from "lodash";
import { getCodeSetTypes } from "app/auth/store/reducers/system-configuration";

// Translate options for returning single/multiple CodeSetValue with term(code) as the value
export const translateTermOptions = (options, isTermSelector, selectedValue, showInactive = false) => {
  if (!options) return [];

  const filteredOptions = filter(options, x => showInactive || (!showInactive && x.isActive) || x.id === selectedValue);

  return map(filteredOptions, option => ({
    label: option.displayName,
    value: isTermSelector ? option.code : option.id,
    disabled: !option.isActive,
    settings: option.propertiesJson,
  }));
};

export const fetchCodeSetValues = (pageNumber, pageSize, searchParams) => dispatch => {
  const params = formatSearchParams(searchParams);
  const url = `/codeSet/getCodeSetValues?pageNumber=${pageNumber}&pageSize=${pageSize}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => isCurrentCodeSetLoading(state, pageNumber),
      entityType: "CURRENT_CODE_SET_VALUES",
      pageNumber,
    },
  });
};

export const addCodeSetValue = (codeSet, codeSetValue) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/codeSet/${codeSet.id}/new`,
    body: { codeSetValue },
    entityType: "CODE_SET_VALUE",
    codeSetType: codeSet.codeSetTypeCode,
  },
});

export const editCodeSetValue = (codeSet, codeSetValue) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/codeSet/${codeSet.id}/edit`,
    body: { codeSetValue },
    entityType: "CODE_SET_VALUE",
    codeSetType: codeSet.codeSetTypeCode,
  },
});

export const fetchMultipleCodeSetValues = (codeSetTypeCodes, showInactive = true, forceLoad = false) => dispatch => {
  let url = `/codeSet/getCodeSetValuesByType?showInactive=${showInactive}`;

  forEach(codeSetTypeCodes, value => {
    url += `&codeSetTypeCodes=${value}`;
  });

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: state => areCodeSetValuesLoading(state) || (!forceLoad && areCodeSetValuesLoaded(state)),
      entityType: "MULTIPLE_CODE_SET_VALUES",
    },
  });
};

export const fetchAllCodeSetValues = (forceLoad = false) => (dispatch, getState) => {
  const codeSetTypeCodes = getCodeSetTypes(getState());
  return isEmpty(codeSetTypeCodes) ? null : dispatch(fetchMultipleCodeSetValues(codeSetTypeCodes, true, forceLoad));
};

export const importCodeSetValue = (codeSetTypeCode, formData) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/codeSet/${codeSetTypeCode}/importCodeSetValues`,
    headers: { "Content-Type": "multipart/form-data" },
    body: formData,
    codeSetTypeCode,
    types: [ActionTypes.IMPORTING_CODE_SET_VALUES, ActionTypes.IMPORTED_CODE_SET_VALUES, ActionTypes.ERROR_IMPORTING_CODE_SET_VALUES],
  },
});
