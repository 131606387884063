import React from "react";
import { useSelector } from "react-redux";
import { find, isEmpty } from "lodash";
import { blue } from "@material-ui/core/colors";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Tooltip, Badge } from "@material-ui/core";
import clsx from "clsx";
import { getReferralWorklistSettings, getReferralWorkflowStateSettings, getDisplayReferrerUrgencyStates } from "app/auth/store/reducers/system-configuration";
import DefaultListItem from "components/items/default-list-item";
import formatDate from "helpers/format-date";
import ItemStatus from "components/items/item-status";
import { AssignedUserIcon, NoteIcon, TaskIcon, AddendumIcon } from "helpers/icon-finder";
import ReferralCategoryLabel from "app/main/referrals/components/referral-category-label";
import IconComponent from "components/items/icon-component";
import UrgentStatus from "app/main/referrals/components/urgent-status";
import ReferralDisabledItem from "app/main/patients/referrals/components/referral-disable-item";

const useStyles = makeStyles(theme => ({
  rootContainer: ({ isDisabled }) => ({
    cursor: isDisabled ? "not-allowed" : "pointer",
  }),
  container: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  content: {
    display: "flex",
    alignItems: "center",
    zIndex: 999,
    gap: `${theme.spacing(1)}px`,
    flex: 1,
  },
  counter: {
    backgroundColor: theme.palette.grey[200],
    border: `2px solid ${theme.palette.common.white}`,
    zoom: 0.7,
  },
}));

const Item = ({ title, icon }) => <Tooltip title={title}><div className="flex items-center"><IconComponent icon={icon} /></div></Tooltip>;

const ReferralSearchListing = ({
  referral: {
    id,
    hasUnreadAddendum,
    referralStatus,
    referralDateUtc,
    referrerOrganisationName,
    assignedTo,
    triageCategory,
    isUrgent,
    noteCounts,
    hasAddendum,
    incompleteTaskCounts,
    patient,
    referralStatusUpdated,
    referrerUrgency,
    orgUnitId,
    orgUnitName,
  },
  active,
  onClick,
  isDisabled = false,
  currentOrgUnitId,
}) => {
  const classes = useStyles({ isDisabled });
  const displayReferrerUrgencyStates = useSelector(getDisplayReferrerUrgencyStates);
  const referralWorkflowStateSettings = useSelector(getReferralWorkflowStateSettings);
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const searchListingContent = referralWorklistSettings?.searchListingContent;
  const noPatientAssignedMessage = referralWorklistSettings?.noPatientAssignedMessage;

  const getWorkflowStateSettings = stateName => find(referralWorkflowStateSettings, x => x.workflowState === stateName) || { icon: "lens", color: blue[600], label: "" };
  const referralStatusLabel = getWorkflowStateSettings(referralStatus).label.toUpperCase();
  const referralStatusColor = getWorkflowStateSettings(referralStatus).color;
  const unread = hasUnreadAddendum || referralStatusUpdated;
  const displayUrgentLabel = find(displayReferrerUrgencyStates, x => x === referralStatus) && referrerUrgency && isEmpty(triageCategory);

  const renderExtraContent = () => {
    if (searchListingContent === "assignment") {
      return assignedTo.value ? assignedTo.assignedToDisplayName : "";
    }
    if (searchListingContent === "referrer") {
      return referrerOrganisationName;
    }
    return "";
  };

  const BadgeComponent = ({ counts, children }) => <Badge classes={{ badge: classes.counter }} badgeContent={counts} max={9}>{children}</Badge>;

  return (
    <DefaultListItem
      highlight={unread}
      color={referralStatusColor}
      id={id}
      onClick={active || isDisabled ? null : () => onClick()}
      active={active}
      classes={{ root: classes.rootContainer }}
      content={(
        <>
          <div className={clsx(classes.container, "justify-between")}>
            <Typography variant="body1" className={unread ? "font-bold" : ""}>
              {isEmpty(patient) ? noPatientAssignedMessage : patient?.displayNameShort}{referralStatusUpdated ? " *" : ""}
            </Typography>
            <Typography variant="caption">{formatDate(referralDateUtc)}</Typography>
          </div>
          <div className={clsx(classes.container, "mt-16")}>
            <div className={classes.content}>
              <div>
                {referralStatus && <ItemStatus label={referralStatusLabel} color={referralStatusColor} />}
                <ReferralCategoryLabel triageCategory={triageCategory} isUrgent={isUrgent} orgUnitId={orgUnitId} />
              </div>
              {/* if has assigned user */}
              {assignedTo.assignedToUserId && <Item title="Assigned" icon={<AssignedUserIcon />} />}
              {/* if has notes/comments */}
              {noteCounts > 0 && <BadgeComponent counts={noteCounts}><Item title="Notes Recorded" icon={<NoteIcon />} /></BadgeComponent>}
              {/* if has addendum */}
              {hasAddendum && <Item title="Update Received" icon={<AddendumIcon />} />}
              {/* if has addendum */}
              {incompleteTaskCounts > 0 && <BadgeComponent counts={incompleteTaskCounts}><Item title="Incomplete tasks" icon={<TaskIcon />} /></BadgeComponent>}
              {displayUrgentLabel && <ItemStatus label="URGENT" color="red" icon={<UrgentStatus iconOnly />} />}
            </div>
            <Typography variant="caption">
              {renderExtraContent()}
            </Typography>
            {isDisabled && <ReferralDisabledItem orgUnitId={currentOrgUnitId} referralOrgUnitName={orgUnitName} referralOrgUnitId={orgUnitId} />}
          </div>
        </>
      )}
    />
  );
};

export default ReferralSearchListing;
