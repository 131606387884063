import SecureReferralWorklistPage from "./pages/secure-referral-worklist-page";
import ReferralPatientPage from "./pages/referral-patient-page";

const ReferralWorklistConfig = {
  routes: [
    {
      title: "Referral Worklist",
      path: "/referralWorklist",
      component: SecureReferralWorklistPage,
      exact: true,
      isIndexPage: true,
      routes: [
        {
          title: "Referral Worklist",
          path: "/referralWorklist?referralId=:referralId",
          component: SecureReferralWorklistPage,
          exact: true,
          isIndexPage: true,
        },
        {
          title: "Referral Worklist",
          path: "/referralWorklist?patientIdentifier=:patientIdentifier",
          component: SecureReferralWorklistPage,
          exact: true,
          isIndexPage: true,
        },
      ],
    },
    {
      path: "/referralWorklist/patient/:patientId",
      component: ReferralPatientPage,
      exact: true,
    },
  ],
};

export default ReferralWorklistConfig;
