import React from "react";
import { Field } from "formik";
import { some, filter } from "lodash";
import * as Yup from "yup";
import { useSelector } from "react-redux";

import { AutoComplete } from "components/inputs";
import Form from "components/form";
import { OrgUnitsIcon, UserRoleIcon } from "helpers/icon-finder";
import { getAllowAutoAssignRoleActionTypes } from "app/auth/store/reducers/system-configuration";

const actionTypeLabel = "Action Type";
const roleLabel = "Auto Assign Role";

const schema = Yup.object().shape({
  autoAssignRole: Yup.object().shape({
    actionType: Yup.string().required(`${actionTypeLabel} is required`).nullable(),
    roleId: Yup.string().required(`${roleLabel} is required`).nullable(),
  }),
});

const { autoAssignRole } = schema.cast();

const AutoAssignRoleForm = ({ autoAssignRoles, onSubmit, options, ...other }) => {
  const supportedReferralActionTypeValues = useSelector(getAllowAutoAssignRoleActionTypes);
  return (
    <Form
      initialValues={{ autoAssignRole }}
      contentProps={other}
      onSubmit={onSubmit}
      validationSchema={schema}
      content={() => (
        <>
          <Field
            name="autoAssignRole.actionType"
            label={actionTypeLabel}
            component={AutoComplete}
            options={filter(supportedReferralActionTypeValues, result => !some(autoAssignRoles, x => x.actionType === result.value))}
            icon={<OrgUnitsIcon />}
            required
            onChange={(value, change) => {
              change("autoAssignRole.actionType", value?.value);
            }}
          />
          <Field
            name="autoAssignRole.roleId"
            label={roleLabel}
            component={AutoComplete}
            options={options}
            icon={<UserRoleIcon />}
            required
            onChange={(value, change) => {
              change("autoAssignRole.roleId", value?.value);
              change("autoAssignRole.roleName", value?.label);
            }}
          />
        </>
      )}
    />
  );
};

export default AutoAssignRoleForm;
