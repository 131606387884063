import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { isPageLoading,
  isPageLoaded,
  isCurrentLoading,
  isOrgUnitsSelectorLoading,
  isOrgUnitsSelectorLoaded,
  areEndorsementTypesLoading,
  areEndorsementTypesLoaded,
  areCurrentOrgUnitFormFieldsLoading,
  areCurrentOrgUnitFormFieldsLoaded,
  areOrgUnitFormFieldConfigByIdLoading,
  areOrgUnitFormFieldConfigByIdLoaded,
  ActionTypes } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";

export const fetchOrgUnitsForSelector = (searchParams, forceLoad = true) => async (dispatch, getState) => {
  const params = formatSearchParams(searchParams);
  const pageNumber = 1;
  const orgUnitId = getSignedInOrgUnit(getState())?.id;
  const url = `/orgUnits/${orgUnitId}?pageNumber=${pageNumber}&pageSize=999${params}`;
  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "ORG_UNIT_SELECTOR",
      bailout: state => isOrgUnitsSelectorLoading(state) || (!forceLoad && isOrgUnitsSelectorLoaded(state)),
    },
  });
};

export const fetchOrgUnitsPharmacyForSelector = (searchParams, forceLoad = true) => async (dispatch, getState) => {
  const params = formatSearchParams(searchParams);
  const pageNumber = 1;
  const orgUnitId = getSignedInOrgUnit(getState())?.id;
  const url = `/orgUnits/${orgUnitId}?pageNumber=${pageNumber}&pageSize=999${params}`;
  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "ORG_UNIT_PHARMACY_SELECTOR",
      bailout: state => isOrgUnitsSelectorLoading(state) || (!forceLoad && isOrgUnitsSelectorLoaded(state)),
    },
  });
};

export const fetchOrgUnitsResults = (orgUnitId, pageNumber, pageSize, searchParams, forceLoad = true) => dispatch => {
  const params = formatSearchParams(searchParams);
  const url = `/orgUnits/${orgUnitId}?pageNumber=${pageNumber}&pageSize=${pageSize}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "ORG_UNITS",
      pageNumber,
      bailout: state => isPageLoading(state, pageNumber) || (!forceLoad && isPageLoaded(state, pageNumber)),
    },
  });
};

export const fetchOrgUnitById = orgUnitId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/GetDetail`,
    entityType: "CURRENT_ORG_UNIT",
    bailout: state => isCurrentLoading(state),
  },
});

export const addOrgUnit = (orgUnitId, orgUnit) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/${orgUnit.orgUnitType}`,
    body: { orgUnit },
    entityType: "ORG_UNIT",
  },
});

export const editOrgUnit = (orgUnitId, orgUnit) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/${orgUnit.orgUnitType}`,
    body: { orgUnit },
    entityType: "ORG_UNIT",
  },
});

export const deprecateOrgUnit = orgUnitId => ({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/deprecate`,
    entityType: "DEPRECATE_ORG_UNIT",
  },
});

export const restoreOrgUnit = orgUnitId => ({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/restore`,
    entityType: "RESTORE_ORG_UNIT",
  },
});

export const editOrgUnitFormFields = (orgUnitId, formType, formFieldConfiguration) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/editFormField/${formType}`,
    body: { formFieldConfiguration },
    orgUnitId,
    formType,
    entityType: "ORG_UNIT_FORM_FIELDS",
  },
});

export const fetchOrgUnitFormFields = (orgUnitId, formType, forceLoad = false) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/getFormFieldConfigs/${formType}`,
    entityType: "CURRENT_ORG_UNIT_FORM_FIELDS",
    orgUnitId,
    formType,
    bailout: state => areCurrentOrgUnitFormFieldsLoading(state, orgUnitId, formType) || (!forceLoad && areCurrentOrgUnitFormFieldsLoaded(state, orgUnitId, formType)),
  },
});

export const addOrgUnitFormFieldConfiguration = (orgUnitId, formFieldConfiguration) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/newFormFieldConfiguration/${formFieldConfiguration.formType}`,
    body: { formFieldConfiguration },
    entityType: "ORG_UNIT_FORM_FIELD_CONFIG",
    orgUnitId,
  },
});

export const editOrgUnitFormFieldConfiguration = (orgUnitId, formFieldConfiguration) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/editFormFieldConfiguration/${formFieldConfiguration.id}`,
    body: { formFieldConfiguration },
    orgUnitId,
    formFieldConfigurationId: formFieldConfiguration.id,
    entityType: "ORG_UNIT_FORM_FIELD_CONFIG",
  },
});

export const fetchOrgUnitFormFieldsById = (orgUnitId, formFieldConfigurationId, forceLoad = false) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/getFormFieldConfigsById/${formFieldConfigurationId}`,
    entityType: "CURRENT_ORG_UNIT_FORM_FIELD_CONFIG",
    orgUnitId,
    formFieldConfigurationId,
    bailout: state => areOrgUnitFormFieldConfigByIdLoading(state, orgUnitId, formFieldConfigurationId) || (!forceLoad && areOrgUnitFormFieldConfigByIdLoaded(state, orgUnitId, formFieldConfigurationId)),
  },
});

export const fetchEndorsementTypeConfiguration = orgUnitId => dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/getEndorsementTypeConfiguration`,
    entityType: "ENDORSEMENT_TYPE_CONFIGURATION",
    orgUnitId,
    bailout: state => areEndorsementTypesLoading(state, orgUnitId) || areEndorsementTypesLoaded(state, orgUnitId),
  },
});

export const fetchOrgUnitNotificationConfiguration = (orgUnitId, pageNumber = 1, pageSize = 50) => dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/getNotificationConfiguration?pageNumber=${pageNumber}&pageSize=${pageSize}`,
    entityType: "NOTIFICATION_CONFIGURATION",
    orgUnitId,
  },
});

export const saveNotificationConfiguration = (orgUnitId, notificationConfiguration) => dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/editNotificationConfiguration`,
    body: { notificationConfiguration },
    entityType: "NOTIFICATION_CONFIGURATION",
    orgUnitId,
  },
});

export const fetchNotificationConfigurationByType = (orgUnitId, eventType) => dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/getNotificationConfiguration/${eventType}`,
    entityType: "CURRENT_NOTIFICATION_CONFIGURATION",
    orgUnitId,
  },
});

export const editEndorsementTypeConfiguration = (orgUnitId, endorsementTypeConfiguration) => dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/editEndorsementTypeConfiguration`,
    body: { endorsementTypeConfiguration },
    entityType: "ENDORSEMENT_TYPE_CONFIGURATION",
    orgUnitId,
  },
});

export const uploadDecisionLetterTemplate = (orgUnitId, formData) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/uploadDecisionLetterTemplate`,
    headers: { "Content-Type": "multipart/form-data" },
    body: formData,
    entityType: "DECISION_LETTER_TEMPLATE",
  },
});

export const removeDecisionLetterTemplate = orgUnitId => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/removeDecisionLetterTemplate`,
    entityType: "DECISION_LETTER_TEMPLATE",
  },
});

export const uploadIPABlankForm = (orgUnitId, formData) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/uploadIPABlankForm`,
    headers: { "Content-Type": "multipart/form-data" },
    body: formData,
    entityType: "IPA_BLANK_FORM",
  },
});

export const removeIPABlankForm = orgUnitId => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/removeIPABlankForm`,
    entityType: "IPA_BLANK_FORM",
  },
});

// handle template file upload
export const uploadGPLetterAttachment = (orgUnitId, formData) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/uploadGPLetterAttachment`,
    headers: { "Content-Type": "multipart/form-data" },
    body: formData,
    entityType: "GP_LETTER_TEMPLATE",
  },
});

export const updateGPLetterAttachment = (orgUnitId, attachmentId, formData) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/${attachmentId}/updateGPLetterAttachment`,
    headers: { "Content-Type": "multipart/form-data" },
    body: formData,
    attachmentId,
    entityType: "GP_LETTER_TEMPLATE",
  },
});

// handle save details
export const updateGPLetterTemplate = (orgUnitId, gpLetterTemplate) => dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/orgUnits/${orgUnitId}/updateGPLetter`,
    body: { gpLetterTemplate },
    entityType: "GP_LETTER_TEMPLATE",
  },
});

export const removeGPLetterTemplate = (orgUnitId, attachmentId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/removeGPLetter/${attachmentId}`,
    entityType: "GP_LETTER_TEMPLATE",
    attachmentId,
  },
});

export const fetchGPLetterTemplateAttachments = orgUnitId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/orgUnits/${orgUnitId}/getGPLetterTemplateAttachments`,
    entityType: "GP_LETTER_TEMPLATES",
    orgUnitId,
  },
});

export const fetchSubFormularies = orgUnitId => dispatch => {
  const url = `/siteConfiguration/SubFormularies/${orgUnitId}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "SUB_FORMULARIES",
    },
  });
};

export const UpdateOrgUnitFormFieldConfigurations = allOrgUnitFormFieldConfigurations => ({
  type: ActionTypes.UPDATE_ORG_UNIT_FORM_FIELD_CONFIG,
  payload: allOrgUnitFormFieldConfigurations,
});
