import React from "react";
import { Field } from "formik";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { get } from "lodash";
import Form from "components/form";
import { DatePicker, NoteInput } from "components/inputs";
import { onAcceptApplication } from "app/main/applications/actions/applications.actions";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";

const meetingDateLabel = "Meeting Date";
const agendaCategoryLabel = "Agenda Category";

const schema = Yup.object().shape({
  acceptApplication: Yup.object().shape({
    meetingDate: Yup.date()
      .required(`${meetingDateLabel} is required`)
      .nullable(),
    agendaCategory: Yup.string()
      .required(`${agendaCategoryLabel} is required`)
      .nullable(),
    patientHistory: Yup.string().nullable(),
  }),
});

const AcceptApplicationForm = ({
  decision,
  id,
  onSucceed,
  patientHistoryLabel,
  orgUnitId,
  ...other
}) => {
  const dispatch = useDispatch();

  const handleSubmit = ({ acceptApplication }, { setErrors }) => {
    let data = acceptApplication;
    const agendaCategory = get(data, ["agendaCategory", "value"], null);

    data = {
      ...data,
      agendaCategory,
    };

    dispatch(onAcceptApplication(id, orgUnitId, data)).then(response => {
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  const { acceptApplication } = schema.cast();

  return (
    <Form
      initialValues={{ acceptApplication }}
      onSubmit={handleSubmit}
      contentProps={other}
      validationSchema={schema}
      content={() => (
        <>
          <Field
            name="acceptApplication.meetingDate"
            component={DatePicker}
            label={meetingDateLabel}
            required
          />
          <Field
            name="acceptApplication.agendaCategory"
            label={agendaCategoryLabel}
            codeSetTypeCode="AgendaCategory"
            isTermSelector
            component={CodeSetValueSelector}
            icon="list_alt"
            required
          />
          <Field
            name="acceptApplication.patientHistory"
            label={patientHistoryLabel}
            component={NoteInput}
            maxLength={500}
          />
        </>
      )}
    />
  );
};

export default AcceptApplicationForm;
