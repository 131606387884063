import moment from "moment-timezone";
import { isEmpty, map, find, includes, filter, startCase, flatten, uniqBy, sortBy as lodashSortBy, forEach, concat, uniq } from "lodash";

import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import downloadFromApi, { fetchFromApi } from "utils/download-from-api";
import { getReferralWorkflowStateSettings, getAllTriageCategorySettings, getReferralWorklistSettings, getReferralSortByOptions, getAllRoles } from "app/auth/store/reducers/system-configuration";
import { getUser, getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import getDefaultWorkListFilterOption from "helpers/get-default-worklist-filter-option";
import { ActionTypes as ReferralsActionTypes } from "app/main/referrals/reducers/referrals.reducers";
import { getCurrentOrgUnitFormFields } from "app/main/orgUnits/reducers/orgUnit.reducers";

import { ActionTypes, bailOutOfLoadingReferrals, shouldShowStatusSearchTags, getDefaultFilterOptionSearchParams, getDefaultSortOrder, getSearchParameters, IsEnableClinicalRoleReferralActiveView, IsEnableReferralWorklistDefaultMyReferrals } from "../reducers/referralWorklist.reducers";

const getDefaultSpecialtySearchParams = (state, presetFilter) => {
  const currentUser = getUser(state);
  const signedInOrgUnit = getSignedInOrgUnit(state);
  const orgUnitId = signedInOrgUnit?.id;

  const { userSpecialtyProfiles, userRoles, userId } = currentUser;
  const assignedSpecialtyProfiles = filter(userSpecialtyProfiles, userSpecialtyProfile => userSpecialtyProfile.specialtyProfile?.orgUnitId === orgUnitId);
  const currentUserRoleIds = map(filter(userRoles, x => x.orgUnitId === orgUnitId), r => r.roleId);
  const specialtyProfileAvailableRoles = flatten(map(assignedSpecialtyProfiles, x => x.specialtyProfileAvailableRoleIds));

  const referralWorklistSettings = getReferralWorklistSettings(state);
  const assignedToRoleIds = referralWorklistSettings?.enableWorkListSearchFilterAutoAssignedToRole
    ? map(filter(specialtyProfileAvailableRoles, x => includes(currentUserRoleIds, x)), r => r)
    : null;

  let defaultParams = {
    assignedToSpecialtyIds: map(assignedSpecialtyProfiles, x => x.specialtyProfile?.id),
    assignedToSubSpecialtyIds: map(assignedSpecialtyProfiles, x => x.subSpecialty?.id),
    assignedToRoleIds,
  };

  if (presetFilter === "myOutstandingTasks") {
    defaultParams = {
      ...defaultParams,
      taskStatus: "OutstandingTasks",
      taskAssignedToUserId: userId,
      taskAssignedToRoleIds: map(userRoles, role => role.roleId),
    };
  }

  if (presetFilter === "myReferrals") {
    // My Referral filter should just restrict based on the created by user field
    defaultParams = {
      createdByUserId: userId,
      includeAllSites: true,
    };
  }

  return defaultParams;
};

export const updateReferralWorkListFilter = (presetFilter, showStatusTags) => (dispatch, getState) => new Promise(resolve => {
  const defaultSpecialtySearchParams = getDefaultSpecialtySearchParams(getState(), presetFilter);
  const defaultSortOrderSearchParam = getDefaultSortOrder(getState());
  const lastSearchParams = getSearchParameters(getState());
  const allRoles = getAllRoles(getState());
  // try to get the user selected sort order from lastSearchParams first and then get defaultSortOrder if the sorOrder in lastSearchParams is empty.
  const sortOrderDescending = lastSearchParams?.sortOrderDescending ?? defaultSortOrderSearchParam?.sortOrderDescending;
  const preSetSearchParams = { ...defaultSpecialtySearchParams, ...defaultSortOrderSearchParam, sortOrderDescending };

  dispatch({ type: ActionTypes.UPDATE_REFERRAL_WORKLIST_FILTER, payload: { presetFilter, showStatusTags, preSetSearchParams, allRoles } });
  const state = getState();
  resolve(state?.referralWorklist.searchParams);
});

export const setUpReferralWorklistFilter = () => (dispatch, getState) => {
  const state = getState();
  const settings = getReferralWorkflowStateSettings(state);
  const defaultSortOrderSearchParam = getDefaultSortOrder(state);
  const referralWorklistSettings = getReferralWorklistSettings(state);
  const defaultWorkListSearchFilterRoleTypeSettings = referralWorklistSettings?.defaultWorkListSearchFilterRoleTypeSettings;
  const user = getUser(state);
  const signedInOrgUnit = getSignedInOrgUnit(state);
  const currentUserRole = find(user.userRoles, x => x.orgUnitId === signedInOrgUnit.id);
  const isEnableClinicalRoleReferralActiveView = IsEnableClinicalRoleReferralActiveView(state);
  const isEnableReferralWorklistDefaultMyReferrals = IsEnableReferralWorklistDefaultMyReferrals(state);
  const defaultSearchType = getDefaultWorkListFilterOption(defaultWorkListSearchFilterRoleTypeSettings, currentUserRole, isEnableClinicalRoleReferralActiveView, isEnableReferralWorklistDefaultMyReferrals);
  const defaultSpecialtySearchParams = getDefaultSpecialtySearchParams(state, defaultSearchType);
  const allRoles = getAllRoles(getState());

  // set to default user's specialty and role (if has been set)
  return dispatch({
    type: ActionTypes.SETUP_REFERRAL_WORKLIST_FILTERS,
    payload: {
      settings,
      defaultSearchType,
      allRoles,
      searchParams: { ...defaultSpecialtySearchParams, ...defaultSortOrderSearchParam },
      referralWorklistSettings,
      isEnableClinicalRoleReferralActiveView,
      isEnableReferralWorklistDefaultMyReferrals,
    },
  });
};

export const resetReferralWorklistFilter = defaultType => (dispatch, getState) => {
  const defaultSearchParams = getDefaultFilterOptionSearchParams(getState());
  const defaultSpecialtySearchParams = getDefaultSpecialtySearchParams(getState(), defaultType);
  const defaultSortOrderSearchParam = getDefaultSortOrder(getState());

  return dispatch({
    type: ActionTypes.RESET_REFERRAL_WORKLIST_FILTER,
    payload: {
      searchParams: {
        ...defaultSearchParams,
        ...defaultSpecialtySearchParams,
        ...defaultSortOrderSearchParam,
      },
    },
  });
};

export const searchReferralResults = (searchParams, pageNumber = 1, pageSize = 20, forceLoad = false) => (dispatch, getState) => {
  const timeZone = moment.tz.guess();
  const defaultSortOrder = getDefaultSortOrder(getState);

  const formattedSearchParams = {
    ...searchParams,
    sortOrderDescending: searchParams?.sortOrderDescending ?? defaultSortOrder?.sortOrderDescending,
    applyUrgentReferralFirst: searchParams?.applyUrgentReferralFirst ?? defaultSortOrder?.applyUrgentReferralFirst,
  };

  const defaultSearchParams = {
    ...getDefaultFilterOptionSearchParams(getState()),
    assignedToSpecialtyIds: searchParams.assignedToSpecialtyIds,
    assignedToRoleIds: searchParams.assignedToRoleIds,
    ...defaultSortOrder,
    assignedToSpecialtyId: searchParams.assignedToSpecialtyId,
  };
  const shouldLoadLastReferralSelectSearchParams = !isEmpty(localStorage.getItem("lastSearchParams")) && JSON.stringify(defaultSearchParams) === JSON.stringify(searchParams);

  if (JSON.stringify(defaultSearchParams) !== JSON.stringify(formattedSearchParams) || localStorage.getItem("lastSelectReferralSearchOption") === "active" || localStorage.getItem("lastSelectReferralSearchOption") === "clinicalRoleActiveView") {
    localStorage.setItem("lastSearchParams", JSON.stringify(formattedSearchParams));
  }
  localStorage.setItem("shouldLoadLastReferralSelectSearchParams", shouldLoadLastReferralSelectSearchParams);
  let params = searchParams;
  const referralWorklistSettings = getReferralWorklistSettings(getState());
  if (shouldLoadLastReferralSelectSearchParams) {
    params = JSON.parse(localStorage.getItem("lastSearchParams"));
    if (!referralWorklistSettings?.setLastSelectReferralSortOrder) {
      params = {
        ...params,
        sortOrderDescending: getDefaultSortOrder(getState)?.sortOrderDescending,
      };
    }
  }

  params = formatSearchParams(params);
  const url = `/referrals/${searchParams.orgUnitId}?pageNumber=${pageNumber}&pageSize=${pageSize}&timeZone=${timeZone}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: !forceLoad && bailOutOfLoadingReferrals(getState(), pageNumber),
      types: [
        ActionTypes.SEARCHING_REFERRALS,
        ActionTypes.SEARCHED_REFERRALS,
        ActionTypes.ERROR_SEARCHING_REFERRALS,
      ],
      pageNumber,
    },
  });
};

export const exportReferralResults = (searchParams, backgroundExport = false, pageNumber = 1, pageSize = 20) => async () => {
  const params = formatSearchParams(searchParams);
  const timeZone = moment.tz.guess();
  const url = `api/referrals/${searchParams.orgUnitId}/export?pageNumber=${pageNumber}&pageSize=${pageSize}&timeZone=${timeZone}${params}`;

  if (backgroundExport) {
    const response = await fetchFromApi(url);
    return response.statusText;
  }

  const response = await downloadFromApi(url);
  return response.statusText;
};

export const clearReferralWorkListFilterTags = () => ({
  type: ActionTypes.RESET_REFERRAL_WORKLIST_FILTER_TAGS,
});

export const formattedReferralWorkListFilterTags = (searchParams, getState) => {
  if (!searchParams) return null;
  const state = getState();
  const referralWorkflowSettings = getReferralWorkflowStateSettings(state);
  const triageCategorySettings = getAllTriageCategorySettings(state);
  const showStatusTags = shouldShowStatusSearchTags(state);
  const sortByOptions = getReferralSortByOptions(state);

  const getWorkflowStateLabel = stateName => find(referralWorkflowSettings, x => x.workflowState === stateName)?.label || stateName;
  const getTriageCategoryLabel = category => find(triageCategorySettings, choice => choice.triageCategory === category)?.label;

  const {
    orgUnitId,
    status,
    category,
    startDate,
    endDate,
    unreadAddendum,
    isUrgent,
    onlyShowUnassigned,
    assignedTo,
    assignedToSpecialtyIds,
    assignedToRoleIds,
    assignedToRoles,
    assignedToUserId,
    daysSinceLastStatusChange,
    assignedToSubSpecialties,
    taskStatus,
    taskAssignedToUserId,
    taskAssignedToRoleIds,
    taskAssignedToUser,
    taskAssignedToRoles,
    createdByUserId,
    createdByUser,
    assignedToSpecialistId,
    sortBy,
    sortOrderDescending,
    applyUrgentReferralFirst,
    isReferrerUrgent,
    presentingComplaintCodes,
    presentingComplaintCodesDisplay,
    referrerAdvisedPriorityCodeIds,
    referrerAdvisedPriorityCodes,
    referringOrgUnitNames,
    referringLocationCodeDisplayNames,
    reasonForReferralCodeDisplayNames,
    typeOfReferralCodeDisplayNames,
    onlyUnassignedReferrerAdvisedPriority,
    includeAllSites,
  } = searchParams;

  let categoryTags = null;
  let statusTags = null;
  let assignedToSpecialtyLabel = null;
  let assignedToUser = null;
  let assignedToSpecialistLabel = null;
  let assignedToSpecialtyRoleLabel = null;
  const specialtyProfileFromSubSpecialty = map(uniqBy(assignedToSubSpecialties, "specialtyProfileName"), x => x.specialtyProfileName);
  const allAssignedSpecialtyProfileNames = lodashSortBy(uniq(concat(map(assignedTo, x => x.specialtyProfileName), specialtyProfileFromSubSpecialty)));
  const presentingComplaintCodesTags = presentingComplaintCodes ? map(presentingComplaintCodesDisplay, x => x.displayName).join(", ") : null;
  let referrerAdvisedPriorityCodesTags = null;
  let onlyUnassignedReferrerAdvisedPriorityLabel = null;
  let includeAllSitesTag = null;

  if (assignedTo) {
    if (assignedToSpecialtyIds) {
      forEach(allAssignedSpecialtyProfileNames, value => {
        assignedToSpecialtyLabel = isEmpty(assignedToSpecialtyLabel) ? "" : `${assignedToSpecialtyLabel}, `;
        if (includes(specialtyProfileFromSubSpecialty, value)) {
          const subSpecialtiesBySpecialty = filter(assignedToSubSpecialties, assignedToSubSpecialty => assignedToSubSpecialty.specialtyProfileName === value);
          assignedToSpecialtyLabel = `${assignedToSpecialtyLabel}${value} (${map(subSpecialtiesBySpecialty, x => x.displayName).join(", ")})`;
        } else {
          assignedToSpecialtyLabel = `${assignedToSpecialtyLabel}${value}`;
        }
      });
    }

    if (assignedToUserId) {
      assignedToUser = assignedTo.userName;
    }

    if (assignedToSpecialistId) {
      assignedToSpecialistLabel = map(assignedTo, x => ` ${x.specialistName}`);
    }
  }

  if (assignedToUserId) {
    assignedToUser = searchParams.assignedToUser;
  }

  if (assignedToRoleIds) {
    assignedToSpecialtyRoleLabel = map(assignedToRoles, x => x.roleDescription ?? startCase(x.roleName));
  }

  if (!isEmpty(status)) {
    statusTags = `${map(status, s => ` ${getWorkflowStateLabel(s)}`)}`;
  }
  if (!isEmpty(category)) {
    categoryTags = `${map(category, c => ` ${getTriageCategoryLabel(c)}`)}`;
  }
  const sortByDateLabel = sortBy ? find(sortByOptions, x => x.value === sortBy)?.label : null;
  const sortByUrgentReferralFirstLabel = applyUrgentReferralFirst === true ? "Urgent on Top, " : "";

  if (referrerAdvisedPriorityCodeIds) {
    referrerAdvisedPriorityCodesTags = `${map(referrerAdvisedPriorityCodes, x => x.displayName).join(", ")}`;
  }

  if (onlyUnassignedReferrerAdvisedPriority) {
    const triageFieldSettings = getCurrentOrgUnitFormFields(state, orgUnitId, "TriageForm");
    const rejectFieldSettings = getCurrentOrgUnitFormFields(state, orgUnitId, "RejectForm");
    const referrerAdvisedPriorityLabel = (triageFieldSettings?.referrerAdvisedPriority?.label ?? rejectFieldSettings?.referrerAdvisedPriority?.label) || "Referrer Advised Priority";
    onlyUnassignedReferrerAdvisedPriorityLabel = `Not Assigned To ${referrerAdvisedPriorityLabel}`;
  }

  if (includeAllSites) {
    includeAllSitesTag = "All Sites";
  }

  const tags = [
    showStatusTags && statusTags,
    showStatusTags && daysSinceLastStatusChange && `${daysSinceLastStatusChange} Day${daysSinceLastStatusChange > 1 ? "s" : ""} Waiting in Status`,
    categoryTags,
    startDate && endDate && `Referrals Dated from ${startDate} to ${endDate}`,
    unreadAddendum && "Has Unread Updates",
    (isUrgent || isReferrerUrgent) && "Marked as Urgent",
    assignedToSpecialtyLabel && `Assigned to ${assignedToSpecialtyLabel}`,
    assignedToSpecialtyRoleLabel && `Assigned to Role ${assignedToSpecialtyRoleLabel}`,
    assignedToSpecialistLabel && `Assigned to Specialist ${assignedToSpecialistLabel}`,
    assignedToUserId && `Assigned to ${assignedToUser}`,
    onlyShowUnassigned && "Not Assigned to a Specialty",
    taskStatus === "CompletedTasks" && "Has Completed Tasks",
    taskStatus === "OutstandingTasks" && "Has Outstanding Tasks",
    taskStatus === "OverdueTasks" && "Has Overdue Tasks",
    taskAssignedToUserId && `Tasks Assigned To ${taskAssignedToUser}`,
    taskAssignedToRoleIds && `Tasks Assigned To ${taskAssignedToRoles.join(", ")}`,
    createdByUserId && `Referrals Created by ${createdByUser}`,
    includeAllSitesTag,
    presentingComplaintCodesTags,
    referrerAdvisedPriorityCodesTags,
    onlyUnassignedReferrerAdvisedPriorityLabel,
    sortByDateLabel && `${sortByUrgentReferralFirstLabel}${sortByDateLabel} ${sortOrderDescending ? "\u2191" : "\u2193"}`,
    referringOrgUnitNames && `Referring to ${referringOrgUnitNames}`,
    referringLocationCodeDisplayNames && referringLocationCodeDisplayNames,
    reasonForReferralCodeDisplayNames && reasonForReferralCodeDisplayNames,
    typeOfReferralCodeDisplayNames && `Show ${typeOfReferralCodeDisplayNames} referrals`,
  ];
  return tags;
};

export const updateReferralWorkListFilterTags = searchParams => (dispatch, getState) => {
  const tags = formattedReferralWorkListFilterTags(searchParams, getState);

  return dispatch({
    type: ActionTypes.UPDATE_REFERRAL_WORKLIST_FILTER_TAGS,
    payload: tags,
  });
};

export const updateReferralsAssignedSite = () => dispatch => dispatch({
  type: ReferralsActionTypes.MOVE_CURRENT_REFERRAL,
});

export const clearLastSearchParams = () => dispatch => dispatch({
  type: ActionTypes.CLEAR_LAST_SEARCH_PARAMS,
});
