import React from "react";
import { Button, CircularProgress, Tooltip, Box, Fab } from "@material-ui/core";
import clsx from "clsx";

import IconComponent from "components/items/icon-component";

const DefaultButton = ({
  icon,
  label,
  onClick,
  loading,
  size,
  disabled,
  tooltip,
  tooltipPlacement,
  fab,
  className,
  component: Component = fab ? Fab : Button,
  component: Container = tooltip ? Tooltip : React.Fragment,
  ...other
}) => {
  const containerProps = tooltip ? { title: tooltip, placement: tooltipPlacement } : null;

  return (
    <Container {...containerProps}>
      <div className={className}>
        <Component
          variant={fab ? "extended" : "contained"}
          color="secondary"
          onClick={onClick}
          size={size}
          disabled={loading || disabled}
          className="w-full"
          {...other}
        >
          <Box className={clsx("pointer-events-none flex items-center justify-center", fab && "px-8")}>
            {(loading || icon) && (
              <div className="flex items-center mr-8">
                {loading ? <CircularProgress size={20} /> : <IconComponent icon={icon} />}
              </div>
            )}
            {label}
          </Box>
        </Component>
      </div>
    </Container>
  );
};

DefaultButton.defaultProps = {
  size: "small",
};

export default DefaultButton;
