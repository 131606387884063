import React from "react";
import { Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { getAllTriageCategorySettings } from "app/auth/store/reducers/system-configuration";
import UrgentStatus from "app/main/referrals/components/urgent-status";
import useTriageFieldSettings from "app/main/referrals/hooks/useTriageFieldSettings";

export default function ReferralCategoryLabel({ orgUnitId, triageCategory, isUrgent, showUrgentIcon = true, ...other }) {
  const triageCategorySettings = useSelector(state => getAllTriageCategorySettings(state));
  const category = find(triageCategorySettings, setting => setting.triageCategory === triageCategory);
  const { fieldLabels } = useTriageFieldSettings(orgUnitId);

  const renderUrgent = () => (
    <div className="flex items-center">
      <div>{` - ${fieldLabels?.isUrgent ?? "URGENT"}`}</div>
      {showUrgentIcon && <UrgentStatus iconOnly />}
    </div>
  );

  return (
    <Box fontWeight="bold" display="flex" alignItems="center" color={category?.color} {...other}>
      {category?.label}
      {isUrgent && renderUrgent()}
    </Box>
  );
}
