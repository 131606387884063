import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { TableCell, TableRow } from "@material-ui/core";
import PagingTable from "components/items/paging-table";
import Avatar from "components/items/avatar";

import { PATIENTS_PAGE_SIZE_OPTIONS,
  getAllPatients,
  isPageLoading,
  getErrorMessage,
  getPageInfo,
  getPatientSearchTerms } from "app/main/patients/reducers/patients.reducers";
import { fetchPatientResults, getExistingPatientById } from "app/main/patients/actions/patients.actions";
import TextTag from "components/items/text-tag";
import { getSexLabel } from "app/auth/store/reducers/system-configuration";

const PatientsTable = ({
  history,
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const loading = useSelector(isPageLoading);
  const error = useSelector(getErrorMessage);
  const pageInfo = useSelector(getPageInfo);
  const patients = useSelector(getAllPatients);
  const searchParams = useSelector(getPatientSearchTerms);
  const sexLabel = useSelector(getSexLabel);

  useEffect(() => {
    dispatch(fetchPatientResults(1, 50, null, location.state?.forceLoad === true || false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeRowsPerPage = pageSize => dispatch(fetchPatientResults(1, pageSize, searchParams));
  const onChangePage = pageNumber => dispatch(fetchPatientResults(pageNumber + 1, pageInfo.pageSize, searchParams));
  const onEdit = id => dispatch(getExistingPatientById(id, true)).then(() => history.push(`/admin/patients/${id}`));
  
  const renderTableHeader = () => (
    <TableRow>
      <TableCell />
      <TableCell>Last Name</TableCell>
      <TableCell>First Name</TableCell>
      <TableCell>Preferred Name</TableCell>
      <TableCell>Middle Name</TableCell>
      <TableCell>Birth Date</TableCell>
      <TableCell>{sexLabel}</TableCell>
    </TableRow>
  );

  const renderTableRow = item => (
    <TableRow
      hover
      key={item.patientId}
      onClick={() => onEdit(item.patientId)}
    >
      <TableCell className="w-52" component="th" scope="row" padding="none">
        <div className="flex justify-center py-8 px-16">
          <Avatar />
          {item.isDeprecated && <TextTag label="DEPRECATED" variant="error" type="badge" />}
          {item.isRemoved && <TextTag label="REMOVED" variant="warning" type="badge" />}
        </div>
      </TableCell>
      <TableCell>{item.familyName}</TableCell>
      <TableCell>{item.givenName}</TableCell>
      <TableCell>{item.preferredName}</TableCell>
      <TableCell>{item.middleName}</TableCell>
      <TableCell>{item.birthDate}</TableCell>
      <TableCell>{item.sex}</TableCell>
    </TableRow>
  );

  return (
    <PagingTable
      loading={loading}
      error={error}
      data={patients}
      rowsPerPage={pageInfo.pageSize}
      columnSpan={5}
      pageSizeOptions={PATIENTS_PAGE_SIZE_OPTIONS}
      page={pageInfo.pageNumber - 1}
      count={pageInfo.totalRecords}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      emptyMessage="No Patients Recorded"
      tableHead={renderTableHeader}
      tableRow={item => renderTableRow(item)}
    />
  );
};

export default PatientsTable;
