import React, { Component } from "react";
import { connect } from "react-redux";
import { isEmpty, size, filter, fromPairs, replace, startCase, find, get, upperCase } from "lodash";
import { Typography } from "@material-ui/core";
import { withRouter } from "react-router-dom";

import ChatPanel from "components/items/chat-panel";
import DefaultListItem from "components/items/default-list-item";
import formatDate from "helpers/format-date";
import InfiniteLoadingList from "components/items/infinite-Loading-list";
import ItemStatus from "components/items/item-status";
import LoadingState from "components/items/loading-state";
import DefaultButton from "components/items/default-button";
import withPermissions from "permissions/withPermissions";

import { searchApplications } from "app/main/applicationWorklist/actions/applicationWorklist.actions";
import { isApplicationWorklistLoading, getApplicationWorklistLoadingErrors, getTotalNumberOfApplications, getCurrentPage, getSearchParameters, defaultPageNumber, defaultPageSize } from "app/main/applicationWorklist/reducers/applicationWorklist.reducers";
import { getApplicationsForWorklist, isCurrentApplicationLoading, isCurrentApplicationLoadingError, getCurrentApplication } from "app/main/applications/reducers/applications.reducers";
import ApplicationAdvancedFilter from "app/main/applicationWorklist/components/application-advanced-filter";
import { setMobileContentSidebarStatus } from "app/store/actions/sidebars.actions";
import ApplicationHeader from "app/main/applications/components/application-header";
import ApplicationTabBar from "app/main/applications/components/application-tab-bar";

import { fetchApplication, setCurrentApplication, searchDrugs } from "app/main/applications/actions/applications.actions";
import { isMobileContentSidebarOpen } from "app/store/reducers/sidebars.reducer";
import { getApplicationStatusValues, getPatientSettingValues, getHelperTextBySectionName, isSystemConfigurationLoading, getUseExternalDispensingQuery } from "app/auth/store/reducers/system-configuration";
import ContentWithDivider from "app/main/applications/components/content-with-divider";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import TreatmentRequestForm from "app/main/applications/components/treatment-request-form";
import ApplicationWorklistPanels from "app/main/applicationWorklist/components/application-worklist-panels";
import { getUser, getSignedInOrgUnit, isDrugSearchDisabled } from "app/auth/store/reducers/user.reducer";
import { AccessDeniedState } from "components/items/empty-state";
import PatientApplicationContainer from "app/main/applications/components/patient-application-container";
import UrgentIcon from "app/main/applications/components/urgent-icon";
import InfoCard from "app/main/applicationWorklist/components/info-card";
import { IPAWorklistIcon } from "helpers/icon-finder";
import { getIPAWorklistTitle } from "utils/get-environment-variables";

import ApplicationHistoryPage from "./application-history-page";
import ApplicationDispensingPage from "./application-dispensing-page";

const decodeURI = uri => {
  if (!uri) return null;
  return decodeURIComponent(uri);
};

const worklistTitle = getIPAWorklistTitle();

class ApplicationWorklistPage extends Component {
  constructor(props) {
    super(props);
    this.onClickItem = this.onClickItem.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.presetURL = props.location.search || null;
    this.requiredSections = [];
    // clear current application on init
    if (this.props.forceLoad) {
      this.props.setCurrentApplication(null);
    }
    this.state = {
      currentTab: 0,
      isNewApplication: false,
      requiredSections: this.requiredSections,
      isDrugSearchLoading: false,
    };
  }

  componentDidMount() {
    const { hasPermissionIPATreatmentEdit } = this.props;

    if (this.presetURL) {
      const formatURL = replace(this.presetURL, "?", "");
      const transformURLtoObject = fromPairs(formatURL.split("&").map(s => s.split("=")));

      // selected application
      if (this.presetURL.indexOf("applicationId") !== -1) {
        const { applicationId } = transformURLtoObject;
        // make sure the application id is not null
        if (applicationId !== "null") {
          this.props.fetchApplication(applicationId, this.props.orgUnitId);
        } else {
          this.props.history.push({
            search: "",
          });
        }
      }

      const prepopulateFields = this.presetURL.indexOf("medication") !== -1 || this.presetURL.indexOf("drugId") !== -1;
      if (prepopulateFields && hasPermissionIPATreatmentEdit) {
        this.prepopulateFields(transformURLtoObject);
      }
    }
  }

  generatePrepopulatedData = async (transformURLtoObject, orgUnitId) => {
    const { medication, form, strength, drugId, formId } = transformURLtoObject;

    if (!this.props.drugSearchEnabled) {
      return {
        requestedMedication: { drug: startCase(decodeURI(medication)), form: decodeURI(form), strength: decodeURI(strength) },
      };
    }

    if (!drugId && !formId) {
      return null;
    }

    const drugResponse = await this.loadDrug(drugId, formId, orgUnitId);

    if (size(drugResponse) === 1) {
      return {
        requestedMedication: {
          drug: drugResponse[0].drugFullName,
          form: drugResponse[0].formName,
          strength: decodeURI(strength),
          drugResultJson: JSON.stringify(drugResponse[0]),
        },
      };
    }

    return { initialDrugOptions: drugResponse };
  }

  prepopulateFields = async transformURLtoObject => {
    this.onCreateNewApplication();

    const prepopulatedData = await this.generatePrepopulatedData(transformURLtoObject, this.props.orgUnitId);

    this.props.openDialog({
      maxWidth: "md",
      children: (
        <PatientApplicationContainer
          orgUnitId={this.props.orgUnitId}
          onSucceed={() => this.props.closeDialog()}
          renderContent={({ onCreateApplicationPatient }) => (
            <TreatmentRequestForm
              // instead of rewrite the class component to be functional component to use the hook
              // set forceLoad to true
              forceLoad
              onSucceed={() => {
                this.props.closeDialog();
                if (this.props.hasPermissionIPAPatientEdit) {
                  onCreateApplicationPatient();
                }
                this.setState({ isNewApplication: false });
                this.props.history.push(`/applications?applicationId=${this.props.currentApplication.id}`);
              }}
              onCancel={() => this.clearPresetDate()}
              submitLabel={this.props.hasPermissionIPAPatientEdit ? "Save and Continue" : "Save"}
              title="Add Treatment Details"
              {...prepopulatedData}
            />
          )}
        />
      ),
    });
  }

  loadDrug = async (drugId, formId, orgUnitId) => {
    this.setState({ isDrugSearchLoading: true });
    const response = await this.props.searchDrugs(orgUnitId, { drugId, formId });
    this.setState({ isDrugSearchLoading: false });

    if (!response.error) {
      return response.payload;
    }
    return null;
  };

  getApplicationStatus = value => {
    const { applicationStatus } = this.props;
    return find(applicationStatus, x => x.value === value);
  }

  updateApplicationStatus = status => this.setState({ isNewApplication: status })

  discardNewApplication = () => {
    this.setState({ isNewApplication: false });
    this.props.setCurrentApplication(null);
    // clear url
    this.props.history.push({
      search: "",
    });
  }

  clearPresetDate = () => {
    this.props.closeDialog();
    this.discardNewApplication();
  }

  onClickItem = selectedItem => {
    if (this.props.isMobileContentSidebarOpen) {
      this.props.setMobileContentSidebarStatus(false);
    }
    this.setState({ isNewApplication: false });
    this.props.fetchApplication(selectedItem.id, this.props.orgUnitId);
    this.props.history.push(`/applications?applicationId=${selectedItem.id}`);
    // If we navigate in dispensing, to an application that isn't approved, we should reset to the Overview tab
    if (this.state.currentTab === (this.props.hasPermissionIPAApplicationHistoryView ? 2 : 1) && selectedItem.status.label !== "Approved") {
      this.setState({ currentTab: 0 });
    }
  }

  // eslint-disable-next-line no-async-promise-executor
  onSearch = (searchParams, page, pageSize, forceLoad = false) => new Promise(async resolve => {
    const params = {
      ...searchParams,
      orgUnitId: this.props.orgUnitId,
    };
    setTimeout(() => {
      resolve(this.props.searchApplications(params, page || defaultPageNumber, pageSize || defaultPageSize, forceLoad));
    }, 500);
  });

  onCreateNewApplication = () => {
    if (this.props.isMobileContentSidebarOpen) {
      this.props.setMobileContentSidebarStatus(false);
    }
    this.props.setCurrentApplication({ status: "New", orgUnitId: this.props.orgUnitId, id: null, statusFlags: {} });
    this.props.history.push("/applications?new");
    this.setState({ isNewApplication: true, currentTab: 0 });
  };

  onSaveApplication = () => {
    if (this.state.isNewApplication) {
      this.setState({ isNewApplication: false });
      this.props.history.push(`/applications?applicationId=${this.props.currentApplication.id}`);
    }
  }

  scrollItemToView = id => {
    const selectedItem = document.getElementById(id);

    if (selectedItem) {
      selectedItem.scrollIntoView({ behavior: "smooth" });
    }
  }

  renderItem = (item, selectedItem) => {
    const status = this.getApplicationStatus(item.status);
    const { requestedMedication, patient } = item;
    const { hasPermissionPatientsView } = this.props;

    return (
      <div id={item.id}>
        <DefaultListItem
          color={status.color}
          id={item.id}
          onClick={() => this.onClickItem(item)}
          active={!isEmpty(selectedItem) && selectedItem.id === item.id}
          content={(
            <>
              <div className="flex items-center justify-between flex-wrap">
                {hasPermissionPatientsView
                  ? (
                    <Typography variant="body1">
                      {isEmpty(patient) ? "No Patient Recorded" : patient?.displayNameShort}
                    </Typography>
                  )
                  : <Typography variant="body1">IPA# {item.applicationNumber}</Typography>}
                <div className="flex-row-container">
                  <div className="flex items-center">
                    <UrgentIcon show={item.urgentApproval} />
                    <ItemStatus
                      color={status.color}
                      label={item.displayProvisionalApprovalLabel ? `${upperCase(status.label)} (Provisionally Approved)` : upperCase(status.label)}
                    />
                  </div>
                  <Typography variant="caption" color="textSecondary" className="px-4">on</Typography>
                  <Typography variant="caption" color="textSecondary">{formatDate(item.statusChangedDateTimeUtc)}</Typography>
                </div>
              </div>
              <div className="flex flex-col mt-8">
                <ContentWithDivider contents={[{ label: !isEmpty(requestedMedication) ? requestedMedication.drug : "No Medication Recorded", variant: "caption" }, { label: get(item, ["indication", "indicationTerm"], null), variant: "caption" }]} />
              </div>
            </>
          )}
        />
      </div>
    );
  };

  renderSidebarContent = selectedItem => {
    const { applications, searchParams, pageNumber, forceLoad, loading, error, total, applicationStatus } = this.props;
    const { isNewApplication } = this.state;
    const newStatus = find(applicationStatus, x => x.value === "New");

    return (
      <>
        {isNewApplication
          && (
            <DefaultListItem
              disabled
              content={(
                <div className="flex items-center justify-between">
                  <Typography>{worklistTitle?.single}</Typography>
                  <ItemStatus label={get(newStatus, "label")} />
                </div>
              )}
            />
          )}
        <InfiniteLoadingList
          initialLoad
          data={applications}
          renderItem={item => this.renderItem(item, selectedItem)}
          loading={loading}
          error={error}
          total={total}
          pageStart={pageNumber}
          loadFunction={page => {
            const number = page === undefined ? 1 : pageNumber + 1;
            return this.onSearch(searchParams, number, null, forceLoad);
          }}
        />
      </>
    );
  };

  renderHeader = selectedItem => {
    const { hasPermissionIPACompleteApplication, patientSetting, hasPermissionIPAApplicationHistoryView } = this.props;
    const applicationStatus = this.getApplicationStatus(selectedItem.status);
    const { currentTab } = this.state;

    const tabs = [
      { label: "Overview", disabled: false },
      ...this.props.currentApplication.id && hasPermissionIPAApplicationHistoryView ? [{ label: "History", disabled: false }] : [],
      ...this.props.currentApplication.id && this.props.currentApplication.status === "Approved" && this.props.enableExternalDispensing ? [{ label: "Dispensing", disabled: false }] : [],
    ];

    return (
      <div className="flex flex-col flex-1">
        <ApplicationHeader
          application={selectedItem}
          applicationStatusColor={get(applicationStatus, "color")}
          applicationStatus={upperCase(get(applicationStatus, "label"))}
          patientSetting={patientSetting}
        />
        <ApplicationTabBar
          application={selectedItem}
          hasPermissionIPACompleteApplication={hasPermissionIPACompleteApplication}
          discardNewApplication={this.discardNewApplication}
          updateApplicationStatus={this.updateApplicationStatus}
          tabs={tabs}
          onChange={(_, newTab) => this.setState({ currentTab: newTab })}
          currentTab={currentTab}
          orgUnitId={this.props.orgUnitId}
          enableExternalDispensing={this.props.enableExternalDispensing}
        />
      </div>
    );
  };

  renderContent = selectedItem => {
    const { currentTab, isDrugSearchLoading } = this.state;
    const { loadingApplication, applicationLoadingError, patientSetting, helperText, hasPermissionIPAApplicationHistoryView } = this.props;
    const { statusFlags } = selectedItem;

    if (loadingApplication || isDrugSearchLoading) return (<LoadingState />);

    if (applicationLoadingError) {
      return <div>{applicationLoadingError}</div>;
    }

    if (selectedItem.status === "New" || statusFlags.canView) {
      return (
        <>
          {currentTab === 0
            && (
              <>
                {selectedItem.status === "Draft" && helperText.draft && (
                  <div className="mt-16 mx-16 flex justify-center">
                    <InfoCard>{helperText.draft}</InfoCard>
                  </div>
                )}
                <ApplicationWorklistPanels
                  application={selectedItem}
                  onSaveApplication={this.onSaveApplication}
                  openDialog={this.props.openDialog}
                  closeDialog={this.props.closeDialog}
                  patientSetting={patientSetting}
                  scrollItemToView={this.scrollItemToView}
                />
              </>
            )}
          {currentTab === 1 && hasPermissionIPAApplicationHistoryView && <ApplicationHistoryPage application={this.props.currentApplication} orgUnitId={this.props.orgUnitId} />}
          {currentTab === (hasPermissionIPAApplicationHistoryView ? 2 : 1) && this.props.enableExternalDispensing && <ApplicationDispensingPage application={this.props.currentApplication} orgUnitId={this.props.orgUnitId} />}
        </>
      );
    }

    return <AccessDeniedState />;
  }

  render() {
    const {
      initialValues,
      searchParams,
      total,
      currentApplication: selectedItem,
      applicationStatusChoices,
      hasPermissionIPAAddApplication,
      loadingApplication,
    } = this.props;
    const { isNewApplication } = this.state;

    return (
      <ChatPanel
        loading={loadingApplication}
        empty={isEmpty(selectedItem)}
        renderHeader={() => this.renderHeader(selectedItem)}
        renderContent={() => this.renderContent(selectedItem)}
        renderSidebarContent={() => this.renderSidebarContent(selectedItem)}
        renderSidebarHeader={() => (
          <ApplicationAdvancedFilter
            total={total}
            onSearch={this.onSearch}
            initialValues={initialValues}
            searchParams={searchParams}
            orgUnitId={this.props.orgUnitId}
            applicationStatusChoices={applicationStatusChoices}
            renderFilterInfo={(
              <div className="flex justify-between items-center">
                <Typography variant="caption">Total Results: {total}</Typography>
                {hasPermissionIPAAddApplication
                  && <DefaultButton label="New Application" disabled={isNewApplication} onClick={() => this.onCreateNewApplication()} />}
              </div>
            )}
          />
        )}
        emptyIcon={<IPAWorklistIcon />}
        emptyTitle="Select an application to view details"
      />
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const currentApplication = getCurrentApplication(state);
  const patientSettingValues = getPatientSettingValues(state);
  let setting = get(currentApplication, "setting", false);
  // find the display label from patient setting
  if (setting) {
    const patientSetting = find(patientSettingValues, x => x.value === setting);
    setting = patientSetting?.label;
  }

  const searchParams = getSearchParameters(state);
  const applicationStatus = getApplicationStatusValues(state);
  const applicationStatusChoices = filter(applicationStatus, x => x.value !== "New" && (ownProps.hasPermissionIPAApplicationInDraftView || x.value !== "Draft"));
  const signedInOrgUnit = getSignedInOrgUnit(state);

  return ({
    applications: getApplicationsForWorklist(state),
    loading: isApplicationWorklistLoading(state),
    errorMessage: getApplicationWorklistLoadingErrors(state),
    total: getTotalNumberOfApplications(state),
    pageNumber: getCurrentPage(state),
    searchParams,
    initialValues: {
      applicationFilters: {
        ...searchParams,
        requestedDateRange: [searchParams.requestedStartDate, searchParams.requestedEndDate],
        meetingDateRange: [searchParams.meetingStartDate, searchParams.meetingEndDate],
        reportDateRange: [searchParams.reportStartDate, searchParams.reportEndDate],
        reportReceivedDateRange: [searchParams.reportReceivedStartDate, searchParams.reportReceivedEndDate],
        prescriptionDateRange: [searchParams.prescriptionStartDate, searchParams.prescriptionEndDate],
      },
    },
    loadingApplication: isCurrentApplicationLoading(state) || isSystemConfigurationLoading(state),
    applicationLoadingError: isCurrentApplicationLoadingError(state),
    isMobileContentSidebarOpen: isMobileContentSidebarOpen(state),
    currentApplication,
    applicationStatusChoices,
    currentUser: getUser(state),
    patientSetting: setting,
    applicationStatus,
    helperText: getHelperTextBySectionName(state, "application"),
    orgUnitId: signedInOrgUnit.id,
    drugSearchEnabled: !isDrugSearchDisabled(state),
    enableExternalDispensing: getUseExternalDispensingQuery(state),
  });
};

export default withPermissions(
  "IPACompleteApplication",
  "IPAAddApplication",
  "IPATreatmentEdit",
  "IPAPatientEdit",
  "IPACanViewOtherUserApplications",
  "IPACanEndorseAssignedApplications",
  "IPACanEndorseOtherApplications",
  "PatientsView",
  "IPAApplicationInDraftView",
  "IPAApplicationHistoryView",
)(connect(
  mapStateToProps,
  {
    searchDrugs,
    searchApplications,
    setMobileContentSidebarStatus,
    fetchApplication,
    setCurrentApplication,
    openDialog,
    closeDialog,
  },
)(withRouter(ApplicationWorklistPage)));
