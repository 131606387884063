import { get } from "lodash";
import curryConnector from "utils/connectors";

export const PATIENTS_SEARCH_STATE_KEY = "patientsSearch";
export const PATIENTS_SEARCH_PAGE_SIZE = 25;

const curry = fn => curryConnector(fn, PATIENTS_SEARCH_STATE_KEY);

const INITIAL_STATE = {
  pages: {},
  loading: false,
  loaded: false,
  error: null,
  pageInfo: {
    pageNumber: 1,
    pageSize: PATIENTS_SEARCH_PAGE_SIZE,
    totalRecords: 0,
  },
  searchParams: {
    givenName: "",
    familyName: "",
    middleName: "",
    dateOfBirth: null,
    sex: null,
    patientIdentifier: null,
    medicareNumber: null,
    dvaNumber: null,
  },
};

export const ActionTypes = {
  LOADING_PATIENTS_SEARCH: "LOADING_PATIENTS_SEARCH",
  ERROR_LOADING_PATIENTS_SEARCH: "ERROR_LOADING_PATIENTS_SEARCH",
  LOADED_PATIENTS_SEARCH: "LOADED_PATIENTS_SEARCH",
  RESET_PATIENTS_SEARCH: "RESET_PATIENTS_SEARCH",
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_PATIENTS_SEARCH:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.pageNumber]: { loading: true, loaded: false, error: false },
        },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.ERROR_LOADING_PATIENTS_SEARCH:
      return {
        ...state,
        pages: {
          ...state.pages,
          [action.payload.pageNumber]: { loading: false, error: action.payload.message, loaded: false },
        },
      };
    case ActionTypes.LOADED_PATIENTS_SEARCH:
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        pages: {
          ...state.pages,
          [action.payload.pageNumber]: {
            loading: false,
            error: false,
            loaded: true,
            patients: action.payload.patients,
          },
        },
        searchParams: action.payload.searchParams,
        pageInfo: action.payload.pageInfo,
      };
    case ActionTypes.RESET_PATIENTS_SEARCH:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const getPatientSearchResults = curry(({ localState }) => {
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", pageNumber, "patients"], []);
});

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});

export const getPageError = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "error"], null);
});

export const getPageInfo = curry(({ localState }) => localState.pageInfo);

export const getSearchParams = curry(({ localState }) => localState.searchParams);
