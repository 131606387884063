import React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
import withPermissions from "permissions/withPermissions";
import { isEmpty } from "lodash";

import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { getEnableStreamlinedAddReferral } from "app/auth/store/reducers/system-configuration";
import ReferralAssignmentPatientDialog from "app/main/referrals/components/referral-assignment-patient-dialog";

import { getReferralById } from "app/main/referrals/reducers/referrals.reducers";
import { actionPanel } from "components/styles/base";
import ViewPatientRecordButton from "app/main/patients/components/view-patient-record-button";
import { getExistingPatientById } from "app/main/patients/actions/patients.actions";
import PatientDetails from "app/main/patients/components/patient-details";

import { cancelledWorkflowState } from "../helpers/workflow-state-names";
import ReferralWorkflowPanel from "./referral-workflow-panel";
import ReferralPatientDetails from "./referral-patient-details";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      justifyContent: "space-between",
      flexDirection: "row",
    },
  },
  content: {
    display: "flex",
    flex: "1 1 auto",
    padding: `${theme.spacing(2)}px`,
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
    },
  },
  action: {
    height: "100%",
    minWidth: actionPanel.width,
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: actionPanel.width,
      display: "flex",
    },
  },
}));

const ReferralHeader = ({
  referral,
  showMedicalRecordButton = true,
  hasPermissionReferralGlobalEdit,
  hasPermissionReferralPatientAssignment,
  orgUnitId,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const enableStreamlinedAddReferral = useSelector(getEnableStreamlinedAddReferral);
  const { patient, referralStatus, permissions, assignedTo, referralSourceDocument } = referral;

  const isCancelled = referralStatus === cancelledWorkflowState;
  // do not display the worklist panel if user does not have view and edit permission
  const showWorkflow = permissions.canView || hasPermissionReferralGlobalEdit;
  const referredToSpecialty = assignedTo ? { label: assignedTo?.assignedToSpecialtyRoleDisplay, value: assignedTo?.assignedToSpecialtyId } : null;

  const goToPatientMedicalRecordPage = () => dispatch(getExistingPatientById(patient.patientId, true)).then(() => {
    history.push({
      pathname: `/referralWorklist/patient/${patient.patientId}`,
      fromUrl: `/referralWorklist?referralId=${referral.id}`,
    });
  });

  const onClose = () => dispatch(closeDialog());

  const onAssignPatient = () => dispatch(openDialog({
    maxWidth: enableStreamlinedAddReferral ? "xl" : "md",
    children: (
      <ReferralAssignmentPatientDialog
        referralId={referral.id}
        enableProcessOCR={referral.isManualReferral}
        disabledRemove={!referral.isManualReferral}
        orgUnitId={orgUnitId}
        referredToSpecialty={referredToSpecialty}
        referralSourceDocument={referralSourceDocument}
        onSucceed={referralId => { onClose(); history.push(`/referralWorklist?referralId=${referralId}`); }}
        onClose={onClose}
        title="Assign Patient"
      />
    ),
  }, enableStreamlinedAddReferral));

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <PatientDetails
          renderContent={(
            <ReferralPatientDetails
              goToPatientRecordPage={(showMedicalRecordButton && !isEmpty(patient)) ? () => goToPatientMedicalRecordPage() : null}
              patient={patient}
              identifierProps={{ disabled: isCancelled }}
              referralId={referral.id}
              orgUnitId={orgUnitId}
            />
          )}
          renderRightContent={
            !isEmpty(patient)
              ? (showMedicalRecordButton && <ViewPatientRecordButton onClick={goToPatientMedicalRecordPage} />)
              : (hasPermissionReferralPatientAssignment && <DefaultButton variant="outlined" label="Assign Patient" onClick={onAssignPatient} />)
          }
        />
      </div>
      {showWorkflow && (
        <div className={classes.action}>
          <ReferralWorkflowPanel referral={referral} orgUnitId={orgUnitId} />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  referral: ownProps.referral || getReferralById(state, ownProps.referralId),
});

export default withPermissions(
  "ReferralGlobalEdit",
  "ReferralPatientAssignment",
)(connect(
  mapStateToProps,
  {},
)(ReferralHeader));
