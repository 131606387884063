import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "formik";

import { NoteInput } from "components/inputs/text-input";
import { rejectReferralAction } from "app/main/referrals/actions/referrals.actions";
import Form from "components/form";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";
import ReferralWarningMessage from "app/main/referrals/components/referral-warning-message-alert";

import { getEnableReadOnly } from "app/auth/store/reducers/system-configuration";
import { PriorityIcon } from "helpers/icon-finder";

const RejectReferralForm = ({
  referralId,
  orgUnitId,
  onSucceed,
  schema,
  warningMessages,
  fieldSettings,
  fieldLabels,
  ...other
}) => {
  const dispatch = useDispatch();
  const enableReadOnly = useSelector(getEnableReadOnly);

  const handleSubmit = ({ referral }, { setSubmitting, setErrors }) => {
    const rejectReferral = {
      referralId,
      rejectReasonCodeId: referral.rejectReasonCodeId.value,
      reason: referral.reason,
    };

    dispatch(rejectReferralAction(orgUnitId, rejectReferral)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const initialValues = schema.cast();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      contentProps={other}
      variant="filled"
      content={() => (
        <>
          <ReferralWarningMessage warningMessages={warningMessages} />
          {fieldSettings?.referrerAdvisedPriority && (
            <Field
              name="referral.referrerAdvisedPriorityCodeId"
              component={CodeSetValueSelector}
              codeSetTypeCode={fieldSettings.referrerAdvisedPriority.fieldName}
              label={fieldLabels.referrerAdvisedPriority}
              required={fieldSettings.referrerAdvisedPriority.required}
              disabled={enableReadOnly && fieldSettings.referrerAdvisedPriority.enabledReadOnly}
              icon={<PriorityIcon />}
              allowHideOnForms
            />
          )}
          {fieldSettings?.rejectReasonCodeId && (
            <Field
              name="referral.rejectReasonCodeId"
              component={CodeSetValueSelector}
              label={fieldLabels.rejectReasonCodeId}
              required={fieldSettings.rejectReasonCodeId.required}
              disabled={enableReadOnly && fieldSettings.rejectReasonCodeId.enabledReadOnly}
              icon="clear"
              codeSetTypeCode="ReasonForRejection"
              allowHideOnForms
            />
          )}
          {fieldSettings?.reason && (
            <Field
              name="referral.reason"
              component={NoteInput}
              label={fieldLabels.reason}
              required={fieldSettings.reason.required}
              disabled={enableReadOnly && fieldSettings.reason.enabledReadOnly}
              icon="message"
              maxLength={2000}
            />
          )}
        </>
      )}
    />
  );
};

export default RejectReferralForm;
