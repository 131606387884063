import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Menu, MenuItem, Typography, Badge, Tooltip } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import CloudDownloadOutlineIcon from "mdi-react/CloudDownloadOutlineIcon";
import CloudDownloadIcon from "mdi-react/CloudDownloadIcon";
import DeleteIcon from "mdi-react/DeleteIcon";
import { getSignedInOrgUnit, getUser } from "app/auth/store/reducers/user.reducer";
import { getExportJobs, showExportCompleteIndicator, getExportCompleteJobId } from "../reducers/reportExport.reducers";
import { getExportJobList, downloadExportedReport, removeExportCompleteIndicator, clearExportedReport, clearExportCompleteJob } from "../actions/reportExport.actions";
import JobStatusType, { isJobStatusComplete, isJobStatusFailedOrCompleteOrInProgress } from "../constants/jobStatusTypes";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0.5, 1),
    "&:hover": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  menuItemContent: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(2),
    overflow: "hidden",
  },
  fileName: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  statusLabel: {
    color: theme.palette.text.secondary,
  },
  actionButtons: {
    flexShrink: 0,
  },
  deleteButton: {
    marginLeft: theme.spacing(1),
  },
}));

const TabBadge = withStyles(theme => ({
  badge: {
    transform: "none",
    position: "relative",
    marginLeft: theme.spacing(0.5),
  },
}))(Badge);

const BUTTON_ID = "export-dropdown-button";

const ReportExportDropDown = () => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);
  const currentUser = useSelector(getUser);
  const exportJobs = useSelector(getExportJobs);
  const exportCompleteJobId = useSelector(getExportCompleteJobId);
  const showIndicator = useSelector(showExportCompleteIndicator);
  const orgUnitId = signedInOrgUnit?.id;

  const hasExportJobs = useMemo(() => exportJobs.length > 0, [exportJobs]);

  const handleClick = useCallback(async target => {
    dispatch(getExportJobList(orgUnitId, currentUser.userId)).then(response => {
      if (response?.payload?.exportJobs.length > 0) {
        setAnchorEl(target);
      }
    });

    dispatch(removeExportCompleteIndicator());
  }, [dispatch, orgUnitId, currentUser.userId]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDownload = useCallback(exportId => {
    downloadExportedReport(orgUnitId, exportId);
  }, [orgUnitId]);

  const handleClear = useCallback(exportId => {
    dispatch(clearExportedReport(orgUnitId, exportId));
  }, [dispatch, orgUnitId]);

  useEffect(() => {
    if (exportCompleteJobId) {
      const button = document.getElementById(BUTTON_ID);
      handleClick(button);

      dispatch(clearExportCompleteJob());
      handleDownload(exportCompleteJobId);
    }
  }, [dispatch, exportCompleteJobId, handleDownload, handleClick]);

  return (
    <div className={classes.root}>
      <Tooltip title="View Exported Reports">
        <IconButton
          id={BUTTON_ID}
          color="inherit"
          onClick={event => handleClick(event.currentTarget)}
        >
          <CloudDownloadOutlineIcon />
          {showIndicator && <TabBadge variant="dot" color="secondary" />}
        </IconButton>
      </Tooltip>
      {hasExportJobs && (
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          // set the bottom-right corner of the IconButton for the popup menu to attach to
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          // set the popup menu to use its top-right corner to attach to the IconButton
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          // ensures the popup menu doesn't attach to the children element (CloudDownloadOutlineIcon) of the IconButton
          getContentAnchorEl={null}
        >
          {exportJobs.map(exportJob => (
            <MenuItem
              key={exportJob.id}
              className={classes.menuItem}
              disableRipple
            >
              <div className={classes.menuItemContent}>
                <Typography
                  variant="body2"
                  className={classes.fileName}
                >
                  {exportJob.exportFileName}
                </Typography>
                <Typography
                  variant="caption"
                  className={classes.statusLabel}
                >
                  {JobStatusType[exportJob.jobStatus.toUpperCase()]}
                </Typography>
              </div>
              <div className={classes.actionButtons}>
                <IconButton
                  size="small"
                  disabled={!isJobStatusComplete(exportJob.jobStatus)}
                  onClick={() => handleDownload(exportJob.id)}
                >
                  <CloudDownloadIcon />
                </IconButton>
                <IconButton
                  size="small"
                  disabled={!isJobStatusFailedOrCompleteOrInProgress(exportJob.jobStatus)}
                  onClick={() => handleClear(exportJob.id)}
                  className={classes.deleteButton}
                >
                  <DeleteIcon />
                </IconButton>
              </div>
            </MenuItem>
          ))}
        </Menu>
      )}
    </div>
  );
};

export default ReportExportDropDown;
