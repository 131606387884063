import React from "react";
import IconButton from "components/items/icon-button";
import MessageBulletedIcon from "mdi-react/MessageBulletedIcon";
import MessageBulletedOffIcon from "mdi-react/MessageBulletedOffIcon";

export default function SMSConsentLabel(props) {
  const { smsConsent } = props;

  return (
    smsConsent ? <IconButton title="Consented to receive SMS communication"><MessageBulletedIcon size={16} /></IconButton> : <IconButton title="Not consented to receive SMS communication"><MessageBulletedOffIcon size={16} /></IconButton>
  );
}

