import React, { useState } from "react";

import withPermissions from "permissions/withPermissions";
import DefaultPageLayout from "components/layouts/default-page-layout";
import useSetUrl from "app/main/admin/hooks/useSetUrl";
import OrgUnitPrimaryHeader from "app/main/orgUnits/components/orgUnit-primary-header";
import ScreenHeader from "components/items/screen-header";

import SpecialtyProfileForm from "../components/specialtyProfile-form";

const SpecialtyProfileNewPage = ({
  match: { params: { id, orgUnitType } },
  route,
  hasPermissionSpecialtyProfileCreate,
  history,
}) => {
  useSetUrl(route);
  const [disabled, setDisabled] = useState(false);

  return (
    <>
      <OrgUnitPrimaryHeader orgUnitId={id} />
      <DefaultPageLayout
        header={<ScreenHeader title="Add Specialty Profile" />}
        accessDenied={!hasPermissionSpecialtyProfileCreate}
        content={(
          <SpecialtyProfileForm
            submitLabel="Add"
            onCancel={() => setDisabled(true)}
            onSucceed={specialtyProfileId => history.push(`/admin/org-units/${orgUnitType}/${id}/specialtyProfiles/${specialtyProfileId}`)}
            orgUnitId={id}
            disabled={disabled}
          />
        )}
      />
    </>
  );
};

export default withPermissions("SpecialtyProfileCreate")(SpecialtyProfileNewPage);
