import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { getExistingPatientById } from "app/main/patients/actions/patients.actions";
import { isCurrentPatientLoading, getCurrentPatient } from "app/main/patients/reducers/patients.reducers";
import { HeaderAction } from "app/main/patients/components/patient-components";
import PatientFormContainer from "app/main/patients/components/patient-form/container";
import DefaultPanelLayout from "components/items/default-panel-layout";
import withPermissions from "permissions/withPermissions";

function PatientDetailsPage({ patientId, hasPermissionPatientsUpdate, hasPermissionPatientsMaintainReadonly }) {
  const dispatch = useDispatch();

  const [disabled, setDisabled] = useState(true);
  const loading = useSelector(isCurrentPatientLoading);
  const currentPatient = useSelector(getCurrentPatient);

  useEffect(() => {
    if (!loading && isEmpty(currentPatient)) {
      dispatch(getExistingPatientById(patientId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!hasPermissionPatientsMaintainReadonly && hasPermissionPatientsUpdate
        && <HeaderAction onClick={() => setDisabled(false)} label="Edit details" disabled={!disabled} icon="edit" />}
      <PatientFormContainer
        patient={currentPatient}
        isEdit
        onCancel={() => setDisabled(true)}
        onSucceed={() => setDisabled(true)}
        disabled={disabled}
        classes={{ content: "p-0" }}
        renderContent={({ personalSection, contactSection, residentialAddressSection, postalAddressSection, values }) => (
          <>
            <DefaultPanelLayout title="Personal Details">
              {personalSection({ values })}
            </DefaultPanelLayout>
            <DefaultPanelLayout title="Contact Details">
              {contactSection()}
            </DefaultPanelLayout>
            <DefaultPanelLayout title="Residential Address">
              {residentialAddressSection()}
            </DefaultPanelLayout>
            <DefaultPanelLayout title="Postal Address" isLast>
              {postalAddressSection()}
            </DefaultPanelLayout>
          </>
        )}
      />
    </>
  );
}

export default withPermissions("PatientsUpdate", "PatientsMaintainReadonly")(PatientDetailsPage);
