import React from "react";
import BaseInputField from "components/inputs/components/base-input-component";
import IconComponent from "components/items/icon-component";
import MarkdownDialog from "./mark-down-dialog";

const MarkdownPreview = ({ icon,
  iconComponent,
  markdownText,
  showBorder = true }) => (
    <BaseInputField>
      {(iconComponent || icon)
          && (
            <div className="flex mt-8 w-32">
              {iconComponent || <IconComponent icon={icon} size={24} />}
            </div>
          )}
      <MarkdownDialog
        markdownText={markdownText}
        showBorder={showBorder}
      />
    </BaseInputField>
);

export default MarkdownPreview;
