import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, concat } from "lodash";

import PatientContactForm from "app/main/patients/components/patient-contact-form";
import PopupLink from "components/items/popup-link";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { getCurrentPatientNextOfKin } from "app/main/patients/reducers/patients.reducers";
import withPermissions from "permissions/withPermissions";
import DefaultItem from "components/items/default-item";
import { AccountOutLineIcon, PhoneIcon } from "helpers/icon-finder";
import { setCurrentContact } from "app/main/patients/actions/contacts.actions";
import { UpdateCurrentReferralPatientContact } from "app/main/referrals/actions/referrals.actions";
import SMSRequestedStatus from "app/main/patients/contacts/components/sms-requested-status";

const NextOfKinPopupContent = ({
  givenName,
  familyName,
  contact,
  alternativeContact,
  smsRequested,
}) => (
  <div>
    <DefaultItem icon={<AccountOutLineIcon />} content={`${isEmpty(givenName) ? "" : concat(givenName, " ")}${familyName}`} />
    <DefaultItem className="mt-16" icon={<PhoneIcon />} content={contact} />
    {(!isEmpty(alternativeContact) && contact !== alternativeContact) && <DefaultItem className="mt-16" icon={<PhoneIcon />} content={alternativeContact} />}
    <SMSRequestedStatus smsRequested={smsRequested} />
  </div>
);

const EmptyPopupContent = () => (
  <div>
    <DefaultItem content="None Recorded" />
  </div>
);

const NextOfKin = ({
  patientId,
  hasPermissionPatientsNextOfKinUpdate,
  disabled,
  nok,
  nokFormReferral,
}) => {
  const dispatch = useDispatch();
  const nokFromCurrentPatient = useSelector(getCurrentPatientNextOfKin);
  const nextOfKin = nokFormReferral ? nok : nokFromCurrentPatient;
  const enableEdit = !disabled && !isEmpty(patientId) && hasPermissionPatientsNextOfKinUpdate;

  const showDialog = () => {
    if (!isEmpty(nextOfKin)) {
      dispatch(setCurrentContact(nextOfKin));
    } else {
      dispatch(setCurrentContact());
    }

    dispatch(openDialog({
      children: (
        <PatientContactForm
          onSucceed={newContact => {
            if (nokFormReferral) {
              dispatch(UpdateCurrentReferralPatientContact(patientId, newContact));
            }
            dispatch(closeDialog());
          }}
          title="Next of Kin"
          patientId={patientId}
          isNOK
        />
      ),
    }));
  };

  return (
    <PopupLink
      onClick={enableEdit ? () => showDialog() : null}
      label="NOK"
      content={!isEmpty(nextOfKin)
        ? <NextOfKinPopupContent givenName={nextOfKin.givenName} familyName={nextOfKin.familyName} contact={nextOfKin.contact} alternativeContact={nextOfKin.alternativeContact} smsRequested={nextOfKin.smsRequested} />
        : <EmptyPopupContent />}
    />
  );
};

export default withPermissions("PatientsNextOfKinUpdate")(NextOfKin);
