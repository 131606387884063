import React from "react";
import { useDispatch } from "react-redux";
// import { Field } from "formik";
// import { NoteInput } from "components/inputs/text-input";

import { setReferralNotUrgent, setReferralUrgent } from "app/main/referrals/actions/referrals.actions";
import Form from "components/form";
// import { validateRequired, validateMaxLength } from "utils/validators";
import ReferralWarningMessage from "./referral-warning-message-alert";

// const messageLabel = "Message";
// const validateMessage = value => validateRequired(messageLabel)(value) || validateMaxLength(messageLabel, 1000)(value);

const ReferralUrgentStatusForm = ({
  warningMessages,
  referralId,
  orgUnitId,
  onSucceed,
  setUrgent,
  referralActionTypeSettings,
  ...other
}) => {
  const dispatch = useDispatch();

  const handleSubmit = ({ urgentEditModel }, { setSubmitting, setErrors }) => {
    const apiCall = setUrgent ? setReferralUrgent : setReferralNotUrgent;

    const urgentModel = {
      ...urgentEditModel,
      referralId,
    };

    dispatch(apiCall(orgUnitId, referralId, urgentModel)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  return (
    <Form
      contentProps={other}
      initialValues={{ urgentEditModel: { message: null } }}
      onSubmit={handleSubmit}
      variant="filled"
      content={() => (
        <>
          <ReferralWarningMessage warningMessages={warningMessages} />
          {/* <Field
            name="urgentEditModel.message"
            component={NoteInput}
            label={messageLabel}
            icon="message"
            validate={validateMessage}
            autoFocus
          /> */}
        </>
      )}
    />
  );
};

export default ReferralUrgentStatusForm;
