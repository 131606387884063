import React from "react";
import { find, get } from "lodash";
import { useSelector } from "react-redux";
import PatientDetails, { NameLabel, PatientDOBLabel, TextLabel, PatientConsentStatus, UnmergeWarningLabel, PatientIdentifierLabel, AddressDetailsLabel, EmailLabel, ContactDetailsLabel, NextOfKin, AssignedUser, SMSConsentLabel, DeceasedLabel } from "app/main/patients/components/patient-details";
import withPermissions from "permissions/withPermissions";
import { getNationalityLabel, getSexLabel, enableSMSConsentLabelDisplay, getEnablePrimaryIdentifierBannerDisplay } from "app/auth/store/reducers/system-configuration";

const PatientHeader = ({
  patient,
  summary: { enrolments },
  patientNameOnClick,
  rightContent,
  hasPermissionEnrolmentView,
}) => {
  const nationalityLabel = useSelector(getNationalityLabel);
  const displayPrimaryIdentifierBanner = useSelector(getEnablePrimaryIdentifierBannerDisplay);
  const salveoEnrolment = find(enrolments, x => x.enrolmentType.value === "SalveoNursing");
  const displaySMSConsentLabel = useSelector(enableSMSConsentLabelDisplay);
  const sexLabel = useSelector(getSexLabel);
  const { unfinancial, removedAsCandidate, doNotContactAgain, ineligibleProduct, enrolmentStatus, reason } = salveoEnrolment || {};
  const { patientId, displayNameFull, birthDate, age, sex, patientIdentifiers, address, email, contactDetails, isDead, nationality, smsConsent, requiresUnmerge, patientCards } = patient;

  return (
    <PatientDetails
      renderContent={(
        <div className="flex flex-col items-container">
          <div className="flex-row-container with-gutter">
            <NameLabel name={displayNameFull} onClick={patientNameOnClick} />
            <PatientDOBLabel age={age} birthDate={birthDate} />
            <TextLabel label={sexLabel} className="font-bold" content={sex} />
            {displayPrimaryIdentifierBanner && <PatientIdentifierLabel patientId={patientId} showIcon={false} onlyPrimaryIdentifier patientIdentifiers={patientIdentifiers} patient={patient} patientCards={patientCards} />}
            <TextLabel label={nationalityLabel} className="font-bold" content={nationality} />
            <DeceasedLabel isDead={isDead} />
            <TextLabel content={unfinancial && "unfinancial"} />
            <TextLabel content={removedAsCandidate && "Not in Hambs"} />
            <TextLabel content={doNotContactAgain && "Do Not Contact Again"} />
            <TextLabel content={ineligibleProduct && "Ineligible Product"} />
            <PatientConsentStatus consent={get(enrolmentStatus, "value", null)} reason={reason} />
            <UnmergeWarningLabel requiresUnmerge={requiresUnmerge} />
          </div>
          <PatientIdentifierLabel patientId={patientId} patientIdentifiers={patientIdentifiers} patient={patient} />
        </div>
      )}
      renderExtraContent={(
        <div className="flex-row-container with-gutter">
          <AddressDetailsLabel address={address} />
          <EmailLabel email={email} />
          <ContactDetailsLabel contact={contactDetails} />
          {displaySMSConsentLabel && <SMSConsentLabel smsConsent={smsConsent} />}
          <NextOfKin patientId={patientId} />
          {hasPermissionEnrolmentView && <AssignedUser enrolments={enrolments} patientId={patientId} enrolmentStatus={enrolmentStatus} />}
        </div>
      )}
      renderRightContent={rightContent}
    />
  );
};

export default withPermissions("EnrolmentView")(PatientHeader);
