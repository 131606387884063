import React from "react";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import HelpRhombusOutlineIcon from "mdi-react/HelpRhombusOutlineIcon";
import DefaultPanelLayout from "components/items/default-panel-layout";
import DefaultItem from "components/items/default-item";
import { getSexLabel } from "app/auth/store/reducers/system-configuration";

const Item = ({ label, ...props }) => <DefaultItem labelClassName="w-256" label={<Typography className="font-bold" variant="subtitle2">«{label}»</Typography>} {...props} />;

const GPLetterTemplateHelper = () => {
  const sexLabel = useSelector(getSexLabel);

  return (
    <DefaultPanelLayout
      title="Letter Template Helper"
      icon={<HelpRhombusOutlineIcon />}
    >
      <div className="items-container">
        <Typography color="textSecondary">Letter Details</Typography>
        <Item label="CurrentDate" content="Display the current date" />
        <Item label="LoggedInUser" content="Display the logged in user" />
        <Item label="LoggedInUserSite" content="Display the name of the logged in user's current site" />
        <Item label="AdditionalMessage" content="Display additional message" />
      </div>

      <div className="items-container with-horizontal-divider">
        <Typography color="textSecondary">Referral Details</Typography>
        <Item label="ReferralDate" content="Display the referral date" />
        <Item label="Specialty" content="Display the assigned specialty of the referral" />
        <Item label="Site" content="Display the name of the site referral belongs to" />
        <Item label="ReasonForRejection" content="Display the reason for rejection" />
      </div>

      <div className="items-container with-horizontal-divider">
        <Typography color="textSecondary">Referrer Details</Typography>
        <Item label="ReferrerName" content="Display the referrer name" />
        <Item label="ReferrerPracticeName" content="Display the referrer practice name" />
        <Item label="TriageCategory" content="Display the priority of the triaged referral" />
      </div>

      <div className="items-container with-horizontal-divider">
        <Typography color="textSecondary">Referrer Postal Address</Typography>
        <Item label="PracticePostalAddressLine1" content="Display referrer practice postal address line 1" />
        <Item label="PracticePostalAddressLine2" content="Display referrer practice postal address line 2" />
        <Item label="PracticePostalCity" content="Display referrer practice postal city" />
        <Item label="PracticePostalState" content="Display referrer practice postal state" />
        <Item label="PracticePostalPostcode" content="Display referrer practice postal postcode" />
      </div>

      <div className="items-container with-horizontal-divider">
        <Typography color="textSecondary">Referrer Street Address</Typography>
        <Item label="PracticeStreetAddressLine1" content="Display referrer practice street address line 1" />
        <Item label="PracticeStreetAddressLine2" content="Display referrer practice street address line 2" />
        <Item label="PracticeStreetCity" content="Display referrer practice street city" />
        <Item label="PracticeStreetState" content="Display referrer practice street state" />
        <Item label="PracticeStreetPostcode" content="Display referrer practice street postcode" />
        <Item label="PracticeFaxNumber" content="Display the referral practice fax number" />
      </div>

      <div className="items-container with-horizontal-divider">
        <Typography color="textSecondary">Patient Details</Typography>
        <Item label="PatientTitle" content="Display the patient's title" />
        <Item label="PatientFirstName" content="Display the patient's first name" />
        <Item label="PatientLastName" content="Display the patient's last name" />
        <Item label="PatientDisplayName" content="Display the patient's full name" />

        <Item label="PatientDOB" content="Display the patient's date of birth" />
        <Item label="PatientSex" content={`Display the patient's ${sexLabel.toLowerCase()}`} />
      </div>

      <div className="items-container with-horizontal-divider">
        <Item label="PatientURNumber" content="Display the patient's UR number" />
        <Item label="PatientMedicareNumber" content="Display the patient's medicare number" />
      </div>

      <div className="items-container with-horizontal-divider">
        <Item label="PatientStreet" content="Display the patient's street" />
        <Item label="PatientSuburb" content="Display the patient's suburb" />
        <Item label="PatientCity" content="Display the patient's city" />
        <Item label="PatientPostcode" content="Display the patient's post code" />

        <Item label="PatientPostalStreet" content="Display the patient's postal address street" />
        <Item label="PatientPostalSuburb" content="Display the patient's postal address suburb" />
        <Item label="PatientPostalPostcode" content="Display the patient's postal address postcode" />
      </div>

      <div className="items-container with-horizontal-divider">
        <Item label="PatientHomePhoneNumber" content="Display the patient's home phone number" />
        <Item label="PatientMobileNumber" content="Display the patient's mobile number" />
        <Item label="PatientWorkNumber" content="Display the patient's work number" />
      </div>

      <div className="items-container with-horizontal-divider">
        <Item label="PatientUsualGPName" content="Display the patient's usual GP name" />
        <Item label="PatientUsualGPPracticeName" content="Display the patient's usual GP practice name" />
      </div>

    </DefaultPanelLayout>
  );
};

export default GPLetterTemplateHelper;
