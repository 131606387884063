import React from "react";
import { Field } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import PoundIcon from "mdi-react/PoundIcon";
import NoteTextOutlineIcon from "mdi-react/NoteTextOutlineIcon";
import SortIcon from "mdi-react/SortIcon";
import { JsonIcon } from "helpers/icon-finder";

import Form from "components/form";
import { TextInput, DateTimePicker, TextAreaInput } from "components/inputs";
import { isNaN } from "lodash";
import { addCodeSetValue, editCodeSetValue } from "app/main/codeSet/actions/codeSet.actions";
import { showMessage } from "app/store/actions/message.actions";

const codeLabel = "Code";
const descriptionLabel = "Description";
const activeDateLabel = "Active Date Time";
const DeprecateDateLabel = "Deprecate Date Time";
const sortOrderLabel = "Sort Order";
const propertiesJsonLabel = "Extended Properties (JSON)";

const validateJson = (value) => {
  try {
    JSON.parse(value);
    return true;
  } catch (e) {
    return false;
  }
};

const schema = Yup.object().shape({
  codeSetValue: Yup.object().shape({
    code: Yup.string()
      .trim().required(`${codeLabel} is required`)
      .max(80, "Too Long!")
      .nullable(),
    description: Yup.string()
      .trim().required(`${descriptionLabel} is required`)
      .max(255, "Too Long!")
      .nullable(),
    activeDateTimeUtc: Yup.date().default(null).nullable(),
    deprecatedDateUtc: Yup.date().default(null).nullable(),
    sortOrder: Yup.number().default(0).nullable()
      .transform(v => (isNaN(v) ? null : v)),
    propertiesJsonStr: Yup.string()
    .trim()
    .max(500, "Too Long!")
    .nullable()
    .test("is-json", "Invalid JSON format", value => !value || validateJson(value)),
  }),
});

const CodeSetValueForm = ({
  currentCodeSet,
  currentCodeSetValue,
  onSucceed,
  ...other
}) => {
  const dispatch = useDispatch();
  const handleSubmit = ({ codeSetValue }, { setSubmitting }) => {
    const apiCall = codeSetValue.id ? editCodeSetValue : addCodeSetValue;

    dispatch(apiCall(currentCodeSet, codeSetValue)).then(response => {
      setSubmitting(false);

      if (response.error === true) {
        dispatch(showMessage({ message: response.payload.message || "An error has occurred", variant: "error" }));
        return;
      }

      onSucceed();    
    });
  };

  let { codeSetValue } = schema.cast();

  if (currentCodeSetValue) {
    codeSetValue = currentCodeSetValue;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ codeSetValue }}
      validationSchema={schema}
      contentProps={other}
      content={() => (
        <>
          <Field
            name="codeSetValue.code"
            component={TextInput}
            label={codeLabel}
            icon={<PoundIcon />}
          />
          <Field
            name="codeSetValue.description"
            component={TextInput}
            label={descriptionLabel}
            icon={<NoteTextOutlineIcon />}
          />
          <Field
            name="codeSetValue.activeDateTimeUtc"
            component={DateTimePicker}
            label={activeDateLabel}
          />
          <Field
            name="codeSetValue.deprecatedDateUtc"
            component={DateTimePicker}
            label={DeprecateDateLabel}
          />
          <Field
            name="codeSetValue.sortOrder"
            component={TextInput}
            label={sortOrderLabel}
            type="number"
            icon={<SortIcon />}
          />
          <Field
            name="codeSetValue.propertiesJsonStr"
            component={TextAreaInput}
            label={propertiesJsonLabel}
            icon={<JsonIcon />}
            rows={10}
            maxLength={500}
          />
        </>
      )}
    />
  );
};

export default CodeSetValueForm;
