import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get, includes } from "lodash";
import clsx from "clsx";
import { Dialog, DialogTitle, DialogContent, DialogActions, AppBar, Toolbar, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import DownloadIcon from "mdi-react/DownloadOutlineIcon";
import PDFIcon from "mdi-react/FilePdfBoxIcon";

import DefaultButton from "components/items/default-button";
import LoadingState from "components/items/loading-state";
import downloadFromApi, { openPdf } from "utils/download-from-api";
import { fetchPreviewAttachment as fetchDocumentPreviewAttachment } from "app/main/referralDocumentAttachmentUploads/actions/referralDocumentAttachmentUploads.actions";
import { fetchPreviewAttachment } from "app/main/referrals/actions/referrals.actions";
import PreviewContent from "components/items/preview-content";
import { EmptyStateComponent } from "components/items/pdf-preview";
import { getAllowNotScannedFileView } from "app/auth/store/reducers/system-configuration";

const useStyles = makeStyles(() => ({
  imageStyle: {
    minHeight: "100%",
    backgroundSize: "contain",
  },
  content: {
    height: "80vh",
  },
}));

const pendingStatus = "Pending";
const cleanStatus = "Clean";
const virusDetectedStatus = "VirusDetected";
const notScanned = "NotScanned";

const ReferralDocumentAttachmentPreviewDialog = ({
  open,
  orgUnitId,
  file,
  onClose,
  isUpload,
  referralId = null,
  referralDocumentId = null,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const contentRef = useRef();

  const allowNotScannedFileView = useSelector(getAllowNotScannedFileView);
  // the attachment with their preview content
  const [attachment, setAttachment] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(open);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  const fetchPreview = (attachmentId, currentReferralId = null, documentId = null) => {
    console.log("orgUnitId, currentReferralId, documentId, attachmentId", orgUnitId, currentReferralId, documentId, attachmentId);
    setLoading(true);
    const apiResponse = isUpload ? dispatch(fetchDocumentPreviewAttachment(orgUnitId, attachmentId)) : dispatch(fetchPreviewAttachment(orgUnitId, currentReferralId, documentId, attachmentId));
    apiResponse.then(response => {
      if (response.error) {
        setError(true);
      } else {
        setError(false);
        setAttachment(response.payload);
      }
    })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  };

  // first load
  useEffect(() => {
    if (open) {
      fetchPreview(file.id, referralId, referralDocumentId);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, file.id, referralId, referralDocumentId]);

  const isPDF = includes(file?.mimeType, "pdf");
  const isPending = attachment?.fileScanStatus === pendingStatus;
  const isClean = attachment?.fileScanStatus === cleanStatus;
  const isVirusDetected = attachment?.fileScanStatus === virusDetectedStatus;
  const isNotScanned = attachment?.fileScanStatus === notScanned;
  const isEnableViewFile = isClean || (allowNotScannedFileView && isNotScanned);

  const downloadAttachment = fileId => downloadFromApi(`api/referrals/${orgUnitId}/${referralId}/download?fileId=${fileId}`);

  const downloadDocumentAttachmentUpload = referralDocumentAttachmentUploadId => downloadFromApi(`api/referralDocumentAttachmentUpload/${orgUnitId}/${referralDocumentAttachmentUploadId}/download`);

  const content = () => {
    if (loading) {
      return <LoadingState />;
    }

    if (error) {
      return <EmptyStateComponent title="An error occurred while loading the preview." />;
    }

    if (isVirusDetected) {
      return <EmptyStateComponent title="Potential malicious content detected. File not available for download." />;
    }

    if (isPending) {
      return <EmptyStateComponent title="Malware scanning pending. Please check back again later." />;
    }

    if (isEnableViewFile) {
      return (
        <PreviewContent
          fileData={attachment?.base64File || attachment?.htmlPreview}
          mimeType={file?.mimeType}
          pdfProps={{ width: get(contentRef, ["current", "clientWidth"], 0) }}
          imageProps={{ classes: { root: classes.imageStyle } }}
          hasPreview={attachment.hasPreview}
        />
      );
    }

    return <EmptyStateComponent />;
  };

  return (
    <Dialog open={isOpen} maxWidth="md" fullWidth>
      <DialogTitle id="form-dialog-title" className="p-0">
        <AppBar position="static" elevation={1} style={{ borderLeft: "none", boxShadow: "none" }}>
          <Toolbar variant="dense" className="flex overflow-x-auto px-8 sm:px-16">
            <Typography variant="subtitle1" color="inherit" className="flex-1">
              {file?.fileName}
            </Typography>
          </Toolbar>
        </AppBar>
      </DialogTitle>
      <DialogContent ref={contentRef} dividers className={clsx(classes.content, !file?.fileName && "py-24 overflow-x-hidden dialog-content")}>
        <div className="w-full h-full">{content()}</div>
      </DialogContent>
      <DialogActions className="flex items-center justify-between flex-1">
        {isEnableViewFile
          ? (
            <div className="flex">
              {isPDF && (
              <DefaultButton
                className="mr-8"
                color="secondary"
                onClick={() => {
                  if (isUpload) {
                    openPdf(`api/referralDocumentAttachmentUpload/${orgUnitId}/${file?.id}/download`);
                  } else {
                    openPdf(`api/referrals/${orgUnitId}/${referralId}/download?fileId=${file.id}`);
                  }
                }}
                label="Open in browser"
                size="medium"
                icon={<PDFIcon />}
              />
              )}
              <DefaultButton
                onClick={() => (isUpload ? downloadDocumentAttachmentUpload(file?.id) : downloadAttachment(file?.id))}
                label="Download"
                size="medium"
                icon={<DownloadIcon />}
              />
            </div>
          ) : <div />}
        <DefaultButton
          color="default"
          onClick={() => {
            setIsOpen(false);
            if (onClose) {
              onClose();
            }
          }}
          label="Close"
          size="medium"
          variant="text"
        />
      </DialogActions>
    </Dialog>
  );
};

export default ReferralDocumentAttachmentPreviewDialog;
