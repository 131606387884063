import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Chip from "@material-ui/core/Chip";
import AccountEdit from "mdi-react/AccountEditIcon";
import { concat, map, startCase, filter, includes, size, find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete } from "components/inputs";
import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { fetchOrgUnitsForSelector } from "app/main/orgUnits/actions/orgUnit.actions";
import { getEnableMultipleUserRoles } from "app/auth/store/reducers/system-configuration";
import { getOrgUnitsForOrgUnitsSelector, isOrgUnitsSelectorLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";
import UserRoleForm from "./user-role-form";

const useStyles = makeStyles(() => ({
  tag: {
    "& svg": {
      pointerEvents: "initial",
    },
  },
}));

const UserRoleSelector = ({
  isDisabled,
  orgUnit,
  ...other
}) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeDialog());
  const { field: { name, value }, form: { setFieldValue } } = other;
  const isOrgUnitLoading = useSelector(isOrgUnitsSelectorLoading);
  const orgUnits = useSelector(getOrgUnitsForOrgUnitsSelector);
  const enableMultipleUserRoles = useSelector(getEnableMultipleUserRoles);
  const selectedOrgUnitIds = map(value, x => x.orgUnitId);
  const orgUnitOptions = enableMultipleUserRoles ? orgUnits : filter(orgUnits, x => !includes(selectedOrgUnitIds, x.id));
  const currentOrgUnitId = orgUnit?.id;

  const updateUserRole = (userRole, currentIndex) => {
    if (currentIndex === -1) {
      setFieldValue(name, concat(value ?? [], userRole));
    } else {
      setFieldValue(name, map(value, (x, index) => (index === currentIndex ? userRole : x)));
    }
    onClose();
  };

  const openUserRoleForm = (currentUserRole, index = -1) => {
    dispatch(openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <UserRoleForm
          orgUnits={currentUserRole ? concat(orgUnitOptions, find(orgUnits, x => x.id === currentUserRole.orgUnitId)) : orgUnitOptions}
          userRoles={value}
          currentUserRole={currentUserRole}
          title="Add Role"
          onSubmit={({ userRole }) => updateUserRole(userRole, index)}
          submitLabel={currentUserRole ? "Update" : "Add"}
        />
      ),
    }));
  };

  const onAddUserRole = () => {
    openUserRoleForm();
  };

  const onUserRoleDelete = option => {
    setFieldValue(name, filter(value, x => x !== option));
  };

  const onUserRoleEdit = (currentUserRole, index) => {
    openUserRoleForm(currentUserRole, index);
  };

  const classes = useStyles();

  React.useEffect(() => {
    dispatch(fetchOrgUnitsForSelector({ orgUnitTypes: ["Site", "Org"], currentOrgUnitId }));
  }, [dispatch, currentOrgUnitId]);

  return (
    <div className="flex sm:flex-row sm:items-center flex-col">
      <AutoComplete
        loading={isOrgUnitLoading}
        className={clsx("flex-1", classes.tag)}
        options={map(value, x => ({ label: `${x.roleDescription ?? startCase(x.roleName)} (${x.orgUnitName})`, value: x }))}
        getOptionSelected={(option, val) => option.value === val.value}
        renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            icon={<AccountEdit size={20} />}
            label={option?.label}
            onClick={() => onUserRoleEdit(option.value, index)}
            onDelete={() => onUserRoleDelete(option.value)}
            size="small"
          />
        ))}
        multiple
        forcePopupIcon={false}
        disableClearable
        open={false}
        multiline
        disabled={isDisabled}
        {...other}
      />
      {!isDisabled && size(orgUnitOptions) > 0 && (
        <div className="sm:pl-8 pl-32 flex">
          <DefaultButton
            onClick={onAddUserRole}
            label="Add"
            edge="end"
          />
        </div>
      )}
    </div>
  );
};

export default UserRoleSelector;
