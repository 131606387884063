import SpecialtyProfileNewPage from "app/main/specialtyProfiles/pages/specialtyProfile-new-page";
import SpecialtyProfileEditPage from "app/main/specialtyProfiles/pages/specialtyProfile-edit-page";

import ReferralFormProfileNewPage from "app/main/referralFormProfiles/pages/referralFormProfile-new-page";
import ReferralFormProfileEditPage from "app/main/referralFormProfiles/pages/referralFormProfile-edit-page";

import NotificationConfigurationEditPage from "./pages/notificationConfiguration-edit-page";
import OrgUnitNewPage from "./pages/orgUnit-new-page";
import OrgUnitOverViewPage from "./pages/orgUnit-overview-page";

const OrgUnitConfig = {
  routes: [
    {
      path: "/admin/org-units/:orgUnitType/new",
      component: OrgUnitNewPage,
      exact: true,
      backUrl: "/admin",
      search: "?tab=orgUnits",
      state: { forceLoad: true },
    },
    {
      path: "/admin/org-units/:orgUnitType/:id",
      component: OrgUnitOverViewPage,
      exact: true,
      backUrl: "/admin",
      search: "?tab=orgUnits",
    },
    {
      path: "/admin/org-units/:orgUnitType/:id/specialtyProfiles/new",
      component: SpecialtyProfileNewPage,
      exact: true,
      strict: true,
      backUrl: "/admin/org-units/:orgUnitType/:id",
      search: "?tab=specialtyProfiles",
      state: { forceLoad: true },
    },
    {
      path: "/admin/org-units/:orgUnitType/:id/specialtyProfiles/:specialtyProfileId",
      component: SpecialtyProfileEditPage,
      exact: true,
      strict: true,
      backUrl: "/admin/org-units/:orgUnitType/:id",
      search: "?tab=specialtyProfiles",
    },
    {
      path: "/admin/org-units/:orgUnitType/:id/referralFormProfiles/new",
      component: ReferralFormProfileNewPage,
      exact: true,
      strict: true,
      backUrl: "/admin/org-units/:orgUnitType/:id",
      search: "?tab=referralFormProfiles",
      state: { forceLoad: true },
    },
    {
      path: "/admin/org-units/:orgUnitType/:id/referralFormProfiles/:referralFormProfileId",
      component: ReferralFormProfileEditPage,
      exact: true,
      strict: true,
      backUrl: "/admin/org-units/:orgUnitType/:id",
      search: "?tab=referralFormProfiles",
    },
    {
      path: "/admin/org-units/:orgUnitType/:id/notificationConfigurations/:eventType",
      component: NotificationConfigurationEditPage,
      exact: true,
      strict: true,
      backUrl: "/admin/org-units/:orgUnitType/:id",
      search: "?tab=notificationConfigurations",
    },
  ],
};

export default OrgUnitConfig;
