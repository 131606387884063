import React from "react";
import { filter, find, first, groupBy, isEmpty, join, map, orderBy, some, values } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Hidden, Icon, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import Highlighter from "react-highlight-words";

import { closeDialog, openDialog } from "app/store/actions/dialog.actions";
import PatientIdentifierForm from "app/main/patients/components/patient-identifier-form";
import getPatientIdentifierLabel from "app/main/patients/helpers/get-patient-identifier-label";
import withPermissions from "permissions/withPermissions";
import { getPatientCardSourceSystemIdentifiers,
  getPrimaryPatientIdentifierSettings } from "app/auth/store/reducers/system-configuration";
import DefaultItem from "components/items/default-item";

const useStyles = makeStyles(() => ({
  deprecatedIdentifier: {
    fontStyle: "italic",
  },

}));

const IdentifierItem = ({ showIcon, children }) => (
  <div className="flex-row-container">
    {showIcon && <Hidden smDown><div className="mr-4 flex"><Icon fontSize="small">account_box</Icon></div></Hidden>}
    <div className="flex-row-container with-gutter enable-shrink">
      {children}
    </div>
  </div>
);

const PatientIdentifierLabel = ({
  patientId,
  patientIdentifiers,
  hasPermissionPatientsIdentifiersUpdate,
  hasPermissionPatientsIdentifiersCreate,
  showIcon = true,
  onlyPrimaryIdentifier,
  textVariant = "body1",
  highlightTerm = [],
  disabled,
  includeDeprecatedIdentifiers,
  showAllIdentifiers = false,
  patient,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const settings = useSelector(getPrimaryPatientIdentifierSettings);
  const sourceSystemPriorityOrder = useSelector(getPatientCardSourceSystemIdentifiers);
  const codes = onlyPrimaryIdentifier ? [settings?.type] : settings?.displayPatientIdentifierTypes;
  const primaryPatientIdentifier = settings?.type;
  const identifierOptions = settings?.options;
  const includeExpiredIdentifiers = false;
  if (isEmpty(codes)) return null;

  const editPatientIdentifier = identifier => (
    dispatch(openDialog({
      maxWidth: "sm",
      children: (
        <PatientIdentifierForm
          patient={patient}
          patientIdentifier={identifier}
          patientIdentifiers={patientIdentifiers}
          patientId={patientId}
          onCancel={() => dispatch(closeDialog())}
          onSucceed={() => dispatch(closeDialog())}
          disabledPatientIdentifierTypeCode
        />
      ),
    }))
  );

  /**
   * Filters an array of patient identifiers based on their deprecated and expired status.
   *
   * @param {Array} identifiers - An array of patient identifier objects.
   * @param {boolean} includeDeprecated - If true, include deprecated identifiers.
   * @param {boolean} includeExpired - If true, include expired identifiers.
   * @returns {Array} - Filtered array of patient identifiers.
   */
  const filterPatientIdentifiers = (identifiers, includeDeprecated, includeExpired) => {
    // Avoid filtering if identifiers is null or undefined to prevent crashes;
    // improve the robustness of this function
    if (!identifiers) {
      return [];
    }

    return identifiers.filter(identifier => {
      // If includeDeprecated is true, keep the identifier regardless of its deprecated status
      // If includeDeprecated is false, only keep non-deprecated identifiers
      const keepDeprecated = includeDeprecated ? true : !identifier.isDeprecated;

      // If includeExpired is true, keep the identifier regardless of its expired status
      // If includeExpired is false, only keep non-expired identifiers
      const keepExpired = includeExpired ? true : !identifier.isExpired;

      // Return true only if both conditions are met
      return keepDeprecated && keepExpired;
    });
  };

  const orderPatientIdentifiers = (identifiers, priorityOrder) => {
    // Avoid ordering if identifiers is null or undefined to prevent crashes;
    if (!identifiers) {
      return [];
    }

    // If priorityOrder is null or undefined, return the original identifiers without ordering
    if (!priorityOrder) {
      return identifiers;
    }

    const LOWEST_PRIORITY = priorityOrder.length;
    const UNKNOWN_PRIORITY = LOWEST_PRIORITY - 1;
    const NOT_FOUND = -1;

    return orderBy(identifiers, [
      identifier => {
        const { sourceSystem } = identifier;

        if (!sourceSystem) {
          return LOWEST_PRIORITY; // sourceSystem can be null, place null sourceSystem at the end
        }
        const index = priorityOrder.findIndex(item => item.value === sourceSystem);
        return index === NOT_FOUND ? UNKNOWN_PRIORITY : index; // place Unknown sourceSystem just before null
      },
    ], ["asc"]);
  };

  const getFilteredIdentifiers = (identifiers, includeDeprecated, includeExpired) => {
    if (!identifiers) {
      return [];
    }

    const filtered = filterPatientIdentifiers(identifiers, includeDeprecated, includeExpired);

    // Group identifiers by patientIdentifierTypeCode( MC, DVA, etc )
    const grouped = groupBy(filtered, "patientIdentifierTypeCode");

    // Get an array of identifier groups
    const identifierGroups = values(grouped);

    // For each group, order the identifiers and take the highest priority one
    return map(identifierGroups, group => {
      const orderedGroup = orderPatientIdentifiers(group, sourceSystemPriorityOrder);
      return first(orderedGroup); // Return the highest priority identifier
    });
  };

  const displayedPatientIdentifiers = getFilteredIdentifiers(patientIdentifiers, includeDeprecatedIdentifiers, includeExpiredIdentifiers);
  return (
    <IdentifierItem showIcon={showIcon}>
      {map(codes, code => {
        let item = !isEmpty(displayedPatientIdentifiers) && !showAllIdentifiers ? find(displayedPatientIdentifiers, x => x.patientIdentifierTypeCode === code) : null;
        const label = find(identifierOptions, x => x.value === code)?.label ?? getPatientIdentifierLabel(code);
        const enableEdit = !disabled && patientId && (code === primaryPatientIdentifier || code === "SLHMN") && (!isEmpty(item) ? hasPermissionPatientsIdentifiersUpdate : hasPermissionPatientsIdentifiersCreate);
        const showDeprecatedIdentifier = showAllIdentifiers ? some(displayedPatientIdentifiers, x => x.isDeprecated) : (includeDeprecatedIdentifiers && item?.isDeprecated);
        const displayIdentifier = identifierItem => `${identifierItem.identifier}${identifierItem.isDeprecated ? "(Deprecated)" : ""}`;
        const singleIdentifier = isEmpty(item) ? "None Recorded" : displayIdentifier(item);
        const textContent = !showAllIdentifiers ? singleIdentifier : join(map(filter(displayedPatientIdentifiers, x => x.patientIdentifierTypeCode === code), x => displayIdentifier(x)), ", ");

        const content = () => (
          <Typography
            onClick={enableEdit && !showAllIdentifiers ? () => editPatientIdentifier(item) : null}
            className={clsx(enableEdit && !showAllIdentifiers ? "cursor-pointer" : "cursor-default", showDeprecatedIdentifier && classes.deprecatedIdentifier)}
            color={enableEdit && !showAllIdentifiers ? "primary" : "inherit"}
            variant={textVariant}
          >
            {isEmpty(highlightTerm) ? textContent : (
              <Highlighter autoEscape searchWords={highlightTerm} textToHighlight={textContent} />
            )}
          </Typography>
        );

        if (!showAllIdentifiers) {
          item = {
            ...item,
            patientIdentifierTypeCode: code,
          };
        }

        return (
          <DefaultItem
            key={code}
            label={label}
            content={content()}
            className="flex-wrap sm:flex-no-wrap"
            labelClassName="w-auto mr-4"
            labelProps={{ variant: textVariant }}
            role="presentation"
            onClick={enableEdit && !showAllIdentifiers ? () => editPatientIdentifier(item) : null}
            textColor={enableEdit && !showAllIdentifiers ? "primary" : "inherit"}
          />
        );
      })}
    </IdentifierItem>
  );
};

export default withPermissions("PatientsIdentifiersUpdate", "PatientsIdentifiersCreate")(PatientIdentifierLabel);
