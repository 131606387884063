import React from "react";
import { Typography } from "@material-ui/core";
import clsx from "clsx";
import { isEmpty, map, join } from "lodash";
import { useSelector } from "react-redux";
import Highlighter from "react-highlight-words";

import formatDate from "helpers/format-date";
import { getNationalityLabel, getSexLabel } from "app/auth/store/reducers/system-configuration";
import PatientIdentifierLabel from "./patient-identifier-label";
import AddressDetailsLabel from "./address-details-label";
import UnmergeWarningLabel from "./unmerge-warning-label";
import ContactDetailsLabel from "./contact-details-label";
import DeceasedLabel from "./deceased-label";

const renderAliases = aliases => {
  const aliasList = map(aliases, alias => alias.displayNameFull);

  return (
    <div className="flex-row-container">
      <Typography variant="body2" className="font-bold mr-4">Also Known As:</Typography>
      <Typography variant="body2" color="textPrimary" style={{ maxWidth: "100%" }}>{join(aliasList, ", ")}</Typography>
    </div>
  );
};

const PatientSummary = ({
  patient,
  inlineContent,
  showAddress,
  showContactDetails,
  titleVariant = "body1",
  textVariant = "body2",
  highlightTerm = [],
  includeDeprecatedIdentifiers = false,
  showAllIdentifiers = false,
}) => {
  const nationalityLabel = useSelector(getNationalityLabel);
  const sexLabel = useSelector(getSexLabel);

  if (isEmpty(patient)) return "N/A";

  return (
    <div className="flex flex-col flex-1">
      <Typography className="font-bold" variant={titleVariant}>
        {isEmpty(highlightTerm) ? patient.displayNameFull : (
          <Highlighter autoEscape searchWords={highlightTerm} textToHighlight={patient.displayNameFull} />
        )}
      </Typography>
      {patient.aliases && renderAliases(patient.aliases)}
      <div className={clsx("flex", inlineContent ? "flex-row-container with-gutter" : "flex-col")}>
        <div className="flex-row-container with-gutter">
          <div className="flex-row-container with-gutter">
            <Typography variant={textVariant} className="font-bold mr-4">DOB</Typography>
            <Typography variant={textVariant} color="textPrimary">{formatDate(patient.birthDate)}</Typography>
          </div>
          <div className="flex-row-container with-gutter">
            <Typography variant={textVariant} className="font-bold mr-4">{sexLabel}</Typography>
            <Typography variant={textVariant} color="textPrimary">{patient.sex?.label ?? patient.sex ?? ""}</Typography>
          </div>
          <PatientIdentifierLabel
            showIcon={false}
            onlyPrimaryIdentifier
            patientIdentifiers={patient.patientIdentifiers}
            textVariant={textVariant}
            highlightTerm={highlightTerm}
            patient={patient}
            includeDeprecatedIdentifiers={includeDeprecatedIdentifiers}
          />
          <UnmergeWarningLabel requiresUnmerge={patient.requiresUnmerge} />
          <DeceasedLabel isDead={patient.isDead} />
        </div>
        <div className="flex-row-container with-gutter">
          <PatientIdentifierLabel
            patientIdentifiers={patient.patientIdentifiers}
            textVariant={textVariant}
            highlightTerm={highlightTerm}
            patient={patient}
            includeDeprecatedIdentifiers={includeDeprecatedIdentifiers}
            showAllIdentifiers={showAllIdentifiers}
          />
          <Typography variant={textVariant} className="font-bold mr-4">{nationalityLabel}</Typography>
          <Typography variant={textVariant} color="textPrimary">{patient.nationality ?? "Not Recorded"}</Typography>
        </div>
      </div>
      <div className="flex-row-container with-gutter">
        {showAddress && <AddressDetailsLabel address={patient.address} showIcon={false} />}
        {showContactDetails && <ContactDetailsLabel contact={patient.contactDetails} />}
      </div>
    </div>
  );
};

export default PatientSummary;
