import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getReferralRequirementsFromSearchedSpecialtyProfile } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import MarkdownPreview from "./mark-down-preview";

const useStyles = makeStyles(() => ({
  contentContainer: {
    maxWidth: 960,
    width: "100%",
  },
}));

const ReferralRequirementsPreview = ({ label,
  specialtyProfileId }) => {
  const classes = useStyles();
  const requirementsFromSpecialty = useSelector(state => getReferralRequirementsFromSearchedSpecialtyProfile(state, specialtyProfileId));
  const content = !isEmpty(specialtyProfileId) ? requirementsFromSpecialty : null;

  if (isEmpty(content)) return null;

  return (
    <div className={classes.contentContainer}>
      <MarkdownPreview
        markdownText={content}
      />
    </div>
  );
};

export default ReferralRequirementsPreview;
