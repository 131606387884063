import moment from "moment";

const date = {
  startOfToday: moment().startOf('day').toDate(),
  endOfToday: moment().endOf('day').toDate(),
  startOfYesterday: moment().subtract(1, 'days').startOf('day').toDate(),
  endOfYesterday: moment().subtract(1, 'days').endOf('day').toDate(),

  startOfWeek: moment().startOf('isoWeek').toDate(), // ISO week starts on Monday
  endOfWeek: moment().endOf('isoWeek').toDate(), // ISO week ends on Sunday
  startOfLastWeek: moment().subtract(1, 'weeks').startOf('isoWeek').toDate(),
  endOfLastWeek: moment().subtract(1, 'weeks').endOf('isoWeek').toDate(),
  startOfNextWeek: moment().add(1, 'weeks').startOf('isoWeek').toDate(),
  endOfNextWeek: moment().add(1, 'weeks').endOf('isoWeek').toDate(),

  startOfMonth: moment().startOf('month').toDate(),
  endOfMonth: moment().endOf('month').toDate(),
  startOfLastMonth: moment().subtract(1, 'months').startOf('month').toDate(),
  endOfLastMonth: moment().subtract(1, 'months').endOf('month').toDate(),
  startOfNextMonth: moment().add(1, 'months').startOf('month').toDate(),
  endOfNextMonth: moment().add(1, 'months').endOf('month').toDate(),
};

export function getToday() {  
  return [date.startOfToday, date.endOfToday];
}

export function getYesterday() {
  return [date.startOfYesterday, date.endOfYesterday];
}

export function getThisWeek() {
  return [date.startOfWeek, date.endOfWeek];
}

export function getLastWeek() {
  return [date.startOfLastWeek, date.endOfLastWeek];
}

export function getNextWeek() {
  return [date.startOfNextWeek, date.endOfNextWeek];
}

export function getThisMonth() {
  return [date.startOfMonth, date.endOfMonth];
}

export function getLastMonth() {
  return [date.startOfLastMonth, date.endOfLastMonth];
}

export function getNextMonth() {
  return [date.startOfNextMonth, date.endOfNextMonth];
}

export function getDateRanges(label) {
  if (!label) return null;
  switch (label) {
    case "today":
      return getToday();
    case "yesterday":
      return getYesterday();
    case "thisWeek":
      return getThisWeek();
    case "thisMonth":
      return getThisMonth();
    case "lastWeek":
      return getLastWeek();
    case "lastMonth":
      return getLastMonth();
    case "nextWeek":
      return getNextWeek();
    case "nextMonth":
      return getNextMonth();
    default:
      return null;
  }
}
