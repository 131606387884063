import React from "react";
import { Icon, Hidden, Popover, Typography, Box } from "@material-ui/core";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/styles";

import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import PopupMenu from "components/items/popup-menu";
import ConfirmationDialog from "components/items/confirmation-dialog";
import { AccountCheckIcon, AutorenewIcon } from "helpers/icon-finder";
import IconButton from "components/items/icon-button";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flex: "1 1 auto",
    flexWrap: "nowrap",
    marginBottom: theme.spacing(3),
    alignItems: "flex-start",
    padding: theme.spacing(1),
    "user-select": "none",
    transition: "all .3s ease",
    backgroundColor: "transparent",
    [theme.breakpoints.up("md")]: {
      marginBottom: 0,
      alignItems: "center",
      padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    "&:hover": {
      backgroundColor: theme.palette.primary[50],
      cursor: "pointer",
    },
  },
  popup: {
    width: 320,
    maxHeight: "50vh",
    [theme.breakpoints.up("sm")]: {
      width: 448,
    },
    [theme.breakpoints.up("md")]: {
      width: 680,
    },
  },
  popupHeaderActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    "& > *:last-child": {
      marginLeft: theme.spacing(2),
    },
  },
  contentContainer: {
    display: "flex",
    flex: "1 1 auto",
    flexWrap: "wrap",
    alignItems: "center",
    minHeight: 56,
  },
}));

const openActionDialog = (dispatch, title, content, onConfirm, type = "warning") => dispatch(openDialog({
  children: (
    <ConfirmationDialog
      type={type}
      title={title}
      content={content}
      onConfirm={onConfirm}
      onCancel={() => dispatch(closeDialog())}
    />
  ),
}));

const PatientContextListItem = ({
  confirmCancelTitle = "Confirm Cancel",
  confirmRemoveTitle = "Confirm Remove",
  confirmResolveTitle = "Confirm Resolve",
  confirmRenewTitle = "Renew",
  editButtonTitle = "Edit",
  resolveButtonTitle = "Resolve",
  renewButtonTitle = "Renew",
  onCancel,
  onEdit,
  onRemove,
  onResolve,
  onRenew,
  isFirst,
  renderItemContent,
  renderPreviewContent,
  title,
  children,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const popupCancelDialog = () => openActionDialog(dispatch, confirmCancelTitle, renderItemContent(), onCancel);
  const popupRemoveDialog = () => openActionDialog(dispatch, confirmRemoveTitle, renderItemContent(), onRemove, "alert");
  const popupResolveDialog = () => openActionDialog(dispatch, confirmResolveTitle, renderItemContent(), onResolve);
  const popupRenewDialog = () => openActionDialog(dispatch, confirmRenewTitle, renderItemContent(), onRenew);

  const renderContextMenu = ({ close, ...other }) => (
    <Popover
      {...other}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      classes={{ paper: classes.popup }}
    >
      <Box className="py-16 px-24">
        {/* actions */}
        <Box className={classes.popupHeaderActions}>
          {onRemove && <IconButton title="Remove" icon="delete" onClick={() => { popupRemoveDialog(); close(); }} />}
          {onEdit && <IconButton title={editButtonTitle} icon="edit" onClick={() => { onEdit(); close(); }} />}
          {onCancel && <IconButton title="Cancel" icon="cancel" onClick={() => { popupCancelDialog(); close(); }} />}
          {onResolve && <IconButton title={resolveButtonTitle} icon={<AccountCheckIcon />} onClick={() => { popupResolveDialog(); close(); }} />}
          {onRenew && <IconButton title={renewButtonTitle} icon={<AutorenewIcon />} onClick={() => { popupRenewDialog(); close(); }} />}
          <IconButton title="Close" icon="close" onClick={close} />
        </Box>
        {/* content */}
        <Typography className="font-bold" variant="h6">{title}</Typography>
        <Box className="items-container md mt-16">
          {renderPreviewContent({ close })}
        </Box>
      </Box>
    </Popover>
  );
  return (
    <PopupMenu
      trigger={popupState => (
        <div>
          <ListItem isFirst={isFirst} {...popupState}>
            {renderItemContent({ title })}
          </ListItem>
          {children}
        </div>
      )}
      content={props => renderContextMenu(props)}
    />
  );
};

export const ItemContent = ({
  icon,
  title,
  status,
  content,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.contentContainer}>
      {/* icon */}
      {icon && (
      <Hidden xsDown>
        <Icon className="mr-24">{icon}</Icon>
      </Hidden>
      )}
      {/* content */}
      <Box className="flex-row-container flex-auto justify-between">
        <Box>
          <Typography className="font-bold">{title}</Typography>
          {content}
        </Box>
        {status}
      </Box>
    </Box>
  );
};

export const ListItem = ({ children, isFirst, ...other }) => {
  const classes = useStyles();
  return (<Box role="presentation" className={clsx(!isFirst && "mt-16", classes.container)} {...other}>{children}</Box>);
};

export default React.memo(PatientContextListItem);
