import React from "react";
import * as Yup from "yup";
import { Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { find, compact } from "lodash";

import Form from "components/form";
import formatFilterDate from "helpers/format-filter-date";
import PatientSelector from "app/main/patients/components/patient-selector";
import PatientOCRSelector from "app/main/patients/components/patient-ocr-selector";
import PatientForm from "app/main/patients/components/patient-form";
import AddReferralForm from "app/main/referrals/components/add-referral-form";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { createNewPatient } from "app/main/patients/actions/patients.actions";
import { getAllPatients } from "app/main/patients/reducers/patients.reducers";
import { resetCurrentPatientOCR, resetCurrentAttachment } from "app/main/patients/actions/patientOCR.selector.actions";
import { resetPatientSearch } from "app/main/patients/actions/patientsSearch.actions";
import { getSexOptions, getPrimaryPatientIdentifier, showExternalPatientSearch, getReferralActionTypeSettings, getEnableStreamlinedAddReferral } from "app/auth/store/reducers/system-configuration";
import { fetchPatientNOK } from "app/main/patients/actions/contacts.actions";
import { clearReferralSourceDocumentFiles } from "app/main/referrals/actions/referrals.actions";
import DefaultButton from "components/items/default-button";
import { getPatientLabel } from "utils/get-environment-variables";
import PatientSearchButton from "app/main/patients/components/external-patient-search-button";
import useOpenConfigFormByProfile from "hooks/use-open-config-form-by-profile";
import withPermissions from "permissions/withPermissions";

const patientLabel = getPatientLabel();
const schema = Yup.object().shape({
  patient: Yup.string()
    .required("This field is required")
    .nullable(),
});

const CreateReferralForm = ({
  orgUnitId,
  sourceDocument,
  onSucceed,
  hasPermissionPatientsCreate,
  hasPermissionReferralsDraft,
  patientPresetFilter,
  confirmedOCR = false,
  isFormStreamlinedAddReferral = false,
  isFromNewPatient = false,
  presetPatient = null,
  ...other
}) => {
  const dispatch = useDispatch();
  const patients = useSelector(getAllPatients);
  const sexOptions = useSelector(getSexOptions);
  const primaryPatientIdentifier = useSelector(getPrimaryPatientIdentifier);
  const referralActionTypeSettings = useSelector(getReferralActionTypeSettings);
  const createdActionSettings = find(referralActionTypeSettings, x => x.actionType === "Create");

  const enableSearchExternal = useSelector(showExternalPatientSearch);
  const enableStreamlinedAddReferral = useSelector(getEnableStreamlinedAddReferral);
  const enableAddPatient = hasPermissionPatientsCreate;

  const translatePatientDetails = patient => (
    {
      ...patient,
      nextOfKin: patient?.nextOfKin ?? null,
      sex: find(sexOptions, x => x.value === patient.sex || x.label === patient.sex) ?? null,
    }
  );

  const onClear = () => { dispatch(resetCurrentPatientOCR()); dispatch(resetCurrentAttachment()); dispatch(resetPatientSearch()); };

  const onClose = () => { onClear(); dispatch(closeDialog()); };

  const onOpenReferralForm = (data, uploadedSourceDocument, formStreamlinedAddReferral = false, fromNewPatient = false) => {
    dispatch(clearReferralSourceDocumentFiles());
    dispatch(openDialog({
      maxWidth: "xl",
      children: (
        <AddReferralForm
          orgUnitId={orgUnitId}
          title="Add Referral Details"
          onCancel={onClose}
          onSucceed={props => {
            onClose();
            if (onSucceed) {
              onSucceed(props);
            }
          }}
          patient={translatePatientDetails(data)}
          warningMessages={createdActionSettings?.warningMessage}
          sourceDocument={uploadedSourceDocument ?? sourceDocument}
          allowSaveAsDraft={hasPermissionReferralsDraft}
          enableProcessOCR={false}
          enableBack={enableStreamlinedAddReferral && formStreamlinedAddReferral}
          isFormStreamlinedAddReferral={formStreamlinedAddReferral}
          isFromNewPatient={fromNewPatient}
        />
      ),
    }));
  };

  const { openConfigForm } = useOpenConfigFormByProfile(orgUnitId, null, onOpenReferralForm);

  const handleCreatePatient = ({ patient: newPatient }, uploadedSourceDocument, formStreamlinedAddReferral = false) => {
    onOpenReferralForm({
      ...newPatient,
      sex: newPatient?.sex?.value ?? newPatient?.sex,
      birthDate: formatFilterDate(newPatient?.birthDate),
      patientIdentifiers: compact([
        {
          identifier: newPatient.primaryPatientIdentifier,
          patientIdentifierTypeCode: primaryPatientIdentifier,
        },
        newPatient.medicareCard ? {
          identifier: newPatient.medicareCard,
          patientIdentifierTypeCode: "MC",
        } : null,
        newPatient.dva ? {
          identifier: newPatient.dva,
          patientIdentifierTypeCode: "DVA",
        } : null,
      ]),
    }, uploadedSourceDocument, formStreamlinedAddReferral, true);
  };

  const openPatientForm = props => {
    dispatch(createNewPatient());
    dispatch(openDialog({
      maxWidth: "md",
      children: (
        <PatientForm
          isModal
          // variant="filled"
          title={`Create a new ${patientLabel.single}`}
          onCancel={onClose}
          onSucceed={onClose}
          handleSubmit={handleCreatePatient}
          submitLabel="Continue"
          showNOK
          showHealthSection
          showPrimaryIdentifier
          {...props}
        />
      ),
    }));
  };

  const handleSubmit = ({ patient }) => {
    // else open add-referral-form, pass patient value
    let existingPatient = find(patients, p => p.patientId === patient.value);

    dispatch(fetchPatientNOK(existingPatient.patientId)).then(response => {
      if (!response.error) {
        existingPatient = { ...existingPatient, nextOfKin: response.payload.contact };
        openConfigForm(existingPatient);
      }
    });
  };

  if (isFormStreamlinedAddReferral || enableStreamlinedAddReferral) {
    return (
      <PatientOCRSelector
        fullWidth
        maxWidth="xl"
        contentProps={{ ...other, title: `Select ${patientLabel.single}`, submitLabel: "Continue" }}
        initialValues={schema.cast()}
        validationSchema={schema}
        onContinue={onOpenReferralForm}
        onCreatePatient={handleCreatePatient}
        onClose={onClose}
        enableSearchExternal={enableSearchExternal}
        enableAddPatient={enableAddPatient}
        patientPresetFilter={patientPresetFilter}
        patientLabel={patientLabel}
        orgUnitId={orgUnitId}
        confirmedOCR={confirmedOCR}
        referredToSpecialty={other?.referredToSpecialty}
        isFromNewPatient={isFromNewPatient}
        patient={presetPatient}
      />
    );
  }

  return (
    <Form
      contentProps={{ ...other, title: `Select ${patientLabel.single}`, submitLabel: "Continue" }}
      initialValues={schema.cast()}
      validationSchema={schema}
      onSubmit={handleSubmit}
      extraActions={(
        <div className="flex-row-container with-gutter">
          {enableSearchExternal && (
          <PatientSearchButton
            onContinue={patient => openConfigForm(patient)}
            buttonProps={{ variant: "text" }}
            enableAddPatient={enableAddPatient}
          />
          )}
          {enableAddPatient && (
          <DefaultButton
            label={`New ${patientLabel.single}`}
            icon="add"
            variant="text"
            onClick={openPatientForm}
          />
          )}
        </div>
      )}
      content={() => (
        <Field
          name="patient"
          component={PatientSelector}
          label={patientLabel.single}
          icon="account_circle"
          required
          autoFocus
        />
      )}
    />
  );
};

export default withPermissions("PatientsCreate", "ReferralsDraft")(CreateReferralForm);
