import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Alert } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import FormContext from "components/FormContext";
import { get, map, isEmpty, filter, concat, includes } from "lodash";
import withPermissions from "permissions/withPermissions";
import DropzoneAttachment from "components/dropzoneAttachment";
import { getUser } from "app/auth/store/reducers/user.reducer";
import IconButton from "components/items/icon-button";
import LoadingState from "components/items/loading-state";
import ImageSearchOutlineIcon from "mdi-react/ImageSearchOutlineIcon";
import TextBoxSearchOutlineIcon from "mdi-react/TextBoxSearchOutlineIcon";
import ReferralDocumentAttachmentItem from "app/main/referralDocumentAttachmentUploads/components/referral-document-attachment-item";
import ReferralDocumentAttachmentPreviewDialog from "app/main/referralDocumentAttachmentUploads/components/referral-document-attachment-preview-dialog";
import { uploadAttachment, removeAttachment } from "app/main/referralDocumentAttachmentUploads/actions/referralDocumentAttachmentUploads.actions";
import { getReferralDocumentAttachmentUploads } from "app/main/referralDocumentAttachmentUploads/reducers/referralDocumentAttachmentUploads.reducers";
import { currentReferralAttachmentIsLoading, getCurrentReferralAttachmentFiles } from "app/main/referrals/reducers/referrals.reducers";
import { getReferralAttachments } from "app/main/referrals/actions/referrals.actions";
import { getFriendlyUploadTypeDescription } from "app/auth/store/reducers/system-configuration";

const useStyles = makeStyles(theme => ({
  helperText: {
    color: theme.palette.text.hint,
    display: "flex",
    minHeight: 20,
  },
  contentContainer: {
    flexDirection: "column",
    display: "flex",
    height: "100%",
  },
  attachmentRoot: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: theme.spacing(2),
  },
  attachmentItem: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  attachmentTitle: {
    overflowWrap: "break-all",
    wordBreak: "break-all",
    wordWrap: "break-word",
    whiteSpace: "normal",
  },
}));

const ReferralDocumentAttachmentUpload = ({
  label,
  orgUnitId,
  onFileUploaded = null,
  onFileRemoved = null,
  field: { name, value },
  form: { errors, setFieldValue },
  referralId = null,
  hasPermissionReferralAttachmentCreate,
  hasPermissionReferralAttachmentDelete,
  hasPermissionReferralAttachmentDeleteOtherUser,
  referralPermissions = null,
  removedAttachmentFieldName = null,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector(getUser);
  const referralDocumentAttachmentUploads = useSelector(getReferralDocumentAttachmentUploads);
  const referralAttachments = useSelector(state => getCurrentReferralAttachmentFiles(state, referralId));
  const loadingReferralAttachments = useSelector(currentReferralAttachmentIsLoading);
  const helperText = useSelector(getFriendlyUploadTypeDescription);
  const [uploading, setUploading] = useState(false);
  const [removing, setRemoving] = useState(false);
  const [error, setError] = useState(get(errors, name, null));
  const hasRemovePermission = referralPermissions?.canEditAttachments && hasPermissionReferralAttachmentDelete;
  const hasRemoveOtherUserPermission = referralPermissions?.canEditAttachments && hasPermissionReferralAttachmentDeleteOtherUser;
  const [currentReferralAttachments, setCurrentReferralAttachments] = useState([]);
  const [currentPreviewAttachment, setCurrentPreviewAttachment] = useState(null);
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [isUpload, setIsUpload] = useState(false);

  useEffect(() => {
    if (orgUnitId && referralId) {
      dispatch(getReferralAttachments(orgUnitId, referralId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId, referralId]);

  useEffect(() => {
    if (loadingReferralAttachments === false && referralAttachments) {
      setCurrentReferralAttachments(referralAttachments);
    }
  }, [loadingReferralAttachments, referralAttachments]);

  const onFileUpload = (file, attachments) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      setUploading(true);
      reader.onload = () => {
        const formData = new FormData();
        formData.append("file", file);
        setError("");
        dispatch(uploadAttachment(orgUnitId, formData))
          .then(response => {
            const apiErrors = response.payload?.exceptionMessage ?? response.payload.message;
            if (response.error === true) {
              setError(apiErrors);
            } else {
              setError(null);
              const referralDocumentAttachmentUploadId = response.payload?.id;
              if (referralDocumentAttachmentUploadId) {
                setFieldValue(name, isEmpty(attachments) ? [referralDocumentAttachmentUploadId] : concat(attachments, referralDocumentAttachmentUploadId));
              }
              if (onFileUploaded) {
                onFileUploaded(response.payload);
              }
            }
          })
          .finally(() => setUploading(false));
      };
    }
  };

  const onRemove = (referralDocumentAttachmentUploadId, attachments) => {
    if (referralDocumentAttachmentUploadId && !removing) {
      setRemoving(true);
      setError("");
      dispatch(removeAttachment(orgUnitId, referralDocumentAttachmentUploadId))
        .then(response => {
          const apiErrors = response.payload?.exceptionMessage ?? response.payload.message;
          if (response.error === true) {
            setError(apiErrors);
          } else {
            setError(null);
            if (referralDocumentAttachmentUploadId) {
              setFieldValue(name, filter(attachments ?? [], x => x !== referralDocumentAttachmentUploadId));
            }
            if (onFileRemoved) {
              onFileRemoved(response.payload, attachments);
            }
          }
        })
        .finally(() => setRemoving(false));
    }
  };

  const referralAttachmentButton = (attachment, uploadItem) => (
    <IconButton
      title="Preview"
      iconSize={24}
      icon={includes(attachment.mimeType, "image") ? <ImageSearchOutlineIcon /> : <TextBoxSearchOutlineIcon />}
      onClick={() => {
        setIsOpenPreview(true);
        setCurrentPreviewAttachment(attachment);
        setIsUpload(uploadItem);
      }}
    />
  );

  const onRemoveReferralAttachment = (attachmentId, attachments) => {
    setError("");
    const currentAttachments = filter(attachments ?? [], x => x.id !== attachmentId);
    const attachmentIds = map(currentAttachments, x => x.id);
    const removeAttachments = map(filter(referralAttachments, referralAttachment => !includes(attachmentIds, referralAttachment.id)), x => x.id);
    setCurrentReferralAttachments(filter(attachments ?? [], x => x.id !== attachmentId));
    setFieldValue(removedAttachmentFieldName, removeAttachments);
  };

  if (loadingReferralAttachments) return <LoadingState />;

  return (
    <FormContext.Consumer>
      {({ formDisabled }) => (
        <div className={classes.contentContainer}>
          {!isEmpty(error) && <Alert severity="error">{error}</Alert>}
          <DropzoneAttachment label={label} helperText={helperText} isAcceptAllFormat extraParameters={value} onFileUpload={onFileUpload} loading={uploading} disabled={!hasPermissionReferralAttachmentCreate && formDisabled} />
          <div className={classes.attachmentRoot}>
            {map(referralDocumentAttachmentUploads, (attachment, index) => (
              <ReferralDocumentAttachmentItem
                id={attachment.id}
                key={attachment.id}
                mimeType={attachment.mimeType}
                isFirst={index === 0}
                title={(
                  <Typography variant="body1" className={clsx(classes.attachmentTitle, "font-bold")}>{attachment.fileName}</Typography>
                )}
                canRemove
                onRemove={() => onRemove(attachment.id, value)}
                className={classes.attachmentItem}
                renderActions={referralAttachmentButton(attachment, true)}
              />
            ))}
            {map(currentReferralAttachments, (referralAttachment, index) => (
              <ReferralDocumentAttachmentItem
                id={referralAttachment.id}
                key={referralAttachment.id}
                mimeType={referralAttachment.mimeType}
                isFirst={index === 0}
                title={(
                  <Typography variant="body1" className={clsx(classes.attachmentTitle, "font-bold")}>{referralAttachment.fileName}</Typography>
                )}
                canRemove={!referralAttachment?.isSystemAttachment && ((hasRemovePermission && user.userId === referralAttachment.addedByUserId) || hasRemoveOtherUserPermission)}
                onRemove={() => onRemoveReferralAttachment(referralAttachment.id, currentReferralAttachments)}
                className={classes.attachmentItem}
                renderActions={referralAttachmentButton(referralAttachment, false)}
              />
            ))}
            {currentPreviewAttachment && (
              <ReferralDocumentAttachmentPreviewDialog
                open={isOpenPreview}
                file={currentPreviewAttachment}
                orgUnitId={orgUnitId}
                onClose={() => setIsOpenPreview(false)}
                referralId={referralId}
                referralDocumentId={currentPreviewAttachment?.referralDocumentId}
                isUpload={isUpload}
              />
            )}
          </div>
        </div>
      )}
    </FormContext.Consumer>
  );
};

export default withPermissions("ReferralAttachmentCreate", "ReferralAttachmentDelete", "ReferralAttachmentDeleteOtherUser")(ReferralDocumentAttachmentUpload);
