import { ActionTypes } from "../reducers/reportExport.reducers";

export const updateReportExportProgress = ({ detail }) => async dispatch => {
  const exportId = detail[0];
  const jobStatus = detail[1];

  await dispatch({
    type: ActionTypes.UPDATE_EXPORT_PROGRESS,
    payload: { exportId, jobStatus },
  });
};
