import { join, map, size } from "lodash";

export function displayUserSpecialtiesInMenu(userSpecialtyProfiles) {
  const maxLength = 3;
  const displayMore = size(userSpecialtyProfiles) > maxLength;
  const displaySpecialtyProfiles = displayMore ? userSpecialtyProfiles.slice(0, maxLength) : userSpecialtyProfiles;
  const formattedSpecialtyProfilesFormat = join(map(displaySpecialtyProfiles, userSpecialtyProfile => userSpecialtyProfile?.specialtyProfile?.displayName), ", ");

  return displayMore ? `${formattedSpecialtyProfilesFormat}, ...` : formattedSpecialtyProfilesFormat;
}

export function displayUserSpecialties(userSpecialtyProfiles) {
  return join(map(userSpecialtyProfiles, userSpecialtyProfile => `${userSpecialtyProfile?.specialtyProfile?.displayName} (${userSpecialtyProfile?.specialtyProfile?.orgUnitName})`), ", ");
}
