import React from "react";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { requestReferralAction } from "app/main/referrals/actions/referrals.actions";
import Form from "components/form";
import ReferralWarningMessage from "./referral-warning-message-alert";

const message = "Mark this referral as requested?";
const title = "Update Referral";

const RequestReferralDialog = ({
  onSucceed,
  referralId,
  orgUnitId,
  isUndo,
  warningMessages,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = ({ id }, { setErrors }) => {
    dispatch(requestReferralAction(orgUnitId, id, isUndo)).then(response => {
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  return (
    <Form
      initialValues={{ id: referralId }}
      onSubmit={handleSubmit}
      contentProps={{ title, submitLabel: "Confirm" }}
      variant="filled"
      content={() => (
        <>
          <ReferralWarningMessage warningMessages={warningMessages} />
          <Typography>{message}</Typography>
        </>
      )}
    />
  );
};

export default RequestReferralDialog;
