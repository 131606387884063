import { ActionTypes } from "../reducers/patientOCR.selector.reducers";

export const setCurrentPatientOCR = ocrResult => dispatch => {
  let patientOCR = null;
  let ocrResultJSON = null;

  try {
    ocrResultJSON = JSON.parse(ocrResult);
    patientOCR = {
      patientOCR: ocrResultJSON,
      error: ocrResultJSON?.message,
    };
  } catch (exception) {
    patientOCR = {
      patientOCR: null,
      error: `Invalid OCR response format ${exception}`,
    };
  }

  dispatch({
    type: ActionTypes.SET_CURRENT_PATIENT_OCR,
    payload: patientOCR,
  });
};

export const setCurrentAttachment = attachment => ({
  type: ActionTypes.SET_CURRENT_ATTACHMENT,
  payload: attachment,
});

export const resetCurrentPatientOCR = () => ({
  type: ActionTypes.RESET_CURRENT_PATIENT_OCR,
});

export const resetCurrentAttachment = () => ({
  type: ActionTypes.RESET_CURRENT_ATTACHMENT,
});
