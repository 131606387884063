import { map, mapValues, keyBy, omit } from "lodash";
import curryConnector from "utils/connectors";
import { isJobStatusComplete } from "../constants/jobStatusTypes";

export const REPORT_EXPORT_STATE_KEY = "reportExport";

const curry = fn => curryConnector(fn, REPORT_EXPORT_STATE_KEY);

export const ActionTypes = {
  ADD_EXPORT_JOB_LIST: "ADD_EXPORT_JOB_LIST",
  UPDATE_EXPORT_PROGRESS: "UPDATE_EXPORT_PROGRESS",

  LOADING_EXPORT_JOB_LIST: "LOADING_EXPORT_JOB_LIST",
  LOADED_EXPORT_JOB_LIST: "LOADED_EXPORT_JOB_LIST",
  ERROR_LOADING_EXPORT_JOB_LIST: "ERROR_LOADING_EXPORT_JOB_LIST",

  SHOW_EXPORT_INDICATOR: "SHOW_EXPORT_INDICATOR",
  REMOVE_EXPORT_COMPLETE_INDICATOR: "REMOVE_EXPORT_COMPLETE_INDICATOR",

  CLEAR_EXPORT_COMPLETE_JOB: "CLEAR_EXPORT_COMPLETE_JOB",

  DELETED_EXPORT_JOB: "DELETED_EXPORT_JOB",
};

const INITIAL_STATE = {
  loading: false,
  error: null,
  loaded: false,
  exportCompleteJobId: null,
  showExportCompleteIndicator: false,
  exportJobs: {}, // { [exportId]: { fileName: null, exportId: null, jobId: null, jobProgress: null } }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.ERROR_LOADING_EXPORT_JOB_LIST:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload.message,
      };
    case ActionTypes.LOADING_EXPORT_JOB_LIST:
      return { ...state, loading: true, error: null, loaded: false };
    case ActionTypes.LOADED_EXPORT_JOB_LIST:
      const newExportJobs = keyBy(action.payload.exportJobs, "id");
      return {
        ...state,
        loading: false,
        error: null,
        loaded: true,
        exportJobs: {
          ...mapValues(newExportJobs, (exportJob, id) => ({
            ...state.exportJobs[id],
            ...exportJob,
          })),
        },
      };
    case ActionTypes.UPDATE_EXPORT_PROGRESS:
      return {
        ...state,
        showExportCompleteIndicator: isJobStatusComplete(action.payload.jobStatus),
        exportCompleteJobId: isJobStatusComplete(action.payload.jobStatus) ? action.payload.exportId : null,
        exportJobs: {
          ...state.exportJobs,
          [action.payload.exportId]: { ...state.exportJobs[action.payload.exportId], id: action.payload.exportId, jobStatus: action.payload.jobStatus },
        },
      };
    case ActionTypes.SHOW_EXPORT_INDICATOR:
      return { ...state, showExportCompleteIndicator: true };
    case ActionTypes.REMOVE_EXPORT_COMPLETE_INDICATOR:
      return { ...state, showExportCompleteIndicator: false };
    case ActionTypes.DELETED_EXPORT_JOB:
      return {
        ...state,
        exportJobs: omit(state.exportJobs, action.payload.exportId),
      };
    case ActionTypes.CLEAR_EXPORT_COMPLETE_JOB:
      return { ...state, exportCompleteJobId: null };
    default:
      return state || INITIAL_STATE;
  }
};

export const showExportCompleteIndicator = curry(({ localState }) => localState.showExportCompleteIndicator);

export const getExportJobs = curry(({ localState }) => map(localState.exportJobs));

export const isExportJobsLoaded = curry(({ localState }) => localState.loaded);

export const getExportCompleteJobId = curry(({ localState }) => localState.exportCompleteJobId);
