import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Field } from "formik";
import * as Yup from "yup";
import { map, startCase, concat, compact, filter, includes, find, isEmpty, trim } from "lodash";

import { TextAreaInput } from "components/inputs/text-input";
import { CheckboxInput, TextInput, AutoComplete } from "components/inputs";
import withPermissions from "permissions/withPermissions";
import { getAllAssignableRoles, getEnableSRCEnabledSetting, getEnableReferralRequirements, getDisplayExtraRMSFieldsInSpecialtyForm } from "app/auth/store/reducers/system-configuration";
import { PreviewMarkIcon } from "helpers/icon-finder";
import Form from "components/form";
import MultipleTextInput from "components/items/multiple-text-input";
import DefaultButton from "components/items/default-button";
import { openDialog } from "app/store/actions/dialog.actions";
import { fetchMultipleCodeSetValues } from "app/main/codeSet/actions/codeSet.actions";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";
import MarkdownPreviewDialog from "components/items/mark-down-preview-dialog";
import MarkdownPreview from "components/items/mark-down-preview";
import { FormLabel, makeStyles } from "@material-ui/core";
import ReferralFormProfileSelector from "app/main/referralFormProfiles/components/referralFormProfile-selector";
import { addNewSpecialtyProfile, updateExistingSpecialtyProfile } from "../actions/specialtyProfiles.actions";
import SubSpecialtyInput from "./subSpecialty-input";
import AutoAssignRoleSelector from "./auto-assign-role-selector";
import SpecialtyUserSelector from "./specialty-user-selector";
import SpecialtyMultipleCodeSetInput from "./specialty-multiple-code-set-input";

const name = "Specialty Name";
const availableRolesLabel = "Available Roles";
const autoAssignRoleLabel = "Auto Assign Role";
const healthLinkServiceCodeLabel = "Health Link Service Code";
const clinicTypeCodeSetValuesLabel = "Clinic Types";
const clinicCodeSetValuesLabel = "Clinics";
const specialtySpecialistsLabel = "Specialists";
const eventProgramLabel = "Event Program";
const preferredSiteLabel = "Preferred Site";
const responsibleHCPLabel = "Responsible HCP";
const presentingComplaintCodesLabel = "Presenting Complaints";
const episodeProgramStreamLabel = "Episode Program Stream";
const episodeHealthConditionCodesLabel = "Episode Health Conditions";
const isCrossOrgAssignmentLabel = "Same Site Referral Assignment Only";
const SRCEnabledLabel = "SRC Enabled";
const referralRequirementsLabel = "Referral Requirements";
const referralProfileFormLabel = "Enabled Form Profiles";
const overrideDefaultFormProfilesLabel = "Customise Enabled Referral Form Profiles";

const useStyles = makeStyles(theme => ({
  label: {
    fontSize: "small",
    marginLeft: theme.spacing(4),
  },
}));

const schema = Yup.object().shape({
  specialtyProfile: Yup.object().shape({
    name: Yup.string()
      .max(100, "Too long!")
      .required(`${name} is required`)
      .nullable(),
    currentSpecialtyRoles: Yup.string().nullable(),
    autoAssignRoles: Yup.string().nullable(),
    serviceCodes: Yup.string().max(50, "Too long!").nullable(),
    subSpecialties: Yup.string().nullable(),
    specialtySpecialists: Yup.string().nullable(),
    clinicTypeCodes: Yup.string().nullable(),
    clinicCodes: Yup.string().nullable(),
    eventProgramCode: Yup.string().nullable(),
    preferredSiteCode: Yup.string().nullable(),
    responsibleHCPCode: Yup.string().nullable(),
    presentingComplaintCodes: Yup.string().nullable(),
    episodeProgramStreamCode: Yup.string().nullable(),
    episodeHealthConditionCodes: Yup.string().nullable(),
    disabledCrossOrgAssignment: Yup.boolean().nullable(),
    srcEnabled: Yup.boolean().nullable().default(false),
    referralRequirements: Yup.string()
      .max(2000, "Too long!")
      .nullable(),
    currentSpecialtyReferralFormProfiles: Yup.string().nullable(),
  }),
});

const SpecialtyProfileForm = ({
  orgUnitId,
  onSucceed,
  specialtyProfile: currentSpecialtyProfile,
  specialtyProfileId,
  hasPermissionChangeCurrentSiteConfiguration,
  ...other
}) => {
  const dispatch = useDispatch();
  const allAssignableRoles = useSelector(getAllAssignableRoles);
  const enableSRCEnabledSetting = useSelector(getEnableSRCEnabledSetting);
  const displayExtraRMSFieldsInSpecialtyForm = useSelector(getDisplayExtraRMSFieldsInSpecialtyForm);
  const roleOptions = map(allAssignableRoles, role => ({ value: role.roleId, label: role.roleDescription ?? startCase(role.roleName) }));
  const enableReferralRequirements = useSelector(getEnableReferralRequirements);
  // set default options for auto Assign roles
  const defaultAutoAssignOptions = currentSpecialtyProfile?.currentSpecialtyRoles
    ? filter(roleOptions, x => includes(currentSpecialtyProfile.currentSpecialtyRoles, x.value))
    : roleOptions;
  const classes = useStyles();
  const [autoAssignRoleOptions, setAutoAssignRoleOptions] = useState(defaultAutoAssignOptions);

  React.useEffect(() => {
    // load coseSets that can be config by specialty profile
    // TODO not the best way to do it...
    dispatch(fetchMultipleCodeSetValues(["PresentingComplaintCodes", "ClaimType", "ClinicType", "Clinic", "ResponsibleHCP", "EventProgram", "PreferredSite", "EpisodeProgramStream", "EpisodeHealthCondition"]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = ({ specialtyProfile }, { setSubmitting, setErrors }) => {
    const apiCall = currentSpecialtyProfile ? updateExistingSpecialtyProfile : addNewSpecialtyProfile;
    const { clinicCodes, clinicTypeCodes, eventProgramCode, preferredSiteCode, presentingComplaintCodes, responsibleHCPCode, episodeProgramStreamCode, episodeHealthConditionCodes } = specialtyProfile;

    const specialtyCodeSetValues = compact(concat(
      clinicCodes,
      clinicTypeCodes,
      presentingComplaintCodes,
      responsibleHCPCode?.value ?? responsibleHCPCode,
      eventProgramCode?.value ?? eventProgramCode,
      preferredSiteCode?.value ?? preferredSiteCode,
      episodeProgramStreamCode?.value ?? episodeProgramStreamCode,
      episodeHealthConditionCodes,
    ));

    const data = {
      ...specialtyProfile,
      orgUnitId,
      currentSpecialtyCodeSetValues: specialtyCodeSetValues,
    };

    dispatch(apiCall(data)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed(responds.payload.specialtyProfile.id);
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const showReferralRequirementsPreview = content => dispatch(openDialog({ children: <MarkdownPreviewDialog content={content} /> }));

  let { specialtyProfile } = schema.cast();

  if (currentSpecialtyProfile) {
    specialtyProfile = currentSpecialtyProfile;
  }

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ specialtyProfile }}
      validationSchema={schema}
      isModal={false}
      {...other}
      content={({ setFieldValue, values }) => (
        <>
          <Field
            name="specialtyProfile.name"
            label={name}
            type="text"
            component={TextInput}
          />
          <Field
            name="specialtyProfile.currentSpecialtyRoles"
            label={availableRolesLabel}
            component={AutoComplete}
            options={roleOptions}
            multiple
            onChange={val => {
              // update the autoAssignRole, when available roles changes
              setAutoAssignRoleOptions(val);
              const currentAutoAssignRoles = values.specialtyProfile.autoAssignRoles;
              // filter out the ones that no longer in the available roles
              const updatedAutoAssignRoles = filter(currentAutoAssignRoles, x => find(val, option => option.value === x.roleId));
              // update the options
              setFieldValue("specialtyProfile.autoAssignRoles", updatedAutoAssignRoles);
            }}
          />
          {displayExtraRMSFieldsInSpecialtyForm && (
            <>
              <Field
                name="specialtyProfile.autoAssignRoles"
                label={autoAssignRoleLabel}
                component={AutoAssignRoleSelector}
                isDisabled={other.disabled}
                options={autoAssignRoleOptions}
              />
              <Field
                name="specialtyProfile.serviceCodes"
                component={MultipleTextInput}
                label={healthLinkServiceCodeLabel}
              />
              <Field
                name="specialtyProfile.subSpecialties"
                component={SubSpecialtyInput}
              />
              <Field
                name="specialtyProfile.specialtySpecialists"
                component={SpecialtyUserSelector}
                label={specialtySpecialistsLabel}
              />
              <Field
                name="specialtyProfile.clinicTypeCodes"
                label={clinicTypeCodeSetValuesLabel}
                codeSetTypeCode="ClinicType"
                component={CodeSetValueSelector}
                multiple
              />
              <Field
                name="specialtyProfile.clinicCodes"
                label={clinicCodeSetValuesLabel}
                component={CodeSetValueSelector}
                codeSetTypeCode="Clinic"
                multiple
              />
              <Field
                name="specialtyProfile.eventProgramCode"
                component={CodeSetValueSelector}
                codeSetTypeCode="EventProgram"
                label={eventProgramLabel}
              />
              <Field
                name="specialtyProfile.preferredSiteCode"
                component={CodeSetValueSelector}
                codeSetTypeCode="PreferredSite"
                label={preferredSiteLabel}
              />
              <Field
                name="specialtyProfile.responsibleHCPCode"
                label={responsibleHCPLabel}
                component={CodeSetValueSelector}
                codeSetTypeCode="ResponsibleHCP"
              />
              <Field
                name="specialtyProfile.episodeHealthConditionCodes"
                label={episodeHealthConditionCodesLabel}
                component={SpecialtyMultipleCodeSetInput}
                codeSetTypeCode="EpisodeHealthCondition"
              />
              <Field
                name="specialtyProfile.episodeProgramStreamCode"
                component={CodeSetValueSelector}
                codeSetTypeCode="EpisodeProgramStream"
                label={episodeProgramStreamLabel}
              />
              <Field
                name="specialtyProfile.presentingComplaintCodes"
                label={presentingComplaintCodesLabel}
                component={SpecialtyMultipleCodeSetInput}
                codeSetTypeCode="PresentingComplaintCodes"
              />
              {enableReferralRequirements && (
                other.disabled
                  ? (
                    <>
                      <FormLabel className={classes.label}>{referralRequirementsLabel}</FormLabel>
                      <MarkdownPreview markdownText={values.specialtyProfile.referralRequirements} />
                    </>
                  )
                  : (
                    <div className="mt-16 flex sm:flex-row sm:items-center flex-col">
                      <Field
                        name="specialtyProfile.referralRequirements"
                        component={TextAreaInput}
                        label={referralRequirementsLabel}
                        maxLength={2000}
                        rows={10}
                        className="flex-1"
                      />
                      <DefaultButton
                        icon={<PreviewMarkIcon />}
                        className="ml-16"
                        type="submit"
                        label="Preview"
                        variant="outlined"
                        disabled={isEmpty(trim(values.specialtyProfile.referralRequirements))}
                        onClick={() => showReferralRequirementsPreview(values.specialtyProfile.referralRequirements)}
                      />
                    </div>
                  ))}
              <Field
                name="specialtyProfile.overrideDefaultFormProfiles"
                component={CheckboxInput}
                label={overrideDefaultFormProfilesLabel}
                onChange={(value, change) => {
                  if (value === false) {
                    change("specialtyProfile.currentSpecialtyReferralFormProfiles", null);
                  }
                }}
              />
              {values.specialtyProfile.overrideDefaultFormProfiles && (
              <Field
                name="specialtyProfile.currentSpecialtyReferralFormProfiles"
                label={referralProfileFormLabel}
                component={ReferralFormProfileSelector}
                orgUnitId={orgUnitId}
                loadInitialOption={values.specialtyProfile.currentSpecialtyReferralFormProfiles}
                showIcon={false}
                multiple
              />
              )}
              {hasPermissionChangeCurrentSiteConfiguration && (
              <Field
                name="specialtyProfile.disabledCrossOrgAssignment"
                component={CheckboxInput}
                label={isCrossOrgAssignmentLabel}
              />
              )}
              {enableSRCEnabledSetting && (
              <Field
                name="specialtyProfile.srcEnabled"
                component={CheckboxInput}
                label={SRCEnabledLabel}
              />
              )}
            </>
          )}
        </>
      )}
    />
  );
};

export default withPermissions(
  "ChangeCurrentSiteConfiguration",
)(SpecialtyProfileForm);
