import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import { isEmpty, includes, map, some } from "lodash";
import { Alert } from "@material-ui/lab";
import { Typography } from "@material-ui/core";
import { ProblemIcon, RemoveAccount, FaceSickIcon, PriorityIcon } from "helpers/icon-finder";
import OCRFieldComponent from "components/ocr-components/ocr-field-component";
import { DateTimePicker, TextInput, NoteInput, CheckboxInput, CheckboxInputValidationAware } from "components/inputs";
import { OCRNoteInput, OCRDateTimePicker } from "components/ocr-components";
import HelperText from "components/inputs/components/helper-text";
import PanelContent from "components/items/panel-content";
import IconButton from "components/items/icon-button";
import Section from "components/items/section";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";
import SubSpecialtySelector from "app/main/specialtyProfiles/components/sub-specialty-selector";
import SpecialtySpecialistSelector from "app/main/specialtyProfiles/components/specialty-specialist-selector";
import SpecialtyCodeSetSelector from "app/main/specialtyProfiles/components/specialty-code-set-selector";
import { getReferralWorklistSettings, getAllowReassignSubSpecialtyStates, getAllowReassignSpecialtyStates, getEnableOCR, getReferralFormLayoutSetting } from "app/auth/store/reducers/system-configuration";
import { getDefaultSpecialist } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import ReferralRequirementsPreview from "components/items/referral-requirements-preview";
import SelectorHelperText from "./selector-helper-text";

export default ({
  isEdit,
  form: { errors, setFieldValue },
  fieldSettings,
  fieldLabels,
  ocrResult,
  isNewUpload,
  referral,
  currentReferral,
  calculateReferralExpiryDate,
  currentAssignedToSpecialty,
  presentingComplaintMaxLength,
  patientPresentingComplaints,
  isEmptyPatient,
}) => {
  const referralFormLayoutSetting = useSelector(getReferralFormLayoutSetting);
  const enableOCR = useSelector(getEnableOCR);
  // allow reassignment when
  // 1. is new form
  // 2. when current referral status in allowed states
  const AllowReassignSpecialtyStates = useSelector(getAllowReassignSpecialtyStates);
  const allowSpecialtyReassignment = !isEdit || includes(AllowReassignSpecialtyStates, currentReferral?.referralStatus);
  const AllowReassignSubSpecialtyStates = useSelector(getAllowReassignSubSpecialtyStates);
  const allowReassignSubSpecialtyStates = !isEdit || includes(AllowReassignSubSpecialtyStates, currentReferral?.referralStatus);
  const multiplePresentingComplaint = presentingComplaintMaxLength > 1;
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const isEnablePresentingComplaintWarning = !isEmpty(referralWorklistSettings?.patientPresentingComplaintWarningMessage);
  const [showPresentingComplaintWarning, setPresentingComplaintWarning] = useState(false);
  const specialtyId = currentAssignedToSpecialty?.value;
  const defaultSpecialist = useSelector(state => getDefaultSpecialist(state, specialtyId));
  const defaultSpecialistOption = defaultSpecialist ? { value: defaultSpecialist.userId, label: defaultSpecialist.name } : null;
  const [assignedToSpecialist, setAssignedToSpecialist] = useState(referral.assignedToSpecialist);

  useEffect(() => {
    if (isEmpty(referral.assignedToSpecialist) && defaultSpecialistOption) {
      setAssignedToSpecialist(defaultSpecialistOption);
      setFieldValue("referral.assignedToSpecialist", defaultSpecialistOption);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [specialtyId]);

  return (
    <Section withDivider title="Referral Information">
      {fieldSettings?.mbsConsent && (
        <Field
          name="referral.mbsConsent"
          component={CheckboxInput}
          label={fieldLabels.mbsConsent}
          required={fieldSettings.mbsConsent.required}
          helperText="Does the patient consent to being seen at an MBS clinic if available?"
        />
      )}
      {referralFormLayoutSetting === "Layout2" && fieldSettings?.referrerUrgency && (
        <Field
          name="referral.referrerUrgency"
          component={CheckboxInput}
          icon={<ProblemIcon />}
          label={fieldLabels.referrerUrgency}
        />
      )}
      <PanelContent>
        {fieldSettings?.referralDate && (
          <Field
            name="referral.referralDateUtc"
            label={fieldLabels.referralDate}
            required={fieldSettings.referralDate.required}
            onChange={val => calculateReferralExpiryDate(referral?.typeOfReferralCodeId, val)}
            component={OCRFieldComponent}
            FieldComponent={DateTimePicker}
            OCRComponent={OCRDateTimePicker}
            enabledOCR={enableOCR && fieldSettings.referralDate.enabledOCR}
            ocrProperty="ReferralDate"
            confirmedOCR={!isNewUpload}
            ocrResult={ocrResult}
          />
        )}
        {referralFormLayoutSetting === "Layout1" && fieldSettings?.referrerUrgency && (
          <Field
            name="referral.referrerUrgency"
            component={CheckboxInput}
            icon={<ProblemIcon />}
            label={fieldLabels.referrerUrgency}
          />
        )}
      </PanelContent>
      <PanelContent itemsPerRow={referralFormLayoutSetting === "Layout1" && !fieldSettings?.referralPeriod ? 1 : 2}>
        {fieldSettings?.referralPeriod && (
          <Field
            name="referral.referralPeriod"
            component={TextInput}
            label={fieldLabels.referralPeriod}
            required={fieldSettings.referralPeriod.required}
          />
        )}
        {referralFormLayoutSetting === "Layout1" && fieldSettings?.reasonForReferralCode && (
        <Field
          name="referral.reasonForReferralCodeId"
          component={CodeSetValueSelector}
          label={fieldLabels.reasonForReferralCode}
          codeSetTypeCode={fieldSettings.reasonForReferralCode.fieldName}
          required={fieldSettings.reasonForReferralCode.required}
          icon="label_important"
          helperText={<SelectorHelperText />}
          allowHideHealthLinkOnly
          allowHideOnForms
        />
        )}
      </PanelContent>
      <PanelContent>
        {referralFormLayoutSetting === "Layout1" && fieldSettings?.specialistConsultant && (
          <Field
            name="referral.assignedToSpecialist"
            freeSolo
            component={SpecialtySpecialistSelector}
            label={fieldLabels.specialistConsultant}
            required={fieldSettings.specialistConsultant.required}
            specialtyId={specialtyId}
                                // only set default value when exiting referral assignedToSpecialist is empty,
                                // i.e. do not overwrite if fields has value
            setDefaultValue={isEmpty(referral.assignedToSpecialist)}
            initialOptions={assignedToSpecialist ? [assignedToSpecialist] : []}
            onInputChange={(_event, value) => setFieldValue("referral.specialistConsultant", value)}
            onChange={val => {
              setFieldValue("referral.specialistConsultant", val?.label ?? null);
              setFieldValue("referral.specialistConsultantUserId", val?.value ?? null);
            }}
          />
        )}
        {fieldSettings?.assignToSubSpecialty && referral.referredToSpecialty && (
          <Field
            name="referral.assignToSubSpecialty"
            component={SubSpecialtySelector}
            label={fieldLabels.assignToSubSpecialty}
            specialtyIds={!isEmpty(specialtyId) ? [specialtyId] : []}
            disabled={!allowReassignSubSpecialtyStates}
          />
        )}
        {referralFormLayoutSetting === "Layout1" && fieldSettings?.typeOfReferral && (
          <Field
            name="referral.typeOfReferralCodeId"
            component={CodeSetValueSelector}
            label={fieldLabels.typeOfReferral}
            codeSetTypeCode={fieldSettings.typeOfReferral.fieldName}
            required={fieldSettings.typeOfReferral.required}
            onChange={val => calculateReferralExpiryDate(val, referral.referralDateUtc)}
            allowHideHealthLinkOnly
            allowHideOnForms
          />
        )}
      </PanelContent>
      {referralFormLayoutSetting === "Layout2" && fieldSettings?.specialistConsultant && (
        <Field
          name="referral.assignedToSpecialist"
          freeSolo
          component={SpecialtySpecialistSelector}
          label={fieldLabels.specialistConsultant}
          required={fieldSettings.specialistConsultant.required}
          specialtyId={specialtyId}
                              // only set default value when exiting referral assignedToSpecialist is empty,
                              // i.e. do not overwrite if fields has value
          setDefaultValue={isEmpty(referral.assignedToSpecialist)}
          initialOptions={assignedToSpecialist ? [assignedToSpecialist] : []}
          onInputChange={(_event, value) => setFieldValue("referral.specialistConsultant", value)}
          onChange={val => {
            setFieldValue("referral.specialistConsultant", val?.label ?? null);
            setFieldValue("referral.specialistConsultantUserId", val?.value ?? null);
          }}
        />
      )}
      {referralFormLayoutSetting === "Layout1" && fieldSettings?.reasonNote && (
      <Field
        name="referral.reasonNote"
        component={OCRFieldComponent}
        FieldComponent={NoteInput}
        OCRComponent={OCRNoteInput}
        enabledOCR={enableOCR && fieldSettings.reasonNote.enabledOCR}
        ocrProperty="ReasonNote"
        confirmedOCR={!isNewUpload}
        ocrResult={ocrResult}
        label={fieldLabels.reasonNote}
        maxLength={500}
        required={fieldSettings.reasonNote.required}
      />
      )}
      {referralFormLayoutSetting === "Layout2" && (
      <>
        {fieldSettings?.meetsRequirements && currentAssignedToSpecialty && (
          <>
            <ReferralRequirementsPreview specialtyProfileId={currentAssignedToSpecialty?.value} />
            <Field
              name="referral.meetsRequirements"
              component={CheckboxInputValidationAware}
              label={fieldLabels.meetsRequirements}
              showHelperText
              required={fieldSettings.meetsRequirements.required}
            />
          </>
        )}
        {fieldSettings?.reasonForReferralCode && (
          <Field
            name="referral.reasonForReferralCodeId"
            component={CodeSetValueSelector}
            label={fieldLabels.reasonForReferralCode}
            codeSetTypeCode={fieldSettings.reasonForReferralCode.fieldName}
            required={fieldSettings.reasonForReferralCode.required}
            icon="label_important"
            allowHideHealthLinkOnly
            allowHideOnForms
          />
        )}
        {fieldSettings?.reasonNote && (
          <Field
            name="referral.reasonNote"
            component={OCRFieldComponent}
            FieldComponent={NoteInput}
            OCRComponent={OCRNoteInput}
            enabledOCR={enableOCR && fieldSettings.reasonNote.enabledOCR}
            ocrProperty="ReasonNote"
            confirmedOCR={!isNewUpload}
            ocrResult={ocrResult}
            label={fieldLabels.reasonNote}
            maxLength={500}
            required={fieldSettings.reasonNote.required}
          />
        )}
      </>
      )}
      {referralFormLayoutSetting === "Layout1" && fieldSettings?.meetsRequirements && currentAssignedToSpecialty && (
        <>
          <ReferralRequirementsPreview specialtyProfileId={currentAssignedToSpecialty?.value} />
          <Field
            name="referral.meetsRequirements"
            component={CheckboxInputValidationAware}
            label={fieldLabels.meetsRequirements}
            showHelperText
            required={fieldSettings.meetsRequirements.required}
          />
        </>
      )}
      {fieldSettings?.presentingComplaintCodes && (
      <>
        <Field
          name="referral.presentingComplaintCodes"
          component={SpecialtyCodeSetSelector}
          icon={<FaceSickIcon />}
          label={fieldLabels.presentingComplaintCodes}
          required={fieldSettings.presentingComplaintCodes.required}
          codeSetTypeCode={fieldSettings.presentingComplaintCodes.fieldName}
          specialtyId={currentAssignedToSpecialty?.value}
          disabled={!allowSpecialtyReassignment}
          multiple={multiplePresentingComplaint}
          multiline
          limitTags={multiplePresentingComplaint}
          isTermSelector
          onChange={selectedValues => {
            if (isEnablePresentingComplaintWarning) {
              const valueArray = [].concat(selectedValues);
              const selectedCodes = map(valueArray, val => val?.value);
              const isExistPresentComplianceCode = some(patientPresentingComplaints, x => includes(selectedCodes, x.code)
                                  && (isEmpty(referral?.referralId) || x.referralId !== referral?.referralId));
              setPresentingComplaintWarning(isExistPresentComplianceCode);
            }
          }}
          allowHideOnForms
        />
        {showPresentingComplaintWarning && <Alert severity="warning">{referralWorklistSettings?.patientPresentingComplaintWarningMessage}</Alert> }
      </>
      )}
      {fieldSettings?.presentingComplaintNote && (
        <Field
          name="referral.presentingComplaintNote"
          component={OCRFieldComponent}
          FieldComponent={NoteInput}
          OCRComponent={OCRNoteInput}
          enabledOCR={enableOCR && fieldSettings.presentingComplaintNote.enabledOCR}
          ocrProperty="PresentingComplaintNote"
          confirmedOCR={!isNewUpload}
          ocrResult={ocrResult}
          rows={15}
          maxLength={5000}
          icon={<FaceSickIcon />}
          label={fieldLabels.presentingComplaintNote}
          required={fieldSettings.presentingComplaintNote.required}
        />
      )}
      {fieldSettings?.relevantPatientHistoryNote && (
        <Field
          name="referral.relevantPatientHistoryNote"
          component={NoteInput}
          maxLength={500}
          label={fieldLabels.relevantPatientHistoryNote}
          required={fieldSettings.relevantPatientHistoryNote.required}
        />
      )}
      {fieldSettings?.referrerAdvisedPriority && (
        <Field
          name="referral.referrerAdvisedPriorityCodeId"
          component={CodeSetValueSelector}
          codeSetTypeCode={fieldSettings.referrerAdvisedPriority.fieldName}
          label={fieldLabels.referrerAdvisedPriority}
          required={fieldSettings.referrerAdvisedPriority.required}
          icon={<PriorityIcon />}
          allowHideOnForms //TODO: Determine if this component should have allowHideHealthLinkOnly
        />
      )}
      {fieldSettings?.interpreterRequired && (
        <div className="relative">
          <div className="flex flex-col sm:flex-row">
            <Field
              name="referral.interpreterRequired"
              component={CheckboxInput}
              onChange={value => {
                if (value === false) {
                  setFieldValue("referral.preferredLanguage", null);
                }
              }}
              label={fieldLabels.interpreterRequired}
              showHelperText={false}
              icon="language"
            />
            <Field
              showIcon={false}
              disabled={!referral.interpreterRequired}
              name="referral.preferredLanguage"
              component={TextInput}
              label={fieldLabels.preferredLanguage}
              required={referral.interpreterRequired === true}
              showHelperText={false}
            />
          </div>
          <div className="ml-32 absolute bottom-0">
            <HelperText error helperText={errors?.referral?.interpreterRequired || errors?.referral?.interpreterRequired} />
          </div>
        </div>
      )}
      {fieldSettings?.nok && !isEmptyPatient && (
        <div>
          <Typography className="ml-32 font-bold" gutterBottom>{fieldLabels.nok}</Typography>
          <div className="relative">
            <div className="flex sm:items-center">
              <div className="flex-auto flex flex-col sm:flex-row">
                <Field
                  name="referral.nok.givenName"
                  component={TextInput}
                  label="Given Name"
                  icon="people"
                  required={fieldSettings.nok.required}
                />
                <Field
                  name="referral.nok.familyName"
                  component={TextInput}
                  label="Family Name"
                  required={fieldSettings.nok.required}
                />
                <Field
                  name="referral.nok.contact"
                  component={TextInput}
                  label="Contact"
                  required={fieldSettings.nok.required}
                />
              </div>
              <IconButton
                title="Remove"
                icon={<RemoveAccount />}
                onClick={() => {
                  setFieldValue("referral.nok.givenName", null);
                  setFieldValue("referral.nok.familyName", null);
                  setFieldValue("referral.nok.contact", null);
                }}
              />
            </div>
            <div className="ml-32 absolute bottom-0">
              <HelperText error helperText={errors?.referral?.nok?.isValid || errors?.referral?.nok} />
            </div>
          </div>
        </div>
      )}
    </Section>
  );
};
