import React from "react";
import { get, isEmpty, find } from "lodash";
import { Typography } from "@material-ui/core";
import { useSelector } from "react-redux";

import formatDate from "helpers/format-date";
import DefaultPanelLayout from "components/items/default-panel-layout";
import EmptyState from "components/items/empty-state";
import TreatmentRequestForm from "app/main/applications/components/treatment-request-form";
import ApplicationPanelContent from "app/main/applications/components/application-panel-content";
import { ApplicationItemWithWidth, ApplicationItemTextArea } from "app/main/applications/components/application-item";
import formatDaysFromNow from "helpers/format-days-from-now";
import { TreatmentIcon } from "helpers/icon-finder";
import ApplicationNoteContent from "app/main/applications/components/application-note-content";
import { isSectionCompleted } from "app/main/applications/reducers/applications.reducers";
import { getDecisionStatusValues } from "app/auth/store/reducers/system-configuration";
import UrgentIcon, { UrgentIconItem } from "app/main/applications/components/urgent-icon";
import useOpenConfigForm from "hooks/use-open-config-form";

const TreatmentPanel = ({
  id,
  closeDialog,
  onSaveApplication,
  openDialog,
  canView,
  canEdit,
  canCreate,
  ...other
}) => {
  const isEdit = id !== null;
  const showIsRequired = useSelector(state => isSectionCompleted(state, "treatment"));
  const decisionStatusValue = useSelector(state => getDecisionStatusValues(state));

  const {
    applicationDate,
    medicationDoseAndFrequency,
    indication,
    additionalIndicationDetails,
    requestedMedication,
    submittedDateTimeUtc,
    rationale,
    relevantTreatmentSoFar,
    urgentTreatmentApprovalGranted,
    urgentApproval,
    decision,
    expectedOutcomeMeasures,
    status,
    cost,
    costPer,
    hasTreatmentCommenced,
    safetyConcerns,
    requestedDuration,
    compassionateAccess,
    priorApproval,
    treatmentDurationUnit,
    treatmentDurationValue,
    treatmentDurationTypeDescription,
    anticipatedImpactOnPrognosis,
    relevantMedicalHistory,
    patientToxicity,
    transitionPlan,
    transitionPlanPaediatric,
    urgencyOfTreatmentRationale,
    urgencyTreatmentTypeDescription,
    nursingConsiderationRationale,
    nursingConsiderations,
    application,
    treatmentIntentDescription,
    medicationAccessProgramOrArrangementDescription,
    medicationSponsorCostContribution,
    prescriberDeclaration,
  } = other;

  const onSucceed = () => {
    closeDialog();
    onSaveApplication();
  };

  const openTreatmentForm = title => {
    openDialog({
      maxWidth: "md",
      children: (
        <TreatmentRequestForm
          onSucceed={onSucceed}
          title={title}
          submitLabel="Save"
          id={id}
          {...other}
        />
      ),
    });
  };

  const { openConfigForm, loadingConfigurations } = useOpenConfigForm(application?.orgUnitId, "CreateEditTreatment", openTreatmentForm);

  const onCreate = () => {
    openConfigForm("Add Treatment Details");
  };

  const onUpdate = () => {
    openConfigForm("Edit Treatment Details");
  };

  const getDecidedOnContent = () => {
    if (!isEmpty(decision) && !isEmpty(decision.decisionDate)) {
      const decisionStatus = find(decisionStatusValue, x => x.value === decision.status);
      return `${formatDate(decision.decisionDate)} (${get(decisionStatus, "label", "")})`;
    }

    return "Decision Pending";
  };

  return (
    <DefaultPanelLayout
      title="Treatment Request"
      icon={<TreatmentIcon />}
      isEmpty={!isEdit}
      emptyState={canEdit || (status === "New" && canCreate)
        ? <EmptyState icon={<TreatmentIcon />} subtitle="Add Treatment Request" onClick={() => onCreate()} />
        : <EmptyState icon={<TreatmentIcon />} subtitle="Unable to add treatment to application" />}
      accessDenied={!canView}
      headerActions={(canEdit || (status === "New" && canCreate))
        && (!isEdit
          ? [
            {
              label: "add Treatment Request",
              icon: "add",
              onClick: () => onCreate(),
              loading: loadingConfigurations,
            },
          ] : [
            {
              label: "Edit",
              icon: "edit",
              onClick: () => onUpdate(),
              loading: loadingConfigurations,
            },
          ])}
      status={showIsRequired && <Typography color="error">*</Typography>}
    >
      <div className="flex items-center mb-8 flex-wrap">
        <Typography variant="h6" className="font-bold mr-8" color="primary">{get(requestedMedication, "drug")}</Typography>
        {requestedMedication && Object.prototype.hasOwnProperty.call(requestedMedication, "strength") && <Typography variant="body2" className="mr-4">{get(requestedMedication, "strength")}</Typography>}
        <Typography variant="body2">{get(requestedMedication, "form")}</Typography>
      </div>
      <ApplicationPanelContent>
        <>
          {requestedMedication && Object.prototype.hasOwnProperty.call(requestedMedication, "brand") && <ApplicationItemWithWidth label="Medication Brand" content={get(requestedMedication, "brand")} renderIfEmpty />}
          {Object.prototype.hasOwnProperty.call(application, "medicationDoseAndFrequency") && <ApplicationItemWithWidth label="Dose and Frequency" content={medicationDoseAndFrequency} renderIfEmpty />}
          {requestedMedication && Object.prototype.hasOwnProperty.call(requestedMedication, "medicationFrequency") && <ApplicationItemWithWidth label="Medication Frequency" content={get(requestedMedication, "medicationFrequency")} renderIfEmpty />}
          {requestedMedication && Object.prototype.hasOwnProperty.call(requestedMedication, "medicationDose") && <ApplicationItemWithWidth label="Medication Dose" content={get(requestedMedication, "medicationDose")} renderIfEmpty />}
          {Object.prototype.hasOwnProperty.call(application, "treatmentDurationType") && <ApplicationItemWithWidth label="Treatment Duration Type" content={treatmentDurationTypeDescription} renderIfEmpty />}
          {Object.prototype.hasOwnProperty.call(application, "treatmentDurationValue") && Object.prototype.hasOwnProperty.call(application, "treatmentDurationUnit")
            && <ApplicationItemWithWidth label="Treatment Duration" content={(treatmentDurationValue && treatmentDurationUnit) ? `${treatmentDurationValue} ${treatmentDurationUnit}` : null} renderIfEmpty />}
          {Object.prototype.hasOwnProperty.call(application, "cost") && cost && <ApplicationItemWithWidth label="Cost" content={`$${cost}${costPer ? ` per ${costPer}` : ""}`} />}
          {requestedMedication && Object.prototype.hasOwnProperty.call(requestedMedication, "route") && <ApplicationItemWithWidth label="Route" content={get(requestedMedication, "route")} renderIfEmpty />}
          {requestedMedication && Object.prototype.hasOwnProperty.call(requestedMedication, "smfApprovedIndications") && <ApplicationItemWithWidth label="SMF Approved Indication" content={get(requestedMedication, "smfApprovedIndications") ? "Yes" : "No"} />}
          {requestedMedication && Object.prototype.hasOwnProperty.call(requestedMedication, "smfApprovedIndicationsComments") && <ApplicationItemWithWidth label="SMF Approved Indication Comments" content={get(requestedMedication, "smfApprovedIndicationsComments")} />}
          {requestedMedication && Object.prototype.hasOwnProperty.call(requestedMedication, "tgaApprovedMedication") && <ApplicationItemWithWidth label="TGA Approved Medication" content={get(requestedMedication, "tgaApprovedMedication") ? "Yes" : "No"} />}
          {requestedMedication && Object.prototype.hasOwnProperty.call(requestedMedication, "tgaLicencedIndication") && <ApplicationItemWithWidth label="TGA Approved Indication" content={get(requestedMedication, "tgaLicencedIndication") ? "Yes" : "No"} />}
          {requestedMedication && Object.prototype.hasOwnProperty.call(requestedMedication, "pbsApprovedIndication") && <ApplicationItemWithWidth label="PBS Approved Indication" content={get(requestedMedication, "pbsApprovedIndication") ? "Yes" : "No"} />}
          {requestedMedication && Object.prototype.hasOwnProperty.call(requestedMedication, "pbsApprovedIndicationComments") && <ApplicationItemWithWidth label="PBS Approved Indication Comments" content={get(requestedMedication, "pbsApprovedIndicationComments")} />}
          {Object.prototype.hasOwnProperty.call(application, "nursingConsiderations") && <ApplicationItemTextArea label="Nursing Considerations" content={nursingConsiderations ? "Yes" : "No"} />}
          {Object.prototype.hasOwnProperty.call(application, "nursingConsiderationRationale") && <ApplicationItemTextArea label="Nursing Considerations Rationale" content={nursingConsiderationRationale && <ApplicationNoteContent>{nursingConsiderationRationale}</ApplicationNoteContent>} />}
          {Object.prototype.hasOwnProperty.call(application, "medicationAccessProgramOrArrangement") && <ApplicationItemTextArea label="Medication Access Program Or Arrangement" content={medicationAccessProgramOrArrangementDescription && <ApplicationNoteContent>{medicationAccessProgramOrArrangementDescription}</ApplicationNoteContent>} />}
          {Object.prototype.hasOwnProperty.call(application, "medicationSponsorCostContribution") && <ApplicationItemTextArea label="Medication Sponsor Cost Contribution" content={medicationSponsorCostContribution && `$${medicationSponsorCostContribution?.toFixed(2)}`} />}
          {Object.prototype.hasOwnProperty.call(application, "prescriberDeclaration") && <ApplicationItemTextArea label="Prescriber Declaration" content={prescriberDeclaration ? "Yes" : "No"} />}
          {Object.prototype.hasOwnProperty.call(application, "compassionateAccess") && <ApplicationItemWithWidth label="Compassionate Access" content={compassionateAccess ? "Yes" : "No"} />}
          {Object.prototype.hasOwnProperty.call(application, "priorApproval") && <ApplicationItemWithWidth label="Prior Approval" content={priorApproval ? "Yes" : "No"} />}
          {Object.prototype.hasOwnProperty.call(application, "hasTreatmentCommenced") && <ApplicationItemWithWidth label="Has Treatment Commenced?" content={hasTreatmentCommenced ? "Yes" : "No"} renderIfEmpty />}
          {Object.prototype.hasOwnProperty.call(application, "requestedDuration") && <ApplicationItemWithWidth label="Requested Duration" content={requestedDuration} renderIfEmpty />}
        </>
        <>
          <ApplicationItemWithWidth icon="date_range" label="Created on" content={applicationDate && `${formatDate(applicationDate)} (${formatDaysFromNow(applicationDate)})`} />
          <ApplicationItemWithWidth
            icon="date_range"
            label="Submitted on"
            content={submittedDateTimeUtc
              ? `${formatDate(submittedDateTimeUtc)} (${formatDaysFromNow(submittedDateTimeUtc)})`
              : "Application not yet submitted"}
          />
          <ApplicationItemWithWidth
            icon="date_range"
            label="Decided on"
            content={getDecidedOnContent()}
          />
          {(urgentApproval || urgentTreatmentApprovalGranted) && <UrgentIconItem show />}
          {Object.prototype.hasOwnProperty.call(application, "urgencyTreatmentTypeDescription") && <ApplicationItemWithWidth icon={<UrgentIcon iconOnly show />} label="Urgency Treatment Type" content={urgencyTreatmentTypeDescription} />}
          {Object.prototype.hasOwnProperty.call(application, "urgencyOfTreatmentRationale") && <ApplicationItemWithWidth icon={<UrgentIcon iconOnly show />} label="Urgency Of Treatment Rationale" content={urgencyOfTreatmentRationale} />}
        </>
      </ApplicationPanelContent>
      <div className="items-container with-horizontal-divider md">
        {Object.prototype.hasOwnProperty.call(application, "indication") && <ApplicationItemTextArea label="Indication" content={indication?.indicationTerm} renderIfEmpty />}
        {Object.prototype.hasOwnProperty.call(application, "additionalIndicationDetails") && <ApplicationItemTextArea label="Additional Indication Details" content={additionalIndicationDetails} />}
        {Object.prototype.hasOwnProperty.call(application, "relevantMedicalHistory") && <ApplicationItemTextArea label="Relevant Medical History" content={relevantMedicalHistory && <ApplicationNoteContent>{relevantMedicalHistory}</ApplicationNoteContent>} />}
        {Object.prototype.hasOwnProperty.call(application, "relevantTreatmentSoFar") && <ApplicationItemTextArea label="Relevant Treatments Trialled" content={relevantTreatmentSoFar && <ApplicationNoteContent>{relevantTreatmentSoFar}</ApplicationNoteContent>} />}
        {Object.prototype.hasOwnProperty.call(application, "treatmentIntent") && <ApplicationItemTextArea label="Treatment Intent" content={treatmentIntentDescription && <ApplicationNoteContent>{treatmentIntentDescription}</ApplicationNoteContent>} />}
        {Object.prototype.hasOwnProperty.call(application, "rationale") && <ApplicationItemTextArea label="Supporting Evidence" content={rationale && <ApplicationNoteContent>{rationale}</ApplicationNoteContent>} />}
        {Object.prototype.hasOwnProperty.call(application, "safetyConcerns") && <ApplicationItemTextArea label="Safety Concerns" content={safetyConcerns && <ApplicationNoteContent>{safetyConcerns}</ApplicationNoteContent>} />}
        {Object.prototype.hasOwnProperty.call(application, "expectedOutcomeMeasures") && <ApplicationItemTextArea label="Expected Outcome Measures / Goals of Therapy" content={expectedOutcomeMeasures && <ApplicationNoteContent>{expectedOutcomeMeasures}</ApplicationNoteContent>} />}
        {Object.prototype.hasOwnProperty.call(application, "anticipatedImpactOnPrognosis") && <ApplicationItemTextArea label="Anticipated Impact on Prognosis" content={anticipatedImpactOnPrognosis && <ApplicationNoteContent>{anticipatedImpactOnPrognosis}</ApplicationNoteContent>} />}
        {Object.prototype.hasOwnProperty.call(application, "patientToxicity") && <ApplicationItemTextArea label="Possible Toxicity in This Patient" content={patientToxicity && <ApplicationNoteContent>{patientToxicity}</ApplicationNoteContent>} />}
        {Object.prototype.hasOwnProperty.call(application, "transitionPlan") && <ApplicationItemTextArea label="Transition Plan" content={transitionPlan && <ApplicationNoteContent>{transitionPlan}</ApplicationNoteContent>} />}
        {Object.prototype.hasOwnProperty.call(application, "transitionPlanPaediatric") && <ApplicationItemTextArea label="Transition Plan Paediatric" content={transitionPlanPaediatric && <ApplicationNoteContent>{transitionPlanPaediatric}</ApplicationNoteContent>} />}
      </div>
    </DefaultPanelLayout>
  );
};

export default TreatmentPanel;
