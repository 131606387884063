import React from "react";
import { useDispatch } from "react-redux";
import { includes, startCase } from "lodash";
import { Box } from "@material-ui/core";
import IconButton from "components/items/icon-button";
import ImageSearchOutlineIcon from "mdi-react/ImageSearchOutlineIcon";
import TextBoxSearchOutlineIcon from "mdi-react/TextBoxSearchOutlineIcon";
import { openDialog } from "app/store/actions/dialog.actions";
import ReferralAttachmentPreviewDialog from "app/main/referrals/components/referral-attachment-preview-dialog";
import ActivityLogItem from "components/items/activity-log/item";
import { RemovedAttachmentIcon } from "helpers/icon-finder";

const AttachmentItem = ({ referralId, orgUnitId, activity }) => {
  const dispatch = useDispatch();

  const openPreviewDialog = attachment => dispatch(openDialog({
    disableBackdropClick: false,
    children: (
      <ReferralAttachmentPreviewDialog
        title="Preview Attachment"
        file={attachment}
        referralId={referralId}
        referralDocumentId={attachment.referralDocumentId}
        orgUnitId={orgUnitId}
        disabledHeaderActions
      />
    ),
  }));

  const title = () => (
    <div className="h-48 flex-row-container with-gutter">
      <Box fontWeight="fontWeightBold">{startCase(activity.type)}</Box>
      {activity.detail?.canPreview && (
      <IconButton
        title="Preview"
        iconSize={24}
        icon={includes(activity.detail.mimeType, "image") ? <ImageSearchOutlineIcon /> : <TextBoxSearchOutlineIcon />}
        onClick={() => openPreviewDialog(activity.detail)}
      />
      )}
    </div>
  );

  return (
    <ActivityLogItem
      icon={activity.type === "addAttachment" ? "attachment" : <RemovedAttachmentIcon />}
      title={title()}
      content={activity.detail?.fileName}
      date={activity.detail?.sortDateTimeLocalised}
      user={activity.detail?.user}
      id={activity.detail?.id}
    />
  );
};

export default AttachmentItem;
