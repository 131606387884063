import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import AccountSearchOutlineIcon from "mdi-react/AccountSearchOutlineIcon";

import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import PatientSearchForm from "app/main/patients/components/external-patient-search-button/patient-search-form";
import { fetchPatientSearchResults } from "app/main/patients/actions/patientsSearch.actions";

const PatientSearchButton = ({ patientPresetFilter, onContinue, onCancel, buttonProps }) => {
  const dispatch = useDispatch();

  const openPatientSearchFrom = () => {
    dispatch(openDialog({
      maxWidth: "md",
      children: (
        <PatientSearchForm
          title="Search PAS"
          onClose={() => (onCancel ? onCancel() : dispatch(closeDialog()))}
          onContinue={onContinue}
          patientPresetFilter={patientPresetFilter}
          searchApi={fetchPatientSearchResults}
        />
      ),
    }));
  };

  return (
    <DefaultButton
      label="Search PAS"
      icon={<AccountSearchOutlineIcon />}
      onClick={openPatientSearchFrom}
      {...buttonProps}
    />
  );
};

PatientSearchButton.propTypes = {
  onContinue: PropTypes.func.isRequired,
};

export default PatientSearchButton;
