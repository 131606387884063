import React from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { PatientDOBLabel, NameLabel, TextLabel, NextOfKin, PatientIdentifierLabel, AddressDetailsLabel, EmailLabel, ContactDetailsLabel, SMSConsentLabel, UnmergeWarningLabel, DeceasedLabel } from "app/main/patients/components/patient-details";
import { getNationalityLabel, getSexLabel, enableSMSConsentLabelDisplay, getEnablePrimaryIdentifierBannerDisplay, getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";

/**
 * This component will share between referral worklist and patient referral worklist
 */
const ReferralPatientDetails = ({
  patient,
  goToPatientRecordPage,
  identifierProps,
}) => {
  const nationalityLabel = useSelector(getNationalityLabel);
  const displaySMAConsentLabel = useSelector(enableSMSConsentLabelDisplay);
  const displayPrimaryIdentifierBanner = useSelector(getEnablePrimaryIdentifierBannerDisplay);
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const sexLabel = useSelector(getSexLabel);

  if (!patient) return <Typography>{referralWorklistSettings?.noPatientAssignedMessage}</Typography>;

  const { nextOfKin, birthDate, sex, isDead, patientId, age, patientIdentifiers, address, contactDetails, displayNameFull, givenName, middleName, familyName, title, nationality, smsConsent, patientCards } = patient;

  const newPatientName = `${title ? `${title} ` : ""}${givenName} ${middleName ? `${middleName} ` : ""} ${familyName}`;

  return (
    <div>
      <div className="flex flex-col items-container">
        <div className="flex-row-container with-gutter">
          <NameLabel name={displayNameFull ?? newPatientName} onClick={goToPatientRecordPage} />
          <PatientDOBLabel age={age} birthDate={birthDate} />
          <TextLabel className="font-bold" label={sexLabel} content={sex?.label ?? sex ?? ""} />
          <DeceasedLabel isDead={isDead} />
          {displayPrimaryIdentifierBanner
            && (
              <PatientIdentifierLabel
                patientId={patientId}
                showIcon={false}
                onlyPrimaryIdentifier
                patientIdentifiers={patientIdentifiers}
                {...identifierProps}
                patient={patient}
                patientCards={patientCards}
              />
            )}
          <UnmergeWarningLabel requiresUnmerge={patient.requiresUnmerge} />
        </div>
        <PatientIdentifierLabel patientId={patientId} patientIdentifiers={patientIdentifiers} {...identifierProps} showIcon patient={patient} />
        <TextLabel className="font-bold" label={nationalityLabel} content={nationality} />
      </div>
      <div className="flex-row-container with-gutter">
        <AddressDetailsLabel address={address} />
        <EmailLabel email={!isEmpty(contactDetails) ? contactDetails.email : null} />
        <ContactDetailsLabel contact={contactDetails} />
        {displaySMAConsentLabel && <SMSConsentLabel smsConsent={smsConsent} />}
        <NextOfKin patientId={patientId} nokFormReferral nok={nextOfKin} />
      </div>
    </div>
  );
};

export default ReferralPatientDetails;
