import React from "react";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { draftReferralAction } from "app/main/referrals/actions/referrals.actions";
import Form from "components/form";
import ReferralWarningMessage from "./referral-warning-message-alert";

const message = "Mark this referral as draft?";
const title = "Update Referral";

const DraftReferralDialog = ({
  onSucceed,
  warningMessages,
  referralId,
  orgUnitId,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = ({ draftReferral }, { setErrors }) => {
    dispatch(draftReferralAction(orgUnitId, draftReferral.referralId)).then(responds => {
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const initialValues = {
    draftReferral: {
      referralId,
    },
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      contentProps={{ title, submitLabel: "Confirm" }}
      variant="filled"
      content={() => (
        <>
          <ReferralWarningMessage warningMessages={warningMessages} />
          <Typography>{message}</Typography>
        </>
      )}
    />
  );
};

export default DraftReferralDialog;
