import useFormFieldConfig from "hooks/use-form-field-config";

const useNotificationConfigurationFieldSettings = (orgUnitId, forceLoad) => {
  const { loadingFieldSettings, fieldSettings, loadedFieldSettings } = useFormFieldConfig(orgUnitId, "NotificationConfiguration", forceLoad);

  const fieldLabels = {
    sendToInterestedParties: fieldSettings?.isUrgent?.label || "Send To Interested Parties",
    sendToApplicant: fieldSettings?.episodeHealthCondition?.label || "Send To Applicant",
    sendToApprover: fieldSettings?.note?.label || "Send To Approver",
    sendToUserId: fieldSettings?.clinic?.label || "Send To User Id",
    sendToAssignedRoleUsers: fieldSettings?.clinicType?.label || "Send To Assigned Role Users",
    sendToPatients: fieldSettings?.externalTriageStatus?.label || "Send To Patients",
    sendToReferralCreator: fieldSettings?.externalTriageStatus?.label || "Send To Referral Creator",
    sendToTaskAssigned: fieldSettings?.externalTriageStatus?.label || "Send To Task Assigned",
    sendToEndorsementTypes: fieldSettings?.externalTriageStatus?.label || "Send To Endorsement Types",
    sendToSpecialtyRoles: fieldSettings?.externalTriageStatus?.label || "Send To Specialty Roles",
    notificationDelayMinutes: fieldSettings?.externalTriageStatus?.label || "Notification Delay Minutes",
    emailSubject: fieldSettings?.externalTriageStatus?.label || "Email Subject",
    emailBody: fieldSettings?.externalTriageStatus?.label || "Email Body",
    mobileBody: fieldSettings?.externalTriageStatus?.label || "Mobile Body",
    sentToDirectEmailAddresses: fieldSettings?.externalTriageStatus?.label || "Sent To Direct Email Address",
  };

  return ({
    loadingFieldSettings,
    fieldSettings,
    fieldLabels,
    loadedFieldSettings,
  });
};

export default useNotificationConfigurationFieldSettings;
