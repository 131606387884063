export function getBaseUrl() {
  return process.env.REACT_APP_API_BASE_URL;
}

export function getDefaultRoute() {
  return process.env.REACT_APP_DEFAULT_ROUTE;
}

export function getPatientLabel() {
  return {
    single: process.env.REACT_APP_PATIENT_LABEL_SINGLE,
    plural: process.env.REACT_APP_PATIENT_LABEL_PLURAL,
  };
}

export function getLogo() {
  return process.env.REACT_APP_MAIN_LOGO;
}

export function getLoginMessage() {
  return process.env.REACT_APP_LOGIN_MESSAGE;
}

export function getCurrentThemeValue() {
  return process.env.REACT_APP_CURRENT_THEME;
}

export function getIPAWorklistTitle() {
  return {
    single: process.env.REACT_APP_IPA_WORKLIST_SINGLE ?? "Individual Patient Application",
    plural: process.env.REACT_APP_IPA_WORKLIST_PLURAL ?? "Individual Patient Applications",
  };
}

export function getSignalRHubAddress() {
  return process.env.REACT_APP_SIGNALR_HUB_ADDRESS;
}

export function isSignalREnabled() {
  return process.env.REACT_APP_SIGNALR_ENABLED === "TRUE" || process.env.REACT_APP_SIGNALR_ENABLED === "true";
}

export function getDraggableDialogs() {
  return process.env.REACT_APP_DRAGGABLE_DIALOGS;
}
