import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { map } from "lodash";
import { fetchOrgUnitsPharmacyForSelector } from "app/main/orgUnits/actions/orgUnit.actions";

import { AutoComplete } from "components/inputs";

export const SiteSelector = ({
  currentSite,
  initialOptions = [],
  onChange,
  ...others
}) => {
  const [options, setOptions] = useState(initialOptions || []);

  useEffect(() => {
    // Filter out the currentSite from the options
    const filteredOptions = initialOptions.filter(option => option.id !== currentSite.id);
    setOptions(filteredOptions);
  }, [initialOptions, currentSite]);

  return (
    <AutoComplete
      options={map(options, x => ({ label: x.name, value: x.id }))}
      onChange={onChange}
      getOptionSelected={(option, val) => option.value === val.value}
      {...others}
    />
  );
};

export const PharmacySelector = ({
  selectedSite,
  onChange,
  ...others
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState(null); // Initialize options as null
  useEffect(() => {
    if (!selectedSite || !selectedSite.id) {
      setOptions(null);
      setLoading(false);

      return;
    }

    setLoading(true);
    const pharmacyOrgUnitsType = ["Pharmacy"];
    dispatch(fetchOrgUnitsPharmacyForSelector({ orgUnitTypes: pharmacyOrgUnitsType, currentOrgUnitId: selectedSite.id, showDeprecated: false }))
      .then(response => {
        setOptions(response.payload.orgUnits);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
      });
  }, [dispatch, selectedSite]);

  return (
    <AutoComplete
      options={map(options, x => ({ label: x.name, value: x.id }))}
      loading={loading}
      onChange={onChange}
      {...others}
    />
  );
};
