import { get, isEmpty, uniq, filter, map, includes, mapKeys, find } from "lodash";
import moment from "moment";
import curryConnector from "utils/connectors";
import { ActionTypes as UserActionTypes } from "app/main/users/reducers/users.reducers";

export const CURRENT_USER_STATE_KEY = "auth";

export const ActionTypes = {
  LOADING_USER_DATA: "LOADING_USER_DATA",
  ERROR_LOADING_USER_DATA: "ERROR_LOADING_USER_DATA",
  LOADED_USER_DATA: "LOADED_USER_DATA",

  REMOVE_USER_DATA: "REMOVE_USER_DATA",
  USER_LOGGED_OUT: "USER_LOGGED_OUT",

  SAVED_USER_PROFILE: "SAVED_USER_PROFILE",
  UPDATE_REFERRAL_FORM_PROFILE: "UPDATE_REFERRAL_FORM_PROFILE",

  LOGGING_IN: "LOGGING_IN",
  LOGGED_IN: "LOGGED_IN",
  ERROR_LOGGING_IN: "ERROR_LOGGING_IN",
  LOGGED_OUT: "LOGGED_OUT",

  CHANGED_CURRENT_ORG_UNIT: "CHANGED_CURRENT_ORG_UNIT",
  AUTH_ERROR: "AUTH_ERROR",
};

const initialState = {
  user: {},
  userPermissions: {},
  loading: false,
  error: null,
  loaded: false,
  hasAuthenticated: false,
  authenticating: false,
  signedInOrgUnit: {},
  authenticationMessage: null, // fail authentication
};

const defaultState = {
  role: "guest",
  name: "John Doe",
  photoURL: "assets/images/avatars/Velazquez.jpg",
  email: "johndoe@withinpixels.com",
};

const curry = fn => curryConnector(fn, CURRENT_USER_STATE_KEY);

const updateLoginUser = (state, action) => {
  const loginUserId = get(state.user, "userId");
  const updatedUserId = get(action.payload.user, "userId");
  if (updatedUserId === loginUserId) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  return state;
};

export default ((state, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_USER_DATA:
      return { ...state, loading: true, loaded: false, error: false };

    case ActionTypes.ERROR_LOADING_USER_DATA:
      return { ...state, loading: false, loaded: false, error: action.payload.message };

    case ActionTypes.LOADED_USER_DATA:
      return {
        ...state,
        loading: false,
        error: null,
        loaded: true,
        authenticationMessage: null,
        loadedDateTimeUtc: moment.utc(),
        user: action.payload.user,
        userPermissions: action.payload.userPermissions,
        signedInOrgUnit: action.payload.siteConfiguration,
        drugSearchDisabled: action.payload.drugSearchDisabled,
        enableCIRS: action.payload.enableCIRS,
        orgUnitFormSettings: {
          allReferralFormProfiles: { ...mapKeys(action.payload.allReferralFormProfiles, x => x.id) },
          defaultOrgUnitFormFields: { ...mapKeys(action.payload.defaultOrgUnitFormFields, x => x.formType) },
        },
      };
    case ActionTypes.UPDATE_REFERRAL_FORM_PROFILE:
      return {
        ...state,
        orgUnitFormSettings: {
          ...state.orgUnitFormSettings,
          referralFormProfiles: {
            ...state.orgUnitFormSettings.referralFormProfiles,
            [action.payload.referralFormProfile.id]: action.payload.referralFormProfile,
          },
        },
      };
    case ActionTypes.SAVED_USER_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          email: action.payload.user.email,
          phoneMobile: action.payload.user.phoneMobile,
          notificationPreference: action.payload.user.notificationPreference,
        },
      };
    case UserActionTypes.SAVED_USER:
      return updateLoginUser(state, action);
    case ActionTypes.REMOVE_USER_DATA:
      return { ...state, user: defaultState };
    case ActionTypes.USER_LOGGED_OUT:
    case ActionTypes.LOGGED_OUT:
      return initialState;
    case ActionTypes.LOGGING_IN:
      return { ...state, hasAuthenticated: false, authenticating: true };
    case ActionTypes.LOGGED_IN:
      return { ...state, hasAuthenticated: true, authenticating: false };
    case ActionTypes.ERROR_LOGGING_IN:
      return { ...state, hasAuthenticated: false, authenticating: false };
    case ActionTypes.CHANGED_CURRENT_ORG_UNIT:
      return {
        ...state,
        signedInOrgUnit: action.payload.siteConfiguration,
        drugSearchDisabled: action.payload.drugSearchDisabled,
        enableCIRS: action.payload.enableCIRS,
      };
    case ActionTypes.AUTH_ERROR:
      return { ...state, authenticationMessage: action.payload };
    default:
      return state || initialState;
  }
});

export const isCurrentUserLoading = curry(({ localState }) => get(localState.user, ["loading"], false) === true);

export const isCurrentUserLoadingError = curry(({ localState }) => get(localState.user, ["error"], null));

export const isCurrentUserLoaded = curry(({ localState }) => get(localState.user, ["loaded"], null) === true);

export const hasAuthenticated = curry(({ localState }) => get(localState.user, ["hasAuthenticated"], null) === true);
export const isAuthenticating = curry(({ localState }) => get(localState.user, ["authenticating"], null) === true);

export const getCurrentUserloadedDateTimeUtc = curry(({ localState }) => get(localState.user, ["loadedDateTimeUtc"], null));

export const getUser = curry(({ localState }) => get(localState.user, ["user"], {}));

export const getUserPermissions = curry(({ localState }) => get(localState.user, ["userPermissions"], []));

export const getSignedInOrgUnit = curry(({ localState }) => get(localState.user, ["signedInOrgUnit"], null));

export const getCurrentUserRoles = curry(({ localState }) => get(localState.user, ["user", "userRoles"], []));

// Checks that the current user has the required permission
export const checkPermission = curry(({ state }, requiredPermission) => {
  // If no permission is provided, then return unauthorized
  if (isEmpty(requiredPermission)) {
    return false;
  }
  const signedInOrgUnitId = getSignedInOrgUnit(state)?.id;
  const currentUserRoleIds = map(filter(getCurrentUserRoles(state), x => x.orgUnitId === signedInOrgUnitId), x => x.roleId);
  const userPermissions = map(filter(getUserPermissions(state), x => includes(currentUserRoleIds, x.roleId)), x => x.permission);

  // If the user has no permissions, then they can't do anything
  if (isEmpty(userPermissions) || Object.values(userPermissions).length === 0) {
    return false;
  }

  const authorised = Object.values(userPermissions).includes(requiredPermission);

  return authorised;
});

export const getActiveOrgUnitIds = curry(({ state }) => {
  const currentUserRoles = getCurrentUserRoles(state);
  return uniq(map(filter(currentUserRoles, r => !r.isOrgUnitDeprecated), x => x.orgUnitId));
});

export const isDrugSearchDisabled = curry(({ localState }) => get(localState.user, ["drugSearchDisabled"], false) === true);

export const isEnableCIRS = curry(({ localState }) => get(localState.user, ["enableCIRS"], false) === true);

export const getAutError = curry(({ localState }) => {
  // preformat message
  let message = localState.user?.authenticationMessage;

  if (includes(message, "UNKNOWN_USER_ERROR")) {
    message = "User is not registered in the system, or the account is inactive";
  }
  return message;
});

export const getEnabledMedicationAccessProgramOrArrangementIds = curry(({ localState }) => get(localState.user, ["signedInOrgUnit", "enabledMedicationAccessProgramOrArrangementIds"], []));

export const getReferralFormProfilesByOrgUnitId = curry(({ localState }, orgUnitId) => {
  const allReferralFormProfiles = get(localState.user, ["orgUnitFormSettings", "allReferralFormProfiles"], null);
  const currentSiteReferralFormProfiles = filter(allReferralFormProfiles, x => x.orgUnitId === orgUnitId);

  return currentSiteReferralFormProfiles;
});

export const getDefaultReferralFormProfile = curry(({ state }, orgUnitId) => {
  const currentSiteReferralFormProfiles = getReferralFormProfilesByOrgUnitId(state, orgUnitId);
  const defaultReferralFormProfile = find(currentSiteReferralFormProfiles, x => x.isDefault);

  return defaultReferralFormProfile;
});

export const getDefaultOrgUnitFormFieldConfig = curry(({ localState }) => get(localState.user, ["orgUnitFormSettings", "defaultOrgUnitFormFields"], null));

export const getReferralFormProfilesById = curry(({ localState }, referralFormProfileId) => {
  if(referralFormProfileId === null) return null;
  
  const allReferralFormProfiles = get(localState.user, ["orgUnitFormSettings", "allReferralFormProfiles"], null);
  const currentSiteReferralFormProfiles = find(allReferralFormProfiles, x => x.id === referralFormProfileId);

  return currentSiteReferralFormProfiles;
});

export const getEnableFormTypes  = curry(({ state }) => {
  const defaultOrgUnitFormFields = getDefaultOrgUnitFormFieldConfig(state);
  const enableFormTypes = map(defaultOrgUnitFormFields, x => x.formType);
  console.log('defaultOrgUnitFormFields', defaultOrgUnitFormFields, 'enableFormTypes', enableFormTypes)
  return enableFormTypes;
});

