import React from "react";
import { connect } from "react-redux";
import PatientOverviewPage from "app/main/patients/components/patient-overview-page";
import { getPatientsSummaryById } from "app/main/patients/reducers/patients.reducers";
import { getLastEncounter } from "app/main/patients/reducers/encounters.reducers";
import { getNextEncounter } from "app/main/patients/reducers/appointments.reducers";

const PatientSummaryPage = ({
  patientId,
  orgUnitId,
  patientSummary,
  lastEncounter,
  nextEncounter,
}) => (
  <PatientOverviewPage
    patientId={patientId}
    orgUnitId={orgUnitId}
    lastEncounter={lastEncounter}
    nextEncounter={nextEncounter}
    diseaseStates={patientSummary.diseaseStates}
  />
);

const mapStateToProps = (state, props) => {
  const { patientId, orgUnitId } = props;
  return {
    patientId,
    orgUnitId,
    patientSummary: getPatientsSummaryById(state, patientId),
    lastEncounter: getLastEncounter(state, patientId),
    nextEncounter: getNextEncounter(state, patientId),
  };
};

export default connect(
  mapStateToProps,
  {},
)(PatientSummaryPage);
