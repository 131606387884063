export function getLinkLabelPrefix(status, encounterType = null) {
  switch (status) {
    case "Booked":
      switch (encounterType) {
        case "ConsentCall":
          return "Record Call";
        case "ConsentVisit":
          return "Record visit";
        default:
          return "Record Encounter";
      }
    case "InProgress":
      switch (encounterType) {
        case "ConsentCall":
          return "Continue Consent Call";
        case "ConsentVisit":
          return "Continue Consent Visit";
        case "InitialAssessment":
          return "Continue Initial Assessment";
        case "FollowUpAssessment":
          return "Continue Follow Up Assessment";
        default:
          return "Continue";
      }
    case "Completed":
      return "view";
    default:
      return null;
  }
}
