import { get, mapKeys, map, find, findKey, findIndex } from "lodash";
import curryConnector from "utils/connectors";
import { defaultSearchParams } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import { ActionTypes as ReferralActionTypes } from "app/main/referrals/reducers/referrals.reducers";

export const PATIENT_REFERRALS_STATE_KEY = "patientReferrals";
export const REFERRALS_PAGE_SIZE = 10;
const curry = fn => curryConnector(fn, PATIENT_REFERRALS_STATE_KEY);

export const ActionTypes = {
  LOADING_PATIENT_REFERRALS: "LOADING_PATIENT_REFERRALS",
  LOADED_PATIENT_REFERRALS: "LOADED_PATIENT_REFERRALS",
  ERROR_LOADING_PATIENT_REFERRALS: "ERROR_LOADING_PATIENT_REFERRALS",

  LOADING_PATIENT_ACTIVE_REFERRALS: "LOADING_PATIENT_ACTIVE_REFERRALS",
  LOADED_PATIENT_ACTIVE_REFERRALS: "LOADED_PATIENT_ACTIVE_REFERRALS",
  ERROR_LOADING_PATIENT_ACTIVE_REFERRALS: "ERROR_LOADING_PATIENT_ACTIVE_REFERRALS",

  RESET_PATIENT_REFERRALS_SEARCH: "RESET_PATIENT_REFERRALS_SEARCH",
  SET_DEFAULT_PATIENT_REFERRALS_SEARCH_PARAMS: "SET_DEFAULT_PATIENT_REFERRALS_SEARCH_PARAMS",

  LOADING_PATIENT_SPECIALTY_PRESENTING_COMPLAINTS: "LOADING_PATIENT_SPECIALTY_PRESENTING_COMPLAINTS",
  LOADED_PATIENT_SPECIALTY_PRESENTING_COMPLAINTS: "LOADED_PATIENT_SPECIALTY_PRESENTING_COMPLAINTS",
  ERROR_LOADING_PATIENT_SPECIALTY_PRESENTING_COMPLAINTS: "ERROR_LOADING_PATIENT_SPECIALTY_PRESENTING_COMPLAINTS",

  UPDATE_UPDATE_PATIENT_REFERRAL_FILTER_TAGS: "UPDATE_UPDATE_PATIENT_REFERRAL_FILTER_TAGS",
  UPDATE_PATIENT_REFERRAL_STATUS: "UPDATE_PATIENT_REFERRAL_STATUS",

  UPDATE_PATIENT_REFERRAL_AFTER_EDIT: "UPDATE_PATIENT_REFERRAL_AFTER_EDIT",
  UPDATE_PATIENT_REFERRAL_PRESENTING_COMPLAINTS_AFTER_EDIT: "UPDATE_PATIENT_REFERRAL_PRESENTING_COMPLAINTS_AFTER_EDIT",
};

const INITIAL_STATE = {
  all: {},
  searchParams: defaultSearchParams,
  patientPresentingComplaints: {},
};

const findReferralInAllReferralState = (state, referralId) => {
  const matchingPatientId = findKey(state.all, o => get(o, ["all", referralId, "id"], null) === referralId);
  if (matchingPatientId === undefined) return null;
  return { patientId: matchingPatientId, referralId };
};

const findReferralInActiveReferralState = (state, referralId) => {
  const matchingPatientId = findKey(state.all, o => findIndex(get(o, "actives.referrals", []), ref => ref.id === referralId) !== -1);
  if (matchingPatientId === undefined) return null;
  return { patientId: matchingPatientId, activeReferralIndex: findIndex(get(state.all, [matchingPatientId, "actives.referrals"], []), ref => ref.id === referralId), referralId };
};

const updateReferralInState = (existingReferral, newReferralData) => {
  if (existingReferral.referralStatus !== newReferralData.status) {
    return {
      ...existingReferral,
      referralStatusUpdated: true,
      referralStatusUpdatedBy: newReferralData.userDisplayName,
      referralStatus: newReferralData.status,
    };
  }

  return existingReferral;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ReferralActionTypes.REFERRAL_STATUS_CHANGED_BY_OTHER_USER:
      let newState = state;
      const referralDetails = findReferralInAllReferralState(state, action.detail.referralId);
      if (referralDetails !== null) {
        newState = {
          ...newState,
          all: {
            ...newState.all,
            [referralDetails.patientId]: {
              ...newState.all[referralDetails.patientId],
              all: {
                ...newState.all[referralDetails.patientId].all,
                [action.detail.referralId]: updateReferralInState(newState.all[referralDetails.patientId].all[action.detail.referralId], action.detail),
              },
            },
          },
        };
      }
      const activeReferralDetails = findReferralInActiveReferralState(state, action.detail.referralId);
      if (activeReferralDetails !== null) {
        newState = {
          ...newState,
          all: {
            ...newState.all,
            [activeReferralDetails.patientId]: {
              ...newState.all[activeReferralDetails.patientId],
              actives: {
                ...newState.all[activeReferralDetails.patientId].actives,
                referrals: map(newState.all[activeReferralDetails.patientId].actives?.referrals, x => {
                  if (x.id === action.detail.referralId) return updateReferralInState(x, action.detail);
                  return x;
                }),
              },
            },
          },
        };
      }
      return newState;
    case ActionTypes.LOADING_PATIENT_REFERRALS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            pages: {
              ...state.all[action.payload.patientId]?.pages,
              [action.payload.pageNumber]: { loading: true, loaded: false, error: false, orgUnitId: action.payload.orgUnitId },
            },
            pageInfo: { ...state.all[action.payload.patientId]?.pageInfo, pageNumber: action.payload.pageNumber },
          },
        },
        searchParams: { patientId: action.payload.patientId, ...state.searchParams },
      };
    case ActionTypes.UPDATE_PATIENT_REFERRAL_STATUS: {
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            all: find(state.all[action.payload.patientId]?.all, x => x.id === action.payload?.id) != null ? {
              ...state.all[action.payload.patientId]?.all,
              [action.payload?.id]: {
                ...state.all[action.payload.patientId].all[action.payload?.id],
                referralStatus: action.payload.referralStatus,
              },
            } : state.all[action.payload.patientId]?.all,
            actives: find(state.all[action.payload.patientId]?.actives?.referrals, x => x.id === action.payload?.id) != null ? {
              ...state.all[action.payload.patientId]?.actives,
              referrals: map(state.all[action.payload.patientId]?.actives?.referrals, x => {
                if (x.id === action.payload?.id) {
                  return {
                    ...x,
                    referralStatus: action.payload.referralStatus,
                  };
                }
                return x;
              }),
            } : state.all[action.payload.patientId]?.actives,
          },
        },
        searchParams: { patientId: action.payload.patientId, ...state.searchParams },
      };
    }
    case ActionTypes.UPDATE_PATIENT_REFERRAL_AFTER_EDIT: {
      const { patientId, referralId, referral } = action.payload;

      return {
        ...state,
        all: {
          ...state.all,
          [patientId]: {
            ...state.all[patientId],
            all: {
              ...state.all[patientId]?.all,
              [referralId]: {
                ...state.all[patientId]?.all[referralId],
                referralDateUtc: referral.referralDateUtc,
                referralNumber: referral.referralNumber,
                referralFrom: referral.referralFrom,
                referringOrgUnitName: referral.referringOrgUnitName,
                presentingComplaintSummary: referral.presentingComplaintSummary,
                presentingComplaintNote: referral.presentingComplaintNote,
                assignedTo: {
                  ...state.all[patientId]?.all[referralId]?.assignedTo,
                  assignedToSpecialtyName: referral.assignedTo?.assignedToSpecialtyName,
                },
              },
            },
          },
        },
      };
    }
    case ActionTypes.UPDATE_PATIENT_REFERRAL_PRESENTING_COMPLAINTS_AFTER_EDIT: {
      const { patientId, referralId, presentingComplaintSummary } = action.payload;

      return {
        ...state,
        all: {
          ...state.all[patientId],
          all: {
            ...state.all[patientId]?.all,
            [referralId]: {
              ...state.all[patientId]?.all[referralId],
              presentingComplaintSummary,
            },
          },
        },
      };
    }
    case ActionTypes.LOADED_PATIENT_REFERRALS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            all: mapKeys(action.payload.referrals, x => x.id),
            pages: {
              ...state.all[action.payload.patientId]?.pages,
              [action.payload.pageNumber]: { loading: false, error: false, loaded: true, orgUnitId: action.payload.orgUnitId, ids: map(action.payload.referrals, x => x.id) },
            },
            pageInfo: action.payload.pageInfo,
          },
        },
        searchParams: { patientId: action.payload.patientId, ...action.payload.searchParams },
      };
    case ActionTypes.ERROR_LOADING_PATIENT_REFERRALS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            pages: {
              ...state.all[action.payload.patientId]?.pages,
              [action.payload.pageNumber]: { loading: false, error: action.payload.message, loaded: false, orgUnitId: action.payload.orgUnitId },
            },
          },
        },
      };
    case ActionTypes.LOADING_PATIENT_ACTIVE_REFERRALS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            actives: { loading: true },
          },
        },
      };
    case ActionTypes.LOADED_PATIENT_ACTIVE_REFERRALS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            actives: {
              loading: false,
              loaded: true,
              error: null,
              referrals: action.payload.referrals,
            },
          },
        },
      };
    case ActionTypes.ERROR_LOADING_PATIENT_ACTIVE_REFERRALS:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]: {
            ...state.all[action.payload.patientId],
            actives: { loading: false, loaded: false, error: action.payload.message },
          },
        },
      };
    case ActionTypes.RESET_PATIENT_REFERRALS_SEARCH:
      return {
        ...state,
        searchParams: defaultSearchParams,
      };
    case ActionTypes.SET_DEFAULT_PATIENT_REFERRALS_SEARCH_PARAMS:
      return {
        ...state,
        searchParams: { ...defaultSearchParams, ...action.payload },
      };
    case ActionTypes.LOADING_PATIENT_SPECIALTY_PRESENTING_COMPLAINTS:
      return {
        ...state,
        patientPresentingComplaints: {
          ...state.patientPresentingComplaints,
          meta: { loading: true, loaded: false, error: null },
        },
      };
    case ActionTypes.LOADED_PATIENT_SPECIALTY_PRESENTING_COMPLAINTS:
      return {
        ...state,
        patientPresentingComplaints: {
          [action.payload.patientId]: {
            all: action.payload.patientPresentingComplaints,
          },
          meta: { loading: false, loaded: true, error: null },
        },
      };
    case ActionTypes.ERROR_LOADING_PATIENT_SPECIALTY_PRESENTING_COMPLAINTS:
      return {
        ...state,
        patientPresentingComplaints: {
          ...state.patientPresentingComplaints,
          meta: { loading: false, loaded: false, error: action.payload.message },
        },
      };
    case ActionTypes.UPDATE_UPDATE_PATIENT_REFERRAL_FILTER_TAGS:
      return {
        ...state,
        searchTags: action.payload,
      };
    default:
      return state;
  }
};

export const getReferralsForPatient = curry(({ localState }, patientId) => {
  const data = get(localState, ["all", patientId], null);
  if (!data?.all) return null;
  const pageNumber = data.pageInfo?.pageNumber || 1;
  return map(get(data.pages, [pageNumber, "ids"], []), key => data.all[key]);
});

export const isPageLoading = curry(({ localState }, orgUnitId, patientId, pageNumber) => {
  const data = get(localState, ["all", patientId], null);
  const number = pageNumber || get(data?.pageInfo, ["pageNumber"], 1);
  const info = get(data, ["pages", number]);
  return info?.orgUnitId === orgUnitId && info?.loading;
});

export const isPageLoaded = curry(({ localState }, orgUnitId, patientId, pageNumber) => {
  const data = get(localState, ["all", patientId], null);
  const number = pageNumber || get(data?.pageInfo, ["pageNumber"], 1);
  const info = get(data, ["pages", number]);
  return info?.orgUnitId === orgUnitId && info?.loaded;
});

export const getErrorMessage = curry(({ localState }, patientId, pageNumber) => {
  const data = get(localState, ["all", patientId], null);
  const number = pageNumber || get(data?.pageInfo, ["pageNumber"], 1);
  return get(data, ["pages", number, "error"], null);
});

export const getPatientReferralPageInfo = curry(({ localState }, patientId) => get(localState, ["all", patientId, "pageInfo"], null));
export const getPatientReferralSearchParams = curry(({ localState }) => get(localState, ["searchParams"], null));
export const isPatientActiveReferralsLoading = curry(({ localState }, patientId) => {
  const actives = get(localState, ["all", patientId, "actives"]);
  return actives?.loading;
});
export const isPatientActiveReferralsLoaded = curry(({ localState }, patientId) => {
  const actives = get(localState, ["all", patientId, "actives"]);
  return actives?.loaded;
});
export const getPatientActiveReferrals = curry(({ localState }, patientId) => get(localState, ["all", patientId, "actives", "referrals"], null));
export const isPatientPresentingComplaintsLoading = curry(({ localState }) => get(localState, ["patientPresentingComplaints", "meta", "loading"], false));
export const getPatientPresentingComplaints = curry(({ localState }, patientId) => get(localState, ["patientPresentingComplaints", patientId, "all"], null));

export const getPatientReferralSearchTags = curry(({ localState }) => get(localState, ["searchTags"], null));
