import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Box } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  root: "w-100 enable-shrink",
  icon: {
    margin: theme.spacing(0, 1),
  },
}));

export default function DeceasedLabel({
  isDead = false,
}) {
  const classes = useStyles();
  if (!isDead) return null;

  return (
    <Box className={clsx("flex md:items-center", classes?.root)}>
      <Typography className="font-bold" variant="body1" color="error">DECEASED</Typography>
    </Box>
  );
}
