import React from "react";
import Typography from "@material-ui/core/Typography";
import { Tooltip } from "@material-ui/core";
import { toDOBFormat } from "app/main/patients/helpers/format-dob";

export default function PatientDOBLabel({
  birthDate,
  variant,
  age,
  className,
  ...other
}) {
  if (!birthDate && !age) return null;

  return (
    <Tooltip title={age ?? ""}>
      <Typography variant={variant || "body1"} className={className || "font-bold"} {...other}>
        {toDOBFormat(birthDate)}
      </Typography>
    </Tooltip>
  );
}
