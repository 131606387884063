import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { find, isEqual, trim } from "lodash";

import AdvancedFilter, { FilterForm, FilterSection } from "components/items/advanced-filter";
import { TextInput, CheckboxInput, SelectInput, MultipleCheckbox } from "components/inputs";
import DateRange from "components/inputs/date-range";
import { AscendingIcon, DescendingIcon } from "helpers/icon-finder";
import formatFilterDate from "helpers/format-filter-date";

import { fetchPatientMergeLogResults, resetPatientMergeLogsFilters } from "../actions/patientMerge.actions";
import { getPageInfo, getSearchParams, DefaultSearchParams } from "../reducers/patientMerge.reducers";

const statusOptions = [
  { value: "New", label: "New" },
  { value: "Failed", label: "Failed" },
  { value: "InProgress", label: "In Progress" },
  { value: "Completed", label: "Completed" },
  { value: "LoggedOnly", label: "Logged Only" },
];

const sortOptions = [
  { label: "Created Date - Oldest First", value: "CreatedDateAsc", icon: <AscendingIcon /> },
  { label: "Created Date - Newest First", value: "CreatedDateDesc", icon: <DescendingIcon /> },
];

export default () => {
  const dispatch = useDispatch();
  const pageInfo = useSelector(getPageInfo);
  const params = useSelector(getSearchParams);
  const { pageSize } = pageInfo;

  const onSearch = searchText => {
    dispatch(fetchPatientMergeLogResults(1, pageSize, { ...params, name: trim(searchText) }));
  };

  const searchPatientMergeLogs = searchParams => dispatch(fetchPatientMergeLogResults(1, pageSize, searchParams));

  const handleSubmit = ({ searchParams }) => {
    const { createdStartDate, createdEndDate } = searchParams;

    const newSearchParams = {
      ...searchParams,
      createdStartDate: formatFilterDate(createdStartDate),
      createdEndDate: formatFilterDate(createdEndDate),
    };

    searchPatientMergeLogs(newSearchParams);
  };

  const handleReset = () => {
    dispatch(resetPatientMergeLogsFilters()).then(searchPatientMergeLogs(DefaultSearchParams));
  };

  const searchParams = {
    ...params,
    createdDateRange: [params.createdStartDate, params.createdEndDate],
  };

  return (
    <AdvancedFilter
      onSearch={onSearch}
      searchValue={params?.name}
      filterContentAnchor="right"
      withPaperWrapper={false}
      hasFilterValue={!isEqual(DefaultSearchParams, params)}
      content={onClose => (
        <FilterForm
          initialValues={{ searchParams }}
          onSubmit={handleSubmit}
          onReset={handleReset}
          onClose={onClose}
          filters={({ setFieldValue, values }) => {
            const sortByIcon = find(sortOptions, x => x.value === values.searchParams.sortBy)?.icon;
            return (
              <div>
                <FilterSection withDivider>
                  <Field
                    label="Name"
                    name="searchParams.name"
                    component={TextInput}
                    icon="search"
                  />
                </FilterSection>
                <FilterSection withDivider label="Sort by">
                  <Field
                    name="searchParams.sortBy"
                    component={SelectInput}
                    options={sortOptions}
                    icon={sortByIcon}
                  />
                </FilterSection>
                <FilterSection withDivider label="Created Date">
                  <Field
                    name="searchParams.createdDateRange"
                    component={DateRange}
                    onChange={value => {
                      if (value) {
                        setFieldValue("searchParams.createdStartDate", value[0]);
                        setFieldValue("searchParams.createdEndDate", value[1]);
                      } else {
                        setFieldValue("searchParams.createdStartDate", null);
                        setFieldValue("searchParams.createdEndDate", null);
                      }
                    }}
                  />
                </FilterSection>
                <FilterSection label="Status" withDivider>
                  <Field
                    name="searchParams.status"
                    component={MultipleCheckbox}
                    options={statusOptions}
                  />
                </FilterSection>
                <FilterSection>
                  <Field
                    name="searchParams.showManualOnly"
                    label="Show manual only"
                    component={CheckboxInput}
                    showIcon={false}
                  />
                </FilterSection>
              </div>
            );
          }}
        />
      )}
    />
  );
};
