import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, find, some } from "lodash";
import AdvancedFilter, { FilterTags, FilterSort } from "components/items/advanced-filter";
import { getUser, getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import { isReferralWorklistLoading, getFilterOptions, getSearchTags, IsEnableClinicalRoleReferralActiveView, getSortTitle, IsEnableReferralWorklistDefaultMyReferrals } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import DefaultSelectionFilter from "components/items/default-selection-filter";
import { updateReferralWorkListFilter, resetReferralWorklistFilter, clearReferralWorkListFilterTags } from "app/main/referralWorklist/actions/referralWorklist.actions";
import ReferralAdvancedFilterForm from "app/main/referralWorklist/components/referral-advanced-filter-form";
import translateReferralSearchParams from "app/main/referralWorklist/helpers/translate-search-params";
import getDefaultWorkListFilterOption from "helpers/get-default-worklist-filter-option";
import { getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";

const ReferralAdvancedFilter = ({
  searchParams,
  onSearch,
  presetFilter,
  referralStatusChoices,
  triageCategoryChoices,
  referralWorkflowSettings,
  triageCategorySettings,
  initialValues,
  triageFieldSettings,
  triageFieldLabels,
  rejectFieldSettings,
  rejectFieldLabels,
  referralFieldSettings,
  referralFieldLabels,
  ...other
}) => {
  const dispatch = useDispatch();
  const filterOptions = useSelector(getFilterOptions);
  const user = useSelector(getUser);
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const currentUserRole = find(user.userRoles, x => x.orgUnitId === signedInOrgUnit.id);
  const tags = useSelector(getSearchTags);
  const isEnableClinicalRoleReferralActiveView = useSelector(IsEnableClinicalRoleReferralActiveView);
  const isEnableReferralWorklistDefaultMyReferrals = useSelector(IsEnableReferralWorklistDefaultMyReferrals);
  const defaultWorkListSearchFilterRoleTypeSettings = referralWorklistSettings?.defaultWorkListSearchFilterRoleTypeSettings;
  const defaultType = getDefaultWorkListFilterOption(defaultWorkListSearchFilterRoleTypeSettings, currentUserRole, isEnableClinicalRoleReferralActiveView, isEnableReferralWorklistDefaultMyReferrals);
  const loading = useSelector(isReferralWorklistLoading);
  const lastSelectReferralSearchOption = localStorage.getItem("lastSelectReferralSearchOption");
  const [isSearching, setIsSearching] = useState(false);
  const selectedAsDefault = localStorage.getItem("shouldLoadLastReferralSelectSearchParams") !== false && !isEmpty(lastSelectReferralSearchOption) && some(filterOptions, x => x.value === lastSelectReferralSearchOption) ? lastSelectReferralSearchOption : defaultType;

  const handleSubmit = ({ referralFilters }) => {
    const newReferralFilters = translateReferralSearchParams(referralFilters);
    localStorage.setItem("lastSelectReferralSearchOption", "advancedFilter");
    const newParams = { ...searchParams, ...newReferralFilters };
    dispatch(updateReferralWorkListFilter("advancedFilter", true));
    onSearch(newParams, null, null, true);
  };

  const handleReset = () => dispatch(resetReferralWorklistFilter(defaultType)).then(res => {
    dispatch(clearReferralWorkListFilterTags());
    const params = translateReferralSearchParams(res.payload.searchParams);
    localStorage.removeItem("lastSelectReferralSearchOption");
    onSearch(params, null, null, true);
  });

  const onSearchText = text => {
    const params = translateReferralSearchParams(searchParams);
    onSearch({ ...params, term: text }, null, null, true);
  };

  const onUpdateFilter = filterValue => {
    localStorage.setItem("shouldLoadLastReferralSelectSearchParams", true);
    dispatch(updateReferralWorkListFilter(filterValue)).then(res => {
      onSearch(res, null, null, true);
    });
  };

  const handleSortOrderChange = () => {
    setIsSearching(true);
    const params = translateReferralSearchParams(searchParams);
    onSearch({ ...params, sortOrderDescending: !searchParams.sortOrderDescending }, null, null, true).then(() => setIsSearching(false));
  };

  useEffect(() => {
    handleReset();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signedInOrgUnit]);

  return (
    <AdvancedFilter
      {...other}
      onSearch={searchText => onSearchText(searchText)}
      searchValue={searchParams?.term ?? ""}
      renderFilterTags={<FilterTags tags={tags} />}
      isDisabledAdvancedSearch={loading}
      renderPresetFilter={({ openAdvancedFilter }) => (
        <div className="mb-8">
          <DefaultSelectionFilter
            filterValue={presetFilter}
            options={filterOptions}
            loadLastSelectedAsDefault={selectedAsDefault}
            onChange={
              filterValue => {
                if (!loading && filterValue && presetFilter !== filterValue) {
                  localStorage.setItem("lastSelectReferralSearchOption", filterValue);
                  if (filterValue === "advancedFilter") {
                    openAdvancedFilter();
                  } else {
                    onUpdateFilter(filterValue);
                  }
                }
              }
            }
            renderExtraActions={(
              <FilterSort
                sortType="date"
                sortOrder={searchParams?.sortOrderDescending ? "desc" : "asc"}
                onClick={handleSortOrderChange}
                sortDescendingLabel={getSortTitle(searchParams?.sortBy, true)}
                sortAscending={getSortTitle(searchParams?.sortBy, false)}
                isLoading={isSearching}
              />
            )}
          />
        </div>
      )}
      content={onClose => (
        <ReferralAdvancedFilterForm
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          onClose={onClose}
          referralStatusChoices={referralStatusChoices}
          triageCategoryChoices={triageCategoryChoices}
          referralWorkflowSettings={referralWorkflowSettings}
          triageCategorySettings={triageCategorySettings}
          triageFieldSettings={triageFieldSettings}
          triageFieldLabels={triageFieldLabels}
          rejectFieldSettings={rejectFieldSettings}
          rejectFieldLabels={rejectFieldLabels}
          referralFieldSettings={referralFieldSettings}
          referralFieldLabels={referralFieldLabels}
          orgUnitId={signedInOrgUnit.id}
        />
      )}
    />
  );
};

export default ReferralAdvancedFilter;
