import React from "react";
import { withTheme } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

import { AssignedUserIcon, AssignedSpecialtyIcon } from "helpers/icon-finder";
import ActivityLogItem from "components/items/activity-log/item";
import { isEmpty, truncate } from "lodash";

const getContentMessage = activity => {
  if (!activity.id) {
    return "Marked as Unassigned";
  }

  let assignedString = activity.assignedBy ? "Assigned to " : "Auto-Assigned to ";

  assignedString += activity.assignedToDisplayName ?? activity.assignedTo?.assignedToDisplayName;

  return assignedString;
};

const getIcon = (activity, iconColor) => {
  if (!activity.id) {
    return "remove";
  }
  return activity.assignmentType === "Specialty" ? <AssignedSpecialtyIcon color={iconColor} /> : <AssignedUserIcon color={iconColor} />;
};

const AssignmentItem = ({ activity, theme: { palette: { primary } } }) => {
  const reason = truncate(activity.reason ? `Reason: ${activity.reason}` : undefined, { length: 260 });
  let content;
  if (!isEmpty(activity.assignedFromOrgUnitName)) {
    content = (
      <div>
        <Typography variant="body2">{`Moved From: ${activity.assignedFromOrgUnitName}`}</Typography>
        <Typography variant="body2">{reason}</Typography>
      </div>
    );
  } else {
    content = reason;
  }

  return (
    <ActivityLogItem
      icon={getIcon(activity, primary[500])}
      title={getContentMessage(activity)}
      content={content}
      truncatedContent={truncate(content, { length: 260 })}
      date={activity.createdDateLocalised}
      user={activity.isSystemAssignment ? "System" : activity.assignedBy}
    />
  );
};

export default withTheme(AssignmentItem);
