import React from "react";
import { Field } from "formik";
import ArrowRightCircleOutlineIcon from "mdi-react/ArrowRightCircleOutlineIcon";
import { Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import DisabledInput from "components/inputs/components/disabled-input";
import BaseInputField from "components/inputs/components/base-input-component";
import IconButton from "components/items/icon-button";
import { validateRequired } from "utils/validators";

const useStyle = makeStyles(theme => ({
  divider: {
    backgroundColor: theme.palette.error.main,
  },
  icon: {
    color: theme.palette.error.main,
  },
}));

// input will accept initial value
const SpecialtyInputField = ({
  setting,
  label,
  selectorName,
  defaultValue,
  onUnlock,
  locked,
  ...other
}) => {
  const classes = useStyle();

  if (!setting) return null;

  if (locked) {
    return (
      <BaseInputField
        classes={{ icon: classes.icon }}
        required={setting.required}
        icon="error"
        renderExtraAction={<IconButton icon={<ArrowRightCircleOutlineIcon />} onClick={onUnlock} title={`Update ${label}`} />}
      >
        <DisabledInput label={label} value={defaultValue} classes={{ icon: classes.icon, input: "h-auto" }} />
        <Divider className={classes.divider} />
      </BaseInputField>
    );
  }

  return (
    <div className="flex">
      <Field
        className="flex-1"
        label={label}
        required={setting.required}
        validate={setting.required ? value => validateRequired(label)(value) : null}
        {...other}
      />
      <div className="w-32" />
    </div>
  );
};

export default SpecialtyInputField;
