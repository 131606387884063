import React from "react";
import TabContent from "components/items/tab-content";
import ReferralSummaryPanel from "app/main/referralWorklist/components/referral-summary-panel";
import ReferralActivityPanel from "app/main/referralWorklist/components/referral-activity-panel";
import { AccessDeniedState } from "components/items/empty-state";

export default ({
  referral,
  orgUnitId,
}) => (
  <TabContent>
    {referral?.permissions.canView
      ? (
        <>
          <ReferralSummaryPanel referral={referral} orgUnitId={orgUnitId} />
          <ReferralActivityPanel referral={referral} orgUnitId={orgUnitId} isLast />
        </>
      ) : <AccessDeniedState title="You do not have the correct permission to view this referral" />}
  </TabContent>
);
