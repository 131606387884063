import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { map, startCase, isEmpty } from "lodash";
import { areSpecialtyRolesLoading } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchSpecialtyRoles } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { AssignedSpecialtyIcon } from "helpers/icon-finder";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";

export default ({
  label,
  specialtyIds,
  orgUnitId,
  showIcon = true,
  isAssignment = true,
  ...other
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const loading = useSelector(areSpecialtyRolesLoading);
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);

  useEffect(() => {
    if (!isEmpty(specialtyIds) && !loading) {
      setOptions([]);
      dispatch(fetchSpecialtyRoles(signedInOrgUnit?.id, specialtyIds, orgUnitId, isAssignment)).then(response => {
        if (response) {
          setOptions(response.error ? [] : response.payload.specialtyRoles);
        } else {
          setOptions([]);
        }
      });
    } else {
      setOptions([]);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(specialtyIds), orgUnitId]);

  if (loading) return <AutoCompleteLoading label={label} />;

  return (
    <AutoComplete
      icon={showIcon && <AssignedSpecialtyIcon />}
      label={label}
      options={map(options, x => ({ label: x.roleDescription ?? startCase(x.roleName), value: x.roleId }))}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      {...other}
    />
  );
};
