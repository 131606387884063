export const acceptActionType = "Accept";
export const rejectActionType = "Reject";
export const completeActionType = "Complete";
export const archiveActionType = "Archive";
export const requestMoreInformationActionType = "RequestMoreInformation";
export const printActionType = "Print";
export const cancelActionType = "Cancel";
export const editActionType = "Edit";
export const requestActionType = "Request";
export const processActionType = "Process";
export const draftActionType = "Draft";
export const urgentActionType = "Urgent";
export const notUrgentActionType = "NotUrgent";
