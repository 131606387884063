export const rejectedWorkflowState = "Rejected";
export const acceptedWorkflowState = "Accepted";
export const archivedWorkflowState = "Archived";
export const requestedWorkflowState = "Requested";
export const completedWorkflowState = "Completed";
export const cancelledWorkflowState = "Cancelled";
export const draftWorkflowState = "Draft";
export const processingWorkflowState = "Processing";
export const acceptedBookedWorkflowState = "AcceptedBooked";
export const requestMoreInformationWorkflowState = "AwaitingInformation";

