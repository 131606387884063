import React from "react";
import { FlagOff, Flag } from "helpers/icon-finder";
import ActivityLogItem from "components/items/activity-log/item";
import useActionTypeItem from "./useActionTypeItem";
import Acknowledgement from "./acknowledgement";

const UrgentStatusItem = ({ orgUnitId, activity }) => {
  const { title, status } = useActionTypeItem({ orgUnitId, activity });

  return (
    <ActivityLogItem
      date={activity.createdDateLocalised}
      user={activity?.actionedBy}
      renderLeftContent={<Acknowledgement acknowledgement={activity.acknowledgement} dateTime={activity.acknowledgementDateTimeUtc} />}
      icon={activity.isUrgent ? <Flag style={{ color: status.color }} /> : <FlagOff style={{ color: status.color }} />}
      activeColor={status.color}
      title={title}
      content={activity.message}
    />
  );
};

export default UrgentStatusItem;
