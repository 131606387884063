import { isEmpty, find } from "lodash";

const getDefaultWorkListFilterOption = (defaultWorkListSearchFilterRoleTypeSettings, currentUserRole, isEnableClinicalRoleReferralActiveView, isEnableReferralWorklistDefaultMyReferrals) => {
  let currentActiveView = "active";
  if (isEnableClinicalRoleReferralActiveView) {
    currentActiveView = "clinicalRoleActiveView";
  } else if (isEnableReferralWorklistDefaultMyReferrals) {
    currentActiveView = "myReferrals";
  }

  if (isEmpty(defaultWorkListSearchFilterRoleTypeSettings)) return currentActiveView;
  let defaultType = null;
  if ((!isEmpty(defaultWorkListSearchFilterRoleTypeSettings) && find(defaultWorkListSearchFilterRoleTypeSettings, x => x.roleName === currentUserRole.roleName))) {
    defaultType = find(defaultWorkListSearchFilterRoleTypeSettings, x => x.roleName === currentUserRole.roleName).defaultWorkListSearchFilterType;
  }

  return !isEmpty(defaultType) ? defaultType[0].toLowerCase() + defaultType.slice(1) : currentActiveView;
};

export default getDefaultWorkListFilterOption;
