import React from "react";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { processReferralAction } from "app/main/referrals/actions/referrals.actions";
import Form from "components/form";
import ReferralWarningMessage from "./referral-warning-message-alert";

const ProcessReferralDialog = ({
  onSucceed,
  warningMessages,
  referralId,
  orgUnitId,
  actionLabel,
  title,
}) => {
  const dispatch = useDispatch();

  const handleSubmit = ({ referral }, { setErrors }) => {
    dispatch(processReferralAction(orgUnitId, referral?.referralId)).then(response => {
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{ referral: { referralId } }}
      initialValue={referralId}
      contentProps={{ title, submitLabel: "Confirm" }}
      variant="filled"
      content={() => (
        <>
          <ReferralWarningMessage warningMessages={warningMessages} />
          <Typography>{`Mark this referral as ${actionLabel}?`}</Typography>
        </>
      )}
    />
  );
};

export default ProcessReferralDialog;
