import moment from "moment-timezone";

import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { ActionTypes, bailOutOfLoadingPatientReferrals } from "../reducers/patientReferralWorklist.reducers";

export const updatePatientReferralWorklistSearchParams = params => ({
  type: ActionTypes.UPDATE_PATIENT_REFERRAL_WORKLIST_SEARCH_PARAMS,
  payload: params,
});

export const resetPatientReferralWorklistResults = () => ({
  type: ActionTypes.RESET_PATIENT_REFERRAL_WORKLIST_RESULTS,
});

export const searchPatientReferrals = (searchParams, pageNumber = 1, pageSize = 20, forceLoad = false) => (dispatch, getState) => {
  const params = formatSearchParams(searchParams);
  const timeZone = moment.tz.guess();
  const url = `/patientReferral?pageNumber=${pageNumber}&pageSize=${pageSize}&timeZone=${timeZone}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      bailout: !forceLoad && bailOutOfLoadingPatientReferrals(getState(), pageNumber),
      entityType: "PATIENT_REFERRAL_WORKLIST",
      pageNumber,
    },
  });
};
