
export const getFieldLabels = fieldSettings => ({
  orgUnitId: "Referred to Site",
  referralDate: fieldSettings?.referralDate?.label || "Referral Date",
  typeOfReferral: fieldSettings?.typeOfReferral?.label || "Type of Referral",
  reasonForReferralCode: fieldSettings?.reasonForReferralCode?.label || "Reason For Referral Code",
  specialistConsultant: fieldSettings?.specialistConsultant?.label || "Specialist Consultant",
  mbsConsent: fieldSettings?.mbsConsent?.label || "MBS Consent",
  referrerAdvisedPriority: fieldSettings?.referrerAdvisedPriority?.label || "Referrer Advised Priority",
  referringLocation: fieldSettings?.referringLocation?.label || "Referring Location",
  referringInternalLocation: fieldSettings?.referringInternalLocation?.label || "Referring Internal Location",
  referrerFullName: fieldSettings?.referrerFullName?.label || "Referring Clinician",
  referrerOrganisationName: fieldSettings?.referrerOrganisationName?.label || "Referring Ward/Clinic",
  reasonNote: fieldSettings?.reasonNote?.label || "Reason for Referral",
  presentingComplaintNote: fieldSettings?.presentingComplaintNote?.label || "Presenting Complaint",
  relevantPatientHistoryNote: fieldSettings?.relevantPatientHistoryNote?.label || "Relevant Patient History",
  interpreterRequired: fieldSettings?.interpreterRequired?.label || "Interpreter Required",
  preferredLanguage: fieldSettings?.preferredLanguage?.label || "Language",
  nok: fieldSettings?.nok?.label || "Next of Kin",
  assignToSubSpecialty: fieldSettings?.assignToSubSpecialty?.label || "Sub Specialty",
  clinicType: fieldSettings?.clinicType?.label || "Clinic Type",
  claimType: fieldSettings?.claimType?.label || "Claim Type",
  referralExpiry: fieldSettings?.referralExpiry?.label || "Referral Expiry",
  referralPeriod: fieldSettings?.referralPeriod?.label || "Referral Period",
  referrerPhone: fieldSettings?.referrerPhone?.label || "Referrer Phone",
  referrerProviderNumber: fieldSettings?.referrerProviderNumber?.label || "Referrer Provider Number",
  designation: fieldSettings?.designation?.label || "Designation",
  presentingComplaintCodes: fieldSettings?.presentingComplaintCodes?.label || "Presenting Complaint Code",
  referrerUrgency: fieldSettings?.referrerUrgency?.label || "Urgent",
  meetsRequirements: fieldSettings?.meetsRequirements?.label || "Meets Requirements",
  referringOrgUnitId: fieldSettings?.referringOrgUnitId?.label || "Referral Source",
  attachments: fieldSettings?.attachments?.label || "Attachments",
});
