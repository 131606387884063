import { ApiActions } from "middleware/call-api";
import { ActionTypes, areAppointmentsLoading, areAppointmentsLoaded, getAppointmentById } from "../reducers/appointments.reducers";
import { setCurrentEncounter, recordNewWelfareQuestionnaire } from "./encounters.actions";

const setCurrentAppointment = encounter => ({
  type: ActionTypes.LOADED_CURRENT_APPOINTMENT,
  payload: { encounter },
});

export const fetchPatientAppointments = (orgUnitId, patientId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/appointments/${orgUnitId}`,
    bailout: state => areAppointmentsLoaded(state, patientId) || areAppointmentsLoading(state, patientId),
    entityType: "Appointments",
    patientId,
  },
});

/** * schedule Appointment */
export const scheduleNewAppointment = (patientId, assignedUserId) => setCurrentAppointment({
  id: null,
  patientId,
  assignedUserId,
  contactMethod: null,
  reason: null,
  encounterType: null,
  startDateTimeUtc: null,
  endDateTimeUtc: null,
  status: null,
  details: null,
  assessmentId: null,
  enrolmentType: null,
  assessmentNotRequired: false,
});

export const scheduleAppointment = appointment => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${appointment.patientId}/appointments/schedule`,
    body: { appointment },
    types: [ActionTypes.SCHEDULING_APPOINTMENT, ActionTypes.SCHEDULED_APPOINTMENT, ActionTypes.ERROR_SCHEDULING_APPOINTMENT],
    patientId: appointment.patientId,
  },
});

export const removeAppointment = (patientId, appointmentId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/patient/${patientId}/appointments/remove`,
    body: { appointment: { id: appointmentId, patientId } },
    entityType: "appointment",
    patientId,
    appointmentId,
  },
});

export const editExistingAppointment = (patientId, appointmentId) => (dispatch, getState) => {
  const appointment = getAppointmentById(getState(), patientId, appointmentId);
  dispatch(setCurrentAppointment({ ...appointment }));
};

export const editAppointment = appointment => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${appointment.patientId}/appointments/edit`,
    body: { appointment },
    entityType: "appointment",
    patientId: appointment.patientId,
  },
});

/** ** Record Appointment To Encounter* */
export const setAppointmentToEncounter = (patientId, appointmentId) => (dispatch, getState) => {
  let appointment = getAppointmentById(getState(), patientId, appointmentId);
  const { encounterType } = appointment;
  const isWelfareCheck = encounterType === "WelfareCheck";
  const questionnaire = isWelfareCheck ? recordNewWelfareQuestionnaire() : null;

  appointment = {
    ...appointment,
    id: null,
    appointmentId: appointment.id,
    participantUserId: { value: appointment.assignedUserId, label: appointment.assignedToDisplayName },
    contactMethod: isWelfareCheck ? "Phone" : null,
    consented: null,
    duration: null,
    nextContactDate: null,
    doNotContactAgain: null,
    details: null,
    welfareQuestionnaire: questionnaire,
  };
  dispatch(setCurrentEncounter(appointment));
};

export const cancelAppointment = (patientId, appointmentId) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${patientId}/appointments/cancel`,
    body: { appointment: { id: appointmentId, patientId } },
    entityType: "appointment",
    patientId,
    appointmentId,
    types: ["CANCELLING_APPOINTMENT", ActionTypes.CANCELLED_APPOINTMENT, "ERROR_CANCELLING_APPOINTMENT"],
  },
});
