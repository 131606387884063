import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { isEmpty, isEqual } from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import EmptyState from "components/items/empty-state";
import LoadingState from "components/items/loading-state";
import { isIE } from "helpers/is-browser";
import PreviewControls from "components/items/preview-controls";

/**
 * if we need to update the react-pdf in the future,
 * we also need to update the version of pdf.worker to match the required version
 * to check the version of current required pdf.worker version, log the following output
 * console.log(pdfjs.version)
 *
 * pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;
*/
pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/scripts/pdf.worker.2.12.313.min.js`;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const useStyles = makeStyles(theme => ({
  document: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "100%",
  },
  page: {
    "& > .react-pdf__Page__canvas": {
      border: `1px solid ${theme.palette.divider}`,
    },
  },
}));

export const EmptyStateComponent = props => <div className="my-24"><EmptyState title="Preview not available." {...props} /></div>;

const PDFPreview = ({
  file,
  width,
  loading,
  paged,
  showPreviewControls,
  component: PreviewWrapper = showPreviewControls ? PreviewControls : React.Fragment,
}) => {
  const [totalPages, setTotal] = useState(null);
  const [loadError, setLoadError] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const prevFile = usePrevious(file);

  useEffect(() => {
    if (paged && !isEqual(prevFile, file)) {
      // reset paging
      setPageNumber(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]); // only run when init

  const classes = useStyles();

  if (loading) return <LoadingState />;

  if (isEmpty(file)) return <EmptyStateComponent title="Preview Not Available" />;

  if (loadError) {
    if (isIE) {
      return <EmptyStateComponent title="PDF preview not supported by your browser" icon="warning" />;
    }
    return <EmptyStateComponent title="Unable to load PDF preview" />;
  }

  const onDocumentLoadSuccess = ({ numPages }) => {
    setTotal(numPages);
    if (loadError) {
      setLoadError(false);
    }
  };

  const pageControlsProps = showPreviewControls && {
    goToPrevious: () => setPageNumber(pageNumber - 1),
    goToNext: () => setPageNumber(pageNumber + 1),
    pageControlLabel: `${pageNumber} of ${totalPages}`,
    disablePrevious: pageNumber === 1,
    disableNext: pageNumber === totalPages,
  };

  return (
    <PreviewWrapper {...pageControlsProps}>
      <Document
        file={file}
        onLoadSuccess={onDocumentLoadSuccess}
        className={classes.document}
        onLoadError={() => setLoadError(true)}
        loading={<div style={{ width }}><LoadingState /></div>}
      >
        {!paged ? Array(...Array(totalPages))
          .map((x, i) => i + 1)
          .map((page, index) => (
            <Page
              key={page}
              pageNumber={page}
              width={width - (8.5 * 2)}
              className={clsx(classes.page, index === 0 ? "" : "mt-16")}
              loading={index === 0 ? <LoadingState /> : ""}
            />
          )) : (
            <Page
              width={width - (8.5 * 2)}
              loading={<LoadingState />}
              pageNumber={pageNumber}
            />
        )}
      </Document>
    </PreviewWrapper>
  );
};

export default PDFPreview;
