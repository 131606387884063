import React from "react";
import PatientRecordContent from "app/main/patients/components/patient-record-content";

const PatientRecordPage = ({
  match: { params: { patientId } },
  ...other
}) => (
  <PatientRecordContent routePrefix={process.env.REACT_APP_DEFAULT_ROUTE || "/referralWorklist"} patientId={patientId} {...other} />
);

export default PatientRecordPage;
