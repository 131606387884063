import React from "react";
import { useSelector } from "react-redux";
import { Field } from "formik";
import { isEmpty, find } from "lodash";
import PanelContent from "components/items/panel-content";
import Section from "components/items/section";
import { TextInput } from "components/inputs";
import OCRFieldComponent from "components/ocr-components/ocr-field-component";
import { OCRTextInput } from "components/ocr-components";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";
import ReferralOrgUnitSelector from "app/main/referrals/components/referral-orgUnit-selector";
import { enableFreeTextOnProviderInput, getEnableOCR, getReferralFormLayoutSetting } from "app/auth/store/reducers/system-configuration";
import { getTypeOfReferralCodeSetValues, getDesignationCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";
import { DoctorIcon } from "helpers/icon-finder";
import ProviderHelperText from "./provider-helper-text";
import ProviderSelectorInput from "./provider-selector-input";
import ProviderOCRSelectorInput from "./provider-ocr-selector-input";
import SelectorHelperText from "./selector-helper-text";

export default ({
  autoReferringOrgUnitId,
  form: { setFieldValue },
  fieldSettings,
  fieldLabels,
  ocrResult,
  isNewUpload,
  calculateReferralExpiryDate,
  referral,
}) => {
  const enableOCR = useSelector(getEnableOCR);
  const referralFormLayoutSetting = useSelector(getReferralFormLayoutSetting);
  const designationCodeSetValues = useSelector(getDesignationCodeSetValues);
  const providerFieldsReadOnly = !useSelector(enableFreeTextOnProviderInput);
  const designationCodeSetValue = designationCode => find(designationCodeSetValues, x => x.code === designationCode);

  const typeOfReferralCodeSetValues = useSelector(getTypeOfReferralCodeSetValues);
  const typeOfReferralCodeSetValue = typeOfReferralCode => find(typeOfReferralCodeSetValues, x => x.code === typeOfReferralCode);
  const updateTypeOfReferralCode = (typeOfReferralCode, referralDateUtc) => {
    const typeOfReferralCodeValue = typeOfReferralCodeSetValue(typeOfReferralCode);
    setFieldValue("referral.typeOfReferralCodeId", typeOfReferralCodeValue?.id);
    calculateReferralExpiryDate(typeOfReferralCodeValue?.id, referralDateUtc);
  };

  return (
    <Section withDivider title="Referrer Information">
      {/* // provider info */}
      <PanelContent itemsPerRow={referralFormLayoutSetting === "Layout1" && fieldSettings?.designation ? 2 : 1}>
        {fieldSettings?.referrerFullName && (
        <Field
          name="referral.referrerFullName"
          component={OCRFieldComponent}
          FieldComponent={ProviderSelectorInput}
          OCRComponent={ProviderOCRSelectorInput}
          enabledOCR={enableOCR && fieldSettings.referrerFullName.enabledOCR}
          ocrProperty="ReferrerFullName"
          confirmedOCR={!isNewUpload}
          ocrResult={ocrResult}
          icon={<DoctorIcon />}
          label={fieldLabels.referrerFullName}
          required={fieldSettings.referrerFullName.required}
          helperText={<ProviderHelperText />}
          disabled={referral?.disabledReferrerFullName}
          onChange={(val, onChange) => {
            if (val) {
              if (val?.id) {
                onChange("referral.referrerPracticeProviderGuid", val.id);
              }
              // check the config if fields enabled
              if (fieldSettings?.referrerProviderNumber && val?.providerNumber) {
                onChange("referral.referrerProviderNumber", val.providerNumber);
              }
              if (fieldSettings?.referrerPhone && val?.practicePhone) {
                onChange("referral.referrerPhone", val.practicePhone);
              }
              if (fieldSettings?.referrerOrganisationName && val.practiceName) {
                onChange("referral.referrerOrganisationName", val.practiceName);
              }
              if (fieldSettings?.designation && val.role) {
                const typeOfReferralCodeValue = designationCodeSetValue(val.role);
                onChange("referral.designationCodeId", typeOfReferralCodeValue?.id);
                const typeOfReferralCodeSettings = typeOfReferralCodeValue?.propertiesJson;
                if (typeOfReferralCodeSettings?.typeOfReferralCode) {
                  updateTypeOfReferralCode(typeOfReferralCodeSettings?.typeOfReferralCode, referral.referralDateUtc);
                }
              }
            } else {
              onChange("referral.referrerProviderNumber", null);
              onChange("referral.referrerPhone", null);
              onChange("referral.referrerOrganisationName", null);
              onChange("referral.designationCodeId", null);
              onChange("referral.referrerPracticeProviderGuid", null);
            }
          }}
        />
        )}
        {referralFormLayoutSetting === "Layout1" && fieldSettings?.designation && (
        <Field
          name="referral.designationCodeId"
          component={CodeSetValueSelector}
          codeSetTypeCode={fieldSettings.designation.fieldName}
          label={fieldLabels.designation}
          required={fieldSettings.designation.required}
          helperText={<SelectorHelperText />}
          onChange={value => {
            if (!isEmpty(value) && value.settings?.typeOfReferralCode && fieldSettings?.typeOfReferral) {
              updateTypeOfReferralCode(value.settings?.typeOfReferralCode, referral.referralDateUtc);
            }
          }}
        />
        )}
      </PanelContent>
      <PanelContent>
        {fieldSettings?.referrerProviderNumber && (
        <Field
          name="referral.referrerProviderNumber"
          component={TextInput}
          label={fieldLabels.referrerProviderNumber}
          required={fieldSettings.referrerProviderNumber.required}
          disabled={providerFieldsReadOnly}
        />
        )}
        {fieldSettings?.referrerPhone && (
        <Field
          name="referral.referrerPhone"
          component={OCRFieldComponent}
          FieldComponent={TextInput}
          OCRComponent={OCRTextInput}
          enabledOCR={enableOCR && fieldSettings.referrerPhone.enabledOCR}
          ocrProperty="ReferrerPhone"
          confirmedOCR={!isNewUpload}
          ocrResult={ocrResult}
          label={fieldLabels.referrerPhone}
          required={fieldSettings.referrerPhone.required}
          disabled={providerFieldsReadOnly}
        />
      )}
      </PanelContent>
      {fieldSettings?.referringOrgUnitId && (
        <Field
          name="referral.referringOrgUnitId"
          label={fieldLabels.referringOrgUnitId}
          disabled={autoReferringOrgUnitId || referral?.disabledReferringOrgUnit}
          initialOptions={referral?.referringOrgUnit ? [referral?.referringOrgUnit] : []}
          component={ReferralOrgUnitSelector}
          onChange={(value, change) => {
            change("referral.referringOrgUnitId", value?.value);
          }}
        />
      )}
      <PanelContent>
        {referralFormLayoutSetting === "Layout2" && fieldSettings?.designation && (
          <Field
            name="referral.designationCodeId"
            component={CodeSetValueSelector}
            codeSetTypeCode={fieldSettings.designation.fieldName}
            label={fieldLabels.designation}
            required={fieldSettings.designation.required}
            onChange={value => {
              if (!isEmpty(value) && value.settings?.typeOfReferralCode && fieldSettings?.typeOfReferral) {
                updateTypeOfReferralCode(value.settings?.typeOfReferralCode, referral.referralDateUtc);
              }
            }}
          />
        )}
        {fieldSettings?.referrerOrganisationName && fieldSettings?.referringInternalLocation && (
        <Field
          name="referral.referrerOrganisationName"
          component={TextInput}
          label={fieldLabels.referrerOrganisationName}
          required={fieldSettings.referrerOrganisationName.required}
          disabled={providerFieldsReadOnly}
        />
        )}
      </PanelContent>
      <PanelContent>
        {fieldSettings?.referringLocation && (
        <Field
          name="referral.referringLocationCodeId"
          component={CodeSetValueSelector}
          codeSetTypeCode={fieldSettings.referringLocation.fieldName}
          label={fieldLabels.referringLocation}
          required={fieldSettings.referringLocation.required}
        />
          )}
        {fieldSettings?.referringInternalLocation && (
        <Field
          name="referral.referringInternalLocationCodeId"
          component={CodeSetValueSelector}
          codeSetTypeCode={fieldSettings.referringInternalLocation.fieldName}
          label={fieldLabels.referringInternalLocation}
          required={fieldSettings.referringInternalLocation.required}
        />
        )}
        {fieldSettings?.referrerOrganisationName && !fieldSettings?.referringInternalLocation && (
        <Field
          name="referral.referrerOrganisationName"
          component={TextInput}
          label={fieldLabels.referrerOrganisationName}
          required={fieldSettings.referrerOrganisationName.required}
          showHelperText={false}
          disabled={providerFieldsReadOnly}
        />
        )}
      </PanelContent>
    </Section>
  );
};

