import React from "react";
import OCRBaseComponent from "components/ocr-components/ocr-based-component";
import { CheckboxInput } from "components/inputs";
import { lowerCase, isEmpty, includes } from "lodash";

export const CheckboxInputField = ({
  ...other
}) => {
  const { field: { name }, ocrValue } = other;
  const booleanValues = ["yes", "no", "true", "false", "0", "1"];
  const currentOcrValue = isEmpty(ocrValue) ? null : lowerCase(ocrValue);
  const isValidFormat = includes(booleanValues, currentOcrValue);
  const isTrueValue = isValidFormat ? currentOcrValue === "yes" || currentOcrValue === "true" || currentOcrValue === "1" : null;

  const handleConfirmed = (isAccept, change) => {
    if (isAccept) {
      change(name, isTrueValue);
    } else {
      change(name, null);
    }
  };

  return (
    <OCRBaseComponent
      inputComponent={CheckboxInput}
      onHandleConfirmed={handleConfirmed}
      isValidFormat={isValidFormat}
      formatValue={isTrueValue}
      requireValidFormat
      {...other}
    />
  );
};
