import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { getCurrentReferralSourceDocumentFile, isCurrentReferralSourceDocumentFileLoading, errorLoadingCurrentReferralSourceDocumentFile } from "app/main/referrals/reducers/referrals.reducers";
import { getReferralSourceDocumentFilePreviewData } from "app/main/referrals/actions/referrals.actions";
import PreviewContent from "components/items/preview-content";
import TabContent from "components/items/tab-content";
import EmptyState from "components/items/empty-state";
import { SourceDocumentIcon } from "helpers/icon-finder";

const ReferralSourceDocumentPanel = ({
  referral,
  orgUnitId,
  pdfWidth,
}) => {
  const sourceDocumentId = referral.referralSourceDocument?.id;
  const sourceDocumentFileId = referral.referralSourceDocument?.fileId;

  const dispatch = useDispatch();
  const sourceDocumentFile = useSelector(getCurrentReferralSourceDocumentFile);
  const isSourceDocumentFileLoading = useSelector(isCurrentReferralSourceDocumentFileLoading);
  const errorLoadingSourceDocument = useSelector(errorLoadingCurrentReferralSourceDocumentFile);

  useEffect(() => {
    if (orgUnitId && sourceDocumentId && sourceDocumentFileId) {
      dispatch(getReferralSourceDocumentFilePreviewData(orgUnitId, sourceDocumentId, sourceDocumentFileId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId && sourceDocumentId && sourceDocumentFileId]);

  const renderEmptyState = () => (
    <EmptyState
      icon={<SourceDocumentIcon />}
      title="No Source Document"
    />
  );

  return (
    <TabContent
      withGutters
      loading={isSourceDocumentFileLoading}
      empty={isEmpty(sourceDocumentFile)}
      error={errorLoadingSourceDocument}
      emptyState={renderEmptyState()}
    >
      <PreviewContent
        fileData={sourceDocumentFile?.base64File || sourceDocumentFile?.htmlPreview}
        mimeType={sourceDocumentFile?.mimeType}
        pdfProps={{ width: pdfWidth }}
        hasPreview={sourceDocumentFile?.hasPreview}
      />
    </TabContent>
  );
};

export default ReferralSourceDocumentPanel;
