import { useState } from "react";
import { useDispatch } from "react-redux";
import { fetchOrgUnitFormFields } from "app/main/orgUnits/actions/orgUnit.actions";
import { showMessage } from "app/store/actions/message.actions";

/**
 * @param {string} formType re-enum
 * @param {func} onOpen
 * this hook is use to load the settings before the renders the config forms
 * 1. prevent form is set into loading state with spinner then expand the height
 * 2. prevent calling api multiple times
 * available enum
  CreateEditReferral,
  CreateEditTreatment,
  CreateEditDecision,
  TriageForm,
*/
const useOpenConfigForm = (orgUnitId, formType, onOpen) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  // force to load will be true to make sure form fields are up-to-date
  const openConfigForm = props => {
    if (loading) return;
    setLoading(true);
    dispatch(fetchOrgUnitFormFields(orgUnitId, formType, true))
      .then(res => {
        if (res.error === true) {
          return dispatch(showMessage({ message: "Error occurred while loading the form configurations", variant: "error" }));
        }

        return setTimeout(() => {
          onOpen(props); // open dialog cause loader freeze
        }, 600);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 800);
      });
  };

  return ({
    openConfigForm,
    loadingConfigurations: loading,
  });
};

export default useOpenConfigForm;
