import React from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { withRouter } from "react-router-dom";

import { getRecentAppointments, getAppointmentsByPatientId, areAppointmentsLoading, getErrorMessage } from "app/main/patients/reducers/appointments.reducers";
import { scheduleNewAppointment, fetchPatientAppointments, setAppointmentToEncounter, editExistingAppointment, cancelAppointment, removeAppointment } from "app/main/patients/actions/appointments.actions";
import ScheduleAppointmentForm from "app/main/patients/encounters/components/schedule-encounter-form";
import RecordEncounterForm from "app/main/patients/encounters/components/record-encounter-form";
import withPermissions from "permissions/withPermissions";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { createAssessment, continueAssessment } from "app/main/patients/actions/assessments.actions";

class AppointmentListContainer extends React.PureComponent {
  constructor(props) {
    super(props);
    const { orgUnitId, patientId } = this.props;

    if (orgUnitId && patientId) {
      this.props.fetchPatientAppointments(orgUnitId, patientId);
    }
    this.onClose = this.onClose.bind(this);
    this.onScheduleAppointment = this.onScheduleAppointment.bind(this);
    this.onRecordAppointmentEncounter = this.onRecordAppointmentEncounter.bind(this);
  }

  onClose = () => this.props.closeDialog();

  openScheduleAppointmentForm = (title, isEdit) => {
    this.props.openDialog({
      children: (
        <ScheduleAppointmentForm
          onSucceed={this.onClose}
          isEdit={isEdit}
          title={title}
          patientId={this.props.patientId}
        />
      ),
      maxWidth: "md",
    });
  };

  onRemoveAppointment = appointmentId => {
    this.props.removeAppointment(this.props.patientId, appointmentId);
    this.onClose();
  };

  onScheduleAppointment = () => {
    this.props.scheduleNewAppointment(this.props.patientId, this.props.assignedUserId);
    this.openScheduleAppointmentForm("Schedule Encounter", false);
  };

  onEditAppointment = appointmentId => {
    this.props.editExistingAppointment(this.props.patientId, appointmentId);
    this.openScheduleAppointmentForm("Edit Booked Encounter", true);
  };

  onCancelAppointment = appointmentId => {
    this.props.cancelAppointment(this.props.patientId, appointmentId);
    this.props.closeDialog();
  };

  onRecordAppointmentEncounter = appointmentId => {
    this.props.setAppointmentToEncounter(this.props.patientId, appointmentId);
    this.props.openDialog({
      children: <RecordEncounterForm isAppointmentEncounter disabledEncounterType patientId={this.props.patientId} title="Record Encounter" onSucceed={this.onClose} />, maxWidth: "md",
    });
  };

  goToAssessmentPage = assessmentId => {
    this.props.history.push({
      pathname: `/patients/${this.props.patientId}/assessments/${assessmentId}`,
      fromUrl: this.props.location.pathname,
    });
  };

  onCreateAssessment = ({ assessmentType, appointmentId, encounterId, assessmentId, assessmentHeaderId }) => { // i put encounter call here for now, can improve the call later?
    const { patientId } = this.props;
    if (assessmentId) {
      this.goToAssessmentPage(assessmentId);
    }

    if (assessmentHeaderId) {
      this.props.continueAssessment({
        patientId,
        appointmentId,
        type: assessmentType,
        assessmentHeaderId,
      }).then(responds => {
        if (responds.error !== true) {
          this.goToAssessmentPage(responds.payload.encounter.assessmentId);
        }
      });
    }

    if (!assessmentHeaderId && !assessmentId) {
      this.props.createAssessment({
        patientId,
        appointmentId,
        encounterId,
        Type: assessmentType,
      }).then(responds => {
        if (responds.error !== true) {
          this.goToAssessmentPage(responds.payload.assessment.id);
        }
      });
    }
  }

  render() {
    const {
      loading,
      error,
      appointments,
      renderContent,
      hasPermissionAppointmentsCreate,
      hasPermissionEncountersCreate,
      hasPermissionAppointmentsDelete,
      hasPermissionAppointmentsCancel,
      hasPermissionAppointmentsUpdate,
      hasPermissionAssessmentsCreate,
    } = this.props;

    return (
      renderContent({
        appointments,
        hasPermissionAppointmentsCreate,
        onScheduleAppointment: hasPermissionAppointmentsCreate ? this.onScheduleAppointment : null,
        onEditAppointment: hasPermissionAppointmentsUpdate ? this.onEditAppointment : null,
        onRecordAppointmentEncounter: hasPermissionEncountersCreate ? this.onRecordAppointmentEncounter : null,
        onCancelAppointment: hasPermissionAppointmentsCancel ? this.onCancelAppointment : null,
        onRemoveAppointment: hasPermissionAppointmentsDelete ? this.onRemoveAppointment : null,
        isLoading: loading,
        isError: error,
        isEmpty: isEmpty(appointments),
        onCreateAssessment: hasPermissionAssessmentsCreate ? this.onCreateAssessment : null,
      })
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { patientId, numberOfItems, showAllStatus } = ownProps;
  const appointments = numberOfItems
    ? getRecentAppointments(state, patientId, numberOfItems, showAllStatus)
    : getAppointmentsByPatientId(state, patientId, showAllStatus);

  return {
    appointments,
    error: getErrorMessage(state, patientId),
    loading: areAppointmentsLoading(state, patientId),
  };
};

export default withRouter(withPermissions(
  "AppointmentsCreate",
  "EncountersCreate",
  "AppointmentsDelete",
  "AppointmentsCancel",
  "AppointmentsUpdate",
  "AssessmentsCreate",
)(connect(
  mapStateToProps,
  {
    openDialog,
    closeDialog,
    fetchPatientAppointments,
    scheduleNewAppointment,
    setAppointmentToEncounter,
    removeAppointment,
    editExistingAppointment,
    cancelAppointment,
    createAssessment,
    continueAssessment,
  },
)(AppointmentListContainer)));
