import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import { currentReferralAttachmentIsLoading, errorLoadingCurrentReferralAttachments } from "app/main/referrals/reducers/referrals.reducers";
import TabContent from "components/items/tab-content";
import { getReferralAttachments, removeReferralAttachment } from "app/main/referrals/actions/referrals.actions";
import withPermissions from "permissions/withPermissions";
import EmptyState from "components/items/empty-state";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import ReferralAttachmentForm from "app/main/referrals/components/referral-attachment-form";
import { showMessage } from "app/store/actions/message.actions";
import useCanEditInEndState from "hooks/use-can-edit-in-end-state";

import ManualAttachments from "./manual-attachments";

const ReferralAttachments = ({
  referral,
  orgUnitId,
  hasPermissionReferralAttachmentCreate,
}) => {
  const dispatch = useDispatch();
  const { referralStatus, permissions } = referral;
  const loadingCurrentAttachments = useSelector(currentReferralAttachmentIsLoading);
  const errorLoadingCurrentAttachments = useSelector(errorLoadingCurrentReferralAttachments);
  const canCreate = permissions.canEditAttachments && hasPermissionReferralAttachmentCreate;
  const canEditInEndState = useCanEditInEndState(referralStatus);
  const canManageAttachments = canCreate && canEditInEndState;

  React.useEffect(() => {
    if (referral?.referralDocument.canViewAttachments) {
      dispatch(getReferralAttachments(orgUnitId, referral.id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => dispatch(closeDialog());

  const onUploadAttachment = () => dispatch(openDialog({
    children: (
      <ReferralAttachmentForm
        title="Add Attachment"
        onSucceed={onClose}
        referralId={referral.id}
        orgUnitId={orgUnitId}
        referralDocumentId={referral.referralDocument.id}
      />
    ),
  }));

  const onRemoveAttachment = (referralDocumentId, fileId) => {
    dispatch(removeReferralAttachment(orgUnitId, referral.id, referralDocumentId, fileId)).then((response => {
      if (response.error === true) {
        dispatch(showMessage({ message: response.payload.exceptionMessage || response.payload.message, variant: "error" }));
      }
    }));
  };

  const renderEmptyState = () => (
    <EmptyState
      icon="attach_file"
      title="No Attachments Available"
      subtitle={canManageAttachments ? "Click to add one" : ""}
      onClick={canManageAttachments ? () => onUploadAttachment() : null}
    />
  );

  return (
    <TabContent
      withGutters={false}
      accessDenied={!referral.referralDocument.canViewAttachments}
      loading={loadingCurrentAttachments}
      error={errorLoadingCurrentAttachments}
      empty={isEmpty(referral.attachments)}
      emptyState={renderEmptyState()}
    >
      <ManualAttachments
        attachments={referral.attachments}
        referralId={referral.id}
        onCreate={onUploadAttachment}
        canCreate={canManageAttachments}
        canEditInEndState={canEditInEndState}
        canEditAttachments={permissions.canEditAttachments}
        onRemove={onRemoveAttachment}
        orgUnitId={orgUnitId}
      />
    </TabContent>
  );
};

export default withPermissions("ReferralAttachmentCreate", "ReferralAttachmentDelete", "ReferralAttachmentDeleteOtherUser")(ReferralAttachments);
