
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Field } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { find, every } from "lodash";

import Form from "components/form";
import PatientSelector from "app/main/patients/components/patient-selector";
import PatientOCRSelector from "app/main/patients/components/patient-ocr-selector";
import { getPatientLabel } from "utils/get-environment-variables";
import PatientSearchButton from "app/main/patients/components/external-patient-search-button";
import { normalizePatient, getAllPatients } from "app/main/patients/reducers/patients.reducers";
import { getPrimaryPatientIdentifier, getSexOptions, showExternalPatientSearch, getEnableStreamlinedAddReferral } from "app/auth/store/reducers/system-configuration";
import { fetchPatientNOK } from "app/main/patients/actions/contacts.actions";
import { resetPatientSearch } from "app/main/patients/actions/patientsSearch.actions";

import { setCurrentAttachment, resetCurrentPatientOCR, resetCurrentAttachment } from "app/main/patients/actions/patientOCR.selector.actions";
import { saveReferralAssignedPatient, saveReferralAssignedPatientEdit, getReferralSourceDocumentFilePreviewData } from "app/main/referrals/actions/referrals.actions";
import { showMessage } from "app/store/actions/message.actions";
import { getDefaultReferralSourceDocumentFile } from "app/main/referrals/reducers/referrals.reducers";

const patientLabel = getPatientLabel();
const schema = Yup.object().shape({
  patient: Yup.string()
    .required("This field is required")
    .nullable(),
});

const ReferralAssignmentPatientDialog = ({
  orgUnitId,
  referralId,
  referredToSpecialty,
  onSucceed,
  onClose,
  isReAssignPatient = false,
  referralSourceDocument = null,
  title,
  ...other
}) => {
  const dispatch = useDispatch();
  const patients = useSelector(getAllPatients);
  const enableSearchExternal = useSelector(showExternalPatientSearch);
  const enableStreamlinedAddReferral = useSelector(getEnableStreamlinedAddReferral);
  const sexOptions = useSelector(getSexOptions);
  const primaryPatientIdentifier = useSelector(getPrimaryPatientIdentifier);
  const referralSourceDocumentFile = useSelector(getDefaultReferralSourceDocumentFile);
  const [isContinueLoading, setContinueLoading] = useState(false);

  useEffect(() => {
    if (enableStreamlinedAddReferral && orgUnitId && referralSourceDocument) {
      dispatch(getReferralSourceDocumentFilePreviewData(orgUnitId, referralSourceDocument.id, referralSourceDocument.fileId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgUnitId, referralSourceDocument, enableStreamlinedAddReferral]);

  useEffect(() => {
    dispatch(setCurrentAttachment(referralSourceDocumentFile));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralSourceDocumentFile]);

  const translatePatientDetails = patient => (
    {
      ...patient,
      nextOfKin: patient?.nextOfKin ?? null,
      sex: find(sexOptions, x => x.value === patient.sex || x.label === patient.sex)?.value ?? null,
    }
  );

  const onClear = () => {
    dispatch(resetCurrentPatientOCR());
    dispatch(resetPatientSearch());
    dispatch(resetCurrentAttachment());
  };

  const handleClose = () => {
    onClear();
    if (onClose) {
      onClose();
    }
  };

  const onAssignPatient = (selectedPatient, sourceDocument) => {
    setContinueLoading(true);
    const assignPatientReferral = {
      referralId,
      patientId: selectedPatient?.patientId,
      primaryPatientIdentifier: selectedPatient.primaryPatientIdentifier
                                ?? find(selectedPatient.patientIdentifiers, id => id.patientIdentifierTypeCode === primaryPatientIdentifier)?.identifier,
      patient: normalizePatient(translatePatientDetails(selectedPatient)),
      nok: !every(selectedPatient.nextOfKin, x => (x === null || x === undefined)) ? { ...selectedPatient.nextOfKin, relationshipType: "NextOfKin" } : null,
      referralSourceDocumentFileId: sourceDocument?.id,
    };

    const apiCall = isReAssignPatient ? saveReferralAssignedPatientEdit : saveReferralAssignedPatient;

    return dispatch(apiCall(orgUnitId, assignPatientReferral)).then(response => {
      setContinueLoading(false);
      if (response.error !== true) {
        onClear();
        onSucceed(referralId);
      } else {
        dispatch(showMessage({ message: response.payload.exceptionMessage || "An error has occurred", variant: "error" }));
      }
      return response;
    });
  };

  const handleSubmit = ({ patient }, { setSubmitting, setErrors }) => {
    // else open add-referral-form, pass patient value
    let existingPatient = find(patients, p => p.patientId === patient.value);

    dispatch(fetchPatientNOK(existingPatient.patientId)).then(response => {
      setSubmitting(false);
      if (!response.error) {
        existingPatient = { ...existingPatient, nextOfKin: response.payload.contact };
        onAssignPatient(existingPatient);
      } else {
        setErrors(response.error);
      }
    });
  };

  if (enableStreamlinedAddReferral) {
    return (
      <PatientOCRSelector
        fullWidth
        maxWidth="xl"
        contentProps={{ ...other, title: `Select ${patientLabel.single}`, submitLabel: "Continue" }}
        initialValues={schema.cast()}
        validationSchema={schema}
        onContinue={onAssignPatient}
        onClose={handleClose}
        isContinueLoading={isContinueLoading}
        patientLabel={patientLabel}
        orgUnitId={orgUnitId}
        continueLabel="Assign"
        title={title}
        referredToSpecialty={referredToSpecialty}
        enableProcessOCR={other.enableProcessOCR}
        disabledRemove
        disabledAddDocument
      />
    );
  }

  return (
    <Form
      contentProps={{ ...other, title: `Select ${patientLabel.single}`, submitLabel: "Continue" }}
      initialValues={schema.cast()}
      validationSchema={schema}
      onSubmit={handleSubmit}
      extraActions={(
        <div className="flex-row-container with-gutter">
          {enableSearchExternal && (
            <PatientSearchButton
              onContinue={patient => onAssignPatient(patient)}
              buttonProps={{ variant: "text" }}
            />
          )}
        </div>
      )}
      content={() => (
        <Field
          name="patient"
          component={PatientSelector}
          label={patientLabel.single}
          icon="account_circle"
          required
          autoFocus
        />
      )}
    />
  );
};
export default ReferralAssignmentPatientDialog;
