import React from "react";
import { Typography } from "@material-ui/core";
import DefaultItem, { ContextItem } from "components/items/default-item";
import { PhoneIcon, ContactsIcon, AssignedUserIcon } from "helpers/icon-finder";
import PatientContextListItem, { ItemContent } from "app/main/patients/components/patient-context-list-item";
import SMSRequestedStatus from "app/main/patients/contacts/components/sms-requested-status";

const ContactListItem = ({
  item: {
    contact,
    givenName,
    familyName,
    relationshipType,
    alternativeContact,
    isEmergencyContact,
    relationshipDescription,
    smsRequested,
  },
  ...other
}) => {
  const fullName = `${givenName} ${familyName}`;

  return (
    <PatientContextListItem
      title={fullName}
      {...other}
      renderItemContent={() => (
        <ItemContent
          title={fullName}
          icon={<ContactsIcon />}
          status={isEmergencyContact && <DefaultItem icon="star" content="Emergency Contact" />}
          content={(
            <div className="flex flex-col">
              <Typography variant="caption">{relationshipType.label}</Typography>
              <Typography variant="caption">{contact}</Typography>
            </div>
          )}
        />
      )}
      renderPreviewContent={() => (
        <>
          <ContextItem icon={<AssignedUserIcon />} content={relationshipType.label} />
          <ContextItem icon={<PhoneIcon />} content={contact} />
          <SMSRequestedStatus smsRequested={smsRequested} />
          <ContextItem icon={<PhoneIcon />} content={alternativeContact} />
          <ContextItem content={relationshipDescription} />
        </>
      )}
    />
  );
};

export default ContactListItem;
