import CodeSetIndexPage from "./pages/code-set-index-page";

const AdminConfig = {
  routes: [
    {
      path: "/admin/code-sets/:codeSetTypeCode",
      component: CodeSetIndexPage,
      title: "Code Set",
      exact: true,
      backUrl: "/admin",
      search: "?tab=codeSets",
    },
  ],
};

export default AdminConfig;
