import { get, map, mapKeys, find, filter, reduce, lowerFirst, sortBy, includes, concat } from "lodash";
import { getActiveOrgUnitIds, ActionTypes as UserActionTypes } from "app/auth/store/reducers/user.reducer";
import curryConnector from "utils/connectors";

export const ORG_UNIT_STATE_KEY = "orgUnits";
export const ORG_UNIT_PAGE_SIZE = 50;
export const ORG_UNIT_PAGE_SIZE_OPTIONS = [5, 10, 25, 50];

const curry = fn => curryConnector(fn, ORG_UNIT_STATE_KEY);

export const ActionTypes = {
  LOADING_ORG_UNITS: "LOADING_ORG_UNITS",
  ERROR_LOADING_ORG_UNITS: "ERROR_LOADING_ORG_UNITS",
  LOADED_ORG_UNITS: "LOADED_ORG_UNITS",

  LOADING_CURRENT_ORG_UNIT: "LOADING_CURRENT_ORG_UNIT",
  ERROR_LOADING_CURRENT_ORG_UNIT: "ERROR_LOADING_CURRENT_ORG_UNIT",
  LOADED_CURRENT_ORG_UNIT: "LOADED_CURRENT_ORG_UNIT",

  CREATED_ORG_UNIT: "CREATED_ORG_UNIT",
  SAVED_ORG_UNIT: "SAVED_ORG_UNIT",
  SAVED_DEPRECATE_ORG_UNIT: "SAVED_DEPRECATE_ORG_UNIT",
  SAVED_RESTORE_ORG_UNIT: "SAVED_RESTORE_ORG_UNIT",

  SAVED_ORG_UNIT_FORM_FIELDS: "SAVED_ORG_UNIT_FORM_FIELDS",
  SAVED_ENDORSEMENT_TYPE_CONFIGURATION: "SAVED_ENDORSEMENT_TYPE_CONFIGURATION",
  SAVED_NOTIFICATION_CONFIGURATION: "SAVED_NOTIFICATION_CONFIGURATION",

  LOADING_CURRENT_ORG_UNIT_FORM_FIELDS: "LOADING_CURRENT_ORG_UNIT_FORM_FIELDS",
  LOADED_CURRENT_ORG_UNIT_FORM_FIELDS: "LOADED_CURRENT_ORG_UNIT_FORM_FIELDS",
  ERROR_LOADING_CURRENT_ORG_UNIT_FORM_FIELDS: "ERROR_LOADING_CURRENT_ORG_UNIT_FORM_FIELDS",

  LOADING_CURRENT_ORG_UNIT_FORM_FIELD_CONFIG: "LOADING_CURRENT_ORG_UNIT_FORM_FIELD_CONFIG",
  LOADED_CURRENT_ORG_UNIT_FORM_FIELD_CONFIG: "LOADED_CURRENT_ORG_UNIT_FORM_FIELD_CONFIG",
  ERROR_LOADING_CURRENT_ORG_UNIT_FORM_FIELD_CONFIG: "ERROR_LOADING_CURRENT_ORG_UNIT_FORM_FIELD_CONFIG",

  UPDATE_ORG_UNIT_FORM_FIELD_CONFIG: "UPDATE_ORG_UNIT_FORM_FIELD_CONFIG",

  CREATED_ORG_UNIT_FORM_FIELD_CONFIG: "CREATED_ORG_UNIT_FORM_FIELD_CONFIG",
  SAVED_ORG_UNIT_FORM_FIELD_CONFIG: "SAVED_ORG_UNIT_FORM_FIELD_CONFIG",

  LOADING_ENDORSEMENT_TYPE_CONFIGURATION: "LOADING_ENDORSEMENT_TYPE_CONFIGURATION",
  LOADED_ENDORSEMENT_TYPE_CONFIGURATION: "LOADED_ENDORSEMENT_TYPE_CONFIGURATION",
  ERROR_LOADING_ENDORSEMENT_TYPE_CONFIGURATION: "ERROR_LOADING_ENDORSEMENT_TYPE_CONFIGURATION",

  LOADING_NOTIFICATION_CONFIGURATION: "LOADING_NOTIFICATION_CONFIGURATION",
  LOADED_NOTIFICATION_CONFIGURATION: "LOADED_NOTIFICATION_CONFIGURATION",
  ERROR_LOADING_NOTIFICATION_CONFIGURATION: "ERROR_LOADING_NOTIFICATION_CONFIGURATION",

  LOADING_CURRENT_NOTIFICATION_CONFIGURATION: "LOADING_CURRENT_NOTIFICATION_CONFIGURATION",
  LOADED_CURRENT_NOTIFICATION_CONFIGURATION: "LOADED_CURRENT_NOTIFICATION_CONFIGURATION",
  ERROR_CURRENT_NOTIFICATION_CONFIGURATION: "ERROR_CURRENT_NOTIFICATION_CONFIGURATION",

  LOADING_ORG_UNIT_SELECTOR: "LOADING_ORG_UNIT_SELECTOR",
  LOADED_ORG_UNIT_SELECTOR: "LOADED_ORG_UNIT_SELECTOR",
  ERROR_LOADED_ORG_UNIT_SELECTOR: "ERROR_LOADED_ORG_UNIT_SELECTOR",

  LOADING_ORG_UNIT_PHARMACY_SELECTOR: "LOADING_ORG_UNIT_PHARMACY_SELECTOR",
  LOADED_ORG_UNIT_PHARMACY_SELECTOR: "LOADED_ORG_UNIT_PHARMACY_SELECTOR",
  ERROR_LOADED_ORG_UNIT_PHARMACY_SELECTOR: "ERROR_LOADED_ORG_UNIT_PHARMACY_SELECTOR",

  LOADING_DECISION_LETTER_TEMPLATE: "LOADING_DECISION_LETTER_TEMPLATE",
  LOADED_DECISION_LETTER_TEMPLATE: "LOADED_DECISION_LETTER_TEMPLATE",
  ERROR_LOADING_DECISION_LETTER_TEMPLATE: "ERROR_LOADING_DECISION_LETTER_TEMPLATE",

  CREATING_DECISION_LETTER_TEMPLATE: "CREATING_DECISION_LETTER_TEMPLATE",
  CREATED_DECISION_LETTER_TEMPLATE: "CREATED_DECISION_LETTER_TEMPLATE",
  ERROR_CREATING_DECISION_LETTER_TEMPLATE: "ERROR_CREATING_DECISION_LETTER_TEMPLATE",

  DELETING_DECISION_LETTER_TEMPLATE: "DELETING_DECISION_LETTER_TEMPLATE",
  DELETED_DECISION_LETTER_TEMPLATE: "DELETED_DECISION_LETTER_TEMPLATE",
  ERROR_DELETING_DECISION_LETTER_TEMPLATE: "ERROR_DELETING_DECISION_LETTER_TEMPLATE",

  LOADING_IPA_BLANK_FORM: "LOADING_IPA_BLANK_FORM",
  LOADED_IPA_BLANK_FORM: "LOADED_IPA_BLANK_FORM",
  ERROR_LOADING_IPA_BLANK_FORM: "ERROR_LOADING_IPA_BLANK_FORM",

  CREATING_IPA_BLANK_FORM: "CREATING_IPA_BLANK_FORM",
  CREATED_IPA_BLANK_FORM: "CREATED_IPA_BLANK_FORM",
  ERROR_CREATING_IPA_BLANK_FORM: "ERROR_CREATING_IPA_BLANK_FORM",

  DELETING_IPA_BLANK_FORM: "DELETING_IPA_BLANK_FORM",
  DELETED_IPA_BLANK_FORM: "DELETED_IPA_BLANK_FORM",
  ERROR_DELETING_IPA_BLANK_FORM: "ERROR_DELETING_IPA_BLANK_FORM",

  SAVED_GP_LETTER_TEMPLATE: "SAVED_GP_LETTER_TEMPLATE",
  DELETED_GP_LETTER_TEMPLATE: "DELETED_GP_LETTER_TEMPLATE",
};

const INITIAL_STATE = {
  all: [],
  pages: {},
  pageInfo: { pageNumber: 1, pageSize: ORG_UNIT_PAGE_SIZE, totalRecords: 0 },
  notificationConfigurationPageInfo: { pageNumber: 1, pageSize: ORG_UNIT_PAGE_SIZE, totalRecords: 0 },
  searchParams: {},

  current: { orgUnit: null },
  meta: {
    current: { loading: false, loaded: false, error: null },
    selector: { loading: false, loaded: false, error: null },
    endorsementTypes: {},
    orgUnitFormFieldConfigurations: [],
    orgUnitFormFields: {},
    notificationConfiguration: {},
    currentNotificationConfiguration: {},
  },
  selector: [],
  orgUnitFormFieldConfigurations: [],
  orgUnitFormFields: [],
  endorsementTypeSettings: {},
  notificationConfiguration: {},
  currentNotificationConfiguration: {},
  formularies: [],
};
const formatOrgUnitFormFieldConfiguration = orgUnitFormFieldConfiguration => ({
  ...orgUnitFormFieldConfiguration,
  configurationJson: JSON.parse(orgUnitFormFieldConfiguration.configurationJson),
});

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserActionTypes.LOADED_USER_DATA:
      return {
        ...state,
        formularies: action.payload.siteConfiguration?.formularies?.map(x => ({
          subFormularyId: x.formularyId,
          subFormularyName: x.formularyName,
        })),
      };
    case ActionTypes.LOADING_ORG_UNITS:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: true, error: false } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.ERROR_LOADING_ORG_UNITS:
      return {
        ...state,
        pages: { ...state.pages, [action.payload.pageNumber]: { loading: false, error: action.payload.message } },
        pageInfo: { ...state.pageInfo, pageNumber: action.payload.pageNumber },
      };
    case ActionTypes.LOADED_ORG_UNITS:
      return {
        ...state,
        all: { ...state.all, ...mapKeys(action.payload.orgUnits, x => x.id) },
        pages: { ...state.pages, [action.payload.pageInfo.pageNumber]: { loading: false, error: false, loaded: true, ids: map(action.payload.orgUnits, x => x.id) } },
        pageInfo: action.payload.pageInfo,
        searchParams: action.payload.searchParams,
      };
    case ActionTypes.LOADING_CURRENT_ORG_UNIT:
      return {
        ...state,
        meta: { ...state.meta, current: { loading: true, loaded: false, error: false } },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_ORG_UNIT:
      return {
        ...state,
        meta: { ...state.meta, current: { loading: false, loaded: false, error: action.payload.message } },
      };
    case ActionTypes.LOADED_CURRENT_ORG_UNIT:
      return {
        ...state,
        meta: { ...state.meta, current: { loading: false, loaded: true, error: false } },
        all: { ...state.all, [action.payload.orgUnit.id]: action.payload.orgUnit, ...mapKeys(action.payload.childOrgUnits, x => x.id) },
        current: {
          orgUnit: action.payload.orgUnit,
          orgUnitFormFields: action.payload.orgUnitFormFields,
          currentDecisionLetterTemplate: { ...action.payload.decisionLetterTemplate, loading: false },
          currentIPABlankForm: { ...action.payload.ipaBlankForm, loading: false },
          currentGPLetterTemplates: action.payload.gpLetterTemplates,
        },
        orgUnitFormFieldConfigurations: {
          ...state.orgUnitFormFieldConfigurations,
          ...mapKeys(map(action.payload.orgUnitFormFieldConfigurations, orgUnitFormFieldConfiguration => formatOrgUnitFormFieldConfiguration(orgUnitFormFieldConfiguration)), x => x.id),
        },
      };
    case ActionTypes.CREATED_ORG_UNIT:
    case ActionTypes.SAVED_ORG_UNIT:
    case ActionTypes.SAVED_DEPRECATE_ORG_UNIT:
    case ActionTypes.SAVED_RESTORE_ORG_UNIT:
      return {
        ...state,
        all: { ...state.all, [action.payload.orgUnit.id]: action.payload.orgUnit },
        meta: { ...state.meta, current: { loading: false, loaded: true, error: false } },
        current: {
          ...state.current,
          orgUnit: action.payload.orgUnit,
        },
        orgUnitFormFieldConfigurations: {
          ...state.orgUnitFormFieldConfigurations,
          ...mapKeys(map(action.payload.orgUnitFormFieldConfigurations, orgUnitFormFieldConfiguration => formatOrgUnitFormFieldConfiguration(orgUnitFormFieldConfiguration)), x => x.id),
        },
      };
    case ActionTypes.CREATED_ORG_UNIT_FORM_FIELD_CONFIG:
    case ActionTypes.SAVED_ORG_UNIT_FORM_FIELD_CONFIG:
      return {
        ...state,
        current: {
          ...state.current,
        },
        orgUnitFormFieldConfigurations: {
          ...state.orgUnitFormFieldConfigurations,
          [action.payload.orgUnitFormFieldConfigurations.id]: formatOrgUnitFormFieldConfiguration(action.payload.orgUnitFormFieldConfigurations),
        },
      };
    case ActionTypes.LOADING_CURRENT_ORG_UNIT_FORM_FIELD_CONFIG:
      return {
        ...state,
        meta: {
          ...state.meta,
          orgUnitFormFieldConfigurations: {
            ...state.meta.orgUnitFormFieldConfigurations,
            [action.payload.orgUnitId]: {
              ...state.meta.orgUnitFormFieldConfigurations[action.payload.orgUnitId],
              [action.payload.formFieldConfigurationId]: { loading: true, loaded: false, error: false },
            },
          },
        },
      };
    case ActionTypes.LOADED_CURRENT_ORG_UNIT_FORM_FIELD_CONFIG:
      return {
        ...state,
        orgUnitFormFieldConfigurations: {
          ...state.orgUnitFormFieldConfigurations,
          [action.payload.orgUnitFormFieldConfigurations.id]: formatOrgUnitFormFieldConfiguration(action.payload.orgUnitFormFieldConfigurations),
        },
        meta: {
          ...state.meta,
          orgUnitFormFieldConfigurations: {
            ...state.meta.orgUnitFormFieldConfigurations,
            [action.payload.orgUnitId]: {
              ...state.meta.orgUnitFormFieldConfigurations[action.payload.orgUnitId],
              [action.payload.formFieldConfigurationId]: { loading: false, loaded: true, error: false },
            },
          },
        },
      };
    case ActionTypes.UPDATE_ORG_UNIT_FORM_FIELD_CONFIG:
      return {
        ...state,
        orgUnitFormFieldConfigurations: {
          ...state.orgUnitFormFieldConfigurations,
          ...mapKeys(map(action.payload, orgUnitFormFieldConfiguration => formatOrgUnitFormFieldConfiguration(orgUnitFormFieldConfiguration)), x => x.id),
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_ORG_UNIT_FORM_FIELD_CONFIG:
      return {
        ...state,
        meta: {
          ...state.meta,
          orgUnitFormFields: {
            ...state.meta.orgUnitFormFields,
            [action.payload.orgUnitId]: {
              ...state.meta.orgUnitFormFields[action.payload.orgUnitId],
              [action.payload.formType]: { loading: false, loaded: false, error: true },
            },
          },
        },
      };
    case ActionTypes.SAVED_ORG_UNIT_FORM_FIELDS:
      return {
        ...state,
        orgUnitFormFields: {
          ...state.orgUnitFormFields,
          [action.payload.orgUnitId]: {
            ...state.orgUnitFormFields[action.payload.orgUnitId],
            [action.payload.formType]: {
              ...action.payload.orgUnitFormFields,
              configurationJson: JSON.parse(action.payload.orgUnitFormFields.configurationJson),
            },
          },
        },
        current: {
          ...state.current,
          orgUnitFormFields: map(state.current.orgUnitFormFields,
            x => (x.formType === action.payload.formType ? action.payload.orgUnitFormFields : x)),
        },
      };
    case ActionTypes.LOADING_CURRENT_ORG_UNIT_FORM_FIELDS:
      return {
        ...state,
        meta: {
          ...state.meta,
          orgUnitFormFields: {
            ...state.meta.orgUnitFormFields,
            [action.payload.orgUnitId]: {
              ...state.meta.orgUnitFormFields[action.payload.orgUnitId],
              [action.payload.formType]: { loading: true, loaded: false, error: false },
            },
          },
        },
      };
    case ActionTypes.LOADED_CURRENT_ORG_UNIT_FORM_FIELDS:
      return {
        ...state,
        orgUnitFormFields: {
          ...state.orgUnitFormFields,
          [action.payload.orgUnitId]: {
            ...state.orgUnitFormFields[action.payload.orgUnitId],
            [action.payload.formType]:
            {
              ...action.payload.orgUnitFormFields,
              configurationJson: JSON.parse(action.payload.orgUnitFormFields.configurationJson),
            },
          },
        },
        meta: {
          ...state.meta,
          orgUnitFormFields: {
            ...state.meta.orgUnitFormFields,
            [action.payload.orgUnitId]: {
              ...state.meta.orgUnitFormFields[action.payload.orgUnitId],
              [action.payload.formType]: { loading: false, loaded: true, error: false },
            },
          },
        },
      };
    case ActionTypes.ERROR_LOADING_CURRENT_ORG_UNIT_FORM_FIELDS:
      return {
        ...state,
        meta: {
          ...state.meta,
          orgUnitFormFields: {
            ...state.meta.orgUnitFormFields,
            [action.payload.orgUnitId]: {
              ...state.meta.orgUnitFormFields[action.payload.orgUnitId],
              [action.payload.formType]: { loading: false, loaded: false, error: true },
            },
          },
        },
      };
    case ActionTypes.LOADING_ENDORSEMENT_TYPE_CONFIGURATION:
      return {
        ...state,
        meta: {
          ...state.meta,
          endorsementTypes: {
            ...state.meta.endorsementTypes,
            [action.payload.orgUnitId]: { loading: true, loaded: false, error: false },
          },
        },
      };

    case ActionTypes.SAVED_ENDORSEMENT_TYPE_CONFIGURATION:
    case ActionTypes.LOADED_ENDORSEMENT_TYPE_CONFIGURATION:
      return {
        ...state,
        endorsementTypeSettings: {
          ...state.endorsementTypeSettings, [action.payload.orgUnitId]: action.payload.endorsementTypeSettings,
        },
        meta: {
          ...state.meta,
          endorsementTypes: {
            ...state.meta.endorsementTypes,
            [action.payload.orgUnitId]: { loading: false, loaded: true, error: false },
          },
        },
      };

    case ActionTypes.ERROR_LOADING_ENDORSEMENT_TYPE_CONFIGURATION:
      return {
        ...state,
        meta: {
          ...state.meta,
          endorsementTypes: {
            ...state.meta.endorsementTypes,
            [action.payload.orgUnitId]: { loading: false, loaded: true, error: action.payload.message },
          },
        },
      };

    case ActionTypes.LOADING_NOTIFICATION_CONFIGURATION:
      return {
        ...state,
        meta: {
          ...state.meta,
          notificationConfiguration: {
            ...state.meta.notificationConfiguration,
            [action.payload.orgUnitId]: { loading: true, loaded: false, error: false },
          },
        },
      };

    case ActionTypes.LOADED_NOTIFICATION_CONFIGURATION:
      return {
        ...state,
        notificationConfiguration: {
          ...state.notificationConfiguration, [action.payload.orgUnitId]: action.payload.notificationConfiguration,
        },
        notificationConfigurationPageInfo: action.payload.notificationConfigurationPageInfo,
        meta: {
          ...state.meta,
          notificationConfiguration: {
            ...state.meta.notificationConfiguration,
            [action.payload.orgUnitId]: { loading: false, loaded: true, error: false },
          },
        },
      };

    case ActionTypes.ERROR_LOADING_NOTIFICATION_CONFIGURATION:
      return {
        ...state,
        meta: {
          ...state.meta,
          notificationConfiguration: {
            ...state.meta.notificationConfiguration,
            [action.payload.orgUnitId]: { loading: false, loaded: true, error: action.payload.message },
          },
        },
      };

    case ActionTypes.LOADING_CURRENT_NOTIFICATION_CONFIGURATION:
      return {
        ...state,
        meta: {
          ...state.meta,
          currentNotificationConfiguration: {
            loading: true,
            loaded: false,
            error: false,
          },
        },
      };

    case ActionTypes.SAVED_NOTIFICATION_CONFIGURATION:
    case ActionTypes.LOADED_CURRENT_NOTIFICATION_CONFIGURATION:
      return {
        ...state,
        currentNotificationConfiguration: action.payload.currentNotificationConfiguration,
        meta: {
          ...state.meta,
          currentNotificationConfiguration: {
            loading: false,
            loaded: true,
            error: false,
          },
        },
      };

    case ActionTypes.ERROR_CURRENT_LOADING_NOTIFICATION_CONFIGURATION:
      return {
        ...state,
        meta: {
          ...state.meta,
          currentNotificationConfiguration: { loading: false, loaded: true, error: action.payload.message },
        },
      };

      /** SITES SELECTOR */
    case ActionTypes.LOADING_ORG_UNIT_SELECTOR:
      return {
        ...state,
        meta: {
          ...state.meta,
          selector: { loading: true, loaded: false, error: null },
        },
      };
    case ActionTypes.LOADED_ORG_UNIT_SELECTOR:
      return {
        ...state,
        selector: action.payload.orgUnits,
        meta: {
          ...state.meta,
          selector: { loading: false, loaded: true, error: null },
        },
      };
    case ActionTypes.ERROR_LOADED_ORG_UNIT_SELECTOR:
      return {
        ...state,
        meta: {
          ...state.meta,
          selector: { loading: false, loaded: false, error: action.payload.message },
        },
      };
      /** Pharmacy SELECTOR */
    case ActionTypes.LOADING_ORG_UNIT_PHARMACY_SELECTOR:
      return {
        ...state,
        meta: {
          ...state.meta,
          pharmacySelector: { loading: true, loaded: false, error: null },
        },
      };
    case ActionTypes.LOADED_ORG_UNIT_PHARMACY_SELECTOR:
      return {
        ...state,
        pharmacySelector: action.payload.orgUnits,
        meta: {
          ...state.meta,
          pharmacySelector: { loading: false, loaded: true, error: null },
        },
      };
    case ActionTypes.ERROR_LOADED_ORG_UNIT_PHARMACY_SELECTOR:
      return {
        ...state,
        meta: {
          ...state.meta,
          pharmacySelector: { loading: false, loaded: false, error: action.payload.message },
        },
      };
    /** DECISION LETTER */
    case ActionTypes.LOADING_DECISION_LETTER_TEMPLATE:
    case ActionTypes.CREATING_DECISION_LETTER_TEMPLATE:
    case ActionTypes.DELETING_DECISION_LETTER_TEMPLATE:
      return {
        ...state,
        current: {
          ...state.current,
          currentDecisionLetterTemplate: {
            ...state.current.currentDecisionLetterTemplate,
            loading: true,
          },
        },
      };

    case ActionTypes.LOADED_DECISION_LETTER_TEMPLATE:
    case ActionTypes.CREATED_DECISION_LETTER_TEMPLATE:
    case ActionTypes.DELETED_DECISION_LETTER_TEMPLATE:
    case ActionTypes.ERROR_LOADING_DECISION_LETTER_TEMPLATE:
    case ActionTypes.ERROR_CREATING_DECISION_LETTER_TEMPLATE:
    case ActionTypes.ERROR_DELETING_DECISION_LETTER_TEMPLATE:
      return {
        ...state,
        current: {
          ...state.current,
          currentDecisionLetterTemplate: action.payload,
        },
      };

    /** IPA Form */
    case ActionTypes.LOADING_IPA_BLANK_FORM:
    case ActionTypes.CREATING_IPA_BLANK_FORM:
    case ActionTypes.DELETING_IPA_BLANK_FORM:
      return {
        ...state,
        current: {
          ...state.current,
          currentIPABlankForm: {
            ...state.current.currentIPABlankForm,
            loading: true,
          },
        },
      };

    case ActionTypes.LOADED_IPA_BLANK_FORM:
    case ActionTypes.CREATED_IPA_BLANK_FORM:
    case ActionTypes.DELETED_IPA_BLANK_FORM:
    case ActionTypes.ERROR_LOADING_IPA_BLANK_FORM:
    case ActionTypes.ERROR_CREATING_IPA_BLANK_FORM:
    case ActionTypes.ERROR_DELETING_IPA_BLANK_FORM:
      return {
        ...state,
        current: {
          ...state.current,
          currentIPABlankForm: action.payload,
        },
      };

    /** GP Letter */
    // TODO add loading for each tab instead load them in orgUnit at once
    case ActionTypes.SAVED_GP_LETTER_TEMPLATE:
      return {
        ...state,
        current: {
          ...state.current,
          currentGPLetterTemplates: concat(action.payload.attachment, filter(state.current.currentGPLetterTemplates, x => x.id !== action.payload.attachment.id)),
        },
      };
    case ActionTypes.DELETED_GP_LETTER_TEMPLATE:
      return {
        ...state,
        current: {
          ...state.current,
          currentGPLetterTemplates: filter(state.current.currentGPLetterTemplates, x => x.id !== action.payload.attachmentId),
        },
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const isPageLoading = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loading"], false);
});

export const isPageLoaded = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "loaded"], false);
});

export const getErrorMessage = curry(({ localState }, pageNumber) => {
  const number = pageNumber || get(localState.pageInfo, ["pageNumber"], 1);
  return get(localState, ["pages", number, "error"], null);
});

export const getOrgUnitSearchParams = curry(({ localState }) => localState?.searchParams);

export const getOrgUnitPageInfo = curry(({ localState }) => localState?.pageInfo);

export const getAllOrgUnits = curry(({ localState }) => sortBy(get(localState, ["all"], []), ["orgUnitType", "name"]));

export const getOrgUnits = curry(({ localState, state }) => {
  const orgUnits = getAllOrgUnits(state);
  const pageNumber = get(localState.pageInfo, ["pageNumber"], 1);
  const ids = get(localState.pages, [pageNumber, "ids"], []);
  return map(ids, x => find(orgUnits, y => y.id === x));
});

export const isCurrentLoading = curry(({ localState }) => localState?.meta.current.loading === true);

export const areEndorsementTypesLoading = curry(({ localState }, orgUnitId) => localState?.meta.endorsementTypes[orgUnitId]?.loading === true);

export const areEndorsementTypesLoaded = curry(({ localState }, orgUnitId) => localState?.meta.endorsementTypes[orgUnitId]?.loaded === true);

export const getEndorsementTypeSettings = curry(({ localState }, orgUnitId) => localState.endorsementTypeSettings[orgUnitId] ?? []);

export const getCurrentDecisionLetterTemplate = curry(({ localState }) => localState.current.currentDecisionLetterTemplate);

export const getCurrentIPABlankForm = curry(({ localState }) => localState.current.currentIPABlankForm);

export const isCurrentOrgUnitDecisionLetterTemplateLoading = curry(({ localState }) => localState.current.currentDecisionLetterTemplate?.loading === true);

export const isCurrentOrgUnitIPABlankFormLoading = curry(({ localState }) => localState.current.currentIPABlankForm?.loading === true);

export const getCurrentOrgUnit = curry(({ localState }) => localState?.current.orgUnit);

export const getCurrentGPLetters = curry(({ localState }) => sortBy(localState.current.currentGPLetterTemplates, ["id"]));

export const areCurrentOrgUnitFormFieldsLoading = curry(({ localState }, orgUnitId, formType) => localState?.meta.orgUnitFormFields[orgUnitId]?.[formType]?.loading === true);

export const areCurrentOrgUnitFormFieldsLoaded = curry(({ localState }, orgUnitId, formType) => localState?.meta.orgUnitFormFields[orgUnitId]?.[formType]?.loaded === true);

export const areOrgUnitFormFieldConfigByIdLoading = curry(({ localState }, orgUnitId, formFieldConfigurationId) => localState?.meta.orgUnitFormFieldConfigurations[orgUnitId]?.[formFieldConfigurationId]?.loading === true);

export const areOrgUnitFormFieldConfigByIdLoaded = curry(({ localState }, orgUnitId, formFieldConfigurationId) => localState?.meta.orgUnitFormFieldConfigurations[orgUnitId]?.[formFieldConfigurationId]?.loaded === true);

export const getOrgUnitFormFieldConfigs = curry(({ localState }) => get(localState, ["orgUnitFormFieldConfigurations"], []));

export const getCurrentOrgUnitAllFormFields = curry(({ localState }) => localState?.current.orgUnitFormFields);

export const formatFieldSettings = configs => {
  if (configs) {
    const enabledFields = filter(configs, x => x.enabled === true);
    return reduce(enabledFields, (r, setting) => ({
      ...r,
      [lowerFirst(setting.fieldName)]: {
        required: setting.required,
        label: setting.label,
        fieldName: setting.fieldName,
        enabledOCR: setting.enabledOCR,
        enabledReadOnly: setting.enabledReadOnly === true,
      },
    }), {});
  }
  return null;
};

export const getOrgUnitFormFieldConfigsById = curry(({ localState }, formFieldConfigurationId) => {
  const orgUnitFormFieldConfigurations = get(localState, ["orgUnitFormFieldConfigurations"], []);
  const configs = find(orgUnitFormFieldConfigurations, x => x.id === formFieldConfigurationId)?.configurationJson;

  return formatFieldSettings(configs);
});

export const getCurrentOrgUnitFormFields = curry(({ localState }, orgUnitId, formType) => {
  const configs = get(localState.orgUnitFormFields, [orgUnitId, formType, "configurationJson"], null);
  return formatFieldSettings(configs);
});

export const getCurrentOrgUnitFormFieldConfigByIdField = curry(({ localState }, orgUnitId, formFieldConfigurationId, fieldName) => {
  const configs = get(localState.orgUnitFormFields, [orgUnitId, formFieldConfigurationId], {});
  if (configs) {
    const setting = find(configs, x => x.fieldName === fieldName);
    return setting ? {
      required: setting.required,
      label: setting.label,
      fieldName: setting.fieldName,
      enabledOCR: setting.enabledOCR,
      enabledReadOnly: setting.enabledReadOnly === true,
    } : {};
  }
  return null;
});

export const getAllOrgUnitsByType = curry(({ state }, orgUnitType) => filter(getAllOrgUnits(state), x => x.orgUnitType === orgUnitType));

export const isOrgUnitsSelectorLoading = curry(({ localState }) => localState.meta.selector.loading);

export const isOrgUnitsSelectorLoaded = curry(({ localState }) => localState.meta.selector.loaded);

export const getOrgUnitsForOrgUnitsSelector = curry(({ localState }) => sortBy(get(localState, ["selector"], []), ["orgUnitType", "name"]));

export const getOrgUnitsForPharmacySelector = curry(({ localState }) => sortBy(get(localState, ["pharmacySelector"], []), ["orgUnitType", "name"]));

export const getAuthorizedOrgUnits = curry(({ state }) => {
  const orgUnits = getOrgUnitsForOrgUnitsSelector(state);
  const authorizedOrgUnitIds = getActiveOrgUnitIds(state);
  return filter(orgUnits, x => includes(authorizedOrgUnitIds, x.id));
});

export const isNotificationConfigurationLoading = curry(({ localState }, orgUnitId) => localState?.meta.notificationConfiguration[orgUnitId]?.loading === true);

export const isNotificationConfigurationLoaded = curry(({ localState }, orgUnitId) => localState?.meta.notificationConfiguration[orgUnitId]?.loaded === true);

export const getNotificationConfigurations = curry(({ localState }, orgUnitId) => localState.notificationConfiguration[orgUnitId] ?? []);

export const getCurrentNotificationConfiguration = curry(({ localState }) => localState.currentNotificationConfiguration);

export const getNotificationConfigurationPageInfoPageInfo = curry(({ localState }) => localState.notificationConfigurationPageInfo);

export const isCurrentNotificationConfigurationLoading = curry(({ localState }) => localState?.meta.currentNotificationConfiguration?.loading === true);

export const getConfiguredFormularies = curry(({ state }) => get(state, ["orgUnits", "formularies"], []));
