export const OPEN_DIALOG = "[DIALOG] OPEN";
export const CLOSE_DIALOG = "[DIALOG] CLOSE";
export const SET_DIALOG_OPTIONS = "SET_DIALOG_OPTIONS";
export const RESET_DIALOG = "[DIALOG] RESET";

export function closeDialog() {
  return {
    type: CLOSE_DIALOG,
  };
}

export function openDialog(options, fullHeight) {
  return {
    type: OPEN_DIALOG,
    options,
    fullHeight,
  };
}

export function setDialogOptions(options) {
  return {
    type: SET_DIALOG_OPTIONS,
    options,
  };
}

export function resetDialog() {
  return {
    type: RESET_DIALOG,
  };
}
