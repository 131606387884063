import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { map, isEmpty } from "lodash";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchOrgUnitsForSelector } from "app/main/orgUnits/actions/orgUnit.actions";
import { OrgUnitsIcon } from "helpers/icon-finder";

export default ({
  label,
  showIcon = true,
  initialOptions = [],
  loadInitialOption = false,
  orgUnitId,
  onChange,
  ...other
}) => {
  const dispatch = useDispatch();
  const { field: { value } } = other;
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const shouldLoadInitialOption = loadInitialOption && value && isEmpty(initialOptions);

  const loadOptions = () => {
    setLoading(true);
    setOptions([]);
    dispatch(fetchOrgUnitsForSelector({ orgUnitTypes: ["Site"] })).then(response => {
      if (response) {
        setOptions(response.error ? [] : response.payload.orgUnits);
      } else {
        setOptions([]);
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (shouldLoadInitialOption) {
      setInitialLoading(true);
      loadOptions();
      setInitialLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadInitialOption, orgUnitId]);

  useEffect(() => {
    if (!shouldLoadInitialOption && options !== initialOptions) {
      setOptions(initialOptions);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadInitialOption, JSON.stringify(initialOptions)]);

  const loadOptionsOnOpen = () => loadOptions();

  if (initialLoading) return <AutoCompleteLoading />;

  return (
    <AutoComplete
      icon={showIcon && <OrgUnitsIcon />}
      label={label}
      loading={loading}
      onOpen={shouldLoadInitialOption ? null : loadOptionsOnOpen}
      options={map(options, x => ({ label: x.name, value: x.id }))}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      onChange={onChange}
      {...other}
    />
  );
};
