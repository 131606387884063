import React from "react";
import { Input } from "@material-ui/core";
import IconButton from "components/items/icon-button";

export default function SearchInput(props) {
  const {
    field,
    label,
    required,
    icon,
    onSearch,
    ...other
  } = props;
  return (
    <Input
      className="flex-1 pl-24"
      {...field}
      placeholder={label || "Search"}
      type="search"
      value={field.value || ""}
      fullWidth
      disableUnderline
      onKeyPress={e => (e.keyCode === 13 || e.which === 13 ? onSearch() : null)}
      endAdornment={<IconButton aria-label="Search" title="Search" onClick={onSearch} icon="search" />}
      {...other}
    />
  );
}
