import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import DefaultPageLayout from "components/layouts/default-page-layout";
import ScreenHeader from "components/items/screen-header";
import DefaultButton from "components/items/default-button";
import useSetUrl from "app/main/admin/hooks/useSetUrl";
import { fetchNotificationConfigurationByType } from "app/main/orgUnits/actions/orgUnit.actions";
import OrgUnitPrimaryHeader from "app/main/orgUnits/components/orgUnit-primary-header";
import { getCurrentNotificationConfiguration, isNotificationConfigurationLoading } from "../reducers/orgUnit.reducers";
import OrgUnitNotificationConfigurationForm from "../components/orgUnit-notification-configuration-form";

const NotificationConfigurationEditPage = ({
  match: { params: { id, eventType } },
  route,
}) => {
  const dispatch = useDispatch();

  const loading = useSelector(isNotificationConfigurationLoading);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (!loading && eventType) {
      dispatch(fetchNotificationConfigurationByType(id, eventType));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const current = useSelector(getCurrentNotificationConfiguration);
  useSetUrl(route);

  const renderActions = () => (
    <>
      <DefaultButton label="Edit" variant="outlined" color="inherit" icon="edit" onClick={() => setDisabled(false)} disabled={!disabled} />
    </>
  );

  const onSucceed = () => {
    setDisabled(true);
    // reset selector data, so if will fetch new data again in case of field changes
  };

  return (
    <>
      <OrgUnitPrimaryHeader orgUnitId={id} />
      <DefaultPageLayout
        loading={loading}
        header={(
          <ScreenHeader
            title={eventType}
            renderActions={renderActions()}
          />
        )}
        content={(
          <OrgUnitNotificationConfigurationForm
            current={current}
            disabled={disabled}
            onCancel={() => setDisabled(true)}
            onSucceed={onSucceed}
            orgUnitId={id}
          />
        )}
      />
    </>
  );
};

export default NotificationConfigurationEditPage;
