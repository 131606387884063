import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { NoteOff } from "helpers/icon-finder";
import MarkdownPreview from "components/items/mark-down-preview";

const useStyles = makeStyles(theme => ({
  contentContainer: {
    flexDirection: "column",
    display: "flex",
    height: "100%",
  },
  dropZone: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    borderWidth: 2,
    borderRadius: 2,
    borderColor: "#eeeeee",
    borderStyle: "dashed",
    backgroundColor: "#fafafa",
    color: "#777",
    outline: "none",
    transition: "border .24s ease-in-out",
    justifyContent: "center",
  },
}));

const ReferralSourceDocumentDisableUpload = ({ helperText, showIcon }) => {
  const classes = useStyles();

  return (
    <div className={classes.contentContainer}>
      <div className={classes.dropZone}>
        <MarkdownPreview markdownText={helperText} showBorder={false} />
        {showIcon && <NoteOff size={48} />}
      </div>
    </div>
  );
};

export default ReferralSourceDocumentDisableUpload;
