import { get, find, groupBy, map, isEmpty, filter, includes, sortBy, startCase } from "lodash";
import moment from "moment";
import curryConnector from "utils/connectors";

export const SYSTEM_CONFIGURATION_STATE_KEY = "systemconfiguration";

export const ActionTypes = {
  LOADING_SYSTEMCONFIGURATION: "LOADING_SYSTEMCONFIGURATION",
  ERROR_LOADING_SYSTEMCONFIGURATION: "ERROR_LOADING_SYSTEMCONFIGURATION",
  LOADED_SYSTEMCONFIGURATION: "LOADED_SYSTEMCONFIGURATION",
};

export const thresholdPercentage = 70;

export const convertPercentage = (value, decimalPlaces = null) => {
  if (value) {
    const percentage = (value * 100);
    if (decimalPlaces === null) return percentage;
    if (decimalPlaces === 0) return Math.round(percentage);
    return percentage.toFixed(decimalPlaces);
  }
  return undefined;
};

const initialState = {
  loading: false,
  loaded: false,
  error: null,
};

const curry = fn => curryConnector(fn, SYSTEM_CONFIGURATION_STATE_KEY);

export default (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.LOADING_SYSTEMCONFIGURATION:
      return { ...state, loading: true, loaded: false, error: false };
    case ActionTypes.ERROR_LOADING_SYSTEMCONFIGURATION:
      return { ...state, loading: false, loaded: false, error: true };
    case ActionTypes.LOADED_SYSTEMCONFIGURATION:
      return {
        ...state,
        loading: false,
        error: false,
        loaded: true,
        loadedDateTimeUtc: moment.utc(),
        ...action.payload,
        helperText: groupBy(action.payload.helperText, "section"),
      };
    default:
      return state || initialState;
  }
};

export const isSystemConfigurationLoading = curry(({ localState }) => get(localState, ["loading"], false) === true);

export const errorLoadingSystemConfiguration = curry(({ localState }) => get(localState, ["error"], false) === true);

export const isSystemConfigurationLoaded = curry(({ localState }) => get(localState, ["loaded"], null) === true);

export const getSystemConfigurationloadedDateTimeUtc = curry(({ localState }) => get(localState, ["loadedDateTimeUtc"], null));

export const getApiVersion = curry(({ localState }) => get(localState, ["apiVersion"], null));

export const getApiDescription = curry(({ localState }) => get(localState, ["apiDescription"], null));

export const getExternalHelpPage = curry(({ localState }) => get(localState, ["externalHelpPage"], null));

export const getAllEnrolmentStatus = curry(({ localState }) => get(localState, ["enrolmentStatusValues"], null));

export const getEncounterTypeValues = curry(({ localState }) => get(localState, ["encounterTypeValues"], null));

export const getContactMethodValues = curry(({ localState }) => get(localState, ["encounterContactMethodValues"], null));

export const getContactValues = curry(({ localState }) => get(localState, ["encounterContactValues"], null));

export const getLocationTypeValues = curry(({ localState }) => get(localState, ["encounterLocationValues"], null));

export const getAllTriageCategorySettings = curry(({ localState }) => localState.triageCategorySettings?.all);

export const getEnabledTriageCategorySettings = curry(({ localState }) => sortBy(localState.triageCategorySettings?.enabled, ["displayOrder"]));

export const getApplicationStatusValues = curry(({ localState }) => get(localState, ["applicationStatusValues"], null));

export const getPatientSettingValues = curry(({ localState }) => get(localState, ["patientSettingValues"], null));

export const getDecisionStatusValues = curry(({ localState }) => get(localState, ["decisionStatusValues"], null));

export const getSystemConfigByName = curry(({ localState }, name) => get(localState, name, null));

export const getDefaultAddressState = curry(({ localState }) => get(localState, ["defaultAddressState"], null));

export const getHelperTextBySectionName = curry(({ localState }, section) => {
  const group = get(localState, ["helperText", section], null);
  const keySet = {};
  map(group, x => {
    keySet[x.key] = x.text;
  });
  return keySet;
});

export const getRelationshipTypeValues = curry(({ localState }) => get(localState, ["relationshipTypeValues"], null));
export const getEnrolmentTypeValues = curry(({ localState }) => get(localState, ["enrolmentTypeValues"], null));
export const getReferralWorkflowStateSettings = curry(({ localState }) => get(localState, ["referralWorkflowStateSettings"], null));
export const getReferralActionTypeSettings = curry(({ localState }) => get(localState, ["referralActionTypeSettings"], null));
export const getAllowUndoActionStates = curry(({ localState }) => get(localState, ["allowUndoActionStates"], null));
export const getAllowCorrespondenceAttachmentStates = curry(({ localState }) => get(localState, ["allowCorrespondenceAttachmentStates"], null));
export const getSexOptions = curry(({ localState }) => get(localState, ["sexOptions"], null));
// Identifier Settings
export const getPrimaryPatientIdentifier = curry(({ localState }) => get(localState, ["primaryPatientIdentifierSettings", "type"], null));
export const getPrimaryPatientIdentifierSettings = curry(({ localState }) => get(localState, ["primaryPatientIdentifierSettings"], null));
export const getPatientIdentifierTypeOptions = curry(({ localState }, filterAllowedOptions = false) => {
  const allOptions = localState?.primaryPatientIdentifierSettings?.options;
  const allowedTypes = localState?.primaryPatientIdentifierSettings?.displayPatientIdentifierTypes;

  if (!filterAllowedOptions || isEmpty(allowedTypes)) {
    return allOptions;
  }

  return filter(allOptions, x => includes(allowedTypes, x.value));
});

export const getPatientCardSourceSystemIdentifiers = curry(({ localState }) => get(localState, ["patientCardSourceSystemIdentifiers"], null));
export const getPatientCardOptions = curry(({ localState }) => get(localState, ["patientCardTypeCodeValues"], null));
export const getDisplayNameForExternalSourceDocumentWorklist = curry(({ localState }) => get(localState, ["externalSourceDocumentWorklistDisplayName"], null));
export const getReferralWorklistSettings = curry(({ localState }) => get(localState, ["referralWorklistSettings"], null));
export const getOrgUnitSettings = curry(({ localState }, orgUnitType) => {
  const orgUnits = get(localState, ["orgUnitTypeSettings"], null);
  if (orgUnitType) {
    return find(orgUnits, x => x.orgUnitType === orgUnitType);
  }
  return orgUnits;
});

export const getAllRoles = curry(({ localState }) => get(localState, ["allRoles"], null));

export const getAllAssignableRoles = curry(({ localState }) => {
  const allRoles = get(localState, ["allRoles"], null);
  const assignableRoles = filter(allRoles, x => x.isAssignable === true);

  return assignableRoles;
});

export const getOverviewPanelSettings = curry(({ localState }) => {
  const settings = localState?.overviewPanelSettings;
  return sortBy(filter(settings, x => x.enabled === true), ["sortOrder"]);
});

export const getUrgencyTreatmentTypeSettings = curry(({ localState }) => get(localState, ["urgencyTreatmentTypeSettings"], null));

export const getEndorsementDecisionValues = curry(({ localState }) => get(localState, ["endorsementDecisionValues"], null));

export const getDecisionTemplateAcceptFormat = curry(({ localState }) => get(localState, ["decisionTemplateAcceptFormat"], null));

export const getIPABlankFormAcceptFormatAcceptFormat = curry(({ localState }) => get(localState, ["ipaBlankFormAcceptFormat"], null));

export const getGPLetterAcceptFormat = curry(({ localState }) => get(localState, ["gpLetterAcceptFormat"], null));

export const getChildOrgUnitSettings = curry(({ localState }, orgUnitType) => {
  const allOrgUnitTypeSettings = get(localState, ["orgUnitTypeSettings"], null);
  return filter(allOrgUnitTypeSettings, x => x.parentOrgUnitType === orgUnitType);
});
export const getSubSpecialtyLabel = curry(({ localState }) => get(localState, ["subSpecialtyDescription"], null));

export const getCodeSetTypes = curry(({ localState }) => get(localState, ["codeSetTypes"], null));

export const getFullModePatientSearch = curry(({ localState }) => get(localState?.patientSearchSettings, ["fullModePatientSearch"], false));

export const showExternalPatientSearch = curry(({ localState }) => get(localState?.patientSearchSettings, ["enableExternalPatientSearch"], false));

export const enableFreeTextOnProviderInput = curry(({ localState }) => get(localState, ["enableFreeTextOnProviderInput"], false));

export const getUserAdminOptionalFields = curry(({ localState }) => get(localState, ["userAdminOptionalFields"], null));

export const getOrgUnitAdminTabs = curry(({ localState }) => get(localState, ["orgUnitAdminTabs"], null));

export const getAllowReassignSpecialtyStates = curry(({ localState }) => get(localState, ["allowReassignSpecialtyStates"], null));

export const getAllowReassignSubSpecialtyStates = curry(({ localState }) => get(localState, ["allowReassignSubSpecialtyStates"], null));

export const getAllowReassignRoleStates = curry(({ localState }) => get(localState, ["allowReassignRoleStates"], null));

export const getGPLetterTemplateOptions = curry(({ localState }) => get(localState, ["gpLetterTemplateOptions"], null));

export const getGPLetterOutputTypes = curry(({ localState }) => get(localState, ["gpLetterOutputTypes"], null));

export const getEnableMultipleUserRoles = curry(({ localState }) => get(localState, ["enableMultipleUserRoles"], false));

export const getEnableTypeOfReferralForFormProfile = curry(({ localState }) => get(localState, ["enableTypeOfReferralForFormProfile"], false));

export const getNationalityLabel = curry(({ localState }) => get(localState, ["patientNationalityDescription"], false));

export const getVeteranMilitaryStatusOptions = curry(({ localState }) => get(localState, ["veteranMilitaryStatusOptions"], null));

export const getAllowAutoAssignRoleActionTypes = curry(({ localState }) => {
  const referralActionTypeSettings = localState?.referralActionTypeSettings;
  const allowAutoAssignRoleActionTypes = localState?.allowAutoAssignRoleActionTypes;
  return map(allowAutoAssignRoleActionTypes, x => {
    const actionType = find(referralActionTypeSettings, setting => setting.actionType === x);
    if (actionType) {
      return ({
        label: actionType.nameSingle,
        value: x,
      });
    }
    return ({
      label: startCase(x),
      value: x,
    });
  });
});

export const getAssignToTypes = curry(({ localState }) => get(localState, ["assignToTypes"], null));

export const enableSMSConsentLabelDisplay = curry(({ localState }) => get(localState, ["enableSMSConsentLabelDisplay"], false));

export const getEnablePrimaryIdentifierBannerDisplay = curry(({ localState }) => get(localState, ["enablePrimaryIdentifierBannerDisplay"], false));

export const getEpisodeHealthConditionMaxLength = curry(({ localState }) => get(localState, ["episodeHealthConditionMaxLength"], 5));

export const getPresentingComplaintMaxLength = curry(({ localState }) => get(localState, ["presentingComplaintMaxLength"], 1));

export const getDisplayUsualGPProps = curry(({ localState }) => get(localState, ["displayUsualGPProps"], false));

export const getPatientSearchMandatoryFieldsNumber = curry(({ localState }) => get(localState?.patientSearchSettings, ["externalPatientSearchMandatoryFieldsNumber"], 2));

export const getReferralWorkflowEndStates = curry(({ localState }) => get(localState, ["referralWorkFlowEndStates"], null));

export const getAllowNotScannedFileView = curry(({ localState }) => get(localState, ["allowNotScannedFileView"], false));

export const enablePatientIdentifierSearch = curry(({ localState }) => get(localState?.patientSearchSettings, ["enablePatientIdentifierSearch"], false));

export const getDisplaySourceIndicator = curry(({ localState }) => get(localState?.patientSearchSettings, ["displaySourceIndicator"], false));

export const getDobCountsTowardMandatoryFieldsNumber = curry(({ localState }) => get(localState?.patientSearchSettings, ["dobCountsTowardMandatoryFieldsNumber"], true));

export const getEnableMultipleUserSpecialties = curry(({ localState }) => get(localState, ["enableMultipleUserSpecialties"], false));

export const getNotificationContentParams = curry(({ localState }) => get(localState, ["notificationContentParams"], false));

export const getReferralSortByOptions = curry(({ localState }) => get(localState, ["referralSortByOptions"], null));

export const getEnableAutoGPLetterGeneration = curry(({ localState }) => get(localState, ["enableAutoGPLetterGeneration"], false));

export const getSendOutboundMessageStates = curry(({ localState }) => get(localState, ["sendOutboundMessageStates"], null));

export const getEnableNewReferralSendOutboundMessage = curry(({ localState }) => get(localState, ["enableNewReferralSendOutboundMessage"], false));

export const getAllowUserAssignment = curry(({ localState }) => get(localState, ["allowUserAssignment"], false));

export const useExternalMedicationCostQuery = curry(({ localState }) => get(localState, ["useExternalMedicationCostQuery"], false));

export const getDecimalNumberRegex = curry(({ localState }) => get(localState, ["decimalNumberRegex"], null));

export const getEnableRecordReferralActionAccess = curry(({ localState }) => get(localState, ["enableRecordReferralActionAccess"], false));

export const getEnableWarningMessageForPrintWithAttachments = curry(({ localState }) => get(localState, ["enableWarningMessageForPrintWithAttachments"], false));

export const getWarningTextForPrintWithAttachments = curry(({ localState }) => get(localState, ["warningTextForPrintWithAttachments"], null));

export const getAllowOnlyAssignedSpecialties = curry(({ localState }) => get(localState, ["allowOnlyAssignedSpecialties"], false));

export const getDisplayReferrerUrgencyStates = curry(({ localState }) => get(localState, ["displayReferrerUrgencyStates"], null));

export const getEnablePreventAddEditNotesInEndState = curry(({ localState }) => get(localState, ["enablePreventAddEditNotesInEndState"], null));

export const getReferralFilterItemsSettings = curry(({ localState }) => get(localState, ["referralFilterItemsSettings"], null));

export const getEnableGPLetters = curry(({ localState }) => get(localState, ["enableGPLetters"], false));

export const getFriendlyUploadTypeDescription = curry(({ localState }) => get(localState, ["friendlyUploadTypeDescription"], false));

export const getOnlyAllowRequestedReferralsAssignedSiteEdit = curry(({ localState }) => get(localState, ["onlyAllowRequestedReferralsAssignedSiteEdit"], false));

export const getEnableOCR = curry(({ localState }) => get(localState, ["enableOCR"], null));

export const getOCRSpecialtyProfilesLabel = curry(({ localState }) => get(localState, ["ocrSpecialtyProfilesLabel"], "Referred to Specialty (OCR use Only)"));

export const getEnableStreamlinedAddReferral = curry(({ localState }) => get(localState, ["enableStreamlinedAddReferral"], null));

export const getAllowReassignPatientStates = curry(({ localState }) => get(localState, ["allowReassignPatientStates"], null));

export const getEnableReadOnly = curry(({ localState }) => get(localState, ["enableReadOnly"], false));

export const getEnableSRCEnabledSetting = curry(({ localState }) => get(localState, ["enableSRCEnabledSetting"], false));

export const getAllowEditPresentingComplaintsStates = curry(({ localState }) => get(localState, ["allowEditPresentingComplaintsStates"], null));

export const getReferralSummaryLayoutSetting = curry(({ localState }) => get(localState, ["referralSummaryLayoutSetting"], null));

export const getReferralFormLayoutSetting = curry(({ localState }) => get(localState, ["referralFormLayoutSetting"], null));

export const getAllowedFutureDateMinutes = curry(({ localState }) => get(localState, ["allowedFutureDateMinutes"], null));

export const getEnableSendGPLetterAdditionalMessage = curry(({ localState }) => get(localState, ["enableSendGPLetterAdditionalMessage"], true));

export const getEnableSendGPLetterActionLabel = curry(({ localState }) => get(localState, ["sendGPLetterActionLabel"], true));

export const getReferralCorrespondenceLetterHistoryTitle = curry(({ localState }) => get(localState, ["referralCorrespondenceLetterHistoryTitle"], true));

export const getOpenGPLetterInNewTab = curry(({ localState }) => get(localState, ["openGPLetterInNewTab"], true));

export const getSexLabel = curry(({ localState }) => get(localState, ["sexLabel"], null));

export const getDecimalPlacesRegex = curry(({ localState }) => get(localState, ["decimalPlacesRegex"], null));

export const getUseExternalDispensingQuery = curry(({ localState }) => get(localState, ["useExternalDispensingQuery"], false));

export const getDisplayExtraRMSFieldsInSpecialtyForm = curry(({ localState }) => get(localState, ["displayExtraRMSFieldsInSpecialtyForm"], false));

export const getAutomaticCloneAfterReportBackSubmitted = curry(({ localState }) => get(localState, ["automaticCloneAfterReportBackSubmitted"], false));

export const getEnableCKMSDrugSearch = curry(({ localState }) => get(localState, ["enableCKMSDrugSearch"], false));

export const getAllowReferralProfileFormTypes = curry(({ localState }) => get(localState, ["allowReferralProfileFormTypes"], null));

export const getOnCreateRequestedReferralNotificationMessage = curry(({ localState }) => get(localState, ["onCreateRequestedReferralNotificationMessage"], null));

export const getOnCreateDraftReferralNotificationMessage = curry(({ localState }) => get(localState, ["onCreateDraftReferralNotificationMessage"], null));

export const getEnableReferralRequirements = curry(({ localState }) => get(localState, ["enableReferralRequirements"], false));

export const getReferringSiteLabel = curry(({ localState }) => get(localState, ["referringSiteLabel"], null));

export const getSpecialtyLabel = curry(({ localState }) => get(localState, ["specialtyLabel"], null));

export const getSpecialtyFormProfileLabel = curry(({ localState }) => get(localState, ["specialtyFormProfileLabel"], null));

export const getHideHealthLinkOnlyTypeCodes = curry(({ localState }) => get(localState, ["hideHealthLinkOnlyTypeCodes"], []));

export const getReferralSourceDocumentUploadDisabledMarkdown = curry(({ localState }) => get(localState, ["referralSourceDocumentUploadDisabledMarkdown"], null));

export const getShowReferralSourceDocumentUploadDisableIcon = curry(({ localState }) => get(localState, ["showReferralSourceDocumentUploadDisableIcon"], true));

export const getEnableMultiFormularyCKMSDrugSearch = curry(({ localState }) => get(localState, ["enableMultiFormularyCKMSDrugSearch"], false));
export const getReportingUseHangfireQueuing = curry(({ localState }) => get(localState, ["reportingUseHangfireQueuing"], false));
