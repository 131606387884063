import { head, find, filter, map, isEmpty, includes, some } from "lodash";
import curryConnector from "utils/connectors";
import { ActionTypes as ReferralsActionTypes } from "app/main/referrals/reducers/referrals.reducers";
import { getAllRoles, getReferralFilterItemsSettings, getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";
import { getUser, checkPermission } from "app/auth/store/reducers/user.reducer";

export const REFERRAL_WORKLIST_STATE_KEY = "referralWorklist";

const curry = fn => curryConnector(fn, REFERRAL_WORKLIST_STATE_KEY);

export const ActionTypes = {
  SEARCHING_REFERRALS: "SEARCHING_REFERRALS",
  SEARCHED_REFERRALS: "SEARCHED_REFERRALS",
  ERROR_SEARCHING_REFERRALS: "ERROR_SEARCHING_REFERRALS",

  UPDATE_REFERRAL_WORKLIST_FILTER: "UPDATE_REFERRAL_WORKLIST_FILTER",
  RESET_REFERRAL_WORKLIST_FILTER: "RESET_REFERRAL_WORKLIST_FILTER",
  SETUP_REFERRAL_WORKLIST_FILTERS: "SETUP_REFERRAL_WORKLIST_FILTERS",

  UPDATE_REFERRAL_WORKLIST_FILTER_TAGS: "UPDATE_REFERRAL_WORKLIST_FILTER_TAGS",
  RESET_REFERRAL_WORKLIST_FILTER_TAGS: "RESET_REFERRAL_WORKLIST_FILTER_TAGS",

  CLEAR_LAST_SEARCH_PARAMS: "CLEAR_LAST_SEARCH_PARAMS",
};

export const getSortTitle = (sortBy, sortOrderDescending) => {
  if (sortBy === "TriageCategory") {
    return sortOrderDescending ? "Desc" : "Asc";
  }

  return sortOrderDescending ? "Newest First" : "Oldest First";
};

const defaultFilterOptions = (insertOptions = null, clinicalRoleReferralActiveView = false) => {
  const options = [
    clinicalRoleReferralActiveView
      ? { value: "clinicalRoleActiveView", label: "Active Referrals" }
      : { value: "active", label: "Active Referrals" },
    // TEMP configured for TAS in dev/tasrms
    { value: "unread", label: "Unread Updates", configurable: true },
    { value: "waitingPeriodLongerThan10Days", label: "Waiting Longer Than 10 Days", configurable: true },
    { value: "waitingPeriodLongerThan30Days", label: "Waiting Longer Than 30 Days", configurable: true },
    { value: "myOutstandingTasks", label: "My Outstanding Tasks", configurable: true },
    { value: "clinicSpecialistFilter", label: "Clinic Specialist Filter", configurable: true },
    { value: "unAssignedReferrals", label: "Unassigned Referrals", configurable: true },
    { value: "myReferrals", label: "My Referrals", configurable: true },
    { value: "advancedFilter", label: "Advanced Filter" },
  ];

  if (insertOptions) {
    options.splice(1, 0, ...insertOptions);
  }

  return options;
};

// eslint-disable-next-line max-len
const filteredFilterOptionByConfigSettings = (filterOptions, referralFilterItemsSettings) => filter(filterOptions, x => x.configurable !== true || some(referralFilterItemsSettings, y => y.toLowerCase() === x.value.toLowerCase()));

const initialOptions = defaultFilterOptions();
const initialDefaultFilterOption = head(initialOptions);

export const defaultSearchParams = {
  term: null,
  status: null,
  category: null,
  dateRange: [null, null],
  startDate: null,
  endDate: null,
  assignedTo: null,
  onlyShowUnassigned: false,
  unreadAddendum: false,
  isUrgent: false,
  sortOrderDescending: true,
  sortBy: "ReferralDate",
  taskStatus: null,
  assignedToSpecialtyIds: null,
  assignedToRoleIds: null,
  assignedToRoles: null,
  assignedToUserId: null,
  daysSinceLastStatusChange: null,
  assignedToSubSpecialtyIds: null,
  taskAssignedToUserId: null,
  taskAssignedToRoleIds: null,
  taskAssignedToUser: null,
  taskAssignedToRole: null,
  applyUrgentReferralFirst: false,
  isReferrerUrgent: false,
  referringOrgUnitIds: null,
  referringLocationCodeIds: null,
  reasonForReferralCodeIds: null,
  typeOfReferralCodeIds: null,
  presentingComplaintCodes: [],
};

export const getPresetParams = (options, filterValue, presetSearchParams, allRoles) => {
  const value = filterValue?.value ?? filterValue;
  const statusFilter = find(options, x => x.value === value && x?.title === "Referrals"); // Referrals status filter

  let searchParams = { ...defaultSearchParams };
  if (presetSearchParams) {
    searchParams = { ...defaultSearchParams, ...presetSearchParams };
  }

  if (statusFilter) {
    return {
      ...searchParams,
      status: [value],
    };
  }

  switch (value) {
    case "active":
      return {
        ...searchParams,
        status: map(filter(options, setting => setting.isActive === true), x => x.value),
      };
    case "clinicalRoleActiveView":
      return {
        ...searchParams,
        status: map(filter(options, setting => setting.isActive === true), x => x.value),
        sortBy: "TriageCategory",
      };
    case "unread":
      return {
        ...searchParams,
        unreadAddendum: true,
      };
    case "waitingPeriodLongerThan30Days":
      return {
        ...searchParams,
        daysSinceLastStatusChange: 30,
        status: ["Processing", "Requested", "AwaitingInformation"],
      };
    case "waitingPeriodLongerThan10Days":
      return {
        ...searchParams,
        daysSinceLastStatusChange: 10,
        status: ["Requested", "AwaitingInformation"],
      };
    case "clinicSpecialistFilter":
      const clinicSpecialistRoleId = find(allRoles, x => x.roleName === "ClinicSpecialist")?.roleId;
      return {
        ...searchParams,
        assignedToRoleIds: clinicSpecialistRoleId,
        status: ["Requested", "AwaitingInformation"],
      };
    case "unAssignedReferrals":
      return {
        ...searchParams,
        onlyShowUnassigned: true,
        status: ["Draft", "Requested"],
        assignedToSpecialtyIds: null,
        assignedToRoleIds: null,
        assignedToRoles: null,
        assignedToUserId: null,
      };
    case "myReferrals":
      return {
        ...searchParams,
        status: map(filter(options, setting => setting.isActive === true), x => x.value),
      };
    default:
      return searchParams;
  }
};

const INITIAL_STATE = {
  searchParams: defaultSearchParams,
  loading: false,
  error: null,
  loaded: false,
  presetFilter: initialDefaultFilterOption,
  pageNumber: 1,
  total: 0,
  filterOptions: initialOptions,
  defaultFilterOption: initialDefaultFilterOption,
  filterIsSetUp: false,
  searchTags: null,
  showStatusTags: false,
  includeAllSites: false,
};

export const getDefaultSortOrder = curry(({ state }) => {
  const referralWorklistSettings = getReferralWorklistSettings(state);

  return {
    sortOrderDescending: referralWorklistSettings?.worklistSortOrder === "desc",
    applyUrgentReferralFirst: referralWorklistSettings?.defaultUrgentReferralFirst,
  };
});

export const IsEnableClinicalRoleReferralActiveView = curry(({ state }) => {
  const isEnableClinicalRoleReferralActiveView = checkPermission(state, "ReferralDefaultClinicalRoleActiveView");
  return isEnableClinicalRoleReferralActiveView;
});

export const IsEnableReferralWorklistDefaultMyReferrals = curry(({ state }) => {
  const isEnableClinicalRoleReferralActiveView = checkPermission(state, "ReferralWorklistDefaultMyReferrals");
  return isEnableClinicalRoleReferralActiveView;
});

export const IsEnableReferralGlobalAccessCreateReferral = curry(({ state }) => {
  const isEnableReferralGlobalAccessCreateReferral = checkPermission(state, "ReferralGlobalAccessCreateReferral");
  return isEnableReferralGlobalAccessCreateReferral;
});

const setUpFilters = (state, actions) => {
  const settings = filter(actions.payload.settings, x => x.isEnabled === true);
  const presetOptions = map(settings,
    x => ({ value: x.workflowState, label: x.label, title: "Referrals", isActive: x.isActive }));
  const options = defaultFilterOptions(presetOptions, actions.payload.isEnableClinicalRoleReferralActiveView, actions.payload.isEnableReferralWorklistDefaultMyReferrals);
  const shouldLoadLastReferralSelectSearchParams = localStorage.getItem("shouldLoadLastReferralSelectSearchParams") === "true";

  const searchParams = {
    ...getPresetParams(presetOptions, shouldLoadLastReferralSelectSearchParams ? initialDefaultFilterOption : actions.payload.defaultSearchType, null, actions.payload.allRoles, actions.payload.includeAllSites),
    ...actions.payload.searchParams,
  };

  return {
    ...state,
    filterOptions: options,
    searchParams,
    filterIsSetUp: true,
  };
};

const updatePresetFilter = (state, action) => {
  const { presetFilter, showStatusTags, preSetSearchParams, allRoles, includeAllSites } = action.payload;
  return {
    ...state,
    presetFilter: action.payload.presetFilter,
    searchParams: presetFilter === "advancedFilter" ? state.searchParams : getPresetParams(state.filterOptions, presetFilter, preSetSearchParams, allRoles, includeAllSites),
    showStatusTags,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SEARCHING_REFERRALS:
      return { ...state, loading: true, error: null, loaded: false };
    case ActionTypes.ERROR_SEARCHING_REFERRALS:
      const errorMessage = action.payload?.name === "InternalError" ? "An error has occurred." : action.payload.message;
      return { ...state, loading: false, error: errorMessage, loaded: false };
    case ActionTypes.SEARCHED_REFERRALS:
      return {
        ...state,
        loading: false,
        error: null,
        loaded: true,
        searchParams: action.payload.searchParams,
        pageNumber: action.payload.pageInfo.pageNumber,
        total: action.payload.pageInfo.totalRecords,
      };
    case ActionTypes.UPDATE_REFERRAL_WORKLIST_FILTER:
      return updatePresetFilter(state, action);
    case ActionTypes.RESET_REFERRAL_WORKLIST_FILTER:
      return {
        ...state,
        // this will need to come from action payload in the future
        // if the top of default filter come from api
        // e.g. no longer "Active Referrals"
        presetFilter: initialDefaultFilterOption,
        defaultFilterOption: initialDefaultFilterOption,
        searchParams: action.payload.searchParams,
      };
    case ActionTypes.SETUP_REFERRAL_WORKLIST_FILTERS:
      return setUpFilters(state, action);
    case ReferralsActionTypes.MOVE_CURRENT_REFERRAL:
      return {
        ...state,
        total: state.total - 1,
      };
    case ReferralsActionTypes.CREATE_MANUAL_REFERRAL:
      if (action.payload.orgUnitId !== action.payload.referral.orgUnitId) { return state; }

      return {
        ...state,
        total: state.total + 1,
      };
    case ActionTypes.UPDATE_REFERRAL_WORKLIST_FILTER_TAGS:
      return {
        ...state,
        searchTags: action.payload,
      };
    case ActionTypes.RESET_REFERRAL_WORKLIST_FILTER_TAGS:
      return {
        ...state,
        searchTags: INITIAL_STATE.searchTags,
        showStatusTags: false,
      };
    case ActionTypes.CLEAR_LAST_SEARCH_PARAMS:
      localStorage.removeItem("lastSelectReferralSearchOption");
      localStorage.removeItem("lastSearchParams");
      return state;
    default:
      return state || INITIAL_STATE;
  }
};

export const getSearchParameters = curry(({ localState }) => localState.searchParams);

export const isReferralWorklistLoading = curry(({ localState }) => localState.loading === true);

export const isReferralWorklistLoaded = curry(({ localState }) => localState.loaded === true);

export const getErrorMessage = curry(({ localState }) => localState.error);

export const getReferralWorklistFilter = curry(({ localState }) => localState.presetFilter);

export const getTotalNumberOfReferral = curry(({ localState }) => localState.total);

export const getCurrentPageOfReferral = curry(({ localState }) => localState.pageNumber);

export const bailOutOfLoadingReferrals = curry(({ localState }, pageNumber) => {
  const { loading, total } = localState;

  if (loading || (pageNumber === 1 && total !== 0)) {
    return true;
  }
  return false;
});

export const getFilterOptions = curry(({ localState, state }) => {
  const filterOptionsSettings = getReferralFilterItemsSettings(state);
  const isEnableClinicalRoleReferralActiveView = IsEnableClinicalRoleReferralActiveView(state);
  const isEnableReferralWorklistDefaultMyReferrals = IsEnableReferralWorklistDefaultMyReferrals(state);
  const options = defaultFilterOptions(null, isEnableClinicalRoleReferralActiveView, isEnableReferralWorklistDefaultMyReferrals);

  // TODO: improve code readability for below code lines
  const filteredFilterOptionsFromLocalState = filteredFilterOptionByConfigSettings(localState.filterOptions, filterOptionsSettings);
  const filteredFilterOptions = filteredFilterOptionByConfigSettings(options, filterOptionsSettings);

  // Combine the two sets of filter options
  const combinedFilterOptions = [...filteredFilterOptionsFromLocalState, ...filteredFilterOptions];

  // Remove duplicates using a Set
  const uniqueFilterOptions = Array.from(new Set(combinedFilterOptions.map(JSON.stringify))).map(JSON.parse);

  return uniqueFilterOptions;
});

export const getAssignedSpecialtyRoles = curry(({ state }) => {
  const user = getUser(state);
  const { specialtyProfileId, specialtyProfileAvailableRoleIds } = user;

  const allRoles = getAllRoles(state);
  const availableRoles = !isEmpty(specialtyProfileId) ? filter(allRoles, x => includes(specialtyProfileAvailableRoleIds, x.roleId)) : null;
  return availableRoles;
});

export const getDefaultFilterOptionSearchParams = curry(({ localState, state }) => {
  const { filterOptions } = localState;
  const isEnableClinicalRoleReferralActiveView = IsEnableClinicalRoleReferralActiveView(state);
  const isEnableReferralWorklistDefaultMyReferrals = IsEnableReferralWorklistDefaultMyReferrals(state);
  const options = defaultFilterOptions(null, isEnableClinicalRoleReferralActiveView, isEnableReferralWorklistDefaultMyReferrals);
  const referralActiveView = head(options);
  const allRoles = getAllRoles(state);
  return getPresetParams(filterOptions, referralActiveView, null, allRoles);
});

export const areFiltersLoaded = curry(({ localState }) => localState.filterIsSetUp === true);

export const getSearchTags = curry(({ localState }) => localState.searchTags);

export const shouldShowStatusSearchTags = curry(({ localState }) => localState.showStatusTags === true);

export const getExportJobs = curry(({ localState }) => localState.exportJobs);
