import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { includes } from "lodash";
import { Typography, CircularProgress } from "@material-ui/core";

import DefaultItem from "components/items/default-item";
import DefaultButton from "components/items/default-button";
import { showMessage } from "app/store/actions/message.actions";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import ReferralAssignmentForm from "app/main/referrals/components/referral-assignment-form";
import { AssignedUserIcon, AssignedSpecialtyIcon } from "helpers/icon-finder";
import withPermissions from "permissions/withPermissions";
import TextLink from "components/items/text-link";
import { getAllowReassignSpecialtyStates, getAllowReassignSubSpecialtyStates, getAllowReassignRoleStates } from "app/auth/store/reducers/system-configuration";
import useOpenConfigForm from "hooks/use-open-config-form";
import { updateReferralsAssignedSite } from "app/main/referralWorklist/actions/referralWorklist.actions";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import { resetPatientSearch } from "app/main/patients/actions/patientsSearch.actions";

const ReferralAssignTo = ({
  hasPermissionReferralsAssign,
  orgUnitId,
  referral,
  loading,
  disabled,
}) => {
  const dispatch = useDispatch();
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);

  const { assignedTo, referralStatus } = referral;
  const AllowReassignSpecialtyStates = useSelector(getAllowReassignSpecialtyStates);
  const allowSpecialtyReassignment = includes(AllowReassignSpecialtyStates, referralStatus);

  const AllowReassignSubSpecialtyStates = useSelector(getAllowReassignSubSpecialtyStates);
  const allowReassignSubSpecialtyStates = includes(AllowReassignSubSpecialtyStates, referralStatus);

  const AllowReassignRoleStates = useSelector(getAllowReassignRoleStates);
  const allowReassignRoleStates = includes(AllowReassignRoleStates, referralStatus);

  const onSucceed = assignedReferral => {
    dispatch(closeDialog());
    dispatch(resetPatientSearch());
    if (assignedReferral.orgUnitId !== assignedReferral.assignedOrgUnitId) {
      dispatch(updateReferralsAssignedSite());
      dispatch(showMessage({
        variant: "success",
        message:
  <>
    <div className="mt-4">Successfully moved referral {referral.referralNumber} from {signedInOrgUnit.name} to {assignedReferral.assignedOrgUnitName}.</div>
  </>,
      }));
    }
  };

  const openReferralAssignmentForm = () => {
    dispatch(openDialog({
      children: (
        <ReferralAssignmentForm
          title="Assign Referral"
          referral={referral}
          orgUnitId={orgUnitId}
          onSucceed={onSucceed}
        />
      ),
    }));
  };

  const { loadingConfigurations, openConfigForm } = useOpenConfigForm(referral?.orgUnitId, "ReferralAssignment", openReferralAssignmentForm);

  if (loading) {
    return (
      <CircularProgress size={20} currentColor="inherit" />
    );
  }

  if (!referral) {
    return "N/A";
  }

  const isAssigned = assignedTo?.assignedToId;
  const disableAssignedReferral = disabled || !hasPermissionReferralsAssign || (!allowSpecialtyReassignment && !allowReassignSubSpecialtyStates && !allowReassignRoleStates);
  const disableAssignment = disabled || !hasPermissionReferralsAssign;

  if (isAssigned) {
    return (
      <DefaultItem
        icon={assignedTo?.assignmentType === "User" ? <AssignedUserIcon /> : <AssignedSpecialtyIcon />}
        loading={loadingConfigurations}
        content={(
          <div className="flex flex-row items-center sm:flex-col sm:items-start">
            <Typography variant="caption" className="mr-8">Assigned To </Typography>
            <TextLink
              disabled={disableAssignedReferral || loadingConfigurations}
              variant="caption"
              fontSize="small"
              content={assignedTo?.assignedToDisplayName}
              onClick={openConfigForm}
            />
          </div>
        )}
      />
    );
  }

  return (
    <DefaultButton
      disabled={disableAssignment}
      label="Assign"
      icon={<AssignedUserIcon />}
      onClick={openReferralAssignmentForm}
    />
  );
};

export default withPermissions("ReferralsAssign")(ReferralAssignTo);
