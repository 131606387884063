import React, { useState, useEffect } from "react";
import { Alert } from "@material-ui/lab";
import { useDispatch, useSelector } from "react-redux";
import { find, every, isEmpty, some, map, includes, filter } from "lodash";
import * as Yup from "yup";
import { Typography, InputLabel } from "@material-ui/core";
import clsx from "clsx";
import checkIfEmpty from "helpers/check-if-empty";

import useReferralFieldSettings from "app/main/referrals/hooks/useReferralFieldSettings";
import Form from "components/form";
import SubSpecialtySelector from "app/main/specialtyProfiles/components/sub-specialty-selector";
import SpecialtySpecialistSelector from "app/main/specialtyProfiles/components/specialty-specialist-selector";
import SpecialtyCodeSetSelector from "app/main/specialtyProfiles/components/specialty-code-set-selector";
import { getAllCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";
import { saveReferralAssignedUser } from "app/main/referrals/actions/referrals.actions";
import { showMessage } from "app/store/actions/message.actions";
import DefaultItem from "components/items/default-item";
import { fetchSpecialtyProfileCodeSetValues } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import BaseInputField from "components/inputs/components/base-input-component";

import { fetchPatientPresentingComplaints } from "app/main/referrals/actions/patientReferrals.actions";
import { getPatientPresentingComplaints } from "app/main/referrals/reducers/patientReferrals.reducers";
import { getReferralWorklistSettings, getPresentingComplaintMaxLength } from "app/auth/store/reducers/system-configuration";
import { getSpecialtyCodeSetValues } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import { getCurrentOrgUnitFormFieldConfigByIdField } from "app/main/orgUnits/reducers/orgUnit.reducers";

import SpecialtyInputField from "./specialty-input-field";
import SubmitButton from "../submit-button";

const initialValidationFields = (condition, fieldSettings) => ({
  assignedToSubSpecialtyId: condition || isEmpty(fieldSettings?.assignToSubSpecialty),
  specialistConsultant: condition || isEmpty(fieldSettings?.specialistConsultant),
  clinicTypeCodeId: condition || isEmpty(fieldSettings?.clinicType),
  presentingComplaintCodes: condition || isEmpty(fieldSettings?.presentingComplaintCodes),
  referrerAdvisedPriorityCodeId: condition || isEmpty(fieldSettings?.referrerAdvisedPriorityCodeId),
});

const initialFieldStatus = condition => ({
  assignedToSubSpecialtyId: condition,
  specialistConsultant: condition,
  clinicTypeCodeId: condition,
  presentingComplaintCodes: condition,
  referrerAdvisedPriorityCodeId: condition,
});

const ReferralReAssignmentForm = ({
  orgUnitId,
  referral,
  currentAssignReferral,
  currentAssignedTo,
  onSucceed,
  assignmentSpecialtyFieldSettings,
  assignmentSpecialtyFieldLabels,
  referrerAdvisedPriorityCodeId,
  ...other
}) => {
  const dispatch = useDispatch();
  const currentReferralOrgUnitId = currentAssignReferral.orgUnitId ?? orgUnitId;
  const { fieldSettings, fieldLabels } = useReferralFieldSettings(referral.formFieldConfigurationId);
  // maintaining the state of referral form fields, for validation
  // all fields need to be uncloaked and fulfill before save the form
  const [validFields, setValidFields] = useState(initialValidationFields(false, fieldSettings));
  // maintaining locking the state of referral form fields
  const [fieldStatus, setFieldStatus] = useState(initialFieldStatus(true));

  const specialtyId = currentAssignedTo.assignedToSpecialtyId;
  const patientPresentingComplaints = useSelector(state => getPatientPresentingComplaints(state, referral?.patientId));
  const [showPresentingComplaintWarning, setPresentingComplaintWarning] = useState(false);

  const presentingComplaintMaxLength = useSelector(getPresentingComplaintMaxLength);
  const multiplePresentingComplaint = presentingComplaintMaxLength > 1;
  const specialtyCodeSetValues = useSelector(state => getSpecialtyCodeSetValues(state, specialtyId));
  const presentingComplaintSettings = useSelector(state => getCurrentOrgUnitFormFieldConfigByIdField(state, referral.orgUnitId, referral.formFieldConfigurationId, "PresentingComplaintCodes"));
  const presentingComplaintCodeSetTypeCode = fieldSettings?.presentingComplaintCodes?.fieldName ?? "PresentingComplaintCodes";
  const presentingComplaintCodes = map(filter(specialtyCodeSetValues, x => x.codeSetTypeCode === presentingComplaintCodeSetTypeCode), x => x.code);

  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const isEnablePresentingComplaintWarning = !isEmpty(referralWorklistSettings?.patientPresentingComplaintWarningMessage);
  const isShowPresentingComplaintCodes = fieldSettings?.presentingComplaintCodes || !isEmpty(referral?.presentingComplaints) || referral?.referralDocument?.hasReferralCriteria === true;
  const [assignedToSpecialist, setAssignedToSpecialist] = useState(null);

  useEffect(() => {
    dispatch(fetchSpecialtyProfileCodeSetValues(currentReferralOrgUnitId, specialtyId));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setAssignedToSpecialist((referral?.referralDocument?.specialistConsultantUserId || referral?.referralDocument?.specialistConsultant)
      ? {
        value: referral?.referralDocument?.specialistConsultantUserId,
        label: referral?.referralDocument?.specialistConsultant,
      } : null);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referral?.referralDocument?.specialistConsultantUserId, referral?.referralDocument?.specialistConsultant]);

  useEffect(() => {
    if (isEnablePresentingComplaintWarning && currentReferralOrgUnitId && referral?.patientId) {
      dispatch(fetchPatientPresentingComplaints(currentReferralOrgUnitId, referral?.patientId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEnablePresentingComplaintWarning, currentReferralOrgUnitId, referral.patientId]);

  useEffect(() => {
    if (isShowPresentingComplaintCodes && find(validFields, x => x.presentingComplaintCodes === true)) {
      setValidFields({ ...validFields, presentingComplaintCodes: false });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShowPresentingComplaintCodes, JSON.stringify(validFields)]);

  const codeSetValues = useSelector(getAllCodeSetValues);
  const getCodeSetDisplayValue = codeSetValueId => find(codeSetValues, x => x.id === codeSetValueId)?.description;

  let referrerAdvisedPriority = null;
  if (referrerAdvisedPriorityCodeId) {
    referrerAdvisedPriority = find(codeSetValues, x => x.id === referrerAdvisedPriorityCodeId);
  }
  const isEmptyReferrerAdvisedPriority = checkIfEmpty(referrerAdvisedPriority?.displayName);

  const getConvertedReferralFields = assignReferral => {
    let convertedAssignReferral = {
      referralDocumentId: referral.referralDocument.id,
      specialistConsultant: assignReferral.specialistConsultant,
      specialistConsultantUserId: assignReferral.specialistConsultantUserId,
      clinicTypeCodeId: assignReferral.clinicTypeCodeId?.value,
    };

    if (!isEmpty(assignReferral.presentingComplaintCodes)) {
      const valueArray = multiplePresentingComplaint ? assignReferral.presentingComplaintCodes : [].concat(assignReferral.presentingComplaintCodes);
      convertedAssignReferral.presentingComplaintCodes = map(valueArray, x => ({
        code: x.value,
        description: x.label,
      }));
    }

    if (fieldSettings?.assignToSubSpecialty && !assignmentSpecialtyFieldSettings?.subSpecialty) {
      convertedAssignReferral = {
        ...convertedAssignReferral,
        assignedToSubSpecialtyId: assignReferral.assignedToSubSpecialtyId?.value,
      };
    }

    if (assignmentSpecialtyFieldSettings?.subSpecialty) {
      convertedAssignReferral = {
        ...convertedAssignReferral,
        assignedToSubSpecialtyId: currentAssignedTo.assignedToSubSpecialtyId,
        assignSubSpecialty: !isEmpty(assignmentSpecialtyFieldSettings?.subSpecialty),
      };
    }
    return convertedAssignReferral;
  };

  const handleSubmit = ({ assignReferral }, { setErrors, setSubmitting }) => {
    const areCustomFieldValidated = every(validFields, x => x === true);

    if (!areCustomFieldValidated) {
      setSubmitting(false);
      return dispatch(showMessage({
        message: "All highlighted referral fields must be updated before re-assigning",
        variant: "error",
      }));
    }

    const convertedAssigningReferral = {
      ...currentAssignReferral,
      ...getConvertedReferralFields(assignReferral),
    };

    return dispatch(saveReferralAssignedUser(orgUnitId, convertedAssigningReferral)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed(responds.payload);
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const renderSubmitButton = formProps => (
    <SubmitButton
      label="Update"
      {...formProps}
      onClick={() => {
        const areCustomFieldValidated = every(validFields, x => x === true);

        if (!areCustomFieldValidated) {
          return dispatch(showMessage({
            message: "All fields must be updated",
            variant: "error",
          }));
        }
        // call handle submit function once all field are valid
        return null;
      }}
    />
  );

  const presentingComplaintCodeSchema = Yup.object().shape({
    code: Yup.string(),
    description: Yup.string(),
  });

  const schema = Yup.object().shape({
    assignReferral: Yup.object().shape({
      assignedToSubSpecialtyId: Yup.string()
        .fieldSettingsValidation(fieldLabels.assignToSubSpecialty, fieldSettings?.assignToSubSpecialty)
        .nullable(),
      specialistConsultant: Yup.string()
        .fieldSettingsValidation(fieldLabels.specialistConsultant, fieldSettings?.specialistConsultant)
        .nullable(),
      clinicTypeCodeId: Yup.string()
        .fieldSettingsValidation(fieldLabels.clinicType, fieldSettings?.clinicType)
        .nullable(),
      presentingComplaintCodes: presentingComplaintMaxLength > 1
        ? Yup.array()
          .of(presentingComplaintCodeSchema)
          .fieldSettingsValidation(fieldLabels.presentingComplaintCodes, fieldSettings?.presentingComplaintCodes)
          .max(presentingComplaintMaxLength, `${fieldLabels.presentingComplaintCodes} must have less than or equal to ${presentingComplaintMaxLength} items`)
          .nullable()
        : presentingComplaintCodeSchema
          .fieldSettingsValidation(fieldLabels.presentingComplaintCodes, fieldSettings?.presentingComplaintCodes)
          .nullable(),
    }),
  });

  const { assignReferral } = schema.cast();

  if (assignmentSpecialtyFieldSettings?.subSpecialty && !validFields.assignedToSubSpecialtyId) {
    setValidFields({ ...validFields, assignedToSubSpecialtyId: true });
  }

  let toLabel = currentAssignedTo.assignedToDisplayName;
  if (assignmentSpecialtyFieldSettings?.specialtyRole) {
    toLabel += currentAssignedTo.assignedToRoleDisplayName ? ` (${currentAssignedTo.assignedToRoleDisplayName})` : "";
  }
  if (assignmentSpecialtyFieldSettings?.subSpecialty) {
    toLabel += currentAssignedTo.assignedToSubSpecialtyDisplayName ? `- ${currentAssignedTo.assignedToSubSpecialtyDisplayName}` : "";
  }

  assignReferral.assignedToSpecialist = assignedToSpecialist;

  return (
    <Form
      validationSchema={schema}
      contentProps={{ ...other }}
      initialValues={{ assignReferral }}
      onSubmit={handleSubmit}
      variant="filled"
      renderSubmitButton={renderSubmitButton}
      renderHeaderContent={(
        <Alert severity="warning" icon={false}>
          <div className="px-32">
            <DefaultItem label="From" content={referral.assignedTo.assignedToDisplayName} />
            <DefaultItem label="To" content={toLabel} />
            <Typography className="mt-8">
              Re-assigning the referral requires the following details to be updated.
            </Typography>
          </div>
        </Alert>
      )}
      content={({ setFieldValue }) => (
        <>
          <SpecialtyInputField
            name="assignReferral.assignedToSubSpecialtyId"
            setting={!assignmentSpecialtyFieldSettings?.subSpecialty ? fieldSettings?.assignToSubSpecialty : null}
            label={fieldLabels?.assignToSubSpecialty}
            defaultValue={referral.referralAssignedToSubSpecialtyName}
            specialtyIds={!isEmpty(specialtyId) ? [specialtyId] : []}
            component={SubSpecialtySelector}
            showIcon={false}
            locked={fieldStatus.assignedToSubSpecialtyId}
            onUnlock={() => {
              setValidFields(prev => ({ ...prev, assignedToSubSpecialtyId: true }));
              setFieldStatus(prev => ({ ...prev, assignedToSubSpecialtyId: false }));
            }}
          />

          <SpecialtyInputField
            name="assignReferral.assignedToSpecialist"
            setting={fieldSettings?.specialistConsultant || !isEmpty(referral?.referralDocument.specialistConsultant)}
            label={fieldLabels?.specialistConsultant}
            setDefaultValue={isEmpty(assignedToSpecialist)}
            initialOptions={assignedToSpecialist ? [assignedToSpecialist] : []}
            specialtyId={specialtyId}
            component={SpecialtySpecialistSelector}
            showIcon={false}
            locked={fieldStatus.specialistConsultant}
            freeSolo
            onUnlock={() => {
              setValidFields(prev => ({ ...prev, specialistConsultant: true }));
              setFieldStatus(prev => ({ ...prev, specialistConsultant: false }));
            }}
            onInputChange={(_event, value) => setFieldValue("assignReferral.specialistConsultant", value)}
            onChange={val => {
              setFieldValue("assignReferral.specialistConsultant", val?.label ?? null);
              setFieldValue("assignReferral.specialistConsultantUserId", val?.value ?? null);
            }}
          />
          <SpecialtyInputField
            name="assignReferral.clinicTypeCodeId"
            setting={fieldSettings?.clinicType || !isEmpty(referral?.referralDocument.clinicTypeCodeId)}
            label={fieldLabels?.clinicType}
            component={SpecialtyCodeSetSelector}
            codeSetTypeCode={fieldSettings?.clinicType?.fieldName}
            defaultValue={referral.referralDocument.clinicTypeCodeId ? getCodeSetDisplayValue(referral.referralDocument.clinicTypeCodeId) : ""}
            specialtyId={specialtyId}
            locked={fieldStatus.clinicTypeCodeId}
            onUnlock={() => {
              setValidFields(prev => ({ ...prev, clinicTypeCodeId: true }));
              setFieldStatus(prev => ({ ...prev, clinicTypeCodeId: false }));
            }}
          />
          <>
            <SpecialtyInputField
              name="assignReferral.presentingComplaintCodes"
              setting={isShowPresentingComplaintCodes}
              label={presentingComplaintSettings?.label ?? fieldLabels.presentingComplaintCodes}
              component={SpecialtyCodeSetSelector}
              codeSetTypeCode={presentingComplaintCodeSetTypeCode}
              defaultValue={referral.presentingComplaintSummary}
              multiple={multiplePresentingComplaint}
              multiline
              limitTags={multiplePresentingComplaint}
              isTermSelector
              specialtyId={specialtyId}
              locked={fieldStatus.presentingComplaintCodes}
              onUnlock={() => {
                const currentAutoSelectedCodes = filter(referral.presentingComplaints, x => includes(presentingComplaintCodes, x.code));
                setFieldValue("assignReferral.presentingComplaintCodes",
                  map(currentAutoSelectedCodes, presentingComplaint => ({
                    value: presentingComplaint.code,
                    label: presentingComplaint.term,
                  })));
                setValidFields(prev => ({ ...prev, presentingComplaintCodes: true }));
                setFieldStatus(prev => ({ ...prev, presentingComplaintCodes: false }));
              }}
              onChange={selectedValues => {
                if (isEnablePresentingComplaintWarning) {
                  const valueArray = [].concat(selectedValues);
                  const selectedCodes = map(valueArray, val => val?.value);
                  const isExistPresentComplianceCode = some(patientPresentingComplaints, x => includes(selectedCodes, x.code) && (isEmpty(referral.id) || x.referralId !== referral.id));
                  setPresentingComplaintWarning(isExistPresentComplianceCode);
                }
              }}
            />
            {showPresentingComplaintWarning && <Alert severity="warning">{fieldLabels.presentingComplaintCodes} is already selected in the other referral.</Alert>}
          </>
          {(fieldSettings?.referrerAdvisedPriority || !isEmpty(referrerAdvisedPriorityCodeId) || referral?.referralDocument?.hasReferralCriteria === true) && (
            <BaseInputField>
              <InputLabel shrink htmlFor={fieldLabels.referrerAdvisedPriority}>{fieldLabels.referrerAdvisedPriority}</InputLabel>
              <div className={clsx("MuiInputBase-root MuiInputBase-input mt-16 flex")}>
                <Typography>{isEmptyReferrerAdvisedPriority ? "None Recorded" : referrerAdvisedPriority?.displayName}</Typography>
              </div>
            </BaseInputField>
          )}
        </>
      )}
    />
  );
};

export default ReferralReAssignmentForm;
