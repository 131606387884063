import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { map, isEmpty, includes, filter, size, head } from "lodash";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchSpecialtyProfilesForSelector } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { getAllowOnlyAssignedSpecialties } from "app/auth/store/reducers/system-configuration";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import { SpecialtyIcon } from "helpers/icon-finder";
import withPermissions from "permissions/withPermissions";

const SpecialtySelector = ({
  label,
  showIcon = true,
  initialOptions = [],
  showDeprecated = false,
  loadInitialOption = false, // should load default option for existing value
  orgUnitId,
  excludeSpecialtiesIds = [],
  traverseParentOrgUnits = false,
  isAssignment = false,
  isCreateReferral = false,
  isCrossOrgAssignment = false,
  includeDefaultSpecialist = false,
  includeReferralProfiles = false,
  onAutoAssignExtraAction = null,
  hasPermissionReferralsViewCreatedByUser,
  hasPermissionReferralAllSpecialtyCreateReferral,
  ...other
}) => {
  const dispatch = useDispatch();
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);
  const { field: { name, value }, form: { setFieldValue } } = other; // consider existing value
  const [options, setOptions] = useState(initialOptions || []);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const requestedByOrgUnitId = signedInOrgUnit?.id;

  // overrides the specialty selector's behaviour on the advanced search when the user has permission to
  // view they're created referrals or has permission to view all specialties
  const allowOnlyAssignedSpecialties = useSelector(getAllowOnlyAssignedSpecialties) && !hasPermissionReferralsViewCreatedByUser && !hasPermissionReferralAllSpecialtyCreateReferral;

  const shouldLoadInitialOption = loadInitialOption && value && isEmpty(initialOptions);
  const loadOptions = () => {
    if (orgUnitId) {
      setLoading(true);
      setOptions([]);
      dispatch(fetchSpecialtyProfilesForSelector(orgUnitId,
        { showDeprecated, traverseParentOrgUnits, isAssignment, isCreateReferral, isCrossOrgAssignment, includeDefaultSpecialist, includeReferralProfiles, requestedByOrgUnitId }))
        .then(response => {
          if (response) {
            const specialties = response.error ? [] : filter(response.payload.specialtyProfiles, x => !includes(excludeSpecialtiesIds, x.id));
            setOptions(response.error ? [] : filter(response.payload.specialtyProfiles, x => !includes(excludeSpecialtiesIds, x.id)));
            if (allowOnlyAssignedSpecialties && isEmpty(value) && size(specialties) === 1) {
              const selectedSpecialty = head(specialties);
              const currentSelectedValue = { label: selectedSpecialty.displayName, value: selectedSpecialty.id };
              setFieldValue(name, currentSelectedValue);
              if (onAutoAssignExtraAction) {
                onAutoAssignExtraAction(currentSelectedValue);
              }
            }
          } else {
            setOptions([]);
          }
        }).finally(() => {
          setLoading(false);
        });
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    if (shouldLoadInitialOption || allowOnlyAssignedSpecialties) {
      setInitialLoading(true);
      loadOptions();
      setInitialLoading(false);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLoadInitialOption, allowOnlyAssignedSpecialties, orgUnitId]);

  const loadOptionsOnOpen = () => loadOptions();

  if (initialLoading) return <AutoCompleteLoading />;

  return (
    <AutoComplete
      icon={showIcon && <SpecialtyIcon />}
      label={label}
      loading={loading}
      onOpen={shouldLoadInitialOption ? null : loadOptionsOnOpen}
      options={map(options, x => ({ label: x.displayName, value: x.id }))}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      filterOption={{ matchFrom: "start" }}
      {...other}
    />
  );
};

export default withPermissions("ReferralsViewCreatedByUser", "ReferralAllSpecialtyCreateReferral")(SpecialtySelector);
