import React, { useState } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";
import { Typography } from "@material-ui/core";
import { some, isEmpty } from "lodash";

import IconButton from "components/items/icon-button";

const useStyles = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    display: "flex",
    position: "relative",
  },
  previewContent: {
    overflowX: "hidden",
    overflow: "auto",
    zIndex: 1,
  },
  controlContent: {
    position: "absolute",
    width: "calc(100% - 20px)", // 20px the width of scrollbar
    height: "100%",
  },
  pageControls: {
    position: "absolute",
    bottom: theme.spacing(2),
    width: "100%",
    display: "flex",
    justifyContent: "center",
    zIndex: 999,
  },
  zoomControls: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 999,
  },
  controls: {
    background: theme.palette.common.white,
    boxShadow: "0 30px 40px 0 rgba(16, 36, 94, 0.2)",
    borderRadius: theme.shape.borderRadius,
    opacity: 0,
    transition: theme.transitions.create("opacity", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard,
    }),
    "&.hover": {
      opacity: 1,
    },
  },
}));

const PreviewControls = ({
  children,
  goToNext,
  goToPrevious,
  pageControlLabel,
  disablePrevious,
  disableNext,
}) => {
  const classes = useStyles();
  const [hover, setHover] = useState(false);
  const pageControlProps = [goToNext, goToPrevious, pageControlLabel];
  const showPageControls = some(pageControlProps, x => !isEmpty(x));

  return (
    <div className={classes.root} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <TransformWrapper
        defaultScale={1}
        defaultPositionX={200}
        defaultPositionY={100}
        wheel={{ disabled: true, wheelEnabled: false }}
      >
        {({ zoomIn, zoomOut, resetTransform }) => (
          <>
            <div className={classes.previewContent}>
              <TransformComponent>
                {children}
              </TransformComponent>
            </div>
            <div className={classes.controlContent}>
              <div className={classes.zoomControls}>
                <div className={clsx(classes.controls, { hover })}>
                  <IconButton title="Zoom In" icon="add" onClick={zoomIn} />
                  <IconButton title="Zoom Out" icon="remove" onClick={zoomOut} />
                  <IconButton title="Reset" icon="zoom_out_map" onClick={resetTransform} />
                </div>
              </div>
              {showPageControls && (
              <div className={classes.pageControls}>
                <div className={clsx("flex items-center", classes.controls, { hover })}>
                  {goToPrevious && <IconButton title="Previous" icon="navigate_before" onClick={goToPrevious} disabled={disablePrevious} />}
                  {pageControlLabel && <Typography variant="caption">{pageControlLabel}</Typography>}
                  {goToNext && <IconButton title="Next" icon="navigate_next" onClick={goToNext} disabled={disableNext} />}
                </div>
              </div>
              )}
            </div>
          </>
        )}
      </TransformWrapper>
    </div>

  );
};

export default PreviewControls;
