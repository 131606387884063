import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Chip } from "@material-ui/core";
import { concat, map } from "lodash";
import { useDispatch } from "react-redux";
import { AutoComplete } from "components/inputs";
import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import FormContext from "components/FormContext";
import { CloseCrossIcon } from "helpers/icon-finder";

import SpecialtySpecialistForm from "./specialty-specialist-form";

const useStyles = makeStyles(() => ({
  tag: {
    "& svg": {
      pointerEvents: "initial",
    },
  },
}));

const SpecialtyUserSelector = props => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeDialog());
  const { field: { name, value }, form: { setFieldValue }, disabled } = props;

  const updateSpecialtySpecialist = (currentSpecialtySpecialist, currentIndex) => {
    const specialtySpecialist = {
      ...currentSpecialtySpecialist,
      userId: currentSpecialtySpecialist.userId.value ?? currentSpecialtySpecialist.userId,
    };

    if (currentIndex === -1) {
      setFieldValue(name, concat(value || [], specialtySpecialist));
    } else {
      setFieldValue(name, map(value, (x, index) => (index === currentIndex ? specialtySpecialist : x)));
    }
    onClose();
  };

  const openSpecialtySpecialistForm = (currentSpecialtySpecialist, index = -1) => {
    dispatch(openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <SpecialtySpecialistForm
          specialtySpecialists={value}
          currentSpecialtySpecialist={currentSpecialtySpecialist}
          title="Add Specialty Specialist"
          onSubmit={({ specialtySpecialist }) => updateSpecialtySpecialist(specialtySpecialist, index)}
          submitLabel={currentSpecialtySpecialist ? "Update" : "Add"}
        />
      ),
    }));
  };

  const onAddSpecialtySpecialist = () => {
    openSpecialtySpecialistForm();
  };

  const onSpecialtySpecialistDelete = index => {
    setFieldValue(name, [...value.slice(0, index), ...value.slice(index + 1)]);
  };

  const onSpecialtySpecialistEdit = (currentUserRole, index) => {
    openSpecialtySpecialistForm(currentUserRole, index);
  };

  const classes = useStyles();

  return (
    <FormContext.Consumer>
      {({ formDisabled }) => (
        <div className="flex sm:flex-row sm:items-center flex-col">
          <AutoComplete
            className={clsx("flex-1", classes.tag)}
            options={map(value, x => ({ label: `${x.name} ${x.isDefault === true ? "(Default)" : ""}`, value: x }))}
            getOptionSelected={(option, val) => option.value === val.value}
            renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
              <Chip
                {...getTagProps({ index })}
                label={option?.label}
                onClick={() => onSpecialtySpecialistEdit(option.value, index)}
                onDelete={() => onSpecialtySpecialistDelete(index)}
                size="small"
                deleteIcon={<CloseCrossIcon />}
              />
            ))}
            multiple
            forcePopupIcon={false}
            disableClearable
            open={false}
            multiline
            {...props}
          />
          {!disabled && !formDisabled && (
          <div className="sm:pl-8 pl-32 flex">
            <DefaultButton
              onClick={onAddSpecialtySpecialist}
              label="Add"
              edge="end"
            />
          </div>
          )}
        </div>
      )}
    </FormContext.Consumer>
  );
};

export default SpecialtyUserSelector;
