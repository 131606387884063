import React, { useState } from "react";
import { List, ListItem, ListItemText, ListItemIcon, ListItemSecondaryAction, Typography, Divider } from "@material-ui/core";
import { map, filter, size, chunk } from "lodash";
import Pagination from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";

import Form from "components/form";
import AutoCompleteInput from "components/inputs/auto-complete-input/input";
import IconComponent from "components/items/icon-component";
import IconButton from "components/items/icon-button";
import EmptyState from "components/items/empty-state";
import TextTag from "components/items/text-tag";

const ITEMS_PER_PAGE = 25;

const useStyles = makeStyles(() => ({
  content: {
    height: "60vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
}));

const SpecialtyCodeSetForm = ({
  label,
  onSucceed,
  currentValues,
  options,
  ...other
}) => {
  const classes = useStyles();
  const activeOptions = filter(options, x => x.isActive);
  const [selectedValue, setSelectedValue] = React.useState(currentValues);
  const [pendingValue, setPendingValue] = React.useState(currentValues);
  const [pageNumber, setPageNumber] = useState(1);
  const [openSearch, setOpenSearch] = useState(false);

  const total = size(selectedValue);
  const pagedCodeSetValues = chunk(selectedValue, ITEMS_PER_PAGE);

  const handleClose = (event, reason) => {
    if (reason === "toggleInput") {
      return;
    }
    setSelectedValue(pendingValue);
    setOpenSearch(false);
  };

  const handleRemove = codeSetValue => {
    const newValues = filter(selectedValue, code => code.id !== codeSetValue.id);
    setSelectedValue(newValues);
    setPendingValue(newValues);
  };

  const handleSubmit = () => {
    onSucceed(selectedValue);
  };

  const handleChangePage = (event, page) => {
    if (page !== pageNumber) {
      setPageNumber(page);
    }
  };

  return (
    <Form
      onSubmit={handleSubmit}
      contentProps={{ ...other, title: `Add or Remove from ${label}` }}
      renderHeaderContent={(
        <div className="px-32 py-8">
          <AutoCompleteInput
            autoFocus
            disableClearable
            disableCloseOnSelect
            multiple
            withDivider
            variant="filled"
            open={openSearch}
            onOpen={() => setOpenSearch(true)}
            onClose={handleClose}
            label={`Search for ${label}`}
            showIcon={false}
            showHelperText={false}
            value={pendingValue}
            onChange={(event, newValue) => {
              setPendingValue(newValue);
            }}
            onInputChange={() => setOpenSearch(true)}
            renderTags={() => null}
            noOptionsText="No Code Set found"
            getOptionSelected={(option, val) => option.id === val.id}
            renderOption={(option, { selected }) => (
              <>
                <ListItemIcon>
                  {selected && <IconComponent icon="check" size={24} />}
                </ListItemIcon>
                <ListItemText primary={option.code} secondary={option.description} />
              </>
            )}
            options={activeOptions}
            getOptionLabel={option => option.description}
            {...other}
          />
        </div>
      )}
      content={() => (
        <div className={classes.content}>
          {
            total === 0 ? <EmptyState title="No Code Sets" subtitle={`Search to add ${label}`} icon="search" />
              : (
                <>
                  <List disablePadding className="mb-16">
                    {map(pagedCodeSetValues, (set, index) => {
                      if (index + 1 !== pageNumber) return null;

                      return map(set, value => (
                        <ListItem button disableRipple key={value.id} className="cursor-default">
                          <ListItemText primary={`${value.code} - ${value.description}`} />
                          {value.deprecatedDateUtc && <TextTag label="DEPRECATED" variant="error" />}
                          <ListItemSecondaryAction>
                            <IconButton title={`Remove ${value.description}`} icon="clear" onClick={() => handleRemove(value)} />
                          </ListItemSecondaryAction>
                        </ListItem>
                      ));
                    })}
                  </List>
                  <div>
                    <Divider />
                    <div className="flex items-center justify-between px-16 mt-8">
                      <Typography variant="caption" color="textSecondary">Total: {total}</Typography>
                      {size(pagedCodeSetValues) > 1 && (
                      <Pagination
                        count={Math.ceil(total / ITEMS_PER_PAGE) ?? 0}
                        page={pageNumber}
                        onChange={handleChangePage}
                      />
                      )}
                    </div>
                  </div>
                </>
              )
          }
        </div>
      )}
    />
  );
};

export default SpecialtyCodeSetForm;
