import React from "react";
import { Paper, Slide, Typography, useMediaQuery } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import DefaultButton from "components/items/default-button";

const useStyles = makeStyles(theme => ({
  search: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    maxWidth: 512,
    maxHeight: 48,
    borderRadius: 24,
  },
  actions: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    flexWrap: "wrap",
    gap: `${theme.spacing(1)}px`,
  },
}));

const ScreenHeader = ({
  title,
  subtitle,
  action,
  renderSearch,
  renderFilter,
  renderActions,
  showActionDiv = true,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const displayLabel = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <div className="flex flex-1 mx-32 items-center">
      <div className="flex-1">
        {title && <Typography variant="h6">{title}</Typography>}
        {subtitle && <Typography>{subtitle}</Typography>}
      </div>
      {renderSearch && (
        <div className="flex flex-auto items-center justify-center px-8">
          <Slide in direction="down" timeout={{ enter: 300 }}>
            <Paper className={classes.search} elevation={1}>
              {renderSearch}
            </Paper>
          </Slide>
        </div>
      )}
      {renderFilter && (
        <div className="flex">
          <Slide in direction="down" timeout={{ enter: 300 }}>
            <Paper elevation={1}>
              {renderFilter}
            </Paper>
          </Slide>
        </div>
      )}
      {showActionDiv && (
      <div className={classes.actions}>
        {action && (
        <Slide in direction="left" timeout={{ enter: 300 }}>
          <div>
            <DefaultButton
              size="medium"
              onClick={action.onClick}
              icon={action.icon || "add"}
              label={displayLabel && (action.label || "Add")}
              variant={displayLabel ? "extended" : "round"}
              fab
            />
          </div>
        </Slide>
        )}
        {renderActions && renderActions}
      </div>
      )}
    </div>
  );
};

export default ScreenHeader;
