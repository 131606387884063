import { ApiActions } from "middleware/call-api";
import { ActionTypes,
  areEncountersLoading,
  areEncountersLoaded } from "../reducers/encounters.reducers";

export const setCurrentEncounter = encounter => ({
  type: ActionTypes.LOADED_CURRENT_ENCOUNTER,
  payload: { encounter },
});

export const fetchPatientEncounters = patientId => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/patient/${patientId}/encounters`,
    bailout: state => areEncountersLoaded(state, patientId) || areEncountersLoading(state, patientId),
    entityType: "ENCOUNTERS",
    patientId,
  },
});

export const recordNewWelfareQuestionnaire = () => ({
  livesAlone: null,
  hasSupport: null,
  hasMedicationSupplies: null,
  hasPrescriptionHelp: null,
  hasEnoughFood: null,
  hasInternetAccess: null,
});

export const recordEncounter = encounter => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${encounter.patientId}/encounters/new`,
    body: { encounter },
    entityType: "encounter",
    patientId: encounter.patientId,
  },
});

export const editEncounter = encounter => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/patient/${encounter.patientId}/encounters/edit`,
    body: { encounter },
    entityType: "encounter",
    patientId: encounter.patientId,
  },
});
