import React from "react";
import { map, some } from "lodash";
import { Field } from "formik";

import { CheckboxInput, UserSelector } from "components/inputs";
import { validateRequired } from "utils/validators";
import Form from "components/form";

const specialistLabel = "Specialist";
const validateSpecialist = value => validateRequired(specialistLabel)(value);

const isDefaultLabel = "Default";

const SpecialtySpecialistForm = props => {
  const { specialtySpecialists, currentSpecialtySpecialist, onSubmit, ...other } = props;
  const disabledDefault = !currentSpecialtySpecialist?.isDefault && some(specialtySpecialists, x => x.isDefault);

  let initialValues = { userId: null, name: null, isDefault: false };
  if (currentSpecialtySpecialist) {
    initialValues = { ...currentSpecialtySpecialist,
      userId: {
        value: currentSpecialtySpecialist.userId,
        label: currentSpecialtySpecialist.name,
      } };
  }

  return (
    <Form
      initialValues={{ specialtySpecialist: initialValues }}
      contentProps={other}
      onSubmit={onSubmit}
      content={({ setFieldValue }) => (
        <>
          <Field
            name="specialtySpecialist.userId"
            component={UserSelector}
            loadOptions={false}
            label={specialistLabel}
            excludeUserIds={map(specialtySpecialists, s => s.userId)}
            validate={validateSpecialist}
            showFullName
            onChange={user => {
              setFieldValue("specialtySpecialist.name", user ? (user.label) : null);
            }}
          />
          <Field
            name="specialtySpecialist.isDefault"
            component={CheckboxInput}
            label={isDefaultLabel}
            showIcon={false}
            disabled={disabledDefault}
          />
        </>
      )}
    />
  );
};

export default SpecialtySpecialistForm;
