import React from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup"; 
import connect from "react-redux/es/connect/connect";
import { Field } from "formik";
import { get } from "lodash";
import { PhoneIcon, AssignedUserIcon } from "helpers/icon-finder";
import CheckboxInput from "components/inputs/checkbox";
import { NoteInput, TextInput } from "components/inputs/text-input";
import SelectInput from "components/inputs/select-input";
import Form from "components/form";
import { validatePhoneFormat } from "utils/validators";
import { recordOrUpdateContact } from "app/main/patients/actions/contacts.actions";
import { recordOrUpdateNextOfKin } from "app/main/patients/actions/patients.actions";
import { getErrorMessage, areContactsLoading, getCurrentContact, getNextOfKinContact } from "app/main/patients/reducers/contacts.reducers";
import { getRelationshipTypeValues } from "app/auth/store/reducers/system-configuration";

const firstName = "First Name";
const lastName = "Last Name";
const contactNumber = "Contact Number";
const alternativeContactNumber = "Alternative Contact Number";
const contactType = "Contact Type";
const relationshipDescription = "Relationship Description";
const smsRequested = "SMS Requested";

const PatientContactForm = ({
  isNOK,
  patientId,
  onSucceed,
  initialValues,
  relationshipTypeValues,
  hasExistingNextOfKinContact,
  ...other
}) => {
  const dispatch = useDispatch();
  const validateSingleNOK = value => {
    return !(get(initialValues.contact, ["relationshipType", "value"]) !== "NextOfKin" && value === "NextOfKin" && hasExistingNextOfKinContact)
  };

  const validationSchema = Yup.object().shape({
    contact: Yup.object().shape({
      isEmergencyContact: Yup.boolean(),
      relationshipType: Yup.string().nullable() //nullable required here for overriding the default required message
      .transform((value, originalValue, ctx) => 
      {
        return originalValue != null && typeof originalValue === 'object' ? originalValue.value : originalValue
      })
      .when([], {
        is: () => !isNOK,
        then: Yup.string().required("Relationship Type is required").test("is-only-one-nok", "Only one Next of Kin contact allowed", validateSingleNOK),
      }),
      givenName: Yup.string().nullable().max(60, "First Name must be at most 60 characters"),
      familyName: Yup.string().nullable().when("relationshipType", {
        is: (relationshipType) => relationshipType !== "PowerOfAttorney" && relationshipType !== "Power Of Attorney",
        then: Yup.string().max(60, "Last Name must be at most 60 characters").required("Last Name is required"),
        otherwise: Yup.string().nullable().max(60, "Last Name must be at most 60 characters"),
      }),
      contact: Yup.string().nullable().test(
        'is-valid-phone',
        'This does not look like a valid phone number.',
        value => validatePhoneFormat()(value) === undefined
      ),
      alternativeContact: Yup.string().nullable().test(
        'is-valid-phone',
        'This does not look like a valid phone number.',
        value => validatePhoneFormat()(value) === undefined
      ),
      smsRequested: Yup.boolean(),
      relationshipDescription: Yup.string().nullable().max(255, "Relationship Description must be at most 255 characters"),
    }),
  });

  const handleSubmit = ({ contact }, { setErrors }) => {
    const relationshipTypeValue = contact.relationshipType?.value ?? contact.relationshipType; // need to get the "value" if adding new contact because returning label / value from api
    const isNOKContact = relationshipTypeValue === "NextOfKin";
    const newContact = { ...contact, relationshipType: relationshipTypeValue };
    const apiCall = isNOKContact ? recordOrUpdateNextOfKin : recordOrUpdateContact;

    dispatch(apiCall(patientId, newContact)).then(response => {
      if (response.error !== true) {
        onSucceed(response?.payload?.contact);
      } else {
        setErrors(response.payload);
      }
    });
  };

  return (
    <Form
      contentProps={other}
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      content={({ values }) => (
        <>
          <Field
            name="contact.isEmergencyContact"
            label="Emergency Contact"
            component={CheckboxInput}
          />
          {!isNOK && (
            <Field
              name="contact.relationshipType"
              component={SelectInput}
              icon={<AssignedUserIcon />}
              label={contactType}
              options={relationshipTypeValues}
            />
          )}
          <div className="flex flex-col sm:flex-row">
            <div className="flex-1">
              <Field
                name="contact.givenName"
                icon="person"
                label={firstName}
                component={TextInput}
              />
            </div>
            <div className="flex-1">
              <Field
                name="contact.familyName"
                label={lastName}
                component={TextInput}
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row">
            <div className="flex-1">
              <Field
                name="contact.contact"
                icon={<PhoneIcon />}
                label={contactNumber}
                component={TextInput}
              />
            </div>
            <div className="flex-1">
              <Field
                name="contact.alternativeContact"
                icon={<PhoneIcon />}
                label={alternativeContactNumber}
                component={TextInput}
              />
            </div>
          </div>
          <Field
            name="contact.smsRequested"
            label={smsRequested}
            component={CheckboxInput}
          />
          <Field
            name="contact.relationshipDescription"
            label={relationshipDescription}
            component={NoteInput}
          />
        </>
      )}
    />
  );
};

const mapStateToProps = (state, ownProps) => {
  const { patientId, isNOK } = ownProps;
  let contact = getCurrentContact(state);
  const hasExistingNextOfKinContact = getNextOfKinContact(state, patientId);
  const relationshipTypeValues = getRelationshipTypeValues(state);

  contact = {
    ...contact,
    relationshipType: isNOK ? "NextOfKin" : contact.relationshipType,
    givenName: contact?.givenName,
    familyName: contact?.familyName,
    contact: contact?.contact,
  };

  return {
    relationshipTypeValues,
    hasExistingNextOfKinContact,
    initialValues: { contact },
    error: getErrorMessage(state, patientId),
    loading: areContactsLoading(state, patientId),
  };
};

export default connect(mapStateToProps, {})(PatientContactForm);
