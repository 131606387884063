import React from "react";
import ScreenHeader from "components/items/screen-header";
import IntegrationLogsSearch from "./integration-logs-search";

const IntegrationLogsPageHeader = () => {
  const header = "Integration Logs"

  return (
    <ScreenHeader
      title={header}
      renderFilter={<IntegrationLogsSearch />}
      showActionDiv={false}
    />
  );
};

export default IntegrationLogsPageHeader;
