import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDefaultReferralFormProfile } from "app/auth/store/reducers/user.reducer";
import { fetchOrgUnitFormFieldsById } from "app/main/orgUnits/actions/orgUnit.actions";
import { showMessage } from "app/store/actions/message.actions";

const useOpenConfigFormByProfile = (orgUnitId, formFieldConfigurationId, onOpen) => {
  const dispatch = useDispatch();
  const defaultReferralFormProfile = useSelector(state => getDefaultReferralFormProfile(state, orgUnitId));

  const requestFormFieldConfigurationId = formFieldConfigurationId ?? defaultReferralFormProfile?.formFieldConfigurationId;
  const [loading, setLoading] = useState(false);

  // force to load will be true to make sure form fields are up-to-date
  const openConfigForm = props => {
    if (loading) return;
    setLoading(true);
    dispatch(fetchOrgUnitFormFieldsById(orgUnitId, requestFormFieldConfigurationId, true))
      .then(res => {
        if (res.error === true) {
          return dispatch(showMessage({ message: "Error occurred while loading the form configurations", variant: "error" }));
        }

        return setTimeout(() => {
          onOpen(props); // open dialog cause loader freeze
        }, 600);
      })
      .finally(() => {
        setTimeout(() => {
          setLoading(false);
        }, 800);
      });
  };

  return ({
    openConfigForm,
    loadingConfigurations: loading,
  });
};

export default useOpenConfigFormByProfile;
