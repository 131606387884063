import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { map, isEmpty, size, find, get } from "lodash";
import IconButton from "components/items/icon-button";
import { Hidden, Typography } from "@material-ui/core";

import DefaultButton from "components/items/default-button";
import LoadingState from "components/items/loading-state";
import { setCurrentAttachment, resetCurrentPatientOCR, resetCurrentAttachment } from "app/main/patients/actions/patientOCR.selector.actions";
import { getCurrentAttachment } from "app/main/patients/reducers/patientOCR.selector.reducers";
import ReferralSourceDocumentPreview from "app/main/referrals/components/add-referral-form/referralSourceDocumentPreview";
import AutoCompleteInput from "components/inputs/auto-complete-input/input";
import { fetchOCRSpecialtyProfiles } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { areOCRSpecialtyProfilesLoading, getOCRSpecialtyProfiles } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import { processOCR } from "app/main/referrals/actions/referrals.actions";
import { isCurrentReferralSourceDocumentFileLoading } from "app/main/referrals/reducers/referrals.reducers";
import { getEnableOCR, getOCRSpecialtyProfilesLabel } from "app/auth/store/reducers/system-configuration";
import { SpecialtyIcon, OCRIcon } from "helpers/icon-finder";

const useStyles = makeStyles(theme => ({
  rootContainer: {
    flexDirection: "row",
    flex: "auto 1",
    height: "100%",
  },
  headerContainer: {
    display: "flex",
    backgroundColor: theme.palette.background.default,
    alignItems: "center",
    marginTop: theme.spacing(1),
    height: 72,
    borderBottom: "1px solid #e0e0e0",
  },
  headerContentContainer: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    margin: theme.spacing(2),
  },
  headerActionContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    marginRight: theme.spacing(2),
    backgroundColor: "transparent",
  },
  contentContainer: {
    display: "flex",
    height: "calc(100% - 80px)",
    flex: "1 1",
  },
  specialtySelector: {
    minWidth: 320,
    marginRight: "8px",
  },
  leftContainer: {
    overflowX: "hidden",
    margin: theme.spacing(0, 2, 0, 0),
    position: "relative",
    flex: 1,
    height: "100%",
  },
  rightContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderLeft: "1px solid #e0e0e0",
    paddingTop: "8px",
  },
}));

const OCRProcessReferral = ({
  orgUnitId,
  renderContent,
  referredToSpecialty = null,
  onClose,
  onCancel,
  title = null,
  enableProcessOCR = true,
  disabledRemove = false,
  disabledAddDocument = false,
}) => {
  const classes = useStyles();
  const ref = useRef();
  const [width, setWidth] = useState(0);
  const dispatch = useDispatch();

  const isReferralSourceDocumentFileLoading = useSelector(isCurrentReferralSourceDocumentFileLoading);
  const currentAttachment = useSelector(getCurrentAttachment);
  const [isReading, setIsReading] = useState(false);
  const [assignToSpecialty, setAssignToSpecialty] = useState(referredToSpecialty);
  const ocrSpecialtyProfiles = useSelector(getOCRSpecialtyProfiles);
  const ocrSpecialtyProfilesLabel = useSelector(getOCRSpecialtyProfilesLabel);
  const ocrSpecialtyProfilesLoading = useSelector(areOCRSpecialtyProfilesLoading);
  const enableOCR = useSelector(getEnableOCR);
  const [ocrError, setOCRError] = useState(null);
  const [ocrSuccess, setOCRSuccess] = useState(null);

  const updateWidth = () => {
    setWidth(ref.current?.clientWidth ? ref.current?.clientWidth / 2 : 0);
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => { window.removeEventListener("resize", updateWidth); };
  });

  useEffect(() => {
    if (orgUnitId && enableProcessOCR) {
      dispatch(fetchOCRSpecialtyProfiles(orgUnitId));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableProcessOCR, orgUnitId]);

  useEffect(() => {
    if (referredToSpecialty?.value && !find(ocrSpecialtyProfiles, x => x.id === referredToSpecialty?.value)) {
      setAssignToSpecialty(null);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referredToSpecialty]);

  const handleReadPDF = selectedSpecialtyId => {
    if (currentAttachment?.id) {
      setIsReading(true);
      setOCRSuccess("");
      setOCRError("");
      dispatch(resetCurrentPatientOCR());
      dispatch(processOCR(orgUnitId, selectedSpecialtyId, currentAttachment?.id)).then(response => {
        setIsReading(false);
        if (response.error) {
          const errorMessage = get(response.payload, "message", null) ?? response.error;
          setOCRError(errorMessage);
        } else {
          setOCRSuccess("OCR request is completed.");
        }
      });
    } else {
      dispatch(resetCurrentPatientOCR());
    }
  };

  const onFileUploaded = uploadedSourceDocumentFile => {
    if (uploadedSourceDocumentFile) {
      dispatch(setCurrentAttachment(uploadedSourceDocumentFile));
    }
  };

  const onFileRemove = () => {
    setOCRSuccess("");
    setOCRError("");
    dispatch(resetCurrentPatientOCR()); dispatch(resetCurrentAttachment());
  };

  const fileData = currentAttachment?.fileData || currentAttachment?.base64File || currentAttachment?.htmlPreview;
  const enableOCRSpecialty = enableOCR && enableProcessOCR && size(ocrSpecialtyProfiles) > 0;
  const showOCRProcess = !isReading && !ocrSpecialtyProfilesLoading && enableOCRSpecialty;
  const hasPreview = !isEmpty(fileData);
  const showFileRemove = !disabledRemove && !isReading && !ocrSpecialtyProfilesLoading && hasPreview;

  const renderHeader = () => (
    <div className={classes.headerContentContainer}>
      {ocrSpecialtyProfilesLoading && <LoadingState />}
      {!ocrSpecialtyProfilesLoading && enableOCRSpecialty && (
        <AutoCompleteInput
          label={ocrSpecialtyProfilesLabel}
          name="selectedSpecialty"
          icon={<SpecialtyIcon />}
          loading={ocrSpecialtyProfilesLoading}
          value={assignToSpecialty}
          options={map(ocrSpecialtyProfiles, x => ({ label: x.displayName, value: x.id }))}
          onChange={(event, selectedSpecialty) => {
            setAssignToSpecialty(selectedSpecialty);
          }}
          getOptionSelected={(option, val) => option.value === val.value}
          disabled={isReading}
          className={classes.specialtySelector}
        />
      )}
      {showOCRProcess && (
        <DefaultButton
          label="OCR"
          onClick={() => handleReadPDF(assignToSpecialty?.value)}
          icon={<OCRIcon />}
          disabled={!hasPreview || !assignToSpecialty?.value}
          style={{ marginLeft: "8px" }}
        />
      )}
      {!ocrSpecialtyProfilesLoading && enableOCRSpecialty === false && (
        <Typography variant="subtitle1" color="secondary">{title ?? "Add Referral"}</Typography>
      )}
      {showFileRemove && (
        <IconButton style={{ marginLeft: "4px" }} title="Remove file" icon="delete" disabled={isReading} edge="end" onClick={onFileRemove} className="pr-8" />
      )}
    </div>
  );

  const onCloseAction = () => {
    onCancel();
    onClose();
  };

  return (
    <div className={classes.rootContainer} elevation={1}>
      <div className={classes.headerContainer}>
        {renderHeader()}
        <div className={classes.headerActionContainer}>
          <IconButton title="Close" onClick={() => onCloseAction()} icon="close" />
        </div>
      </div>
      <div className={classes.contentContainer} ref={ref}>
        <Hidden smDown>
          <div className={classes.leftContainer}>
            <ReferralSourceDocumentPreview
              fileData={fileData}
              mimeType={currentAttachment?.mimeType}
              pdfProps={{ paged: true, showPreviewControls: true, width: width > 960 ? 960 : width }}
              hasPreview
              loading={isReferralSourceDocumentFileLoading}
              orgUnitId={orgUnitId}
              label="Drag & drop your pdf source referral here or click on the box to upload"
              onFileUploaded={onFileUploaded}
              specialtyId={assignToSpecialty?.value}
              ocrError={ocrError}
              ocrSuccess={ocrSuccess}
              ocrProcessing={isReading}
              disabled={isReading || disabledAddDocument}
            />
          </div>
        </Hidden>
        <div className={classes.rightContainer}>
          {renderContent({ referredToSpecialty: assignToSpecialty, contentClasses: { root: classes.rightContent } })}
        </div>
      </div>
    </div>
  );
};

export default OCRProcessReferral;
