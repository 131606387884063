import { useSelector, useDispatch } from "react-redux";
import { map, filter } from "lodash";
import PropTypes from "prop-types";

import { getReferralWorklistFilter } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import { getReferralWorkflowStateSettings, getEnabledTriageCategorySettings, getReportingUseHangfireQueuing } from "app/auth/store/reducers/system-configuration";
import { updateReferralWorkListFilterTags } from "app/main/referralWorklist/actions/referralWorklist.actions";
import { showExportIndicator } from "app/main/reportExport/actions/reportExport.actions";

const useReferralSearch = ({
  params,
  orgUnitId,
  searchAPI,
  exportAPI,
  updateFilterTags = updateReferralWorkListFilterTags,
}) => {
  const dispatch = useDispatch();
  const triageCategorySettings = useSelector(getEnabledTriageCategorySettings);
  const triageCategoryChoices = map(triageCategorySettings, x => x.triageCategory);
  const referralWorkflowSettings = useSelector(getReferralWorkflowStateSettings);
  const referralStatusChoices = map(filter(referralWorkflowSettings, x => x.isEnabled === true), setting => setting.workflowState);
  const presetFilter = useSelector(getReferralWorklistFilter);
  const reportingUseHangfireQueuing = useSelector(getReportingUseHangfireQueuing);

  const getParameters = parameters => {
    if (!parameters) {
      return {
        searchParams: null,
        initialValues: null,
      };
    }

    const referralFilters = {
      ...parameters,
      orgUnitId,
      dateRange: [params.startDate, params.endDate],
    };

    return ({
      searchParams: referralFilters,
      orgUnitId,
      initialValues: {
        referralFilters,
      },
    });
  };

  const onSearch = (newParams, page, pageSize, reload = false) => new Promise(resolve => {
    const { searchParams } = getParameters(newParams);

    setTimeout(() => {
      resolve(dispatch(searchAPI(searchParams, page || 1, pageSize || 14, reload))
        .then(res => {
          if (res) {
            dispatch(updateFilterTags(res.payload?.searchParams));
          }
        }));
    }, 500);
  });

  const onExport = () => {
    if (!exportAPI) return null;

    const { searchParams } = getParameters(params);

    if (reportingUseHangfireQueuing) {
      dispatch(showExportIndicator());

      return dispatch(exportAPI(searchParams, true));  // background exporting: true
    }
    return dispatch(exportAPI(searchParams, false));  // background exporting: false
  };

  const { searchParams, initialValues } = getParameters(params);

  return ({
    searchParams,
    initialValues,
    referralStatusChoices,
    triageCategoryChoices,
    presetFilter,
    referralWorkflowSettings,
    triageCategorySettings,
    onSearch,
    onExport,
  });
};

useReferralSearch.propTypes = {
  params: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchAPI: PropTypes.func.isRequired,
  exportAPI: PropTypes.func,
};

export default useReferralSearch;
