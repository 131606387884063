import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Field } from "formik";
import { Typography, Popover } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { isEmpty, isObject, filter, find, head, get } from "lodash";

import { saveAssignedUser, reEnrol } from "app/main/patients/actions/patients.actions";
import { UserSelector, SelectInput } from "components/inputs";
import { validateRequired } from "utils/validators";
import Form from "components/form";
import { setRecentSelectedUser } from "app/main/users/actions/users.actions";
import { getUser } from "app/auth/store/reducers/user.reducer";
import { getEnrolmentTypeValues } from "app/auth/store/reducers/system-configuration";
import PopupMenu from "components/items/popup-menu";
import withPermissions from "permissions/withPermissions";
import DefaultButton from "components/items/default-button";
import { showMessage } from "app/store/actions/message.actions";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import PatientWithDrawnForm from "app/main/patients/components/patient-withdrawn-form";
import SubmitButton from "app/main/referrals/components/submit-button";

const enrolmentLabel = "Program";
const userLabel = "Assigned To User";
const validateUser = validateRequired(userLabel);
const validateEnrolment = validateRequired(enrolmentLabel);

const useStyles = makeStyles(theme => ({
  button: {
    backgroundColor: theme.palette.error.light,
    color: theme.palette.error.contrastText,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const PatientAssignUserForm = ({
  patientId,
  enrolments,
  enrolment: currentEnrolment,
  onSucceed,
  enrolmentStatus,
  hasPermissionEnrolmentWithdraw,
  ...other
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const currentUser = useSelector(state => getUser(state));
  const options = useSelector(state => getEnrolmentTypeValues(state));
  const enrolmentType = get(currentEnrolment, ["enrolmentType", "value"], null);
  const currentEnrolmentType = find(options, x => x.value === enrolmentType);
  const showExtraAction = !isEmpty(enrolmentStatus) && hasPermissionEnrolmentWithdraw && !isEmpty(currentEnrolment) && enrolmentType !== "WelfareCheck"; // TODO: temp hide withdraw function from WelfareCheck
  const isWithdrawn = get(enrolmentStatus, "label") === "Withdrawn";
  // disable the form
  // if patient has been withdrawn from Salveo program but it should not apply to WelfareCheck
  const disableForm = isWithdrawn && (!isEmpty(enrolmentType) && enrolmentType !== "WelfareCheck");

  const onClose = () => dispatch(closeDialog());

  const handleSubmit = ({ enrolment }, { setErrors }) => {
    const newEnrolment = {
      ...enrolment,
      assignedUserId: enrolment.assignedUser.value,
      enrolmentType: isObject(enrolment.enrolmentType) ? enrolment.enrolmentType.value : enrolment.enrolmentType,
    };

    dispatch(saveAssignedUser(patientId, newEnrolment)).then(responds => {
      if (responds.error !== true) {
        dispatch(setRecentSelectedUser(enrolment.assignedUser));
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const onWithdraw = () => {
    dispatch(openDialog({
      fullScreen: false,
      children: (
        <PatientWithDrawnForm
          patientId={patientId}
          enrolmentType={enrolmentType}
          onCancel={onClose}
          onSucceed={onClose}
          submitLabel="withdraw"
          title={`Withdraw from ${currentEnrolmentType?.label}`}
        />
      ),
    }));
  };

  const onReEnrol = close => {
    dispatch(reEnrol(patientId, enrolmentType)).then((response => {
      if (response.error === true) {
        dispatch(showMessage({ message: response.payload.exceptionMessage || response.payload.message, variant: "error" }));
      } else {
        onSucceed();
        close();
      }
    }));
  };

  const renderExtraActions = formProps => {
    const label = isWithdrawn ? "Re-enrol" : "Withdraw";
    const confirmationMessage = `Are you sure you want to ${isWithdrawn ? "re-enrol" : "withdraw"} this patient from Salveo?`;

    if (isWithdrawn) {
      return (
        <PopupMenu
          trigger={popupState => (
            <DefaultButton
              label={label}
              size="medium"
              {...popupState}
            />
          )}
          content={({ close, ...otherProps }) => (
            <Popover
              {...otherProps}
            >
              <div className="p-16 max-w-320">
                <Typography>{confirmationMessage}</Typography>
                <div className="mt-16 flex justify-between">
                  <SubmitButton
                    onClick={() => { onReEnrol(close); }}
                    label={`Confirm ${label}`}
                    size="medium"
                    {...formProps}
                    className={classes.button}
                  />
                  <DefaultButton
                    color="default"
                    label="Cancel"
                    className="ml-16 flex-1"
                    size="medium"
                    variant="text"
                    onClick={() => close()}
                  />
                </div>
              </div>
            </Popover>
          )}
        />
      );
    }

    return (
      <DefaultButton
        label={label}
        size="medium"
        onClick={() => onWithdraw()}
        className={classes.button}
      />
    );
  };

  // for form data
  let enrolment = currentEnrolment;
  let enrolmentOptions = options;
  let disableSelect = false;

  if (!isEmpty(enrolments)) {
    // only show options for the programs that the client is not enroled in
    enrolmentOptions = filter(enrolmentOptions, type => find(enrolments, x => x.enrolmentType.value !== type.value));
  }

  if (isEmpty(enrolment)) {
    enrolment = {
      ...enrolment,
      assignedUser: {
        value: currentUser.userId,
        label: currentUser.name,
      },
      enrolmentType: head(enrolmentOptions)?.value,
    };
  } else {
    disableSelect = true;
    enrolment = {
      ...enrolment,
      assignedUser: {
        value: enrolment.assignedToUserId,
        label: enrolment.assignedToUser,
      },
    };
  }

  return (
    <Form
      contentProps={other}
      initialValues={{ enrolment }}
      onSubmit={handleSubmit}
      renderExtraActions={showExtraAction && (props => renderExtraActions(props))}
      disabled={disableForm}
      content={() => (
        <>
          <Field
            name="enrolment.assignedUser"
            label={userLabel}
            component={UserSelector}
            validate={validateUser}
          />

          <Field
            name="enrolment.enrolmentType"
            component={SelectInput}
            options={enrolmentOptions}
            disabled={disableSelect}
            label={enrolmentLabel}
            validate={validateEnrolment}
          />
        </>
      )}
    />
  );
};

export default withPermissions("EnrolmentWithdraw")(PatientAssignUserForm);
