import { get, isEmpty, concat, map, find } from "lodash";
import curryConnector from "utils/connectors";

export const ASSESSMENTS_STATE_KEY = "assessments";

const curry = fn => curryConnector(fn, ASSESSMENTS_STATE_KEY);

export const ActionTypes = {
  CREATING_ASSESSMENT: "CREATING_ASSESSMENT",
  CREATED_ASSESSMENT: "CREATED_ASSESSMENT",
  ERROR_CREATING_ASSESSMENT: "ERROR_CREATING_ASSESSMENT",

  COMPLETED_ASSESSMENT: "COMPLETED_ASSESSMENT",

  CONTINUED_ASSESSMENT: "CONTINUED_ASSESSMENT",
};

const INITIAL_STATE = {
  all: {},
  current: null,
  currentSurvey: null,
  assessmentPrintHistory: null,
};

// we should update the status when we complete the assessment
const onCompleteAssessment = (state, action) => {
  let { assessments } = state.all[action.payload.patientId];
  assessments = map(assessments, x => {
    if (x.id === action.payload.assessment.id) {
      return ({
        ...x,
        status: action.payload.assessment.status,
      });
    }
    return x;
  });

  return {
    ...state,
    all: {
      ...state.all,
      [action.payload.patientId]: {
        ...state.all[action.payload.patientId],
        assessments,
      },
    },
    current: {
      ...state.current,
      status: action.payload.assessment.status,
    },
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.CREATED_ASSESSMENT:
      return {
        ...state,
        all: {
          ...state.all,
          [action.payload.patientId]:
          {
            ...state.all[action.payload.patientId],
            assessments: isEmpty(state.all[action.payload.patientId]) || isEmpty(state.all[action.payload.patientId].assessments)
              ? [action.payload.assessment]
              : concat(...state.all[action.payload.patientId].assessments, action.payload.assessment),
          },
        },
      };
    case ActionTypes.COMPLETED_ASSESSMENT:
      return onCompleteAssessment(state, action);
    default:
      return state;
  }
};

export const getPatientAssessmentList = curry(({ localState }, patientId) => get(localState, ["all", patientId, "assessments"], []));

export const getAssessmentByHeaderId = curry(({ localState }, patientId, assessmentHeaderId) => {
  const assessments = get(localState, ["all", patientId, "assessments"], []);
  return find(assessments, x => x.assessmentHeaderId === assessmentHeaderId) || {};
});

export const getDefaultPatientAssessment = curry(({ localState }, patientId, encounterType) => {
  const assessments = localState?.all[patientId]?.assessments;

  if (isEmpty(assessments)) {
    return "NewAssessment";
  }

  return find(assessments, x => x.type === encounterType && x.status === "InProgress")?.id ?? "NewAssessment";
});
