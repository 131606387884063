import React from "react";
import { get, find, map, filter, includes, isEmpty, isNil} from "lodash";
import FormContext from "components/FormContext";
import AutoCompleteInput from "./input";

export default ({
  options,
  multiple,
  form: { touched, errors, setFieldValue, setFieldTouched },
  field: { name, value },
  onChange,
  handleOnChange,
  onInputChange,
  disabled,
  onBlur,
  ...other
}) => {
  const isTouched = get(touched, name, false);

  const defaultValue = multiple
    ? filter(options, x => includes(value, x?.value) || find(value, val => (val?.value ?? val) === x?.value))
    : find(options, x => x?.value === value || x?.value === value?.value) ?? value ?? value?.label ?? null;

  const defaultOnChange = newValue => {
    if (multiple) {
      setFieldValue(name, map(newValue, x => x.value));
    } else {
      setFieldValue(name, newValue);
    }
  };

  const handleBlur = event => {
    setFieldTouched(name);

    if (onBlur) {
      onBlur(event);
    }
  };

  const handleOnInputChange = (event, inputValue, action) => {
    // when input is cleared
    if (action === "clear") {
      setFieldValue(name, null);
    }
    if (onInputChange) {
      onInputChange(event, inputValue, action);
    }
  };

  const handleOnOptionChange = (event, newValue) => {
    if (!isNil(handleOnChange)) {
      // allow overwrite the default on change function
      handleOnChange(newValue, name, setFieldValue);
    } else {
      defaultOnChange(newValue);
    }

    if (onChange) {
      onChange(newValue, setFieldValue);
    }
  };

  return (
    <FormContext.Consumer>
      {({ formDisabled, formFieldVariant }) => (
        <AutoCompleteInput
          options={options}
          value={defaultValue}
          onChange={handleOnOptionChange}
          error={get(errors, name, null)}
          touched={isTouched}
          multiple={multiple}
          onBlur={handleBlur}
          name={name}
          disabled={disabled || formDisabled}
          onInputChange={handleOnInputChange}
          variant={formFieldVariant}
          showHelperText={!(isEmpty(get(errors, name, null)) && isEmpty(other.helperText))}
          {...other}
        />
      )}
    </FormContext.Consumer>
  );
};
