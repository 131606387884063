export const primary = {
  50: "#ede7f6",
  100: "#d1c4e9",
  200: "#b39ddb",
  300: "#9575cd",
  400: "#7e57c2",
  500: "#673ab7",
  600: "#5e35b1",
  700: "#512da8",
  800: "#4527a0",
  900: "#311b92",
  A100: "#b388ff",
  A200: "#7c4dff",
  A400: "#651fff",
  A700: "#6200ea",
  contrastText: "#fff",
};

export const secondary = {
  light: "#7e57c2",
  main: "#673ab7",
  dark: "#512da8",
  contrastText: "#fff",
};
