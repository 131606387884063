import PatientReferralRecordPage from "./pages/patient-referral-record-page";
import SecurePatientReferralWorklistPage from "./pages/secure-patient-referral-worklist-page";

import PatientReferralWorklistPage from "./pages/patient-referral-worklist-page";

const PatientReferralWorklistConfig = {
  routes: [
    {
      title: "Referral Worklist",
      path: "/PatientReferralWorklist",
      component: SecurePatientReferralWorklistPage,
      exact: true,
      isIndexPage: true,
      routes: [
        {
          title: "Referral Worklist",
          path: "/PatientReferralWorklist?patientId=:patientId",
          component: PatientReferralWorklistPage,
          exact: true,
          isIndexPage: true,
        },
        {
          title: "Referral Worklist",
          path: "/PatientReferralWorklist?patientIdentifier=:identifier",
          component: PatientReferralWorklistPage,
          exact: true,
          isIndexPage: true,
        },
      ],
    },
    {
      path: "/PatientReferralWorklist/patients/:patientId",
      component: PatientReferralRecordPage,
      exact: true,
    },
  ],
};

export default PatientReferralWorklistConfig;
