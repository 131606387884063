import moment from "moment-timezone";
import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { getReferralWorklistSettings } from "app/auth/store/reducers/system-configuration";
import { formattedReferralWorkListFilterTags } from "app/main/referralWorklist/actions/referralWorklist.actions";
import { isPageLoading, isPageLoaded, REFERRALS_PAGE_SIZE, isPatientActiveReferralsLoading, isPatientPresentingComplaintsLoading, ActionTypes } from "../reducers/patientReferrals.reducers";

const timeZone = moment.tz.guess();

const getDefaultSpecialtySearchParams = state => {
  const referralWorklistSettings = getReferralWorklistSettings(state);
  const defaultSorOrder = referralWorklistSettings?.worklistSortOrder;

  return {
    showOldestFirst: defaultSorOrder === "asc",
  };
};

export const resetPatientActiveReferralSearch = () => ({
  type: ActionTypes.RESET_PATIENT_REFERRALS_SEARCH,
});

export const setUpPatientReferralDefaultSearchParams = () => (dispatch, getState) => {
  const defaultSpecialtySearchParams = getDefaultSpecialtySearchParams(getState());

  return dispatch({
    type: ActionTypes.SET_DEFAULT_PATIENT_REFERRALS_SEARCH_PARAMS,
    payload: defaultSpecialtySearchParams,
  });
};

export const fetchPatientReferrals = (searchParams, pageNumber = 1, forceReload = false) => dispatch => {
  const patientId = searchParams?.patientId;
  const orgUnitId = searchParams?.orgUnitId;

  const params = formatSearchParams(searchParams);
  const url = `/referrals/${orgUnitId}/getPatientReferrals?pageNumber=${pageNumber}&pageSize=${REFERRALS_PAGE_SIZE}&timeZone=${timeZone}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      entityType: "PATIENT_REFERRALS",
      patientId,
      pageNumber,
      orgUnitId,
      bailout: state => isPageLoading(state, orgUnitId, patientId, pageNumber) || (!forceReload && isPageLoaded(state, orgUnitId, patientId, pageNumber)),
    },
  });
};

export const fetchPatientActiveReferrals = (orgUnitId, patientId, takeNumber) => dispatch => {
  const url = `/referrals/${orgUnitId}/getPatientReferrals?pageNumber=1&pageSize=${takeNumber ?? 2}&timeZone=${timeZone}&activeOnly=true&patientId=${patientId}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      entityType: "PATIENT_ACTIVE_REFERRALS",
      patientId,
      orgUnitId,
      bailout: state => isPatientActiveReferralsLoading(state, patientId),
    },
  });
};

export const fetchPatientPresentingComplaints = (orgUnitId, patientId) => dispatch => {
  const url = `/referrals/${orgUnitId}/getPatientPresentingComplaints/${patientId}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      entityType: "PATIENT_SPECIALTY_PRESENTING_COMPLAINTS",
      patientId,
      bailout: state => isPatientPresentingComplaintsLoading(state),
    },
  });
};

export const updatePatientReferralWorkFilterTags = searchParams => (dispatch, getState) => {
  const tags = formattedReferralWorkListFilterTags(searchParams, getState);

  return dispatch({
    type: ActionTypes.UPDATE_UPDATE_PATIENT_REFERRAL_FILTER_TAGS,
    payload: tags,
  });
};
