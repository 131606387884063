import React from "react";
import { withRouter } from "react-router-dom";
import { filter } from "lodash";

import DefaultPanelLayout from "components/items/default-panel-layout";
import AppointmentListContainer from "app/main/patients/appointments/containers/appointment-list-container";
import AppointmentList from "./appointment-list";

const AppointmentSummaryPanel = ({
  patientId,
  orgUnitId,
  history,
  referralAppointments,
  ...other
}) => (
  <AppointmentListContainer
    numberOfItems={3}
    patientId={patientId}
    orgUnitId={orgUnitId}
    showAllStatus
    renderContent={({
      appointments,
      isEmpty,
      isLoading,
      isError,
    }) => (
      <DefaultPanelLayout
        isEmpty={isEmpty}
        isLoading={isLoading}
        error={isError}
        title="Appointments"
        icon="perm_contact_calendar"
        emptyTitle="No Recorded Appointments"
        action={!isEmpty
          && {
            label: "View More",
            onClick: () => history.push(`/patients/${patientId}?tab=appointments`),
          }}
        {...other}
      >
        <AppointmentList
          appointments={referralAppointments ? filter(appointments, x => x.appointment.encounterType === "Referral") : appointments}
        />
      </DefaultPanelLayout>
    )}
  />
);

export default withRouter(AppointmentSummaryPanel);
