import React, { useState } from "react";
import { Fade } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import { Field } from "formik";
import * as Yup from "yup";

import Form from "components/form";

import { getCurrentAttachment } from "app/main/applications/reducers/applications.reducers";
import { NoteInput, TextInput } from "components/inputs/text-input";
import DefaultButton from "components/items/default-button";
import { uploadFile } from "app/main/applications/actions/applications.actions";
import CheckboxInput from "components/inputs/checkbox";
import AttachmentUploadField from "components/inputs/attachment-upload";

const descriptionLabel = "File description";
const internalUseLabel = "Internal Use";
const commentLabel = "Comment";

const ResourceForm = ({
  id,
  resourceType,
  description,
  handleSubmit,
  showInternalUseCheck,
  showComment,
  orgUnitId,
  fileOptional,
  ...other
}) => {
  const schema = Yup.object().shape({
    resource: Yup.object().shape({
      attachment: Yup.object().shape({
        fileName: Yup.string()
          .when("link", {
            is: val => val === undefined,
            then:
              Yup.string().fileAttachmentRequired("Attachment", fileOptional).nullable(),
          }),
        link: Yup.string()
          .matches(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?\s*$/,
            "This does not look like a valid URL.")
          .when("fileName", {
            is: fileName => fileName === undefined,
            then: Yup.string().validateLink("Link", fileOptional).nullable(),
          }),
      }, ["fileName", "link"]),
      description: Yup.string()
        .when("attachment.fileName", {
          is: val => val !== undefined,
          then:
            Yup.string().required(`${descriptionLabel} is required.`).nullable(),
        })
        .max(255, "Too Long!")
        .trim()
        .fileAttachmentRequired(descriptionLabel, fileOptional)
        .nullable(),
      internalUse: Yup.bool().nullable(),
      comment: Yup.string()
        .when(["attachment.fileName", "attachment.link"], {
          is: (fileName, link) => fileName === undefined && link === undefined,
          then:
            Yup.string().required(`${commentLabel} is required.`).nullable(),
        })
        .max(1000, "Too long!")
        .nullable(),
    }),
  });

  const dispatch = useDispatch();
  const [type, setType] = useState(resourceType);

  const [uploading, setUploading] = useState(false);
  const [apiError, setAPIError] = useState(false);

  const currentAttachment = useSelector(state => getCurrentAttachment(state));

  let { resource } = schema.cast();

  if (description) {
    resource = {
      ...resource,
      description,
    };
  }

  if (showInternalUseCheck) {
    resource = {
      ...resource,
      internalUse: true,
    };
  }

  const initialValues = { resource };
  const disabledForm = !showComment && (!type || uploading || apiError);

  return (
    <Form
      initialStatus={{ apiErrors: null }}
      initialValues={initialValues}
      validationSchema={schema}
      disabled={disabledForm}
      onSubmit={(data, formProps) => handleSubmit(data, formProps, currentAttachment)}
      contentProps={other}
      content={({ setFieldTouched, setFieldValue, setStatus, values }) => (
        <>
          {type && showInternalUseCheck
          && (
            <Field
              name="resource.internalUse"
              component={CheckboxInput}
              label={internalUseLabel}
              showIcon={false}
            />
          )}
          {!type
          && (
            <div className="flex-row-container with-gutter">
              <DefaultButton className="flex-1" variant="outlined" size="large" label="add an attachment" onClick={() => setType("attachment")} />
              <DefaultButton className="flex-1 ml-8" variant="outlined" size="large" label="add a link" onClick={() => setType("link")} />
            </div>
          )}
          {type
            && (
              <Fade in={!isEmpty(type)}>
                <div>
                  {type === "attachment"
                  && (
                    <Field
                      name="resource.attachment.fileName"
                      component={AttachmentUploadField}
                      label="Attachment"
                      icon="attachment"
                      loading={uploading}
                      required={!fileOptional}
                      onChange={file => {
                        if (file) {
                          setFieldValue("resource.attachment.file", file);
                          setFieldValue("resource.attachment.fileName", file.name);
                          const reader = new FileReader();
                          reader.readAsDataURL(file);
                          setUploading(true);
                          reader.onload = () => {
                            const formData = new FormData();
                            formData.append("file", file);
                            dispatch(uploadFile(id, formData, orgUnitId)).then(response => {
                              if (response.error === true) {
                                setStatus({ apiErrors: response.payload.message });
                                setAPIError(true);
                                setFieldTouched("resource.attachment.fileName");
                              } else {
                                setStatus({ apiErrors: null });
                                setAPIError(null);
                              }
                              setUploading(false);
                            });
                          };
                        }
                      }}
                    />
                  )}
                  {type === "link"
                  && (
                    <Field
                      name="resource.attachment.link"
                      component={TextInput}
                      label="URL"
                      icon="link"
                      required
                    />
                  )}
                  {(values.resource.attachment.fileName || values.resource.attachment.link)
                  && (
                    <Field
                      name="resource.description"
                      component={TextInput}
                      label={descriptionLabel}
                      required={!fileOptional}
                      maxLength={255}
                    />
                  )}
                  {showComment && (
                  <Field
                    name="resource.comment"
                    component={NoteInput}
                    label={commentLabel}
                    maxLength={1000}
                  />
                  )}
                </div>
              </Fade>
            )}
        </>
      )}
    />
  );
};

export default ResourceForm;
