import React from "react";
import { useSelector, useDispatch } from "react-redux";
import * as Yup from "yup";
import { Field } from "formik";
import { find } from "lodash";
import CardTextOutlineIcon from "mdi-react/CardTextOutlineIcon";
import withPermissions from "permissions/withPermissions";

import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { createPatientIdentifier, updatePatientIdentifier, removePatientIdentifier } from "app/main/patients/actions/patients.actions";
import { resetPatientSearch } from "app/main/patients/actions/patientsSearch.actions";
import { SelectInput, TextInput, CheckboxInput } from "components/inputs";
import { getPatientIdentifierTypeOptions, getPrimaryPatientIdentifierSettings, showExternalPatientSearch, enablePatientIdentifierSearch } from "app/auth/store/reducers/system-configuration";
import Form from "components/form";
import getPatientIdentifierLength from "app/main/patients/helpers/get-patient-identifier-length";
import "utils/yup-validation";
import PatientSearchButton from "app/main/patients/components/external-patient-search-button";

const typeLabel = "Type";
const identifierLabel = "Identifier";
const deprecatedLabel = "Is Deprecated";

const PatientIdentifierForm = ({
  patient,
  patientId,
  patientIdentifier: identifier,
  onSucceed,
  disabledPatientIdentifierTypeCode,
  showIsDeprecated,
  patientIdentifiers,
  hasPermissionPatientsIdentifiersRemove,
  ...other
}) => {
  const dispatch = useDispatch();
  const typeOptions = useSelector(getPatientIdentifierTypeOptions); // to return allowed options useSelector(state => getPatientIdentifierTypeOptions(state, true))
  const primaryPatientIdentifierSettings = useSelector(getPrimaryPatientIdentifierSettings);
  const primaryPatientIdentifier = primaryPatientIdentifierSettings?.type;
  const primaryPatientIdentifierMaxLength = primaryPatientIdentifierSettings?.maxLength;
  const primaryPatientIdentifierMinLength = primaryPatientIdentifierSettings?.minLength;
  const numericIdentifiersOnly = primaryPatientIdentifierSettings?.numericIdentifiersOnly;
  const enabledExternalPatientSearch = useSelector(showExternalPatientSearch);
  const isEnablePatientIdentifierSearch = useSelector(enablePatientIdentifierSearch);

  const handleRemove = () => {
    dispatch(removePatientIdentifier(patientId, identifier.patientIdentifierId)).then(responds => {
      if (responds.error !== true) {
        dispatch(resetPatientSearch());
        onSucceed();
      }
    });
  };

  const handleSubmit = ({ patientIdentifier }, { setErrors }) => {
    const apiCall = patientIdentifier.patientIdentifierId ? updatePatientIdentifier : createPatientIdentifier;
    dispatch(apiCall(patientId, patientIdentifier)).then(responds => {
      if (responds.error !== true) {
        dispatch(resetPatientSearch());
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const schema = Yup.object().shape({
    patientIdentifier: Yup.object().shape({
      patientIdentifierTypeCode: Yup.string()
        .required(`${typeLabel} is required`)
        .nullable(),
      identifier: Yup.string()
        .when("patientIdentifierTypeCode", (patientIdentifierTypeCode, YupSchema) => {
          const isPrimaryPatientIdentifier = patientIdentifierTypeCode === primaryPatientIdentifier;
          const patientIdentifierLength = getPatientIdentifierLength(patientIdentifierTypeCode) ?? 11;

          if (isPrimaryPatientIdentifier) {
            return YupSchema.validateIdentifier(identifierLabel, { min: primaryPatientIdentifierMinLength, max: primaryPatientIdentifierMaxLength, numberOnly: numericIdentifiersOnly });
          }
          return YupSchema.validateIdentifier(identifierLabel, { min: patientIdentifierLength, max: patientIdentifierLength });
        })
        .trim().required(`${identifierLabel} is required`)
        .nullable(),
    }),
  });

  const isEdit = identifier?.patientIdentifierId;

  let { patientIdentifier } = schema.cast();

  if (identifier) {
    patientIdentifier = identifier;
  }
  let patientSearchFilter = {};
  if (patient) {
    patientSearchFilter = {
      givenName: patient.givenName,
      middleName: patient.middleName,
      familyName: patient.familyName,
      dateOfBirth: patient.birthDate,
      sex: patient.sex,
      dvaNumber: find(patientIdentifiers, x => x.patientIdentifierTypeCode === "DVA")?.identifier,
      medicareNumber: find(patientIdentifiers, x => x.patientIdentifierTypeCode === "MC")?.identifier,
    };
  }

  const openPatientForm = currentIdentifier => {
    dispatch(openDialog({
      maxWidth: "sm",
      children: (
        <PatientIdentifierForm
          patientIdentifier={currentIdentifier}
          disabledPatientIdentifierTypeCode={disabledPatientIdentifierTypeCode}
          patientIdentifiers={patientIdentifiers}
          patientId={patientId}
          patient={patient}
          onCancel={() => dispatch(closeDialog())}
          onSucceed={() => dispatch(closeDialog())}
          showIsDeprecated
        />
      ),
    }));
  };

  return (
    <Form
      contentProps={{ ...other, title: `${isEdit ? "Edit" : "Add"} Patient Identifier` }}
      initialValues={{ patientIdentifier }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      variant="filled"
      onRemove={(isEdit && hasPermissionPatientsIdentifiersRemove) ? () => handleRemove() : null}
      content={({ values }) => (
        <>
          <Field
            name="patientIdentifier.patientIdentifierTypeCode"
            label={typeLabel}
            component={SelectInput}
            options={typeOptions}
            disabled={disabledPatientIdentifierTypeCode}
            required
            icon={<CardTextOutlineIcon />}
          />
          <div className="flex items-center justify-center">
            <Field
              className=" flex-1"
              name="patientIdentifier.identifier"
              label={identifierLabel}
              component={TextInput}
              required
            />
            {isEnablePatientIdentifierSearch && enabledExternalPatientSearch && primaryPatientIdentifier === values?.patientIdentifier?.patientIdentifierTypeCode && (
            <div className="ml-16">
              <PatientSearchButton
                patientPresetFilter={patientSearchFilter}
                onContinue={selectedPatient => {
                  dispatch(resetPatientSearch());
                  openPatientForm({ ...values?.patientIdentifier, identifier: selectedPatient.patientIdentifier });
                }}
                onCancel={() => {
                  dispatch(resetPatientSearch());
                  openPatientForm(values?.patientIdentifier);
                }}
              />
            </div>
            )}
          </div>

          {showIsDeprecated && (
            <div className="ml-32">
              <Field
                name="patientIdentifier.isDeprecated"
                component={CheckboxInput}
                label={deprecatedLabel}
                showIcon={false}
              />
            </div>
          )}
        </>
      )}
    />
  );
};

export default withPermissions("PatientsIdentifiersRemove")(PatientIdentifierForm);
