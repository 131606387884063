import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getExistingPatientById } from "app/main/patients/actions/patients.actions";
import { getCurrentPatient, isCurrentPatientLoading } from "app/main/patients/reducers/patients.reducers";
import { clearReferralSourceDocumentFiles } from "app/main/referrals/actions/referrals.actions";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import AddReferralForm from "app/main/referrals/components/add-referral-form";
import useOpenConfigFormByProfile from "hooks/use-open-config-form-by-profile";

const useAddPatientReferral = ({
  patientId,
  orgUnitId,
  onSucceed,
  allowSaveAsDraft,
}) => {
  const dispatch = useDispatch();
  const currentPatientLoading = useSelector(isCurrentPatientLoading);
  const patient = useSelector(getCurrentPatient);

  useEffect(() => {
    if (!currentPatientLoading && patient?.patientId !== patientId) {
      dispatch(getExistingPatientById(patientId));
    }
    dispatch(clearReferralSourceDocumentFiles());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClose = () => dispatch(closeDialog());

  const createdReferral = () => Promise.resolve(onSucceed()).then(() => onClose());

  const onAddReferral = () => dispatch(openDialog({
    maxWidth: "xl",
    children: (
      <AddReferralForm
        orgUnitId={orgUnitId}
        patient={patient}
        allowSaveAsDraft={allowSaveAsDraft}
        onCancel={onClose}
        onSucceed={createdReferral}
        title="Add Referral"
        enableProcessOCR
      />
    ),
  }));

  const { openConfigForm, loadingConfigurations } = useOpenConfigFormByProfile(orgUnitId, null, onAddReferral);

  return ({
    openAddReferralForm: () => (onSucceed ? openConfigForm() : null),
    loadingConfigurations,
  });
};

export default useAddPatientReferral;
