import { ApiActions } from "middleware/call-api";
import { getUser } from "app/auth/store/reducers/user.reducer";
import { getUserSearchTerms } from "app/main/users/reducers/users.reducers";
import formatSearchParams from "helpers/format-search-params";
import { isEqual } from "lodash";

import { ActionTypes, areRolesLoaded, areRolesLoading, isPageLoading, isPageLoaded, isCurrentUserLoading } from "../reducers/users.reducers";

const setCurrentUserAction = user => ({
  type: ActionTypes.SET_CURRENT_USER,
  payload: { user },
});

export const resetCurrentUser = () => ({
  type: ActionTypes.RESET_CURRENT_USER,
});

export const resetUsersFilters = () => ({
  type: ActionTypes.RESET_USERS_FILTERS,
});

export const setRecentSelectedUser = user => ({
  type: ActionTypes.SET_RECENT_SELECTED_USER,
  payload: { user },
});

export const createNewUser = () => dispatch => {
  dispatch(
    setCurrentUserAction({
      userId: null,
      firstName: null,
      lastName: null,
      username: null,
      email: null,
      userRoles: null,
      specialtyProfileId: null,
    }),
  );
};
// TO DO - double check loadCurrentUser condition when update user selector, check if its still needed
export const fetchUsersResults = (orgUnitId, pageNumber, pageSize, searchParams, loadCurrentUser = false, forceLoad = true) => (dispatch, getState) => {
  const params = formatSearchParams(searchParams);
  const url = `/users/${orgUnitId}?pageNumber=${pageNumber}&pageSize=${pageSize}${params}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "users",
      pageNumber,
      currentUser: loadCurrentUser ? getUser(getState()) : null,
      searchParams,
      bailout: state => {
        const currentSearchParams = getUserSearchTerms(state);

        return (isPageLoading(state, pageNumber))
               || ((!forceLoad && isPageLoaded(state, pageNumber)) && (isEqual(currentSearchParams, searchParams)));
      },
    },
  });
};

export const addNewUser = (orgUnitId, user) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/users/${orgUnitId}`,
    body: { user },
    entityType: "user",
  },
});

export const getExistingUserById = (orgUnitId, userId) => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/users/${orgUnitId}/${userId}`,
    entityType: "CURRENT_USER",
    bailout: state => isCurrentUserLoading(state),
  },
});

export const updateExistingUser = (orgUnitId, user) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/users/${orgUnitId}`,
    body: { user },
    entityType: "user",
  },
});

export const deactivateUser = userId => ({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/users/deactivateUser/${userId}`,
    entityType: "DEACTIVATE_USER",
    userId,
  },
});

export const activateUser = userId => ({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/users/activateUser/${userId}`,
    entityType: "ACTIVATE_USER",
    userId,
  },
});

export const fetchAllUserRoles = () => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: "/users/roles",
    bailout: state => areRolesLoaded(state) || areRolesLoading(state),
    entityType: "ROLES",
  },
});

export const fetchUserListForSelector = (searchParams, pageSize = 25) => dispatch => {
  const params = formatSearchParams(searchParams);

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: `/users/getUserSelectorResults?&pageSize=${pageSize}${params}`,
      method: "GET",
      entityType: "USER_SELECTOR",
    },
  });
};

export const updateUserProfile = user => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: "/users/updateUserProfile",
    body: { user },
    entityType: "USER_PROFILE",
  },
});

export const importUserValues = (orgUnit, formData) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/users/${orgUnit}/importUserValues`,
    headers: { "Content-Type": "multipart/form-data" },
    body: formData,
    types: [ActionTypes.IMPORTING_USER_VALUES, ActionTypes.IMPORTED_USER_VALUES, ActionTypes.ERROR_IMPORTING_USER_VALUES],
  },
});
