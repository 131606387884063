import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { showMessage } from "app/store/actions/message.actions";
import { getError } from "utils/errorutil";
import { Field } from "formik";
import * as Yup from "yup";
import { isEmpty, map } from "lodash";

import Form from "components/form";
import { getGPLetterAcceptFormat, getGPLetterOutputTypes, getReferralWorkflowStateSettings, getEnableAutoGPLetterGeneration } from "app/auth/store/reducers/system-configuration";
import { SelectInput, TextInput, CheckboxInput, AutoComplete } from "components/inputs";
import AttachmentUploadField from "components/inputs/attachment-upload";
import { uploadGPLetterAttachment, updateGPLetterTemplate, updateGPLetterAttachment } from "app/main/orgUnits/actions/orgUnit.actions";

const typeLabel = "Letter Template Type";
const displayNameLabel = "Display Name";
const outputLabel = "Filetype";
const patientSMSLabel = "Trigger Patient SMS";
const autoGenerateForReferralStatusesLabel = "Auto generate letter on referral status";
const autoGenerateNoPatientSMSConsentOnlyLabel = "Only auto generate letter for patients without SMS";
const includeInHL7ReferralMessagesLabel = "Include letter in REF_I12/REF_I13 messages";
const excludeFromPrintedReferralLabel = "Exclude From Printed Referral";

const schema = Yup.object().shape({
  attachment: Yup.object().shape({
    fileName: Yup.string().required("Please choose a file"),
    gpLetterTemplateType: Yup.string().required(`${typeLabel} is required`).nullable(),
    displayName: Yup.string().required(`${displayNameLabel} is required`).max(200, "Too long!").nullable(),
    outputType: Yup.string().required(`${outputLabel} is required`).nullable(),
    patientSMS: Yup.boolean().nullable(),
  }),
});

const OrgUnitGPLetterTemplateForm = ({
  typeOptions,
  currentAttachment = null,
  onSucceed,
  orgUnitId,
  editAttachment = false,
  ...other
}) => {
  const dispatch = useDispatch();
  const formats = useSelector(getGPLetterAcceptFormat);
  const gpLetterOutputTypes = useSelector(getGPLetterOutputTypes);
  const [disabledActions, setDisabledActions] = useState(false);
  const [uploading, setUploading] = useState(false);
  const enabledAttachment = editAttachment || currentAttachment === null;
  const referralWorkflowStateSettings = useSelector(getReferralWorkflowStateSettings);
  const enableAutoGPLetterGeneration = useSelector(getEnableAutoGPLetterGeneration);
  const autoGeneratedTypes = map(referralWorkflowStateSettings, x => ({ value: x.workflowState, label: x.label }));

  useEffect(() => {
    setDisabledActions(uploading || editAttachment);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uploading]);

  const handleSubmit = ({ attachment }, { setSubmitting, setErrors }) => {
    dispatch(updateGPLetterTemplate(orgUnitId, attachment)).then(response => {
      setSubmitting(false);
      if (response.error !== true) {
        onSucceed();
      } else {
        setErrors(response.payload);
      }
    });
  };

  let { attachment } = schema.cast();

  if (currentAttachment) {
    attachment = currentAttachment;
  }

  const displayApiError = (response, setStatus) => {
    setStatus({ apiErrors: response.payload.message });
    const errorMessage = getError(response);
    dispatch(showMessage({
      title: errorMessage.title,
      variant: "error",
      message: errorMessage.message,
      anchorOrigin: { vertical: "bottom", horizontal: "center" },
    }));
    setUploading(false);
  };

  return (
    <Form
      initialStatus={{ apiErrors: null }}
      initialValues={{ attachment }}
      validationSchema={schema}
      onSubmit={handleSubmit}
      contentProps={other}
      disabledActions={disabledActions}
      content={({ setFieldValue, setStatus, values }) => (
        <>
          {!editAttachment
          && (
          <>
            <Field
              name="attachment.gpLetterTemplateType"
              component={SelectInput}
              label={typeLabel}
              options={typeOptions}
              disabled={editAttachment}
              required
            />
            <Field
              name="attachment.displayName"
              component={TextInput}
              label={displayNameLabel}
              disabled={editAttachment}
              required
            />
          </>
          )}
          {enabledAttachment && (
            <Field
              name="attachment.fileName"
              component={AttachmentUploadField}
              label="Attachment"
              icon="attachment"
              acceptFormat={formats}
              loading={uploading}
              required
              onChange={file => {
                // unlock the save button
                setDisabledActions(false);

                if (file) {
                  setFieldValue("attachment.fileName", file.name);
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  setUploading(true);
                  reader.onload = () => {
                    const formData = new FormData();
                    formData.append("file", file);
                    if (editAttachment) {
                      dispatch(updateGPLetterAttachment(orgUnitId, attachment.id, formData)).then(response => {
                        if (response.error === true) displayApiError(response, setStatus);
                        else onSucceed();
                      });
                    } else {
                      // save the attachment
                      dispatch(uploadGPLetterAttachment(orgUnitId, formData)).then(response => {
                        if (response.error === true) displayApiError(response, setStatus);
                        else {
                          setFieldValue("attachment.id", response.payload.id);
                          setStatus({ apiErrors: null });
                          setUploading(false);
                        }
                      });
                    }
                  };
                }
              }}
            />
          )}
          {!editAttachment && (
            <>
              <Field
                name="attachment.outputType"
                component={SelectInput}
                label={outputLabel}
                options={gpLetterOutputTypes}
                disabled={editAttachment}
                required
              />
              <Field
                name="attachment.patientSMS"
                component={CheckboxInput}
                label={patientSMSLabel}
                disabled={editAttachment}
              />
              <Field
                name="attachment.excludeFromPrintedReferral"
                component={CheckboxInput}
                label={excludeFromPrintedReferralLabel}
              />
            </>
          )}
          {!editAttachment && enableAutoGPLetterGeneration && (
            <>
              <Field
                name="attachment.autoGenerateForReferralStatuses"
                component={AutoComplete}
                label={autoGenerateForReferralStatusesLabel}
                options={autoGeneratedTypes}
                disabled={editAttachment}
                multiple
              />
              <Field
                name="attachment.autoGenerateNoPatientSMSConsentOnly"
                component={CheckboxInput}
                disabled={isEmpty(values.attachment.autoGenerateForReferralStatuses)}
                label={autoGenerateNoPatientSMSConsentOnlyLabel}
              />
              <Field
                name="attachment.includeInHL7ReferralMessages"
                component={CheckboxInput}
                label={includeInHL7ReferralMessagesLabel}
              />
            </>
          )}
        </>
      )}
    />
  );
};

export default OrgUnitGPLetterTemplateForm;
