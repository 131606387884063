
import { useSelector } from "react-redux";
import { get, isEmpty } from "lodash";
import useFormFieldConfigByProfile from "hooks/use-form-field-config-by-profile";
import { getFieldLabels } from "app/main/referrals/utils/referral-fieldLabels";
import { formatFieldSettings } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { getSignedInOrgUnit, getDefaultOrgUnitFormFieldConfig } from "app/auth/store/reducers/user.reducer";

// load current orgUnit form field settings - 'CreateEditReferral';
const useReferralFieldSettings = formFieldConfigurationId => {
  const signedInOrgUnitId = useSelector(state => getSignedInOrgUnit(state))?.id;
  const { fieldSettings } = useFormFieldConfigByProfile(signedInOrgUnitId, formFieldConfigurationId);
  const defaultOrgUnitFormFields = useSelector(state => getDefaultOrgUnitFormFieldConfig(state, signedInOrgUnitId));
  const configurationJson = get(defaultOrgUnitFormFields, ["CreateEditReferral"])?.configurationJson;
  const defaultFieldSettings = isEmpty(configurationJson) ? null : formatFieldSettings(JSON.parse(configurationJson));

  return formFieldConfigurationId ? ({
    fieldSettings,
    fieldLabels: getFieldLabels(fieldSettings),
  })
    : ({
      fieldSettings: defaultFieldSettings,
      fieldLabels: getFieldLabels(defaultFieldSettings),
    });
};

export default useReferralFieldSettings;
