import React, { useMemo, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { TableCell, TableRow } from "@material-ui/core";
import { isEmpty, startCase } from "lodash";
import PagingTable from "components/items/paging-table";
import { USERS_PAGE_SIZE_OPTIONS, getAllUsers, isPageLoading, getErrorMessage, getUserSearchTerms, getPageInfo } from "app/main/users/reducers/users.reducers";
import Avatar from "components/items/avatar";
import withPermissions from "permissions/withPermissions";
import TextTag from "components/items/text-tag";
import { displayUserSpecialties } from "helpers/format-user-specialties";
import { fetchUsersResults, getExistingUserById } from "app/main/users/actions/users.actions";
import useUserUniversalSearchConfig from "hooks/use-user-universal-search-config";

const UsersTable = ({
  history,
  orgUnit,
  hasPermissionUsersMaintain,
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(isPageLoading);
  const error = useSelector(getErrorMessage);
  const users = useSelector(getAllUsers);
  const pageInfo = useSelector(getPageInfo);
  const searchParams = useSelector(getUserSearchTerms);
  const { isSpecialtyFieldEnabled, isUserRoleFieldEnabled } = useUserUniversalSearchConfig();
  const showSpecialtyColumn = isSpecialtyFieldEnabled;

  const enableUpdate = hasPermissionUsersMaintain;
  const orgUnitId = orgUnit?.id;
  const restrictSearchParams = useMemo(() => ({
    ...searchParams,
    restrictRolesToOrgUnitId: orgUnitId,
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }), [JSON.stringify(searchParams), orgUnitId]);

  useEffect(() => {
    dispatch(fetchUsersResults(orgUnitId, 1, 50, restrictSearchParams, false, history.location.state?.forceLoad === true || false));
  }, [dispatch, history, restrictSearchParams, orgUnitId]);

  const loadUserResult = (pageNumber, pageSize, forceLoad = true) => dispatch(fetchUsersResults(orgUnitId, pageNumber, pageSize, restrictSearchParams, false, forceLoad));
  const onChangeRowsPerPage = pageSize => loadUserResult(1, pageSize);
  const onChangePage = pageNumber => loadUserResult(pageNumber + 1, pageInfo.pageSize);
  const onEdit = id => dispatch(getExistingUserById(orgUnitId, id)).then(() => history.push(`/admin/users/${id}`));

  const renderTableHeader = () => (
    <TableRow>
      <TableCell />
      <TableCell>Name</TableCell>
      <TableCell>Username</TableCell>
      <TableCell>{showSpecialtyColumn ? "Specialty" : "Email Address"}</TableCell>
      <TableCell>Roles</TableCell>
    </TableRow>
  );

  const getUserRolesDisplay = user => {
    if (!user.userRoles || user.userRoles.length === 0) return "";

    return user.userRoles
      .map(role => {
        const roleTitle = role.roleDescription ?? startCase(role.roleName);
        const roleInfo = `${roleTitle} (${role.orgUnitName})`;

        return role.isRoleDeprecated ? `${roleInfo} (Deprecated)` : roleInfo;
      })
      .join(", ");
  };

  const renderTableRow = user => (
    <TableRow
      hover={enableUpdate}
      key={user.userId}
      onClick={enableUpdate ? () => onEdit(user.userId) : null}
    >
      <TableCell className="w-52" component="th" scope="row" padding="none">
        <div className="flex justify-center py-8 px-16">
          <Avatar />
          {!isEmpty(user.inactiveDateTimeUtc) && (
          <TextTag label="INACTIVE" variant="warning" type="badge" />
          )}
        </div>
      </TableCell>
      <TableCell style={{ minWidth: 200 }}>{user.name}</TableCell>
      <TableCell>{user.username}</TableCell>
      <TableCell>{showSpecialtyColumn ? displayUserSpecialties(user.userSpecialtyProfiles) : user.email}</TableCell>
      <TableCell>{isUserRoleFieldEnabled && getUserRolesDisplay(user)}</TableCell>
    </TableRow>
  );

  return (
    <PagingTable
      loading={loading}
      error={error}
      data={users}
      rowsPerPage={pageInfo.pageSize}
      columnSpan={5}
      pageSizeOptions={USERS_PAGE_SIZE_OPTIONS}
      page={pageInfo.pageNumber - 1}
      count={pageInfo.totalRecords}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onChangePage={onChangePage}
      emptyMessage="No Users Recorded"
      tableHead={renderTableHeader}
      tableRow={item => renderTableRow(item)}
    />
  );
};

export default withPermissions("UsersMaintain")(UsersTable);
