import React from "react";
import { ButtonBase, Link } from "@material-ui/core";

import withPermissions from "permissions/withPermissions";
import downloadFromApi from "utils/download-from-api";

const ProviderHelperText = ({
  hasPermissionReferralProviderEmailTemplateDownload,
}) => {
  if (!hasPermissionReferralProviderEmailTemplateDownload) return null;

  const handleDownload = () => downloadFromApi("api/referrals/getProviderEmailTemplate");

  return (
    <span className="flex-row-container">
      <span className="mr-4">If you cannot find a provider, please complete and send the following </span>
      <ButtonBase component={Link} onClick={handleDownload}>email template</ButtonBase>
    </span>
  );
};

export default withPermissions("ReferralProviderEmailTemplateDownload")(ProviderHelperText);
