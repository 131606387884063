import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { map, isEmpty, find, size, head, filter, some, includes, intersection } from "lodash";
import { getUser, getDefaultReferralFormProfile, getReferralFormProfilesByOrgUnitId, getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import { areSearchedSpecialtyProfilesLoading, getOverrideDefaultFormProfilesFromSearchedSpecialtyProfile, getCurrentSpecialtyReferralFormProfiles } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { SummaryIcon } from "helpers/icon-finder";

export default ({
  label,
  specialtyId,
  orgUnitId,
  showIcon = true,
  onChange,
  extraParams = null,
  onAutoAssignExtraAction = null,
  disabled,
  initialOptions,
  ...other
}) => {
  const [options, setOptions] = useState(initialOptions || []);
  const currentSiteReferralFormProfiles = useSelector(state => getReferralFormProfilesByOrgUnitId(state, orgUnitId));
  const overrideDefaultFormProfiles = useSelector(state => getOverrideDefaultFormProfilesFromSearchedSpecialtyProfile(state, specialtyId));
  const specialtyReferralFormProfileIds = useSelector(state => getCurrentSpecialtyReferralFormProfiles(state, specialtyId));
  const loading = useSelector(areSearchedSpecialtyProfilesLoading);
  const user = useSelector(getUser);
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);
  const currentUserRoles = filter(user.userRoles, x => x.orgUnitId === signedInOrgUnit?.id);
  const roleIds = map(currentUserRoles, x => x.roleId);
  const defaultReferralFormProfile = useSelector(state => getDefaultReferralFormProfile(state, orgUnitId));

  const { field: { name, value }, form: { setFieldValue } } = other; // consider existing value   
  const shouldLoadOption = isEmpty(value) && isEmpty(initialOptions);  
  
  const disabledEdit = disabled || size(options) === 1;
  // Apply role validation if the referral form profile is restricted to specific roles
  const accessibleDefaultReferralFormProfile = !overrideDefaultFormProfiles
   && (!defaultReferralFormProfile?.restrictToSpecificRoles 
      || (defaultReferralFormProfile?.restrictToSpecificRoles && (defaultReferralFormProfile?.restrictToSpecificRoles && intersection(defaultReferralFormProfile?.currentReferralFormProfileRoles, roleIds).length > 0)))
   ? defaultReferralFormProfile : null;
  const defaultOptions = !isEmpty(accessibleDefaultReferralFormProfile) ? [accessibleDefaultReferralFormProfile] : [];
  const accessibleReferralFormProfiles = filter(currentSiteReferralFormProfiles, x => !x.restrictToSpecificRoles
    || (x.restrictToSpecificRoles && intersection(x.currentReferralFormProfileRoles, roleIds).length > 0));

    useEffect(() => {
      if (shouldLoadOption) {
        loadOptions(specialtyId, overrideDefaultFormProfiles);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldLoadOption, specialtyId, overrideDefaultFormProfiles]);

  const loadOptions = (selectedSpecialtyId, selectedOverrideDefaultFormProfiles) => {

    if (selectedSpecialtyId && !loading) {
      if (selectedOverrideDefaultFormProfiles) {
        setOptions([]);
        const selectedSpecialtyReferralFormProfiles = filter(accessibleReferralFormProfiles, x => includes(specialtyReferralFormProfileIds, x.id));
        const hasSelectedSpecialtyProfiles = size(selectedSpecialtyReferralFormProfiles) > 0;
        // Use the selected profiles or default options based on the above check
        let specialtyReferralFormProfileOptions = hasSelectedSpecialtyProfiles ? selectedSpecialtyReferralFormProfiles : defaultOptions;        
        // Check if the current value is not in the options and add it if necessary
        const currentValue = value?.value ?? value;
        if (currentValue && !some(specialtyReferralFormProfileOptions, option => option.id === currentValue)) {
          const currentOption = find(currentSiteReferralFormProfiles, profile => profile.id === currentValue);

          if (currentOption) {
            specialtyReferralFormProfileOptions = [...specialtyReferralFormProfileOptions, currentOption];
          }
        }
        setOptions(specialtyReferralFormProfileOptions);

        if (isEmpty(currentValue) || (hasSelectedSpecialtyProfiles && !some(specialtyReferralFormProfileOptions, profile => profile.id === currentValue))) {
          let currentSpecialtyReferralFormProfile = accessibleDefaultReferralFormProfile;
          if (hasSelectedSpecialtyProfiles > 0) {
            currentSpecialtyReferralFormProfile = head(filter(selectedSpecialtyReferralFormProfiles, x => x.isDefault)) ?? head(selectedSpecialtyReferralFormProfiles);
          }
          const currentSelectedValue = !isEmpty(currentSpecialtyReferralFormProfile) ? { label: currentSpecialtyReferralFormProfile.name, value: currentSpecialtyReferralFormProfile.id } : null;
          setFieldValue(name, currentSelectedValue);

          if (onAutoAssignExtraAction) {
            onAutoAssignExtraAction(currentSpecialtyReferralFormProfile, extraParams);
          }
        }
      } else if (!isEmpty(accessibleDefaultReferralFormProfile)) {
        setOptions(defaultOptions);
        setFieldValue(name, { label: accessibleDefaultReferralFormProfile.name, value: accessibleDefaultReferralFormProfile.id });
        if (onAutoAssignExtraAction) {
          onAutoAssignExtraAction(accessibleDefaultReferralFormProfile, extraParams);
        }
      } else {
        setOptions([]);
      }
    } else if (isEmpty(selectedSpecialtyId)) {
      setOptions([]);
    }
  };

  const onSelectChange = selectedValue => {
    if (onChange) {
      const selectedReferralFormProfile = find(options, x => x.id === selectedValue?.value);
      onChange(selectedReferralFormProfile, extraParams);
    }
  };

  if (loading) return <AutoCompleteLoading label={label} />;

  return (
    <AutoComplete
      icon={showIcon && <SummaryIcon />}
      label={label}
      options={map(options, x => ({ label: x.name, value: x.id }))}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      onChange={onSelectChange}
      disabled={disabledEdit}
      noOptionsText={`No ${label} available`}
      {...other}
    />
  );
};
