import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button, Typography, CircularProgress, Dialog, DialogActions, DialogContent, Fade, Checkbox, FormControlLabel } from "@material-ui/core";
import { getErrorSendingReferralOutboundMessage } from "app/main/referrals/reducers/referrals.reducers";
import IconButton from "components/items/icon-button";
import { Alert } from "@material-ui/lab";
import { getReferralWorklistSettings, getEnableNewReferralSendOutboundMessage } from "app/auth/store/reducers/system-configuration";

const SendReferralOutboundDialog = ({
  title,
  loadingMessage = "Sending referral outbound message...",
  content,
  onClose,
  onSend,
  onCancel,
  loading,
  open,
}) => {
  const errorSendingReferralOutboundMessage = useSelector(getErrorSendingReferralOutboundMessage);
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const enableNewReferralSendOutboundMessage = useSelector(getEnableNewReferralSendOutboundMessage);
  const dialogTitle = title || referralWorklistSettings?.referralSendOutboundMessageDialogTitle;
  const [isNewReferral, setIsNewReferral] = useState(false);

  const newReferralOptionComponent = () => (
    enableNewReferralSendOutboundMessage
    && (
    <FormControlLabel
      control={(
        <Checkbox
          checked={isNewReferral}
          label="New Referral (Send REF_I12 instead of REF_I13)"
          onChange={val => {
            setIsNewReferral(val.target.checked);
          }}
          disabled={loading}
        />
      )}
      label="New Referral (Send REF_I12 instead of REF_I13)"
    />
    )
  );

  let dialogContent = () => (content || (
    <>
      <Typography align="center" gutterBottom>{referralWorklistSettings?.referralSendOutboundMessageDialogContent}</Typography>
      {enableNewReferralSendOutboundMessage && newReferralOptionComponent()}
    </>
  ));

  if (loading) {
    dialogContent = () => (
      <>
        <Typography align="center">{loadingMessage}</Typography>
        {newReferralOptionComponent()}
        <div className="flex items-center flex-col w-320 py-16">
          <CircularProgress />
        </div>
      </>
    );
  }

  if (errorSendingReferralOutboundMessage) {
    dialogContent = () => (
      <>
        {errorSendingReferralOutboundMessage && <Alert severity="warning">{errorSendingReferralOutboundMessage}</Alert>}
        {newReferralOptionComponent()}
      </>
    );
  }

  return (
    <Dialog open={open} maxWidth="xs">
      <div className="flex justify-between items-center px-16 py-8">
        <Typography variant="h6">{dialogTitle}</Typography>
        <IconButton edge="end" onClick={onClose} icon="clear" title="Close" />
      </div>
      <DialogContent className="p-24">
        {dialogContent()}
      </DialogContent>
      {!loading && (
      <Fade in={!loading}>
        <DialogActions>
          <Button onClick={() => { onSend(isNewReferral); setIsNewReferral(false); }} color="secondary" className="flex-1" variant="contained">
            Send
          </Button>
          <Button onClick={() => { onCancel(); setIsNewReferral(false); }} className="flex-1">
            Cancel
          </Button>
        </DialogActions>
      </Fade>
      )}
    </Dialog>
  );
};

export default SendReferralOutboundDialog;
