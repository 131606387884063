import React from "react";
import { map } from "lodash";
import { Typography } from "@material-ui/core";
import StateContainer from "components/layouts/default-state-container";
import { getLinkLabelPrefix } from "app/main/patients/helpers/suggested-encounter-label";
import AppointmentListItem from "./appointment-list-item";

const AppointmentList = ({
  stateProps,
  ...other
}) => {
  if (stateProps === undefined) {
    return <AppointmentListComponent {...other} />;
  }
  return <AppointmentListWithState {...stateProps} {...other} />;
};

const AppointmentListComponent = ({
  onCancelAppointment,
  onRemoveAppointment,
  onRecordAppointmentEncounter,
  onEditAppointment,
  onCreateAssessment,
  appointments,
  orgUnitId,
}) => map(appointments, (referralAppointment, index) => {
  const { appointment, appointmentReferrals } = referralAppointment;
  return (
    <AppointmentListItem
      type="appointment"
      item={appointment}
      appointmentReferrals={appointmentReferrals}
      key={appointment.id}
      isFirst={index === 0}
      orgUnitId={orgUnitId}
      assignedBy={appointment.assignedToDisplayName}
      editButtonTitle="Edit Booking"
      confirmRemoveTitle="Remove Scheduled Encounter"
      confirmRemoveText="Remove this scheduled encounter?"
      confirmCancelTitle="Cancel Scheduled Encounter"
      confirmCancelText="Cancel this scheduled encounter?"
        // actions
      onCancel={onCancelAppointment ? () => onCancelAppointment(appointment.id) : null}
      onRemove={onRemoveAppointment ? () => onRemoveAppointment(appointment.id) : null}
      onRecord={onRecordAppointmentEncounter ? () => onRecordAppointmentEncounter(appointment.id) : null}
      onEdit={onEditAppointment ? () => onEditAppointment(appointment.id) : null}
      onCreateAssessment={onCreateAssessment ? () => onCreateAssessment({
        appointmentId: appointment.id,
        assessmentType: appointment.encounterType,
        assessmentHeaderId: appointment.assessmentHeaderId ? appointment.assessmentHeaderId : null,
      }) : null}
      assessmentButtonTitle={appointment.assessmentHeaderId === null ? "Create Assessment" : "Continue Assessment"}
      recordButtonTitle={getLinkLabelPrefix(appointment.status, appointment.encounterType)}
    />
  );
});

export const AppointmentListWithState = ({
  title,
  isEmpty,
  isError,
  isLoading,
  emptyTitle = "No Recorded Appointments",
  ...other
}) => (
  <StateContainer loading={isLoading} error={isError} empty={isEmpty} emptyTitle={emptyTitle}>
    {title && <Typography className="px-16" gutterBottom>{title}</Typography>}
    <AppointmentListComponent {...other} />
  </StateContainer>
);

export default AppointmentList;
