import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, filter, map, includes, flatten, some, find } from "lodash";
import { Typography } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import qs from "qs";

import { getReferralWorklistSettings, getEnableStreamlinedAddReferral } from "app/auth/store/reducers/system-configuration";
import { fetchReferral, clearReferralSourceDocumentFiles, resetCurrentReferral } from "app/main/referrals/actions/referrals.actions";
import { getReferralsForWorklist } from "app/main/referrals/reducers/referrals.reducers";
import { isReferralWorklistLoading, getFilterOptions, getErrorMessage, getTotalNumberOfReferral, getCurrentPageOfReferral, getSearchParameters, getDefaultSortOrder, IsEnableClinicalRoleReferralActiveView, IsEnableReferralWorklistDefaultMyReferrals } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import ChatPanel from "components/items/chat-panel/index";
import DefaultButton from "components/items/default-button";
import InfiniteLoadingList from "components/items/infinite-Loading-list";
import { setMobileContentSidebarStatus } from "app/store/actions/sidebars.actions";
import { openDialog } from "app/store/actions/dialog.actions";
import withPermissions from "permissions/withPermissions";
import ReferralAdvancedFilter from "app/main/referralWorklist/components/referral-advanced-filter";
import { searchReferralResults, exportReferralResults } from "app/main/referralWorklist/actions/referralWorklist.actions";

import { getUser } from "app/auth/store/reducers/user.reducer";
import EmptyState from "components/items/empty-state";
import { isMobileContentSidebarOpen } from "app/store/reducers/sidebars.reducer";
import CreateReferralForm from "app/main/referrals/components/create-referral-form";
import ReferralPageContent from "app/main/referrals/components/referral-page-content";
import PatientReferralViewSelector from "app/main/referrals/components/patient-referral-view-selector";
import useTriageFieldSettings from "app/main/referrals/hooks/useTriageFieldSettings";
import useRejectFieldSettings from "app/main/referrals/hooks/useRejectFieldSettings";
import useReferralFieldSettings from "app/main/referrals/hooks/useReferralFieldSettings";
import { ExcelExportIcon, ReferralWorklistIcon } from "helpers/icon-finder";
import getDefaultWorkListFilterOption from "helpers/get-default-worklist-filter-option";
import ReferralSearchListing from "app/main/referralWorklist/components/referral-search-listing";
import useReferralSearch from "../hooks/useReferralSearch";

const hidePatientView = process.env.REACT_APP_HIDE_REFERRALS_PATIENT_VIEW === "true"; // temp, TODO replace with permission

const ReferralWorklistPage = ({
  forceLoad,
  orgUnitId,
  hasPermissionReferralGlobalAccess,
  hasPermissionReferralsCreate,
  hasPermissionReferralsExport,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const sidebarIsOpen = useSelector(isMobileContentSidebarOpen);
  const referrals = useSelector(getReferralsForWorklist);
  const loading = useSelector(isReferralWorklistLoading);
  const error = useSelector(getErrorMessage);
  const total = useSelector(getTotalNumberOfReferral);
  const pageNumber = useSelector(getCurrentPageOfReferral);
  const params = useSelector(getSearchParameters);
  const currentUser = useSelector(getUser);
  const [loadedReferralId, setLoadedReferralId] = React.useState(null);
  const sortOrderDescending = useSelector(getDefaultSortOrder)?.sortOrderDescending;
  const referralWorklistSettings = useSelector(getReferralWorklistSettings);
  const enableStreamlinedAddReferral = useSelector(getEnableStreamlinedAddReferral);
  const { loadingFieldSettings: loadingTriageSettings, fieldSettings: triageFieldSettings, fieldLabels: triageFieldLabels } = useTriageFieldSettings(orgUnitId);
  const { loadingFieldSettings: loadingRejectSettings, fieldSettings: rejectFieldSettings, fieldLabels: rejectFieldLabels } = useRejectFieldSettings(orgUnitId);
  const { loadingFieldSettings: loadingReferralSettings, fieldSettings: referralFieldSettings, fieldLabels: referralFieldLabels } = useReferralFieldSettings();
  const filterOptions = useSelector(getFilterOptions);
  const lastSelectReferralSearchOption = localStorage.getItem("lastSelectReferralSearchOption");
  const currentUserRole = find(currentUser.userRoles, x => x.orgUnitId === orgUnitId);
  const isEnableClinicalRoleReferralActiveView = useSelector(IsEnableClinicalRoleReferralActiveView);
  const isEnableReferralWorklistDefaultMyReferrals = useSelector(IsEnableReferralWorklistDefaultMyReferrals);
  const defaultWorkListSearchFilterRoleTypeSettings = referralWorklistSettings?.defaultWorkListSearchFilterRoleTypeSettings;
  const defaultType = getDefaultWorkListFilterOption(defaultWorkListSearchFilterRoleTypeSettings, currentUserRole, isEnableClinicalRoleReferralActiveView, isEnableReferralWorklistDefaultMyReferrals);
  const selectedAsDefault = localStorage.getItem("shouldLoadLastReferralSelectSearchParams") !== false && !isEmpty(lastSelectReferralSearchOption) && some(filterOptions, x => x.value === lastSelectReferralSearchOption) ? lastSelectReferralSearchOption : defaultType;

  const { searchParams, onSearch, onExport, ...searchProps } = useReferralSearch({ params, orgUnitId, searchAPI: searchReferralResults, exportAPI: exportReferralResults });

  const onAddReferral = patientPresetFilter => {
    dispatch(openDialog({
      maxWidth: enableStreamlinedAddReferral ? "xl" : "md",
      children: (
        <CreateReferralForm
          orgUnitId={orgUnitId}
          patientPresetFilter={patientPresetFilter}
          onSucceed={({ referral: { id } }) => history.push(`/referralWorklist?referralId=${id}`)}
        />
      ),
    }, enableStreamlinedAddReferral));
  };

  useEffect(() => {
    if (forceLoad) {
      const currentOrgUnitAssignedToSpecialtyProfiles = filter(currentUser.userSpecialtyProfiles, x => x.specialtyProfile.orgUnitId === orgUnitId);
      const currentOrgUnitAssignedToSpecialtyIds = map(currentOrgUnitAssignedToSpecialtyProfiles, x => x.specialtyProfile.id);
      const currentOrgUnitAssignedToSubSpecialtyIds = map(currentOrgUnitAssignedToSpecialtyProfiles, x => x.subSpecialty?.id);
      const paramsAssignedSpecialtyId = filter(params.assignedToSpecialtyIds, x => includes(currentOrgUnitAssignedToSpecialtyIds, x));

      let assignedToSpecialtyIds = isEmpty(paramsAssignedSpecialtyId) ? currentOrgUnitAssignedToSpecialtyIds : paramsAssignedSpecialtyId;
      if (selectedAsDefault === "myReferrals") {
        assignedToSpecialtyIds = null;
      }

      const paramsAssignedToSubSpecialtyIds = filter(params.assignedToSubSpecialtyIds, x => includes(currentOrgUnitAssignedToSubSpecialtyIds, x));
      const assignedToSubSpecialtyIds = isEmpty(paramsAssignedToSubSpecialtyIds) ? currentOrgUnitAssignedToSubSpecialtyIds : paramsAssignedToSubSpecialtyIds;
      const currentUserRoleIds = map(filter(currentUser.userRoles, x => x.orgUnitId === orgUnitId), r => r.roleId);
      const specialtyProfileAvailableRoles = flatten(map(currentOrgUnitAssignedToSpecialtyProfiles, x => x.specialtyProfileAvailableRoleIds));

      let assignedToRoleIds = map(filter(specialtyProfileAvailableRoles, x => includes(currentUserRoleIds, x)), r => r);
      if (selectedAsDefault === "myReferrals") {
        assignedToRoleIds = null;
      }

      dispatch(resetCurrentReferral());

      // Applied the default sortBy based on the selected default filter
      let updatedSearchParamsParams = { ...params, assignedToSpecialtyIds, assignedToSubSpecialtyIds, assignedToRoleIds };
      if (selectedAsDefault === "clinicalRoleActiveView") {
        updatedSearchParamsParams = { ...updatedSearchParamsParams, sortBy: "TriageCategory" };
      } else if (selectedAsDefault !== "advancedFilter") {
        updatedSearchParamsParams = { ...updatedSearchParamsParams, sortBy: "ReferralDate" };
      }

      onSearch(updatedSearchParamsParams, 1, null, forceLoad);
    }
    const presetURL = history.location.search ?? null;
    if (presetURL) {
      const queryString = qs.parse(presetURL, { ignoreQueryPrefix: true });
      const { referralId, patientIdentifier } = queryString;

      if (!isEmpty(patientIdentifier)) {
        onAddReferral({ primaryIdentifier: patientIdentifier });
      }

      // make sure the referralId is not null
      if (!isEmpty(referralId) && (isEmpty(loadedReferralId) || loadedReferralId !== referralId)) {
        dispatch(fetchReferral(orgUnitId, referralId)).then(() => setLoadedReferralId(referralId));
      } else {
        history.push({ search: "" });
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forceLoad, orgUnitId]);

  const onClickItem = selectedItemId => {
    if (sidebarIsOpen) {
      dispatch(setMobileContentSidebarStatus(false));
    }
    dispatch(clearReferralSourceDocumentFiles());
    dispatch(fetchReferral(orgUnitId, selectedItemId)).then(
      () => history.push(`/referralWorklist?referralId=${selectedItemId}`),
    );
  };

  const renderItem = (item, selectedItem, resetCurrentTab) => {
    const isActive = !isEmpty(selectedItem) && selectedItem.id === item.id;
    const isDisabledViewDetail = !item.canViewFullReferral;

    return (
      <ReferralSearchListing
        onClick={isActive || isDisabledViewDetail ? null : () => { onClickItem(item.id); resetCurrentTab(); }}
        active={isActive}
        referral={item}
        isDisabled={isDisabledViewDetail}
        currentOrgUnitId={orgUnitId}
      />
    );
  };

  const renderSidebarContent = (selectedItem, resetCurrentTab) => {
    if (!hasPermissionReferralGlobalAccess && currentUser.userSpecialtyProfiles === null) {
      return <div className="p-16"><EmptyState title="No Specialty Configured" subtitle="Please contact a system administrator" /></div>;
    }

    return (
      <InfiniteLoadingList
        initialLoad
        data={referrals}
        renderItem={item => renderItem(item, selectedItem, resetCurrentTab)}
        loading={loading || loadingTriageSettings || loadingRejectSettings || loadingReferralSettings}
        error={error}
        total={total}
        pageStart={pageNumber}
        loadFunction={page => {
          const preSetParams = localStorage.getItem("lastSearchParams");
          if (page === undefined && !isEmpty(preSetParams) && localStorage.getItem("shouldLoadLastReferralSelectSearchParams") !== false) {
            let lastSearchParams = JSON.parse(preSetParams);
            if (referralWorklistSettings.setLastSelectReferralSortOrder === false) {
              lastSearchParams = { ...lastSearchParams, sortOrderDescending };
            }
            return onSearch(lastSearchParams, 1, null, forceLoad);
          }
          const number = page === undefined ? 1 : pageNumber + 1;
          return onSearch(params, number, null, forceLoad);
        }}
      />
    );
  };

  const extraActions = [
    hasPermissionReferralsExport && { label: "Export", icon: <ExcelExportIcon />, onClick: onExport },
  ];

  return (
    <ReferralPageContent
      orgUnitId={orgUnitId}
      renderContent={({ header, content, loading: loadingCurrent, currentReferral, resetTab }) => (
        <ChatPanel
          loading={loadingCurrent}
          empty={!currentReferral}
          renderSidebarHeader={() => (
            <>
              {!hidePatientView && (
                <div className="mb-8">
                  <PatientReferralViewSelector />
                </div>
              )}
              <ReferralAdvancedFilter
                onSearch={onSearch}
                searchParams={searchParams}
                currentUser={currentUser}
                renderExtraActions={total > 0 && extraActions}
                triageFieldSettings={triageFieldSettings}
                triageFieldLabels={triageFieldLabels}
                rejectFieldSettings={rejectFieldSettings}
                rejectFieldLabels={rejectFieldLabels}
                referralFieldSettings={referralFieldSettings}
                referralFieldLabels={referralFieldLabels}
                renderFilterInfo={(
                  <div className="flex justify-between items-center">
                    <Typography variant="caption">Total Results: {total}</Typography>
                    {hasPermissionReferralsCreate && <DefaultButton label="Add referral" onClick={onAddReferral} />}
                  </div>
                )}
                {...searchProps}
              />
            </>
          )}
          renderSidebarContent={() => renderSidebarContent(currentReferral, resetTab)}
          renderContent={({ width }) => content({ width })}
          renderHeader={() => header()}
          emptyIcon={<ReferralWorklistIcon />}
          emptyTitle="Select a referral to view details"
        />
      )}
    />
  );
};

export default withPermissions(
  "ReferralsExport",
  "ReferralsCreate",
  "ReferralGlobalAccess",
)(ReferralWorklistPage);
