import useFormFieldConfig from "hooks/use-form-field-config";

// load current orgUnit form field settings - 'RejectForm';
const useRejectFieldSettings = (orgUnitId, forceLoad) => {
  const { loadingFieldSettings, fieldSettings, loadedFieldSettings } = useFormFieldConfig(orgUnitId, "RejectForm", forceLoad);

  const fieldLabels = {
    referrerAdvisedPriority: fieldSettings?.referrerAdvisedPriority?.label || "Referrer Advised Priority",
    rejectReasonCodeId: fieldSettings?.rejectReasonCodeId?.label || "Reason for Rejection",
    reason: fieldSettings?.reason?.label || "Message",
  };

  return ({
    loadingFieldSettings,
    fieldSettings,
    fieldLabels,
    loadedFieldSettings,
  });
};

export default useRejectFieldSettings;
