import useFormFieldConfig from "hooks/use-form-field-config";

// load current orgUnit form field settings - 'ReferralAssignment';
const useReferralAssignmentFieldSettings = (orgUnitId, primaryPatientIdentifierLabel, forceLoad) => {
  const { loadingFieldSettings, fieldSettings, loadedFieldSettings } = useFormFieldConfig(orgUnitId, "ReferralAssignment", forceLoad);

  const fieldLabels = {
    orgUnit: fieldSettings?.orgUnit?.label || "Site",
    patientIdentifier: fieldSettings?.patientIdentifier?.label || `${primaryPatientIdentifierLabel} Identifier`,
    specialty: fieldSettings?.specialty?.label || "Assign to",
    subSpecialty: fieldSettings?.subSpecialty?.label || "Case Team",
    specialtyRole: fieldSettings?.specialtyRole?.label || "Role",
    specialtyUser: fieldSettings?.specialtyUser?.label || "Specialist",
    reason: fieldSettings?.reason?.label || "Reason",
  };

  return ({
    loadingFieldSettings,
    fieldSettings,
    fieldLabels,
    loadedFieldSettings,
  });
};

export default useReferralAssignmentFieldSettings;
