import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import "utils/yup-validation";
import { Field } from "formik";
import { isEmpty } from "lodash";
import { getDefaultReferralFormProfile } from "app/auth/store/reducers/user.reducer";
import { getInitialTemplateSchema } from "app/main/referrals/utils/referral-schema";
import AddReferralFormComponent from "./form";
import ReferralTemplateSection from "./referral-template-section";
import { getPresentingComplaintMaxLength } from "app/auth/store/reducers/system-configuration";

const AddReferralForm = ({ forceLoad,
  currentReferral,
  ...props }) => {
  const [defaultReferralDate, setDefaultReferralDate] = useState(null);

  const [schema, setSchema] = useState(getInitialTemplateSchema(props?.isEdit, props?.defaultReferralDate, props?.allowedFutureDateMinutes));
  const [fieldSettings, setFieldSettings] = useState(null);
  const [fieldLabels, setFieldLabels] = useState(null);
  const [loadingFieldSettings, setLoadingFieldSettings] = useState(false);

  const defaultFormFieldConfiguration = useSelector(state => getDefaultReferralFormProfile(state, currentReferral?.orgUnitId ?? props?.orgUnitId));
  const presentingComplaintMaxLength = useSelector(getPresentingComplaintMaxLength);
  const [autoReferralFullName, setAutoReferralFullName] = useState(defaultFormFieldConfiguration?.setCurrentUserAsReferrer);
  const [autoReferringOrgUnitId, setAutoReferringOrgUnitId] = useState(defaultFormFieldConfiguration?.setCurrentSiteAsReferring);
  const [enableDraft, setEnableDraft] = useState(defaultFormFieldConfiguration?.enableDraft);

  // Initialize the disableSourceDocumentUpload state
  const [disableSourceDocumentUpload, setDisableSourceDocumentUpload] = useState(() => {
    // When editing a referral, pick the disableSourceDocumentUpload value from the form profile that this referral has chosen
    if (props.isEdit) {
      return currentReferral?.disableSourceDocumentUpload ?? false;
    }

    // When creating a new referral, pick the disableSourceDocumentUpload value from the default form profile
    return defaultFormFieldConfiguration?.disableSourceDocumentUpload ?? false;
  });

  const currentAssignedTo = currentReferral?.assignedTo ? { value: currentReferral?.assignedTo?.assignedToSpecialtyId, label: currentReferral?.assignedTo?.assignedToSpecialtyName } : null;
  const referredToSpecialty = props.sourceDocument?.referredToSpecialty ?? currentAssignedTo;
  const [changesReferral, setChangesReferral] = useState(null); // Tracks the updated data in the form to ensure changes reset accordingly after the schema is updated
  const [currentAssignedToSpecialty, setCurrentAssignSpecialty] = useState(isEmpty(referredToSpecialty) ? null : referredToSpecialty);

  const referral = currentReferral;
  useEffect(() => {
    // set default referral date when component mounted,
    // prevent re-render
    setDefaultReferralDate(moment());
  }, []);

  return (
    <AddReferralFormComponent
      defaultReferralDate={defaultReferralDate}
      currentReferral={currentReferral}
      templateReferral={referral !== changesReferral && !isEmpty(changesReferral) ? {
        ...changesReferral,
        referralDateUtc: changesReferral?.referralDateUtc ?? defaultReferralDate,
      } : referral}
      schema={schema}
      fieldSettings={fieldSettings}
      fieldLabels={fieldLabels}
      loadingFieldSettings={loadingFieldSettings}
      autoReferralFullName={autoReferralFullName}
      autoReferringOrgUnitId={autoReferringOrgUnitId}
      enableDraft={enableDraft}
      disableSourceDocumentUpload={disableSourceDocumentUpload}
      currentAssignedToSpecialty={currentAssignedToSpecialty}
      setCurrentAssignSpecialty={updateValue => setCurrentAssignSpecialty(updateValue)}
      presentingComplaintMaxLength={presentingComplaintMaxLength}
      renderTemplateComponents={({ isReading, values, loadingSettings, settings }) => (
        <Field
          name="referral.referralTemplateSection"
          component={ReferralTemplateSection}
          orgUnitId={props.orgUnitId}
          isEdit={props.isEdit}
          currentReferral={referral}
          isReading={isReading}
          loadingFieldSettings={loadingSettings}
          fieldSettings={settings}
          defaultReferralDate={defaultReferralDate}
          values={values}
          currentAssignedToSpecialty={currentAssignedToSpecialty}
          setCurrentAssignSpecialty={updateValue => setCurrentAssignSpecialty(updateValue)}
          setSchema={updateValue => setSchema(updateValue)}
          setLoadingFieldSettings={updateValue => setLoadingFieldSettings(updateValue)}
          setFieldLabels={updateValue => setFieldLabels(updateValue)}
          setFieldSettings={updateValue => setFieldSettings(updateValue)}
          setChangesReferral={updateValue => setChangesReferral(updateValue)}
          setAutoReferralFullName={updateValue => setAutoReferralFullName(updateValue)}
          setAutoReferringOrgUnitId={updateValue => setAutoReferringOrgUnitId(updateValue)}
          setEnableDraft={updateValue => setEnableDraft(updateValue)}
          setDisableSourceDocumentUpload={updateValue => setDisableSourceDocumentUpload(updateValue)}
        />
      )}
      {...props}
    />
  );
};

export default AddReferralForm;
