import { ApiActions } from "middleware/call-api";
import formatSearchParams from "helpers/format-search-params";
import { PROVIDERS_PAGE_SIZE } from "app/main/providers/reducers/providers.reducers";

// TODO improve when provider list requires paging
export const fetchProvidersResults = (params, pageNumber = 1, pageSize = PROVIDERS_PAGE_SIZE) => dispatch => {
  const searchParams = formatSearchParams(params);

  const url = `/providers?pageNumber=${pageNumber}&pageSize=${pageSize}${searchParams}`;

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "providers",
      pageNumber,
    },
  });
};
