import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { compact, map, find, head, isEmpty } from "lodash";
import { useSelector } from "react-redux";

import SideTabBar from "components/layouts/side-tab-bar";
import { OrgUnitsIcon, UserIcon, PatientMergeIcon, PatientDetailsIcon, PatientAccessIcon, CodeSetIcon, LogTableIcon } from "helpers/icon-finder";
import withPermissions from "permissions/withPermissions";
import LoadingState from "components/items/loading-state";
import DefaultPageLayout from "components/layouts/default-page-layout";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import { getPatientLabel } from "utils/get-environment-variables";

import UsersPageHeader from "app/main/users/components/users-page-header";
import UsersTable from "app/main/users/components/users-table";
import OrgUnitPageHeader from "app/main/orgUnits/components/orgUnit-page-header";
import OrgUnitList from "app/main/orgUnits/components/orgUnit-list";
import PatientMergeLogHeader from "app/main/patientMergeLog/components/patient-merge-log-header";
import PatientMergeLogTable from "app/main/patientMergeLog/components/patient-merge-log-table";
import PatientsPageHeader from "app/main/patients/components/patients-page-header";
import PatientsTable from "app/main/patients/components/patients-table";
import PatientAccessPageHeader from "app/main/patientAccessLog/components/patient-access-page-header";
import PatientAccessTable from "app/main/patientAccessLog/components/patient-access-table";
import CodeSetPageHeader from "app/main/codeSet/components/code-set-page-header";
import CodeSetPageContent from "app/main/codeSet/components/code-set-page-content";
import IntegrationLogsPageHeader from "app/main/integration/integration-logs-page-header";
import IntegrationLogsTable from "app/main/integration/integration-logs-table";

const patientLabel = getPatientLabel();

const useStyles = makeStyles(theme => ({
  sidebarRoot: {
    marginTop: theme.spacing(7),
  },
  sidebarContent: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const AdminIndexPage = ({
  history,
  hasPermissionUsersMaintain,
  hasPermissionOrgUnitMaintain,
  hasPermissionPatientMergeMaintain,
  hasPermissionPatientAccessMaintain,
  hasPermissionPatientsMaintain,
  hasPermissionCodeSetMaintain,
  hasPermissionAdminView,
  hasPermissionIntegrationLogsView,
}) => {
  const classes = useStyles();
  const signedInOrgUnit = useSelector(getSignedInOrgUnit);
  const routes = compact([
    hasPermissionUsersMaintain
    && {
      label: "Users",
      value: "users",
      icon: <UserIcon />,
      header: props => <UsersPageHeader orgUnit={signedInOrgUnit} {...props} />,
      content: props => <UsersTable orgUnit={signedInOrgUnit} {...props} />,
    },
    hasPermissionOrgUnitMaintain
      && {
        label: "Sites",
        value: "orgUnits",
        icon: <OrgUnitsIcon />,
        header: props => <OrgUnitPageHeader orgUnitType="Site" parentOrgUnitType={signedInOrgUnit.orgUnitType} parentOrgUnitId={signedInOrgUnit.id} {...props} />,
        content: props => <OrgUnitList orgUnitType="Site" parentOrgUnitId={signedInOrgUnit.id} parentOrgUnitType={signedInOrgUnit.orgUnitType} {...props} />,
      },
    hasPermissionPatientMergeMaintain
      && {
        label: `${patientLabel.single} Merge`,
        value: "patientMerge",
        icon: <PatientMergeIcon />,
        header: props => <PatientMergeLogHeader title={`${patientLabel.single} Merge`} {...props} />,
        content: props => <PatientMergeLogTable {...props} />,
      },
    hasPermissionPatientAccessMaintain
      && {
        label: `${patientLabel.single} Access`,
        value: "patientAccess",
        icon: <PatientAccessIcon />,
        header: props => <PatientAccessPageHeader title={`${patientLabel.single} Access`} {...props} />,
        content: props => <PatientAccessTable {...props} />,
      },
    hasPermissionPatientsMaintain
      && {
        label: patientLabel.plural,
        value: "patients",
        icon: <PatientDetailsIcon />,
        header: props => <PatientsPageHeader title={patientLabel.plural} {...props} />,
        content: props => <PatientsTable {...props} />,
      },
    hasPermissionCodeSetMaintain
      && {
        label: "Code Sets",
        value: "codeSets",
        icon: <CodeSetIcon />,
        header: props => <CodeSetPageHeader title={patientLabel.plural} {...props} />,
        content: props => <CodeSetPageContent {...props} />,
      },
    hasPermissionIntegrationLogsView
      && {
        label: "Integration Logs",
        value: "logs",
        icon: <LogTableIcon />,
        header: props => <IntegrationLogsPageHeader title={patientLabel.plural} {...props} />,
        content: props => <IntegrationLogsTable {...props} />,
      },
  ]);

  const [currentTab, setCurrentTab] = useState(null);
  const [loading, setLoading] = useState(!isEmpty(routes));

  const query = new URLSearchParams(history.location.search);
  const querySelectedTab = query.get("tab");

  useEffect(() => {
    const selected = find(routes, x => x.value === querySelectedTab);
    if (!isEmpty(routes)) {
      let tab = "";
      if (selected) {
        tab = querySelectedTab;
      } else {
        tab = head(routes).value;
      }
      history.push({
        ...history.location,
        pathname: history.location.pathname,
        search: `?tab=${tab}`,
      });
      setCurrentTab(tab);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [querySelectedTab]);

  if (loading) {
    return <LoadingState />;
  }

  const handleTabChange = val => {
    setCurrentTab(val);
    history.push(`${history.location.pathname}?tab=${val}`);
  };

  const sidebar = props => (
    <SideTabBar
      header={<div />}
      currentTab={currentTab}
      handleTabChange={handleTabChange}
      routes={routes}
      classes={{ root: classes.sidebarRoot, content: classes.sidebarContent }}
      {...props}
    />
  );

  const header = () => map(routes, x => {
    if (x.value !== currentTab) return undefined;
    return <React.Fragment key={x.value}>{x.header({ history })}</React.Fragment>;
  });

  const content = () => map(routes, x => {
    if (x.value !== currentTab) return undefined;
    return <React.Fragment key={x.value}>{x.content({ history })}</React.Fragment>;
  });

  return (
    <DefaultPageLayout
      accessDenied={isEmpty(routes) || !hasPermissionAdminView}
      header={header()}
      content={content()}
      sidebar={props => sidebar(props)}
    />
  );
};

export default withPermissions(
  "OrgUnitMaintain",
  "UsersMaintain",
  "PatientMergeMaintain",
  "PatientAccessMaintain",
  "PatientsMaintain",
  "CodeSetMaintain",
  "AdminView",
  "IntegrationLogsView",
)(AdminIndexPage);
