import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { map, startCase } from "lodash";
import { AutoComplete } from "components/inputs";
import { UserRoleIcon } from "helpers/icon-finder";
import { isSystemConfigurationLoading, getAllRoles } from "app/auth/store/reducers/system-configuration";
import { fetchAllUserRoles } from "../actions/users.actions";

const UserSearchRoleSelector = ({
  label,
  showIcon = true,
  initialOptions = [],
  ...other
}) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState(initialOptions || []);

  const loading = useSelector(isSystemConfigurationLoading);
  const roles = useSelector(getAllRoles);

  useEffect(() => {
    // Dispatch action to fetch roles when the component mounts if roles aren't already loaded
    dispatch(fetchAllUserRoles());
  }, [dispatch]);

  const formatRoleDescription = role => {
    let { roleDescription } = role;
    if (!role.isAssignable) {
      roleDescription = `${roleDescription} (Deprecated)`;
    }

    return roleDescription;
  };

  const formatRoleName = role => {
    let roleName = role.roleDescription ?? startCase(role.roleName);
    if (!role.isAssignable) {
      roleName = `${roleName} (Deprecated)`;
    }

    return roleName;
  };

  const formatRoleOptions = roleList => roleList.map(role => ({
    roleDescription: formatRoleDescription(role),
    roleName: formatRoleName(role),
    roleId: role.roleId,
  }));

  const handleOpen = () => {
    // Set the roles to options when the dropdown opens
    const formattedOptions = formatRoleOptions(roles);
    setOptions(formattedOptions);
  };

  return (
    <AutoComplete
      icon={showIcon && <UserRoleIcon />}
      label={label}
      loading={loading}
      onOpen={handleOpen}
      options={map(options, r => ({ label: r.roleDescription ?? startCase(r.roleName), value: r.roleId }))}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      {...other}
    />
  );
};

export default UserSearchRoleSelector;
