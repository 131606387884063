import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { isEmpty } from "lodash";

import formatDate from "helpers/format-date";
import ConfirmationDialog from "components/items/confirmation-dialog";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import DefaultPageLayout from "components/layouts/default-page-layout";
import ScreenHeader from "components/items/screen-header";
import withPermissions from "permissions/withPermissions";
import DefaultButton from "components/items/default-button";
import useSetUrl from "app/main/admin/hooks/useSetUrl";
import OrgUnitPrimaryHeader from "app/main/orgUnits/components/orgUnit-primary-header";
import ReferralFormProfileForm from "../components/referralFormProfile-form";
import { getCurrentReferralFormProfile, isCurrentReferralFormProfileLoading } from "../reducers/referralFormProfiles.reducers";
import { getReferralFormProfile, resetCurrentReferralFormProfile, deprecateReferralFormProfile, restoreReferralFormProfile } from "../actions/referralFormProfiles.actions";

const ReferralFormProfileEditPage = ({
  match: { params: { id, referralFormProfileId } },
  hasPermissionReferralFormProfileUpdate,
  route,
}) => {
  const dispatch = useDispatch();
  const current = useSelector(getCurrentReferralFormProfile);
  const isActive = isEmpty(current?.deprecatedDateUtc);
  const loading = useSelector(isCurrentReferralFormProfileLoading);
  const [disabled, setDisabled] = useState(true);

  useSetUrl(route);

  const onClose = () => dispatch(closeDialog());

  const onUpdateReferralFormProfileStatus = () => dispatch(openDialog({
    disableBackdropClick: false,
    maxWidth: "xs",
    children: (
      <ConfirmationDialog
        type="warning"
        title={`${isActive ? "Deprecate" : "Restore"}`}
        content={`Are you sure you want to ${isActive ? "deprecate" : "restore"} ${current.name}?`}
        onConfirm={() => {
          const apiCall = isActive ? deprecateReferralFormProfile : restoreReferralFormProfile;

          dispatch(apiCall(current)).then(() => {
            onClose();
          });
        }}
        onCancel={onClose}
      />
    ),
  }));

  useEffect(() => {
    if (!loading && referralFormProfileId && referralFormProfileId !== current?.id) {
      dispatch(getReferralFormProfile(id, referralFormProfileId));
    }

    return () => dispatch(resetCurrentReferralFormProfile());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referralFormProfileId]);

  const renderActions = () => {
    if (!hasPermissionReferralFormProfileUpdate) return null;
    return (
      <>
        {false && (
          <DefaultButton
            variant="outlined"
            color="inherit"
            label={isActive ? "Deprecate" : "Restore"}
            icon={isActive ? "cancel" : "undo"}
            onClick={onUpdateReferralFormProfileStatus}
            disabled={!disabled}
          />
        )}
        {hasPermissionReferralFormProfileUpdate && <DefaultButton label="Edit" variant="outlined" color="inherit" icon="edit" onClick={() => setDisabled(false)} disabled={!disabled || !isActive} />}
      </>
    );
  };

  const onSucceed = () => {
    setDisabled(true);
  };

  return (
    <>
      <OrgUnitPrimaryHeader orgUnitId={id} />
      <DefaultPageLayout
        loading={loading}
        header={(
          <ScreenHeader
            title={`Referral Form Profile - ${current?.name}`}
            subtitle={!isActive ? `Deprecated Date: ${formatDate(current?.deprecatedDateUtc)}` : ""}
            renderActions={renderActions()}
          />
        )}
        content={(
          <ReferralFormProfileForm
            referralFormProfile={current}
            disabled={disabled}
            onCancel={() => setDisabled(true)}
            onSucceed={onSucceed}
            orgUnitId={id}
            referralFormProfileId={referralFormProfileId}
          />
        )}
      />
    </>
  );
};

export default withPermissions("ReferralFormProfileUpdate")(ReferralFormProfileEditPage);
