import { ApiActions } from "middleware/call-api";
import { isEmpty, first } from "lodash";
import { formatUtcDate } from "helpers/format-date-time";
import { PreferredTimeFormat } from "utils/date";
import { getEnableRecordReferralActionAccess } from "app/auth/store/reducers/system-configuration";
import { setCurrentPatientOCR, resetCurrentPatientOCR } from "app/main/patients/actions/patientOCR.selector.actions";
import { getCurrentPatientOCR } from "app/main/patients/reducers/patientOCR.selector.reducers";
import { ActionTypes as PatientReferralWorkListActionTypes } from "../reducers/patientReferrals.reducers";
import { ActionTypes, isSendingReferralOutboundMessage, isWarningReferralAccessLogLoading } from "../reducers/referrals.reducers";

export const resetCurrentReferral = () => ({
  type: ActionTypes.RESET_CURRENT_REFERRAL,
});

export const UpdateCurrentReferralPatientContact = (patientId, contact) => ({
  type: ActionTypes.UPDATE_CURRENT_REFERRAL_PATIENT_CONTACT,
  payload: { patientId, contact },
});

export const UpdatePatientReferralStatus = referral => ({
  type: PatientReferralWorkListActionTypes.UPDATE_PATIENT_REFERRAL_STATUS,
  payload: referral,
});

export const UpdatePatientReferralAfterEdit = (patientId, referralId, referral) => ({
  type: PatientReferralWorkListActionTypes.UPDATE_PATIENT_REFERRAL_AFTER_EDIT,
  payload: { patientId, referralId, referral },
});

export const UpdatePatientReferralPresentingComplaintsAfterEdit = (patientId, referralId, presentingComplaintSummary) => ({
  type: PatientReferralWorkListActionTypes.UPDATE_PATIENT_REFERRAL_PRESENTING_COMPLAINTS_AFTER_EDIT,
  payload: { patientId, referralId, presentingComplaintSummary },
});

export const getReferralAttachments = (orgUnitId, referralId) => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/GetAttachments`,
    method: "GET",
    entityType: "REFERRAL_ATTACHMENTS",
  },
});

export const fetchReferral = (orgUnitId, referralId) => async dispatch => dispatch({ [ApiActions.FETCH_FROM_API]: {
  endpoint: `/referrals/${orgUnitId}/${referralId}`,
  method: "GET",
  types: [
    ActionTypes.LOADING_REFERRAL,
    ActionTypes.LOADED_REFERRAL,
    ActionTypes.ERROR_LOADING_REFERRAL,
  ],
} });

export const getReferralReport = (orgUnitId, referralId) => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/${orgUnitId}/GetReport/${referralId}`,
    method: "GET",
    types: [
      ActionTypes.LOADING_REFERRAL_REPORT,
      ActionTypes.LOADED_REFERRAL_REPORT,
      ActionTypes.ERROR_LOADING_REFERRAL_REPORT,
    ],
  },
});

export const printReferralReport = (orgUnitId, referralId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/${orgUnitId}/Print/${referralId}`,
    method: "GET",
    types: [
      "ActionTypes.LOADING_PRINT_REFERRAL",
      ActionTypes.LOADED_PRINT_REFERRAL,
      "ActionTypes.ERROR_LOADING_PRINT_REFERRAL",
    ],
  },
});

export const rejectReferralAction = (orgUnitId, rejectReferral) => async dispatch => {
  const rejectedReferral = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/reject`,
      body: { rejectReferral },
      entityType: "rejectReferral",
      types: [
        "REJECTING_REFERRAL",
        ActionTypes.REJECTED_REFERRAL,
        "FAILED_TO_REJECT_REFERRAL",
      ],
    },
  });

  if (rejectedReferral.error !== true) {
    dispatch(UpdatePatientReferralStatus(rejectedReferral.payload));
  }

  return rejectedReferral;
};

export const addReferralCorrespondence = (orgUnitId, referralCorrespondence) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/addCorrespondence`,
    body: { referralCorrespondence },
    entityType: "REFERRAL_CORRESPONDENCE",
  },
});

export const completeReferralAction = (orgUnitId, completeReferral) => async dispatch => {
  const completedReferral = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/complete`,
      body: { completeReferral },
      entityType: "completeReferral",
      types: [
        "COMPLETING_REFERRAL",
        ActionTypes.COMPLETED_REFERRAL,
        "FAILED_TO_COMPLETE_REFERRAL",
      ],
    },
  });
  if (completedReferral.error !== true) {
    dispatch(UpdatePatientReferralStatus(completedReferral.payload));
  }
  return completedReferral;
};

export const archiveReferralAction = (orgUnitId, archiveReferral) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/archive`,
    body: { archiveReferral },
    entityType: "archiveReferral",
    types: [
      "ARCHIVING_REFERRAL",
      ActionTypes.ARCHIVED_REFERRAL,
      "FAILED_TO_ARCHIVE_REFERRAL",
    ],
  },
});

export const cancelReferralAction = (orgUnitId, cancelReferral) => async dispatch => {
  const cancelledReferral = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/cancel`,
      body: { cancelReferral },
      entityType: "cancelReferral",
      types: [
        "CANCELLINING_REFERRAL",
        ActionTypes.CANCELLED_REFERRAL,
        "FAILED_TO_CANCEL_REFERRAL",
      ],
    },
  });
  if (cancelledReferral.error !== true) {
    dispatch(UpdatePatientReferralStatus(cancelledReferral.payload));
  }
  return cancelledReferral;
};

export const undoAcceptBookedAction = (orgUnitId, undoReferral) => async dispatch => {
  const updatedReferral = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/UndoAcceptBooked`,
      body: { undoReferral },
      entityType: "undoReferral",
      types: [
        "UNDOING_REFERRAL_ACTION",
        ActionTypes.UNDONE_REFERRAL_ACTION,
        "FAILED_TO_UNDO_REFERRAL_ACTION",
      ],
    },
  });
  if (updatedReferral.error !== true) {
    dispatch(UpdatePatientReferralStatus(updatedReferral.payload));
  }
  return updatedReferral;
};

export const undoReferralAction = (orgUnitId, undoReferral) => async dispatch => {
  const updatedReferral = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/undo`,
      body: { undoReferral },
      entityType: "undoReferral",
      types: [
        "UNDOING_REFERRAL_ACTION",
        ActionTypes.UNDONE_REFERRAL_ACTION,
        "FAILED_TO_UNDO_REFERRAL_ACTION",
      ],
    },
  });
  if (updatedReferral.error !== true) {
    dispatch(UpdatePatientReferralStatus(updatedReferral.payload));
  }
  return updatedReferral;
};

export const draftReferralAction = (orgUnitId, referralId) => async dispatch => {
  const draftedReferral = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/draftReferral/${referralId}`,
      entityType: "draftReferral",
      types: [
        "UPDATING_DRAFT_REFERRAL",
        ActionTypes.UPDATED_DRAFT_REFERRAL,
        "FAILED_TO_UPDATE_DRAFT_REFERRAL",
      ],
    },
  });
  if (draftedReferral.error !== true) {
    dispatch(UpdatePatientReferralStatus(draftedReferral.payload));
  }
  return draftedReferral;
};

export const processReferralAction = (orgUnitId, referralId) => async dispatch => {
  const processReferral = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/processReferral/${referralId}`,
      entityType: "processReferral",
      types: [
        "UPDATING_PROCESS_REFERRAL",
        ActionTypes.UPDATED_PROCESS_REFERRAL,
        "FAILED_TO_UPDATE_PROCESS_REFERRAL",
      ],
    },
  });
  if (processReferral.error !== true) {
    dispatch(UpdatePatientReferralStatus(processReferral.payload));
  }
  return processReferral;
};

export const requestReferralAction = (orgUnitId, referralId, isUndo) => async dispatch => {
  const requestedReferral = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/requestReferral/${referralId}?isUndo=${isUndo}`,
      entityType: "requestReferral",
      types: [
        "REQUESTING_REFERRAL_ACTION",
        ActionTypes.REQUEST_REFERRAL_ACTION,
        "FAILED_TO_REQUEST_REFERRAL_ACTION",
      ],
    },
  });

  if (requestedReferral.error !== true) {
    dispatch(UpdatePatientReferralStatus(requestedReferral.payload));
  }
  return requestedReferral;
};

export const addReferralNote = (orgUnitId, referralId, referralNote) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/addNote`,
    body: { referralNote },
    entityType: "REFERRAL_NOTE",
    referralId,
  },
});

export const editReferralNote = (orgUnitId, referralId, referralNote) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/editNote`,
    body: { referralNote },
    entityType: "REFERRAL_NOTE",
    referralId,
  },
});

export const removeReferralNote = (orgUnitId, referralId, referralNoteId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/removeNote/${referralNoteId}`,
    entityType: "REFERRAL_NOTE",
  },
});

export const acceptReferralAction = (orgUnitId, acceptReferral) => async dispatch => {
  const acceptedReferral = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/accept`,
      body: { acceptReferral },
      entityType: "acceptReferral",
      types: [
        "ACCEPTING_REFERRAL",
        ActionTypes.ACCEPTED_REFERRAL,
        "FAILED_TO_ACCEPT_REFERRAL",
      ],
    },
  });
  if (acceptedReferral.error !== true) {
    dispatch(UpdatePatientReferralStatus(acceptedReferral.payload));
  }

  return acceptedReferral;
};

export const saveReferralAssignedUser = (orgUnitId, assignReferral) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/assign`,
    body: { assignReferral },
    types: ["SAVING_REFERRAL_ASSIGNED_USER", ActionTypes.SAVED_REFERRAL_ASSIGNED_USER, "ERROR_SAVING_REFERRAL_ASSIGNED_USER"],
    referralId: assignReferral.referralId,
    assignmentType: assignReferral.assignmentType,
  },
});

export const requestMoreInfoReferralAction = (orgUnitId, requestMoreInfo) => async dispatch => {
  const requestedMoreInfoReferral = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/requestMoreInformation`,
      body: { requestMoreInformation: requestMoreInfo },
      entityType: "requestMoreInfo",
      types: [
        "SENDING_REQUEST_MORE_INFO",
        ActionTypes.REQUESTED_MORE_INFO,
        "FAILED_TO_REQUEST_MORE_INFO",
      ],
    },
  });

  if (requestedMoreInfoReferral.error !== true) {
    dispatch(UpdatePatientReferralStatus(requestedMoreInfoReferral.payload));
  }

  return requestedMoreInfoReferral;
};

export const getReferralAppointments = (orgUnitId, referralId) => ({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/GetAppointments`,
    method: "GET",
    entityType: "REFERRAL_APPOINTMENTS",
  },
});

export const fetchPreviewAttachment = (orgUnitId, referralId, referralDocumentId, id) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/PreviewAttachment?fileId=${id}`,
    method: "GET",
    entityType: "REFERRAL_ATTACHMENT_FILE",
    id,
    referralId,
    referralDocumentId,
  },
});

export const fetchAssignmentOptions = (orgUnitId, searchParams, isAssignment = true) => dispatch => {
  let url = `/referrals/assignmentOptions?&isAssignment=${isAssignment}&orgUnitId=${orgUnitId}`;

  if (!isEmpty(searchParams)) {
    url += `&name=${searchParams}`;
  }

  return dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: url,
      method: "GET",
      entityType: "ASSIGNMENT_OPTIONS",
    },
  });
};

export const fetchPDFPreview = (orgUnitId, referralId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/${orgUnitId}/printPreview/${referralId}`,
    method: "GET",
    entityType: "REFERRAL_PDF_PREVIEW",
    referralId,
  },
});

export const createManualReferral = (orgUnitId, createReferral) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/create`,
    body: { createReferral },
    types: ["CREATING_MANUAL_REFERRAL", ActionTypes.CREATE_MANUAL_REFERRAL, "ERROR_CREATING_MANUAL_REFERRAL"],
    patientId: createReferral.patientId,
    orgUnitId,
  },
});

export const editManualReferral = (orgUnitId, editReferral) => async dispatch => {
  const response = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/edit`,
      body: { editReferral },
      types: ["EDITING_MANUAL_REFERRAL", ActionTypes.EDIT_MANUAL_REFERRAL, "ERROR_EDITING_MANUAL_REFERRAL"],
      orgUnitId,
    },
  });

  if (response.error !== true && editReferral.patientId && editReferral.referralId) {
    dispatch(UpdatePatientReferralAfterEdit(editReferral.patientId, editReferral.referralId, response.payload.referral));
  }

  return response;
};

export const updateReferralPresentingComplaint = (orgUnitId, presentingComplaints) => async dispatch => {
  const response = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/updatePresentingComplaint`,
      body: { presentingComplaints },
      types: ["UPDATING_PRESENTING_COMPLAINT", ActionTypes.UPDATED_PRESENTING_COMPLAINT, "ERROR_UPDATING_PRESENTING_COMPLAINT"],
    },
  });

  if (response.error !== true
    && response.payload.patientId
    && presentingComplaints.referralId
  ) {
    dispatch(
      UpdatePatientReferralPresentingComplaintsAfterEdit(
        response.payload.patientId,
        presentingComplaints.referralId,
        response.payload.presentingComplaintSummary,
      ),
    );
  }

  return response;
};

export const uploadReferralSourceDocumentFile = (orgUnitId, formData, specialtyId = null) => async dispatch => {
  let url = `/referrals/${orgUnitId}/uploadReferralSourceDocumentFile`;
  if (specialtyId) {
    url += `?specialtyId=${specialtyId}`;
  }

  return dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: url,
      headers: { "Content-Type": "multipart/form-data" },
      body: formData,
      entityType: "SOURCE_DOCUMENT_FILE",
    },
  });
};

export const processOCR = (orgUnitId, specialtyId, referralSourceDocumentFileId) => async (dispatch, getState) => {
  const response = await dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: `/referrals/${orgUnitId}/ProcessOCR/${specialtyId}/${referralSourceDocumentFileId}`,
      method: "GET",
      entityType: "SOURCE_DOCUMENT_FILE",
      referralSourceDocumentFileId,
      orgUnitId,
      specialtyId,
    },
  });

  if (response.error !== true) {
    dispatch(setCurrentPatientOCR(response?.payload?.ocrResultJSON));
    const result = getCurrentPatientOCR(getState());
    return result;
  }
  dispatch(resetCurrentPatientOCR());
  return response;
};

export const clearReferralSourceDocumentFiles = () => ({
  type: ActionTypes.CLEAR_SOURCE_DOCUMENT_FILES,
});

export const getReferralSourceDocumentFilePreviewData = (orgUnitId, sourceDocumentId, fileId) => dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/${orgUnitId}/getReferralSourceDocumentFileData?fileId=${fileId}`,
    method: "GET",
    entityType: "EXISTING_SOURCE_DOCUMENT_FILE",
    sourceDocumentId,
    fileId,
  },
});

export const getReferralAccessLog = (orgUnitId, referralId) => dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/${orgUnitId}/GetReferralAccessLog?referralId=${referralId}`,
    method: "GET",
    types: [
      ActionTypes.LOADING_REFERRAL_ACCESS_LOG,
      ActionTypes.LOADED_REFERRAL_ACCESS_LOG,
      ActionTypes.ERROR_LOADING_REFERRAL_ACCESS_LOG,
    ],
  },
});

export const addReferralTask = (orgUnitId, referralId, referralTask) => dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/addTask`,
    body: { referralTask },
    entityType: "REFERRAL_TASK",
    referralId,
  },
});

export const editReferralTask = (orgUnitId, referralId, referralTask) => async dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/editTask`,
    body: { referralTask },
    entityType: "REFERRAL_TASK",
    referralId,
  },
});

export const removeReferralTask = (orgUnitId, referralId, referralTaskId) => dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/removeTask/${referralTaskId}`,
    entityType: "REFERRAL_TASK",
    referralId,
    referralTaskId,
  },
});

export const uploadReferralAttachment = (orgUnitId, referralId, referralDocumentId, formData) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/${referralDocumentId}/addAttachment`,
    headers: { "Content-Type": "multipart/form-data" },
    body: formData,
    entityType: "REFERRAL_ATTACHMENT",
  },
});

export const updateReferralAttachment = (orgUnitId, referralId, referralDocumentId, attachment) => dispatch => dispatch({
  [ApiActions.SAVE_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/${referralDocumentId}/saveAttachment`,
    body: { attachment },
    entityType: "REFERRAL_ATTACHMENT",
    referralId,
    referralDocumentId,
  },
});

export const removeReferralAttachment = (orgUnitId, referralId, referralDocumentId, referralAttachmentId) => dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/removeAttachment/${referralDocumentId}/${referralAttachmentId}`,
    entityType: "REFERRAL_ATTACHMENT",
    referralId,
    referralDocumentId,
    referralAttachmentId,
  },
});

export const addCorrespondenceAttachment = (orgUnitId, correspondenceAttachment) => dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/addCorrespondenceAttachment`,
    body: { correspondenceAttachment },
    entityType: "REFERRAL_CORRESPONDENCE",
  },
});

export const sendReferralOutboundMessage = (orgUnitId, referralId, isNewReferral = false) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/SendOutboundMessage?isNewReferral=${isNewReferral}`,
    method: "GET",
    types: [
      ActionTypes.SENDING_REFERRAL_OUTBOUND_MESSAGE,
      ActionTypes.SENT_REFERRAL_OUTBOUND_MESSAGE,
      ActionTypes.ERROR_SENDING_REFERRAL_OUTBOUND_MESSAGE,
    ],
    bailout: state => isSendingReferralOutboundMessage(state),
  },
});

export const recordReferralAccessLog = (orgUnitId, referralId, accessType) => async (dispatch, getState) => {
  const enableRecordReferralActionAccess = getEnableRecordReferralActionAccess(getState());

  // If recording referral action access logs is disabled, return null
  if (!enableRecordReferralActionAccess) {
    return null;
  }

  // Create an object containing the referral ID and access type
  const referralActionAccess = { referralId, accessType };

  const response = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/referrals/${orgUnitId}/RecordReferralActionAccessLog`,
      body: { referralActionAccess },
      entityType: "REFERRAL_ACTION_ACCESS_LOG",
      bailout: state => isWarningReferralAccessLogLoading(state),
    },
  });

  // If there was an error saving the referral action access log or no logs were returned, return null
  if (response.error || !response?.payload?.warningReferralAccessLogs?.length) {
    return null;
  }

  // Get the first referral action(Edit/Accept) access log from the response payload
  const warningReferralAccessLog = first(response.payload.warningReferralAccessLogs);

  if (warningReferralAccessLog) {
    const actionType = {
      Edit: "editing",
      Accept: "triaging",
    }[warningReferralAccessLog.accessType] || warningReferralAccessLog.accessType.toLowerCase();

    const userDetail = isEmpty(warningReferralAccessLog.firstName) && isEmpty(warningReferralAccessLog.lastName) ? "Someone else" : `${warningReferralAccessLog.firstName} ${warningReferralAccessLog.lastName}`;
    const accessedAt = formatUtcDate(warningReferralAccessLog.dateAccessed, PreferredTimeFormat);

    return `${userDetail} was recently ${actionType} this referral at ${accessedAt}`;
  }

  return null;
};

export const setReferralNotUrgent = (orgUnitId, referralId, urgentEditModel) => dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/SetNotUrgent`,
    body: { urgentEditModel },
    types: [
      "SENDING_REFERRAL_AS_NOT_URGENT",
      ActionTypes.REFERRAL_AS_NOT_URGENT,
      "FAILED_TO_REFERRAL_AS_NOT_URGENT",
    ],
    referralId,
  },
});

export const setReferralUrgent = (orgUnitId, referralId, urgentEditModel) => dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/${referralId}/SetUrgent`,
    body: { urgentEditModel },
    types: [
      "SENDING_REFERRAL_AS_URGENT",
      ActionTypes.REFERRAL_AS_URGENT,
      "FAILED_TO_REFERRAL_AS_URGENT",
    ],
    referralId,
  },
});

export const saveReferralAssignedPatient = (orgUnitId, assignPatientReferral) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/assignPatient`,
    body: { assignPatientReferral },
    types: ["SAVING_REFERRAL_ASSIGNED_PATIENT", ActionTypes.SAVED_REFERRAL_ASSIGNED_PATIENT, "ERROR_SAVING_REFERRAL_ASSIGNED_PATIENT"],
    referralId: assignPatientReferral.referralId,
  },
});

export const saveReferralAssignedPatientEdit = (orgUnitId, assignPatientReferral) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referrals/${orgUnitId}/ReAssignPatient`,
    body: { assignPatientReferral },
    types: ["SAVING_REFERRAL_ASSIGNED_PATIENT_EDIT", ActionTypes.SAVED_REFERRAL_ASSIGNED_PATIENT_EDIT, "ERROR_SAVING_REFERRAL_ASSIGNED_PATIENT_EDIT"],
    referralId: assignPatientReferral.referralId,
  },
});
