import { combineReducers } from "redux";
import auth, { AUTH_STATE_KEY } from "app/auth/store/reducers";

import users, { USERS_STATE_KEY } from "app/main/users/reducers/users.reducers";
import patients, { PATIENTS_STATE_KEY } from "app/main/patients/reducers/patients.reducers";
import encounters, { ENCOUNTERS_STATE_KEY } from "app/main/patients/reducers/encounters.reducers";
import appointments, { APPOINTMENTS_STATE_KEY } from "app/main/patients/reducers/appointments.reducers";
import worklist, { WORKLIST_STATE_KEY } from "app/main/worklist/reducers/worklist.reducers";
import candidates, { CANDIDATES_STATE_KEY } from "app/main/candidates/reducers/candidates.reducers";
import candidateWorklist, { CANDIDATE_WORKLIST_STATE_KEY } from "app/main/candidateWorklist/reducers/candidateWorklist.reducers";
import referrals, { REFERRALS_STATE_KEY } from "app/main/referrals/reducers/referrals.reducers";
import referralWorklist, { REFERRAL_WORKLIST_STATE_KEY } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import systemconfiguration, { SYSTEM_CONFIGURATION_STATE_KEY } from "app/auth/store/reducers/system-configuration";
import specialtyProfiles, { SPECIALTY_PROFILES_STATE_KEY } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import applicationWorklist, { APPLICATION_WORKLIST_STATE_KEY } from "app/main/applicationWorklist/reducers/applicationWorklist.reducers";
import applicationHistory, { APPLICATION_HISTORY_STATE_KEY } from "app/main/applicationWorklist/reducers/applicationHistory.reducers";
import applicationDispensing, { APPLICATION_DISPENSING_STATE_KEY } from "app/main/applicationWorklist/reducers/applicationDispensing.reducers";
import applications, { APPLICATIONS_STATE_KEY } from "app/main/applications/reducers/applications.reducers";
import assessments, { ASSESSMENTS_STATE_KEY } from "app/main/patients/reducers/assessments.reducers";
import contacts, { CONTACTS_STATE_KEY } from "app/main/patients/reducers/contacts.reducers";
import patientReferrals, { PATIENT_REFERRALS_STATE_KEY } from "app/main/referrals/reducers/patientReferrals.reducers";
import patientReferralWorklist, { PATIENT_REFERRAL_WORKLIST_STATE_KEY } from "app/main/patientReferralWorklist/reducers/patientReferralWorklist.reducers";
import patientMerge, { PATIENT_MERGE_STATE_KEY } from "app/main/patientMergeLog/reducers/patientMerge.reducers";
import externalDocumentWorklist, { EXTERNAL_DOCUMENT_WORKLIST_STATE_KEY } from "app/main/externalDocumentWorklist/reducers/externalDocumentWorklist.reducers";
import orgUnits, { ORG_UNIT_STATE_KEY } from "app/main/orgUnits/reducers/orgUnit.reducers";
import patientAccess, { PATIENT_ACCESS_STATE_KEY } from "app/main/patientAccessLog/reducers/patientAccessLog.reducers";
import patientsSearch, { PATIENTS_SEARCH_STATE_KEY } from "app/main/patients/reducers/patientsSearch.reducers";
import codeSet, { CODE_SET_STATE_KEY } from "app/main/codeSet/reducers/codeSet.reducers";
import providers, { PROVIDER_STATE_KEY } from "app/main/providers/reducers/providers.reducers";
import patientOCR, { PATIENT_OCR_STATE_KEY } from "app/main/patients/reducers/patientOCR.selector.reducers";
import integrationLogs, { INTEGRATION_LOGS_STATE_KEY } from "app/main/integration/reducers/integrationLogs.reducers";
import referralFormProfiles, { REFERRAL_FORM_PROFILES_STATE_KEY } from "app/main/referralFormProfiles/reducers/referralFormProfiles.reducers";
import referralDocumentAttachmentUploads, { REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD_STATE_KEY } from "app/main/referralDocumentAttachmentUploads/reducers/referralDocumentAttachmentUploads.reducers";
import reportExport, { REPORT_EXPORT_STATE_KEY } from "app/main/reportExport/reducers/reportExport.reducers";

import sidebars, { SIDEBAR_STATE_KEY } from "./sidebars.reducer";
import theme from "./theme.reducer";
import dialog from "./dialog.reducer";
import message from "./message.reducer";
import routes from "./routes.reducer";

export default () => combineReducers({
  [AUTH_STATE_KEY]: auth,
  [USERS_STATE_KEY]: users,
  [SYSTEM_CONFIGURATION_STATE_KEY]: systemconfiguration,
  [PATIENTS_STATE_KEY]: patients,
  [PATIENTS_SEARCH_STATE_KEY]: patientsSearch,
  [PATIENT_MERGE_STATE_KEY]: patientMerge,
  [PATIENT_ACCESS_STATE_KEY]: patientAccess,
  [ENCOUNTERS_STATE_KEY]: encounters,
  [APPOINTMENTS_STATE_KEY]: appointments,
  [ASSESSMENTS_STATE_KEY]: assessments,
  [CONTACTS_STATE_KEY]: contacts,
  [WORKLIST_STATE_KEY]: worklist,
  [PATIENT_REFERRALS_STATE_KEY]: patientReferrals,
  [PATIENT_REFERRAL_WORKLIST_STATE_KEY]: patientReferralWorklist,
  [PATIENT_OCR_STATE_KEY]: patientOCR,
  [REFERRALS_STATE_KEY]: referrals,
  [REFERRAL_WORKLIST_STATE_KEY]: referralWorklist,
  [CANDIDATES_STATE_KEY]: candidates,
  [CANDIDATE_WORKLIST_STATE_KEY]: candidateWorklist,
  [APPLICATIONS_STATE_KEY]: applications,
  [APPLICATION_WORKLIST_STATE_KEY]: applicationWorklist,
  [APPLICATION_HISTORY_STATE_KEY]: applicationHistory,
  [APPLICATION_DISPENSING_STATE_KEY]: applicationDispensing,
  [EXTERNAL_DOCUMENT_WORKLIST_STATE_KEY]: externalDocumentWorklist,
  [ORG_UNIT_STATE_KEY]: orgUnits,
  [SPECIALTY_PROFILES_STATE_KEY]: specialtyProfiles,
  [CODE_SET_STATE_KEY]: codeSet,
  [PROVIDER_STATE_KEY]: providers,
  [SIDEBAR_STATE_KEY]: sidebars,
  [INTEGRATION_LOGS_STATE_KEY]: integrationLogs,
  [REFERRAL_FORM_PROFILES_STATE_KEY]: referralFormProfiles,
  [REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD_STATE_KEY]: referralDocumentAttachmentUploads,
  [REPORT_EXPORT_STATE_KEY]: reportExport,
  theme,
  dialog,
  message,
  routes,
});
