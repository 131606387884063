import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import TabContent from "components/items/tab-content";
import { currentReferralAppointmentsLoading, errorLoadingCurrentReferralAppointments } from "app/main/referrals/reducers/referrals.reducers";
import { getReferralAppointments } from "app/main/referrals/actions/referrals.actions";
import AppointmentList from "app/main/patients/appointments/components/appointment-list";
import DefaultPanelLayout from "components/items/default-panel-layout";
import { AppointmentIcon } from "helpers/icon-finder";
import EmptyState from "components/items/empty-state";

export default ({
  referral,
  orgUnitId,
}) => {
  const dispatch = useDispatch();
  const loadingCurrentAppointments = useSelector(currentReferralAppointmentsLoading);
  const errorLoadingCurrentAppointments = useSelector(errorLoadingCurrentReferralAppointments);

  React.useEffect(() => {
    dispatch(getReferralAppointments(orgUnitId, referral.id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderEmptyState = () => (
    <EmptyState
      icon={<AppointmentIcon />}
      title="No Recorded Referral Appointments"
    />
  );

  return (
    <TabContent
      loading={loadingCurrentAppointments}
      empty={isEmpty(referral.appointments)}
      emptyState={renderEmptyState()}
    >
      <DefaultPanelLayout>
        <AppointmentList
          appointments={referral.appointments}
          orgUnitId={orgUnitId}
          stateProps={{
            isLoading: loadingCurrentAppointments,
            isEmpty: isEmpty(referral.appointments),
            error: errorLoadingCurrentAppointments,
          }}
        />
      </DefaultPanelLayout>
    </TabContent>
  );
};
