import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import DefaultButton from "components/items/default-button";
import PatientSearchForm from "app/main/patients/components/external-patient-search-button/patient-search-form";
import PatientForm from "app/main/patients/components/patient-form";
import { createNewPatient } from "app/main/patients/actions/patients.actions";

import { getCurrentPatientOCR, getCurrentAttachment } from "app/main/patients/reducers/patientOCR.selector.reducers";
import { fetchPatientSearch } from "app/main/patients/actions/patientsSearch.actions";
import OCRProcessReferral from "app/main/referrals/components/ocr-process-referral";

const useStyles = makeStyles(theme => ({
  titleClass: {
    backgroundColor: "transparent",
    color: theme.palette.primary.dark,
  },
}));

const PatientOptionComponent = props => {
  const {
    enableAddPatient,
    isNewPatient,
    ocrResult,
    referredToSpecialty,
    sourceDocument,
    patientLabel,
    onClose,
    onContinue,
    onCreatePatient,
    onNewPatient,
    onPatientSearch,
    titleClass,
    confirmedOCR,
    patientPresetFilter,
  } = props;

  const onContinueAction = selectedPatient => {
    const formattedSourceDocument = {
      ...sourceDocument,
      referredToSpecialty,
    };
    onContinue(selectedPatient, formattedSourceDocument, true, false);
  };

  const onCreatePatientAction = parameter => {
    const formattedSourceDocument = {
      ...sourceDocument,
      referredToSpecialty,
    };
    onCreatePatient(parameter, formattedSourceDocument, true);
  };

  return isNewPatient ? (
    <PatientForm
      isModal
      title={`Create a new ${patientLabel.single}`}
      titleClass={titleClass}
      onCancel={onClose}
      onSucceed={onClose}
      handleSubmit={onCreatePatientAction}
      submitLabel="Continue"
      showNOK
      showHealthSection
      showPrimaryIdentifier
      patient={props?.patient}
      ocrResult={ocrResult}
      confirmedOCR={confirmedOCR}
      sourceDocument={sourceDocument}
      headerBackgroundColor="transparent"
      renderHeaderActions={(
        <DefaultButton
          label="Search Patient"
          onClick={onPatientSearch}
          variant="outlined"
        />
      )}
      isFromNewPatient
    />
  ) : (
    <PatientSearchForm
      title="Search Patient"
      titleClass={titleClass}
      onContinue={onContinueAction}
      onClose={onClose}
      ocrResult={ocrResult}
      searchApi={fetchPatientSearch}
      sourceDocument={sourceDocument}
      continueLabel={props.continueLabel}
      continueActionError={props.continueActionError}
      patientPresetFilter={patientPresetFilter}
      isContinueLoading={props.isContinueLoading}
      renderHeaderActions={enableAddPatient && (
        <DefaultButton
          label={`Create a new ${patientLabel.single}`}
          onClick={onNewPatient}
          variant="outlined"
        />
      )}
      confirmedOCR={confirmedOCR}
      isFormStreamlinedAddReferral
    />
  );
};

const PatientOCRSelector = ({
  orgUnitId,
  onClose,
  referredToSpecialty = null,
  title,
  isFromNewPatient = false,
  disabledAddDocument = false,
  ...other
}) => {
  const dispatch = useDispatch();
  const [isNewPatient, setIsNewPatient] = useState(isFromNewPatient);
  const classes = useStyles();

  const currentPatientOCR = useSelector(getCurrentPatientOCR);
  const currentAttachment = useSelector(getCurrentAttachment);

  const onPatientSearch = () => {
    setIsNewPatient(false);
  };

  const onNewPatient = () => {
    dispatch(createNewPatient());
    setIsNewPatient(true);
  };

  return (
    <OCRProcessReferral
      orgUnitId={orgUnitId}
      onClose={onClose}
      onCancel={onClose}
      referredToSpecialty={referredToSpecialty}
      enableProcessOCR={other.enableProcessOCR}
      disabledRemove={other.disabledRemove}
      disabledAddDocument={disabledAddDocument}
      title={title}
      renderContent={props => (
        <PatientOptionComponent
          isNewPatient={isNewPatient}
          ocrResult={currentPatientOCR?.ocrData}
          sourceDocument={currentAttachment}
          referredToSpecialty={props?.referredToSpecialty}
          onNewPatient={onNewPatient}
          onPatientSearch={onPatientSearch}
          titleClass={classes.titleClass}
          onClose={onClose}
          {...props}
          {...other}
        />
      )}
    />
  );
};

export default PatientOCRSelector;
