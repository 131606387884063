import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";

import TabContent from "components/items/tab-content";
import { currentReferralReportIsLoading, errorLoadingCurrentReferralReport } from "app/main/referrals/reducers/referrals.reducers";
import { getReferralReport } from "app/main/referrals/actions/referrals.actions";
import ReferralDetailsPanel from "app/main/referralWorklist/components/referral-details-panel";
import ReferralReportPanel from "app/main/referralWorklist/components/referral-report-panel";

export default ({
  referral,
  orgUnitId,
}) => {
  const dispatch = useDispatch();
  const loadingCurrentReports = useSelector(currentReferralReportIsLoading);
  const errorLoadingCurrentReports = useSelector(errorLoadingCurrentReferralReport);
  const manualReferral = referral.referralDocument.referralDocumentType === "Manual";
  React.useEffect(() => {
    if (!manualReferral && referral.referralDocument.canViewDetails && orgUnitId) {
      dispatch(getReferralReport(orgUnitId, referral.id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex-1 bg-white">
      {manualReferral
        ? <TabContent withGutters><ReferralDetailsPanel referral={referral} /></TabContent>
        : (
          <TabContent
            withGutters
            accessDenied={!referral?.referralDocument?.canViewDetails}
            loading={loadingCurrentReports}
            error={errorLoadingCurrentReports}
            empty={isEmpty(referral.reports)}
          >
            <ReferralReportPanel reports={referral.reports} />
          </TabContent>
        )}
    </div>
  );
};
