import { blue, amber, green, grey } from "@material-ui/core/colors";
import { primary as defaultPrimary, secondary as defaultSecondary } from "./color/default";
import { primary as waPrimary, secondary as waSecondary, logo as waLogo } from "./color/wa";
import { primary as tasTrainingPrimary, secondary as tasTrainingSecondary } from "./color/tasTraining";
import { primary as tealPrimary, secondary as tealSecondary } from "./color/teal";
/**
 * THEME DEFAULT SETTINGS
 */
export const mustHaveThemeOptions = {
  typography: {
    htmlFontSize: 10,
  },
  palette: {
    warning: {
      light: amber[300],
      main: amber[600],
      dark: amber[900],
      contrastText: "#fff",
    },
    info: {
      light: blue[300],
      main: blue[500],
      dark: blue[800],
      contrastText: "#fff",
    },
    success: {
      light: green[300],
      main: green[500],
      dark: green[800],
      contrastText: "#fff",
    },
    background: {
      default: grey[50],
      control: grey[50],
      panel: grey[100],
      panelContent: "#ffffff",
      main: grey[300],
    },
  },
  size: {
    header: 64,
    drawer: 400,
    actionPanel: 240,
  },
};

const themesConfig = {
  default: {
    palette: {
      type: "light",
      primary: defaultPrimary,
      secondary: {
        light: defaultSecondary[400],
        main: defaultSecondary[500],
        dark: defaultSecondary[700],
      },
    },
  },
  blue: {
    palette: {
      primary: defaultSecondary,
      secondary: {
        light: defaultSecondary[400],
        main: defaultSecondary[500],
        dark: defaultSecondary[700],
      },
    },
  },
  wa: {
    palette: {
      primary: waPrimary,
      secondary: waSecondary,
    },
    logo: waLogo,
  },
  tasTrain: {
    palette: {
      primary: tasTrainingPrimary,
      secondary: tasTrainingSecondary,
    },
  },
  teal: {
    palette: {
      primary: tealPrimary,
      secondary: tealSecondary,
    },
  },
};

export default themesConfig;
