import React, { useEffect, useRef } from "react";
import { DialogActions, DialogContent, DialogTitle, AppBar, Toolbar, Typography } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { get } from "lodash";
import clsx from "clsx";

const DialogPopup = ({
  title,
  titleClass = null,
  content,
  renderActions,
  renderHeaderActions,
  renderHeaderContent,
  classes,
}) => {
  const theme = useTheme();
  const contentRef = useRef();

  useEffect(() => {
    if (contentRef.current !== undefined) {
      contentRef.current.scrollTop = 0;
    }
  }, []);

  return (
    <>
      <DialogTitle id="form-dialog-title" className="p-0">
        <AppBar position="static" elevation={1} className={titleClass} style={{ borderLeft: "none", boxShadow: "none" }}>
          <Toolbar variant="dense" className="flex overflow-x-auto px-8 sm:px-16">
            <Typography variant="subtitle1" color="inherit" className="flex-1">
              {title}
            </Typography>
            {renderHeaderActions && renderHeaderActions}
          </Toolbar>
        </AppBar>
        {renderHeaderContent && renderHeaderContent}
      </DialogTitle>
      <DialogContent ref={contentRef} dividers className={clsx("py-24 overflow-x-hidden dialog-content", get(classes, "content"))}>
        {content}
      </DialogContent>
      {renderActions
        && (
        <DialogActions classes={{ root: "m-0 px-16 py-8" }} style={{ backgroundColor: theme.palette.background.control }}>
          {renderActions}
        </DialogActions>
        )}
    </>
  );
};

export default DialogPopup;
