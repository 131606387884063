import React from "react";
import { Typography, Box, CircularProgress } from "@material-ui/core";
import { isString } from "lodash";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import IconComponent from "components/items/icon-component";
import checkIfEmpty from "helpers/check-if-empty";

const useStyles = makeStyles(theme => ({
  prefix: {
    display: "flex",
    alignItems: "center",
    flex: "0 0 auto",
  },
  content: ({ textColor }) => ({
    flex: "1 1 auto",
    width: "100%", // fix for IE - text wrapping
    color: textColor || theme.palette.text.primary,
    whiteSpace: "pre-wrap",
    wordBreak: "break-word",
  }),
  prefixItem: {
    display: "flex",
    alignItems: "center",
    width: 48,
  },
}));

/**
 * Component display label, icon, content in a defined layout
 * Note that this could be improve in the future, use classes instead, but because its widely used across the app,
 * leave the the style and className props incase some places are still using them
 *
 * @param {string | any} label
 * @param {string | any} icon
 * @param {string | any} content
 * // start - following should be deprecated and replace with classes
 * @param {*} className
 * @param {*} iconClassName
 * @param {*} labelClassName
 * @param {*} contentClassName
 * // find out the usage and remove it
 * @param {*} style
 * // end
 * @param {string} iconColor
 * @param {string} textColor
 * @param {TypographyProps} labelProps - props pass to label - must be acceptable by Typography https://material-ui.com/api/typography/
 * @param {TypographyProps} alignItems - how the prefix is align with the content, default alignment to center, common options: items-start, items-center, items-end
 * @param {*} classes - classes object, includes root, prefix, icon, label, content
 */
const ItemComponent = ({
  label,
  icon,
  content,
  className,
  iconClassName,
  labelClassName,
  contentClassName,
  style,
  iconColor,
  textColor,
  showRequiredLabel,
  labelProps,
  alignment = "items-center",
  classes,
  loading,
}) => {
  const styles = useStyles({ textColor });

  return (
    <Box className={clsx("flex", alignment, className, classes?.root)} style={style}>
      {(label || icon || loading)
        && (
          <Box className={clsx(styles.prefix, classes?.prefix)}>
            {icon && !loading
              && (
              <Box className={clsx(styles.prefixItem, iconClassName, classes?.icon)}>
                <IconComponent icon={icon} color={iconColor} />
              </Box>
              )}
            {loading && (
              <Box className={clsx(styles.prefixItem, iconClassName, classes?.icon)}>
                <CircularProgress size={20} />
              </Box>
            )}
            {label
              && (
                <Box className={clsx(styles.prefixItem, labelClassName)}>
                  {isString(label) ? <Typography className="font-bold mr-4" variant="subtitle2" {...labelProps}>{label.toUpperCase()}</Typography> : label}
                  {showRequiredLabel ? <Typography color="error">*</Typography> : null}
                </Box>
              )}
          </Box>
        )}
      <Box className={clsx(styles.content, contentClassName, classes?.content)}>
        {isString(content) ? <Typography color="inherit">{content}</Typography> : content}
      </Box>
    </Box>
  );
};

export const ContextItem = ({
  label,
  icon,
  content,
  renderIfEmpty,
  showRequiredLabel,
  emptyContent = "Not Recorded",
  ...other
}) => {
  // do not render the component if content supplied is empty and renderIfEmpty is not set to true
  if (checkIfEmpty(content) && !renderIfEmpty && !showRequiredLabel) return null;
  return (
    <ItemComponent icon={icon} label={label} content={renderIfEmpty && checkIfEmpty(content) ? emptyContent : content} showRequiredLabel={showRequiredLabel} {...other} />
  );
};

export default ItemComponent;
