
import { makeStyles } from "@material-ui/core/styles";

const useOCRStyles = makeStyles(theme => ({
  root: {
    "& > div": {
      height: "auto",
    },
  },
  helperText: {
    color: theme.palette.text.hint,
    display: "flex",
    marginBottom: theme.spacing(3),
  },
  activeLabel: {
    color: theme.palette.text.primary,
  },
  content: {
    marginLeft: theme.spacing(4),
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  contentLabel: {
    marginLeft: theme.spacing(4),
  },
  contentValue: {
    justifyContent: "center",
    marginTop: theme.spacing(1),
    alignItems: "center",
    display: "flex",
  },
  actions: {
    display: "flex",
    flex: 1,
    justifyContent: "flex-end",
    "& > *:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
  threshold: {
    fontStyle: "italic",
  },
  thresholdPercentage: ({ requireConfirm }) => ({
    color: requireConfirm ? "red" : "green",
  }),
  confirmedThresholdPercentage: {
    color: "green",
  },
  ocrValue: {
    fontWeight: "bolder",
  },
}));

export default useOCRStyles;
