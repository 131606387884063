import { ApiActions } from "middleware/call-api";
import { ActionTypes } from "../reducers/referralDocumentAttachmentUploads.reducers";

export const uploadAttachment = (orgUnitId, formData) => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/referralDocumentAttachmentUpload/${orgUnitId}`,
    headers: { "Content-Type": "multipart/form-data" },
    body: formData,
    entityType: "REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD",
  },
});

export const removeAttachment = (orgUnitId, referralDocumentAttachmentUploadId) => async dispatch => dispatch({
  [ApiActions.DELETE_FROM_API]: {
    endpoint: `/referralDocumentAttachmentUpload/${orgUnitId}/${referralDocumentAttachmentUploadId}`,
    entityType: "REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD",
    referralDocumentAttachmentUploadId,
  },
});

export const clearUploadAttachment = () => ({
  type: ActionTypes.CLEAR_REFERRAL_DOCUMENT_ATTACHMENT_UPLOADS,
});

export const fetchPreviewAttachment = (orgUnitId, referralDocumentAttachmentUploadId) => async dispatch => dispatch({
  [ApiActions.FETCH_FROM_API]: {
    endpoint: `/referralDocumentAttachmentUpload/${orgUnitId}/${referralDocumentAttachmentUploadId}/PreviewAttachment`,
    method: "GET",
    entityType: "REFERRAL_DOCUMENT_ATTACHMENT_UPLOAD_FILE",
    referralDocumentAttachmentUploadId,
  },
});
