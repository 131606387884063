import React, { useEffect } from "react";
import { map, isEmpty, some, includes } from "lodash";
import { connect, useSelector, useDispatch } from "react-redux";
import LoadingState from "components/items/loading-state";
import { getActivityByTypeAndId } from "app/main/referrals/reducers/referrals.reducers";
import ActivityLog from "components/items/activity-log";
import { acceptActionType, notUrgentActionType, urgentActionType } from "app/main/referrals/helpers/action-type-names";
import { areCodeSetValuesLoaded, areCodeSetValuesLoading, getAllCodeSetValues } from "app/main/codeSet/reducers/codeSet.reducers";
import { fetchAllCodeSetValues } from "app/main/codeSet/actions/codeSet.actions";
import { getEnablePreventAddEditNotesInEndState, getReferralWorkflowEndStates } from "app/auth/store/reducers/system-configuration";

import ActionTypeItem from "./action-type-item";
import NoteItem from "./note-item";
import CorrespondenceItem from "./correspondence-item";
import AssignmentItem from "./assignment-item";
import TaskItem from "./task-item";
import AcceptActionTypeItem from "./accept-action-type-item";
import UrgentStatusItem from "./urgent-status-item";
import AttachmentItem from "./attachment-item";

import Header from "./header";

const ReferralActivityLog = ({ mappedActivities, referral, orgUnitId }) => {
  const { permissions, referralStatus } = referral;
  const enablePreventAddEditNotesInEndState = useSelector(getEnablePreventAddEditNotesInEndState);
  const referralWorklistEndStates = useSelector(getReferralWorkflowEndStates);

  const dispatch = useDispatch();
  const codeSetValuesLoaded = useSelector(areCodeSetValuesLoaded);
  const codeSetValuesLoading = useSelector(areCodeSetValuesLoading);
  const codeSetValues = useSelector(getAllCodeSetValues);

  useEffect(() => {
    if ((!codeSetValuesLoaded && !codeSetValuesLoading) || !some(codeSetValues)) {
      dispatch(fetchAllCodeSetValues(true));
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeSetValuesLoaded, codeSetValuesLoading]);

  if (codeSetValuesLoading) {
    return <LoadingState />;
  }

  return (
    <ActivityLog>
      {permissions.canEditNotes && <Header referral={referral} orgUnitId={orgUnitId} />}
      {map(mappedActivities, (activity, index) => {
        if (activity && activity.detail) {
          switch (activity.type) {
            case "action":
              if (activity.detail.actionType === acceptActionType) return <AcceptActionTypeItem key={activity.detail.id} activity={activity.detail} orgUnitId={referral?.orgUnitId} />;
              if (activity.detail.actionType === urgentActionType || activity.detail.actionType === notUrgentActionType) {
                return <UrgentStatusItem key={activity.detail.id} activity={activity.detail} orgUnitId={referral?.orgUnitId} />;
              }
              return <ActionTypeItem key={activity.detail.id} activity={activity.detail} orgUnitId={referral?.orgUnitId} />;
            case "note":
              return (
                <NoteItem
                  key={activity.detail.id}
                  activity={activity.detail}
                  canEdit={referral.isActive && !(includes(referralWorklistEndStates, referralStatus) && enablePreventAddEditNotesInEndState)}
                  orgUnitId={orgUnitId}
                />
              );
            case "assignment":
              return <AssignmentItem key={activity.detail.id} activity={activity.detail} />;
            case "correspondence":
              return <CorrespondenceItem key={activity.detail.id} activity={activity.detail} referralId={referral.id} orgUnitId={orgUnitId} />;
            case "task":
              return <TaskItem key={activity.detail.id} activity={activity.detail} canDelete={referral.isActive} specialtyId={referral.assignedTo.assignedToSpecialtyId} orgUnitId={orgUnitId} />;
            case "addAttachment":
            case "removeAttachment":
              return <AttachmentItem key={index} activity={activity} referralId={referral.id} orgUnitId={orgUnitId} />;
            default:
              return null;
          }
        }
        return null;
      })}
    </ActivityLog>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { activities, referralId, orgUnitId } = ownProps;
  let mappedActivities = null;

  if (!isEmpty(activities)) {
    mappedActivities = map(activities, activity => (activity.type ? getActivityByTypeAndId(state, activity.type, activity.id) : activity));
  }

  return {
    mappedActivities,
    referralId,
    orgUnitId,
  };
};

export default (connect(
  mapStateToProps,
  {},
)(ReferralActivityLog));
