import React from "react";
import { Typography } from "@material-ui/core";
import { map, isEmpty, join, filter } from "lodash";

import { ArrowRightIcon } from "helpers/icon-finder";
import DefaultItem, { ContextItem } from "components/items/default-item";
import IconButton from "components/items/icon-button";
import ActivityLogItem from "components/items/activity-log/item";
import { formatUtcDate } from "helpers/format-date-time";
import useActionTypeItem from "./useActionTypeItem";
import Acknowledgement from "./acknowledgement";

const formatValue = (value, dataType) => {
  if (dataType === "DateTime" && value !== "Not Recorded" && value !== "Removed" && !isEmpty(value)) {
    return formatUtcDate(value, "DD-MMM-YYYY HH:mm");
  }

  return value;
};

const EditItem = (name, originalValue, newValue, description, dataType) => (
  <DefaultItem
    key={name}
    label={description}
    className="flex-col"
    alignment="items-start"
    labelProps={{ variant: "caption", color: "textSecondary" }}
    labelClassName="w-auto"
    content={<Typography>{formatValue(originalValue, dataType)} <ArrowRightIcon size={12} className="mx-8" /> {formatValue(newValue, dataType)} </Typography>}
  />
);

const Item = props => (
  <ContextItem
    className="flex-col"
    alignment="items-start"
    labelClassName="w-full"
    {...props}
  />
);

const ActionTypeItem = ({ orgUnitId, activity }) => {
  const { title, popupTitle, status, getDisplayCodeSetValue } = useActionTypeItem({ orgUnitId, activity });
  const showPopup = !isEmpty(activity.message) || !isEmpty(activity.referralChangesJson);

  const actionCodeSet = () => {
    if (isEmpty(activity.codeSetValues)) return null;

    return map(activity.codeSetValues, x => <Item key={x.value} label={x.label} content={getDisplayCodeSetValue(x.value)} />);
  };

  const content = () => {
    if (activity.referralChangesJson) {
      const changes = JSON.parse(activity.referralChangesJson);
      // filter out empty items
      return `${join(filter(map(changes, x => x?.description), des => !isEmpty(des)), ", ")}`;
    }
    return (
      <>
        {actionCodeSet()}
        {activity.message}
      </>
    );
  };

  const popupContent = () => {
    if (activity.referralChangesJson) {
      return (
        <div className="items-container with-horizontal-divider sm">
          {map(JSON.parse(activity.referralChangesJson), x => x && (
            EditItem(x.name, x.originalValue, x.newValue, x.description, x.dataType)
          ))}
        </div>
      );
    }
    return (
      <div className="pt-16 items-container">
        {actionCodeSet()}
        <Item label="Message" content={activity.message} />
      </div>
    );
  };

  return (
    <ActivityLogItem
      date={activity.createdDateLocalised}
      user={activity?.actionedBy}
      renderLeftContent={<Acknowledgement acknowledgement={activity.acknowledgement} dateTime={activity.acknowledgementDateTimeUtc} />}
      icon={status.icon}
      iconColor={status.color}
      activeColor={status.color}
      title={title}
      content={content()}
      renderPopupContent={showPopup ? ({ close }) => (
        <>
          <div className="flex justify-between items-center">
            {popupTitle}
            <div className="flex items-center justify-end">
              <IconButton title="Close" onClick={close} icon="close" />
            </div>
          </div>
          <Typography variant="caption">by {activity.actionedBy} at {activity.createdDateLocalised}</Typography>
          {popupContent()}
        </>
      ) : null}
    />
  );
};

export default ActionTypeItem;
