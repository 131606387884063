import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { map, filter } from "lodash";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchOrgUnitsForSelector } from "app/main/orgUnits/actions/orgUnit.actions";
import { getOrgUnitsForOrgUnitsSelector, isOrgUnitsSelectorLoading } from "app/main/orgUnits/reducers/orgUnit.reducers";

export default ({
  label,
  orgUnitType,
  disabled,
  ...other
}) => {
  const dispatch = useDispatch();
  const loading = useSelector(isOrgUnitsSelectorLoading);
  const orgUnits = useSelector(getOrgUnitsForOrgUnitsSelector);

  const options = filter(orgUnits, x => {
    if (orgUnitType && x.orgUnitType !== orgUnitType) {
      return false;
    }

    return true;
  });

  React.useEffect(() => {
    dispatch(fetchOrgUnitsForSelector());
  }, [dispatch]);

  if (loading) return <AutoCompleteLoading label={label} />;

  return (
    <AutoComplete
      label={label}
      disabled={disabled}
      options={map(options, x => ({ label: x.name, value: x.id }))}
      filterSelectedOptions
      getOptionSelected={(option, val) => option.value === val.value}
      {...other}
    />
  );
};
