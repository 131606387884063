import React from "react";
import { Divider } from "@material-ui/core";
import { map } from "lodash";
import { useSelector } from "react-redux";

import { ContextItem } from "components/items/default-item";
import normaliseDateTime from "app/main/patients/helpers/normalise-date-time";
import PatientContextListItem, { ItemContent } from "app/main/patients/components/patient-context-list-item";
import { DateItem } from "app/main/patients/components/patient-components";
import { getReferralWorkflowStateSettings } from "app/auth/store/reducers/system-configuration";
import useTriageFieldSettings from "app/main/referrals/hooks/useTriageFieldSettings";
import PatientReferralsListItem from "app/main/patients/referrals/components/patient-referrals-item";
import AppointmentStatus from "./appointment-status";

const AppointmentListItem = ({
  assignedBy,
  type,
  classes,
  item: {
   id,
   patientId,
   startDateTimeUtc,
   status,
   locationDisplayValue,
   details,
  },
  appointmentReferrals,
  orgUnitId,
  ...other
}) => {
  const appointmentIcon = "events";
  const referralWorkflowSettings = useSelector(getReferralWorkflowStateSettings);
  const appointmentDate = normaliseDateTime(startDateTimeUtc, true);
  const { fieldLabels: triageFieldLabels } = useTriageFieldSettings(orgUnitId);

  const previewTitle = () => {
    return "Appointment";
  };

  const appointmentItem = (
    appointmentLocation,
    appointmentStatus,
    appointmentDate,
    appointmentStatusAssignedBy
  ) => {
    return (
      <ItemContent
        title={appointmentLocation}
        icon={appointmentIcon}
        status={
          <AppointmentStatus status={appointmentStatus}/>
        }
        content={
          <DateItem date={appointmentDate} name={appointmentStatusAssignedBy}/>
        }
      />
    );
  };

  const appointmentPreview = (
    appointmentStatus,
    appointmentDateTime,
    appointmentLocation,
    appointmentDetails
  ) => {
    return (
      <>
        <AppointmentStatus status={appointmentStatus}/>
        <ContextItem icon="date_range" content={appointmentDateTime} />
        <ContextItem icon="location_on" content={appointmentLocation} />
        <ContextItem content={appointmentDetails} />
      </>
    );
  };

  const renderAppointmentReferrals = (appointmentReferrals, orgUnitId) => {
    return (
      map(appointmentReferrals, (referral, index) => (
        <PatientReferralsListItem
          key={referral.id}
          orgUnitId={referral.orgUnitId}
          isFirst={index === 0}
          referral={referral}
          patientId={patientId}
          fromUrl={`/referralWorklist/patient/${patientId}`}
          referralWorkflowSettings={referralWorkflowSettings}
          triageFieldLabels={triageFieldLabels}
          disabled={referral.orgUnitId !== orgUnitId}
        />
      ))
    );
  }

  return (
    <>
      <PatientContextListItem
        key={id}
        title={previewTitle()}
        {...other}

        renderItemContent={() => (
          appointmentItem(locationDisplayValue, status, appointmentDate, assignedBy)
        )}

        renderPreviewContent={() => (
          appointmentPreview(status, appointmentDate.dateTime, locationDisplayValue, details)
        )}
      >
        {appointmentReferrals &&
          renderAppointmentReferrals(appointmentReferrals, orgUnitId)
        }
      </PatientContextListItem>
      <Divider />
    </>
  );
};

export default AppointmentListItem;
