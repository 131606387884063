import history from "app/browserHistory";
import { ApiActions } from "middleware/call-api";
import { getBaseUrl, getDefaultRoute } from "utils/get-environment-variables";
import { getSearchParameters, defaultPageNumber, defaultPageSize } from "app/main/applicationWorklist/reducers/applicationWorklist.reducers";
import { searchApplications } from "app/main/applicationWorklist/actions/applicationWorklist.actions";
import { setCurrentApplication } from "app/main/applications/actions/applications.actions";
import { msalInstance } from "app/auth/msalConfig";
import { checkPermission } from "app/auth/store/reducers/user.reducer";
import { UpdateOrgUnitFormFieldConfigurations } from "app/main/orgUnits/actions/orgUnit.actions";
import { clearLastSearchParams } from "app/main/referralWorklist/actions/referralWorklist.actions";
import { ActionTypes, isCurrentUserLoading, isCurrentUserLoaded } from "../reducers/user.reducer";

export function setUserData(user) {
  return dispatch => {
    /*
      Set User Data
    */
    dispatch({
      type: ActionTypes.LOADED_USER_DATA,
      payload: user,
    });
  };
}

export const loadCurrentUser = (forceLoad = false) => async dispatch => {
  const currentUserResponse = await dispatch({
    [ApiActions.FETCH_FROM_API]: {
      endpoint: "/users/currentUser",
      bailout: state => isCurrentUserLoading(state) || (!forceLoad && isCurrentUserLoaded(state)),
      entityType: "USER_DATA",
    },
  });

  if (!currentUserResponse.error) {
    dispatch(UpdateOrgUnitFormFieldConfigurations(currentUserResponse.payload.allOrgUnitFormFieldConfigurations));
  }

  return currentUserResponse;
};

export const recordUserLogout = () => ({
  [ApiActions.POST_TO_API]: {
    endpoint: "/users/logout",
    entityType: "USER_DATA",
  },
});

function clearUserData() {
  const baseUrl = getBaseUrl();

  history.push({
    pathname: baseUrl,
  });

  return dispatch => {
    dispatch({
      type: ActionTypes.USER_LOGGED_OUT,
    });
  };
}

/**
 * Logout
 */
export function logoutUser() {
  return (dispatch, getState) => {
    const { user } = getState().auth;

    if (user.role === "guest") {
      return;
    }

    sessionStorage.clear();
    msalInstance.logoutRedirect({
      postLogoutRedirectUri: "/",
    });

    clearUserData();
  };
}

export const changeSignedInOrgUnit = orgUnitId => async (dispatch, getState) => {
  const changeSignedInOrgUnitRes = await dispatch({
    [ApiActions.POST_TO_API]: {
      endpoint: `/siteConfiguration/ChangeCurrentSite/${orgUnitId}`,
      types: ["CHANGING_CURRENT_ORG_UNIT", ActionTypes.CHANGED_CURRENT_ORG_UNIT, "ERROR_CHANGING_CURRENT_ORG_UNIT"],
    },
  });

  await dispatch(clearLastSearchParams());

  if (changeSignedInOrgUnitRes.error !== true) {
    if (checkPermission(getState(), "IPAWorklistView")) {
      // refresh existing search result applications.Clear current application and history
      const searchParameters = getSearchParameters(getState());
      dispatch(searchApplications({ ...searchParameters, status: [], orgUnitId }, defaultPageNumber, defaultPageSize, true));
      dispatch(setCurrentApplication(null));
    }
    dispatch(UpdateOrgUnitFormFieldConfigurations(changeSignedInOrgUnitRes.payload.allOrgUnitFormFieldConfigurations));
    history.push({ pathname: getDefaultRoute(), search: "", state: { forceLoad: true, referralId: null } });
  }

  return changeSignedInOrgUnitRes;
};

export const setAuthErrorMessage = message => ({
  type: ActionTypes.AUTH_ERROR,
  payload: message,
});

export const UserReferralFormProfile = referralFormProfile => ({
  type: ActionTypes.UPDATE_REFERRAL_FORM_PROFILE,
  payload: referralFormProfile,
});
