import React, { useEffect, useRef } from "react";
import { Checkbox as NativeCheckbox, FormControlLabel, FormHelperText } from "@material-ui/core";
import { get, isEmpty } from "lodash";

import FormContext from "components/FormContext";
import BaseFieldInput from "components/inputs/components/base-input-component";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
  errorText: {
    color: theme.palette.error.main,
  },
}));

export const Checkbox = ({
  checked,
  onChange,
  disabled,
  label,
  helperText,
  indeterminate,
  required,
  ...other
}) => (
  <FormContext.Consumer>
    {({ formDisabled }) => (
      <BaseFieldInput
        showHelperText={!isEmpty(helperText)}
        helperText={helperText}
        isCheckBox
        {...other}
      >
        {/*
          Plain <div> elements shrink to fit their contents if they don't have a specified width.
          We use this feature to break the chain of width inheritance. As a result:
          - The clickable area of the Checkbox component will be limited to the label and checkbox only
          - It will not extend to the entire width of the parent container
        */}
        <div>
          <FormControlLabel
            disabled={formDisabled || disabled}
            control={(
              <NativeCheckbox
                checked={checked === "indeterminate" ? false : checked}
                onChange={onChange}
                className="checkbox-control"
                indeterminate={checked === "indeterminate"}
              />
            )}
            label={(
              <>
                {/* Conditionally render the star symbol in the label if the required prop is true. */}
                {label} {required && <span style={{ color: "red" }}>*</span>}
              </>
            )}
          />
        </div>
      </BaseFieldInput>
    )}
  </FormContext.Consumer>
);

const CheckboxInput = ({
  field: { name, value },
  form: { errors, touched, setFieldValue },
  onChange,
  showHelperText,
  required,
  ...other
}) => {
  const inputValue = value === "true" ? true : value;
  const error = get(errors, name, null);
  const isTouched = get(touched, name, false);

  return (
    <Checkbox
      checked={inputValue || false}
      error={!isEmpty(error)}
      helperText={showHelperText && isTouched && error}
      onChange={val => {
        setFieldValue(name, val.target.checked);
        if (onChange) {
          onChange(val.target.checked, setFieldValue);
        }
      }}
      required={required}
      {...other}
    />
  );
};

export const CheckboxInputValidationAware = ({
  field: { name, value },
  form: { errors, touched, setFieldValue, setFieldTouched, submitCount },
  onChange,
  showHelperText,
  required,
  label,
  disabled,
  ...other
}) => {
  const classes = useStyles();
  const checkboxRef = useRef(null);

  const inputValue = value === "true" ? true : value;
  const error = get(errors, name, null);
  const isTouched = get(touched, name, false);

  useEffect(() => {
    if (error && (isTouched || submitCount > 0) && checkboxRef.current) {
      checkboxRef.current.focus();
    }
  }, [error, isTouched, submitCount]);

  const handleChange = event => {
    const newValue = event.target.checked;
    setFieldValue(name, newValue);
    if (onChange) {
      onChange(newValue, setFieldValue);
    }
  };

  const handleBlur = () => {
    setFieldTouched(name, true);
  };

  const showError = showHelperText && (isTouched || submitCount > 0) && !isEmpty(error);

  const errorMessage = typeof error === "string" ? error : "This field is required";

  return (
    <BaseFieldInput
      isCheckBox
      {...other}
    >
      <FormControlLabel
        label="" // pass an empty string to avoid eslint warning
        ref={checkboxRef}
        control={(
          <Checkbox
            checked={inputValue || false}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabled}
            required={required}
            label={label}
          />
        )}
      />
      {showError && (
        <FormHelperText className={classes.errorText}>
          {errorMessage}
        </FormHelperText>
      )}
    </BaseFieldInput>
  );
};

export default CheckboxInput;
