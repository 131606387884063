import React from "react";
import { connect } from "react-redux";
import { map, size, includes, isEmpty, filter, isObject, startCase, capitalize } from "lodash";
import { Field } from "formik";
import { HeartPulseIcon, CircleIconP } from "helpers/icon-finder";

import { filterOptions, getPresetFilterParams, defaultFilterOption } from "app/main/worklist/reducers/worklist.reducers";
import { setWorkListFilter } from "app/main/worklist/actions/worklist.actions";
import DefaultSelectionFilter from "components/items/default-selection-filter";
import AdvancedFilter, { FilterSection, FilterForm, FilterTags } from "components/items/advanced-filter";
import CheckboxInput from "components/inputs/checkbox";

import SelectInput from "components/inputs/select-input";
import DateRange from "components/inputs/date-range";
import { UserSelector, MultipleCheckbox } from "components/inputs";
import formatFilterDate from "helpers/format-filter-date";
import RadioButtonsGroup from "components/inputs/radio-buttons-group";
import getEnrolmentStatus from "app/main/patients/helpers/get-enrolment-status";
import ChipInput from "components/inputs/chip-input";
import { AutoComplete } from "components/inputs/auto-complete";
import withPermissions from "permissions/withPermissions";
import getCandidateDiseaseStateDescription from "app/main/candidates/helpers/get-candidate-disease-state-description";

const diseaseStateOptions = [
  {
    value: "HF",
    label: "Heart Failure",
  },
  {
    value: "IHD",
    label: "Ischemic Heart Disease",
  },
  {
    value: "DM",
    label: "Diabetes Mellitus",
  },
  {
    value: "COPD",
    label: "COPD",
  },
];

const doNotContactAgainChoices = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const eligibleChoices = [
  { value: false, label: "Yes" },
  { value: true, label: "No" },
];

class WorklistAdvancedFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTags: null,
    };
  }

  componentDidMount() {
    this.updateSearchTags(this.props.searchParams);
  }

  componentDidUpdate(prevProps) {
    // Added to update when enrolmentStatusChoices propagate on parent, bind to advanced filter props instead?
    if (this.props.enrolmentStatusChoices && prevProps.enrolmentStatusChoices !== this.props.enrolmentStatusChoices) {
      this.updateSearchTags(this.props.searchParams);
    }
  }

  updateSearchTags = newParams => {
    const { enrolmentStatusChoices } = this.props;
    const {
      enrolmentStatus,
      diseaseState,
      encounterStartDate,
      encounterEndDate,
      appointmentStartDate,
      appointmentEndDate,
      ineligibleProduct,
      assignedToUser,
      assignedToMe,
      overdue,
      postcode,
      doNotContactAgain,
      enrolmentType,
    } = newParams;

    let enrolmentStatusTags = null;
    if (!isEmpty(enrolmentStatus) && size(enrolmentStatus) < size(enrolmentStatusChoices)) {
      enrolmentStatusTags = `${map(enrolmentStatus, tag => ` ${getEnrolmentStatus(tag).label}`)}`;
    }
    const allEnrolmentStatus = size(enrolmentStatus) === size(enrolmentStatusChoices);

    const diseaseStateObjects = map(diseaseState, disease => ({
      label: getCandidateDiseaseStateDescription(disease) || disease,
      value: disease,
    }));

    const postcodeObjects = map(postcode, code => ({
      label: code,
      value: code,
    }));

    let enrolmentTypeTag = enrolmentType;

    if (isObject(enrolmentTypeTag)) {
      enrolmentTypeTag = enrolmentTypeTag.label;
    }

    const searchTags = [
      enrolmentType && startCase(enrolmentTypeTag),
      diseaseStateObjects !== null && diseaseStateObjects.length && `Disease state: ${map(diseaseStateObjects, state => ` ${state.label}`)}`,
      ineligibleProduct && "Ineligible",
      assignedToMe && "Assigned to me",
      assignedToUser && !assignedToMe && `Assigned to ${assignedToUser.label}`,
      enrolmentStatusTags,
      allEnrolmentStatus && "All enrolment statuses",
      postcodeObjects && `${map(postcodeObjects, code => ` ${code.label}`)}`,
      overdue && "Overdue appointments",
      (encounterStartDate && encounterEndDate)
        && `Encounters from ${formatFilterDate(encounterStartDate, "DD-MMM-YYYY")} to ${formatFilterDate(encounterEndDate, "DD-MMM-YYYY")}`,
      (appointmentStartDate && appointmentEndDate)
        && `Appointments from ${formatFilterDate(appointmentStartDate[0], "DD-MMM-YYYY")} to ${formatFilterDate(appointmentEndDate[1], "DD-MMM-YYYY")}`,
      doNotContactAgain && "Do not contact again",
    ];

    this.setState({ searchTags });
  }

  handleSubmit = ({ patientFilter }) => {
    const { searchParams } = this.props;
    const {
      assignedToUser,
      assignedToMe,
      ineligibleProduct,
      diseaseState,
      overdue,
      doNotContactAgain,
      allEnrolmentStatus,
      enrolmentStatus,
      postcode,
      encounterStartDate,
      encounterEndDate,
      appointmentStartDate,
      appointmentEndDate,
      enrolmentType,
    } = patientFilter;

    let postcodeValue = postcode;
    if (postcodeValue) {
      postcodeValue = map(postcode, code => code.value);
    }

    let diseaseStateValue = diseaseState;
    if (diseaseStateValue) {
      diseaseStateValue = map(diseaseState, state => state.value);
    }

    let enrolmentTypeValue = enrolmentType;
    if (isObject(enrolmentTypeValue)) {
      enrolmentTypeValue = enrolmentTypeValue.value;
    }

    const newPatientFilter = {
      allEnrolmentStatus,
      enrolmentStatus,
      encounterStartDate: formatFilterDate(encounterStartDate),
      encounterEndDate: formatFilterDate(encounterEndDate),
      appointmentStartDate: formatFilterDate(appointmentStartDate),
      appointmentEndDate: formatFilterDate(appointmentEndDate),
      assignedToUser,
      assignedToMe,
      ineligibleProduct,
      diseaseState: diseaseStateValue,
      postcode: postcodeValue,
      overdue,
      doNotContactAgain,
      enrolmentType: enrolmentTypeValue,
    };

    this.updateSearchTags(newPatientFilter);
    this.props.setWorkListFilter("advancedFilter");
    const updatedFilter = { ...searchParams, ...newPatientFilter };

    this.props.onSearch(updatedFilter, null, null, true);
  }

  handleReset = () => {
    this.props.setWorkListFilter(defaultFilterOption);
    const defaultFilter = getPresetFilterParams(defaultFilterOption);
    this.props.onSearch(defaultFilter, null, null, true);
    this.updateSearchTags(defaultFilter);
    this.setState({ searchTags: null });
  }

  onSearchText = searchTerm => {
    this.props.onSearch({ ...this.props.searchParams, name: searchTerm }, null, null, true);
  }

  onUpdateFilter = filterValue => {
    this.props.setWorkListFilter(filterValue);
    // when update filter we should keep existing search string
    const newParams = {
      ...getPresetFilterParams(filterValue),
      name: this.props.searchParams.name,
    };
    this.props.onSearch(newParams, null, null, true);
    this.props.onUpdateForceLoad(false);
    this.updateSearchTags(newParams);
  }

  secureFilterOptions = () => {
    if (this.props.hasPermissionPatientsMaintain) {
      return filterOptions;
    }
    const allowedOptions = ["myCurrentClients", "myCandidatesToContact", "WelfareCheck", "advancedFilter", "ineligible"];
    return filter(filterOptions, option => includes(allowedOptions, option.value));
  }

  render() {
    const { searchParams, presetFilter, initialValues, currentUser, enrolmentStatusChoices, renderFilterInfo, enrolmentTypeChoices } = this.props;
    const { searchTags } = this.state;
    const options = this.secureFilterOptions();
    initialValues.patientFilter.diseaseState = map(initialValues.patientFilter.diseaseState, disease => ({
      label: getCandidateDiseaseStateDescription(disease) || disease,
      value: disease,
    }));
    initialValues.patientFilter.postcode = map(initialValues.patientFilter.postcode, code => ({
      label: code,
      value: code,
    }));

    const enrolmentStatus = map(enrolmentStatusChoices, x => ({ label: capitalize(getEnrolmentStatus(x).label), value: x }));

    return (
      <AdvancedFilter
        renderFilterInfo={renderFilterInfo}
        onSearch={searchText => this.onSearchText(searchText)}
        searchValue={searchParams.name}
        renderFilterTags={(
          <FilterTags tags={searchTags} />
        )}
        renderPresetFilter={({ openAdvancedFilter }) => (
          <div className="flex-1 mb-8">
            <DefaultSelectionFilter
              filterValue={presetFilter}
              onChange={
                filterValue => {
                  if (filterValue && presetFilter !== filterValue) {
                    if (filterValue === "advancedFilter") {
                      openAdvancedFilter();
                    } else {
                      this.onUpdateFilter(filterValue);
                    }
                  }
                }
              }
              options={options}
            />
          </div>
        )}
        content={onClose => (
          <FilterForm
            initialValues={initialValues}
            onSubmit={this.handleSubmit}
            onClose={onClose}
            onReset={this.handleReset}
            filters={({ setFieldValue }) => (
              <div className="advanced-filter-sections">
                <FilterSection label="Encounters between" withDivider>
                  <Field
                    name="patientFilter.encounterDateRange"
                    component={DateRange}
                    definedRanges={["lastWeek", "thisMonth"]}
                    onChange={value => {
                      if (value) {
                        setFieldValue("patientFilter.encounterStartDate", value[0]);
                        setFieldValue("patientFilter.encounterEndDate", value[1]);
                      }
                    }}
                  />
                </FilterSection>
                <FilterSection label="Appointments between" withDivider>
                  <Field
                    name="patientFilter.appointmentDateRange"
                    component={DateRange}
                    definedRanges={["nextWeek", "nextMonth"]}
                    onChange={value => {
                      if (value) {
                        setFieldValue("patientFilter.appointmentStartDate", value[0]);
                        setFieldValue("patientFilter.appointmentEndDate", value[1]);
                      }
                    }}
                  />
                  <Field
                    name="patientFilter.overdue"
                    label="Overdue appointments"
                    component={CheckboxInput}
                  />
                </FilterSection>
                <FilterSection withDivider>
                  <Field
                    name="patientFilter.postcode"
                    label="Postcodes"
                    icon="map-marker"
                    multiline
                    textarea
                    component={ChipInput}
                  />
                </FilterSection>
                <FilterSection label="Eligible" withDivider>
                  <Field
                    name="patientFilter.ineligibleProduct"
                    component={RadioButtonsGroup}
                    isBoolean
                    choices={eligibleChoices}
                    displayRow
                    allowUnCheck
                  />
                </FilterSection>

                <FilterSection withDivider>
                  <Field
                    name="patientFilter.diseaseState"
                    label="Disease states"
                    icon={<HeartPulseIcon />}
                    component={AutoComplete}
                    options={diseaseStateOptions}
                    multiline
                    textarea
                    isCreatable
                    isMulti
                    createLabel="Search: "
                  />
                </FilterSection>
                <FilterSection withDivider>
                  <Field
                    name="patientFilter.enrolmentType"
                    component={SelectInput}
                    icon={<CircleIconP />}
                    options={enrolmentTypeChoices}
                    label="Enrolled Program"
                  />
                </FilterSection>
                <FilterSection label="Enrolment Status" withDivider>
                  <Field
                    name="patientFilter.enrolmentStatus"
                    component={MultipleCheckbox}
                    options={enrolmentStatus}
                  />
                </FilterSection>
                <FilterSection label="Marked as Do Not Contact" withDivider>
                  <Field
                    name="patientFilter.doNotContactAgain"
                    component={RadioButtonsGroup}
                    isBoolean
                    choices={doNotContactAgainChoices}
                    displayRow
                    allowUnCheck
                  />
                </FilterSection>
                <FilterSection>
                  <Field
                    name="patientFilter.assignedToUser"
                    component={UserSelector}
                    label="Assigned To"
                    includeInactive
                    onChange={selectedUser => {
                      if (selectedUser?.value === currentUser.userId) {
                        setFieldValue("patientFilter.assignedToMe", true);
                      } else {
                        setFieldValue("patientFilter.assignedToMe", false);
                      }
                    }}
                  />
                  <Field
                    name="patientFilter.assignedToMe"
                    component={CheckboxInput}
                    label="Assigned To Me"
                    showIcon={false}
                    onChange={value => {
                      if (value === true) {
                        setFieldValue("patientFilter.assignedToUser", { value: currentUser.userId, label: currentUser.name });
                      } else {
                        setFieldValue("patientFilter.assignedToUser", null);
                      }
                    }}
                  />
                </FilterSection>
              </div>
            )}
          />
        )}
      />
    );
  }
}

export default withPermissions("PatientsMaintain")(connect(null,
  { setWorkListFilter })(WorklistAdvancedFilter));
