import { getUser } from "app/auth/store/reducers/user.reducer";
import { ActionTypes } from "../reducers/referrals.reducers";

export const setReferralStatusUpdated = ({ detail }) => async (dispatch, getState) => {
  const actionUserId = detail[0];
  const updateDetail = detail[1];
  const currentUser = getUser(getState());

  await dispatch({
    type: ActionTypes.REFERRAL_STATUS_CHANGED_BY_OTHER_USER,
    detail: {
      ...updateDetail,
      updatedByOtherUser: actionUserId !== currentUser.userId,
    },
  });
};
