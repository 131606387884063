import React from "react";
import { Grid } from "@material-ui/core";
import { fade } from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import withPermissions from "permissions/withPermissions";
import clsx from "clsx";
import { includes, isEmpty, find } from "lodash";
import { blue } from "@material-ui/core/colors";

import ReferralCategoryLabel from "app/main/referrals/components/referral-category-label";
import DefaultButton from "components/items/default-button";
import AcceptReferralForm from "app/main/referrals/components/accept-referral-form/index";
import RejectReferralForm from "app/main/referrals/components/reject-referral-form/index";
import CompleteReferralForm from "app/main/referrals/components/complete-referral-form";
import ArchiveReferralForm from "app/main/referrals/components/archive-referral-form";
import UndoReferralDialog from "app/main/referrals/components/undo-referral-dialog";
import RequestReferralDialog from "app/main/referrals/components/request-referral-dialog";
import DraftReferralDialog from "app/main/referrals/components/draft-referral-dialog";
import ProcessReferralDialog from "app/main/referrals/components/process-referral-dialog";
import UndoAcceptedBookedDialog from "app/main/referrals/components/undo-accepted-booked-dialog";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { fetchReferral } from "app/main/referrals/actions/referrals.actions";
import ReferralRequestMoreInfoForm from "app/main/referrals/components/referral-request-more-info-form";
import TextLink from "components/items/text-link";
import ItemStatus from "components/items/item-status";
import { getReferralWorkflowStateSettings, getReferralActionTypeSettings, getAllowUndoActionStates } from "app/auth/store/reducers/system-configuration";
import useOpenConfigForm from "hooks/use-open-config-form";
import ReferralActionWarningMessage from "app/main/referrals/utils/referral-action-warning-message";
import { getWarningMessages } from "../helpers/get-referral-action-warning-messages";

import { rejectedWorkflowState, acceptedWorkflowState, archivedWorkflowState, completedWorkflowState, draftWorkflowState, acceptedBookedWorkflowState, requestMoreInformationWorkflowState, processingWorkflowState } from "../helpers/workflow-state-names";
import { draftActionType, processActionType, acceptActionType, rejectActionType, completeActionType, archiveActionType, requestActionType, requestMoreInformationActionType } from "../helpers/action-type-names";

const ReferralWorkflowPanel = ({
  referral,
  orgUnitId,
  hasPermissionReferralsDraft,
  hasPermissionReferralsAccept,
  hasPermissionReferralsReject,
  hasPermissionReferralsProcess,
}) => {
  const dispatch = useDispatch();
  const referralWorkflowSettings = useSelector(getReferralWorkflowStateSettings);
  const referralActionTypeSettings = useSelector(getReferralActionTypeSettings);
  const allowUndoActionStates = useSelector(getAllowUndoActionStates);

  const onSucceed = reload => {
    dispatch(closeDialog());
    if (reload) {
      dispatch(fetchReferral(orgUnitId, referral.id));
    }
  };

  const getReferralActionSettings = actionType => find(referralActionTypeSettings, x => x.actionType === actionType);

  const getReferralActionLabel = actionType => getReferralActionSettings(actionType)?.nameSingle ?? actionType;

  const getReferralActionedName = actionType => getReferralActionSettings(actionType)?.actionedName ?? actionType;

  const getReferralActionFormTitle = actionType => getReferralActionSettings(actionType)?.formTitle ?? actionType;

  const getWorkflowStateSettings = stateName => find(referralWorkflowSettings, x => x.workflowState === stateName) || { icon: "lens", color: blue[600], label: "" };

  const getActionTypeSettings = actionType => find(referralActionTypeSettings, x => x.actionType === actionType) || { icon: "lens", color: blue[600], label: "" };

  const actionButton = ({ actionType, settings, ...other }) => <DefaultButton icon={settings?.icon} style={{ backgroundColor: settings?.color }} label={getReferralActionLabel(actionType)} {...other} />;

  const openActionForm = ({ actionType, component: FormComponent, submitLabel, reloadReferral, ...other }) => dispatch(openDialog({
    children: (
      <FormComponent
        referralId={referral?.id}
        orgUnitId={orgUnitId}
        onSucceed={() => onSucceed(reloadReferral)}
        title={getReferralActionFormTitle(actionType)}
        submitLabel={submitLabel ?? "Submit"}
        warningMessages={getWarningMessages(getReferralActionSettings(actionType), referral?.referralDocument?.referralDocumentType)}
        {...other}
      />
    ),
  }));

  const handleAcceptPopup = () => {
    ReferralActionWarningMessage(orgUnitId, referral.id, "Accept");
    openActionForm({ actionType: acceptActionType, component: AcceptReferralForm, submitLabel: "Save & Accept", referral, reloadReferral: true });
  };

  const handleRejectPopup = () => openActionForm({ actionType: rejectActionType, component: RejectReferralForm, referral, reloadReferral: true });

  const handleCompletePopup = () => openActionForm({ actionType: completeActionType, component: CompleteReferralForm });

  const handleArchivePopup = () => openActionForm({ actionType: archiveActionType, component: ArchiveReferralForm });

  // const handleEditReferral = () => dispatch(openDialog({
  //   maxWidth: "xl",
  //   children: (
  //     <AddReferralForm
  //       title="Edit Referral"
  //       currentReferral={referral}
  //       onSucceed={() => onSucceed(true)}
  //       warningMessage={getReferralActionSettings(requestActionType)?.warningMessage}
  //       isEdit
  //     />
  //   ),
  // }));

  const handleRequestPopup = () => dispatch(openDialog({
    children: (
      <RequestReferralDialog
        onSucceed={() => onSucceed(true)}
        warningMessages={getWarningMessages(getReferralActionSettings(requestActionType), referral?.referralDocument.referralDocumentType)}
        referralId={referral.id}
        orgUnitId={orgUnitId}
        isUndo={referral.referralStatus !== processingWorkflowState}
      />
    ),
  }));

  const handleProcessPopup = () => dispatch(openDialog({
    children: (
      <ProcessReferralDialog
        onSucceed={() => onSucceed(true)}
        warningMessages={getWarningMessages(getReferralActionSettings(requestActionType), referral?.referralDocument.referralDocumentType)}
        referralId={referral.id}
        orgUnitId={orgUnitId}
        isUndo={referral.referralStatus !== draftWorkflowState}
        actionLabel={getReferralActionLabel(processActionType)}
        title={getReferralActionFormTitle(processActionType)}
      />
    ),
  }));

  const handleDraftPopup = () => dispatch(openDialog({
    children: (
      <DraftReferralDialog
        onSucceed={() => onSucceed(true)}
        warningMessages={getWarningMessages(getReferralActionSettings(draftActionType), referral?.referralDocument.referralDocumentType)}
        referralId={referral.id}
        orgUnitId={orgUnitId}
      />
    ),
  }));

  const handleUndoPopup = () => dispatch(openDialog({
    children: (
      <UndoReferralDialog
        onSucceed={() => onSucceed(true)}
        referralWorkflowSettings={referralWorkflowSettings}
        referralActionTypeSettings={referralActionTypeSettings}
        referral={referral}
        orgUnitId={orgUnitId}
      />
    ),
  }));

  const handleUndoAcceptedBooked = () => dispatch(openDialog({
    children: (
      <UndoAcceptedBookedDialog
        onSucceed={() => onSucceed(true)}
        referralWorkflowSettings={referralWorkflowSettings}
        referralActionTypeSettings={referralActionTypeSettings}
        referralId={referral.id}
        referralDocumentType={referral?.referralDocument.referralDocumentType}
        orgUnitId={orgUnitId}
      />
    ),
  }));

  const { openConfigForm, loadingConfigurations } = useOpenConfigForm(referral?.orgUnitId, "TriageForm", handleAcceptPopup);

  const { openConfigForm: openRejectOpenConfig, loadingConfigurations: loadingRejectConfigurations } = useOpenConfigForm(referral?.orgUnitId, "RejectForm", handleRejectPopup);

  const { referralStatus, availableActions, activities, permissions, canAcceptOrReject } = referral;
  let referralStatusConfig = getWorkflowStateSettings(referralStatus);

  const enableDraftToProcess = hasPermissionReferralsProcess && includes(availableActions, processActionType) && permissions.canProcess;

  if (referralStatus === archivedWorkflowState) {
    referralStatusConfig = getWorkflowStateSettings(rejectedWorkflowState);
  } else if (referralStatus === completedWorkflowState) {
    // there will be 2 accepted workflow state
    // 1. accepted (waiting)
    // 2. accepted (booked)
    // we will need to find out the lasted action type from activities
    const latestAcceptedAction = find(activities?.action, x => x.actionType === "AcceptBooked" || x.actionType === "Accepted");
    // TODO this need to be improved
    referralStatusConfig = getWorkflowStateSettings(latestAcceptedAction?.actionType === "AcceptBooked" ? acceptedBookedWorkflowState : acceptedWorkflowState);
  }

  let content = () => null;
  const accept = getActionTypeSettings(acceptActionType);
  const reject = getActionTypeSettings(rejectActionType);
  const complete = getActionTypeSettings(completeActionType);
  const archive = getActionTypeSettings(archiveActionType);

  const ActionButtonGroup = () => {
    if (hasPermissionReferralsAccept || hasPermissionReferralsReject || permissions.canRequest || hasPermissionReferralsDraft || hasPermissionReferralsProcess) {
      return (
        <>
          <div className="flex items-center mt-8">
            {hasPermissionReferralsAccept && includes(availableActions, acceptActionType) && permissions.canAccept && canAcceptOrReject
            && actionButton({ actionType: acceptActionType, settings: accept, onClick: openConfigForm, loading: loadingConfigurations })}
            {hasPermissionReferralsReject && includes(availableActions, rejectActionType) && permissions.canReject && canAcceptOrReject
              && actionButton({ actionType: rejectActionType,
                settings: reject,
                onClick: openRejectOpenConfig,
                className: clsx(hasPermissionReferralsAccept && "ml-16"),
                loading: loadingRejectConfigurations })}
          </div>
          <div className="flex justify-center mt-8">
            {permissions.canRequest && includes(availableActions, requestActionType)
            && (
            <TextLink
              content={`Mark as ${getReferralActionedName(requestActionType)}`}
              variant="caption"
              fontSize="small"
              onClick={handleRequestPopup}
            />
            )}
          </div>
          <div className="flex items-center mt-8">
            {hasPermissionReferralsDraft && includes(availableActions, draftActionType) && permissions.canDraft
            && <TextLink content={`Mark as ${getReferralActionedName(draftActionType)}`} variant="caption" fontSize="small" onClick={handleDraftPopup} />}
          </div>
          <div className="flex items-center mt-8">
            { enableDraftToProcess
            && <TextLink content={`Mark as ${getReferralActionedName(processActionType)}`} variant="caption" fontSize="small" onClick={handleProcessPopup} />}
          </div>
        </>
      );
    }
    return null;
  };

  if (includes(availableActions, requestMoreInformationActionType)) {
    content = () => (
      <>
        <ActionButtonGroup />
        {permissions.canRequestMoreInformation && referral.referralStatus !== requestMoreInformationWorkflowState
        && (
          <div className="mt-8">
            <TextLink
              content={getReferralActionLabel(requestMoreInformationActionType)}
              variant="caption"
              fontSize="small"
              onClick={() => openActionForm({ actionType: requestMoreInformationActionType, component: ReferralRequestMoreInfoForm })}
            />
          </div>
        )}
      </>
    );
  } else if (includes(availableActions, completeActionType)) {
    content = () => (
      <>
        <ReferralCategoryLabel triageCategory={referral.triageCategory} isUrgent={referral.isUrgent} orgUnitId={referral.orgUnitId} />
        {permissions.canComplete && actionButton({ actionType: completeActionType, settings: complete, onClick: handleCompletePopup, className: "mt-8" })}
      </>
    );
  } else if (referralStatus === rejectedWorkflowState && includes(availableActions, archiveActionType) && permissions.canArchive) {
    content = () => (
      actionButton({ actionType: archiveActionType, settings: archive, onClick: handleArchivePopup, className: "mt-8" })
    );
  } else if (isEmpty(availableActions) && referralStatus === completedWorkflowState) {
    content = () => (
      <ItemStatus icon={complete.icon} label={getReferralActionedName(completeActionType).toUpperCase()} color={complete.color} />
    );
  } else if (isEmpty(availableActions) && referralStatus === archivedWorkflowState) {
    content = () => (
      <ItemStatus icon={archive.icon} label={getReferralActionedName(archiveActionType).toUpperCase()} color={archive.color} />
    );
  } else {
    content = () => <ActionButtonGroup />;
  }

  // Render Undo button when user has the canUndo permission and the referral status is in a state that allows it
  const renderUndoButton = permissions.canUndo && includes(allowUndoActionStates, referralStatus);

  // Hack for BHS workflow to override the label for the Closed undo option
  const undoOptionLabel = referralStatus === completedWorkflowState && getReferralActionLabel(completeActionType) === "Close" && accept?.actionedName ? `Undo to ${accept.actionedName}` : "UNDO";

  const undoAcceptedBooked = referralStatus === acceptedBookedWorkflowState && renderUndoButton;

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      className="px-16 py-8 h-full"
      style={{ backgroundColor: fade(referralStatusConfig.color, 0.1) }}
    >
      {referralStatus !== completedWorkflowState && <ItemStatus icon={referralStatusConfig.icon} label={referralStatusConfig.label.toUpperCase()} color={referralStatusConfig.color} />}
      {/* render content base on status */}
      {content()}
      {undoAcceptedBooked && (
        <div className="mt-8">
          <TextLink
            content={undoOptionLabel}
            variant="caption"
            fontSize="small"
            onClick={handleUndoAcceptedBooked}
          />
        </div>
      )}
      {!undoAcceptedBooked && renderUndoButton && (
        <div className="mt-8">
          <TextLink
            content={undoOptionLabel}
            variant="caption"
            fontSize="small"
            onClick={handleUndoPopup}
          />
        </div>
      )}
    </Grid>
  );
};

export default withPermissions(
  "ReferralsDraft",
  "ReferralsAccept",
  "ReferralsReject",
  "ReferralsProcess",
)(ReferralWorkflowPanel);
