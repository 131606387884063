import React from "react";
import Markdown from "react-markdown";
import { makeStyles } from "@material-ui/core/styles";
import CustomLinkRenderer from "./custom-link-renderer";

const useStyles = makeStyles(theme => ({
  markdownContainer: {
    borderRadius: theme.shape.borderRadius,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  withBorder: {
    border: `1px solid ${theme.palette.grey[600]}`,
  },
  markdown: {
    flex: 1,
    overflow: "auto",
  },
  oneReturnForLineBreak: {
    whiteSpace: "pre-wrap",
  },
}));

const MarkdownDialog = ({ markdownText, showBorder = true }) => {
  const classes = useStyles();

  const customComponents = {
    // make a single return in a paragraph of Markdown behave as a line break
    p: ({ children }) => <p className={classes.oneReturnForLineBreak}>{children}</p>,
    a: CustomLinkRenderer,
  };

  return (
    <div className={`${classes.markdownContainer} ${showBorder ? classes.withBorder : ""}`}>
      <Markdown
        components={customComponents}
        className={classes.markdown}
        allowedElements={["p", "strong", "em", "ul", "ol", "li", "a"]}
      >
        {markdownText}
      </Markdown>
    </div>
  );
};

export default MarkdownDialog;
