import React from "react";
import { useDispatch } from "react-redux";
import { Field } from "formik";
import * as Yup from "yup";

import Form from "components/form";
import { NoteInput } from "components/inputs/text-input";
import { cancelReferralAction } from "app/main/referrals/actions/referrals.actions";
import CodeSetValueSelector from "app/main/codeSet/components/code-set-value-selector";
import ReferralWarningMessage from "./referral-warning-message-alert";

const cancelReasonCode = "Reason for Cancellation";
const reason = "Details";

const schema = Yup.object().shape({
  referral: Yup.object().shape({
    cancelReasonCodeId: Yup.string()
      .required(`${cancelReasonCode} is required`)
      .nullable(),
    cancelReason: Yup.string()
      .max(2000, `${reason} exceeds maximum length of 2000 characters`)
      .nullable(),
  }),
});

const CancelReferralForm = ({ orgUnitId, referralId, warningMessages, onSucceed, ...other }) => {
  const dispatch = useDispatch();

  const handleSubmit = ({ referral }, { setSubmitting, setErrors }) => {
    const cancelReferral = {
      referralId,
      cancelReasonCodeId: referral.cancelReasonCodeId.value,
      cancelReason: referral.cancelReason,
    };

    dispatch(cancelReferralAction(orgUnitId, cancelReferral)).then(responds => {
      setSubmitting(false);
      if (responds.error !== true) {
        onSucceed();
      } else {
        setErrors(responds.payload);
      }
    });
  };

  const initialValues = schema.cast();

  return (
    <Form
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={handleSubmit}
      contentProps={other}
      variant="filled"
      content={() => (
        <>
          <ReferralWarningMessage warningMessages={warningMessages} />
          <Field
            name="referral.cancelReasonCodeId"
            component={CodeSetValueSelector}
            label={cancelReasonCode}
            icon="cancel"
            codeSetTypeCode="ReasonForCancellation"
            required
            allowHideOnForms
          />
          <Field
            name="referral.cancelReason"
            component={NoteInput}
            label={reason}
            icon="message"
            maxLength={2000}
          />
        </>
      )}
    />
  );
};

export default CancelReferralForm;
