import React from "react";
import { useSelector } from "react-redux";
import AdvancedFilter, { FilterTags } from "components/items/advanced-filter";
import { makeStyles } from "@material-ui/core/styles";
import DefaultButton from "components/items/default-button";
import ReferralAdvancedFilterForm from "app/main/referralWorklist/components/referral-advanced-filter-form";
import { getPatientReferralSearchTags } from "app/main/referrals/reducers/patientReferrals.reducers";
import translateReferralSearchParams from "app/main/referralWorklist/helpers/translate-search-params";
import { defaultSearchParams } from "app/main/referralWorklist/reducers/referralWorklist.reducers";
import useTriageFieldSettings from "app/main/referrals/hooks/useTriageFieldSettings";
import useRejectFieldSettings from "app/main/referrals/hooks/useRejectFieldSettings";
import useReferralFieldSettings from "app/main/referrals/hooks/useReferralFieldSettings";

const useStyles = makeStyles(theme => ({
  searchInput: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const PatientReferralListAdvancedFilter = ({
  patientId,
  total,
  pageNumber,
  pageSize,
  onSearch,
  searchParams,
  initialValues,
  onPageChange,
  ...other
}) => {
  const classes = useStyles();
  const tags = useSelector(getPatientReferralSearchTags);
  const { orgUnitId } = other;
  const { fieldSettings: triageFieldSettings, fieldLabels: triageFieldLabels } = useTriageFieldSettings(orgUnitId);
  const { fieldSettings: rejectFieldSettings, fieldLabels: rejectFieldLabels } = useRejectFieldSettings(orgUnitId);
  const { fieldSettings: referralFieldSettings, fieldLabels: referralFieldLabels } = useReferralFieldSettings();

  const handleSubmit = ({ referralFilters }) => {
    const newReferralFilters = translateReferralSearchParams(referralFilters);
    const newParams = { ...searchParams, ...newReferralFilters, patientId };
    onSearch(newParams, null, null, true);
  };

  const handleReset = () => {
    onSearch({ ...defaultSearchParams, patientId }, null, null, true);
  };

  return (
    <AdvancedFilter
      withPaperWrapper={false}
      onSearch={text => onSearch({ ...searchParams, patientId, term: text }, null, null, true)}
      searchValue={searchParams?.term ?? ""}
      renderFilterTags={<FilterTags tags={tags} showDivider={false} />}
      searchInputProps={{ classes: { root: "flex-auto px-8", input: classes.searchInput } }}
      variant="accordion"
      content={onClose => (
        <ReferralAdvancedFilterForm
          showHeader={false}
          triageFieldSettings={triageFieldSettings}
          triageFieldLabels={triageFieldLabels}
          rejectFieldSettings={rejectFieldSettings}
          rejectFieldLabels={rejectFieldLabels}
          referralFieldSettings={referralFieldSettings}
          referralFieldLabels={referralFieldLabels}
          initialValues={initialValues}
          handleSubmit={handleSubmit}
          handleReset={handleReset}
          onClose={onClose}
          renderActions={props => (
            <div className="flex-row-container with-gutter">
              <DefaultButton label="Clear" className="w-320" onClick={() => { props.onReset(); props.onClose(); }} variant="outlined" size="medium" />
              <DefaultButton label="Search" className="w-320" onClick={() => { props.handleSubmit(); props.onClose(); }} size="medium" />
            </div>
          )}
          {...other}
        />
      )}
    />
  );
};

export default PatientReferralListAdvancedFilter;
