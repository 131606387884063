import React, { useState } from "react";
import { Typography, Box } from "@material-ui/core";
import { useSelector } from "react-redux";
import { truncate } from "lodash";
import IconButton from "components/items/icon-button";
import ActivityLogItem from "components/items/activity-log/item";
import downloadFromApi, { openPdf } from "utils/download-from-api";
import { getOpenGPLetterInNewTab, getReferralCorrespondenceLetterHistoryTitle } from "app/auth/store/reducers/system-configuration";
import withPermissions from "permissions/withPermissions";
import DownloadIcon from "mdi-react/DownloadIcon";
import Acknowledgement from "./acknowledgement";

const CorrespondenceItem = ({ activity, referralId, hasPermissionReferralCorrespondenceAttachmentView }) => {
  const [downloading, setDownloading] = useState(false);
  const openGPLetterInNewTab = useSelector(getOpenGPLetterInNewTab);
  const referralCorrespondenceLetterHistoryTitle = useSelector(getReferralCorrespondenceLetterHistoryTitle);
  const canGenerateGPLetter = hasPermissionReferralCorrespondenceAttachmentView && activity.referralCorrespondenceAttachmentId;
  const icon = activity.isLetter ? "mail_outline" : "reply";
  const titleContent = activity.isLetter ? referralCorrespondenceLetterHistoryTitle : "Reply to Referrer";
  const content = (
    <div>
      <Typography variant="body2">{(activity.isLetter ? activity.subject : `Subject: ${activity.subject}`)}</Typography>
      <Typography variant="body2">{truncate(activity.message, { length: 150 })}</Typography>
    </div>
  );

  const onGenerateGPLetter = async event => {
    event.stopPropagation();
    setDownloading(true);
    const call = openGPLetterInNewTab ? openPdf : downloadFromApi;
    await call(`api/referrals/${referralId}/downloadReferralCorrespondenceAttachment/${activity.referralCorrespondenceAttachmentId}`);
    return setDownloading(false);
  };

  const title = () => (
    <div className="flex-row-container with-gutter">
      <Box fontWeight="fontWeightBold">{titleContent}</Box>
      {canGenerateGPLetter
        && <IconButton iconSize={24} icon={<DownloadIcon />} title="Download" onClick={onGenerateGPLetter} loading={downloading} />}
    </div>
  );

  return (
    <ActivityLogItem
      icon={icon}
      title={title()}
      content={content}
      date={activity.createdDateLocalised}
      user={activity.addedBy}
      renderLeftContent={<Acknowledgement acknowledgement={activity.acknowledgement} dateTime={activity.acknowledgementDateTimeUtc} />}
      renderPopupContent={({ close }) => (
        <>
          <div className="flex justify-between items-center">
            <Typography className="font-bold">{titleContent}</Typography>
            <div className="flex items-center justify-end">
              <div className="ml-16">
                <IconButton title="Close" onClick={close} icon="close" />
              </div>
            </div>
          </div>
          <Typography variant="caption">Added by {activity.addedBy} at {activity.createdDateLocalised}</Typography>
          <div className="pt-16">
            {content}
          </div>
        </>
      )}
    />
  );
};

export default withPermissions("ReferralCorrespondenceAttachmentView")(CorrespondenceItem);
