import { get } from "lodash";
import curryConnector from "utils/connectors";

export const PATIENT_OCR_STATE_KEY = "patientOCR";

const curry = fn => curryConnector(fn, PATIENT_OCR_STATE_KEY);

export const ActionTypes = {
  SET_CURRENT_PATIENT_OCR: "SET_CURRENT_PATIENT_OCR",
  SET_CURRENT_ATTACHMENT: "SET_CURRENT_ATTACHMENT",
  RESET_CURRENT_PATIENT_OCR: "RESET_CURRENT_PATIENT_OCR",
  RESET_CURRENT_ATTACHMENT: "RESET_CURRENT_ATTACHMENT",
};

const defaultPatientOCR = {
  ocrData: null,
};

const defaultAttachment = {
  fileName: "",
};

const INITIAL_STATE = {
  current: defaultPatientOCR,
  currentFile: defaultAttachment,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SET_CURRENT_PATIENT_OCR:
      return {
        ...state,
        current: {
          ocrData: action.payload.patientOCR,
          error: action.payload.error,
        },
      };
    case ActionTypes.SET_CURRENT_ATTACHMENT:
      return {
        ...state,
        currentFile: action.payload,
      };
    case ActionTypes.RESET_CURRENT_PATIENT_OCR:
      return {
        ...state,
        current: defaultPatientOCR,
      };
    case ActionTypes.RESET_CURRENT_ATTACHMENT:
      return {
        ...state,
        currentFile: defaultAttachment,
      };
    default:
      return state;
  }
};

export const getCurrentPatientOCR = curry(({ localState }) => get(localState, ["current"], null));

export const getCurrentAttachment = curry(({ localState }) => get(localState, ["currentFile"], null));
