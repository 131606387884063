import React, { useState } from "react";
import { map, find, toLower, join, isEmpty, filter, concat } from "lodash";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import { Chip, InputLabel, Input, IconButton, Tooltip } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";

import ConfirmationDialog from "components/items/confirmation-dialog";
import DefaultButton from "components/items/default-button";
import DisabledInput from "components/inputs/components/disabled-input";
import BaseInputField from "components/inputs/components/base-input-component";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { getSubSpecialtyLabel } from "app/auth/store/reducers/system-configuration";
import FormContext from "components/FormContext";
import HelperText from "components/inputs/components/helper-text";
import { CloseCrossIcon } from "helpers/icon-finder";

import SubSpecialtyForm from "./sub-specialty-form";

const useStyles = makeStyles(() => ({
  tag: {
    "& svg": {
      pointerEvents: "initial",
    },
  },
  input: {
    "&::placeholder": {
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
    },
  },
}));

const SubSpecialtyInput = ({
  field: { name, value: items },
  form: { setFieldValue },
  label,
  readOnly,
  disabled,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [error, setError] = useState(null);
  const subSpecialtyLabel = useSelector(getSubSpecialtyLabel);
  const displayLabel = label ?? subSpecialtyLabel;
  const placeholderLabel = `Enter name of the new ${subSpecialtyLabel}`;

  const addItem = () => {
    const newItem = { displayName: inputValue, name: inputValue, isDeprecated: false };
    const isMatch = find(items, x => toLower(x.name) === toLower(inputValue));

    if (isMatch) {
      setError(`The ${subSpecialtyLabel} entered already exists in the list`);
      return items;
    }
    setInputValue("");
    return setFieldValue(name, isEmpty(items) ? [newItem] : concat(items, newItem));
  };

  const removeItem = item => {
    const updatedItems = filter(items, x => x.name !== item.name);
    setFieldValue(name, updatedItems);
  };

  const deleteItem = item => {
    dispatch(openDialog({
      children: (
        <ConfirmationDialog
          type="warning"
          title={`Remove ${item.name}?`}
          content={`${subSpecialtyLabel} ${item.name} has never been used and can be permanently removed. Any users assigned to this ${subSpecialtyLabel} will be un-assigned.`}
          onConfirm={() => {
            removeItem(item);
            dispatch(closeDialog());
          }}
          onCancel={() => dispatch(closeDialog())}
        />
      ),
    }));
  };

  const updateItem = (subSpecialty, index) => {
    const updatedItem = { ...subSpecialty, displayName: `${subSpecialty.name}${subSpecialty.isDeprecated ? " (Deprecated)" : ""}` };
    const updatedItems = [...items.slice(0, index), updatedItem, ...items.slice(index + 1)];
    setFieldValue(name, updatedItems);
  };

  const handleDelete = (allowRemove, item, index) => {
    if (allowRemove && isEmpty(item.id)) {
      removeItem(item);
    } else if (allowRemove && !isEmpty(item.id)) {
      deleteItem(item);
    } else {
      updateItem({ ...item, isDeprecated: !item.isDeprecated }, index);
    }
  };

  const openForm = (item, itemIndex) => {
    dispatch(openDialog({
      maxWidth: "xs",
      children: (
        <SubSpecialtyForm
          title={`Edit ${subSpecialtyLabel}`}
          subSpecialty={item}
          handleSubmit={currentSubSpecialty => {
            updateItem(currentSubSpecialty, itemIndex);
            dispatch(closeDialog());
          }}
          handleRemove={currentSubSpecialty => {
            removeItem(currentSubSpecialty);
            dispatch(closeDialog());
          }}
        />
      ),
    }));
  };

  return (
    <FormContext.Consumer>
      {({ formDisabled }) => {
        const disabledInput = readOnly || disabled || formDisabled;

        if (disabledInput) {
          return (
            <BaseInputField multiline>
              <DisabledInput label={displayLabel} value={items ? join(map(items, x => x.displayName), ", ") : null} />
            </BaseInputField>
          );
        }

        return (
          <>
            <BaseInputField error={!isEmpty(error)}>
              <InputLabel htmlFor={displayLabel}>{displayLabel}</InputLabel>
              <div className="mt-16 flex sm:flex-row sm:items-center flex-col">
                <Input
                  id={displayLabel}
                  label={displayLabel}
                  rows={2}
                  classes={{
                    root: "flex-1 flex-row-container with-gutter",
                    input: clsx("h-auto flex-1", classes.tag, classes.input),
                  }}
                  placeholder={placeholderLabel}
                  value={inputValue}
                  onKeyDown={e => {
                    if (e.key === "Enter" && !isEmpty(inputValue.trim())) {
                      addItem();
                    }
                  }}
                  onChange={e => {
                    if (error) {
                      setError(null);
                    }
                    setInputValue(e.target.value);
                  }}
                  startAdornment={map(items, (item, index) => {
                    const allowRemove = isEmpty(item.id) || item?.enableDelete;

                    return (
                      <Chip
                        label={item.displayName}
                        size="small"
                        key={item.name}
                        onDelete={allowRemove ? () => handleDelete(allowRemove, item, index) : null}
                        onClick={() => openForm(item, index)}
                        deleteIcon={allowRemove ? <Tooltip title="Remove"><div><CloseCrossIcon size={17} /></div></Tooltip> : null}
                      />
                    );
                  })}
                  endAdornment={inputValue
                  && (
                  <div className="absolute right-0">
                    <IconButton
                      icon="clear"
                      title="Clear input"
                      onClick={() => { setInputValue(""); setError(null); }}
                    />
                  </div>
                  )}
                />
                <DefaultButton type="submit" label="Add" disabled={inputValue.trim() === ""} onClick={addItem} />
              </div>
            </BaseInputField>
            {error && (
              <div className="ml-32 -mt-8">
                <HelperText error helperText={error} />
              </div>
            )}
          </>
        );
      }}
    </FormContext.Consumer>
  );
};

export default SubSpecialtyInput;
