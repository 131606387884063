import React from "react";
import { Card, CardHeader, CardContent, CardActions, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { find } from "lodash";
import { useSelector } from "react-redux";

import { OrgUnitsIcon } from "helpers/icon-finder";
import PatientReferralLinkButton from "app/main/patients/referrals/components/patient-referral-link-button";
import ReferralAssignTo from "app/main/referralWorklist/components/referral-assign-to";
import { ContextItem } from "components/items/default-item";
import formatDaysFromNow from "helpers/format-days-from-now";
import formatDate from "helpers/format-date";
import ItemStatus from "components/items/item-status";
import ReferralCategoryLabel from "app/main/referrals/components/referral-category-label";
import { getReferralWorkflowStateSettings } from "app/auth/store/reducers/system-configuration";
import useReferralFieldSettings from "app/main/referrals/hooks/useReferralFieldSettings";

const useStyles = makeStyles(theme => ({
  headerContainer: {
    padding: theme.spacing(1, 0, 1, 2),
  },
  headerAction: {
    marginRight: 0,
    paddingLeft: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(0, 2, 1),
  },
  action: {
    borderTop: `1px dashed ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "flex-end",
  },
  category: {
    marginLeft: "8px",
  },
}));

const Item = props => <ContextItem labelClassName="w-128" iconClassName="w-128" renderIfEmpty {...props} />;

const ReferralCard = ({
  orgUnitId,
  referral,
  goToReferral,
}) => {
  const classes = useStyles();
  const referralWorkflowSettings = useSelector(getReferralWorkflowStateSettings);
  const { fieldSettings } = useReferralFieldSettings(referral.formFieldConfigurationId);
  const status = find(referralWorkflowSettings, x => x.workflowState === referral.referralStatus);
  const { assignedTo, referralFrom, orgUnitName } = referral;
  const assignedToSubSpecialtyDisplayValue = assignedTo?.assignedToSubSpecialtyDisplayName ? ` (${assignedTo?.assignedToSubSpecialtyDisplayName})` : "";
  const assignedToSpecialtyDisplayValue = assignedTo?.assignedToSpecialtyName ? `${assignedTo?.assignedToSpecialtyName}${assignedToSubSpecialtyDisplayValue}` : "No Assigned Specialty";

  return (
    <Card className={classes.root}>
      <CardHeader
        classes={{ root: classes.headerContainer, content: "flex items-center justify-between", action: classes.headerAction }}
        action={(
          <div className="flex md:items-center">
            {orgUnitName && <Typography className="flex flex-1 items-center font-bold"><OrgUnitsIcon />{orgUnitName}</Typography>}
            <PatientReferralLinkButton isDisabled={goToReferral === null} orgUnitId={orgUnitId} referralOrgUnitName={referral.orgUnitName} referralOrgUnitId={referral.orgUnitId} onClick={goToReferral} />
          </div>
        )}
        title={(
          <div className="flex flex-1 md:items-center">
            <ItemStatus label={status?.label ?? referral.status} color={status?.color} icon={status?.icon} textProps={{ variant: "body1" }} />
            <ReferralCategoryLabel className={classes.category} triageCategory={referral.triageCategory} isUrgent={referral.isUrgent} orgUnitId={referral.orgUnitId} />
          </div>
        )}
        disableTypography
      />
      <CardContent className={classes.content}>
        <div className="items-container">
          <Item label="From" content={`${referralFrom ? `${referralFrom}` : ""}${referral.referrerProviderNumber ? ` (${referral.referrerProviderNumber})` : ""}`} />
          <Item label="For" content={assignedToSpecialtyDisplayValue} />

          <Item label="Referral Date" content={referral.referralDateUtc ? `${formatDate(referral.referralDateUtc)} (${formatDaysFromNow(referral.referralDateUtc)})` : null} />
          {referral.isManualReferral && <Item label="Referral Period" content={referral.referralPeriod} />}
          {fieldSettings?.presentingComplaintCodes && referral.isManualReferral && <Item label="Presenting Complaint" content={referral.presentingComplaintSummary ? referral.presentingComplaintSummary : null} />}
        </div>
      </CardContent>
      <CardActions className={classes.action}>
        <ReferralAssignTo referral={referral} disabled orgUnitId={orgUnitId} />
      </CardActions>
    </Card>
  );
};

export default ReferralCard;
