import React, { useState } from "react";
import moment from "moment-timezone";
import {useDispatch, useSelector} from "react-redux";

import DefaultButton from "components/items/default-button";
import ScreenHeader from "components/items/screen-header";
import { ExcelExportIcon } from "helpers/icon-finder";
import downloadFromApi, {fetchFromApi} from "utils/download-from-api";
import formatSearchParams from "helpers/format-search-params";
import { getReportingUseHangfireQueuing } from "app/auth/store/reducers/system-configuration";
import { showExportIndicator } from "app/main/reportExport/actions/reportExport.actions";
import PatientMergeLogSearch from "./patient-access-log-search";
import { getSearchParams } from "../reducers/patientAccessLog.reducers";

const PatientAccessLogHeader = ({ title = "Patient Access Logs" }) => {
  const dispatch = useDispatch();
  const [loadingExport, setLoadingExport] = useState(false);

  const searchParams = useSelector(getSearchParams);
  const backgroundExport = useSelector(getReportingUseHangfireQueuing);

  const handleExport = async () => {
    const timeZone = moment.tz.guess();
    const params = formatSearchParams({ ...searchParams });
    const exportUrl = `api/patientAccess/createExcelDocument?timeZone=${timeZone}${params || searchParams}`;

    setLoadingExport(true);
    if (backgroundExport) {
      const response = await fetchFromApi(exportUrl);
      setLoadingExport(false);

      dispatch(showExportIndicator());
      return response.statusText;
    }

    const response = await downloadFromApi(exportUrl);
    setLoadingExport(false);
    return response.statusText;
  };

  return (
    <ScreenHeader
      title={title}
      renderSearch={<PatientMergeLogSearch />}
      renderActions={(
        <DefaultButton
          label="Export"
          variant="outlined"
          color="inherit"
          loading={loadingExport}
          icon={<ExcelExportIcon />}
          onClick={handleExport}
        />
      )}
    />
  );
};

export default PatientAccessLogHeader;
