import React from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { map, isEmpty, startCase, filter, includes } from "lodash";
import { Hidden, Typography } from "@material-ui/core";

import DefaultPanelLayout from "components/items/default-panel-layout";
import EmptyState from "components/items/empty-state";
import IconButton from "components/items/icon-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import { getOrgUnitFormFieldConfigs } from "app/main/orgUnits/reducers/orgUnit.reducers";
import { getAllowReferralProfileFormTypes } from "app/auth/store/reducers/system-configuration";
import OrgUnitFormFieldsConfigurationForm from "./orgUnit-form-fields-configuration-form";
import { getEnableFormTypes } from "app/auth/store/reducers/user.reducer";

const title = "Form Configurations";

const OrgUnitFormFieldsConfigurationPageContent = ({
  orgUnit,
}) => {
  const dispatch = useDispatch();
  const orgUnitFormFields = useSelector(getOrgUnitFormFieldConfigs);
  const currentOrgUnitFormFields = filter(orgUnitFormFields, x => x.orgUnitId === orgUnit.id);
  const allowReferralProfileFormTypes = useSelector(getAllowReferralProfileFormTypes);
  const enableFormTypes = useSelector(getEnableFormTypes)  
  const addFormTypeOption = map(filter(allowReferralProfileFormTypes, referralFormType => includes(enableFormTypes, referralFormType)), x => ({ value: x, label: startCase(x) }));
  const disabledAdd = isEmpty(addFormTypeOption);

  const openOrgUnitFormFieldsConfigurationForm = (formFieldTitle, options = null, formFieldsConfiguration = null, editFormFields = false) => dispatch(openDialog({
    maxWidth: "xl",
    children: (
      <OrgUnitFormFieldsConfigurationForm
        title={formFieldTitle}
        currentFormFieldsConfiguration={formFieldsConfiguration}
        formTypeOptions={options}
        orgUnitId={orgUnit.id}
        editFormFields={editFormFields}
        enableOCR={formFieldsConfiguration?.enableOCR}
        enableReadOnly={formFieldsConfiguration?.enableReadOnly}
        onSucceed={() => dispatch(closeDialog())}
      />
    ),
  }, { fullHeight: true }));

  const onAdd = () => {
    openOrgUnitFormFieldsConfigurationForm("Add Form Configuration", addFormTypeOption);
  };

  const onEdit = currentFormConfig => {
    const editFormTypeOption = { value: currentFormConfig.formType, label: startCase(currentFormConfig.formType) };
    openOrgUnitFormFieldsConfigurationForm("Edit Form Configuration", editFormTypeOption, currentFormConfig, true);
  };

  return (
    <DefaultPanelLayout
      title="Form Configurations"
      icon="mail_outline"
      isEmpty={isEmpty(currentOrgUnitFormFields)}
      emptyState={disabledAdd ? <EmptyState /> : <EmptyState subtitle={`Add ${title}`} onClick={onAdd} />}
      headerActions={!disabledAdd &&([
        {
          label: "Add",
          icon: "add",
          onClick: () => onAdd(),
        },
      ])}
    >
      <div className="items-container">
        {map(currentOrgUnitFormFields, (form, index) => (
          <div key={index.toString()} className={clsx("flex-auto", !(index === 0) && "with-horizontal-divider")}>
            <div className="flex-row-container">
              <div className="flex flex-col flex-1">
                <div className="flex-row-container">
                  <Typography variant="body2" className="font-bold">{form.name ?? startCase(form.formType)}</Typography>
                </div>
                <div className="flex-row-container">
                  <Typography variant="body2" className="mr-4">Form Type:</Typography>
                  <Typography variant="body2" color="textPrimary">{startCase(form.formType)}</Typography>
                </div>
              </div>
              <Hidden>
                <div className="flex items-center">
                  <IconButton title="Edit" icon="edit" onClick={() => onEdit(form)} />
                </div>
              </Hidden>
            </div>
          </div>
        ))}
      </div>
    </DefaultPanelLayout>
  );
};

export default OrgUnitFormFieldsConfigurationPageContent;
