import { ApiActions } from "middleware/call-api";
import { ActionTypes } from "../reducers/assessments.reducers";

export const createAssessment = assessment => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${assessment.patientId}/assessments/new`,
    body: { assessment },
    types: ["CREATING_ASSESSMENT", ActionTypes.CREATED_ASSESSMENT, "ERROR_CREATING_ASSESSMENT"],
    patientId: assessment.patientId,
    appointmentId: assessment.appointmentId,
    encounterId: assessment.encounterId,
  },
});

export const continueAssessment = assessment => async dispatch => dispatch({
  [ApiActions.POST_TO_API]: {
    endpoint: `/patient/${assessment.patientId}/assessments/continue`,
    body: { assessment },
    types: ["CONTINUING_ASSESSMENT", ActionTypes.CONTINUED_ASSESSMENT, "ERROR_CONTINUING_ASSESSMENT"],
    patientId: assessment.patientId,
    appointmentId: assessment.appointmentId,
  },
});
