import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { filter, find, concat, isEmpty, isArray, map, includes } from "lodash";

import { areSpecialtyCodeSetValuesLoading, getSpecialtiesCodeSetValues } from "app/main/specialtyProfiles/reducers/specialtyProfiles.reducers";
import { AutoComplete, AutoCompleteLoading } from "components/inputs";
import { fetchSpecialtyProfilesCodeSetValue } from "app/main/specialtyProfiles/actions/specialtyProfiles.actions";
import { translateTermOptions } from "app/main/codeSet/actions/codeSet.actions";
import { getSignedInOrgUnit } from "app/auth/store/reducers/user.reducer";
import { getHideHealthLinkOnlyTypeCodes } from "app/auth/store/reducers/system-configuration";

const SpecialtiesCodeSetSelector = ({
  label,
  specialtyIds,
  codeSetTypeCode,
  multiple,
  forceLoad,
  isTermSelector = false,
  loadOptions,
  allowHideHealthLinkOnly = false,
  allowHideOnForms = false,
  allowHideOnSearch = false,
  ...other
}) => {
  const dispatch = useDispatch();
  const orgUnit = useSelector(getSignedInOrgUnit);
  const loading = useSelector(state => areSpecialtyCodeSetValuesLoading(state));
  const hideHealthLinkOnlyTypeCodes = useSelector(getHideHealthLinkOnlyTypeCodes);
  const specialtyCodeSetValues = useSelector(getSpecialtiesCodeSetValues);
  const codeSetOptions = filter(specialtyCodeSetValues, x => x.codeSetTypeCode === codeSetTypeCode);
  const timer = useRef();
  const isReloadOptions = (loadOptions || specialtyIds) && codeSetTypeCode;

  const { field: { name, value: selectedValue }, form: { setFieldValue } } = other;

  let options = [];

  const loadSpecialtyProfilesCodeSetValueOptions = () => {
    dispatch(fetchSpecialtyProfilesCodeSetValue(orgUnit.id, { specialtyIds, codeSetTypeCode })).then(response => {
      if (!isEmpty(selectedValue)) {
        const specialtiesCodeSetValues = map(response?.payload?.specialtiesCodeSetValues, x => x.code);
        if (isArray(selectedValue)) {
          const updatedSelectedValues = filter(selectedValue, x => includes(specialtiesCodeSetValues, x));
          setFieldValue(name, updatedSelectedValues);
        } else {
          const updatedSelectedValues = includes(specialtiesCodeSetValues, selectedValue) ? selectedValue : null;
          setFieldValue(name, updatedSelectedValues);
        }
      }
    });
  };

  useEffect(() => {
    if (isReloadOptions) {
      timer.current = setTimeout(() => {
        loadSpecialtyProfilesCodeSetValueOptions();
      }, 1000);
    }

    return () => clearTimeout(timer.current);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(specialtyIds), isReloadOptions]);

  if (loading) return <AutoCompleteLoading label={label} />;

  let filteredOptions = codeSetOptions || [];
  if (includes(hideHealthLinkOnlyTypeCodes, codeSetTypeCode) && allowHideHealthLinkOnly === true) {
    filteredOptions = filter(filteredOptions, x => (x.propertiesJson?.HealthLinkOnly ?? false) === false);
  }
  if (allowHideOnForms) {
    filteredOptions = filter(filteredOptions, x => (x.propertiesJson?.HideOnForms ?? false) === false);
  }
  if (allowHideOnSearch) {
    filteredOptions = filter(filteredOptions, x => (x.propertiesJson?.HideOnSearch ?? false) === false);
  }

  if (isReloadOptions) {
    options = translateTermOptions(filteredOptions, isTermSelector, selectedValue);
  }

  if (!isEmpty(selectedValue)) {
    // check if selected value is in the option
    // select code set from all the code set values
    if (isArray(selectedValue)) {
      selectedValue.forEach(value => {
        const existingOption = find(specialtyCodeSetValues, x => x.id === value);
        options = concat(options ?? [], { value: existingOption?.id, label: existingOption?.displayName, disabled: true });
      });
    } else {
      const existingOption = find(specialtyCodeSetValues, x => x.id === selectedValue);
      options = concat(options ?? [], { value: existingOption?.id, label: existingOption?.displayName, disabled: true });
    }
  }

  const multipleProps = multiple && {
    handleOnChange: (newValue, _name, _setFieldValue) => _setFieldValue(_name, newValue),
  };

  return (
    <AutoComplete
      label={label}
      options={options}
      filterSelectedOptions
      multiple={multiple}
      getOptionDisabled={option => option.disabled}
      {...multipleProps}
      {...other}
    />
  );
};

export default SpecialtiesCodeSetSelector;
