import React from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import AccountEdit from "mdi-react/AccountEditIcon";
import { concat, map, some, filter, size } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { AutoComplete } from "components/inputs";
import DefaultButton from "components/items/default-button";
import { openDialog, closeDialog } from "app/store/actions/dialog.actions";
import Chip from "@material-ui/core/Chip";
import { getEnableMultipleUserSpecialties } from "app/auth/store/reducers/system-configuration";
import UserSpecialtyProfileForm from "./user-specialty-profile-form";

const useStyles = makeStyles(() => ({
  tag: {
    "& svg": {
      pointerEvents: "initial",
    },
  },
}));

const UserSpecialtyProfileSelector = ({
  orgUnit,
  isDisabled,
  userSpecialtyProfiles,
  ...other
}) => {
  const dispatch = useDispatch();
  const onClose = () => dispatch(closeDialog());
  const enableMultipleUserSpecialties = useSelector(getEnableMultipleUserSpecialties);
  const { field: { name, value }, form: { setFieldValue } } = other;

  const addSpecialtyProfile = (userSpecialtyProfile, currentIndex) => {
    if (!some(value, x => x.specialtyProfileId === userSpecialtyProfile.specialtyProfileId && x.subSpecialtyId === userSpecialtyProfile.subSpecialtyId)) {
      if (currentIndex === -1) {
        setFieldValue(name, concat(value ?? [], userSpecialtyProfile));
      } else {
        setFieldValue(name, map(value, (x, index) => (index === currentIndex ? userSpecialtyProfile : x)));
      }
    }
    onClose();
  };

  const openUserSpecialtyProfileForm = (currentUserSpecialtyProfile, index = -1) => {
    dispatch(openDialog({
      maxWidth: "xs",
      fullScreen: false,
      children: (
        <UserSpecialtyProfileForm
          userSpecialtyProfiles={value}
          currentUserSpecialtyProfile={currentUserSpecialtyProfile}
          title={currentUserSpecialtyProfile ? "Edit Specialty" : "Add Specialty"}
          onSubmit={({ userSpecialtyProfile }) => addSpecialtyProfile(userSpecialtyProfile, index)}
          submitLabel={currentUserSpecialtyProfile ? "Update" : "Add"}
          orgUnit={orgUnit}
        />
      ),
    }));
  };

  const onAddSpecialtyProfile = () => {
    openUserSpecialtyProfileForm();
  };

  const onSpecialtyProfileDelete = option => {
    setFieldValue(name, filter(value, x => x.specialtyProfileId !== option.specialtyProfileId));
  };

  const onSpecialtyProfileEdit = (currentUserRole, index) => {
    openUserSpecialtyProfileForm(currentUserRole, index);
  };

  const classes = useStyles();

  return (
    <div className="flex sm:flex-row sm:items-center flex-col">
      <AutoComplete
        className={clsx("flex-1", classes.tag)}
        options={map(value, x => ({ label: `${x.specialtyProfileLabel} ${x.subSpecialtyLabel ? `-${x.subSpecialtyLabel}` : ""} (${x.orgUnitName})`, value: x }))}
        getOptionSelected={(option, val) => option.value === val.value}
        renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
          <Chip
            {...getTagProps({ index })}
            icon={<AccountEdit size={20} />}
            label={option?.label}
            onClick={() => onSpecialtyProfileEdit(option.value, index)}
            onDelete={() => onSpecialtyProfileDelete(option.value)}
            size="small"
          />
        ))}
        multiple
        forcePopupIcon={false}
        disableClearable
        open={false}
        disabled={isDisabled}
        multiline
        {...other}
      />
      {!isDisabled && (enableMultipleUserSpecialties || (!enableMultipleUserSpecialties && size(value) === 0)) && (
        <div className="sm:pl-8 pl-32 flex">
          <DefaultButton
            onClick={onAddSpecialtyProfile}
            label="Add"
            edge="end"
          />
        </div>
      )}
    </div>
  );
};

export default UserSpecialtyProfileSelector;
