/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { map, isEmpty, includes } from "lodash";
import { Typography, Link } from "@material-ui/core";
import { useDispatch } from "react-redux";
import ImageSearchOutlineIcon from "mdi-react/ImageSearchOutlineIcon";
import TextBoxSearchOutlineIcon from "mdi-react/TextBoxSearchOutlineIcon";

import { AttachmentItem } from "components/items/attachment";
import formatDate from "helpers/format-date";
import DefaultPanelLayout from "components/items/default-panel-layout";
import ReferralAttachmentPreviewDialog from "app/main/referrals/components/referral-attachment-preview-dialog";
import { openDialog } from "app/store/actions/dialog.actions";
import IconButton from "components/items/icon-button";
import formatAttachmentType from "app/main/patients/helpers/format-attachment-type";

export default ({
  orgUnitId,
  referralId,
  attachments,
  canCreate,
  canEditInEndState,
  canEditAttachments,
  onCreate,
  onRemove,
}) => {
  const dispatch = useDispatch();

  const openPreviewDialog = attachment => dispatch(openDialog({
    disableBackdropClick: false,
    children: (
      <ReferralAttachmentPreviewDialog
        title="Preview Attachment"
        file={attachment}
        referralId={referralId}
        referralDocumentId={attachment.referralDocumentId}
        orgUnitId={orgUnitId}
      />
    ),
  }));

  const previewButton = attachment => (
    <IconButton
      title="Preview"
      iconSize={24}
      icon={includes(attachment.mimeType, "image") ? <ImageSearchOutlineIcon /> : <TextBoxSearchOutlineIcon />}
      onClick={() => openPreviewDialog(attachment)}
    />
  );

  return (
    <DefaultPanelLayout
      title="Attachments"
      icon="attachment"
      isEmpty={isEmpty(attachments)}
      headerActions={canCreate && [
        {
          label: "Add Attachment",
          icon: "add",
          onClick: onCreate,
        },
      ]}
    >
      {map(attachments, (attachment, index) => (
        <AttachmentItem
          id={attachment.id}
          key={attachment.id}
          mimeType={attachment.mimeType}
          isFirst={index === 0}
          title={(
            <Typography className="font-bold" noWrap>
              <Link href="#" color="inherit" onClick={() => openPreviewDialog(attachment)}>
                {attachment.fileName}
              </Link>
            </Typography>
          )}
          subtitle={(
            <div className="flex flex-col">
              {attachment.documentType !== "Original"
                ? <Typography variant="caption">Added by Referrer as part of Update {attachment.documentVersion} on {formatDate(attachment.addedDateTimeUtc)}</Typography>
                : <Typography variant="caption">Added by {attachment.isSystemAttachment ? "Referrer" : attachment.addedBy} on {formatDate(attachment.addedDateTimeUtc)}</Typography>}
              <Typography variant="caption">Type: {formatAttachmentType(attachment.attachmentType)}</Typography>
            </div>
          )}
          canRemove={canEditAttachments && attachment.canDelete && canEditInEndState}
          onRemove={() => onRemove(attachment.referralDocumentId, attachment.id)}
          renderActions={previewButton(attachment)}
        >
          {attachment.description}
        </AttachmentItem>
      ))}
    </DefaultPanelLayout>

  );
};
