import React from "react";
import { useSelector } from "react-redux";
import { filter, map, find, isEmpty } from "lodash";

import { SelectInput } from "components/inputs";
import { getPatientAssessmentList } from "app/main/patients/reducers/assessments.reducers";
import { encounterTypeDisplay } from "app/main/patients/helpers/encounter-subject-display";
import formatDateTime from "helpers/format-date-time";

const translateAssessmentOption = assessment => (assessment
  ? ({ value: assessment.id, label: `${encounterTypeDisplay(assessment.type)} ${formatDateTime(assessment.startedDateTimeUtc, true)} ${assessment.assessmentBy}` }) : "");

const EncounterAssessmentInput = ({
  patientId,
  encounterType,
  assessmentNotRequired,
  disabled,
  ...other
}) => {
  let assessments = useSelector(state => getPatientAssessmentList(state, patientId)) ?? [];

  if (!patientId) return null;

  // always add default option to the select options
  const defaultOption = [{ value: "NewAssessment", label: "New Assessment" }];

  // if input is disabled and input has value,
  // we only need to find the assessmentOption from the list
  // and pass it to the select input to render the display label
  if (disabled) {
    const assessment = find(assessments, x => x.id === other?.field.value) ?? [];
    return (<SelectInput disabled options={(assessmentNotRequired || isEmpty(assessment)) ? null : [translateAssessmentOption(assessment)]} {...other} />);
  }

  // filter by encounter type
  // in this case would be either InitialAssessment or FollowUpAssessment
  // the available assessments for select options are limited to when their status is "InProgress"
  if (!isEmpty(assessments)) {
    if (encounterType) {
      assessments = filter(assessments, x => x.type === encounterType && x.status === "InProgress");
    }
    // translate the assessments to options
    assessments = map(assessments, assessment => translateAssessmentOption(assessment));
  }

  // combine default option with assessments
  const combinedOptions = [...defaultOption, ...assessments];

  return (
    <SelectInput
      options={combinedOptions}
      {...other}
    />
  );
};

export default EncounterAssessmentInput;
