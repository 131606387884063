import curryConnector from "utils/connectors";
import { get, head, filter, map } from "lodash";
import { ActionTypes as ApplicationActionTypes } from "app/main/applications/reducers/applications.reducers";

export const APPLICATION_WORKLIST_STATE_KEY = "applicationWorklist";

const curry = fn => curryConnector(fn, APPLICATION_WORKLIST_STATE_KEY);

export const ActionTypes = {
  SET_APPLICATION_WORKLIST_FILTERS: "SET_APPLICATION_WORKLIST_FILTERS",

  SEARCHING_APPLICATIONS: "SEARCHING_APPLICATIONS",
  SEARCHED_APPLICATIONS: "SEARCHED_APPLICATIONS",
  ERROR_SEARCHING_APPLICATIONS: "ERROR_SEARCHING_APPLICATIONS",
};

export const applicationStatusFilterOptions = [
  { value: "Draft", label: "Draft" },
  { value: "Requested", label: "Submitted" },
  { value: "Endorsed", label: "Endorsed" },
  { value: "UnderReview", label: "Under Review" },
  { value: "Approved", label: "Approved" },
  { value: "Rejected", label: "Not Approved" },
  { value: "Deferred", label: "Deferred" },
  { value: "Withdrawn", label: "Withdrawn" },
  { value: "NoDecision", label: "No Decision" },
  { value: "AwaitingInformation", label: "Awaiting Information" },
];

export const filterOptions = [
  { value: "all", label: "All Applications" },
  ...applicationStatusFilterOptions,
  { value: "advancedFilter", label: "Advanced Filter" },
];

export const defaultFilterOption = head(filterOptions);
const defaultApplicationStatusFilterOptions = filter(applicationStatusFilterOptions, x => x.value !== "Withdrawn");

export const defaultSearchParams = {
  term: null,
  applicationSpecialtyId: null,
  medications: null,
  indications: null,
  applicant: null,
  requestedById: null,
  requestedDate: null,
  assignedToUserId: null,
  endorserId: null,
  status: map(defaultApplicationStatusFilterOptions, x => x.value),
  meetingDate: null,
  reportDate: null,
  sortOrderDescending: true,
  sortBy: "StatusChangedDate",
  orgUnitId: null,
};

export const defaultPageNumber = 1;

export const defaultPageSize = 28;

export const getPresetFilterParams = filterValue => {
  const preFilter = get(filterValue, "value", filterValue);

  if (preFilter !== "all") {
    return {
      ...defaultSearchParams,
      status: [preFilter],
    };
  }

  return defaultSearchParams;
};

const INITIAL_STATE = {
  loading: false,
  error: null,
  loaded: false,
  searchParams: getPresetFilterParams(defaultFilterOption),
  selectedId: null,
  pageNumber: defaultPageNumber,
  total: 0,
  presetFilter: defaultFilterOption,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ActionTypes.SEARCHING_APPLICATIONS:
      return {
        ...state,
        loading: true,
        error: null,
        loaded: false,
      };
    case ActionTypes.ERROR_SEARCHING_APPLICATIONS:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.payload.message,
      };
    case ActionTypes.SEARCHED_APPLICATIONS:
      return {
        ...state,
        loading: false,
        error: null,
        loaded: true,
        searchParams: action.payload.searchParams,
        pageNumber: action.payload.pageInfo.pageNumber,
        total: action.payload.pageInfo.applicationsCount,
      };
    case ActionTypes.SET_APPLICATION_WORKLIST_FILTERS:
      return {
        ...state,
        presetFilter: action.payload.presetFilter,
      };
    case ApplicationActionTypes.CREATED_APPLICATION:
    case ApplicationActionTypes.CLONED_APPLICATION:
      return {
        ...state,
        total: state.total + 1,
      };
    case ApplicationActionTypes.TRANSFERRED_SITE:
      return {
        ...state,
        total: state.total - 1,
      };
    default:
      return state || INITIAL_STATE;
  }
};

export const getSearchParameters = curry(({ localState }) => localState.searchParams);
export const isApplicationWorklistLoading = curry(({ localState }) => localState.loading === true);
export const getApplicationWorklistLoadingErrors = curry(({ localState }) => localState.error);
export const getTotalNumberOfApplications = curry(({ localState }) => localState.total);
export const getApplicationWorklistFilter = curry(({ localState }) => localState.presetFilter);
export const getCurrentPage = curry(({ localState }) => localState.pageNumber);

export const bailOutOfLoadingApplications = curry(({ localState }, pageNumber) => {
  const { loading, total } = localState;

  if (loading || (pageNumber === 1 && total !== 0)) {
    return true;
  }
  return false;
});
